import { UserState } from '../../../../hooks/authUtils';
import getGraphQLClient, { DomainParams } from '../../../../hooks/graphQLClientUtil';
import GET_EQUIPMENT_INFO from '../graphql/GetEquipmentInfo';

const getEquipmentByItemIdHelper = async (userState: UserState, regionId: number, itemId: number) => {
  try {
    const data = await getGraphQLClient(userState, DomainParams.Equipments).request(GET_EQUIPMENT_INFO, { regionId, itemId });
    return data.equipment;
  } catch (error) {
    return null;
  }
};

export default getEquipmentByItemIdHelper;