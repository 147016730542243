import { duplicateButtonImage, resizeButtonImage, rotateButtonImage } from './common';
import { Drawing, DrawingExport } from './drawing';
import { Ellipse, EllipseExport } from './ellipse';
import { SketchImage, SketchImageExport } from './image';
import { Rect, RectExport } from './rect';
import { ObjectConfig, ObjectExport, SketchObject, SketchObjectType, SystemOfMeasurement } from './sketch-object';
import { Text, TextExport } from './text';
import { Wall } from './wall';

export type SketchObjectExport = RectExport | EllipseExport | SketchImageExport | TextExport | DrawingExport | SketchGroupExport;

export interface SketchGroupConfig extends ObjectConfig {
  objects?: SketchObjectExport[];
}

export interface SketchGroupExport extends ObjectExport {
  type: SketchObjectType.Group;
  objects?: SketchObjectExport[];
}

export class SketchGroup extends SketchObject {
  objects: SketchObject[] = [];

  private currentTransform?: DOMMatrix;

  constructor(config: SketchGroupConfig) {
    super(config);
    this.loadObjects(config.objects || []);
  }

  setObjects(objects: SketchObject[]) {
    this.objects = objects;
    this.emit('update');
  }

  setSelected(selected: boolean): void {
    this.objects.forEach(obj => obj.setSelected(selected));
    super.setSelected(selected);
  }

  setCanvas(canvas: HTMLCanvasElement | null): void {
    this.objects.forEach(obj => obj.setCanvas(canvas));
    super.setCanvas(canvas);
  }

  setSystemOfMeasurement(systemOfMeasurement: SystemOfMeasurement) {
    super.setSystemOfMeasurement(systemOfMeasurement);
    this.objects.forEach(o => o.setSystemOfMeasurement(systemOfMeasurement));
  }

  export(): SketchGroupExport {
    return {
      ...super.export(),
      type: SketchObjectType.Group,
      objects: this.objects.map(obj => obj.export() as SketchObjectExport),
    };
  }

  draw() {
    if (!this.canvas) return;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    const { x, y, width, height } = this.getBoundingRect();
    ctx.translate(x + width / 2, y + height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(x + width / 2), -(y + height / 2));
    this.objects.forEach(o => o.draw());
    this.currentTransform = ctx.getTransform();
    ctx.restore();
    if (this.selected) {
      this.drawSelection();
      this.drawRotateButton();
      this.drawResizeButton();
      this.drawDuplicateButton();
    }
  }

  drawName(): void { }

  drawSquareFootage(): void { }

  drawDimensions(): void { }

  drawSelection(): void {
    if (!this.objects.length || !this.canvas) return;
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    const { x, y, width, height } = this.getBoundingRect();
    ctx.translate(x + width / 2, y + height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(x + width / 2), -(y + height / 2));
    const size = 8;
    ctx.fillStyle = 'red';
    const x1 = x - size / 2;
    const y1 = y - size / 2;
    ctx.fillRect(x1, y1, size, size);
    ctx.fillRect(x1 + width, y1, size, size);
    ctx.fillRect(x1, y1 + height, size, size);
    ctx.fillRect(x1 + width, y1 + height, size, size);
    ctx.restore();
  }

  isPointInsideObject(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const { x: rx, y: ry, width, height } = this.getBoundingRect();
    path.rect(rx, ry, width, height);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isContainedByRect(outerRect: Rect) {
    return this.isObjectContainedByRect(this.getBoundingRect(), outerRect);
  }

  isPointInsideRotationButton(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.setTransform(this.currentTransform);
    const { x: rx, y: ry, width } = this.getBoundingRect();
    const path = new Path2D();
    path.rect(rx + width / 2 - 24 / 2, ry - 12 - 24, 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideResizeButton(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const { x: rx, y: ry, width } = this.getBoundingRect();
    path.rect(rx + width + 24 / 2, ry - 12 - 24, 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideDuplicateButton(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const { x: rx, y: ry, width, height } = this.getBoundingRect();
    const btnWidth = 24;
    const bx = rx + width + 12;
    const by = ry + height / 2 - btnWidth / 2;
    path.rect(bx, by, 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  handleDragStart(event: HammerInput): void {
    this.objects.forEach(obj => obj.handleDragStart(event));
  }

  handleDrag(event: HammerInput): void {
    this.objects.forEach(obj => obj.handleDrag(event));
  }

  handleDragEnd(event: HammerInput): void {
    this.objects.forEach(obj => obj.handleDragEnd(event));
  }

  handleResizeStart(event: HammerInput): void {
    this.objects.forEach(obj => obj.handleResizeStart(event));
  }

  handleResize(event: HammerInput): void {
    this.objects.forEach(obj => obj.handleResize(event));
  }

  handleResizeEnd(event: HammerInput): void {
    this.objects.forEach(obj => obj.handleResizeEnd(event));
  }

  loadObjects(objects: SketchObjectExport[]) {
    for (const o of objects) {
      this.loadObject(o);
    }
  }

  loadObject(o: SketchObjectExport) {
    let obj: SketchObject;
    switch (o.type) {
      case SketchObjectType.Rect:
        obj = new Rect(o);
        break;
      case SketchObjectType.Ellipse:
        obj = new Ellipse(o);
        break;
      case SketchObjectType.Image:
        obj = new SketchImage(o);
        break;
      case SketchObjectType.Text:
        obj = new Text(o);
        break;
      case SketchObjectType.Wall:
        obj = new Wall(o);
        break;
      case SketchObjectType.Drawing:
        obj = new Drawing(o);
        break;
      case SketchObjectType.Group:
        obj = new SketchGroup(o);
        break;
    }

    this.add(obj);
    return obj;
  }

  add(obj: SketchObject) {
    obj.setCanvas(this.canvas);
    obj.parent = this;
    this.objects.push(obj);
    obj.on('update', () => this.emit('update'));
    obj.on('load', () => this.emit('update'));
    obj.on('dragstart', (event) => obj.handleDragStart(event));
    obj.on('drag', (event) => obj.handleDrag(event));
    obj.on('dragend', (event) => obj.handleDragEnd(event));
    obj.on('resizestart', (event) => obj.handleResizeStart(event));
    obj.on('resize', (event) => obj.handleResize(event));
    obj.on('resizeend', (event) => obj.handleResizeEnd(event));
    obj.on('tap', () => this.select(obj));
    obj.on('insideselection', () => this.select(obj));
    obj.on('outsideselection', () => this.deselectObject(obj));
    obj.on('duplicate', (event: HammerInput) => {
      obj.emit('willduplicate');
      const newObj = this.loadObject(obj.export() as SketchObjectExport);
      newObj.name = `${obj.name} copy`;
      // move object
      newObj.handleDragStart(event);
      event.deltaX = 12;
      event.deltaY = 12;
      newObj.handleDrag(event);
      newObj.handleDragEnd(event);
    });
    this.emit('update');
    this.emit('addobject', obj);
  }

  remove(obj: SketchObject) {
    const idx = this.objects.findIndex(o => o === obj);
    if (idx >= 0) {
      if (obj.selected) {
        obj.setSelected(false);
        this.emit('deselect', obj);
      }
      obj.destroy();
      this.objects.splice(idx, 1);
      obj.parent = null;
      this.emit('update');
      this.emit('removeobject', obj);
    }
  }

  clear() {
    for (let i = this.objects.length - 1; i >= 0; i--) {
      const obj = this.objects[i];
      this.remove(obj);
    }
  }

  getSelected() {
    return this.objects.filter(obj => obj.selected);
  }

  reorderObjects(from: number, to: number) {
    const [obj] = this.objects.splice(from, 1);
    this.objects.splice(to, 0, obj);
    this.draw();
  }

  getObjects() {
    return [...this.objects];
  }

  select(obj: SketchObject) {
    obj.setSelected(true);
    this.emit('select', obj);
  }

  deselect() {
    for (const obj of this.objects) {
      const v = obj.selected;
      obj.setSelected(false);
      if (v) {
        this.emit('deselect', obj);
      }
    }
  }

  deselectObject(obj: SketchObject) {
    const v = obj.selected;
    obj.setSelected(false);
    if (v) {
      this.emit('deselect', obj);
    }
  }

  drawRotateButton(): void {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    const { x, y, width, height } = this.getBoundingRect();
    ctx.translate(x + width / 2, y + height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(x + width / 2), -(y + height / 2));
    const btnWidth = 24;
    const bx = x + width / 2 - btnWidth / 2;
    const by = y - 12 - btnWidth;
    ctx.drawImage(rotateButtonImage, bx, by);
    ctx.restore();
  }

  drawResizeButton(): void {
    const ctx = this.canvas!.getContext('2d')!;
    const { x, y, width, height } = this.getBoundingRect();
    ctx.save();
    ctx.translate(x + width / 2, y + height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(x + width / 2), -(y + height / 2));
    const btnWidth = 24;
    const bx = x + width + btnWidth / 2;
    const by = y - 12 - btnWidth;
    ctx.drawImage(resizeButtonImage, bx, by);
    ctx.restore();
  }

  drawDuplicateButton(): void {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    const { x, y, width, height } = this.getBoundingRect();
    ctx.translate(x + width / 2, y + height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(x + width / 2), -(y + height / 2));
    const btnWidth = 24;
    const bx = x + width + 12;
    const by = y + height / 2 - btnWidth / 2;
    ctx.drawImage(duplicateButtonImage, bx, by);
    ctx.restore();
  }

  private getBoundingRect() {
    const [minX, maxX] = this.getMinMaxCoordsX(this.objects);
    const [minY, maxY] = this.getMinMaxCoordsY(this.objects);
    const width = maxX - minX;
    const height = maxY - minY;
    return {
      x: minX,
      y: minY,
      width,
      height,
    };
  }

  private getMinMaxCoordsX(objects: SketchObject[]) {
    if (!this.canvas || !this.objects.length) return [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY];
    const xValues: number[] = [];
    for (const obj of objects) {
      if (obj instanceof Rect || obj instanceof SketchImage) {
        xValues.push(obj.x);
        xValues.push(obj.x + obj.width);
      }
      if (obj instanceof Ellipse) {
        xValues.push(obj.x - obj.radiusX);
        xValues.push(obj.x + obj.radiusX);
      }
      if (obj instanceof Drawing) xValues.push(...obj.points.map(([x]) => x));
      if (obj instanceof SketchGroup) xValues.push(this.getMinMaxCoordsX(obj.objects)[0]);
    }
    const minX = Math.min(...xValues);
    const maxX = Math.max(...xValues);
    return [minX, maxX];
  }

  private getMinMaxCoordsY(objects: SketchObject[]) {
    if (!this.canvas) return [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY];
    const yValues: number[] = [];
    for (const obj of objects) {
      if (obj instanceof Rect || obj instanceof SketchImage) {
        yValues.push(obj.y);
        yValues.push(obj.y + obj.height);
      }
      if (obj instanceof Ellipse) {
        yValues.push(obj.y - obj.radiusY);
        yValues.push(obj.y + obj.radiusY);
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      if (obj instanceof Drawing) yValues.push(...obj.points.map(([x, y]) => y));
      if (obj instanceof SketchGroup) yValues.push(this.getMinMaxCoordsX(obj.objects)[1]);
    }
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);
    return [minY, maxY];
  }
}

export default SketchGroup;
