import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import useClaimPhotos from '../hooks/claimPhotos';
import { useAuth } from '../hooks/authContext';
import useUpdateCtPhotosPositions from '../hooks/updateCtPhotosPositions';
import { ClaimPhotosType } from '../graphql/GetClaimPhotosList';

export interface Props {
  claimIndx: number
  onDidDismiss: () => any
}

export const ReorderSiteInspectionImagesModal: React.FC<Props> = ({ claimIndx, onDidDismiss }) => {
  const { t } = useTranslation();
  const { userState } = useAuth()!;
  const region = userState.userAuthPayload?.regionId;
  const { claimPhotosList } = useClaimPhotos(region!, claimIndx, userState);
  const { mutateAsync } = useUpdateCtPhotosPositions(userState);
  const [isiPhotos, setIsiPhotos] = useState<ClaimPhotosType[]>([]);

  useEffect(() => {
    if (claimPhotosList.length > 0) {
      setIsiPhotos(claimPhotosList.filter(photo => photo.includedInIsi));
    }
  }, [claimPhotosList]);

  const handleReorder = async (event: any) => {
    const { from, to } = event.detail;
    const [obj] = isiPhotos.splice(from, 1);
    isiPhotos.splice(to, 0, obj);
    event.detail.complete();
    await mutateAsync({
      claimIndx,
      ctPhotoPositions: isiPhotos.map((photo, position) => ({ ctfileId: photo.ctfileId, position })),
    });
  };

  return (
    <IonModal isOpen={claimIndx > 0} onDidDismiss={onDidDismiss} >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('reorderImages').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDidDismiss}>{t('ok').toString()}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
          {!!isiPhotos?.length && isiPhotos?.map((photo) => (<IonItem key={photo.ctfileId}>
            <IonThumbnail slot="start">
              <img alt={photo.description} src={photo.filePath} />
            </IonThumbnail>
            <IonLabel>
              <h3>{`${photo.fileName}.${photo.fileExtension}`}</h3>
              <p>{format(new Date(photo.uploadedDate), "yyyy-MM-dd hh:mm aaaaa'm'")}</p>
            </IonLabel>
            <IonReorder slot="end" />
          </IonItem>))}
          {!isiPhotos?.length && <p className="ion-text-center">{t('noPhotos').toString()}</p>}
        </IonReorderGroup>
      </IonContent>
    </IonModal>
  );
};

export default ReorderSiteInspectionImagesModal;
