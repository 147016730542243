/* eslint-disable react/no-array-index-key */
import React from 'react';
import { IonItem, IonNote, IonSelect, IonSelectOption, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './FosCertificateSelectItem.css';

export type SelectOptionProps = React.ComponentProps<typeof IonSelectOption> & { label: string };

export interface Props {
  label: string;
  options: SelectOptionProps[];
  name?: string;
  error?: string;
  onChange?: (event: any) => any;
  onIonChange?: (event: any) => any;
  asterisk?: boolean;
  disableMarginBottom?: boolean;
  altBorder?: boolean;
  placeholder?: any;
}

const FosCertificateSelectItem = React.forwardRef<HTMLIonSelectElement, Props>(
  ({ onChange, name, label, error, options, asterisk, disableMarginBottom, altBorder, placeholder }) => {
    const { t } = useTranslation();
    const classes = ['fos-label-value-item-style'];
    if (!disableMarginBottom) {
      classes.push('ion-margin-bottom');
    }
    classes.push(altBorder ? 'form-border-color' : 'fos-border');
    const marginClass = classes.join(' ');


    return (
      <IonItem lines="none" className={marginClass}>
        <div className="label-container">
          <IonLabel className="fos-certificate-select-label" slot="start">
            {label}
          </IonLabel>
          {asterisk && <span className="asterisk">*</span>}
        </div>{' '}
        <IonSelect
          className="fos-select-item-label max-width-fix"
          slot="end"
          name={name}
          placeholder={placeholder || t('choose')}
          okText={t('ok')}
          cancelText={t('cancel')}
          onIonChange={onChange}
        >
          {options.map((optionProps, idx) => (
            <IonSelectOption key={idx} {...optionProps}>
              {optionProps.label}
            </IonSelectOption>
          ))}
        </IonSelect>
        {error && <IonNote slot="error">{error}</IonNote>}
      </IonItem>
    );
  },
);

export default FosCertificateSelectItem;
