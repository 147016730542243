import { gql } from '@apollo/client';

const GET_SAFETY_INFO = gql`
query inspectionSafety($claimId: String) {
  inspectionSafety(claimId: $claimId)
  {
    id 
    claimId 
    crawlspace
    demolished
    hazards
    correctiveActions
    heights
    reportGenerated
    reportUploaded
    utility
    yearOfConstruction
  }
}`;

export default GET_SAFETY_INFO;