import { useContext, useEffect, useState } from 'react';
import { UserState } from './authUtils';
import { DomainParams } from './graphQLClientUtil';
import GET_BADGE_INFO, { BadgeInfo } from '../graphql/GetBadgeInfo';
import useBaseQuery from './baseQuery';
import badgeKeys from './badgeKeys';
import NetworkContext from '../contexts/NetworkContext';
import { getBadgeInfo, storeBadgeInfo } from '../pages/helper/offlineHelper/BadgeInfoHelper';

const useGetDigitalBadgeInfo = (region: number, userState: UserState, variables: any) => {
  const network = useContext(NetworkContext);
  const [badgeInfo, setBadgeInfo] = useState<BadgeInfo>();

  const onSuccess = async (data: any) => {
    const info = data.digitalBadgeInfo || {};
    setBadgeInfo(info);
    await storeBadgeInfo(info);
  };

  const { isLoading, refetch } = useBaseQuery(
    region,
    variables,
    userState,
    network.connected,
    GET_BADGE_INFO,
    badgeKeys.badgeInfo(),
    DomainParams.Employees,
    onSuccess,
  );

  useEffect(() => {
    if (network.connected) return;
    const getAsync = async () => {
      const result = await getBadgeInfo();
      if (result?.data) setBadgeInfo(result.data);
    };
    getAsync();
  }, [network.connected]);

  return { badgeInfo, isLoading, refetch };
};

export default useGetDigitalBadgeInfo;