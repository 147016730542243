import { format } from 'date-fns';

export const DATE_FORMAT_MM_DD_YYYY_HH_MM = 'MM/dd/yyyy HH:mm';
export const DATE_FORMAT_MM_DD_DASH_YYYY_HH_MM = 'MM/dd/yyyy - HH:mm';
export const DATE_FORMAT_MM_DD_YYYY = 'MM/dd/yyyy';

const isValidDate = (d: object):boolean => d instanceof Date && !Number.isNaN(d.getTime());

const handleDateFormat = (selectedDate: string, selectedFormat: string = DATE_FORMAT_MM_DD_YYYY_HH_MM): string => {
  const date = new Date(selectedDate);
  if (!isValidDate(date)) return selectedDate;
  return format(date, selectedFormat);
};
export default handleDateFormat;