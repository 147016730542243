import { useIonActionSheet, useIonAlert, useIonRouter } from '@ionic/react';
import { t } from 'i18next';
import useDeleteExpense from '../hooks/deleteExpense';
import { useAuth } from '../hooks/authContext';
import { ApiStatus } from '../pages/helper/Const';

const FosEditExpenseActionSheet = () => {
  const { userState } = useAuth()!;
  const [presentAlert] = useIonAlert();
  const { mutateAsync: deleteExpense } = useDeleteExpense(userState);

  const [present] = useIonActionSheet();
  const navigation = useIonRouter();
  enum EditExpenseAction {
    RESET = 'RESET',
    RELEASE = 'RELEASE',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    CANCEL = 'Cancel',
  }
  enum ExpenseMethod {
    MILEAGE = 3,
    OUT_OF_POCKET = 2,
    CREDIT_CARD = 1,
  }
  const expensesActionSheetButtons = [
    {
      text: t('edit'),
      data: {
        action: EditExpenseAction.EDIT,
      },
    },
    {
      text: t('delete'),
      data: {
        action: EditExpenseAction.DELETE,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: EditExpenseAction.CANCEL,
      },
    },
  ];
  const handleError = (err: string) => {
    presentAlert({
      header: t('warning'),
      message: err,
    });
  };

  const deleteExpenseInternal = async (expenseId: number) => {
    if (expenseId) {
      window.location.reload();
      return;
      const result = await deleteExpense(+expenseId);
      if (result?.deleteExpense?.status === ApiStatus.FAILURE) {
        handleError(result?.deleteExpense?.message);
      } else {
        window.location.reload();
      }
    }
  };

  const handleClick = (expenseId: string, expenseMethodId: number) => {
    present({
      header: 'Expense',
      cssClass: 'custom-action-sheet',
      buttons: expensesActionSheetButtons,
      onDidDismiss: ({ detail }) => {
        switch (detail.data?.action) {
          case EditExpenseAction.EDIT: {
            if (expenseMethodId === ExpenseMethod.MILEAGE) {
              navigation.push(`/mileage-expense/${expenseId}`);
            } else if (expenseMethodId === ExpenseMethod.CREDIT_CARD) {
              navigation.push(`/credit-card-expense/${expenseId}`);
            } else {
              navigation.push(`/expense/${expenseId}`);
            }

            break;
          }
          case EditExpenseAction.DELETE: {
            presentAlert({
              header: t('confirm'),
              message: t('confirmDeleteExpense'),
              buttons: [
                t('cancel'),
                {
                  handler: () => deleteExpenseInternal(Number(expenseId)),
                  text: t('delete'),
                },
              ],
            });

            break;
          }
          case EditExpenseAction.CANCEL:
          default: break;
        }
      },
    });
  };
  return { handleClick };
};

export default FosEditExpenseActionSheet;