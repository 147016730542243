/* eslint-disable @typescript-eslint/no-unused-vars */
import EventEmitter from 'events';
import { type Sketch } from './sketch-tool';
import { type SketchGroup } from './group';

export enum SketchTool {
  Rect = 'rect',
  Ellipse = 'ellipse',
  Text = 'text',
  Move = 'move',
  Img = 'img',
  Pen = 'pen',
  LibObject = 'libObject',
  Wall = 'wall',
  Sel = 'sel',
}

export enum SystemOfMeasurement {
  Metric = 'M',
  Imperial = 'I',
}

export enum SketchObjectType {
  Rect = 'rect',
  Ellipse = 'ellipse',
  Image = 'image',
  Text = 'text',
  Drawing = 'drawing',
  Wall = 'wall',
  Group = 'group',
}

export interface ObjectConfig {
  id?: number;
  name?: string;
  canvas?: HTMLCanvasElement | null;
  selected?: boolean;
  showDimensions?: boolean;
  showName?: boolean;
  showSquareFootage?: boolean;
  visible?: boolean;
  systemOfMeasurement?: SystemOfMeasurement;
  meterToPixels?: number;
  feetToPixels?: number;
  rotation?: number;
  unsaved?: boolean;
}

export interface ObjectExport extends Omit<ObjectConfig, 'canvas' | 'selected'> { }

export interface ScaleConfig {
  systemOfMeasurement: SystemOfMeasurement;
  feetToPixels: number;
  meterToPixels: number;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export abstract class SketchObject extends EventEmitter {
  id: number;

  name: string;

  canvas: HTMLCanvasElement | null;

  selected = false;

  showDimensions: boolean;

  showName: boolean;

  showSquareFootage: boolean;

  visible: boolean;

  systemOfMeasurement: SystemOfMeasurement;

  meterToPixels: number;

  feetToPixels: number;

  rotation: number;

  dragging: boolean = false;

  rotating: boolean = false;

  resizing: boolean = false;

  parent: SketchGroup | null = null;

  unsaved?: boolean;

  private rotationStart?: number;

  static getMeasureUnit(systemOfMeasurement: SystemOfMeasurement) {
    return systemOfMeasurement === SystemOfMeasurement.Imperial ? "'" : 'm';
  }

  constructor(config: ObjectConfig) {
    super();
    const {
      id = 0,
      name = '',
      canvas = null,
      selected = false,
      showDimensions = false,
      showName = false,
      showSquareFootage = false,
      visible = true,
      systemOfMeasurement = SystemOfMeasurement.Imperial,
      feetToPixels = 20,
      meterToPixels = 65,
      rotation = 0,
      unsaved,
    } = config;
    this.id = id;
    this.name = name;
    this.selected = selected;
    this.showDimensions = showDimensions;
    this.showName = showName;
    this.showSquareFootage = showSquareFootage;
    this.visible = visible;
    this.systemOfMeasurement = systemOfMeasurement;
    this.feetToPixels = feetToPixels;
    this.meterToPixels = meterToPixels;
    this.canvas = canvas;
    this.rotation = rotation;
    this.unsaved = unsaved;
    this.on('dragstart', () => { this.dragging = true; });
    this.on('dragend', () => { this.dragging = false; });
    this.on('resizestart', () => { this.resizing = true; });
    this.on('resizeend', () => { this.resizing = false; });
    this.on('rotatestart', () => { this.handleRotateStart(); });
    this.on('rotate', (event) => { this.handleRotate(event); });
    this.on('rotateend', () => { this.handleRotateEnd(); });
  }

  setCanvas(canvas: HTMLCanvasElement | null) {
    this.canvas = canvas;
    this.emit('update');
  }

  setVisible(visible: boolean) {
    this.visible = visible;
    this.emit('update');
  }

  setSelected(selected: boolean) {
    this.selected = selected;
    this.emit('update');
  }

  setShowDimensions(showDimensions: boolean) {
    this.showDimensions = showDimensions;
    this.emit('update');
  }

  setShowName(showName: boolean) {
    this.showName = showName;
    this.emit('update');
  }

  setShowSquareFootage(showSquareFootage: boolean) {
    this.showSquareFootage = showSquareFootage;
    this.emit('update');
  }

  setName(name: string) {
    this.name = name;
    this.emit('update');
  }

  setSystemOfMeasurement(systemOfMeasurement: SystemOfMeasurement) {
    this.systemOfMeasurement = systemOfMeasurement;
    this.emit('update');
  }

  setFeetToPixels(feetToPixels: number) {
    this.feetToPixels = feetToPixels;
    this.emit('update');
  }

  setMeterToPixels(meterToPixels: number) {
    this.meterToPixels = meterToPixels;
    this.emit('update');
  }

  setRotation(rotation: number) {
    this.rotation = rotation;
    this.emit('update');
  }

  destroy() {
    this.setCanvas(null);
    this.removeAllListeners();
  }

  export(): ObjectExport {
    return {
      id: this.id,
      name: this.name,
      showDimensions: this.showDimensions,
      showName: this.showName,
      showSquareFootage: this.showSquareFootage,
      visible: this.visible,
      systemOfMeasurement: this.systemOfMeasurement,
      meterToPixels: this.meterToPixels,
      feetToPixels: this.feetToPixels,
      rotation: this.rotation,
    };
  }

  getSketch(): Sketch | null {
    let sketch: SketchObject = this;
    while (sketch.parent) {
      sketch = sketch.parent;
    }
    return ('activeTool' in sketch) ? sketch as Sketch : null;
  }

  handleRotateStart() {
    this.rotating = true;
    this.rotationStart = this.rotation;
  }

  handleRotate(event: HammerInput) {
    this.setRotation(this.rotationStart! + event.deltaX);
  }

  handleRotateEnd() {
    this.rotating = false;
  }

  isPartOfGroup(): boolean {
    return !!this.parent && this.parent !== this.getSketch();
  }

  getAmountFormatted(amount: number) {
    const { systemOfMeasurement, feetToPixels, meterToPixels } = this;
    const scaleInPixels = systemOfMeasurement === SystemOfMeasurement.Imperial
      ? feetToPixels
      : meterToPixels;
    const value = amount / scaleInPixels;
    return systemOfMeasurement === SystemOfMeasurement.Metric
      ? this.formatMeters(value)
      : this.formatFeet(value);
  }

  formattedDimensionToDecimalFeet(measurement: string): number {
    const [feet, inchesPart] = measurement.split('ft');
    const [inches] = inchesPart.split('in');
    const trimmedFeet = feet.trim();
    const trimmedInches = inches.trim();
    const decimalFeet = parseFloat(trimmedFeet) + parseFloat(trimmedInches) / 12;
    return decimalFeet;
  }

  formattedDimensionToDecimalMeters(measurement: string): number {
    const [meters, centimetersPart] = measurement.split('m');
    const [centimeters] = centimetersPart.split('cm');
    const trimmedMeters = meters.trim();
    const trimmedCentimeters = centimeters.trim();
    const decimalMeters = parseFloat(trimmedMeters) + parseFloat(trimmedCentimeters) / 100;
    return decimalMeters;
  }

  formattedDimensionToPixels(measurement: string) {
    if (this.systemOfMeasurement === SystemOfMeasurement.Imperial) {
      const feets = this.formattedDimensionToDecimalFeet(measurement);
      return feets * this.feetToPixels;
    }
    const meters = this.formattedDimensionToDecimalMeters(measurement);
    return meters * this.meterToPixels;
  }

  getImperialParts(measurement: string) {
    const [feets, inchesPart] = measurement.split('ft');
    const [inches] = inchesPart.split('in');
    const fts = Number.isNaN(+feets) ? 0 : +feets;
    const ins = Number.isNaN(+inches) ? 0 : +inches;
    return [fts, ins];
  }

  getMetricParts(measurement: string) {
    const [meters, centimetersPart] = measurement.split('m');
    const [centimeters] = centimetersPart.split('c');
    const mts = Number.isNaN(+meters) ? 0 : +meters;
    const cms = Number.isNaN(+centimeters) ? 0 : +centimeters;
    return [mts, cms];
  }

  private formatFeet(amountInFeet: number) {
    const wholeFeet = Math.floor(amountInFeet);
    const fractionalFeet = amountInFeet - wholeFeet;
    const inches = Math.round(fractionalFeet * 12);
    let formattedString = '';
    if (wholeFeet > 0) {
      formattedString += ` ${wholeFeet} ft`;
    }

    if (inches > 0) {
      formattedString += ` ${inches} in`;
    }

    if (formattedString === '') {
      formattedString = '0 ft';
    }

    return formattedString;
  }

  formatMeters(amountInMeters: number) {
    const wholeMeters = Math.floor(amountInMeters);
    const fractionalMeters = amountInMeters - wholeMeters;
    const centimeters = Math.round(fractionalMeters * 100);
    let formattedString = '';
    if (wholeMeters > 0) {
      formattedString += ` ${wholeMeters} m`;
    }

    if (centimeters > 0) {
      formattedString += ` ${centimeters} cm`;
    }

    if (formattedString === '') {
      formattedString = '0 m';
    }

    return formattedString;
  }

  isObjectContainedByRect(innerRect: Rect, outerRect: Rect) {
    const innerRight = innerRect.x + innerRect.width;
    const innerBottom = innerRect.y + innerRect.height;

    const outerRight = outerRect.x + outerRect.width;
    const outerBottom = outerRect.y + outerRect.height;
    return (
      innerRect.x >= outerRect.x && 
      innerRect.y >= outerRect.y && 
      innerRight <= outerRight && 
      innerBottom <= outerBottom
    );
  }

  abstract isContainedByRect(outerRect: Rect): boolean;

  abstract handleResizeStart(event: HammerInput): void;

  abstract handleResize(event: HammerInput): void;

  abstract handleResizeEnd(event: HammerInput): void;

  abstract draw(): void;

  abstract drawName(): void;

  abstract drawSquareFootage(): void;

  abstract drawDimensions(): void;

  abstract drawSelection(): void;

  abstract drawRotateButton(): void;

  abstract drawResizeButton(): void;

  abstract drawDuplicateButton(): void;

  abstract isPointInsideObject(x: number, y: number): boolean;

  abstract isPointInsideRotationButton(x: number, y: number): boolean;

  abstract isPointInsideResizeButton(x: number, y: number): boolean;

  abstract isPointInsideDuplicateButton(x: number, y: number): boolean;

  abstract handleDragStart(event: HammerInput): void;

  abstract handleDrag(event: HammerInput): void;

  abstract handleDragEnd(event: HammerInput): void;
}
