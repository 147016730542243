import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { Variables } from 'graphql-request';
import { DocumentNode } from 'graphql';
import { isPlatform, useIonRouter } from '@ionic/react';
import { UserState } from './authUtils';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import { HYBRID } from './photo';

// doesn't use cache
const useBaseQuery = (
  region: number,
  variables: any,
  userState: UserState,
  enabled: boolean,
  baseGraphqlQuery: DocumentNode,
  baseKey: string[],
  domainParam?: DomainParams,
  onSuccess?: (data: any) => void,
  retry: boolean = true,
) => {
  const { checkTokenExpiration } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState, domainParam);
  const localEnabled = enabled && userState.userAuthPayload?.accessToken !== undefined;
  const navigation = useIonRouter();
  const variablesBase: Variables = {
    regionId: region,
    ...variables,
  };

  const onError = async (error: any) => {
    if (error.response.status === 401) {
      const currentPath = navigation.routeInfo.pathname;
      if (navigation && currentPath !== '/login' && !isPlatform(HYBRID)) {
        navigation.push('/login');
      }
    }
  };

  const baseQuery = useQuery({
    queryKey: [...baseKey, variablesBase],
    queryFn: async (ctx: QueryFunctionContext) => {
      await checkTokenExpiration();
      const result = await graphQLClient.request(baseGraphqlQuery, ctx.queryKey[1]);
      return result;
    },
    enabled: localEnabled,
    onSuccess,
    onError,
    retry,
    refetchOnWindowFocus: false,
  });

  const { data, error, isLoading, isFetching, status, refetch } = baseQuery;

  if (error) window.console.error(error);
  return { data, error, isLoading, isFetching, status, refetch };
};

export default useBaseQuery;