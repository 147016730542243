/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { IonAvatar, IonIcon, IonLabel, IonLoading } from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import * as faceapi from 'face-api.js';
import { t } from 'i18next';
import { User } from '../graphql/GetUser';
import './UserProfile.css';
import { getDataFromPhoto, loadSaved, savePicture, UserPhoto } from '../hooks/photo';
import { useAuth } from '../hooks/authContext';
import useUserProfileInfo from '../hooks/userProfileInfo';
import { GUID } from '../graphql/GetUserProfileInfo';
import FosCachedImage from '../components/FosCachedImage';

type StyleType = {
  container: CSSProperties;
  icon: CSSProperties;
  title: CSSProperties;
  row: CSSProperties;
  rows: CSSProperties;
  divIcon: CSSProperties;
  divText: CSSProperties;
  divTextLimit: CSSProperties;
  picture: CSSProperties;
  label: CSSProperties;
  email: CSSProperties;
  separator: CSSProperties;
};

type Props = {
  user: User | undefined;
};

const styles: StyleType = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'black',
    marginRight: 27,
    marginLeft: 27,
  },
  divIcon: {
    overflow: 'hidden',
    position: 'relative',
    width: 162,
    height: 162,
    marginBottom: 16,
  },
  divText: {
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    background: 'black',
    width: 151,
    zIndex: 20,
    textAlign: 'center',
    bottom: 0,
  },
  divTextLimit: {
    position: 'absolute',
    bottom: 4,
  },
  icon: {
    height: 170,
    width: 170,
    position: 'absolute',
    bottom: -14,
    left: 0,
  },
  picture: {
    position: 'absolute',
  },
  title: {
    fontSize: 22,
    fontWeight: 600,
  },
  rows: {
    width: '100%',
    marginTop: 16,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 5,
    marginBottom: 5,
  },
  label: {
    color: '#CBCDD4',
    fontWeight: 700,
    marginBottom: 6,
    fontSize: 15,
  },
  email: {
    color: '#F7921E',
    fontSize: 18,
  },
  separator: {
    width: '100%',
    borderTop: '1px solid #CBCDD4',
  },
};

const UserProfile: FC<Props> = ({ user }) => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const { userInfo, isLoading } = useUserProfileInfo(userState, regionId, window.localStorage.getItem(GUID) as string);

  const [userPicture, setUserPicture] = useState({
    filepath: '',
    webviewPath: '',
  } as UserPhoto);

  const addNewToGallery = async () => {
    // Take a photo
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      width: 238,
      height: 313,
      quality: 100,
    });

    try {
      // load image to detect face
      const photoData = await getDataFromPhoto(capturedPhoto);
      const imageElement = document.createElement('img');
      imageElement.src = photoData;
      const detections = await faceapi.detectAllFaces(imageElement);
      // image doesn't contain any face
      if (!detections || detections.length === 0) {
        // eslint-disable-next-line no-alert
        alert('No Face Detected');
        return;
      }
    } catch (ex) {
      console.log(ex);
    }

    // save image to local device
    const fileName = `${new Date().getTime()}.jpeg`;
    const savedImageFile = await savePicture(capturedPhoto, fileName);
    setUserPicture(savedImageFile);
  };

  useEffect(() => {
    const loadSavedPicture = async () => {
      try {
        const loadImage = await loadSaved();
        setUserPicture(loadImage);
      } catch (error) {
        // noop
      }
    };
    const loadModules = async () => {
      if (!faceapi.nets.ssdMobilenetv1 || !faceapi.nets.ssdMobilenetv1.isLoaded) {
        await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
      }
    };
    loadModules();
    loadSavedPicture();
  }, [isLoading]);

  return (
    <div style={styles.container}>
      {userPicture.webviewPath ? (
        <IonAvatar style={styles.divIcon}>
          <FosCachedImage src={userPicture.webviewPath} className='profile-picture' style={styles.picture} onClick={addNewToGallery} />
        </IonAvatar>
      ) : (
        <div style={styles.divIcon}>
          <IonIcon icon={personCircle} style={styles.icon} className="profile-picture-placeholder" onClick={addNewToGallery} />
        </div>
      )}
      <IonLabel style={styles.title} className="username">{user?.fullName}</IonLabel>
      <IonLabel style={styles.email}>{`${user?.username}@firstonsite.ca`}</IonLabel>
      <hr style={styles.separator} />
      {!isLoading &&
        <div style={styles.rows} className="profile-content">
          {userInfo?.regionName &&
            <div style={styles.row}>
              <IonLabel className="profile-heading" style={styles.label}>{t('profileInfoRegion').toString()}</IonLabel>
              <IonLabel>{userInfo?.regionName}</IonLabel>
            </div>
          }
          {userInfo?.branchName &&
            <div style={styles.row}>
              <IonLabel className="profile-heading" style={styles.label}>{t('profileInfoHomeBranch').toString()}</IonLabel>
              <IonLabel>{userInfo?.branchName}</IonLabel>
            </div>
          }
          {userInfo?.ctUserName &&
            <div style={styles.row}>
              <IonLabel className="profile-heading" style={styles.label}>{t('profileInfoCTUser').toString()}</IonLabel>
              <IonLabel>{userInfo?.ctUserName}</IonLabel>
            </div>
          }
          {userInfo?.phoneCell &&
            <div style={styles.row}>
              <IonLabel className="profile-heading" style={styles.label}>{t('profileInfoPhone').toString()}</IonLabel>
              <IonLabel>{userInfo?.phoneCell}</IonLabel>
            </div>
          }
        </div>
      }
      <IonLoading isOpen={isLoading} message={t('loadingProfileInfo')} duration={1} />
    </div>
  );
};

export default UserProfile;
