import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import DECLINE_EXPENSE from '../graphql/DeclineExpense';
import { GUID } from '../graphql/GetUserProfileInfo';

/*
* GraphQL call: decline Expense
* */
const useDeclineExpense = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState, DomainParams.Payroll);
  const userGuid = window.localStorage.getItem(GUID) as string;
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({ expenseId, reason }: { expenseId: number, reason: string }) => graphQLClient.request(DECLINE_EXPENSE, { userGuid, expenseId, reason }),
  });
  return { isLoading, mutateAsync };
};

export default useDeclineExpense;