/* eslint-disable react/no-array-index-key */
import React, { FC, useState } from 'react';
import {
  IonContent,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonReorderGroup,
  ItemReorderEventDetail,
} from '@ionic/react';
import { t } from 'i18next';
import { Sketch, SketchObject } from '../sketch-tool';
import SketchToolObjectItem from './SketchToolObjectItem';

interface SketchToolLayersViewProps {
  sketch: Sketch | null;
  isOpen: boolean;
  onClose: (event: any) => any;
}

const SketchToolLayersView: FC<SketchToolLayersViewProps> = ({ sketch, isOpen, onClose }: SketchToolLayersViewProps) => {
  const [objects, setObjects] = useState<SketchObject[]>([]);

  const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const { from, to } = event.detail;
    sketch!.reorderObjects(from, to);
    setObjects([...sketch!.getObjects()]);
    event.detail.complete();
  };

  const handlePresent = () => {
    setObjects(sketch?.getObjects() || []);
  };

  return (
    <IonModal
      isOpen={isOpen}
      initialBreakpoint={0.75}
      onIonModalWillPresent={handlePresent}
      onIonModalDidDismiss={onClose}
      breakpoints={[0, 0.25, 0.5, 0.75, 1]}>
      <IonContent className="ion-padding">
        <IonList>
          <IonListHeader>
            <IonLabel>{t('objects').toString()}</IonLabel>
          </IonListHeader>
          <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
            {objects.map((obj, idx) => (<SketchToolObjectItem key={`${obj.name}_${idx}`} object={obj} />))}
          </IonReorderGroup>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default SketchToolLayersView;