import React, { CSSProperties, FC } from 'react';
import { isPlatform } from '@ionic/react';
import { ActiveOption } from '../pages/helper/ProfileMenuHelper';
import { ReactComponent as ProfileIcon } from '../assets/icons/ic_profile.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/ic_cog.svg';
import { ReactComponent as ExpenseIcon } from '../assets/icons/ic_expense.svg';
import { ReactComponent as IdIcon } from '../assets/icons/ic_id.svg';
import './Menu.css';
import { ProfileActiveOption } from '../pages/helper/Const';
import { HYBRID } from '../hooks/photo';
import useGetPayrollDetails from '../hooks/useGetPayrollDetails';
import { useAuth } from '../hooks/authContext';

export type { ActiveOption } from '../pages/helper/ProfileMenuHelper';

type StyleType = {
  topMenu: CSSProperties;
  iconId: CSSProperties;
  iconExpense: CSSProperties;
  iconSettings: CSSProperties;
  iconUser: CSSProperties;
  option: CSSProperties;
  activeOption: CSSProperties;
};


type Props = {
  activeOption: ActiveOption;
  onOptionPress: (option: ActiveOption) => void
};

const styles: StyleType = {
  topMenu: {
    display: 'flex',
  },
  iconId: {
    width: 18.67,
    height: 13.33,
  },
  iconExpense: {
    width: 16,
    height: 18.67,
  },
  iconSettings: {
    width: 18.67,
    height: 18.65,
  },
  iconUser: {
    width: 13.33,
    height: 18.65,
  },
  option: {
    width: 56,
    height: 56,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '2px solid #F7F7FB',
    display: 'flex',
    borderColor: '#F7F7FB',
    background: 'inherit',
  },
  activeOption: {
    borderColor: '#F6831D',
  },
};

const Menu: FC<Props> = ({ activeOption, onOptionPress }) => {
  const { userState } = useAuth()!;
  const { data: payrollDetails } = useGetPayrollDetails(userState);
  const isUserExpenseEnabled = payrollDetails?.payrollDetails?.userDetail?.allowExpenseEntry || false;
  // dev will allow app + web, prod will allow app only
  const isExpenseEnabled = isUserExpenseEnabled && (process.env.REACT_APP_ENV !== 'prod' || isPlatform(HYBRID));
  return (
    <div style={styles.topMenu}>
      <button 
        type="button"
        style={{ ...styles.option, ...(activeOption === ProfileActiveOption.Details ? styles.activeOption : {}) }}
        onClick={() => onOptionPress(ProfileActiveOption.Details)}
      >
        <ProfileIcon fill="none" className={activeOption === ProfileActiveOption.Details ? 'active-action' : 'menu-action-icon'}/>
      </button>
      <button 
        type="button"
        style={{ ...styles.option, ...(activeOption === ProfileActiveOption.Badge ? styles.activeOption : {}) }}
        onClick={() => onOptionPress(ProfileActiveOption.Badge)}
      >
        <IdIcon fill="none" className={activeOption === ProfileActiveOption.Badge ? 'active-action' : 'menu-action-icon'}/>
      </button>
      {isExpenseEnabled && <button 
        type="button"
        style={{ ...styles.option, ...(activeOption === ProfileActiveOption.Expenses ? styles.activeOption : {}) }}
        onClick={() => onOptionPress(ProfileActiveOption.Expenses)}
      >
        <ExpenseIcon fill="none" className={activeOption === ProfileActiveOption.Expenses ? 'active-action' : 'menu-action-icon'}/>
      </button>}
      <button 
        type="button"
        style={{ ...styles.option, ...(activeOption === ProfileActiveOption.Settings ? styles.activeOption : {}) }}
        onClick={() => onOptionPress(ProfileActiveOption.Settings)}
      >
        <SettingsIcon fill="none" className={activeOption === ProfileActiveOption.Settings ? 'active-action' : 'menu-action-icon'}/>
      </button>
    </div>
  );
};

export default Menu;
