import React, { useImperativeHandle, useRef } from 'react';

type Props = {
  onSelectFile: (ev: React.ChangeEvent<HTMLInputElement>) => void;
};

const DocumentUpload = React.forwardRef<any, Props>(({
  onSelectFile,
}, ref) => {
  const fileInput = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    fileUpload() {
      fileInput?.current?.click();
    },
  }));

  return (
    <input
      ref={fileInput}
      hidden
      multiple
      type="file"
      accept=".doc,.docx,.pdf,.txt,.xls,.xlsx"
      onChange={(event) => onSelectFile(event)}
    />
  );
});

export default DocumentUpload;
