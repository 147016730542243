import { useCallback, useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { ApiStatus, ClaimOnlineStatus } from './helper/Const';
import { useAuth } from '../hooks/authContext';
import useSafetyInfo from '../hooks/safetyInfo';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import { storeClaimSafety, useSaveSafetyMutation } from './helper/offlineHelper/ClaimSafetyHelper';
import NetworkContext from '../contexts/NetworkContext';
import { Phase } from '../types/Phases';
import { useClaimOffline } from '../contexts/ClaimOfflineContext';
import { getErrorMsg } from './helper/AppHelper';
import { GUID } from '../graphql/GetUserProfileInfo';

type SafetyProps = {
  claimId: string;
  claimYearOfConstruction: string;
  phases: Array<Phase>
  onSaveClick: () => any;
  mandatoryFieldsInvalid: (isValid: boolean) => void;
  isMandatoryFieldsInvalid: boolean;
  hasChangedMessageControl: (isChanged: boolean) => void;
};

type FormData = {
  claimId: string;
  id: number;
  yearOfConstruction: string;
  demolished: boolean;
  utility: boolean;
  crawlspace: boolean;
  heights: boolean;
  hazards: string;
  correctiveActions: string;
};

const SAFETY_REPORT_PATH = 'common/inspectionsafety/report/preview';

const SafetyViewModel = (claimId: string, claimYearOfConstruction: string, onSaveClick: () => any, mandatoryFieldsInvalid: (isValid: boolean) => void, isMandatoryFieldsInvalid: boolean) => {
  const { userState } = useAuth()!;
  const { i18n } = useTranslation();
  const [saveError, setSaveError] = useState('');
  const [safetyItemId, setSafetyItemId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [html, setHtml] = useState('');
  const [safetyInfo, setSafetyInfo] = useState<any>(null);
  const restClient = getRestClient(userState, ClientType.PLAIN);
  const network = useContext(NetworkContext);
  const [generateRepertMessage, setGenerateReportMessage] = useState('');
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [isYearOfConstructionValid, setIsYearOfConstructionValid] = useState(false);
  const { refresh } = useClaimOffline()!;
  const [hasChanged, setHasChanged] = useState(false);
  enum MandatoryFields {
    YearOfConstruction = 'yearOfConstruction',
  }
  const schema = yup.object({
    yearOfConstruction: yup.string().required(t('yearOfConstructionIsRequired')),
    demolished: yup.bool(),
    utility: yup.bool(),
    crawlspace: yup.bool(),
    heights: yup.bool(),
    hazards: yup.string(),
    correctiveActions: yup.string(),
  }).required();

  const { handleSubmit, control, setValue, trigger, formState: { errors, isValid } } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  useEffect(()=> {
    if (!isMandatoryFieldsInvalid && !isValid) {
      mandatoryFieldsInvalid(true);
    }
    if (isMandatoryFieldsInvalid && isValid) {
      mandatoryFieldsInvalid(false);
    }
  }, [isMandatoryFieldsInvalid, isValid, mandatoryFieldsInvalid]);

  const setValuesForForm = useCallback((_inspectionSafety: any) => {
    if (_inspectionSafety) {
      // Text areas
      setValue('hazards', DOMPurify.sanitize(_inspectionSafety?.hazards));
      setValue('correctiveActions', DOMPurify.sanitize(_inspectionSafety?.correctiveActions));

      // Checkbox
      setValue('demolished', _inspectionSafety?.demolished);
      setValue('utility', _inspectionSafety?.utility);
      setValue('crawlspace', _inspectionSafety?.crawlspace);
      setValue('heights', _inspectionSafety?.heights);

      // Year of Construction
      let value = '';
      if (_inspectionSafety?.yearOfConstruction !== null) {
        value = _inspectionSafety?.yearOfConstruction;
      } else if (claimYearOfConstruction !== '') {
        value = claimYearOfConstruction;
      }
      setValue('yearOfConstruction', value);
    }
  }, [claimYearOfConstruction, setValue]);

  useSafetyInfo(
    userState,
    claimId,
    safetyInfo,
    setSafetyInfo,
    undefined,
    setValuesForForm);

  /*
  * Mutation to save the form
  * */
  const { isLoading: isSaving, mutateAsync } = useSaveSafetyMutation();

  const formatForm = (formData: FormData) => {
    const info: any = { ...formData };
    info.claimId = claimId;
    if (safetyInfo?.id) {
      info.id = Number(safetyInfo?.id);
    } else if (safetyItemId) {
      info.id = Number(safetyItemId);
    }
    return info;
  };

  /* 
  * Submit form
  * */
  const onSubmit = handleSubmit(async (formData: FormData) => {
    const info = formatForm(formData);
    if (network.connected) {
      mutateAsync(info)
        .then(async (response) => {
          if (response?.saveInspectionSafety?.status === ApiStatus.SUCCESS) {
            setSafetyItemId(response.saveInspectionSafety.itemId);
            await storeClaimSafety({ info, claimIndx: claimId, status: ClaimOnlineStatus.AvailableOffline });
            return;
          }
          throw new Error(response.saveClaim?.message || t('saveSiteInfoError'));
        })
        .catch((e) => {
          const msg = getErrorMsg(e);
          const message = msg || t('saveSiteInfoError');
          setSaveError(message);
        });
    } else {
      await storeClaimSafety({ info, claimIndx: claimId, status: ClaimOnlineStatus.ToBeSynched });
      refresh();
    }
  });

  const buildPreview = async () => {
    setIsModalOpen(true);
    restClient.get(SAFETY_REPORT_PATH, {
      params: {
        regionId: userState.userAuthPayload?.regionId!,
        claimIndx: parseInt(claimId, 10),
        frenchVersion: i18n.language === 'fr',
        userGuid: window.localStorage.getItem(GUID),
      },
    }).then((res) => {
      setHtml(res.data);
    });
  };

  const onPreviewClick = async () => {
    await onSaveClick();
    await buildPreview();
  };

  const testMandatoryField = async (fieldName: MandatoryFields) => {
    await trigger(fieldName);
    if (errors[fieldName]) {
      switch (fieldName) {
        case MandatoryFields.YearOfConstruction:
          setIsYearOfConstructionValid(false);
          break;
        default:
          break;
      }
    } else {
      switch (fieldName) {
        case MandatoryFields.YearOfConstruction:
          setIsYearOfConstructionValid(true);
          break;
        default:
          break;
      }
    }
  };
  const onReportGenerated = () => {
    setGenerateReportMessage(t('reportGeneratedSuccesfully'));
  };

  const onReportError = () => {
    setGenerateReportMessage(t('errorGeneratingReport'));
  };
  const handleUserChange = () => {
    setHasChanged(true);
  };
  return {
    control,
    html,
    isModalOpen,
    isSaving,
    onPreviewClick,
    onSubmit,
    saveError,
    setIsModalOpen,
    setSaveError,
    onReportGenerated,
    onReportError,
    generateRepertMessage,
    setGenerateReportMessage,
    isGeneratingReport,
    setIsGeneratingReport,
    testMandatoryField,
    isYearOfConstructionValid,
    MandatoryFields,
    handleUserChange,
    hasChanged,
    trigger,
  };
};

export default SafetyViewModel;

export type { SafetyProps };