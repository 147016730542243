import { useState } from 'react';
import { UserState } from './authUtils';
import { GUID } from '../graphql/GetUserProfileInfo';
import { DomainParams } from './graphQLClientUtil';
import useBaseQuery from './baseQuery';
import GET_USER_YTD_MILEAGE from '../graphql/GetUserYtdMileage';

const useUserYtdMileage = (userState: UserState, region: number) => {
  const userGuid = window.localStorage.getItem(GUID) as string;

  const [userYtdMileage, setUserYtdMileage] = useState<number>();
  const enabled: boolean = !!userGuid;

  const onSuccess = async (data: any) => {
    const ytdMileage: number  = data?.loadYtdMileage || 0;
    setUserYtdMileage(ytdMileage);
  };

  const { isLoading, isFetching } = useBaseQuery(
    region,
    { regionId: region, userGuid },
    userState,
    enabled,
    GET_USER_YTD_MILEAGE,
    ['loadYtdMileage'],
    DomainParams.Payroll,
    onSuccess,
  );

  return { userYtdMileage, isLoading, isFetching };
};

export default useUserYtdMileage;
