import { gql } from '@apollo/client';

const DELETE_EXPENSE = gql`
mutation DeleteExpense($id: Int!) {
  deleteExpense(id: $id) {
    status
    itemId
  }
}`;

export default DELETE_EXPENSE;
