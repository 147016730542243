import { gql } from '@apollo/client';

export type User = {
  userGuid?: string;
  employeeId?: number;
  payrollId?: string;
  username?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  userCode?: string;
  title?: string;
  bbpin?: string;
  defaultRegionId?: number;
  corporateUser?: boolean;
  regionId?: number;
  branchId?: number;
  departmentId?: number;
  ctdepartmentId?: string;
  isAduser?: boolean;
  isProduction?: boolean;
  isHourly?: boolean;
  isPayroll?: boolean;
  isEmployee?: boolean;
  isCcq?: boolean;
  isSystemObject?: boolean;
  isAdtombstone?: boolean;
  adguid?: string;
  addirectoryEntry?: string;
  adenabled?: boolean;
  aduSnchanged?: number;
  enabled?: boolean;
  dateStamp?: string;
  dateModified?: string;
  financeReviewed?: boolean;
  userSignature?: string;
  managerGuid?: string;
  secondaryManagerGuid?: string;
  autoVac?: boolean;
  badgeNumber?: string;
  onCall?: boolean;
};

export type ClaimTrakAccessLevel = {
  id: number;
  value: string;
};

type MobileUserPermisions = {
  userGuid: string;
  permissions: string[];
  claimTrakAccessLevel: ClaimTrakAccessLevel;
};

export type GetUserResponse = {
  data: {
    userByUserName: User;
  };
};

export type GetMobileUserAccess = {
  data: {
    mobileUserAccess: MobileUserPermisions;
  };
};

export const GET_USER = gql`
  query UserByUserName($username: String) {
    userByUserName(username: $username) {
      fullName
      firstName
      lastName
      employeeId
      userGuid
      username
      title
      regionId
    }
  }
`;

export const GET_MOBILE_USER_ACCESS = gql`
  query mobileUserAccess($regionId: Int!, $userGuid: UUID!) {
    mobileUserAccess(regionId: $regionId, userGuid: $userGuid) {
      userGuid
      permissions
      claimTrakAccessLevel {
        id
        value
      }
    }
  }
  `;
