import { gql } from '@apollo/client';
import { UserSingleExpenseStatus } from './GetUserExpenseList';

export type UserSingleExpenseForApproval = {
  id: number,
  comments: string,
  merchant: string,
  totalAmount: number,
  isParent: boolean,
  status: UserSingleExpenseStatus,
  childExpenses: UserSingleExpenseForApproval[],
};

export type ChildExpenseForApprovalList = {
  expense: UserSingleExpenseForApproval;
};

export type UserExpenseObject = {
  expense: UserSingleExpenseForApproval,
  childExpenses: ChildExpenseForApprovalList[],
};

const GET_USER_EXPENSES_FOR_APPROVAL_LIST = gql`
query expensesToBeApproved ($userGuid: UUID!, $expenseMethodId: Int!, $statusId: Int) {
  expensesToBeApproved (employeeGuid: $userGuid, expenseMethodId: $expenseMethodId, statusId: $statusId) {
    expense {
      id
      comments
      merchant
      totalAmount
      isParent
      status {
        name
        id
      }     
    }
    childExpenses {
      expense {
          id
          comments
          merchant
          isParent
          totalAmount
          status {
              name
              id
          }
      }
    }
  }
}`;

export default GET_USER_EXPENSES_FOR_APPROVAL_LIST;