import React, { useRef, useState } from 'react';
import {
  IonActionSheet,
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
  IonList,
  IonModal,
  useIonAlert,
  IonHeader,
  IonButtons,
  IonToolbar,
} from '@ionic/react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CameraSource } from '@capacitor/camera';
import { TFunction } from 'i18next';
import './ExpBaseExpenseForm.css';
import FosCurrencyInput from '../components/FosCurrencyInput';
import { PROVINCES, CURRENCIES, FileType } from '../pages/helper/Const';
import { cameraTakePicture } from '../pages/helper/ClaimPhotosHelper';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
import FosDateTime from '../atom/FosDateTime';
import FosThumbItem from '../atom/FosThumbItem';
import DocumentUpload from '../atom/DocumentUpload';
import FosTextArea from '../atom/FosTextArea';
import { ExpenseReceiptDtoInput } from '../hooks/expSaveExpense';
import useExpenseReceiptFile from '../hooks/expReceiptFile';
import getFileType from '../pages/helper/FileType';
import openDocumentURL from '../pages/helper/Documents';
import { ExpenseMethod } from '../pages/ExpenseMethod';

export type ExpenseBaseFormData = {
  date: string
  creditCardNumber: string
  creditCardId?: string
  location: string
  currency: string
  totalAmount: string
  tip: string
  merchant: string
  receipts: File[]
  receiptsData: ExpenseReceiptDtoInput[]
};

export type ExpenseBaseFormErrors = {
  date?: string
  location?: string
  creditCardId?: string,
  creditCardNumber?: string,
  currency?: string
  totalAmount?: string
  tip?: string
  merchant?: string
  receipts?: string
  receiptsData?: string
  isParent?: string
  statusId?: string
};

export interface Props {
  expense: ExpenseBaseFormData
  errors: ExpenseBaseFormErrors,
  totalAmountReadOnly: boolean,
  expenseMethod: ExpenseMethod,
  editMode: boolean,
  onChange: (id: keyof ExpenseBaseFormData, value: any, data: ExpenseBaseFormData) => any
}

interface RouteParams {
  expenseId: string;
}

export function createBaseExp(): ExpenseBaseFormData {
  return {
    date: '',
    creditCardNumber: '',
    creditCardId: undefined,
    location: '',
    currency: '',
    totalAmount: '',
    tip: '',
    merchant: '',
    receipts: [],
    receiptsData: [],
  };
}

export function getBaseExpErrors(expense: ExpenseBaseFormData, t: TFunction) {
  const errs: ExpenseBaseFormErrors = {};
  if (!expense.date) errs.date = t('expenseDateIsRequired');
  if (!expense.location) errs.location = t('expenseLocationIsRequired');
  if (!expense.merchant) errs.merchant = t('expenseMerchantIsRequired');
  if (!expense.totalAmount) errs.totalAmount = t('expenseTotalAmountIsRequired');
  if (!expense.currency) errs.currency = t('expenseCurrencyIsRequired');

  return errs;
}

export const ExpenseBaseForm: React.FC<Props> = ({ expense, errors, totalAmountReadOnly, expenseMethod, editMode, onChange }) => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const [showActionSheetReceipts, setShowActionSheetReceipts] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [selectedFileType, setSelectedFileType] = useState<any | null>(null);
  const { expenseId } = useParams<RouteParams>();
  const fileUploadRef = useRef<any>(null);
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files: File[] = Array.from(e.target.files);
      const receipts = [files[0]];
      const exp: ExpenseBaseFormData = { ...expense, receipts };
      onChange('receipts', receipts, exp);
    }
    e.target.value = '';
  };

  const fileUploadClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.fileUpload();
    }
  };
  const getReceiptPhoto = async (source: CameraSource) => {
    const result = await cameraTakePicture(source);
    const receipts = [result.blobArray[0]];
    const exp: ExpenseBaseFormData = { ...expense, receipts };
    onChange('receipts', receipts, exp);
  };

  const handleCancelActionSheet = () => {
    setShowActionSheetReceipts(false);
  };

  const handleValueChange = (id: keyof ExpenseBaseFormData, value?: any) => {
    const exp: ExpenseBaseFormData = { ...expense };
    exp[id] = value;
    onChange(id, value, exp);
  };

  const removeReceipt = (receipt: any) => {
    if (expense.receipts?.length) {
      const receipts = expense.receipts.filter((r: any) => r.fileName !== receipt.fileName);
      const receiptsToDelete = expense.receipts.filter((r: any) => r.fileName === receipt.fileName);
      const receiptsData = ('id' in receipt) ? receiptsToDelete.map((r: any) => ({
        ...r,
        deleted: r.fileName === receipt.fileName ? true : r.deleted,
      })) : [];

      const exp: ExpenseBaseFormData = { ...expense, receipts, receiptsData };
      onChange('receipts', receipts, exp);
      onChange('receiptsData', receiptsData, exp);
    }
  };

  const handleRemoveReceipt = (receipt: File) => {
    presentAlert({
      header: t('confirm'),
      message: t('confirmDeleteReceipt'),
      buttons: [
        t('cancel'),
        {
          handler: () => {
            removeReceipt(receipt);
          },
          text: t('delete'),
        },
      ],
    });
  };

  const onSuccessDetail = (data: any) => {
    const filePath = data?.expenseReceiptFile?.[0]?.fileLink ?? null;
    setSelectedFile(filePath);
    setSelectedFileType(getFileType(data?.expenseReceiptFile?.[0]?.fileName));
  };

  useExpenseReceiptFile(+expenseId, onSuccessDetail);

  const handleReceiptClick = (receipt: File, receiptName: string | undefined) => {
    const isImage = receipt?.type?.startsWith('image/');
    const isDocument = receipt?.type === 'application/pdf' || receipt?.type?.startsWith('application/');
    if (receipt.size) {
      if (isImage) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          setSelectedFile(e.target?.result as string);
        };
        reader.readAsDataURL(receipt);
        setShowModal(true);
      } else if (isDocument) {
        const documentDataUrl = URL.createObjectURL(receipt);
        openDocumentURL(documentDataUrl);
      }
    } else if (selectedFileType === FileType.Image) {
      setShowModal(true);
    } else if (receiptName?.endsWith('pdf')) {
      setSelectedFileType(FileType.Document);
      setShowModal(true);
    } else {
      openDocumentURL(selectedFile);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <IonGrid className="ion-no-padding ion-no-margin">
      <IonRow className="ion-no-padding ion-margin-bottom">
        <IonCol>
          <FosDateTime
            disabled={expenseMethod === ExpenseMethod.CREDIT_CARD || !editMode}
            id="date"
            label={`${t('date')} *`}
            presentation='date'
            value={expense.date}
            showDefaultButtons
            onIonChange={(e) => {
              if (e.detail?.value) {
                handleValueChange('date', e.detail.value);
              }
            }}
            error={errors.date}
          />
        </IonCol>
      </IonRow>
      {expenseMethod === ExpenseMethod.CREDIT_CARD &&
        <IonRow>
          <IonCol>
            <FosTextArea disabled rows={1} value={`${t('creditCard').toString()}: ${expense.creditCardNumber}`} />
          </IonCol>
        </IonRow>}
      <IonRow>
        <IonCol>
          <FosSelectBigListItem
            required
            disabled={!editMode}
            id="location-base-expense-form"
            name="location-base-expense-form"
            value={`${expense.location}`}
            onChange={(val: string) => { handleValueChange('location', val); }}
            label={t('purchaseLocation')}
            options={PROVINCES}
            error={errors.location}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <FosTextArea disabled={expenseMethod === ExpenseMethod.CREDIT_CARD || !editMode} error={errors.merchant} rows={1} required value={expense.merchant} onIonChange={(event) => handleValueChange('merchant', event.detail.value)} placeholder={t('merchant')} />
        </IonCol>
      </IonRow>
      {!expense.receipts?.length && <IonRow>
        <IonCol>
          <IonButton fill="outline" expand="block" disabled={!editMode} onClick={() => setShowActionSheetReceipts(true)}>
            {t('addReceipt').toString()}
          </IonButton>
          {errors.receipts && <p className="ion-padding-start ion-no-margin">
            <small>
              <IonNote color="danger">{errors.receipts}</IonNote>
            </small>
          </p>}
        </IonCol>
      </IonRow>}
      <IonModal isOpen={showModal} onDidDismiss={closeModal} className="IonModalContent">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='end'>
              <IonButton onClick={closeModal}>X</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {selectedFileType === 'pdf' ?
          <img src={selectedFile || ''} alt="Receipt" style={{ width: '100%', maxHeight: '80vh', objectFit: 'contain' }} /> :
          <iframe src={selectedFile || ''} title="Receipt" style={{ width: '100%', height: '100%' }} />}
      </IonModal>
      <IonList style={{ 'z-index': 99999 }}>
        {editMode && !!expense.receipts?.length && expense.receipts.map((receipt: any) => (
          <FosThumbItem
            key={receipt.fileName ?? receipt.name}
            label={receipt.fileName ?? receipt.name}
            thumbSrc="assets/images/receipt.svg"
            onClick={() => handleReceiptClick(receipt, receipt.fileName ?? receipt.name)}
            onRemove={(event) => {
              event.stopPropagation();
              handleRemoveReceipt(receipt);
            }}
          />
        ))}
        {!editMode && !!expense.receipts?.length && expense.receipts.map((receipt: any) => (
          <FosThumbItem
            key={receipt.fileName ?? receipt.name}
            label={receipt.fileName ?? receipt.name}
            thumbSrc="assets/images/receipt.svg"
            onClick={() => handleReceiptClick(receipt, receipt.fileName ?? receipt.name)}
          />
        ))}
      </IonList>
      <IonRow className="ion-margin-top">
        <IonCol>
          <FosSelectBigListItem
            required
            id="currency-base-expense-form"
            name="currency-base-expense-form"
            value={`${expense.currency}`}
            onChange={(val: string) => { handleValueChange('currency', val); }}
            label={t('currency')}
            options={CURRENCIES}
            error={errors.currency}
            disabled={expenseMethod === ExpenseMethod.CREDIT_CARD || !editMode}
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-margin-top">
        <IonCol>
          <IonItem lines="none">
            <IonLabel>{`${t('totalAmount').toString()} *`}</IonLabel>
            <FosCurrencyInput disabled={totalAmountReadOnly || !editMode} value={expense.totalAmount} onChange={(val) => handleValueChange('totalAmount', val)} />
          </IonItem>
          {errors.totalAmount && <p className="ion-padding-start ion-no-margin ion-padding-bottom">
            <small>
              <IonNote color="danger">{errors.totalAmount}</IonNote>
            </small>
          </p>}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem lines="none">
            <IonLabel>{t('tip').toString()}</IonLabel>
            <FosCurrencyInput disabled={!editMode} error={errors.tip} value={expense.tip} onChange={(val) => handleValueChange('tip', val)} />
          </IonItem>
        </IonCol>
      </IonRow>
      <DocumentUpload onSelectFile={(ev) => onSelectFile(ev)} ref={fileUploadRef} />
      <IonActionSheet
        isOpen={showActionSheetReceipts}
        onDidDismiss={handleCancelActionSheet}
        buttons={[
          {
            text: t('camera'),
            handler: () => getReceiptPhoto(CameraSource.Camera),
          },
          {
            text: t('photos'),
            handler: () => getReceiptPhoto(CameraSource.Photos),
          },
          {
            text: t('documents'),
            handler: fileUploadClick,
          },
          {
            text: t('Cancel'),
            role: 'cancel',
            handler: handleCancelActionSheet,
          },
        ]}
      />
    </IonGrid>
  );
};

export default ExpenseBaseForm;
