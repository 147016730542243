import { useIonActionSheet, useIonRouter } from '@ionic/react';
import { t } from 'i18next';

const FosAddExpenseActionSheet = () => {
  const [present] = useIonActionSheet();
  const navigation = useIonRouter();
  enum AddExpenseAction {
    MILEAGE = 'Mileage',
    OUT_OF_POCKET = 'Out of Pocket',
    CANCEL = 'Cancel',
  }
  const expensesActionSheetButtons = [
    {
      text: t('mileage'),
      data: {
        action: AddExpenseAction.MILEAGE,
      },
    },
    {
      text: t('outOfPocket'),
      data: {
        action: AddExpenseAction.OUT_OF_POCKET,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: AddExpenseAction.CANCEL,
      },
    },
  ];

  const handleClick = () => {
    present({
      header: 'Add expense',
      cssClass: 'custom-action-sheet',
      buttons: expensesActionSheetButtons,
      onDidDismiss: ({ detail }) => {
        switch (detail.data?.action) {
          case AddExpenseAction.MILEAGE: {
            navigation.push('/mileage-expense');
            break;
          }
          case AddExpenseAction.OUT_OF_POCKET: {
            navigation.push('/expense');
            break;
          }
          case AddExpenseAction.CANCEL:
          default: break;
        }
      },
    });
  };
  return { handleClick };
};

export default FosAddExpenseActionSheet;