import React, { useEffect, useState } from 'react';
import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ImageToolSettings, Sketch } from '../sketch-tool';

export interface ImageToolSettingsProps {
  sketch: Sketch;
}

const SketchToolImageToolSettings = ({ sketch }: ImageToolSettingsProps) => {
  const [imageToolSettings, setImageToolSettings] = useState<ImageToolSettings>(sketch?.imageToolSettings || {});
  const { t } = useTranslation();

  useEffect(() => {
    if (!sketch) return;
    sketch.imageToolSettings = imageToolSettings;
  }, [sketch, imageToolSettings]);

  const handleShowDimensionsChange = (event: any) => {
    setImageToolSettings({ ...imageToolSettings, showDimensions: event.detail.checked });
  };

  return (
    <IonToolbar color="light">
      <IonGrid>
        <IonRow class="ion-align-items-center ion-nowrap" style={{ overflowX: 'scroll' }}>
          <IonCol>
            <IonItem lines="none" color="light">
              <IonLabel>
                {t('showDimensions').toString()}
              </IonLabel>
              <IonCheckbox checked={imageToolSettings.showDimensions || false} onIonChange={handleShowDimensionsChange} />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );
};

export default SketchToolImageToolSettings;
