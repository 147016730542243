import { Storage } from '@ionic/storage';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseCacheAndList from './baseCacheAndList';
import { PhaseCategoriesType } from '../pages/helper/PhasesCategoriesType';
import GET_PHASES_LIST from '../graphql/GetPhasesList';

const usePhaseCategories = (region: number, userState: UserState, storage: Storage) => {
  const phaseCategories:PhaseCategoriesType[] = useBaseCacheAndList(region, userState, storage, GET_PHASES_LIST, claimsKeys.phaseCategories(), 'phaseCategories');
  return phaseCategories;
};

export default usePhaseCategories;