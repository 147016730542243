import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { useAuth } from '../../../hooks/authContext';
import getGraphQLClient from '../../../hooks/graphQLClientUtil';
import SaveStorage, { SaveStorageReturn, SavedRecords, StorageRecord } from '../../../utils/SaveStorage';
import { ApiStatus, ClaimOnlineStatus } from '../Const';
import SAVE_SITE_INSPECTION_INFO from '../../../graphql/SaveSiteInspectionInfo';
import { deepClone } from '../../../utils/Utilities';
import { getErrorMsg } from '../AppHelper';

type StoreClaimSiteInfo = {
  info: any;
  claimIndx: number;
  status: ClaimOnlineStatus;
};

// used to save new claim or save the edit of existent claim
const saveClaimSiteInfo = async ({
  info,
  claimIndx,
  status,
} : StoreClaimSiteInfo) => {
  const saveStorage = SaveStorage();
  await saveStorage.setItem(SavedRecords.ClaimsSiteInfo, { info, status }, claimIndx);
};

export const storeClaimSiteInfo = async ({
  info,
  claimIndx,
  status,
} : StoreClaimSiteInfo) => {
  if (!info) {
    return;
  }
  await saveClaimSiteInfo({ info, claimIndx, status });
};

export const getAllClaimSiteInfo = async () => {
  const saveStorage = SaveStorage();
  const claimSiteInfoList = await saveStorage.getItems(SavedRecords.ClaimsSiteInfo);
  return claimSiteInfoList;
};

export const getClaimSiteInfo = async (
  claimIndx: number,
) => {
  const claimSiteInfoList = await getAllClaimSiteInfo();
  const claimSiteInfo = claimSiteInfoList.find(cSI => cSI.primaryKey === claimIndx);
  if (!claimSiteInfo?.data?.info) {
    return { info: undefined, status: undefined };
  }
  const info = claimSiteInfo?.data?.info;
  const status = claimSiteInfo?.data?.status;
  return { info, status };
};

export const useSaveSiteInfoMutation = () => { 
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (info: any) => graphQLClient.request(SAVE_SITE_INSPECTION_INFO, { info }),
  });
  return { isLoading, mutateAsync };
};


export const syncClaimSiteInfo = async (
  claimSiteInfo: StorageRecord,
  saveStorage: SaveStorageReturn,
  mutateSaveClaimSiteInfo: any,
) => {
  try {
    const cloneClaimSiteInfo = deepClone(claimSiteInfo);
    const res = await mutateSaveClaimSiteInfo(cloneClaimSiteInfo.data.info);
    
    if (res?.saveInspectionSiteInfo?.status === ApiStatus.SUCCESS) {
      claimSiteInfo.data.status = ClaimOnlineStatus.AvailableOffline;
      await saveStorage.setItem(SavedRecords.ClaimsSiteInfo, claimSiteInfo.data, claimSiteInfo.primaryKey);
    }
  } catch (e: any) {
    const msg = getErrorMsg(e);
    toast.error(msg || t('saveClaimError'), { duration: 4000 });
  }
};

export const getAndSyncClaimSiteInfo = async (
  saveStorage: SaveStorageReturn,
  mutateSaveClaimSiteInfo: any,
) => {
  const claimSiteInfoList = await getAllClaimSiteInfo();
  const filteredSiteInfoList = claimSiteInfoList?.filter(cSI => cSI.data.status === ClaimOnlineStatus.ToBeSynched);
  if (filteredSiteInfoList?.length > 0) {
    filteredSiteInfoList.forEach(async (claimSiteInfo) => {
      await syncClaimSiteInfo(claimSiteInfo, saveStorage, mutateSaveClaimSiteInfo);
    });
  }
};