import React, { useCallback, useEffect, useState } from 'react';
import {
  IonCheckbox,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import { add, mail } from 'ionicons/icons';
import { Toaster, toast } from 'react-hot-toast';
import { t } from 'i18next';

import './FormItemCheckbox.css';
import './FosShareWithContact.css';
import FormItemDivider from './FormItemDivider';
import { ContactsListType } from '../pages/helper/ContactsListType';
import OtherEmailsModal, { CustomEmailContact } from '../organism/OtherEmailsModal';
import handleDateFormat from '../utils/DateFormat';

export type SelectOptionProps = React.ComponentProps<typeof IonSelectOption> & { label: string };

export interface Props {
  title?: string;
  contactsList: Array<ContactsListType>;
  note: string;
  noteId?: number;
  regionId?: number;
  sentEmails: Array<string>;
  claimNumber: string;
  userName: string;
  setSelectedRecipients(selectedRecipients: CustomEmailContact): void;
  onEmailSent: (emails: Array<string>) => any;
}

const FosShareWithContact = React.forwardRef<HTMLIonCheckboxElement, Props>(({
  title,
  note,
  noteId,
  regionId,
  contactsList,
  sentEmails,
  claimNumber,
  userName,
  onEmailSent,
  setSelectedRecipients,
}, ref) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [emailBody, setEmailBody] = useState<string>('');
  const [customEmailsSent, setCustomEmailsSent] = useState<Array<string>>([]);
  const [customContactList, setCustomContactList] = useState<Array<ContactsListType & { selected: boolean }>>([]);

  useEffect(() => {
    setEmailBody(note);
  }, [note]);

  useEffect(() => {
    setCustomEmailsSent(sentEmails);
  }, [sentEmails]);

  useEffect(() => {
    const filteredContacts = contactsList.filter((contact) => contact.email!);
    const customContactList = filteredContacts.map((contact) => ({ ...contact, selected: false }));

    setCustomContactList(customContactList);
  }, [contactsList]);

  const onEmailSentHandler = useCallback((status?: string, sentEmails?: Array<string>) => {
    setIsShareModalOpen(false);
    if (status === 'success' && sentEmails) {
      const updatedEmails = [...customEmailsSent, ...sentEmails];

      setCustomEmailsSent(updatedEmails);
      onEmailSent(sentEmails);
      toast.success(t('emailSent').toString(), { duration: 4000 });
    }
  }, [
    customEmailsSent,
    onEmailSent,
  ]);

  const onContactChange = useCallback((event: any, contact: ContactsListType) => {
    const filteredContacts = customContactList.filter((item) => item.email !== contact.email);

    setCustomContactList([...filteredContacts, { ...contact, selected: event.detail.checked }]);
    setSelectedRecipients({ email: contact.email, selected: event.detail.checked, id: 'temp' });
  }, [
    customContactList,
    setSelectedRecipients,
  ]);

  return (
    <>
      <IonLabel>
        {title && <IonText className='fos-share-with-contact-title'>{title}</IonText>}
      </IonLabel>
      <IonList>
        {customContactList.map((contact, index) => (
          <div key={`${contact.name + index}`}>
            <FormItemDivider />
            <IonItem lines="none" className='ion-no-padding'>
              <IonCheckbox
                ref={ref}
                onIonChange={(e) => onContactChange(e, contact)}
                checked={contact.selected}
                className="form-item-checkbox"
                slot="start"
                color="primary" />
              <IonLabel>
                <IonText className='fos-share-with-contact-value'>{`${contact.role} - ${contact.name}`}</IonText>
              </IonLabel>
            </IonItem>
          </div>
        ))}
        <div className="other-emails">
          <FormItemDivider />
          <IonItem lines="none" className="ion-no-padding" onClick={() => setIsShareModalOpen(true)}>
            <div className="close-icon-container add-email-icon-container">
              <IonIcon icon={add} slot="start" />
            </div>
            <IonLabel className="add-email-label">
              <IonText className='fos-share-with-contact-value'>Add Custom Email</IonText>
            </IonLabel>
          </IonItem>
        </div>
        <OtherEmailsModal
          isModalOpen={isShareModalOpen}
          note={emailBody}
          noteId={noteId}
          regionId={regionId}
          claimNumber={claimNumber}
          userName={userName}
          onDismiss={onEmailSentHandler}
        />
      </IonList>
      <div className="sent-emails">
        {sentEmails.length > 0 && (
          <>
            <IonLabel>
              <IonText className='fos-share-with-contact-title'>{t('emailsSent').toString()}</IonText>
            </IonLabel>
            <IonList>
              {customEmailsSent.map((contact, index) => (
                <div key={`${contact + index}`}>
                  <FormItemDivider />
                  <IonItem lines="none" className='ion-no-padding'>
                    <div className="close-icon-container add-email-icon-container">
                      <IonIcon icon={mail} slot="start" />
                    </div>
                    <IonLabel>
                      <IonText className='fos-share-with-contact-value'>{contact}</IonText>
                    </IonLabel>
                    <IonLabel>
                      <IonText className='fos-share-with-contact-date'>{handleDateFormat(new Date().toISOString())}</IonText>
                    </IonLabel>
                  </IonItem>
                </div>
              ))}
            </IonList>
          </>
        )}
      </div>
      <Toaster
        containerStyle={{
          top: 100,
          left: 20,
          bottom: 20,
          right: 20,
        }} />
    </>
  );
});

export default FosShareWithContact;
