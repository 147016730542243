import React, { FC, useEffect, useRef, useState } from 'react';
import { Text } from '../sketch-tool';
import './SketchToolInlineTextEdit.css';

interface TextEditProps {
  textObject: Text | null;
  onDone: (text: string) => any;
}

const SketchToolInlineTextEdit: FC<TextEditProps> = ({ textObject, onDone }: TextEditProps) => {
  const [text, setText] = useState('');
  const [fontSize, setFontSize] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [visible, setVisible] = useState(false);
  const txtAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (!textObject) {
      setVisible(false);
      return;
    }
    setText(textObject.text);
    setFontSize(textObject.fontSize);
    setWidth(textObject.width);
    setHeight(textObject.height);
    setVisible(true);
  }, [textObject]);

  useEffect(() => {
    if (!visible) return;
    txtAreaRef.current?.focus();
    txtAreaRef.current?.select();
  }, [visible]);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  if (!textObject) return null;
  const { zoom, x: sketchX, y: sketchY } = textObject.getSketch()!;

  return (
    <div className="sketch-tool-inline-text-edit" style={{
      transform: `translate(${sketchX}px, ${sketchY}px) scale(${zoom})`,
      transformOrigin: 'left top',
      display: !visible ? 'none' : 'unset',
    }}>
      <textarea
        className="sketch-tool-inline-text-edit-textarea"
        ref={txtAreaRef}
        value={text}
        onBlur={() => onDone(text)}
        onInput={handleTextChange}
        style={{
          width,
          height,
          left: textObject.x,
          top: textObject.y,
          fontSize,
          transform: `rotate(${textObject.rotation}deg)`,
          transformOrigin: 'center',
        }}
      />
    </div>
  );
};

export default SketchToolInlineTextEdit;