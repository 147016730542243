import { useState } from 'react';
import { UserState } from './authUtils';

import { DomainParams } from './graphQLClientUtil';
import useBaseQuery from './baseQuery';
import GET_USER_EXPENSES_LIST, { UserExpenseObject } from '../graphql/GetUserExpenseList';

const useUserExpenseList = (userState: UserState, region: number, userGuid: string, expenseMethodId: number, statusId: number) => {
  const [userExpenseList, setUserExpenseList] = useState<UserExpenseObject[]>();

  const onSuccess = async (data: any) => {    
    const expenseList: UserExpenseObject[] = data?.personalExpenses || [];
    setUserExpenseList(expenseList);
  };

  const { isLoading, isFetching, refetch } = useBaseQuery(
    region,
    { regionId: region, userGuid, expenseMethodId, statusId },
    userState,
    !!expenseMethodId,
    GET_USER_EXPENSES_LIST,
    ['useUserExpenseList'],
    DomainParams.Payroll,
    onSuccess,
  );

  return { userExpenseList, isLoading, isFetching, refetch };
};

export default useUserExpenseList;
