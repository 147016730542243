import { Storage } from '@ionic/storage';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseCacheAndList from './baseCacheAndList';
import { ProjectManagersType } from '../pages/helper/ProjectManagersType';
import GET_PROJECT_MANAGER_LIST from '../graphql/GetProjectManagersList';

const useProjectManagers = (region: number, userState: UserState, storage: Storage) => {
  const projectManagersList:ProjectManagersType[] = useBaseCacheAndList(region, userState, storage, GET_PROJECT_MANAGER_LIST, claimsKeys.projectManagerList(), 'projectManagers');
  return projectManagersList;
};

export default useProjectManagers;