import { SketchObjectType } from '../sketch-object';
import { type LibItem } from './common';

export const window90cm: LibItem = {
  id: Math.random() * 100000,
  thumbUrl: '/assets/images/sketch-tool/window-90cm.svg',
  name: 'Window 90cm',
  type: SketchObjectType.Image,
  src: '/assets/images/sketch-tool/window-90cm.svg',
};
