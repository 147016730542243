import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';
import DELETE_SKETCH from '../graphql/DeleteSketch';

/*
* GraphQL call: delete sketch
* */
const useDeleteSketch = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (id: number) => graphQLClient.request(DELETE_SKETCH, { id }),
  });
  return { isLoading, mutateAsync };
};

export default useDeleteSketch;