import { UserState } from './authUtils';
import GET_CLAIM_NOTES, { ClaimNotesType } from '../graphql/GetClaimNotes';
import { claimsKeys } from './claims';
import useBaseQuery from './baseQuery';

const useClaimNotes = (region: number, claimIndx: number, userState: UserState) => {
  const enabled = !!claimIndx;
  const { data, isLoading, isFetching, refetch } = useBaseQuery(
    region,
    { claimIndx },
    userState,
    enabled,
    GET_CLAIM_NOTES,
    claimsKeys.claimNotes(),
  );
  const claimNotesList: ClaimNotesType[] =
    !isLoading && !isFetching ? data?.claimNotes || [] : [];
  return { claimNotesList, isLoading: isLoading || isFetching, refetch };
};

export default useClaimNotes;
