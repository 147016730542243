/* eslint-disable no-await-in-loop */
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { useMutation } from '@tanstack/react-query';
import SaveStorage, { SaveStorageReturn, SavedRecords, StorageRecord } from '../../../utils/SaveStorage';
import { ApiStatus, ClaimOnlineStatus } from '../Const';
import { deepClone } from '../../../utils/Utilities';
import { useAuth } from '../../../hooks/authContext';
import getGraphQLClient from '../../../hooks/graphQLClientUtil';
import UPDATE_PHOTOS from '../../../graphql/UpdatePhotos';
import { ClaimPhotosType } from '../../../graphql/GetClaimPhotosList';
import { getErrorMsg } from '../AppHelper';

type StoreClaimPhotos = {
  photos: any[];
  claimIndx: number;
  status: ClaimOnlineStatus;
};

type StoreClaimPhoto = {
  photo: any;
  claimIndx: number;
};

// save at Storage
const saveClaimPhotos = async ({
  photos,
  claimIndx,
  status,
}: StoreClaimPhotos) => {
  const saveStorage = SaveStorage();
  await saveStorage.setItem(SavedRecords.ClaimsPhoto, { photos, status }, claimIndx);
};

export const storeClaimPhotos = async ({
  photos,
  claimIndx,
  status,
}: StoreClaimPhotos) => {
  if (!photos) {
    return;
  }
  await saveClaimPhotos({ photos, claimIndx, status });
};

export const getAllClaimPhotos = async () => {
  const saveStorage = SaveStorage();
  const claimPhotosList = await saveStorage.getItems(SavedRecords.ClaimsPhoto);
  return claimPhotosList;
};

export const getClaimPhotos = async (
  claimIndx: number,
) => {
  const claimPhotosList = await getAllClaimPhotos();
  const claimPhotos = claimPhotosList.find(cSI => cSI.primaryKey === claimIndx);
  if (!claimPhotos?.data?.photos) {
    return { photos: undefined, status: undefined };
  }
  const photos = claimPhotos?.data?.photos;
  const status = claimPhotos?.data?.status;
  return { photos, status };
};

export const getPhotosToBeAdded = async (claimIndx: number) => {
  const saveStorage = SaveStorage();
  const claimPhotosList = await saveStorage.getItems(SavedRecords.AddPhotosType);
  const photoList = claimPhotosList?.length ? Object.values(claimPhotosList[0].data) : [];
  const photosToReturn = photoList.filter((p:any) => Number(p.claimIndex) === claimIndx);
  photosToReturn.map((p: any) => {
    p.fileName = p.files[0].name;
    p.filePath = window.URL.createObjectURL(p.files[0]);
    p.sentToXactEm = p.sendToXact;
    return p;
  });
  return photosToReturn;
};

export const saveOfflineClaimPhoto = async ({
  photo,
  claimIndx,
}: StoreClaimPhoto) => {
  const claimPhotos = await getClaimPhotos(claimIndx);
  const storedPhoto: any = claimPhotos?.photos?.find((p: ClaimPhotosType) => p.ctfileId === photo.ctfileId);
  storedPhoto.description = photo.description;
  storedPhoto.includedInIsi = photo.includeInIsi;
  storedPhoto.updatedBy = photo.updatedBy;
  storedPhoto.phaseIndx = photo.phaseIndx;
  storedPhoto.sentToXactEm = photo.sentToXactEm;
  storedPhoto.status = ClaimOnlineStatus.ToBeSynched;
  await saveClaimPhotos({ photos: claimPhotos?.photos, claimIndx, status: ClaimOnlineStatus.ToBeSynched });
};

const editParseEditPhoto = (claimPhoto: any) => ({
  ctfileId: claimPhoto.ctfileId,
  description: claimPhoto.description,
  includeInIsi: claimPhoto.includeInIsi || claimPhoto.includedInIsi || 0,
  phaseIndx: claimPhoto.phaseIndx || undefined,
  sendToXact: claimPhoto.sendToXact || false,
  sendToSymbility: claimPhoto.sendToSymbility || false,
  updatedBy: claimPhoto.updatedBy,
});

export const syncClaimPhotos = async (
  claimPhotos: StorageRecord,
  saveStorage: SaveStorageReturn,
  mutateSaveClaimPhotos: any,
) => {
  try {
    const cloneClaimPhotos = deepClone(claimPhotos);
    const photos = cloneClaimPhotos.data.photos.filter((p: ClaimPhotosType)=> p.status === ClaimOnlineStatus.ToBeSynched);
    for (let photoIndex = 0; photoIndex < photos.length; photoIndex++) {
      const photo = photos[photoIndex];
      delete photo.status;
      try {
        const editPhoto = editParseEditPhoto(photo);
        const res = await mutateSaveClaimPhotos(editPhoto);
        if (res?.updateCtPhoto?.status !== ApiStatus.SUCCESS) {
          photo.status = ClaimOnlineStatus.ToBeSynched;
        }
      } catch (ex) {
        window.console.error(ex);
        photo.status = ClaimOnlineStatus.ToBeSynched;
      }
    }
    claimPhotos.data.status = ClaimOnlineStatus.AvailableOffline;
    await saveStorage.setItem(SavedRecords.ClaimsPhoto, claimPhotos.data, claimPhotos.primaryKey);

  } catch (e: any) {
    const msg = getErrorMsg(e);
    toast.error(msg || t('saveClaimError'), { duration: 4000 });
  }
};

export const useSavePhotoMutation = () => { 
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (request: any) => graphQLClient.request(UPDATE_PHOTOS, { request }),
  });
  return { isLoading, mutateAsync };
};

export const getAndSyncClaimPhotos = async (
  saveStorage: SaveStorageReturn,
  region: number,
  mutateSaveClaimPhotos: any,
) => {
  const claimPhotosList = await getAllClaimPhotos();
  const filteredPhotosList = claimPhotosList?.filter(cll => cll.data.status === ClaimOnlineStatus.ToBeSynched);
  if (filteredPhotosList?.length > 0) {
    filteredPhotosList.forEach(async (claimPhotos) => {
      await syncClaimPhotos(claimPhotos, saveStorage, mutateSaveClaimPhotos);
    });
  }
};