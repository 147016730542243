import React from 'react';
import { IonGrid, IonRow, IonCol, IonCheckbox, IonButton } from '@ionic/react';
import { t } from 'i18next';
import { ClaimPhotosType } from '../graphql/GetClaimPhotosList';
import FosCachedImage from '../components/FosCachedImage';

type Props = {
  claimIndx: number
  claimPhotosList: ClaimPhotosType[];
  isSelectAll: boolean;
  isSelectEnabled: boolean;
  selectedPhotos: ClaimPhotosType[];
  setSelectedPhotos: (claimPhotoList: ClaimPhotosType[]) => void;
  setIsSelectEnabled: (enabled: boolean) => void;
  setIsSelectAll: (select: boolean) => void;
  setSelectedPhotoEdit: (photoEdit: ClaimPhotosType | null) => void;
  setIsAddPhotoToClaimFormOpen: (adding: boolean) => void;
  setSelectedBulkPhotoEdit: (bulkPhotoEdit: ClaimPhotosType[]) => void;
  setIsEditBulkPhotoToClaimFormOpen: (adding: boolean) => void;
  openLevelsModal: () => void;
};

const tempRoomImageURL = 'https://via.placeholder.com/200x200';

const ClaimPhotosGridImage: React.FC<Props> = ({
  claimIndx,
  claimPhotosList,
  isSelectAll,
  isSelectEnabled,
  selectedPhotos,
  setSelectedPhotos,
  setIsSelectEnabled,
  setIsSelectAll,
  setSelectedPhotoEdit,
  setSelectedBulkPhotoEdit,
  setIsAddPhotoToClaimFormOpen,
  setIsEditBulkPhotoToClaimFormOpen,
  openLevelsModal,
}) => {

  const onImgError = (event: any) => {
    const img = event.target;
    img.src = tempRoomImageURL;
  };

  const cancelClick = (): void => {
    claimPhotosList.forEach(photo => {
      const photoChanged = photo;
      photoChanged.selected = false;
    });
    setSelectedPhotos([]);
    setIsSelectEnabled(false);
    setIsSelectAll(true);
  };

  const selectAll = (): void => {
    claimPhotosList.forEach(photo => {
      const photoChanged = photo;
      photoChanged.selected = isSelectAll;
    });
    setIsSelectAll(!isSelectAll);
    setSelectedPhotos(isSelectAll ? claimPhotosList : []);
  };

  const isPhotoSelected = (claimPhoto: ClaimPhotosType): boolean =>
    selectedPhotos.some((obj: ClaimPhotosType) => obj.ctfileId === claimPhoto.ctfileId);
  
  const selectPhoto = (claimPhoto: ClaimPhotosType): void => {
    if (claimPhoto.ctfileId) {
      const selectedBefore: boolean = isPhotoSelected(claimPhoto);
      const newSelectedPhotos = selectedBefore ? selectedPhotos.filter(p => p.ctfileId !== claimPhoto.ctfileId) : [...selectedPhotos, claimPhoto];
      setSelectedPhotos(newSelectedPhotos);
      const updatedClaimPhoto = claimPhoto;
      updatedClaimPhoto.selected = !claimPhoto.selected;
    }
  };

  const selectImage = (claimPhoto: ClaimPhotosType): void => {
    if (isSelectEnabled) {
      selectPhoto(claimPhoto);
    } else {
      // open photo details
      setSelectedPhotoEdit(claimPhoto);
      setIsAddPhotoToClaimFormOpen(true);
    }
  };

  const editClick = () => {
    if (selectedPhotos.length === 1) {
      // open photo details
      setSelectedPhotoEdit(selectedPhotos[0]);
      setIsAddPhotoToClaimFormOpen(true);
    } else {
      setSelectedBulkPhotoEdit(selectedPhotos);
      setIsEditBulkPhotoToClaimFormOpen(true);
    }
  };

  return (
    <IonGrid>
    <IonRow className='photo-ion-row'>
      {claimPhotosList.map((claimPhoto) => (
        <IonCol className='photo-ion-col' sizeMd='2' sizeLg='2' sizeXl='1' size="4" key={`photo-${claimPhoto.ctfileId || claimPhoto.tempId}`}>
          <FosCachedImage
            className='claim-photo'
            onClick={() => selectImage(claimPhoto)}
            src={claimPhoto.thumbnailFileName || claimPhoto.filePath}
            onIonError={onImgError}
            folder={`${claimIndx}`}
          />
          {isSelectEnabled &&
            <IonCheckbox
              className='checkbox-selected'
              name={`${claimPhoto.ctfileId}-radio`}
              value={!!claimPhoto.selected}
              checked={!!claimPhoto.selected}
              onClick={() => selectPhoto(claimPhoto)}
              id={claimPhoto.ctfileId?.toString() || claimPhoto.tempId?.toString()}
            />
          }
        </IonCol>
      ))}
    </IonRow>
    <div className='footer-button-div-first'>
      {selectedPhotos.length > 0 &&
        <IonButton
          shape="round"
          className='footer-button right-button'
          onClick={() => editClick()}
        >
          {t('edit').toString()}
        </IonButton>
      }
    </div>
    <div className='footer-button-div'>
      {!isSelectEnabled &&
        <IonButton
          shape="round"
          className='footer-button'
          onClick={() => setIsSelectEnabled(true)}
        >
          {t('select').toString()}
        </IonButton>
      }
      {isSelectEnabled &&
        <IonButton
          shape="round"
          className='footer-button'
          onClick={() => cancelClick()}
        >
          {t('cancel').toString()}
        </IonButton>
      }
      {selectedPhotos.length > 0 &&
        <IonButton
          shape="round"
          className='footer-button move-to-button'
          onClick={() => openLevelsModal()}
        >
          {t('moveTo').toString()}
        </IonButton>
      }
      {isSelectEnabled &&
        <IonButton
          shape="round"
          className='footer-button right-button'
          onClick={() => selectAll()}
        >
          {isSelectAll ? t('selectAll').toString() : t('clearAll').toString()}
        </IonButton>
      }
    </div>
    </IonGrid>
  );
};

export default ClaimPhotosGridImage;