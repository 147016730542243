import { useMutation } from '@tanstack/react-query';
import getGraphQLClient from './graphQLClientUtil';
import { useAuth } from './authContext';
import SAVE_REGISTER_DEVICE_TOKEN from '../graphql/SaveRegisterDeviceToken';

/*
* GraphQL call: save claim levels
* */
const useSaveDeviceToken = () => {
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({ deviceToken, plataform, userId } : { deviceToken: string, plataform: string, userId: string }) => 
      graphQLClient.request(SAVE_REGISTER_DEVICE_TOKEN, { request: { deviceToken, plataform, userId } }),
  });
  return { isLoading, mutateAsync };
};

export default useSaveDeviceToken;