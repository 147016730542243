import { gql } from '@apollo/client';

export interface RoomModelType {
  roomId?: number;
  roomName: string;
  levelId?: number;
}

export interface LevelModelType {
  levelId?: number;
  levelName: string;
  rooms: RoomModelType[];
}

const GET_LEVELS_LIST = gql`
query listLevels($regionId: Int!, $claimIndx: Int!) {
  listLevels(regionId: $regionId, claimIndx: $claimIndx)
  {
    levelId
    levelName
    rooms {
      roomId
      roomName
      levelId
    }
  }
}`;

export default GET_LEVELS_LIST;