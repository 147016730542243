import { gql } from '@apollo/client';

const GET_PROPERTY_MANAGER_LIST = gql`
query propertyManagers($regionId: Int!) {
  propertyManagers(regionId: $regionId)
  { 
    adjCode
    coCode
    firstName
    lastName
    phone
    clientCell
  } 
}`;

export default GET_PROPERTY_MANAGER_LIST;