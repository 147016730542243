import { duplicateButtonImage, resizeButtonImage, rotateButtonImage } from './common';
import {
  ObjectConfig,
  ObjectExport,
  Rect,
  SketchObject,
  SketchObjectType,
  SystemOfMeasurement,
} from './sketch-object';

export interface EllipseConfig extends ObjectConfig {
  x?: number;
  y?: number;
  radiusX?: number;
  radiusY?: number;
  startAngle?: number;
  endAngle?: number;
  fillStyle?: string;
  strokeStyle?: string;
  lineWidth?: number;
  mode?: 'fill' | 'stroke';
}

export interface EllipseExport extends ObjectExport {
  type: SketchObjectType.Ellipse;
  x?: number;
  y?: number;
  radiusX?: number;
  radiusY?: number;
  startAngle?: number;
  endAngle?: number;
  fillStyle?: string;
  strokeStyle?: string;
  lineWidth?: number;
  mode?: 'fill' | 'stroke';
}

export class Ellipse extends SketchObject {
  x: number;

  y: number;

  radiusX: number;

  radiusY: number;

  startAngle: number;

  endAngle: number;

  mode: 'fill' | 'stroke';

  fillStyle: string;

  strokeStyle: string;

  lineWidth: number;

  dragStart: HammerPoint | null = null;

  private path?: Path2D;

  private currentTransform?: DOMMatrix;

  private resizeStart?: { radiusX: number; radiusY: number };

  constructor(config: EllipseConfig = {}) {
    super(config);
    const {
      x = 0,
      y = 0,
      radiusX = 0,
      radiusY = 0,
      startAngle = 0,
      endAngle = Math.PI * 2,
      fillStyle = '',
      strokeStyle = '',
      lineWidth = 1,
      mode = 'stroke',
    } = config;

    this.x = x;
    this.y = y;
    this.radiusX = radiusX;
    this.radiusY = radiusY;
    this.startAngle = startAngle;
    this.endAngle = endAngle;
    this.fillStyle = fillStyle;
    this.strokeStyle = strokeStyle;
    this.lineWidth = lineWidth;
    this.mode = mode;

    this.on('dragstart', (event: HammerInput) => {
      this.dragStart = event.center;
    });

    this.on('dragend', () => {
      this.dragStart = null;
    });
  }

  setX(x: number) {
    this.x = x;
    this.emit('update');
  }

  setY(y: number) {
    this.y = y;
    this.emit('update');
  }

  setRadiusX(radiusX: number) {
    if (radiusX < 0) return;
    this.radiusX = radiusX;
    this.emit('update');
  }

  setRadiusY(radiusY: number) {
    if (radiusY < 0) return;
    this.radiusY = radiusY;
    this.emit('update');
  }

  setRadius(radiusX: number, radiusY: number) {
    if (radiusX < 0 || radiusY < 0) return;
    this.radiusX = radiusX;
    this.radiusY = radiusY;
    this.emit('update');
  }

  setStartAngle(startAngle: number) {
    this.startAngle = startAngle;
    this.emit('update');
  }

  setEndAngle(endAngle: number) {
    this.endAngle = endAngle;
    this.emit('update');
  }

  setMode(mode: 'fill' | 'stroke') {
    this.mode = mode;
    this.emit('update');
  }

  setFillStyle(fillStyle: string) {
    this.fillStyle = fillStyle;
    this.emit('update');
  }

  setStrokeStyle(strokeStyle: string) {
    this.strokeStyle = strokeStyle;
    this.emit('update');
  }

  export(): EllipseExport {
    return {
      ...super.export(),
      type: SketchObjectType.Ellipse,
      x: this.x,
      y: this.y,
      radiusX: this.radiusX,
      radiusY: this.radiusY,
      startAngle: this.startAngle,
      endAngle: this.endAngle,
      fillStyle: this.fillStyle,
      strokeStyle: this.strokeStyle,
      lineWidth: this.lineWidth,
      mode: this.mode,
    };
  }

  draw() {
    if (!this.visible || !this.canvas) return;
    if (this.mode === 'fill') {
      this.fill();
    } else {
      this.stroke();
    }

    if (this.showName) this.drawName();
    if (this.showSquareFootage) this.drawSquareFootage();
    if (this.showDimensions) this.drawDimensions();
    if (this.selected && !this.isPartOfGroup()) {
      this.drawSelection();
      this.drawRotateButton();
      this.drawResizeButton();
      this.drawDuplicateButton();
    }
  }

  drawName() {
    const ctx = this.canvas?.getContext('2d')!;
    const { width: nameWidth } = ctx.measureText(this.name);
    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-this.x, -this.y);
    ctx.fillText(this.name, this.x - nameWidth / 2, this.y - this.radiusY - 12);
    ctx.restore();
  }

  drawSquareFootage() {
    const { systemOfMeasurement, feetToPixels, meterToPixels } = this;
    const ctx = this.canvas?.getContext('2d')!;
    ctx.save();
    const unit = SketchObject.getMeasureUnit(systemOfMeasurement);
    const scaleInPixels = systemOfMeasurement === SystemOfMeasurement.Imperial
      ? feetToPixels
      : meterToPixels;
    const squareFootage = (this.radiusX / scaleInPixels) * (this.radiusY / scaleInPixels) * Math.PI;
    const squareFootageTxt = `${squareFootage.toFixed(2)}${unit}²`;
    const { width, actualBoundingBoxAscent } = ctx.measureText(squareFootageTxt);
    ctx.translate(this.x, this.y);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-this.x, -this.y);
    ctx.fillText(squareFootageTxt, this.x - width / 2, this.y + this.radiusY + 12 + actualBoundingBoxAscent);
    ctx.restore();
  }

  drawDimensions() {
    const ctx = this.canvas!.getContext('2d')!;
    const rxTxt = this.getAmountFormatted(this.radiusX);
    const ryTxt = this.getAmountFormatted(this.radiusY);
    const { width: rxTxtWidth, actualBoundingBoxAscent: rxTxtHeight } = ctx.measureText(rxTxt);
    const { width: ryTxtWidth, actualBoundingBoxAscent: ryTxtHeight } = ctx.measureText(ryTxt);
    if (rxTxtWidth > this.radiusX || ryTxtWidth > this.radiusY) {
      return;
    }
    ctx.save();
    if (this.mode === 'fill') {
      ctx.strokeStyle = '#fff';
      ctx.fillStyle = '#fff';
    }
    const path = new Path2D();
    ctx.translate(this.x, this.y);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-this.x, -this.y);
    const gapBetweenLineAndTxt = 5;
    path.moveTo(this.x, this.y);
    path.lineTo(this.x + this.radiusX / 2 - rxTxtWidth / 2 - gapBetweenLineAndTxt, this.y);
    ctx.fillText(rxTxt, this.x + this.radiusX / 2 - rxTxtWidth / 2, this.y + rxTxtHeight / 2);
    path.moveTo(this.x + this.radiusX / 2 - rxTxtWidth / 2 + rxTxtWidth + gapBetweenLineAndTxt, this.y);
    path.lineTo(this.x + this.radiusX, this.y);
    const txtX = this.x;
    const txtY = this.y - this.radiusY / 2 + ryTxtWidth / 2;
    path.moveTo(this.x, this.y);
    path.lineTo(txtX, txtY + gapBetweenLineAndTxt);
    ctx.save();
    ctx.translate(txtX, txtY);
    ctx.rotate(-90 * Math.PI / 180);
    ctx.translate(-txtX, -txtY);
    ctx.fillText(ryTxt, txtX, txtY + ryTxtHeight / 2);
    ctx.restore();
    path.moveTo(txtX, txtY - ryTxtWidth - 5);
    path.lineTo(this.x, this.y - this.radiusY);
    ctx.stroke(path);
    ctx.restore();
  }

  drawSelection() {
    const ctx = this.canvas!.getContext('2d')!;
    const size = 8;
    ctx.save();
    ctx.fillStyle = 'red';
    ctx.translate(this.x, this.y);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-this.x, -this.y);
    const x1 = this.x - this.radiusX - size / 2;
    const y1 = this.y - size / 2;
    ctx.fillRect(x1, y1, size, size);
    ctx.fillRect(x1 + this.radiusX, y1 - this.radiusY, size, size);
    ctx.fillRect(x1 + 2 * this.radiusX, y1, size, size);
    ctx.fillRect(x1 + this.radiusX, y1 + this.radiusY, size, size);
    ctx.restore();
  }

  isContainedByRect(outerRect: Rect): boolean {
    return this.isObjectContainedByRect(this.getBoundingRect(), outerRect);
  }

  isPointInsideObject(x: number, y: number) {
    if (!this.canvas || !this.path) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.setTransform(this.currentTransform);
    const v = ctx.isPointInPath(this.path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideRotationButton(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const btnWidth = 24;
    const rx = this.x - btnWidth / 2;
    const ry = this.y - this.radiusY - 12 - btnWidth;
    path.rect(rx, ry, btnWidth, btnWidth);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideDuplicateButton(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const btnWidth = 24;
    const rx = this.x + this.radiusX + 12;
    const ry = this.y - btnWidth / 2;
    path.rect(rx, ry, btnWidth, btnWidth);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideResizeButton(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const btnWidth = 24;
    const rx = this.x + this.radiusX - btnWidth / 2;
    const ry = this.y - this.radiusY;
    path.rect(rx, ry, btnWidth, btnWidth);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  handleDragStart(): void {
    this.dragStart = { x: this.x, y: this.y };
  }

  handleDrag({ deltaX, deltaY }: HammerInput): void {
    const sketch = this.getSketch();
    if (!this.dragStart || !sketch) return;
    const { x, y } = this.dragStart;
    this.x = x + deltaX / sketch.zoom;
    this.y = y + deltaY / sketch.zoom;
    this.emit('update');
  }

  handleDragEnd(): void {
    this.dragStart = null;
  }

  drawRotateButton() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-this.x, -this.y);
    const btnWidth = 24;
    const x = this.x - btnWidth / 2;
    const y = this.y - this.radiusY - 12 - btnWidth;
    ctx.drawImage(rotateButtonImage, x, y);
    ctx.restore();
  }

  drawResizeButton(): void {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-this.x, -this.y);
    const btnWidth = 24;
    const x = this.x + this.radiusX - btnWidth / 2;
    const y = this.y - this.radiusY;
    ctx.drawImage(resizeButtonImage, x, y);
    ctx.restore();
  }

  drawDuplicateButton() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-this.x, -this.y);
    const btnWidth = 24;
    const x = this.x + this.radiusX + 12;
    const y = this.y - btnWidth / 2;
    ctx.drawImage(duplicateButtonImage, x, y);
    ctx.restore();
  }

  handleResizeStart() {
    this.resizeStart = { radiusX: this.radiusX, radiusY: this.radiusY };
  }

  handleResize(event: HammerInput) {
    this.setRadius(this.resizeStart!.radiusX + event.deltaX, this.resizeStart!.radiusY + event.deltaY);
  }

  handleResizeEnd(): void {
    delete this.resizeStart;
    this.emit('update');
  }

  private fill() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    this.path = new Path2D();
    ctx.fillStyle = this.fillStyle;
    ctx.translate(this.x, this.y);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-this.x, -this.y);
    this.path.ellipse(
      this.x,
      this.y,
      this.radiusX,
      this.radiusY,
      0,
      this.startAngle,
      this.endAngle,
    );
    ctx.fill(this.path);
    this.currentTransform = ctx.getTransform();
    ctx.restore();
  }

  private stroke() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    this.path = new Path2D();
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.translate(this.x, this.y);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-this.x, -this.y);
    this.path.ellipse(
      this.x,
      this.y,
      this.radiusX,
      this.radiusY,
      0,
      this.startAngle,
      this.endAngle,
    );
    ctx.stroke(this.path);
    this.currentTransform = ctx.getTransform();
    ctx.restore();
  }

  private getBoundingRect() {
    return {
      x: this.x - this.radiusX,
      y: this.y - this.radiusY,
      width: this.radiusX * 2,
      height: this.radiusY * 2,
    };
  }
}
