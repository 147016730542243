import {
  IonContent,
  IonPage,
  IonButton,
  IonLoading,
  isPlatform,
  IonFooter,
  IonLabel,
  IonList,
} from '@ionic/react';

import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { NativeBiometric, BiometryType } from 'capacitor-native-biometric';
import { t } from 'i18next';
import { useAuth } from '../../hooks/authContext';
import './Login.css';
import { HYBRID } from '../../hooks/photo';
import { AUTH_LOCATION_KEY } from '../../hooks/authUtils';
import { PermissionsList, usePermissions } from '../../hooks/permissions';
import FosCachedImage from '../../components/FosCachedImage';
import { APP_VERSION } from '../helper/Const';

const USER_FACE_ID = 'USER_FACE_ID';

const LoginPage: React.FC<RouteComponentProps> = () => {
  const { logIn, userState } = useAuth()!;
  const history = useHistory();
  const [allowFaceId, setAllowFaceId] = useState(false);
  const { permissions } = usePermissions();

  const { loggedIn, initialized } = userState;
  useEffect(() => {
    if (permissions?.length > 0 && initialized && loggedIn) {
      const isViewClaimsEnabled = permissions.includes(PermissionsList.ViewClaims);
      if (isViewClaimsEnabled) {
        history.replace('/tabs/jobs');
      } else {
        history.replace('/tabs/profile');
      }
    }
  }, [loggedIn, initialized, history, permissions]);

  const biometricLogin = async () => {
    if (!isPlatform(HYBRID)) {
      return;
    }
    const result = await NativeBiometric.isAvailable();

    if (!result.isAvailable) return;
  
    const isFaceID = result.biometryType === BiometryType.FACE_ID;
    window.console.log(isFaceID);
  
    const verified = await NativeBiometric.verifyIdentity({
      reason: 'For easy log in',
      title: 'Log in',
      subtitle: '',
      description: '',
    })
      .then(() => true)
      .catch(() => false);
  
    if (!verified) return;

    logIn();
    history.replace('/tabs/jobs');
  };

  useEffect(()=> {
    // user token stored
    const storage = window.localStorage.getItem(AUTH_LOCATION_KEY);
    // face id enabled
    const savedFaceId : boolean = window.localStorage.getItem(USER_FACE_ID) === 'true';
    setAllowFaceId(isPlatform(HYBRID) && savedFaceId && !!storage);
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="container">
          <FosCachedImage src="../assets/mobiletrak_colour.png" className='logoImage' alt="First On Site" />
          {allowFaceId && 
            <IonButton onClick={() => biometricLogin()} className='loginButton'>
              Biometric
            </IonButton>
          }
          <IonButton onClick={logIn} disabled={userState.loading} className='loginButton'>
            Single Sign On
          </IonButton>
          <IonLoading isOpen={userState.loading} message={t('loadingYourInformation')} />
        </div>
      </IonContent>
      <IonFooter className='loginFooter'>
        <IonList className='powerByList'>
          <IonLabel className='powerByText'>{t('poweredBy').toString()}</IonLabel>
          <FosCachedImage src="../assets/MicrosoftTeams-image.png" className='powerByImg' alt="First On Site" />
          <IonLabel className='versionLabel'>{t('version').toString()} {APP_VERSION}</IonLabel>
        </IonList>
      </IonFooter>
    </IonPage>
  );
};

export default LoginPage;
