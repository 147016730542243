import React from 'react';
import {
  IonItem,
  IonLabel,
  IonText,
  IonModal,
  IonDatetime,
  IonNote,
} from '@ionic/react';
import Moment from 'moment';

interface Props {
  showDefaultButtons?: boolean;
  label: string;
  id: string;
  name?: string;
  value?: string;
  presentation?: 'date' | 'time' | 'date-time' | 'time-date';
  required?: boolean;
  error?: string;
  size?: any;
  max?: string | number;
  min?: string | number;
  disabled?: boolean;
  onChange?: (event: any) => void;
  onIonChange?: (event: any) => void;  
}

const FosDateTime = React.forwardRef<HTMLIonDatetimeElement, Props>(({
  showDefaultButtons,
  label,
  id,
  name,
  value,
  presentation,
  required,
  error,
  size,
  max,
  min,
  disabled,
  onChange,
  onIonChange,
}, ref) => {
  const formattedValue = value ? Moment(value).format('DD MMMM, YYYY') : '';
  if (!max) {
    max = (new Date()).toISOString();
  }

  if (!min) {
    min = (new Date(2000, 1, 1)).toISOString();
  }
  
  return (
    <>
      <IonItem className="fos-border fos-label-value-item-style" id={id}>
        <div className="fos-select-date-border" />
        <IonLabel className="fos-certificate-select-label">
          <IonText className="fos-select-date-label">{label}{required && <span className="required-asterisk"> *</span>}</IonText>
        </IonLabel>
        <IonText className="wa-select-date-label">
          {formattedValue}
        </IonText>
        <IonModal trigger={id} className="center fixed-300-width" keepContentsMounted>
          <IonDatetime
            disabled={disabled}
            ref={ref}
            id={id}
            presentation={presentation}
            name={name}
            value={value}
            size={size}
            showDefaultButtons={showDefaultButtons}
            max={max?.toString()}
            min={min?.toString()}
            onIonChange={onIonChange}
            onChange={onChange}
          />
        </IonModal>
      </IonItem>
      {error && <p className="ion-no-margin ion-padding-start">
        <small>
          <IonNote color="danger">{error}</IonNote>
        </small>
      </p>}
    </>
  );
});

export default FosDateTime;