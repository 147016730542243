import { t } from 'i18next';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { User } from '../../../graphql/GetUser';
import SaveStorage, { SaveStorageReturn, SavedRecords, StorageRecord } from '../../../utils/SaveStorage';
import { formatDataForm } from '../ClaimHelper';
import { Option } from '../../../utils/Interfaces';
import { PhaseListType } from '../PhaseList';
import { ApiStatus, ClaimOnlineStatus } from '../Const';
import SAVE_CLAIM from '../../../graphql/SaveClaim';
import getGraphQLClient from '../../../hooks/graphQLClientUtil';
import { useAuth } from '../../../hooks/authContext';
import UPDATE_CLAIM from '../../../graphql/UpdateClaim';
import { deepClone } from '../../../utils/Utilities';
import { getErrorMsg } from '../AppHelper';

export type ClaimFormData = {
  brId: string;
  lossType: string;
  projName: string;
  projAddr: string;
  projCity: string;
  projProv: string;
  projZip?: string;
  jobPcoord: string;
  lossCat: string;
  pcId: string;
  pmgrName?: string;
  adjCode?: string;
  claimCo?: string;
  contactName?: string;
  calledIn: string;
  jobDOpen: string;
  lossDate: string;
  dedAmt: number;
  claimNumber: string;
  claimNo: string;
  policyNo: string;
  contactPhone: string;
  status?: ClaimOnlineStatus;
  phases?: PhaseListType[];
  claimIndx?: number;
  yearOfConstruction?: string;
  projEmail?: string;
  verticalId?: number;
  verticalName?: string;
  projPh1?: string;
  projPh2?: string;
  projPh3?: string;
  projPhone1?: string;
  projPhone2?: string;
  projPhone3?: string;
};

type SafeClaimProps = {
  data: any,
  phaseList: PhaseListType[],
  user: User | undefined,
  claimIndx: number,
  lossAddressLon: number | undefined,
  lossAddressLat: number | undefined,
  region: number,
  xactTransactionId: string | null,
  jobIdx: number,
  operation: SaveClaimOperation,
};

export enum SaveClaimOperation {
  ADD = 'Add Claim',
  EDIT = 'Edit Claim',
}

// used to save new claim or save the edit of existent claim
export const saveClaim = async ({
  data,
  phaseList,
  user,
  claimIndx,
  lossAddressLat,
  lossAddressLon,
  region,
  xactTransactionId,
  jobIdx,
  operation,
} : SafeClaimProps) => {
  const formClaim = formatDataForm({ data, phaseList, user, claimIndx, lossAddressLon, lossAddressLat });
  formClaim.tempId = Date.now();
  formClaim.phases = [...phaseList];
  const saveStorage = SaveStorage();      
  await saveStorage.setItem(SavedRecords.Claims, { regionId: region, claim: formClaim, xactTransactionId, operation }, jobIdx || undefined);
};

export const convertToClaimFormData = (
  claim: any,
  lossTypeOptions: Option[],
  adjusterOptions: Option[],
  propertyManagerOptions: Option[],
  insuranceCompaniesOptions: Option[],
  projectManagerOptions: Option[],
) : ClaimFormData | undefined => {
  try {
    const lossTypeCode = lossTypeOptions.find((option) => option.label === claim?.lossType)?.value;
    const adjusterCode = adjusterOptions.find((option) => option.label === claim?.adjuster)?.value;
    const propertyManagerCode = propertyManagerOptions.find((option) => option.label === claim?.propertyManager)?.value;
    const insurerCode = insuranceCompaniesOptions.find((option) => option.label === claim?.insurer)?.value;
    const projectManagerCode = projectManagerOptions.find((option) => option.label === claim?.pmNameClaim)?.value;
  
    const claimFormData : ClaimFormData = {
      brId: claim?.claimNumber.slice(0, 2),
      claimNo: claim?.claimNo,
      policyNo: claim?.policyNo,
      lossType: lossTypeCode || claim?.lossType,
      projName: claim?.projName,
      projAddr: claim?.projAddr,
      projCity: claim?.projCity,
      projProv: claim?.projProv,
      jobPcoord: projectManagerCode || claim?.jobPcoord,
      lossCat: claim?.lossCat?.toString(),
      pcId: claim?.pcId || claim?.constructionManagerId || '',
      calledIn: claim?.calledIn,
      jobDOpen: claim?.jobDateOpen,
      lossDate: claim?.lossDate,
      dedAmt: claim?.dedAmt,
      claimNumber: claim.claimNumber,
      contactPhone: claim?.contactPhone,
      adjCode: adjusterCode || claim?.adjuster,
      projZip: claim?.projZip,
      pmgrName: propertyManagerCode || claim?.propertyManager,
      claimCo: insurerCode || claim?.insurer,
      contactName: claim?.contactName,
      phases: claim.phases,
      claimIndx: claim.claimIndx,
      yearOfConstruction: claim.yearOfConstruction,
      projEmail: claim.projEmail,
      verticalId: claim?.verticalId,
      verticalName: claim?.verticalName,
      projPh1: claim?.projPh1,
      projPh2: claim?.projPh2,
      projPh3: claim?.projPh3,
      projPhone1: claim?.projPhone1,
      projPhone2: claim?.projPhone2,
      projPhone3: claim?.projPhone3,
    };
    return claimFormData;
  } catch {
    return undefined;
  }
};

type StoreClaimDetails = {
  _data: any;
  user: User | undefined;
  region: number;
  lossTypeOptions: Option[];
  adjusterOptions: Option[];
  propertyManagerOptions: Option[];
  insuranceCompaniesOptions: Option[];
  projectManagerOptions: Option[];
};

export const storeClaimDetails = async ({
  _data,
  user, 
  region,
  lossTypeOptions,
  adjusterOptions,
  propertyManagerOptions,
  insuranceCompaniesOptions,
  projectManagerOptions,
} : StoreClaimDetails) => {
  const claimData = convertToClaimFormData(
    _data.claim,
    lossTypeOptions,
    adjusterOptions,
    propertyManagerOptions,
    insuranceCompaniesOptions,
    projectManagerOptions,
  );
  if (!claimData) {
    return;
  }
  claimData.status = ClaimOnlineStatus.AvailableOffline;
  const claimIndx = _data?.claim?.claimIndx;
  const lossAddressLat = _data?.claim?.lossAddressLat;
  const lossAddressLon = _data?.claim?.lossAddressLon;
  const xactTransactionId = null;
  await saveClaim({
    data: claimData,
    phaseList: claimData.phases || [],
    user,
    claimIndx,
    lossAddressLat,
    lossAddressLon,
    region,
    xactTransactionId,
    jobIdx: claimData.claimIndx || 0,
    operation: SaveClaimOperation.EDIT,
  });
};

export const useCreateNewClaimMutation = () => {
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading: saving, mutateAsync } = useMutation({
    mutationFn: (data: any) => graphQLClient.request(SAVE_CLAIM, data),
  });

  return { saving, mutateAsync };
};

export const useEditClaimMutation = () => { 
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading: updating, mutateAsync: updateClaim } = useMutation({
    mutationFn: (data: any) => graphQLClient.request(UPDATE_CLAIM, data),
  });
  return { updating, updateClaim };
};

const cleanUpBeforeSave = (claimData: StorageRecord) => {
  const operation = claimData?.data?.operation;
  if (operation === SaveClaimOperation.ADD) {
    delete claimData.data.claim.claimIndx;
  } else {
    // remove fields that are not editable
    delete claimData.data.claim.claimNumber;
    delete claimData.data.claim.jobPcoord;
    delete claimData.data.claim.brId;
    delete claimData.data.claim.phases;
    delete claimData.data.claim.claimNumber;
    delete claimData.data.claim.pmnameClaim;
    delete claimData.data.claim.adjuster;
    delete claimData.data.claim.insurer;
  }
  delete claimData.data.claim.tempId;
  delete claimData.data.claim.status;
};

export const syncClaimDetails = async (
  claimData: StorageRecord,
  saveStorage: SaveStorageReturn,
  mutateSaveClaimAsync: any,
  mutateEditClaimAsync: any,
) => {
  try {
    const operation = claimData?.data?.operation;
    const cloneClaimData = deepClone(claimData);
    cleanUpBeforeSave(cloneClaimData);
    let res;
    if (operation === SaveClaimOperation.ADD) {
      res = await mutateSaveClaimAsync(cloneClaimData.data);
    } else {
      res = await mutateEditClaimAsync(cloneClaimData.data);
    }
    if (res?.saveClaim?.status === ApiStatus.SUCCESS || res?.updateClaim?.status === ApiStatus.SUCCESS) {
      claimData.data.claim.status = ClaimOnlineStatus.AvailableOffline;
      await saveStorage.setItem(SavedRecords.Claims, claimData.data, claimData.primaryKey);

      toast.success(t('claimSaved'), { duration: 4000 });
    } else {
      throw new Error(res.saveClaim?.message || t('saveClaimError'));
    }
  } catch (e: any) {
    const msg = getErrorMsg(e);
    toast.error(msg || t('saveClaimError'), { duration: 4000 });
  }
};