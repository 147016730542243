import { gql } from '@apollo/client';

const GET_EQUIPMENT_CONDITIONS_LIST = gql`
query conditions($regionId: Int!) {
  conditions(regionId: $regionId)
  { 
    id
    value
  } 
}`;

export default GET_EQUIPMENT_CONDITIONS_LIST;