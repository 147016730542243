import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  squareOutline,
  ellipseOutline,
  pencilOutline,
  imageOutline,
  textOutline,
  handRightOutline,
  locateOutline,
  layersOutline,
  settingsOutline,
  hammerOutline,
  arrowUndoOutline,
  arrowRedoOutline,
  analyticsOutline,
  moveOutline,
} from 'ionicons/icons';
import { useResizeDetector } from 'react-resize-detector';
import {
  Sketch,
  SketchExport,
  SketchObject,
  Text,
  SketchTool as Tool,
  SketchGroup,
  Rect,
  SystemOfMeasurement,
} from '../sketch-tool';
import SketchToolObjectEdit from './SketchToolObjectEdit';
import SketchToolLayersView from './SketchToolLayersView';
import SketchToolSettings from './SketchToolSettings';
import SketchToolInlineTextEdit from './SketchToolInlineTextEdit';
import SketchToolObjectLib from './SketchToolObjectLib';
import objectLib from '../sketch-tool/object-lib';
import SketchToolPenToolSettings from './SketchToolPenToolSettings';
import './SketchTool.css';
import SketchToolRectToolSettings from './SketchToolRectToolSettings';
import SketchToolEllipseToolSettings from './SketchToolEllipseToolSettings';
import SketchToolWallToolSettings from './SketchToolWallToolSettings';
import useImperialMeasurePicker from '../hooks/imperialMeasurePicker';
import useMetricMeasurePicker from '../hooks/metricMeasurePicker';
import SketchToolImageToolSettings from './SketchToolImageToolSettings';

interface SketchToolProps {
  isOpen: boolean;
  onSave: (sketchExport: SketchExport) => any;
  onClose: (newObjects?: SketchObject[]) => any;
  onDeleteObject: (obj: SketchObject) => any;
  saveFile: (file: File) => Promise<string>;
  sketch?: SketchExport | null;
}

const SketchTool: FC<SketchToolProps> = ({ sketch, isOpen, saveFile, onClose, onSave, onDeleteObject }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const sketchRef = useRef<Sketch | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showEdit, setShowEdit] = useState(false);
  const [activeTool, setActiveTool] = useState(Tool.Sel);
  const [selectedObjects, setSelectedObjects] = useState<SketchObject[]>([]);
  const [showLayers, setShowLayers] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [editingText, setEditingText] = useState<Text | null>(null);
  const [showObjectLib, setShowObjectLib] = useState(false);
  const [showToolSettings, setShowToolSettings] = useState(true);
  const [presentImperialMeasurePicker] = useImperialMeasurePicker();
  const [presentMetricMeasurePicker] = useMetricMeasurePicker();
  const { t } = useTranslation();

  const onResize = useCallback((width?: number, height?: number) => {
    const sketch = sketchRef.current;
    const canvas = canvasRef.current;
    if (!(canvas && sketch && width && height)) return;
    canvas.width = width;
    canvas.height = height;
    sketch.draw();
  }, []);

  useEffect(() => {
    const sketch = sketchRef.current!;
    if (sketch) sketch.activeTool = activeTool;
  }, [activeTool]);

  const { ref: sketchContainerRef } = useResizeDetector<HTMLDivElement>({ onResize });

  const handleTextClick = () => {
    setActiveTool(Tool.Text);
    setShowToolSettings(activeTool !== Tool.Text ? true : !showToolSettings);
  };

  const handleImageClick = () => {
    setActiveTool(Tool.Img);
    setShowToolSettings(activeTool !== Tool.Img ? true : !showToolSettings);
  };

  const handleSquareClick = () => {
    setActiveTool(Tool.Rect);
    setShowToolSettings(activeTool !== Tool.Rect ? true : !showToolSettings);
  };

  const handleEllipseClick = () => {
    setActiveTool(Tool.Ellipse);
    setShowToolSettings(activeTool !== Tool.Ellipse ? true : !showToolSettings);
  };

  const handlePencilClick = () => {
    setActiveTool(Tool.Pen);
    setShowToolSettings(activeTool !== Tool.Pen ? true : !showToolSettings);
  };

  const handleMoveClick = () => {
    setActiveTool(Tool.Move);
    setShowToolSettings(activeTool !== Tool.Move ? true : !showToolSettings);
  };

  const handleHandClick = () => {
    setActiveTool(Tool.Sel);
    setShowToolSettings(activeTool !== Tool.Sel ? true : !showToolSettings);
  };

  const handleWallClick = () => {
    setActiveTool(Tool.Wall);
    setShowToolSettings(activeTool !== Tool.Wall ? true : !showToolSettings);
  };

  const handleDelete = () => {
    const selected = sketchRef.current?.getSelected();
    if (selected?.length) {
      selected.forEach(obj => {
        sketchRef.current?.remove(obj);
        onDeleteObject(obj);
      });
    }
  };

  const handleEdit = () => {
    const sketch = sketchRef.current;
    if (!sketch) return;
    const [selected] = sketch.getSelected();
    if (selected) {
      setShowEdit(true);
    }
  };

  const handleSave = async () => {
    if (!sketchRef.current) {
      onClose([]);
    } else {
      onSave(await sketchRef.current.save());
    }
  };

  const pickFile: () => Promise<string | null> = () => new Promise(resolve => {
    const fileInput = fileInputRef.current!;
    fileInput.onchange = () => {
      const { files } = fileInput;
      if (!files || files.length <= 0) {
        resolve(null);
        return;
      }

      saveFile(files[0]).then(src => resolve(src));
      fileInput.value = '';
    };
    fileInput.click();
  });

  const handleDblTapWidth = (obj: Rect) => {
    const text = obj.getAmountFormatted(obj.width);
    if (obj.systemOfMeasurement === SystemOfMeasurement.Imperial) {
      const [currentFeets, currentInches] = obj.getImperialParts(text);
      presentImperialMeasurePicker({
        currentFeets,
        currentInches,
        handler(value) {
          const newWidth = obj.formattedDimensionToPixels(`${value.feets.value} ft ${value.inches.value} in`);
          obj.setWidth(newWidth);
        },
      });
    } else {
      const [currentMeters, currentCentimeters] = obj.getMetricParts(text);
      presentMetricMeasurePicker({
        currentMeters,
        currentCentimeters,
        handler(value) {
          const newWidth = obj.formattedDimensionToPixels(`${value.meters.value} m ${value.centimeters.value} cm`);
          obj.setWidth(newWidth);
        },
      });
    }
  };

  const handleDblTapHeight = (obj: Rect) => {
    const text = obj.getAmountFormatted(obj.height);
    if (obj.systemOfMeasurement === SystemOfMeasurement.Imperial) {
      const [currentFeets, currentInches] = obj.getImperialParts(text);
      presentImperialMeasurePicker({
        currentFeets,
        currentInches,
        handler(value) {
          const newHeight = obj.formattedDimensionToPixels(`${value.feets.value} ft ${value.inches.value} in`);
          obj.setHeight(newHeight);
        },
      });
    } else {
      const [currentMeters, currentCentimeters] = obj.getMetricParts(text);
      presentMetricMeasurePicker({
        currentMeters,
        currentCentimeters,
        handler(value) {
          const newHeight = obj.formattedDimensionToPixels(`${value.meters.value} m ${value.centimeters.value} cm`);
          obj.setHeight(newHeight);
        },
      });
    }
  };

  const handlePresent = () => {
    const s = new Sketch({
      pickFile,
      defaultObjectName: t('object'),
      lib: objectLib,
      ...(sketch && sketch),
    });
    s.clear();
    s.on('select', () => {
      setSelectedObjects(s.getSelected());
    });
    s.on('deselect', () => setSelectedObjects(s.getSelected()));
    s.on('addobject', (obj: SketchObject) => {
      if (obj instanceof Text) {
        const edit = () => {
          obj.setVisible(false);
          setEditingText(obj);
        };
        obj.on('ready', edit);
        obj.on('dbltap', edit);
      }

      if (obj instanceof Rect) {
        obj.on('dbltapwidth', handleDblTapWidth);
        obj.on('dbltapheight', handleDblTapHeight);
      }
    });
    if (sketch) s.loadObjects(sketch.objects || []);
    sketchRef.current = s;
    (window as any).sketch = s;
    const canvas = canvasRef.current!;
    const container = sketchContainerRef.current!;
    const { width, height } = container.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;
    s.setCanvas(canvas);
  };

  const handleDismiss = () => {
    if (!sketchRef.current) return;
    sketchRef.current?.destroy();
    sketchRef.current = null;
    setActiveTool(Tool.Sel);
    onClose();
  };

  const handleResetTransformClick = () => {
    sketchRef.current?.resetTransform();
  };

  const handleInlineTextEditBlur = (text: string) => {
    editingText?.setVisible(true);
    editingText?.setText(text);
    setEditingText(null);
  };

  const handleGroup = () => {
    sketchRef.current?.groupSelected();
  };

  const handleUngroup = () => {
    if (selectedObjects[0] instanceof SketchGroup) {
      sketchRef.current?.ungroup(selectedObjects[0]);
    }
  };

  const handleCloseObjectLib = (libObjectId?: number) => {
    if (sketchRef.current && typeof libObjectId === 'number') sketchRef.current.currentLibObject = libObjectId;
    setShowObjectLib(false);
  };

  const handleObjectLibClick = () => {
    setShowObjectLib(true);
    setActiveTool(Tool.LibObject);
  };

  const handleCancelClick = () => {
    const newObjects = sketchRef.current?.objects.filter(o => o.unsaved);
    onClose(newObjects);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalDidPresent={handlePresent}
      onIonModalDidDismiss={handleDismiss}
    >
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonButton onClick={handleCancelClick}>
              {t('cancel').toString()}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {t('sketchTool').toString()}
          </IonTitle>
          <IonButtons slot="end" onClick={handleSave}>
            <IonButton>
              {t('save').toString()}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div id="sketch-container" ref={sketchContainerRef} slot="fixed">
          <canvas id="sketch-canvas" ref={canvasRef} style={{ width: '100%', height: '100%' }} />
          <SketchToolInlineTextEdit textObject={editingText} onDone={handleInlineTextEditBlur} />
        </div>
        <SketchToolObjectEdit object={selectedObjects[0]} isOpen={showEdit} onClose={() => setShowEdit(false)} />
        <SketchToolLayersView
          sketch={sketchRef.current}
          isOpen={!!sketchRef.current && showLayers}
          onClose={() => setShowLayers(false)}
        />
        <SketchToolSettings
          sketch={sketchRef.current}
          isOpen={!!sketchRef.current && showSettings}
          onClose={() => setShowSettings(false)}
        />
        <SketchToolObjectLib lib={objectLib} isOpen={showObjectLib} onClose={handleCloseObjectLib} />
        <input ref={fileInputRef} type="file" accept="image/*" style={{ display: 'none' }} />
        <IonFab slot="fixed" horizontal="start" vertical="bottom">
          <IonFabButton onClick={handleResetTransformClick}>
            <IonIcon icon={locateOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
      <IonFooter>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonButton onClick={() => setShowLayers(true)}>
              <IonIcon icon={layersOutline} />
            </IonButton>
            <IonButton onClick={() => setShowSettings(true)}>
              <IonIcon icon={settingsOutline} />
            </IonButton>
            <IonButton onClick={() => sketchRef.current?.undo()}>
              <IonIcon icon={arrowUndoOutline} />
            </IonButton>
            <IonButton onClick={() => sketchRef.current?.redo()}>
              <IonIcon icon={arrowRedoOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            {selectedObjects.length === 1 && selectedObjects[0] instanceof SketchGroup && <IonButton onClick={handleUngroup}>
              {t('ungroup').toString()}
            </IonButton>}
            {selectedObjects.length > 1 && <IonButton onClick={handleGroup}>
              {t('group').toString()}
            </IonButton>}
            <IonButton onClick={handleEdit} disabled={selectedObjects.length !== 1 || selectedObjects[0] instanceof SketchGroup}>
              {t('edit').toString()}
            </IonButton>
            <IonButton onClick={handleDelete} disabled={selectedObjects.length <= 0}>
              {t('delete').toString()}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {activeTool === Tool.Pen && showToolSettings && sketchRef.current && <SketchToolPenToolSettings sketch={sketchRef.current} />}
        {activeTool === Tool.Rect && showToolSettings && sketchRef.current && <SketchToolRectToolSettings sketch={sketchRef.current} />}
        {activeTool === Tool.Ellipse && showToolSettings && sketchRef.current && <SketchToolEllipseToolSettings sketch={sketchRef.current} />}
        {activeTool === Tool.Wall && showToolSettings && sketchRef.current && <SketchToolWallToolSettings sketch={sketchRef.current} />}
        {activeTool === Tool.Img && showToolSettings && sketchRef.current && <SketchToolImageToolSettings sketch={sketchRef.current} />}
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonButton fill={activeTool === Tool.Sel ? 'solid' : undefined} onClick={handleHandClick}>
              <IonIcon icon={handRightOutline} />
            </IonButton>
            <IonButton fill={activeTool === Tool.Move ? 'solid' : undefined} onClick={handleMoveClick}>
              <IonIcon icon={moveOutline} />
            </IonButton>
            <IonButton fill={activeTool === Tool.Text ? 'solid' : undefined} onClick={handleTextClick}>
              <IonIcon icon={textOutline} />
            </IonButton>
            <IonButton fill={activeTool === Tool.Img ? 'solid' : undefined} onClick={handleImageClick}>
              <IonIcon icon={imageOutline} />
            </IonButton>
            <IonButton fill={activeTool === Tool.Rect ? 'solid' : undefined} onClick={handleSquareClick}>
              <IonIcon icon={squareOutline} />
            </IonButton>
            <IonButton fill={activeTool === Tool.Ellipse ? 'solid' : undefined} onClick={handleEllipseClick}>
              <IonIcon icon={ellipseOutline} />
            </IonButton>
            <IonButton fill={activeTool === Tool.Pen ? 'solid' : undefined} onClick={handlePencilClick}>
              <IonIcon icon={pencilOutline} />
            </IonButton>
            <IonButton fill={activeTool === Tool.Wall ? 'solid' : undefined} onClick={handleWallClick}>
              <IonIcon icon={analyticsOutline} />
            </IonButton>
            <IonButton fill={activeTool === Tool.LibObject ? 'solid' : undefined} onClick={handleObjectLibClick}>
              <IonIcon icon={hammerOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default SketchTool;
