import { useIonRouter } from '@ionic/react';
import { useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import NetworkContext from '../contexts/NetworkContext';
import { LevelModelType } from '../graphql/GetLevelsList';
import { useAuth } from '../hooks/authContext';
import useClaimLevels from '../hooks/claimLevels';
import useSaveClaimLevels from '../hooks/saveClaimLevels';
import { provideAddedLevel, provideRoomNameChange, provideLevelNameChange } from '../organism/AddLevelsHelper';
import { Phase } from '../types/Phases';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import { ApiStatus, ClaimOnlineStatus, NONE } from './helper/Const';
import { InspectionActiveOption, InspectionMenuTabs } from './helper/InspectionMenuHelper';
import useClaimDetails from '../hooks/useClaimDetails';
import { useClaimOffline } from '../contexts/ClaimOfflineContext';
import { getClaimStorage } from './helper/ClaimHelper';
import { storeClaimLevels } from './helper/offlineHelper/ClaimLevelsHelper';
import useDeleteRoom from '../hooks/deleteRoom';
import useDeleteLevel from '../hooks/deleteLevel';
import useDeleteCTFiles from '../hooks/deleteCTFiles';
import { GUID } from '../graphql/GetUserProfileInfo';
import { getErrorMsg } from './helper/AppHelper';

const SLASH_UTF8_SYMBOL = '%2F';
const SLASH_SYMBOL = '/';
const SPACE_UTF8_SYMBOL = '%20';
const SPACE_SYMBOL = ' ';
const AMPERSAND_UTF8_SYMBOL = '%26';
const AMPERSAND_SYMBOL = '&';
const ISR_REPORT_PATH = 'common/inspectionsite/report/preview';

interface RouteParams {
  jobIdx: string;
  claimNumber: string;
  lossType: string;
  yearOfConstruction: string;
}

const InspectionViewModel = () => {
  const { t, i18n } = useTranslation();
  const { userState } = useAuth()!;
  const [activeOption, setActiveOption] = useState<InspectionActiveOption>(InspectionMenuTabs.SiteInfo);
  const navigation = useIonRouter();
  const { claimNumber, lossType, jobIdx, yearOfConstruction } = useParams<RouteParams>();
  const [inspectionLevels, setInspectionLevels] = useState<LevelModelType[]>([]);
  const region = userState.userAuthPayload?.regionId!;
  const [saveError, setSaveError] = useState('');
  const [phases, setPhases] = useState<Array<Phase>>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [html, setHtml] = useState('');
  const network = useContext(NetworkContext);
  const restClient = getRestClient(userState, ClientType.PLAIN);
  const { offlineClaims : offlineClaimStorage, refresh }  = useClaimOffline()!;
  const offlineClaim = useMemo(() => offlineClaimStorage || [], [offlineClaimStorage]);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [generateRepertMessage, setGenerateReportMessage] = useState('');
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const { mutateAsync: deleteRoom, isLoading: isDeletingRoom } = useDeleteRoom(userState);
  const { mutateAsync: deleteLevel, isLoading: isDeletingLevel } = useDeleteLevel(userState);
  const { mutateAsync: deleteCTFile, isLoading: isDeletingCTFile } = useDeleteCTFiles(userState);
  const [showRoomDeletedMessage, setShowRoomDeletedMessage] = useState(false);
  const [showLevelDeletedMessage, setShowLevelDeletedMessage] = useState(false);
  const [showCTFileDeletedMessage, setShowCTFileDeletedMessage] = useState(false);
  const [showReorderImagesModal, setShowReorderImagesModal] = useState(0);

  const onClaimDetailsSuccess = async (_data : any) => {
    if (!phases?.length && _data) {
      setPhases(_data?.claim?.phases as Array<Phase>);
    }
  };
  useClaimDetails(region, !network.connected ? 0 : Number(jobIdx), userState, onClaimDetailsSuccess);

  useEffect(() => {
    if (!network.connected && !phases) {
      const getAsync = async () => {
        const { claimForm: localClaim } = await getClaimStorage(jobIdx, offlineClaim, [], [], []);
        setPhases(localClaim.phases);
      };
      getAsync();
    }
  }, [jobIdx, network.connected, offlineClaim, phases]);

  const { refetch: refetchClaimLevels } = useClaimLevels(
    region,
    Number.parseInt(jobIdx, 10),
    userState,
    inspectionLevels,
    setInspectionLevels,
  );

  const getISRHTML = () => restClient.get(ISR_REPORT_PATH, {
    params: {        
      regionId: region,
      claimIndx: parseInt(jobIdx, 10),
      frenchVersion: i18n.language === 'fr',
      userGuid: window.localStorage.getItem(GUID),
    },
  }).then((res) => {
    setHtml(res.data);
  });

  /*
  * Save claim levels call
  * */
  const { isLoading: isSaving, mutateAsync } = useSaveClaimLevels();

  /*
  * Handling save claim levels service response
  * */
  const handleSaveClaimLevels = async (inspectionLevels: LevelModelType[]) => {
    if (network.connected) {
      await mutateAsync({ region, jobIdx: Number(jobIdx), levels: inspectionLevels })
        .then(async (response) => {
          if (response?.saveLevels?.status === ApiStatus.SUCCESS) {
            await refetchClaimLevels();
            await storeClaimLevels({ levels: inspectionLevels, claimIndx: Number(jobIdx), status: ClaimOnlineStatus.AvailableOffline });
            return;
          }
          throw new Error(response.saveLevels?.message || t('saveSiteInfoError'));
        })
        .catch((e) => {
          const msg = getErrorMsg(e);
          const message = msg || t('saveSiteInfoError');
          setSaveError(message);
        });
    } else {
      // save/update levels info at storage
      await storeClaimLevels({ levels: inspectionLevels, claimIndx: Number(jobIdx), status: ClaimOnlineStatus.ToBeSynched });
      refresh();
    }
  };

  const handleLossType = (): string => {
    let newString = lossType;
    if (lossType.includes(SLASH_UTF8_SYMBOL)) {
      newString = lossType.replaceAll(SLASH_UTF8_SYMBOL, SLASH_SYMBOL);
    }
    if (lossType.includes(SPACE_UTF8_SYMBOL)) {
      newString = lossType.replaceAll(SPACE_UTF8_SYMBOL, SPACE_SYMBOL);
    }
    if (lossType.includes(AMPERSAND_UTF8_SYMBOL)) {
      newString = lossType.replaceAll(AMPERSAND_UTF8_SYMBOL, AMPERSAND_SYMBOL);
    }
    return newString;
  };

  const handleAddLevel = (newLevel?: LevelModelType, pos?: number) => {
    const levels = provideAddedLevel(inspectionLevels, newLevel, pos);
    handleSaveClaimLevels(levels);
  };

  const handleChangeRoomName = (newRoomName: string, roomPos: number, levelPos: number) => {
    const levels = provideRoomNameChange(inspectionLevels, newRoomName, roomPos, levelPos);
    setInspectionLevels(levels);
    handleSaveClaimLevels(levels);
  };

  const handleYearOfConstruction = (): string => {
    if (yearOfConstruction === NONE) {
      return '';
    }
    return yearOfConstruction;
  };

  const handleChangeLevelName = (newLevelName: string, pos: number) => {
    const levels = provideLevelNameChange(inspectionLevels, newLevelName, pos);
    setInspectionLevels(levels);
    handleSaveClaimLevels(levels);
  };

  const handleRoomDelete = (roomPos: number, levelPos: number, roomId?: number) => {

    if (roomId) {
      deleteRoom(roomId)
        .then((response) => {
          if (response?.deleteRoom?.status === ApiStatus.SUCCESS) {
            const levels = [...inspectionLevels];
            levels[levelPos].rooms.splice(roomPos, 1);
  
            setShowRoomDeletedMessage(true);
            setInspectionLevels(levels);
            refresh();
            return;
          }
          throw new Error(response.deleteRoom?.message || t('deleteRoomError'));
        });
    }
  };

  const handleLevelDelete = (levelPos: number, levelId?: number) => {

    if (levelId) {
      deleteLevel(levelId)
        .then((response) => {
          if (response?.deleteLevel?.status === ApiStatus.SUCCESS) {
            const levels = [...inspectionLevels];
            levels.splice(levelPos, 1);
  
            setShowLevelDeletedMessage(true);
            setInspectionLevels(levels);
            refresh();
            return;
          }
          throw new Error(response.deleteRoom?.message || t('deletelevelError'));
        });
    }
  };

  const handleCTFileDelete = (claimFileId: number) => {
    deleteCTFile([claimFileId])
      .then((response) => {
        if (response?.deleteCTFiles?.status === ApiStatus.SUCCESS) {
          setShowCTFileDeletedMessage(true);
          refresh();
        }
      });
  };

  const submitEvent = new Event('submit', { cancelable: true, bubbles: true });

  const onSaveClick = async () => {
    await handleSaveClaimLevels(inspectionLevels);
    document.getElementById('addSiteInspectionForm')?.dispatchEvent(submitEvent);
    document.getElementById('addSafetyForm')?.dispatchEvent(submitEvent);
  };

  const previewClick = async () => {
    await onSaveClick();
    setIsModalOpen(true);
    getISRHTML();
  };

  const onReportGenerated = () => {
    setGenerateReportMessage(t('reportGeneratedSuccesfully'));
    setIsGeneratingReport(false);
  };

  const onReportError = () => {
    setGenerateReportMessage(t('errorGeneratingReport'));
    setIsGeneratingReport(false);
  };

  return {
    navigation,
    claimNumber,
    handleLossType,
    inspectionLevels,
    activeOption,
    setActiveOption,
    isSaving,
    jobIdx,
    phases,
    handleAddLevel,
    handleChangeLevelName,
    handleChangeRoomName,
    setIsModalOpen,
    handleYearOfConstruction,
    saveError,
    setSaveError,
    html,
    isModalOpen,
    onSaveClick,
    previewClick,
    showSuccessToast,
    setShowSuccessToast,
    generateRepertMessage,
    setGenerateReportMessage,
    onReportGenerated,
    onReportError,
    isGeneratingReport,
    setIsGeneratingReport,
    handleRoomDelete,
    isDeletingRoom,
    handleLevelDelete,
    isDeletingLevel,
    showRoomDeletedMessage,
    setShowRoomDeletedMessage,
    showLevelDeletedMessage,
    setShowLevelDeletedMessage,
    handleCTFileDelete,
    isDeletingCTFile,
    showCTFileDeletedMessage,
    setShowCTFileDeletedMessage,
    showReorderImagesModal,
    setShowReorderImagesModal,
    getISRHTML,
  };
};

export default InspectionViewModel;