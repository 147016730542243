import  { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import { ExpenseDtoInput } from './expSaveExpense';
import GET_EXPENSE_DETAIL from '../graphql/ExpenseDetail';
import { GUID, REGION_ID } from '../graphql/GetUserProfileInfo';
import useBaseQuery from './baseQuery';

const useExpenseDetails = (expenseId: number, onSuccess: ((data: any) => void) | undefined) => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId! || 
  Number(window.localStorage.getItem(`${REGION_ID}_${window.localStorage.getItem(GUID) || ''}`));
  
  const { data, isLoading } = useBaseQuery(
    regionId,
    { expenseId },
    userState,
    true,
    GET_EXPENSE_DETAIL,
    ['expenseDetails'],
    DomainParams.Payroll,
    onSuccess,
  );

  return { isLoading, data: (data?.expense?.expense || {}) as ExpenseDtoInput };
};

export default useExpenseDetails;