import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  IonCheckbox,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRange,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Ellipse } from '../sketch-tool';

interface EllipseEditProps {
  object: Ellipse;
  isOpen: boolean;
  onClose: (event: any) => any;
}

const black = '#000';

const EllipseEdit: FC<EllipseEditProps> = ({ object, isOpen, onClose }: EllipseEditProps) => {
  const [radiusX, setRadiusX] = useState(0);
  const [radiusY, setRadiusY] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [mode, setMode] = useState<'fill' | 'stroke'>('fill');
  const [color, setColor] = useState(black);
  const [showDimensions, setShowDimensions] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpen) return;
    setRadiusX(object.radiusX);
    setRadiusY(object.radiusY);
    setRotation(object.rotation);
    setMode(object.mode);
    setShowDimensions(object.showDimensions);
    setColor(object.fillStyle || object.strokeStyle || black);
  }, [isOpen, object]);

  useEffect(() => {
    if (isOpen) object.setRadiusX(radiusX);
  }, [radiusX, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setRadiusY(radiusY);
  }, [radiusY, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setRotation(rotation);
  }, [rotation, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setMode(mode);
  }, [mode, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setShowDimensions(showDimensions);
  }, [showDimensions, object, isOpen]);

  useEffect(() => {
    if (isOpen) {
      object.setFillStyle(color);
      object.setStrokeStyle(color);
    }
  }, [color, object, isOpen]);

  const handleRadiusXChange = (event: any) => {
    setRadiusX(Number(event.detail.value));
  };

  const handleRadiusYChange = (event: any) => {
    setRadiusY(Number(event.detail.value));
  };

  const handleRotationChange = (event: any) => {
    setRotation(Number(event.detail.value));
  };

  const handleModeChange = (event: any) => {
    setMode(event.detail.value);
  };

  const handleShowDimensionsChange = (event: any) => {
    setShowDimensions(event.detail.checked);
  };

  const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} initialBreakpoint={0.75} breakpoints={[0, 0.25, 0.5, 0.75, 1]}>
      <IonContent className="ion-padding">
        <IonList>
          <IonListHeader>
            <IonLabel>
              {t('dimensions').toString()}
            </IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel position="stacked">
              {t('radiusX').toString()}
            </IonLabel>
            <IonInput value={radiusX} onIonChange={handleRadiusXChange} type="number" />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              {t('radiusY').toString()}
            </IonLabel>
            <IonInput value={radiusY} onIonChange={handleRadiusYChange} type="number" />
          </IonItem>
          <IonListHeader>
            <IonLabel>
              {t('rotation').toString()}
            </IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonLabel position="stacked">
              {t('degrees').toString()}
            </IonLabel>
            <IonRange
              value={rotation}
              onIonChange={handleRotationChange}
              pin
              pinFormatter={(value: number) => `${value}˚`}
              max={360}
            />
          </IonItem>
          <IonListHeader>
            <IonLabel>
              {t('style').toString()}
            </IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonLabel>
              {t('mode').toString()}
            </IonLabel>
            <IonSelect
              aria-label="mode"
              placeholder={t('rectMode')}
              value={mode}
              onIonChange={handleModeChange}
            >
              <IonSelectOption value="fill">
                {t('fill').toString()}
              </IonSelectOption>
              <IonSelectOption value="stroke">
                {t('stroke').toString()}
              </IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>
              {t('showDimensions').toString()}
            </IonLabel>
            <IonCheckbox checked={showDimensions} onIonChange={handleShowDimensionsChange} />
          </IonItem>
          <IonItem lines="none">
            <IonLabel>
              {t('color').toString()}
            </IonLabel>
            <input type="color" onChange={handleColorChange} value={color} />
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default EllipseEdit;
