/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { IonHeader, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import { filterOutline, notificationsOutline } from 'ionicons/icons';
import ButtonIcon from '../atom/ButtonIcon';
import ButtonSolid from '../atom/ButtonSolid';
import SearchInput from '../atom/SearchInput';
import './Header.css';
import FosCachedImage from '../components/FosCachedImage';

type StyleType = {
  filterButton: React.CSSProperties;
  toolBar: React.CSSProperties;
};

type Props = {
  text: string;
  collapse?: 'condense' | 'fade';
  headerType?: 'jobs' | 'claimDetails';
  isAddClaimEnabled: boolean;
  onSearchJobs?: (term: string) => any;
};

const styles: StyleType = {
  filterButton: {
    marginRight: 8,
  },
  toolBar: {
    margin: '0 20',
    display: 'flex',
    flex: 1,
    marginBottom: 9,
    marginTop: 20,
  },
};

const Header: FC<Props> = ({ text, collapse, headerType, isAddClaimEnabled, onSearchJobs }) => {
  const isCondense = collapse === 'condense';
  const toolbarClasses = `custom-ion-toolbar${isCondense ? 'custom-ion-toolbar-condense' : ''}`;
  const navigation = useIonRouter();

  return (
    <IonHeader className="custom-ion-header" collapse={collapse} mode="ios">
      <IonToolbar className={toolbarClasses}>
        {(headerType !== 'jobs' && headerType !== 'claimDetails')
          &&
          <>
            <IonTitle class="title">{text}</IonTitle>
            <FosCachedImage src="assets/images/fos-logo.png" className="logo-image" />
          </>
        }
        {headerType === 'jobs' &&
          <div className="ion-padding-horizontal" style={styles.toolBar}>
            <SearchInput
              id="search-jobs-bar"
              debounce={2000}
              onSearch={onSearchJobs}
            />
            <ButtonIcon id="filterModal" icon={filterOutline} containerStyle={styles.filterButton} />
            <ButtonIcon id="notificationModal" icon={notificationsOutline} containerStyle={styles.filterButton} />
            {isAddClaimEnabled && <ButtonSolid onClick={() => navigation.push('/new-job')} />}
          </div>
        }
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
