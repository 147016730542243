import { IonItem, IonLabel, IonNote, IonText } from '@ionic/react';
import React from 'react';
import './FosCurrencyInput.css';
import CurrencyInputField from 'react-currency-input-field';

export interface Props {
  label?: string;
  placeholder?: string;
  error?: string;
  value?: string;
  disabled?: boolean;
  prefix?: string;
  onChange?: (event: any) => any;
  disableMarginBottom?: boolean;
  required?: boolean;
}

const FosCurrencyInput: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  (
    { label, placeholder = '$0.00', error, value, prefix = '$', disabled, onChange, disableMarginBottom, required },
    ref ) => {
    const classes = ['fos-stacked-input-item'];
    if (!disableMarginBottom) {
      classes.push('ion-margin-bottom');
    }
    const marginClass = classes.join(' ');

    return (
      <IonItem lines="none" className={marginClass}>
        {label && (
          <IonLabel position="stacked" style={{ visibility: !label ? 'hidden' : undefined }}>
            {label && <IonText className="fos-stacked-input-label">{label}</IonText>}
          </IonLabel>
        )}
        <CurrencyInputField
          required={required}
          ref={ref}
          className={`fos-currency-input ${disabled ? 'disabled' : ''}`}
          prefix={prefix}
          decimalSeparator="."
          groupSeparator=","
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onValueChange={onChange}
        />
        {error && <IonNote className="fos-currency-error">{error}</IonNote>}
      </IonItem>
    );
  });

export default FosCurrencyInput;
