import { gql } from '@apollo/client';

export type ExpenseStatusesType = {
  id: number,
  name: string,
};

const GET_EXPENSES_STATUSES = gql`
query expenseStatuses {
  expenseStatuses {
    id
    name
  }
}`;

export default GET_EXPENSES_STATUSES;