import { useContext, useEffect } from 'react';
import { UserState } from './authUtils';
import { DomainParams } from './graphQLClientUtil';
import GET_SITE_INSPECTION_INFO from '../graphql/GetSiteInspectionInfo';
import useBaseQuery from './baseQuery';
import { claimsKeys } from './claims';
import NetworkContext from '../contexts/NetworkContext';
import { getClaimSiteInfo, storeClaimSiteInfo } from '../pages/helper/offlineHelper/ClaimSiteInfoHelper';
import { ClaimOnlineStatus } from '../pages/helper/Const';

const useSiteInspectionInfo = (
  userState: UserState,
  claimId: number,
  siteInspectionInfo?: any,
  setSiteInspectionInfo?: (_siteInspectionInfo: any) => void,
  onSuccess?: (_data: any) => void,
  setValuesForForm?: (_inspectionSiteInfo: any) => void,
) => {
  const network = useContext(NetworkContext);
  const enabled = network.connected && !!claimId;
  const region = userState.userAuthPayload?.regionId!;

  const localSuccess = async (_data: any) => {
    if (_data?.inspectionSiteInfo) {
      if (setSiteInspectionInfo) {
        setSiteInspectionInfo(_data?.inspectionSiteInfo);
      }
      if (setValuesForForm) {
        setValuesForForm(_data?.inspectionSiteInfo);
      }
      await storeClaimSiteInfo({ info: _data?.inspectionSiteInfo, claimIndx: claimId, status: ClaimOnlineStatus.AvailableOffline });
    }
    if (onSuccess) onSuccess(_data);
  };

  const { data, isLoading, status, refetch } = useBaseQuery(
    region,
    { claimId: claimId.toString() },
    userState,
    enabled,
    GET_SITE_INSPECTION_INFO,
    claimsKeys.inspectionSiteInfo(),
    DomainParams.Common,
    localSuccess,
  );

  useEffect(() => {
    if (!network.connected && !siteInspectionInfo && claimId) {
      const getAsync = async () => {
        const { info: localInspectionSiteInfo } = await getClaimSiteInfo(claimId);
        if (setSiteInspectionInfo) {
          setSiteInspectionInfo(localInspectionSiteInfo);
        }
        if (setValuesForForm) {
          setValuesForForm(localInspectionSiteInfo);
        }
      };
      getAsync();
    }
  }, [claimId, network.connected, setSiteInspectionInfo, setValuesForForm, siteInspectionInfo]);

  return { data, isLoading, status, refetch };
};
export default useSiteInspectionInfo;
