import React, { FC } from 'react';
import { IonBackButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import './FosToolbar.css';

type Props = {
  title?: string;
};

const FosToolbar: FC<Props> = ({ title }) => (
  <IonHeader>
    <IonToolbar className='fos-toolbar'>
      <IonButtons slot="start">
        <IonBackButton defaultHref="/" />
      </IonButtons>
      <IonTitle>{title}</IonTitle>
    </IonToolbar>
  </IonHeader>
);

export default FosToolbar;
