import { gql } from '@apollo/client';

const SAVE_REGISTER_DEVICE_TOKEN = gql`
mutation registerDeviceToken($request: RegisterDeviceTokenDtoInput) {
  registerDeviceToken(request: $request)
  {
    itemId
    status
    message
  }
}`;

export default SAVE_REGISTER_DEVICE_TOKEN;
