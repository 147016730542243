import { gql } from '@apollo/client';

export type BadgeInfo = {
  badgeNumber: string,
  title: string,
  fullName: string,
  branch: string,
  department: string,
  region: string,
  userPhoto: string,
  qrCodeImage: string
};

const GET_BADGE_INFO = gql`
query digitalBadgeInfo ($id: UUID!) {
  digitalBadgeInfo(id: $id)
  {
    badgeNumber
    title
    fullName
    branch
    department
    region
    userPhoto
    qrCodeImage
  }
}`;

export default GET_BADGE_INFO;