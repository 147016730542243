import { IonContent, IonHeader, IonList, IonPage, IonText } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ScanEquipment.css';
import FosToolbar from '../../../../components/FosToolbar';
import FosDocItem from '../../../../atom/FosDocItem';
import useScanBatchOptionsViewModel from './ScanBatchOptionsViewModel';
import { EquipmentAction } from '../../util/EquipmentUtil';

const ScanBatchOptions: React.FC = () => {
  const { t } = useTranslation();

  const {
    handleEquipmentAction,
  } = useScanBatchOptionsViewModel();

  return (
    <IonPage>
      <IonHeader>
        <FosToolbar title={t('batchScan').toString()} />
      </IonHeader>
      <IonContent className='ion-padding'>
        <div className='ion-margin-bottom'>
          <IonText>{t('scanTo').toString()}</IonText>
        </div>
        <IonList lines='none'>
          <FosDocItem
            label={t('inTransit').toString()}
            onItemClick={() => handleEquipmentAction(EquipmentAction.InTransit)}
            onArrowClick={() => { }} />
          <FosDocItem
            label={t('addToClaim').toString()}
            onItemClick={() => handleEquipmentAction(EquipmentAction.AddToClaim)}
            onArrowClick={() => { }} />
          <FosDocItem
            label={t('branchTransfer').toString()}
            onItemClick={() => handleEquipmentAction(EquipmentAction.BranchTransfer)}
            onArrowClick={() => { }} />
          <FosDocItem
            label={t('returnToBranch').toString()}
            onItemClick={() => handleEquipmentAction(EquipmentAction.ReturnToBranch)}
            onArrowClick={() => { }} />
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ScanBatchOptions;
