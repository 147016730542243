import { GraphQLClient } from 'graphql-request';
import { UserState } from './authUtils';
import { BASE_API_URL } from '../utils/Env';

export enum DomainParams {
  Common = 'common',
  Equipments = 'equipments',
  Payroll = 'payroll',
  Employees = 'employees',
  Platform = 'platform',
}

const getGraphQLClient = (userState: UserState, domainParam: DomainParams = DomainParams.Common) => {
  const API_URL = `${BASE_API_URL}/${domainParam}/graphql`;
  return new GraphQLClient(API_URL, {
    headers: {
      Authorization: `Bearer ${userState.userAuthPayload?.accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization, Access-Control-Allow-Origin, Access-Control',
    },
  });
};

export default getGraphQLClient;
