const isProd = process.env.REACT_APP_ENV === 'prod';
const isStaging = process.env.REACT_APP_ENV === 'staging';

let BASE_API_URL_LET = '';

if (isProd) {
  BASE_API_URL_LET = 'https://ca-mobile-api.azurewebsites.net';
} else if (isStaging) {
  BASE_API_URL_LET = 'https://ca-mobile-api-staging.azurewebsites.net';
} else {
  BASE_API_URL_LET = 'https://dev-ca-mobile-api.azurewebsites.net';
}

export const BASE_API_URL = BASE_API_URL_LET;

let ICC_URL_LET = 'https://qa.icc.ca/icc/form/insert_request.cfm';

if (isProd) {
  ICC_URL_LET = 'https://www.icc.ca/icc/form/insert_request.cfm';
}

export const ICC_URL = ICC_URL_LET;

export const AUTH_CONF = isProd
  ? {
    clientId: '5161a3aa-1e5b-414c-a4f6-665c616d57b6',
    tenant: '9f175ec5-875a-4cc2-b995-e65d79bb421e',
    domainHint: 'https://login.microsoftonline.com/',
    authorityUrl: 'https://login.microsoftonline.com/9f175ec5-875a-4cc2-b995-e65d79bb421e',
    scopes: ['api://795b3f9d-08d4-4265-89a7-4635770882fd/.default'],
    authorityTypeString: 'AAD',
    keyHash: 'T7cyjs2zEAZfvZJbIYuxt1F21e8=',
  }
  : {
    clientId: '82218b1d-d433-4fa0-b123-326569534251',
    tenant: '9f175ec5-875a-4cc2-b995-e65d79bb421e',
    domainHint: 'https://login.microsoftonline.com/',
    authorityUrl: 'https://login.microsoftonline.com/9f175ec5-875a-4cc2-b995-e65d79bb421e',
    scopes: ['api://84548be3-62bb-463b-a73d-6a214bc09578/.default'],
    authorityTypeString: 'AAD',
    keyHash: 'T7cyjs2zEAZfvZJbIYuxt1F21e8=',
  };
