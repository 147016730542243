import { Camera, CameraResultType, CameraSource, GalleryPhoto, GalleryPhotos } from '@capacitor/camera';
import { format as formatDate } from 'date-fns';
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { Sketch, SketchImage, Text } from '../../sketch-tool';
import { HYBRID } from '../../hooks/photo';

export enum ClaimPhotoMenuTabs {
  All = 'All',
  Rooms = 'Rooms',
  RoomDetails = 'RoomDetails',
}
const TIMESTAMP_MARGIN = 15;
const MAX_SIZE = 1280;
export type ClaimPhotoActiveOption = ClaimPhotoMenuTabs.All | ClaimPhotoMenuTabs.Rooms | ClaimPhotoMenuTabs.RoomDetails;

const resizePhotoForWeb = (webPath: string): Promise<File> => new Promise((resolve) => {
  const img = new Image();
  img.src = webPath;

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let { width, height } = img;
    if (width > MAX_SIZE) {
      height *= MAX_SIZE / width;
      width = MAX_SIZE;
    }
    if (height > MAX_SIZE) {
      width *= MAX_SIZE / height;
      height = MAX_SIZE;
    }
    canvas.width = width; // Set the desired width
    canvas.height = height; // Set the desired height
    ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const file = new File([blob!], `${Date.now()}.jpeg`, { type: 'image/jpeg' }); // Create a new File object from the Blob
      resolve(file); // Resolve with the resized image File
    }, 'image/jpeg', 0.9);
  };
});

export const addTimestampToImage: (src: string) => Promise<string> = (src: string) => new Promise((resolve) => {
  const sketch = new Sketch();
  const img = new SketchImage({ src });
  const txt = new Text({
    text: formatDate(new Date(), "yyyy-MM-dd hh:mm aaaaa'm'").toUpperCase(),
    mode: 'fill',
    color: '#fff',
    fillStyle: 'rgba(0, 0, 0, 0.5)',
  });
  const canvas = document.createElement('canvas');
  sketch.setCanvas(canvas);
  img.on('load', () => {
    canvas.width = img.width;
    canvas.height = img.height;
    sketch.add(img);
    sketch.add(txt);
    txt.setFontSize(img.height / 30);
    const { width: txtWidth } = txt.measureText();
    txt.setWidth(txtWidth + 12);
    txt.setHeight(txt.fontSize);
    txt.setX(img.width - txt.width - TIMESTAMP_MARGIN);
    txt.setY(img.height - txt.height - TIMESTAMP_MARGIN);
    resolve(sketch.toDataURL());
  });
});

export const cameraTakePicture = async (source: CameraSource): Promise<{ blobArray: File[] }> => {
  if (source === CameraSource.Photos) {
    const capturedPhotos = await Camera.pickImages({
      width: 1280,
      height: 1280,
      quality: 100,
      correctOrientation: true,
      presentationStyle: 'fullscreen',
    });

    const blobPromise = async (capture: GalleryPhotos) => {
      const blobArray : File[] = await Promise.all(capture.photos.map(async (photo: GalleryPhoto, index: number) => {
        // App keep same logic
        if (isPlatform(HYBRID)) {
          const path = Capacitor.convertFileSrc(photo.webPath!!);
          const response = await fetch(path!);
          const blob = await response.blob();
          const file = new File([blob], `${Date.now()}${index}.jpeg`);
          return file;
        } 
        const photoResize = await resizePhotoForWeb(photo.webPath);
        return photoResize;
      }));
      return blobArray;
    };

    const blobArray = await blobPromise(capturedPhotos);

    return { blobArray };
  }
  // Take a photo
  const capturedPhoto = await Camera.getPhoto({
    resultType: CameraResultType.Uri,
    source,
    width: 1280,
    height: 1280,
    quality: 100,
    correctOrientation: true,
    presentationStyle: 'fullscreen',
  });

  const promiseFile = async (path: string): Promise<File> => {
    const response = await fetch(path);
    const blob = await response.blob();
    const file = new File([blob], `${Date.now()}.jpeg`);
    return file;
  };
  let file;
  if (isPlatform(HYBRID)) {
    const path = Capacitor.convertFileSrc(capturedPhoto.path!! || capturedPhoto.webPath!!);
    file = await promiseFile(path);
  } else {
    file = await resizePhotoForWeb(capturedPhoto.webPath!!);
  }

  return { blobArray: [file] };
};

