import { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import useBaseQuery from './baseQuery';
import GET_CLAIMS_PHASES_LIST from '../graphql/GetClaimsPhasesList';

export interface ClaimPhaseType {
  code: string
  id: string
  parentId: string
  value: string
}

const useClaimsPhases = (term = '') => {
  const { userState } = useAuth()!;
  const { data, isLoading } = useBaseQuery(
    userState.userAuthPayload?.regionId!,
    { term },
    userState,
    true,
    GET_CLAIMS_PHASES_LIST,
    ['getClaimsPhases'],
    DomainParams.Common,
  );

  return { isLoading, data: (data?.claimsPhases || []) as ClaimPhaseType[] };
};

export default useClaimsPhases;