import { gql } from '@apollo/client';

export type ClaimNotesType = {
  claimIndx: number,
  dateEntered: string,
  dateRead: string,
  deptId: string,
  enteredBy: string,
  enteredByGuid: string,
  notesText: string,
  phaseIndx: number,
  recordKey: number,
  showClient: boolean,
};

const GET_CLAIM_NOTES = gql`
query claimNotes($regionId: Int!, $claimIndx: Int!, $phaseIndx: Int) {
  claimNotes(regionId: $regionId, claimIndx: $claimIndx, phaseIndx: $phaseIndx)
  {
    recordKey
    phaseIndx
    claimIndx
    deptId
    notesText
    dateEntered
    dateRead
    enteredBy
    showClient
    enteredByGuid
  }
}`;

export default GET_CLAIM_NOTES;