import { gql } from '@apollo/client';

const GET_EQUIPMENT_BY_ASSET_TAG = gql`
query equipmentByAssetTag($assetTag: String!) {
  equipmentByAssetTag(assetTag: $assetTag)
  {
    id
    region {
      id
      value
    }
    status {
      id
      value
    }
  }
}`;

export default GET_EQUIPMENT_BY_ASSET_TAG;