/* eslint-disable react/require-default-props */
import { IonButton } from '@ionic/react';
import React, { FC } from 'react';
import './ButtonOutlined.css';

type Props = {
  text: String
  onClick?: () => any;
};

const ButtonOutlined: FC<Props> = ({ text, onClick }) => (
  <IonButton onClick={onClick} class='ion-button-red-outline'>{text}</IonButton>
);

export default ButtonOutlined;