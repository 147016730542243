import { gql } from '@apollo/client';

/**
 * Batch Scan
 */
const UPDATE_EQUIPMENTS_STATUSES = gql`
mutation updateEquipmentsStatuses($regionId: Int!, $itemIds: [Int!], $statusId: Int!) {
  updateEquipmentsStatuses(regionId: $regionId, itemIds: $itemIds, statusId: $statusId)
  {
    itemId
    status
    message
  }
}`;

export default UPDATE_EQUIPMENTS_STATUSES;
