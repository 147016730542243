/* eslint-disable @typescript-eslint/no-use-before-define */
import { useIonRouter, useIonAlert } from '@ionic/react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { t } from 'i18next';
import { useMutation } from '@tanstack/react-query';
import { Variables } from 'graphql-request';
import { useAuth } from '../../../../../hooks/authContext';
import useFilterAndSearchEquipments from '../../../data/hooks/useFilterAndSearchEquipments';
import { EquipmentsType } from '../../../data/graphql/GetEquipmentsList';
import useEquipmentClasses from '../../../data/hooks/useEquipmentClasses';
import useStorage from '../../../../../hooks/storage';
import useEquipmentModels from '../../../data/hooks/useEquipmentModels';
import useEquipmentStatus from '../../../data/hooks/useEquipmentStatus';
import useEquipmentBranches from '../../../data/hooks/useEquipmentBranches';
import useEquipmentCostCategories from '../../../data/hooks/useEquipmentCostCategories';
import useEquipmentConditions from '../../../data/hooks/useEquipmentConditions';
import { ApiStatus } from '../../../../../pages/helper/Const';
import getGraphQLClient, { DomainParams } from '../../../../../hooks/graphQLClientUtil';
import UPDATE_EQUIPMENT_STATUS from '../../../data/graphql/UpdateEquipmentStatus';
import { Option } from '../../../../../utils/Interfaces';
import { EquipmentAction, EquipmentStatus, ScanEquipmentAction, ScanEquipmentOption } from '../../../util/EquipmentUtil';
import { getErrorMsg } from '../../../../../pages/helper/AppHelper';

interface RouteParams {
  classIdParam: string;
}

const EquipmentsByClassesViewModel = () => {

  const navigation = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { classIdParam } = useParams<RouteParams>();
  const classId: number = Number(classIdParam);
  const { userState, checkTokenExpiration } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const initFilter = { classIds: [+classIdParam] };
  const [filter, setFilter] = useState<any>({});
  const filterObject = Object.keys(filter).length === 0 ? { classIds: [classId], active: true } : filter;
  const variables: Variables = { regionId, filter: filterObject };
  const { equipmentsList, isFetching, refetch: refetchEquipments } = useFilterAndSearchEquipments(regionId, userState, classId, variables);
  const equipmentsSubset: EquipmentsType[] = equipmentsList.slice(0, 100);
  const history = useHistory();
  const [updateError, setUpdateError] = useState('');
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [selectedModel, setSelectedModel] = useState<Option[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Option[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Option[]>([]);

  const equipmentModels = useEquipmentModels(regionId, userState, useStorage());
  const equipmentModelsOptions = equipmentModels.filter(model => model.equipmentClassId?.toString() === classIdParam).map(model => ({ label: model.model, value: String(model.modelIndx) }));
  const equipmentBranches = useEquipmentBranches(regionId, userState, useStorage());
  const equipmentBranchesOptions = equipmentBranches.map(branch => ({ label: branch.value, value: branch.id }));
  const equipmentStatus = useEquipmentStatus(regionId, userState, useStorage());
  const equipmentStatusOptions = equipmentStatus.map(status => ({ label: status.value, value: status.id }));

  useEquipmentClasses(regionId, userState, useStorage());
  useEquipmentCostCategories(regionId, userState, useStorage());
  useEquipmentConditions(regionId, userState, useStorage());

  const callEquipmentEditionForm = (equipmentId?: number) => navigation.push(`/equipment-edition/${equipmentId}`);

  const EQUIPMENT_ACTION_SHEET = [
    {
      text: t('inTransit'),
      data: {
        action: EquipmentAction.InTransit,
      },
    },
    {
      text: t('addToClaim'),
      data: {
        action: EquipmentAction.AddToClaim,
      },
    },
    {
      text: t('branchTransfer'),
      data: {
        action: EquipmentAction.BranchTransfer,
      },
    },
    {
      text: t('returnToBranch'),
      data: {
        action: EquipmentAction.ReturnToBranch,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: EquipmentAction.CANCEL,
      },
    },
  ];

  const scanActionSheetButtons = [
    {
      text: t('single'),
      data: {
        action: ScanEquipmentAction.Single,
      },
    },
    {
      text: t('batch'),
      data: {
        action: ScanEquipmentAction.Batch,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: ScanEquipmentAction.Cancel,
      },
    },
  ];

  const handleOnDidDismiss = (action: any, equipment: EquipmentsType) => {
    switch (action) {
      case EquipmentAction.InTransit: {
        handleSelectedAction(equipment, EquipmentStatus.InTransit);
        break;
      }
      case EquipmentAction.AddToClaim: {
        if (Number(equipment.status.id) === EquipmentStatus.Available) {
          handleItemSelection(equipment);
        } else {
          setShowErrorToast(true);
        }
        break;
      }
      case EquipmentAction.BranchTransfer: {
        handleSelectedAction(equipment, EquipmentStatus.InterBranchTransit);
        break;
      }
      case EquipmentAction.ReturnToBranch: {
        presentAlert({
          header: t('confirm'),
          message: t('confirmReturnExpense'),
          buttons: [
            t('no'),
            {
              handler: () => handleSelectedAction(equipment, EquipmentStatus.Available, true),
              text: t('yes'),
            },
          ],
        });
        break;
      }
      case EquipmentAction.CANCEL:
      default: break;
    }
  };

  const handleOnDidDismissScanOption = (action: any) => {
    switch (action) {
      case ScanEquipmentAction.Single: {
        navigateToScanEquipment(ScanEquipmentOption.Single);
        break;
      }
      case ScanEquipmentAction.Batch: {
        navigateToScanBatchOptions();
        break;
      }
      case ScanEquipmentAction.Cancel:
      default: break;
    }
  };

  const navigateToScanEquipment = (scanType: string) => navigation.push(`/scan-equipment/${scanType}`);

  const navigateToScanBatchOptions = () => navigation.push('/scan-batch-options');

  const onSearchTerm = (term: string) => {
    const newFilter = { ...filter };
    newFilter.searchStr = term;
    newFilter.classIds = [classId];
    onFilterChange(newFilter);
  };

  const onAddClick = () => {
    callEquipmentEditionForm();
  };

  const handleSelectedAction = (equipment: EquipmentsType, newStatus: EquipmentStatus, returnToBranch: boolean = false) => {
    if (returnToBranch && Number(equipment.status.id) !== EquipmentStatus.Available) {
      onSubmit(newStatus, equipment);
    } else if (Number(equipment.status.id) === EquipmentStatus.Available && !returnToBranch) {
      onSubmit(newStatus, equipment);
    } else {
      setShowErrorToast(true);
    }
  };

  const onEditClick = (equipmentId: number) => {
    callEquipmentEditionForm(equipmentId);
  };

  const handleItemSelection = (equipment: EquipmentsType) => {
    if (Number(equipment.status.id) === EquipmentStatus.Available) {
      history.push({
        pathname: '/equipment-assignment',
        state: equipment,
      });
    } else {
      setShowErrorToast(true);
    }
  };

  /* 
  * Submit update
  * */
  const onSubmit = async (statusId: number, equipment: EquipmentsType) => {
    await checkTokenExpiration();
    mutateAsync({ statusId, equipment })
      .then((response) => {
        if (response?.updateEquipmentStatus?.status === ApiStatus.SUCCESS) {
          setShowSuccessToast(true);
          refetchEquipments();
          return;
        }
        throw new Error(response.saveEquipment?.message || t('updateEquipmentStatusError'));
      })
      .catch((e) => {
        const msg = getErrorMsg(e);
        const message = msg || t('updateEquipmentStatusError');
        setUpdateError(message);
      });
  };

  /*
  * Mutation to save the update
  * */
  const { isLoading: isSaving, mutateAsync } = useMutation({
    mutationFn: (params: any) =>
      getGraphQLClient(userState, DomainParams.Equipments).request(UPDATE_EQUIPMENT_STATUS, {
        regionId,
        itemId: params.equipment.id,
        statusId: params.statusId,
      }),
  });

  const onFilterChange = (newFilter: any) => {
    setFilter(newFilter);
  };

  const clearFilter = () => {
    setSelectedModel([]);
    setSelectedBranch([]);
    setSelectedStatus([]);
    onFilterChange(initFilter);
  };

  const showResults = () => {
    onFilterChange(filter);
  };

  const handleModelChange = (selectedOptions: Option[]) => {
    setSelectedModel(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions.length > 0) {
      newFilter.modelIds = selectedOptions.map(o => Number(o.value));
    } else {
      newFilter.modelIds = undefined;      
    }
    newFilter.classIds = [classId];
    setFilter(newFilter);
  };

  const handleBranchChange = (selectedOptions: Option[]) => {
    setSelectedBranch(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions.length > 0) {
      newFilter.branchIds = selectedOptions.map(o => o.value);
    } else {
      newFilter.branchIds = undefined;      
    }
    newFilter.classIds = [classId];
    setFilter(newFilter);
  };

  const handleStatusChange = (selectedOptions: Option[]) => {
    setSelectedStatus(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions.length > 0) {
      newFilter.statusIds = selectedOptions.map(o => Number(o.value));
    } else {
      newFilter.statusIds = undefined;      
    }
    newFilter.classIds = [classId];
    setFilter(newFilter);
  };

  return {
    EQUIPMENT_ACTION_SHEET,
    isFetching,
    isSaving,
    showErrorToast,
    showSuccessToast,
    updateError,
    equipmentsSubset,
    selectedModel,
    selectedBranch,
    selectedStatus,
    equipmentBranchesOptions,
    equipmentStatusOptions,
    equipmentModelsOptions,
    scanActionSheetButtons,
    showResults,
    clearFilter,
    handleModelChange,
    handleBranchChange,
    handleStatusChange,
    onFilterChange,
    handleOnDidDismiss,
    onSearchTerm,
    onEditClick,
    onAddClick,
    refetchEquipments,
    handleItemSelection,
    setShowErrorToast,
    setShowSuccessToast,
    setUpdateError,
    handleOnDidDismissScanOption,
  };

};

export default EquipmentsByClassesViewModel;

