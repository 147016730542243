import { Rect, RectConfig, RectExport } from './rect';
import { SketchObjectType } from './sketch-object';

export type TextAlign = 'start' | 'end' | 'left' | 'right' | 'center';

export interface TextConfig extends RectConfig {
  text?: string;
  font?: string;
  fontSize?: number;
  textAlign?: TextAlign;
  color?: string;
}

export interface TextExport extends Omit<RectExport, 'type'> {
  type: SketchObjectType.Text;
  text?: string;
  font?: string;
  fontSize?: number;
  textAlign?: TextAlign;
  color?: string;
}

export class Text extends Rect {
  text: string;

  font: string;

  fontSize: number;

  textAlign: TextAlign;

  color: string;

  constructor(config: TextConfig) {
    super({ ...config, showDimensions: false, showName: false, showSquareFootage: false });
    const {
      text = '',
      font = 'sans-serif',
      fontSize = 18,
      textAlign = 'start',
      color = '',
    } = config;

    this.text = text;
    this.font = font;
    this.fontSize = fontSize;
    this.textAlign = textAlign;
    this.color = color;
  }

  setText(text: string) {
    this.emit('willchangetext');
    this.text = text;
    this.emit('update');
  }

  setFont(font: string) {
    this.font = font;
    this.emit('update');
  }

  setFontSize(fontSize: number) {
    this.fontSize = fontSize;
    this.emit('update');
  }

  setColor(color: string) {
    this.color = color;
    this.emit('update');
  }

  draw() {
    if (!this.visible || !this.canvas) return;
    super.draw();
    const canvas = this.canvas!;
    const ctx = canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.color;
    ctx.font = `${this.fontSize}px ${this.font}`;
    ctx.textAlign = this.textAlign;
    ctx.textBaseline = 'bottom';
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const lines = this.getLines();
    lines.forEach((line, idx) => ctx.fillText(line, this.x, this.y + this.fontSize * (idx + 1)));
    ctx.restore();
  }

  measureText(text?: string): TextMetrics {
    if (!text) text = this.text;
    const ctx = this.canvas?.getContext('2d');
    if (!ctx)
      return {
        width: 0,
        actualBoundingBoxAscent: 0,
        actualBoundingBoxDescent: 0,
        actualBoundingBoxLeft: 0,
        actualBoundingBoxRight: 0,
        fontBoundingBoxAscent: 0,
        fontBoundingBoxDescent: 0,
      };
    ctx.save();
    ctx.font = `${this.fontSize}px ${this.font}`;
    ctx.textAlign = this.textAlign;
    const measurements = ctx.measureText(text);
    ctx.restore();
    return measurements;
  }

  // @ts-ignore
  export(): TextExport {
    return {
      ...super.export(),
      type: SketchObjectType.Text,
      text: this.text,
      font: this.font,
      fontSize: this.fontSize,
      textAlign: this.textAlign,
      color: this.color,
    };
  }

  private getLines() {
    const ctx = this.canvas?.getContext('2d');
    if (!ctx) return [];
    const words = this.text.split(' ');
    const lines = [];
    let currentLine = words[0];
    words.forEach((word, idx) => {
      if (idx === 0) return;
      const { width } = this.measureText(`${currentLine} ${word}`);
      if (width < this.width) {
        currentLine += ` ${word}`;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });
    lines.push(currentLine);
    return lines;
  }
}

