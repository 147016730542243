import { gql } from '@apollo/client';
import { EquipmentGenericItemType } from '../../../../graphql/EquipmentGenericItemType';

export type EquipmentsType = {
  id: number;
  itemNumber: string;
  itemModel: EquipmentGenericItemType;
  itemClass: EquipmentGenericItemType;
  status: EquipmentGenericItemType;
  branch: EquipmentGenericItemType;
  transitBranch: EquipmentGenericItemType;
};

const GET_EQUIPMENTS_LIST = gql`
query equipments ($regionId: Int!, $filter: EquipmentFilterInput) {
  equipments(regionId: $regionId, filter: $filter)
  {
    id
    itemNumber 
    itemModel { 
      value 
    } 
    itemClass { 
      value 
    } 
    status { 
      id
      value 
    }
    branch {
      id
      value
    }
    transitBranch {
      id
      value
    }
  }
}`;

export default GET_EQUIPMENTS_LIST;