import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import APPROVE_EXPENSE from '../graphql/ApproveExpense';
import { GUID } from '../graphql/GetUserProfileInfo';

/*
* GraphQL call: approve Expense
* */
const useApproveExpense = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState, DomainParams.Payroll);
  const userGuid = window.localStorage.getItem(GUID) as string;
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (expenseId: number) => graphQLClient.request(APPROVE_EXPENSE, { userGuid, expenseId }),
  });
  return { isLoading, mutateAsync };
};

export default useApproveExpense;