import React from 'react';
import { IonCol, IonItem, IonLabel, IonNote, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import FosCurrencyInput from '../components/FosCurrencyInput';
import useExpenseTaxTypes from '../hooks/expTaxTypes';

export interface ExpTax {
  total: string
  taxType: string
}

interface ExpenseTaxesProps {
  location: string
  taxes: ExpTax[]
  error?: string
  editMode: boolean
  isRequired: (isRequired: boolean) => any
  onChange: (taxes: ExpTax[]) => any
}

const ExpenseTaxes: React.FC<ExpenseTaxesProps> = ({ location, taxes, error, editMode, isRequired, onChange }) => {
  const { t } = useTranslation();
  const { data: allTaxTypes } = useExpenseTaxTypes();
  const taxTypes = allTaxTypes.filter(tt => tt.province === location && !tt.name.startsWith('TAX'));

  isRequired(taxTypes.length > 0);

  const handleChange = (val: string, taxType: string) => {
    const result = taxTypes.map(({ name }) => ({
      id: 0,
      glAccountIndx: 0,
      includeInNet: false,
      isOverride: false,
      parentId: 0,
      totalOverride: 0,
      totalRestricted: 0,
      taxType: name,
      total: name === taxType
        ? val
        : taxes.find(item => item.taxType === name)?.total || '',
    }));
    onChange(result);
  };

  return (
    <>
      {taxTypes.map((tax) => {
        const value = taxes.find(v => v.taxType === tax.name)?.total ?? '';
        return (
          <IonRow key={tax.id}>
            <IonCol>
              <IonItem lines="none">
                <IonLabel>{`${t('tax').toString()} (${tax.name}) *`}</IonLabel>
                <FosCurrencyInput
                  disabled={!editMode}
                  required
                  value={`${value}`}
                  onChange={(val) => handleChange(val, tax.name)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
        );
      })}
      {error && taxTypes.length > 0 && <p className="ion-no-margin ion-padding-start">
        <small>
          <IonNote color="danger">{error}</IonNote>          
        </small>
      </p>}
    </>
  );
};

export default ExpenseTaxes;
