import { gql } from '@apollo/client';

const SAVE_MILEAGE_EXPENSE = gql`
mutation saveMileageExpense($userGuid: UUID!, $expenses: [MileageExpenseDtoInput], $releaseAfterSave: Boolean!) {
  saveMileageExpense(userGuid: $userGuid, expenses: $expenses, releaseAfterSave: $releaseAfterSave)
  {
    itemId
    status
    message
  }
}`;

export default SAVE_MILEAGE_EXPENSE;
