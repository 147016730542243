/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonList,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonModal,
  IonButtons,
  IonLabel,
  IonItem,
  IonPage,
  IonLoading,
  IonToast,
  IonText,
  IonDatetime,
  IonNote,
} from '@ionic/react';
import { Controller } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { closeOutline } from 'ionicons/icons';
import { t } from 'i18next';
import moment from 'moment';
import StackedInput from '../atom/StackedInput';
import FormItemDivider from '../atom/FormItemDivider';
import AddPhaseForm from '../organism/AddPhaseForm';
import { CALLED_IN_BY, CATEGORIES, PROVINCES, ClaimType, Region } from './helper/Const';
import ImportAssignmentModal from '../organism/ImportAssignmentModal';
import './AddJob.css';
import AutocompleteSearch from '../molecule/AutocompleteSearch';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
import useAddJobViewModel from './AddJobViewModel';
import FosCurrencyInput from '../components/FosCurrencyInput';
import addCurrencyMask from '../utils/CurrencyMask';
import { SaveClaimOperation } from './helper/offlineHelper/ClaimDetailsHelper';
import FosCachedImage from '../components/FosCachedImage';
import { VerticalWrapperComponent } from '../organism/ClaimVertical';

const AddJob: React.FC = () => {
  const {
    navigation,
    onSubmit,
    setIsAssignmentModalOpen,
    claim,
    register,
    errors,
    control,
    brancheOptions,
    projectManagerOptions,
    constructionManagerOptions,
    adjusterOptions,
    insuranceCompaniesOptions,
    propertyManagerOptions,
    lossTypeOptions,
    verticalOptions,
    onAutoCompleteOptionSelect,
    isReadOnlyField,
    suggestions,
    onAddressChange,
    closeIcon,
    phaseList,
    isPhaseModalOpen,
    setIsPhaseModalOpen,
    deleteItem,
    isAssignmentModalOpen,
    preSelectedPhase,
    addPhaseItem,
    saving,
    updating,
    onCloseImportAssignment,
    isLoading,
    saveError,
    setSaveError,
    addPhaseClick,
    showSuccessToast,
    setShowSuccessToast,
    showSuccessLocallyToast,
    setShowSuccessLocallyToast,
    operation,
    isSendToXAEnabled,
    isCommercial,
    isOtherVertical,
    region,
    maxDate,
  } = useAddJobViewModel();

  useEffect(() => {
    if (showSuccessToast) {
      toast.success(t('claimSaved'), { duration: 4000 });
      setShowSuccessToast(false);
    }
  }, [setShowSuccessToast, showSuccessToast]);

  useEffect(() => {
    if (showSuccessLocallyToast) {
      toast.success(t('claimAddedLocally'), { duration: 4000 });
      setShowSuccessLocallyToast(false);
    }
  }, [setShowSuccessLocallyToast, showSuccessLocallyToast]);

  const [isOtherVerticalSelected, setIsOtherVerticalSelected] = useState(false);
  const [isVerticalVisible, setIsVerticalVisible] = useState(false);

  useEffect(() => {
    if (isCommercial) {
      setIsVerticalVisible(true);
    }
  }, [setIsVerticalVisible, isCommercial]);

  const handleLossCatChange = (value: string) => {
    if (value === ClaimType.Commercial.toString()) {
      setIsVerticalVisible(true);
    } else {
      setIsVerticalVisible(false);
    }
  };

  return (
    <IonPage className="add-job">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="danger" onClick={() => navigation.goBack()}>{t('cancel').toString()}</IonButton>
          </IonButtons>
          <IonTitle>{operation !== SaveClaimOperation.EDIT ? t('addClaim').toString() : t('editClaim').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton type="button" strong onClick={() => {
              document.getElementById('addJobForm')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }}>
              {t('save').toString()}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form id="addJobForm" className="fos-form" onSubmit={onSubmit}>
          {isSendToXAEnabled && 
            <IonButton fill="outline" expand="block" className="job-form-button" onClick={() => {
              setIsAssignmentModalOpen(true);
            }}>
              {t('importAssignment').toString()}
            </IonButton>
          }

          <IonList className='list-form'>
            <Controller
              control={control}
              name="brId"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="addClaimBrId"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('branch')}
                  options={brancheOptions}
                  error={error?.message}
                  disabled={isReadOnlyField}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="jobPcoord"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="addClaimProjectManager"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('projectManager')}
                  options={projectManagerOptions}
                  error={error?.message}
                  disabled={isReadOnlyField}
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="pcId"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="addClaimPcId"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('constructionManager')}
                  options={constructionManagerOptions}
                  error={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="lossCat"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="addClaimLossCat"
                  onChange={onChange}
                  onAdditionalChange={handleLossCatChange}
                  value={value}
                  name={name}
                  label={t('category')}
                  options={CATEGORIES}
                  error={error?.message}
                  required
                />
              )}
            />
            {isVerticalVisible && <Controller
              control={control}
              name="verticalId"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <VerticalWrapperComponent
                  setIsOtherVerticalSelected = {setIsOtherVerticalSelected}
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('vertical')}
                  options={verticalOptions}
                  error={error?.message}
                />
              )}
            />}
            {(region !== Region.Quebec && (isOtherVertical  || isOtherVerticalSelected)) && <Controller
              control={control}
              name="verticalName"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <StackedInput 
                  onChange={onChange}
                  onIonChange={onChange}
                  label={t('otherVertical')}
                  error={error?.message}
                  value={value}
                  name={name} />
              )} />}
            <FormItemDivider />
            <Controller
              control={control}
              name="adjCode"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="addClaimAdjuster"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('adjuster')}
                  options={adjusterOptions}
                  error={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="claimCo"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="addClaimInsurer"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('insurer')}
                  options={insuranceCompaniesOptions}
                  error={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="pmgrName"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="addClaimPropertyManager"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('propertyManager')}
                  options={propertyManagerOptions}
                  error={error?.message}
                />
              )}
            />
            <FormItemDivider />
            <Controller
              control={control}
              name="projName"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <StackedInput 
                  onChange={onChange}
                  onIonChange={onChange}
                  label={t('projectName')}
                  error={error?.message}
                  value={value}
                  name={name}
                  required />
              )} />
            <Controller
              control={control}
              name="contactName"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <StackedInput 
                  onChange={onChange}
                  onIonChange={onChange}
                  label={t('contact')}
                  error={error?.message}
                  value={value}
                  name={name} />
              )} />
            <Controller
              control={control}
              name="projEmail"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <StackedInput 
                  onChange={onChange}
                  onIonChange={onChange}
                  label={t('onsiteContacteEmail')}
                  error={error?.message}
                  value={value}
                  name={name} />
              )} />
            <FormItemDivider />
            <AutocompleteSearch
              {...register('projAddr')}
              debounce={800}
              placeholder={claim?.projAddr}
              label={t('address')}
              options={suggestions}
              onChange={onAddressChange}
              onOptionSelect={onAutoCompleteOptionSelect}
            />
            <StackedInput
              label={t('city')}
              error={errors.projCity?.message}
              {...register('projCity')}
              required
            />
            <Controller
              control={control}
              name="projProv"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="selectProjProv"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('province')}
                  options={PROVINCES}
                  error={error?.message}
                  required
                />
              )}
            />
             <Controller
              control={control}
              name="projZip"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <StackedInput 
                  onChange={onChange}
                  onIonChange={onChange}
                  label={t('postalCode')}
                  error={error?.message}
                  value={value}
                  name={name} />
              )} />
            <Controller
              control={control}
              name="yearOfConstruction"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <StackedInput 
                  onChange={onChange}
                  onIonChange={onChange}
                  label={t('yearOfConstruction')}
                  error={error?.message}
                  value={value}
                  name={name} />
              )} />
            <Controller
              control={control}
              name="contactPhone"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <StackedInput
                  label={t('phoneNumber')}
                  placeholder={claim?.contactPhone}
                  value={value}
                  onChange={onChange}
                  onIonChange={onChange}
                  error={error?.message}
                />
              )}
            />

            <FormItemDivider />
            <Controller
              control={control}
              name="calledIn"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="addClaimCalledIn"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('calledInBy')}
                  options={CALLED_IN_BY}
                  error={error?.message}
                  required
                />
              )}
            />
            {operation === SaveClaimOperation.ADD ? 
            <Controller
              control={control}
              name="jobDOpen"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <>
                <IonItem id="jobDOpen-trigger" lines="none" className='fos-select-date-item'>
                  <div className="fos-select-date-border" />
                  <IonLabel id="open-date-input" className="ion-text-wrap">
                    <IonText className='fos-select-date-label'>{t('calledInOn').toString()} *</IonText>
                  </IonLabel>              
                  <IonText className='fos-select-date-label'>{moment(value || null).isValid() ? moment(value).format('MMMM Do YYYY, h:mm a') : ''}</IonText>
                  <IonModal trigger='jobDOpen-trigger' className='center fixed-300-width'>
                    <IonDatetime
                      showDefaultButtons
                      presentation="date-time"
                      id='jobDOpen'
                      name={name}
                      size='cover'
                      value={value || null}
                      onIonChange={onChange}
                      max={maxDate}
                    />
                  </IonModal>
                </IonItem>
                {error && <p className="ion-no-margin ion-padding-start">
                  <small>
                    <IonNote color="danger">{error.message}</IonNote>
                  </small>
                </p>}
                </>
              )}
            /> : 
              <IonItem lines="none" className='fos-select-date-item ion-margin-top'>
                <div className="fos-select-date-border" />
                <IonLabel id="open-date-input" className="ion-text-wrap">
                  <IonText className='fos-select-date-label'>{t('calledInOn').toString()} *</IonText>
                </IonLabel>              
                <IonText className='fos-select-date-label'>{moment(claim?.jobDateOpen || claim?.jobDOpen || null).isValid() ? moment(claim?.jobDateOpen || claim?.jobDOpen).format('MMMM Do YYYY, h:mm a') : ''}</IonText>
              </IonItem>
            }
            <Controller
              control={control}
              name="lossDate"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <>
                <IonItem id="lossDate-trigger" lines="none" className='fos-select-date-item ion-margin-top'>
                  <div className="fos-select-date-border" />
                  <IonLabel id="loss-date-input" className="ion-text-wrap">
                    <IonText className='fos-select-date-label'>{t('lossDate').toString()} *</IonText>
                  </IonLabel>              
                  <IonText className='fos-select-date-label'>{moment(value || null).isValid() ? moment(value).format('MMMM Do YYYY') : ''}</IonText>   
                  <IonModal trigger='lossDate-trigger' className='center fixed-300-width'>
                    <IonDatetime
                      showDefaultButtons
                      presentation="date"
                      id='lossDate'
                      name={name}
                      size='cover'
                      value={value || null}
                      onIonChange={onChange}
                      max={maxDate}
                    />
                  </IonModal>
                </IonItem>
                <p className="ion-no-margin ion-padding-start ion-margin-bottom">
                  {error && 
                    <small>
                      <IonNote color="danger">{error.message}</IonNote>
                    </small>
                  }
                </p>
                </>
              )}
            /> 
            <Controller
              control={control}
              name="dedAmt"
              render={({ field: { value, onChange } }) => (
                <FosCurrencyInput
                  value={value?.toString()}
                  label={t('deductible')}
                  onChange={onChange}
                  error={errors.dedAmt?.message} />
              )} />
            <Controller
              control={control}
              name="claimNo"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <StackedInput 
                  onChange={onChange}
                  onIonChange={onChange}
                  label={t('claimNumber')}
                  error={error?.message}
                  value={value}
                  name={name} />
              )} />
            <Controller
              control={control}
              name="policyNo"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <StackedInput 
                  onChange={onChange}
                  onIonChange={onChange}
                  label={t('policyNo')}
                  error={error?.message}
                  value={value}
                  name={name} />
              )} />
            <Controller
              control={control}
              name="lossType"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="addClaimLossType"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('lossType')}
                  options={lossTypeOptions}
                  error={error?.message}
                  required
                />
              )}
            />
          </IonList>
          <IonList lines="none">
            {phaseList.length > 0 && <FormItemDivider />}
            {phaseList.map((item) =>
              <div key={ item.phaseId || item.id || item.phaseCode }>
                <div className='add-job-emergency-phase-label'>
                  <IonLabel>{`${item.phaseId || item.id || item.phaseCode} ${t('phase').toString()}`}</IonLabel>
                </div>
                <IonItem className='add-job-border-layout'>
                  <IonLabel className='job-font-size'>{t('phase').toString()}</IonLabel>
                  <IonLabel className='ion-text-right'>{item.phaseId || item.id || item.phaseCode}</IonLabel>
                </IonItem>
                {item.description &&
                  <IonItem className='add-job-border-layout'>
                    <IonLabel className='job-font-size'>{t('description').toString()}</IonLabel>
                    <IonLabel className='ion-text-right'>{item.description}</IonLabel>
                  </IonItem>
                }
                {typeof item.estValue === 'number' &&
                  <IonItem className='add-job-border-layout'>
                    <IonLabel className='job-font-size'>{t('estSale').toString()}</IonLabel>
                    <IonLabel className='ion-text-right'>{addCurrencyMask(item.estValue)}</IonLabel>
                  </IonItem>
                }
                {item.projectManager && <IonItem className='add-job-border-layout'>
                  <IonLabel>{t('projectManager').toString()}</IonLabel>
                  <IonLabel className='ion-text-right'>{item.projectManager}</IonLabel>
                </IonItem>}
                {operation !== SaveClaimOperation.EDIT && <div>
                  <IonButton className='add-job-delete-button-layout' color="danger" fill="outline" expand='block' onClick={() => deleteItem(item)}>
                    {t('deletePhase').toString()}
                  </IonButton>
                </div>}
              </div>,
            )}
            {phaseList.length > 0 && <FormItemDivider />}
          </IonList>
        </form>
        <IonModal isOpen={isPhaseModalOpen} class="custom-modal">
          <IonHeader class="ion-no-border phase-form-header">
            <IonToolbar class="header">
              <IonTitle>{t('addPhase').toString()}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsPhaseModalOpen(false)}>
                  <FosCachedImage src={closeIcon} className="phase-close-icon" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <AddPhaseForm preSelectedPhase={preSelectedPhase} onChange={addPhaseItem} phaseModalOpen={setIsPhaseModalOpen} addPhaseLabel={t('addPhase').toString()} />
          </IonContent>
        </IonModal>
        <ImportAssignmentModal isOpen={isAssignmentModalOpen} onClose={onCloseImportAssignment} />
        {operation !== SaveClaimOperation.EDIT && <IonButton fill="outline" expand="block" className="job-form-button"
          onClick={addPhaseClick}>
          {t('addPhase').toString()}
        </IonButton>}
        <IonLoading isOpen={saving || updating} message={`${t('saving')}...`} />
        <IonLoading isOpen={isLoading} message={t('loading')} duration={1} />
        <IonToast
          isOpen={!!saveError}
          message={saveError}
          buttons={[{ role: 'cancel', icon: closeOutline }]}
          onDidDismiss={() => { setSaveError(''); }}
        />
        <Toaster
          containerStyle={{
            top: 50,
            left: 20,
            bottom: 20,
            right: 20,
          }}
        />
      </IonContent>
    </IonPage >
  );
};

export default AddJob;
