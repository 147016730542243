import { gql } from '@apollo/client';

const GET_EXPENSE_CATEGORIES_BY_BRANCH = gql`
query expenseCategoriesByBranch ($branchId: Int!) {
    expenseCategoriesByBranch(branchId: $branchId)
  {
    category {
        id
        name
        accountCode
    }
    taxRestrictions {
        id
        province
        rate
        taxType
    }
  }
}`;

export default GET_EXPENSE_CATEGORIES_BY_BRANCH;