import { Drawing, DrawingExport, DrawingPoint } from './drawing';
import { SketchObjectType } from './sketch-object';

export interface WallExport extends Omit<DrawingExport, 'type'> {
  type: SketchObjectType.Wall;
}

export class Wall extends Drawing {
  getIntersection(p1: DrawingPoint) {
    for (const p2 of this.points) {
      if (this.getDistance(p1, p2) < 50) {
        return p2;
      }
    }

    return null;
  }

  replaceLastPointIfNeeded(x: number, y: number) {
    if (this.points.length > 1) this.points.pop();
    this.addPoint(x, y);
  }

  drawDimensions(): void {
    if (!this.visible || !this.canvas) return;
    if (!this.canvas || this.points.length < 2) return;
    const p0 = this.points[0];
    const p1 = this.points[1];
    const ctx = this.canvas?.getContext('2d')!;
    ctx.save();
    ctx.lineWidth = this.lineWidth;
    ctx.strokeStyle = this.strokeStyle;
    ctx.translate(p0[0], p0[1]);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-p0[0], -p0[1]);
    const x = (p0[0] + p1[0]) / 2;
    const y = (p0[1] + p1[1]) / 2;
    const dist = this.getDistance(this.points[0], this.points[1]);
    const txt = this.getAmountFormatted(Math.abs(dist));
    ctx.fillText(txt, x + 12, y - 12);
    ctx.restore();
  }

  export(): WallExport {
    return {
      ...super.export(),
      type: SketchObjectType.Wall,
    };
  }

}

export default Wall;
