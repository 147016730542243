/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
import { IonImg } from '@ionic/react';
import filenamify from 'filenamify/browser';
import React, { useContext, useEffect, useState } from 'react';
import { Filesystem, Directory } from '@capacitor/filesystem';
import NetworkContext from '../contexts/NetworkContext';
import codecWorkerRunner, { getFileImageData } from '../codecs/codecWorkerRunner';

export const CACHE_FOLDER = 'fos-cache';

ensureDirectoryExists(CACHE_FOLDER);

type Props = React.ComponentProps<typeof IonImg> & { folder?: string, localPath?: string };

const FosCachedImage: React.FC<Props> = (props) => {
  const network = useContext(NetworkContext);
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (!(props.src || props.localPath)) return;
    const getAvifFile = async () => {
      try {
        const avifUrl = new URL(props.src!);
        avifUrl.searchParams.set('avif', 'true');
        const avifFileName = filenamify(avifUrl.href);
        const file = await Filesystem.readFile({
          directory: Directory.Cache,
          path: getFilePath(avifFileName, props.folder),
        });
        return `data:image/avif;base64,${file.data}`;
      } catch (error) {
        return '';
      }
    };

    const getOriginalFile = async () => {
      try {
        const fileName = filenamify(props.src!);
        const fileType = props.src!.split('.').pop();
        const file = await Filesystem.readFile({
          directory: Directory.Cache,
          path: getFilePath(fileName, props.folder),
        });
        return `data:image/${fileType};base64,${file.data}`;
      } catch (error) {
        return '';
      }
    };

    const getFileUsingLocalPath = async () => {
      if (!props.localPath) return '';
      try {
        const file = await Filesystem.readFile({
          directory: Directory.Cache,
          path: props.localPath,
        });
        const fileType = props.localPath.includes('avif=true')
          ? 'avif'
          : 'jpg';
        return `data:image/${fileType};base64,${file.data}`;
      } catch (error) {
        return '';
      }
    };

    const getImageFromFileSystem = async () => {
      let src = await getFileUsingLocalPath();
      if (src) return setImgSrc(src);
      src = await getAvifFile();
      if (src) return setImgSrc(src);
      src = await getOriginalFile();
      if (src) return setImgSrc(src);
      // TODO: fallback to not found image when offline
      setImgSrc(props.src!);
    };

    getImageFromFileSystem();
  }, [props.src, network.connected, props.folder, props.localPath]);

  return imgSrc ? <IonImg {...props} src={imgSrc} /> : null;
};

function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
}

async function getAvifContent(originalBlob: Blob) {
  try {
    const imageData = await getFileImageData(originalBlob);
    const avifEncoded = await codecWorkerRunner.avifEncode(imageData);
    const avifEncodedBlob = new Blob([avifEncoded], { type: 'image/avif' });
    const base64 = await convertBlobToBase64(avifEncodedBlob);
    return base64;
  } catch (error) {
    console.log('Failed encoding image to AVIF. Fallback to original content.', error);
    // fallback to base64
    const base64 = await convertBlobToBase64(originalBlob);
    return base64;
  }
}

function getFilePath(fileName: string, folder?: string) {
  return folder ? `${CACHE_FOLDER}/${folder}/${fileName}` : `${CACHE_FOLDER}/${fileName}`;
}

async function replaceOriginalFileToAvif(originalBlob: Blob, url: string, folder?: string) {
  // This process can take some time so we do it in the background
  // and replace the file once it's done
  const content = await getAvifContent(originalBlob);
  const fileName = filenamify(url);
  const avifUrl = new URL(url);
  avifUrl.searchParams.set('avif', 'true');
  const avifFileName = filenamify(avifUrl.href);
  console.log('Replacing stored image with AVIF encoding - src: ', avifFileName);
  try {
    await Filesystem.writeFile({
      path: getFilePath(avifFileName, folder),
      data: content,
      directory: Directory.Cache,
    });
  } catch (error) {
    console.log('Failed saving AVIF encoded image - src: ', url, error);
    return false;
  }

  try {
    await Filesystem.deleteFile({
      path: getFilePath(fileName, folder),
      directory: Directory.Cache,
    });
  } catch (error) {
    console.log('Error removing original file when replacing with AVIF encoded - src: ', url, error);
  }

  return true;
}

async function fileExists(path: string) {
  try {
    const stat = await Filesystem.stat({
      path,
      directory: Directory.Cache,
    });
    return !!stat;
  } catch (error) {
    return false;
  }
}

function originalFileExists(url: string, folder?: string) {
  const fileName = filenamify(url);
  return fileExists(getFilePath(fileName, folder));
}

async function avifFileExists(url: string, folder?: string) {
  const avifUrl = new URL(url);
  avifUrl.searchParams.set('avif', 'true');
  const avifFileName = filenamify(avifUrl.href);
  return fileExists(getFilePath(avifFileName, folder));
}

async function ensureDirectoryExists(path: string) {
  try {
    await Filesystem.readdir({
      path,
      directory: Directory.Cache,
    });
  } catch (error) {
    await Filesystem.mkdir({
      path,
      directory: Directory.Cache,
    });
  }
}

export function createClaimFolderIfNeeded(claimIndx: number) {
  return ensureDirectoryExists(`${CACHE_FOLDER}/${claimIndx}`);
}

export async function storeImage(url: string, folder?: string) {
  try {
    if (await avifFileExists(url, folder) || await originalFileExists(url, folder)) {
      console.log('File already exists. Skipping...');
      return;
    }
    const res = await fetch(url);
    const originalBlob = await res.blob();
    const content = await convertBlobToBase64(originalBlob);
    const fileName = filenamify(url);
    await Filesystem.writeFile({
      path: getFilePath(fileName, folder),
      data: content,
      directory: Directory.Cache,
    });
    console.log('Stored original file - src: ', url);
    replaceOriginalFileToAvif(originalBlob, url, folder)
      .then((replaced) => console.log('Replaced original file to AVIF optimized - src: ', url, replaced));
  } catch (error) {
    console.log('Failed to store image', error);
  }
}

export async function jpegEncode(file: File) {
  const originalFileName = file.name.split('.')[0];
  const originalFileExtension = file.type === 'image/jpeg' ? 'jpg' : 'png';
  const fileName = `${originalFileName}-${Math.random().toString(16).slice(2)}`;
  try {
    if (file.type === 'image/jpeg') {
      const buffer = await file.arrayBuffer();
      return new File([buffer], `${fileName}.${originalFileExtension}`, { type: file.type, lastModified: file.lastModified });
    }
    const data = await getFileImageData(file);
    const result = await codecWorkerRunner.jpegEncode(data);
    return new File([result], `${fileName}.jpg`, { type: 'image/jpeg', lastModified: file.lastModified });
  } catch (error) {
    window.console.log('Error optimizing file', error);
    const buffer = await file.arrayBuffer();
    return new File([buffer], `${fileName}.${originalFileExtension}`, { type: file.type, lastModified: file.lastModified });
  }
}

export default FosCachedImage;
