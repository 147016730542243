import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ProcessQueueEvent } from '../processQueue';
import { storeImage } from '../components/FosCachedImage';
import SaveStorage, { SavedRecords } from '../utils/SaveStorage';
import NetworkContext from './NetworkContext';
import { queue, DownloadPhotoItem } from '../photoDownloadQueue';
import { useAuth } from '../hooks/authContext';

interface PhotoDownloadContextType {
  syncCount: number;
  totalCount: number;
  addItem: (item: DownloadPhotoItem) => void;
}

const defaultContext: PhotoDownloadContextType = {
  syncCount: 0,
  totalCount: 0,
  addItem: () => { },
};

const PhotoDownloadContext = createContext<PhotoDownloadContextType>(defaultContext);

const PhotoDownloadProvider = ({ children }: any) => {
  const { userState } = useAuth()!;
  const [totalCount, setTotalCount] = useState<number>(0);
  const [syncCount, setSyncCount] = useState<number>(0);
  const network = useContext(NetworkContext);

  const initQueue = useCallback(() => {
    const updateStorage = async () => {
      const saveStorage = SaveStorage();
      await saveStorage.setItem(SavedRecords.DownloadPhotosQueue, queue.queue, 1);
    };

    queue.removeAllListeners();
    queue.setProcessFn(({ src, folder }) => storeImage(src, folder));
    queue.on(ProcessQueueEvent.AddItem, async () => {
      setTotalCount(queue.countTotal());
      await updateStorage();
    });
    queue.on(ProcessQueueEvent.ItemProcessFinished, async () => {
      setSyncCount(queue.countSynced());
      await updateStorage();
    });
    queue.on(ProcessQueueEvent.AllItemsProcessFinished, async () => {
      setTotalCount(0);
      setSyncCount(0);
      await updateStorage();
    });
    queue.setInitialized(true);
    // here we don't load items from the storage
    // because if the user closes the app and open it again
    // we will retrigger the whole download process.
    // the files that were already downloaded will be skipped
  }, []);

  useEffect(() => {
    if (userState.userAuthPayload && !queue.initialized) {
      initQueue();        
    }
  }, [userState, initQueue]);

  useEffect(() => {
    if (network.connected) {
      queue.sync();
    }
  }, [network.connected]);

  const value = useMemo(() => {
    const addItem = (item: DownloadPhotoItem) => {
      queue.add(item);
      queue.sync();
    };

    return { addItem, totalCount, syncCount };
  }, [totalCount, syncCount]);

  return (
    <PhotoDownloadContext.Provider value={value}>
      {children}
    </PhotoDownloadContext.Provider>
  );
};

export { PhotoDownloadContext, PhotoDownloadProvider };

export const usePhotoDownload: () => PhotoDownloadContextType = () => useContext(PhotoDownloadContext);