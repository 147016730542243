import { gql } from '@apollo/client';

const UPDATE_PHOTOS_ROOM =  gql`mutation changeRoomInCtFiles($roomId: Int!, $ctFileIds: [Int!]) {
  changeRoomInCtFiles(roomId: $roomId, ctFileIds: $ctFileIds)
  {
    itemId
    status
    message
    errors {
      code
      value
    }
  }
}`;

export default UPDATE_PHOTOS_ROOM;