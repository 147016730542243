/* eslint-disable react/no-array-index-key */
import { IonInput, IonItem, IonLabel, IonNote, IonText } from '@ionic/react';
import React from 'react';
import './StackedInput.css';

export interface Props {
  label?: string;
  placeholder?: string;
  name?: string;
  error?: string;
  type?: 'number' | 'text' | 'tel';
  inputMode?: 'none' | 'text' | 'tel' | 'numeric' | 'decimal';
  value?: string;
  debounce?: number;
  disabled?: boolean;
  required?: boolean;
  onBlur?: (event: any) => any;
  onChange?: (event: any) => any;
  onIonChange?: (event: any) => any;
}

const StackedInput: React.FC<Props> = React.forwardRef<HTMLIonInputElement, Props>(({
  name,
  label,
  placeholder,
  error,
  type,
  inputMode,
  value,
  disabled,
  required,
  debounce,
  onChange,
  onBlur,
  onIonChange,
}, ref) => (
  <IonItem lines="none" className={`fos-stacked-input-item ion-margin-bottom ${error ? 'ion-invalid' : ''}`.trim()}>
    <IonLabel position="stacked" style={{ visibility: !label ? 'hidden' : undefined }}>
      <IonText className='fos-stacked-input-label'>
        {label || 'Label'}{required && <span className="required-asterisk"> *</span>}
      </IonText>
    </IonLabel>
    <IonInput
      ref={ref}
      name={name}
      className="fos-stacked-input"
      type={type}
      placeholder={placeholder}
      value={value}
      inputMode={inputMode}
      onChange={onChange}
      onBlur={onBlur}
      onIonChange={onIonChange}
      debounce={debounce}
      disabled={disabled}
    />
    {error && <IonNote slot="error">{error}</IonNote>}
  </IonItem>
));

export default StackedInput;
