import { gql } from '@apollo/client';

const GET_JOB_DEPARTMENTS = gql`
query jobDepartments($regionId: Int!, $projectNumber: String!) {
  jobDepartments(regionId: $regionId, projectNumber: $projectNumber)
  {
    id
    code
    parentId
    value
  }
}`;

export default GET_JOB_DEPARTMENTS;