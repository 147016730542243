export const claimsKeys = {
  all: () => ['claims'],
  list: () => ['searchClaims'],
  lossTypeFilter: () => ['lossTypes'],
  branchesList:() => ['branches'],
  projectManagerList:() => ['projectManagers'],
  adjustersList:() => ['adjusters'],
  verticalsList:() => ['verticals'],
  insuranceCompaniesList:() => ['insuranceCompanies'],
  propertyManagersList:() => ['propertyManagers'],
  constructionManagersList:() => ['projectCoordinators'],
  phaseCategories:() => ['phaseCategories'],
  claimDocuments:() => ['claimDocuments'],
  claimNotes:() => ['claimNotes'],
  communicationMessages:() => ['communicationMessages'],
  claimNotesDepartments:() => ['claimNotesDepartments'],
  claimDocumentTypes:() => ['claimDocumentTypes'],
  claimDocumentVendors:() => ['claimDocumentVendors'],
  claimPhotos:() => ['claimPhotos'],
  claimLevels:() => ['listLevels'],
  details: () => [...claimsKeys.all(), 'detail'],
  claim: () => ['claim'],
  inspectionSiteInfo: () => ['inspectionSiteInfo'],
  inspectionSafety: () => ['inspectionSafety'],
  detail: (id: string) => [...claimsKeys.details(), id],
};
