import { gql } from '@apollo/client';

const GET_SKETCHES_LIST = gql`
query listSketches($levelId: Int!) {
  listSketches(levelId: $levelId)
  { 
    sketchId
    name
    sketchObjects {
      jsonObject
      order
      sketchObjectId
    }
    unitSystem
  } 
}`;

export default GET_SKETCHES_LIST;