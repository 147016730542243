import { gql } from '@apollo/client';

export type UserExpenseSummaryType = {
  id: number,
  name: string,
  endDate: string,
  expenses: number,
  expensesCount: number,
  expenseMethodType: string,
};

const GET_USER_EXPENSES_SUMMARY = gql`
query myPendingExpensesSummary ($userGuid: UUID!) {
  myPendingExpensesSummary (employeeGuid: $userGuid) {
    id
    name
    endDate
    expenses
    expensesCount
    expenseMethodType
  }
}`;

export default GET_USER_EXPENSES_SUMMARY;