import { gql } from '@apollo/client';

const UNDO_EXPENSE = gql`
mutation UndoExpense($userGuid: UUID!, $id: Int!) {
  undoExpense(userGuid: $userGuid, id: $id) {
    status
    itemId
  }
}`;


export default UNDO_EXPENSE;
