/* eslint-disable react/no-array-index-key */


import React, { useEffect, useState } from 'react';
import { IonIcon, IonInput, IonItem, IonReorder } from '@ionic/react';
import {
  squareOutline,
  ellipseOutline,
  pencilOutline,
  imageOutline,
  textOutline,
  helpOutline,
  analyticsOutline,
} from 'ionicons/icons';
import {
  Drawing,
  Ellipse,
  Rect,
  SketchImage,
  SketchObject,
  Text,
  Wall,
} from '../sketch-tool';

interface SketchToolObjectItemProps {
  object: SketchObject;
}

const SketchToolObjectItem = ({ object }: SketchToolObjectItemProps) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(object.name);
  }, [object]);

  const getItemIcon = (obj: SketchObject) => {
    if (obj instanceof Text) return textOutline;
    if (obj instanceof Rect) return squareOutline;
    if (obj instanceof Ellipse) return ellipseOutline;
    if (obj instanceof Wall) return analyticsOutline;
    if (obj instanceof Drawing) return pencilOutline;
    if (obj instanceof SketchImage) return imageOutline;
    return helpOutline; // should never happen
  };

  const handleNameChange = (event: CustomEvent) => {
    const newName = event.detail.value || '';
    setName(newName);
    object.setName(newName);
  };

  return (
    <IonItem>
      <IonIcon slot="start" icon={getItemIcon(object)} />
      <IonInput value={name} onIonChange={handleNameChange} />
      <IonReorder slot="end" />
    </IonItem>
  );
};

export default SketchToolObjectItem;
