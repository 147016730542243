import { useQuery } from '@tanstack/react-query';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import GET_JOB_COST_TYPES from '../graphql/ExpJobCostTypes';

export interface JobCostType {
  id: string
  code: string
  parentId: string
  value: string
}

const useExpenseJobCostTypes = () => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const graphQLClient = getGraphQLClient(userState, DomainParams.Common);
  const { isLoading, data } = useQuery({
    queryKey: ['expJobCostTypes', regionId],
    queryFn: () =>
      graphQLClient.request(GET_JOB_COST_TYPES, { regionId }),
    refetchOnWindowFocus: false,
  });
  return { isLoading, data: (data?.jobCostTypes || []) as JobCostType[] };
};

export default useExpenseJobCostTypes;