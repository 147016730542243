import { UserState } from '../hooks/authUtils';
import getRestClient, { ClientType } from '../utils/AxiosClient';

const GOOGLE_PLACES_DETAILS = '/google-proxy/google-places/details';

export default function getPlaceDetails(placeId: string, userState: UserState) {
  const restClient = getRestClient(userState, ClientType.PLAIN);

  return restClient.post(GOOGLE_PLACES_DETAILS, {
    placeId,
  });
}
