import { ProcessQueue } from './processQueue';

export interface DownloadPhotoItem {
  src: string
  folder?: string
  processed?: boolean
  countFail: number;
}

export const queue = new ProcessQueue<DownloadPhotoItem>();
