import { gql } from '@apollo/client';

const GET_EQUIPMENT_COST_CATEGORIES_LIST = gql`
query equipmentCostCategories($regionId: Int!) {
  equipmentCostCategories(regionId: $regionId)
  { 
    value
    id
  } 
}`;

export default GET_EQUIPMENT_COST_CATEGORIES_LIST;