const ASSET_TAG_REGEX = /^(?:\d{7}|[A-Za-z]\d{6})$/; // It can have 7 digits or start with a letter and be followed by 6 digits.

export const isValidCode = (code: string): boolean => ASSET_TAG_REGEX.test(code);

export enum EquipmentAction {
  InTransit = 'In Transit',
  AddToClaim = 'Add to Claim',
  BranchTransfer = 'Branch Transfer',
  ReturnToBranch = 'Return to Branch ',
  CANCEL = 'Cancel',
}

export enum ScanEquipmentOption {
  Single = 'single',
  Batch = 'batch',
}

export enum EquipmentStatus {
  Available = 1,
  IssuedToJob = 2,
  Missing = 3,
  BeingRepaired = 4,
  NoLongerInService = 5,
  InterBranchTransit = 6,
  InTransit = 7,
  JobsiteIdle = 8,
}

export const getStatusId = (action: EquipmentAction): EquipmentStatus  => {
  switch (action) {
    case EquipmentAction.InTransit: return EquipmentStatus.InTransit;
    case EquipmentAction.BranchTransfer: return EquipmentStatus.InterBranchTransit;
    case EquipmentAction.ReturnToBranch:
    default: return EquipmentStatus.Available;
  }
};

export enum ScanEquipmentAction {
  Single = 'single',
  Batch = 'batch',
  Cancel = 'cancel',
}