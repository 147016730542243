import { gql } from '@apollo/client';

export const GUID = 'GUID';
export const REGION_ID = 'REGION_ID';

const GET_USER_PROFILE_INFO = gql`
query ctUser($regionId: Int!, $userGuid: UUID!) {
  ctUser(regionId: $regionId, id: $userGuid)
  { 
    userGuid
    userName
    fullName
    firstName
    lastName
    ctUserName
    regionId
    regionName
    branchId
    branchName
    areaCode
    phone
    areaCodeCell
    phoneCell
  } 
}`;

export default GET_USER_PROFILE_INFO;