import { IonButton, IonContent, IonFooter, IonLoading, IonToast } from '@ionic/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeOutline } from 'ionicons/icons';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from '../hooks/authContext';
import FosSelectItem from '../atom/FosSelectItem';
import { Option } from '../utils/Interfaces';
import FosLabelValue from '../atom/FosLabelValue';
import handleDateFormat from '../utils/DateFormat';
import FosTextArea from '../atom/FosTextArea';
import FosShareWithContact from '../atom/FosShareWithContact';
import { CustomEmailContact } from './OtherEmailsModal';
import { ContactsListType } from '../pages/helper/ContactsListType';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import { ApiStatus } from '../pages/helper/Const';
import SAVE_CLAIM_NOTE from '../graphql/SaveClaimNote';
import { ClaimNotesType } from '../graphql/GetClaimNotes';

import './ClaimNotesForm.css';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import ClaimDetailsContext from '../contexts/ClaimDetails';
import generateNotesEmailBody, { generateNotesEmailSubject } from '../utils/GenerateNotesEmailBody';
import { getErrorMsg } from '../pages/helper/AppHelper';

type Props = {
  userName: string;
  contactsList: ContactsListType[];
  claimIndx?: string;
  claimNumber?: string;
  phases: Option[];
  categories: Option[];
  sentEmails?: Array<string>;
  onSubmit: (success: boolean) => void;
  claimNote?: ClaimNotesType;

};

type FormData = {
  claimIndx: number;
  phaseIndx: number;
  enteredBy: string;
  deptId: string;
  notesText: string;
  showClient: boolean;
  recordKey: number;
};

const ClaimNotesForm: React.FC<Props> = ({
  userName,
  contactsList,
  claimIndx,
  claimNumber,
  phases,
  categories,
  sentEmails,
  onSubmit,
  claimNote,
}) => {
  const { t } = useTranslation();
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const restClient = getRestClient(userState, ClientType.PLAIN);

  const [sharedEmails, setSharedEmails] = useState<string[]>([]);
  const [selectedRecipients, setSelectedRecipients] = useState<CustomEmailContact[]>([]);
  const [saveError, setSaveError] = useState('');
  const [notesText, setNotesText] = useState('');
  const [selectedPhase, setSelectedPhase] = useState<Option>();
  const workflow = categories.find(category => category.label.toLowerCase() === 'workflow');
  const claim = useContext(ClaimDetailsContext);

  const schema = yup.object({
    phaseIndx: yup.number().required(),
    enteredBy: yup.string(),
    deptId: yup.string(),
    notesText: yup.string(),
    showClient: yup.boolean(),
  }).required();

  const { handleSubmit, register, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue('phaseIndx', claimNote?.phaseIndx ?? phases[0].value);
    setValue('enteredBy', claimNote?.enteredBy ?? userName);
    setValue('deptId', claimNote?.deptId ?? workflow?.value);
    setValue('showClient', claimNote?.showClient ?? false);
  }, [claimNote, phases, setValue, userName, workflow?.value]);

  useEffect(() => {
    setValue('notesText', notesText);
  }, [claimNote?.notesText, notesText, setValue]);

  useEffect(() => {
    setNotesText(claimNote?.notesText ?? '');
  }, [claimNote?.notesText]);

  const handleNotesTextChange = (event: any) => {
    setNotesText(event.target.value);
  };

  const sendEmail = useCallback(async (email: string) => {

    restClient.post('/platform/email/send', {
      to: email,
      body: generateNotesEmailBody({
        userName,
        emailBody: notesText,
        claimIndx: claimNumber,
        projName: claim?.projName,
        projAddr: claim?.projAddr,
      }),
      subject: generateNotesEmailSubject({ claimNumber, projName: claim?.projName, projAddr: claim?.projAddr }),
    });
  }, [userName, notesText, restClient, claimNumber, claim?.projName, claim?.projAddr]);

  const updateSelectedRecipients = useCallback((recipient: CustomEmailContact) => {
    if (recipient.selected!) {
      setSelectedRecipients([...selectedRecipients, recipient]);
    } else {
      const updatedEmails = selectedRecipients.filter((item) => item.email !== recipient.email);
      setSelectedRecipients(updatedEmails);
    }
  }, [
    selectedRecipients,
  ]);

  /*
  * Mutation to save the form
  * */
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (formData: FormData) => {
      const note: any = { ...formData };
      note.claimIndx = Number(claimIndx);
      note.recordKey = claimNote?.recordKey;
      note.sendToXact = !!selectedPhase?.linkedToXA;

      return getGraphQLClient(userState).request(SAVE_CLAIM_NOTE, { regionId, note });
    },
  });

  /*
  * Submit form
  * */
  const onSubmitHandler = handleSubmit((formData: FormData) => {
    mutateAsync(formData)
      .then((response) => {
        if (response?.saveClaimNote?.status === ApiStatus.SUCCESS) {
          selectedRecipients.forEach((contact) => sendEmail(contact.email));
          onSubmit(true);
          return;
        }

        throw new Error(response.saveClaim?.message || t('saveSiteInfoError'));
      })
      .catch((e) => {
        const msg = getErrorMsg(e);
        const message = msg || t('saveSiteInfoError');
        setSaveError(message);
      });
  });

  return (
    <>
      <IonContent className='ion-padding'>
        <form id='addNotesForm' onSubmit={onSubmitHandler}>
          <FosSelectItem
            placeholder={phases[0].label}
            label={t('phase')}
            options={phases}
            onIonChange={(e) => setSelectedPhase(e.detail.value)}
            {...register('phaseIndx')} />
          <FosLabelValue
            label={t('who')}
            value={userName}
            {...register('enteredBy')}
            disabled={claimNote !== undefined} />
          <FosSelectItem
            placeholder={categories[0]?.label}
            label={t('category')}
            options={categories}
            {...register('deptId')} />
          <FosTextArea
            value={notesText}
            placeholder={t('details')}
            rows={6}
            onIonChange={handleNotesTextChange}
            {...register('notesText')} />
            {
              claimNote?.dateEntered && (
                <FosLabelValue
                label={t('lastEdited')}
                value={handleDateFormat(claimNote?.dateEntered ?? new Date().toISOString())}
                disabled />
              )
            }
          <IonToast
            isOpen={!!saveError}
            message={saveError}
            buttons={[{ role: 'cancel', icon: closeOutline }]}
            onDidDismiss={() => { setSaveError(''); }}
            {...register('showClient')} />
          <FosShareWithContact
            userName={userName}
            title={t('shareWith')}
            contactsList={contactsList}
            note={notesText}
            noteId={claimNote?.recordKey}
            regionId={regionId}
            onEmailSent={(emails: Array<string>) => setSharedEmails([ ...sharedEmails, ...emails ])}
            sentEmails={sentEmails || sharedEmails}
            claimNumber={claimNumber || ''}
            setSelectedRecipients={updateSelectedRecipients}
          />
        </form>
      </IonContent>
      <IonFooter className='ion-padding claim-notes-form-footer'>
        <IonButton expand='block' fill='solid' onClick={onSubmitHandler}>{t('save').toString()}</IonButton>
      </IonFooter>
      <IonLoading isOpen={isLoading} message={t('saving')} duration={2000} />
    </>
  );
};

export default ClaimNotesForm;
