import { useCallback, useContext, useEffect, useState } from 'react';
import useAdjusters from '../../../hooks/adjusters';
import useInsuranceCompanies from '../../../hooks/insuranceCompanies';
import useLossTypes from '../../../hooks/lossTypes';
import useProjectManagers from '../../../hooks/projectManagers';
import usePropertyManagers from '../../../hooks/propertyManagers';
import useStorage from '../../../hooks/storage';
import { Option } from '../../../utils/Interfaces';
import useClaimDetails from '../../../hooks/useClaimDetails';
import { ProjectManagersType } from '../ProjectManagersType';
import { storeClaimDetails } from './ClaimDetailsHelper';
import { useAuth } from '../../../hooks/authContext';
import NetworkContext from '../../../contexts/NetworkContext';
import useSiteInspectionInfo from '../../../hooks/siteInspectionInfo';
import useClaimLevels from '../../../hooks/claimLevels';
import useSafetyInfo from '../../../hooks/safetyInfo';
import useClaimPhotos from '../../../hooks/claimPhotos';
import { queue } from '../../../photoDownloadQueue';
import { ProcessQueueEvent } from '../../../processQueue';
import { usePhotoDownload } from '../../../contexts/PhotoDownloadContext';
import { createClaimFolderIfNeeded } from '../../../components/FosCachedImage';

interface Props {
  region: number;
  refresh: () => Promise<void>;
}

const getJobIdx = () => {
  const downloadingJobIdx = localStorage.getItem('downloadingJobIdx');
  return downloadingJobIdx ? Number(downloadingJobIdx) : 0;
};

export const useClaimDataDownloader = ({ region, refresh }: Props) => {
  const [jobIdx, setJobIdx] = useState<number>(getJobIdx());
  const [downloadedClaim, setDownloadedClaim] = useState<boolean>(false);
  const [downloadedSiteInfo, setDownloadedSiteInfo] = useState<boolean>(false);
  const [downloadedClaimLevels, setDownloadedClaimLevels] = useState<boolean>(false);
  const [downloadedClaimSafety, setDownloadedClaimSafety] = useState<boolean>(false);
  const [downloadedPhotos, setDownloadedPhotos] = useState<boolean>(false);
  const photoDownloadContext = usePhotoDownload();
  const network = useContext(NetworkContext);
  const storage = useStorage();
  const { user, userState } = useAuth()!;
  const projectManagers = useProjectManagers(region, userState, storage);
  const projectManagerOptions: Option[] = projectManagers.map((pm: ProjectManagersType) => ({ label: pm.value, value: pm.id }));

  const propertyManagers = usePropertyManagers(region, userState, storage);
  const propertyManagerOptions = propertyManagers.map(manager => ({ label: `${manager.firstName} ${manager.lastName}`, value: manager.adjCode }));

  // load adjusters company list
  const adjusters = useAdjusters(region, userState, storage);
  const adjusterOptions = adjusters.map(adjuster => ({ label: `${adjuster.firstName} ${adjuster.lastName}`, value: adjuster.adjCode }));

  // load insurance company list
  const insurers = useInsuranceCompanies(region, userState, storage);
  const insuranceCompaniesOptions = insurers.map(insuranceCompanie => ({ label: insuranceCompanie.company, value: insuranceCompanie.coCode }));

  const lossTypes = useLossTypes(region, userState, storage);
  const lossTypeOptions = lossTypes.map(lossType => ({ label: lossType.lossCategory, value: lossType.lossCategory }));

  useEffect(() => {
    // only clear jobIdx after all download completed
    if (downloadedClaim && downloadedSiteInfo && downloadedClaimLevels && downloadedClaimSafety && downloadedPhotos) {
      setJobIdx(0);
      setDownloadedClaim(false);
      setDownloadedSiteInfo(false);
      setDownloadedClaimLevels(false);
      setDownloadedClaimSafety(false);
      setDownloadedPhotos(false);
    }
  }, [downloadedClaim, downloadedClaimLevels, downloadedClaimSafety, downloadedPhotos, downloadedSiteInfo]);

  useEffect(() => {
    const onPhotosDownloadFinished = () => {
      setDownloadedPhotos(true);
    };
    queue.on(ProcessQueueEvent.AllItemsProcessFinished, onPhotosDownloadFinished);
    return () => {
      queue.removeListener(ProcessQueueEvent.AllItemsProcessFinished, onPhotosDownloadFinished);
    };
  }, []);

  useEffect(() => {
    // Keep the current job id stored.
    // If the user closes the app and open it again
    // we will trigger the download process again
    localStorage.setItem('downloadingJobIdx', jobIdx.toString());
  }, [jobIdx]);

  const downloadClaim = useCallback((claimIndx: number) => {
    createClaimFolderIfNeeded(claimIndx)
      .then(() => setJobIdx(claimIndx));
  }, []);

  const onSuccessClaimDetails = async (_data: any) => {
    if (_data) {
      // store data
      await storeClaimDetails({
        _data,
        user,
        adjusterOptions,
        insuranceCompaniesOptions,
        lossTypeOptions,
        propertyManagerOptions,
        projectManagerOptions,
        region,
      });
      refresh();
      setDownloadedClaim(true);
    }
  };

  const onSuccessSiteInfo = (_data: any) => {
    if (_data) {
      setDownloadedSiteInfo(true);
    }
  };

  const onSuccessClaimLevels = (_data: any) => {
    if (_data) {
      setDownloadedClaimLevels(true);
    }
  };

  const onSuccessSafety = (_data: any) => {
    if (_data) {
      setDownloadedClaimSafety(true);
    }
  };

  const onSuccessClaimPhotos = async (data: any, claimIndx: number) => {
    // we can only download one claim at a time
    if (queue.syncStarted) return;
    if (data) {
      const items = (data.claimPhotos || []) as { filePath: string, thumbnailFileName: string }[];
      for (const item of items) {
        photoDownloadContext.addItem({ src: item.thumbnailFileName, processed: false, folder: `${claimIndx}`, countFail: 0 });
        photoDownloadContext.addItem({ src: item.filePath, processed: false, folder: `${claimIndx}`, countFail: 0 });
      }
    }
  };

  const claimIndx = network.connected && userState.userAuthPayload && jobIdx ? jobIdx : 0;
  useClaimDetails(region, claimIndx, userState, onSuccessClaimDetails);
  useSiteInspectionInfo(userState,
    claimIndx,
    undefined,
    undefined,
    onSuccessSiteInfo,
  );
  useClaimLevels(
    region,
    claimIndx,
    userState,
    undefined,
    undefined,
    onSuccessClaimLevels,
  );
  useSafetyInfo(
    userState,
    claimIndx === 0 ? '' : `${claimIndx}`,
    undefined,
    undefined,
    onSuccessSafety,
  );
  useClaimPhotos(
    region,
    claimIndx,
    userState,
    undefined,
    undefined,
    onSuccessClaimPhotos,
  );

  return { jobIdx, downloadClaim };
};