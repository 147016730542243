/* eslint-disable no-console */
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  isPlatform,
  useIonActionSheet,
  useIonLoading,
  useIonAlert,
} from '@ionic/react';
import { filterOutline, locationOutline } from 'ionicons/icons';
import { MultiSelect } from 'react-multi-select-component';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import ButtonIcon from '../atom/ButtonIcon';
import SearchInput from '../atom/SearchInput';
import './ClaimNotes.css';
import FosDocItem from '../atom/FosDocItem';
import handleDateFormat, { DATE_FORMAT_MM_DD_DASH_YYYY_HH_MM } from '../utils/DateFormat';
import ButtonSolid from '../atom/ButtonSolid';
import ClaimNotesForm from './ClaimNotesForm';
import { Phase } from '../types/Phases';
import { Option } from '../utils/Interfaces';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import FosModalHeader from '../atom/FosModalHeader';
import { ContactsListType } from '../pages/helper/ContactsListType';
import FilterModal from './FilterModal';
import { ClaimNotesType } from '../graphql/GetClaimNotes';
import { MessageDirection, OVERRIDE_STRINGS } from '../pages/helper/Const';
import { NotesDepartmentsType } from '../graphql/GetNotesDepartmentsList';
import OtherEmailsModal from './OtherEmailsModal';
import FosCachedImage from '../components/FosCachedImage';
import ClaimNotesHeader, { ClaimNoteMenuTabs } from '../molecule/ClaimNotesHeader';
import useClaimNotifications from '../hooks/useClaimNotifications';
import useDeleteNote from '../hooks/deleteNote';
import { useAuth } from '../hooks/authContext';
import { ClaimNotificationsType } from '../graphql/GetClaimNotifications';
import ClaimNotificationForm, { MessageChannel, MessageTemplate } from './ClaimNotificationForm';
import { HYBRID } from '../hooks/photo';
import { PermissionsList, usePermissions } from '../hooks/permissions';

const phoneIcon = 'assets/images/icons/ic_orange_phone.png';

type Props = {
  userName: string;
  contactsList: ContactsListType[];
  claimIndx: string;
  claimNotes: ClaimNotesType[];
  claimNumber: string;
  phases: Phase[];
  categories: NotesDepartmentsType[];
  refetchNotes: () => void;
  isLoading: boolean;
  handleMapButtonClick: (ev: MouseEvent) => void;
  handleCallButtonClick: (ev: MouseEvent) => void;
  defaultPhone?: string;
};

type NotesFilter = {
  enteredByList: string[] | undefined;
  phases: string[] | undefined;
  deptList: string[] | undefined;
};

const defaultNotesFilter = { enteredByList: undefined, phases: undefined, deptList: undefined };

const ClaimNotes: React.FC<Props> = ({
  userName,
  contactsList,
  claimIndx,
  claimNotes,
  claimNumber,
  phases,
  categories,
  refetchNotes,
  isLoading,
  handleMapButtonClick,
  handleCallButtonClick,
  defaultPhone,
}) => {
  const [notesList, setNotesList] = useState<ClaimNotesType[]>();
  const [presentAlert] = useIonAlert();
  const { user, userState } = useAuth()!;
  const { t } = useTranslation();
  const restClient = getRestClient(userState, ClientType.PLAIN);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const filterModal = useRef<HTMLIonModalElement>(null);
  const [filter, setFilter] = useState<NotesFilter>(defaultNotesFilter);
  const [selectedPhasesList, setSelectedPhasesList] = useState<Option[]>([]);
  const [selectedEnteredByList, setSelectedEnteredByList] = useState<Option[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Option[]>([]);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const phaseOptions: Option[] = phases.map((phase) => ({ label: phase.phaseCode, value: phase.phaseIndx, linkedToXA: phase.linkedToXA }));
  const enteredByStringList = claimNotes.map((claimNote) => claimNote.enteredBy);
  const enteredByOptions: Option[] = [...new Set(enteredByStringList)]?.map((entered: string) => ({ label: entered, value: entered }));
  const categoriesOptions: Option[] = categories.map((category) => ({ label: category.noteDepartmentDesc, value: category.noteDepartmentCode }));
  const [selectedNote, setSelectedNote] = useState<ClaimNotesType>();
  const [present] = useIonActionSheet();
  const [isCustomEmailsModalOpen, setIsCustomEmailsModalOpen] = useState(false);
  const [shareNote, setShareNote] = useState<ClaimNotesType>();
  const regionId = userState.userAuthPayload?.regionId!;
  const isDeleteEnabled = process.env.REACT_APP_ENV !== 'prod';
  const [noteSelectedOptions, setNoteSelectedOptions] = useState(ClaimNoteMenuTabs.Notes);
  const [openModal, setOpenModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<any>(null);
  const [sharedEmails, setSharedEmails] = useState<string[]>([]);
  const [presentLoading, dismiss] = useIonLoading();
  const { permissions } = usePermissions();
  const isNotificationsEnabled = permissions.includes(PermissionsList.Notifications);

  const { mutateAsync: deleteNote } = useDeleteNote(userState);

  const { claimNotificationsList: claimNotifications, isLoading: isLoadingNotification } = useClaimNotifications(regionId, Number(claimIndx), userState);

  useEffect(() => {
    if (isLoadingNotification && noteSelectedOptions === ClaimNoteMenuTabs.Notifications) {
      if (isPlatform(HYBRID)) {
        presentLoading(t('loading'));
      } else {
        presentLoading(t('loading'), 3000);
      }
    } else {
      dismiss();
    }
  }, [dismiss, isLoadingNotification, noteSelectedOptions, presentLoading, t]);

  useEffect(() => {
    const openNotification = window.localStorage.getItem('open_notifications');
    if (openNotification && noteSelectedOptions !== ClaimNoteMenuTabs.Notifications && claimNotifications.length > 0) {
      setNoteSelectedOptions(ClaimNoteMenuTabs.Notifications);
      setSelectedNotification(claimNotifications[0]);
      setOpenModal(true);
      window.localStorage.removeItem('open_notifications');
    }
  }, [claimNotifications, claimNotifications.length, noteSelectedOptions]);

  enum NotesAction {
    EDIT = 'Edit Note',
    SHARE = 'Share Note',
    DELETE = 'Delete Note',
    CANCEL = 'Cancel',
  }

  const notesActionSheetButtons = [
    {
      text: t('editNote'),
      data: {
        action: NotesAction.EDIT,
      },
    },
    {
      text: t('shareNote'),
      data: {
        action: NotesAction.SHARE,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: NotesAction.CANCEL,
      },
    },
  ];
  if (isDeleteEnabled) {
    const deleteButton = {
      text: t('deleteNote'),
      role: 'destructive',
      data: {
        action: NotesAction.DELETE,
      },
    };
    notesActionSheetButtons.splice(2, 0, deleteButton);
  }

  const handleOnItemClick = (note: ClaimNotesType) => {
    setSelectedNote(note);
    setIsModalOpen(true);
    if (note?.recordKey) {
      const fetchSharedEmails = async () => {
        try {
          const response = await restClient.get(`/common/claims/note/logs?regionId=${regionId}&noteId=${note.recordKey}`);
          const emails = response.data.map((item: { email: string }) => item.email);
          setSharedEmails(emails);
        } catch (error) {
          console.error('Failed to fetch shared emails:', error);
        }
      };
      fetchSharedEmails();
    }
  };

  const handleDeleteNote = async (note: ClaimNotesType) => {
    setIsNotificationLoading(true);
    try {
      await deleteNote({ regionId, id: note?.recordKey });
      refetchNotes();
    } finally {
      setIsNotificationLoading(false);
    }
  };

  const handleOnOptionsClick = (event: MouseEvent, note: ClaimNotesType) => {
    event.stopPropagation();
    present({
      header: t('options'),
      cssClass: 'custom-action-sheet',
      buttons: notesActionSheetButtons,
      onDidDismiss: ({ detail }) => {
        switch (detail.data?.action) {
          case NotesAction.EDIT: {
            handleOnItemClick(note);
            break;
          }
          case NotesAction.SHARE: {
            // todo: add functionality
            setIsCustomEmailsModalOpen(true);
            setShareNote(note);
            break;
          }
          case NotesAction.DELETE: {
            presentAlert({
              header: t('confirm'),
              message: t('confirmDeleteNote'),
              buttons: [
                t('cancel'),
                {
                  handler: () => handleDeleteNote(note),
                  text: t('delete'),
                },
              ],
            });
            break;
          }
          case NotesAction.CANCEL:
          default: break;
        }
      },
    });
  };

  const onSearchChange = (term: string) => {
    const filteredItems = claimNotes.filter(note => note.notesText.toLowerCase().includes(term.toLowerCase()));
    setNotesList(filteredItems);
  };

  const filterList = useCallback((notes: ClaimNotesType[]) => {
    const filters = Object.keys(filter);
    if (filters.length > 0) {
      const filteredItems = notes.filter((item) => {
        let check = true;
        if (filter.enteredByList && !filter.enteredByList.includes(item.enteredBy)) check = false;
        if (filter.phases && !filter.phases.includes(item.phaseIndx?.toString())) check = false;
        if (filter.deptList && !filter.deptList.includes(item.deptId)) check = false;
        return check;
      });
      setNotesList(filteredItems);
    } else {
      setNotesList(notes);
    }
  }, [filter]);

  const showResults = () => {
    filterList(claimNotes);
    filterModal.current?.dismiss();
  };

  useEffect(() => {
    filterList(claimNotes);
  }, [claimNotes, filterList]);

  const clearFilter = () => {
    setFilter(defaultNotesFilter);
    setNotesList(claimNotes);
    filterModal.current?.dismiss();
  };

  const onSaveNote = (success: boolean) => {
    if (success) {
      toast.success(t('claimNoteSaved'), { duration: 4000 });
    } else {
      toast.error(t('saveSiteInfoError'), { duration: 4000 });
    }
    setTimeout(() => {
      refetchNotes();
    }, 2000);
    setIsModalOpen(false);
  };

  const handlePhaseChange = (selectedOptions: Option[]) => {
    setSelectedPhasesList(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.phases = selectedOptions?.map(o => o.value.toString());
    } else {
      newFilter.phases = undefined;
    }
    setFilter(newFilter);
  };

  const handleEnteredByChange = (selectedOptions: Option[]) => {
    setSelectedEnteredByList(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.enteredByList = selectedOptions?.map(o => o.value);
    } else {
      newFilter.enteredByList = undefined;
    }
    setFilter(newFilter);
  };

  const handleCategoryChange = (selectedOptions: Option[]) => {
    setSelectedCategory(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.deptList = selectedOptions?.map(o => o.value);
    } else {
      newFilter.deptList = undefined;
    }
    setFilter(newFilter);
  };

  const handleAddNoteButton = () => {
    setSelectedNote(undefined);
    setIsModalOpen(true);
  };

  const getNotificationTitle = (notification: ClaimNotificationsType) => {
    if (notification?.submittedDateTime) {
      return handleDateFormat(notification?.submittedDateTime, DATE_FORMAT_MM_DD_DASH_YYYY_HH_MM);
    }
    return notification.subject;
  };

  const getNotificationSubtitle = (notification: ClaimNotificationsType) => {
    const type = notification?.messageType === 'MailMessage' ? MessageChannel.Email : MessageChannel.SMS;
    const regex = /(<([^>]+)>)/gi;
    const body = notification?.body?.replace(regex, ' ') || '';
    const messageTemplate = notification?.metadata?.find((n: { key: string; value: string; }) => n.key === 'messageTemplate');
    const template = messageTemplate?.value === MessageTemplate.ArrivalConfirmation ? t('arrivalConfirmation') : t('onTheWay');
    return `${type} - ${template} - ${body}`;
  };

  const openNotificationDetails = (notification: ClaimNotificationsType) => {
    setSelectedNotification(notification);
    setOpenModal(true);
  };

  return (
    <>
      <IonContent className={`ion-padding ${isNotificationsEnabled ? 'claim-notes-content' : ''}`}>
        {isNotificationsEnabled && <ClaimNotesHeader
          noteSelectedOptions={noteSelectedOptions}
          setNoteSelectedOptions={setNoteSelectedOptions}
        />}
        <IonLoading
          isOpen={isLoading || isNotificationLoading}
          message={t('loading')}
          duration={1}
        />
        {noteSelectedOptions === ClaimNoteMenuTabs.Notes ?
          <>
            <div className="notes-header">
              <SearchInput
                id="search-jobs-bar"
                debounce={1000}
                onSearch={onSearchChange}
              />
              <ButtonIcon id="filterNotesModal" icon={filterOutline} onPress={() => filterModal.current?.present()} />
              <ButtonSolid onClick={handleAddNoteButton} />
            </div>
            <IonList lines="none" className='ion-padding-top'>
              {notesList?.map((note, idx) =>
                <div key={`${note.enteredBy + idx}`}>
                  <FosDocItem
                    label={note.notesText.slice(0, 25)}
                    subheading={note?.dateEntered && `${handleDateFormat(note.dateEntered, DATE_FORMAT_MM_DD_DASH_YYYY_HH_MM)}`}
                    onItemClick={() => handleOnItemClick(note)}
                    onOptionsClick={(event: MouseEvent) => { handleOnOptionsClick(event, note); }}
                    onArrowClick={() => { }} />
                </div>,
              )}
            </IonList>
          </> : 
          <IonList lines="none" className='ion-padding-top'>
            {claimNotifications.length > 0 ? claimNotifications?.map((notification, idx) =>
              <div key={`${notification.clientMessageKey + idx}`}>
                <FosDocItem
                  label={getNotificationTitle(notification)}
                  labelLeftArrow={notification.messageDirection === MessageDirection.Incoming}
                  labelRightArrow={notification.messageDirection !== MessageDirection.Incoming}
                  subheading={getNotificationSubtitle(notification)}
                  onItemClick={() => openNotificationDetails(notification)}
                  onArrowClick={() => openNotificationDetails(notification)} />
              </div>,
            ) : <p className="ion-text-center">{t('noRecord').toString()}</p>
          }
          </IonList>
        }
        <IonModal isOpen={isModalOpen} class="custom-modal">
          <FosModalHeader title={selectedNote ? t('note').toString() : t('createNote').toString()} onCloseClick={() => setIsModalOpen(false)} />
          <ClaimNotesForm
            userName={userName}
            contactsList={contactsList}
            claimIndx={claimIndx}
            claimNumber={claimNumber}
            phases={phaseOptions}
            categories={categoriesOptions}
            claimNote={selectedNote}
            sentEmails={sharedEmails}
            onSubmit={(success: boolean) => onSaveNote(success)}
          />
        </IonModal>
      </IonContent>
      <IonFooter className="claim-details--footer">
        <IonButton
          fill="clear"
          className="claim-details--footer-buttons right-divider"
          onClick={handleMapButtonClick}
        >
          <IonIcon icon={locationOutline} />
        </IonButton>
        <IonButton
          fill="clear"
          className="claim-details--footer-buttons"
          onClick={handleCallButtonClick}
        >
          <FosCachedImage src={phoneIcon} />
        </IonButton>
      </IonFooter>
      <FilterModal
        ref={filterModal}
        trigger="filterNotesModal"
        onClear={clearFilter}
        onClose={() => filterModal.current?.dismiss()}
        onShowResults={showResults}>
        <div className='ion-margin-top'>
          <IonLabel>{t('phase').toString()}</IonLabel>
          <MultiSelect
            options={phaseOptions}
            value={selectedPhasesList}
            onChange={handlePhaseChange}
            labelledBy="Select"
            overrideStrings={OVERRIDE_STRINGS(t)} />
        </div>
        <div className='ion-margin-top'>
          <IonLabel>{t('user').toString()}</IonLabel>
          <MultiSelect
            options={enteredByOptions}
            value={selectedEnteredByList}
            onChange={handleEnteredByChange}
            labelledBy="Select"
            overrideStrings={OVERRIDE_STRINGS(t)} />
        </div>
        <div className='ion-margin-top'>
          <IonLabel>{t('category').toString()}</IonLabel>
          <MultiSelect
            options={categoriesOptions}
            value={selectedCategory}
            onChange={handleCategoryChange}
            labelledBy="Select"
            overrideStrings={OVERRIDE_STRINGS(t)} />
        </div>
      </FilterModal>
      <IonModal isOpen={openModal} class="custom-modal">
          <FosModalHeader title={t('createNotification').toString()} onCloseClick={() => setOpenModal(false)} />
          <ClaimNotificationForm
            userName={user?.fullName ?? ''}
            claimIndx={claimIndx?.toString()}
            onSubmit={() => {}}
            notification={selectedNotification}
            defaultPhone={defaultPhone || t('phoneNumberPlaceHolder')}
          />
        </IonModal>
      <OtherEmailsModal
        isModalOpen={isCustomEmailsModalOpen}
        note={shareNote?.notesText || ''}
        noteId={shareNote?.recordKey || 0}
        regionId={regionId}
        claimNumber={claimNumber}
        userName={userName}
        onDismiss={(status?: string, sentEmails?: Array<string>) => {
          setIsCustomEmailsModalOpen(false);
          if (status === 'success' && sentEmails) {
            toast.success(t('emailSent').toString(), { duration: 4000 });
          }
        }}
      />
      <Toaster />
    </>
  );
};

export default ClaimNotes;
