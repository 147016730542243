import { IonInput, IonLabel, IonList, IonNote } from '@ionic/react';
import React from 'react';
import './FosLabelInputValue.css';

export interface Props {
  label?: string;
  value?: any;
  type?: any;
  disabled?: boolean;
  maxlength?: number;
  error?: string;
  onIonChange?: (event: any) => any;
  onChange?: (event: any) => any;
}

const FosLabelInputValue: React.FC<Props> = React.forwardRef<HTMLIonInputElement, Props>(({
  label,
  value,
  type,
  disabled = false,
  maxlength,
  error,
  onIonChange,
  onChange,
}, ref) => (
  <div className='ion-margin-bottom'>
    <IonList className='fos-label-input-value-container' lines='none'>
      <IonLabel
        class="ion-text-wrap fos-label-input-value-column">
        {label}
      </IonLabel>
      <IonInput
        disabled={disabled}
        className="fos-border ion-padding-start fos-label-input-value-column"
        value={value}
        onChange={onChange}
        onIonChange={onIonChange}
        type={type}
        maxlength={maxlength}
        ref={ref} />
    </IonList>
    {error && <IonNote color='danger' className='ion-invalid' slot="error">{error}</IonNote>}
  </div>

));

export default FosLabelInputValue;
