import React, { FC, useEffect, useState } from 'react';
import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
} from '@ionic/react';
import { t } from 'i18next';
import { Sketch, SystemOfMeasurement } from '../sketch-tool';

interface SketchToolSettingsProps {
  sketch: Sketch | null;
  isOpen: boolean;
  onClose: (event: any) => any;
}

const SketchToolSettings: FC<SketchToolSettingsProps> = ({ sketch, isOpen, onClose }) => {
  const [sketchName, setSketchName] = useState('');
  const [systemOfMeasurement, setSystemOfMeasurement] = useState(sketch?.systemOfMeasurement || SystemOfMeasurement.Imperial);

  useEffect(() => {
    if (!isOpen || !sketch) return;
    setSketchName(sketch.name);
    setSystemOfMeasurement(sketch.systemOfMeasurement);
  }, [isOpen, sketch]);

  useEffect(() => {
    if (!sketch || !isOpen) return;
    sketch.setName(sketchName);
  }, [sketchName, sketch, isOpen]);

  useEffect(() => {
    if (!sketch || !isOpen) return;
    sketch.setSystemOfMeasurement(systemOfMeasurement);
  }, [systemOfMeasurement, sketch, isOpen]);

  const handleSketchNameChange = (event: any) => {
    setSketchName(event.detail.value);
  };

  const handleSystemOfMeasurementChange = (event: any) => {
    setSystemOfMeasurement(event.detail.value);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} initialBreakpoint={0.75} breakpoints={[0, 0.25, 0.5, 0.75, 1]}>
      <IonContent className="ion-padding">
        <IonList>
          <IonListHeader>
            <IonLabel>{t('general').toString()}</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel position="stacked">{t('sketchName').toString()}</IonLabel>
            <IonInput value={sketchName} onIonChange={handleSketchNameChange} type="text" />
          </IonItem>
          <IonListHeader>
            <IonLabel>{t('systemOfMeasurement').toString()}</IonLabel>
          </IonListHeader>
          <IonRadioGroup value={systemOfMeasurement} onIonChange={handleSystemOfMeasurementChange}>
            <IonItem>
              <IonLabel>{t('metric').toString()}</IonLabel>
              <IonRadio slot="end" value={SystemOfMeasurement.Metric} />
            </IonItem>
            <IonItem>
              <IonLabel>{t('imperial').toString()}</IonLabel>
              <IonRadio slot="end" value={SystemOfMeasurement.Imperial} />
            </IonItem>
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default SketchToolSettings;
