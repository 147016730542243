/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosInstance } from 'axios';
import { useState, useEffect } from 'react';
import { Network } from '@capacitor/network';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import { useAuth } from './authContext';

const GOOGLE_SUGGESTIONS_URL = '/google-proxy/google-places/auto-complete';

function getGoogleSuggestions(restClient: AxiosInstance, input: string, setSuggestions: any) {

  if (input.length === 0) {
    setSuggestions([]);
    return;
  }

  if (!input) return;

  const response = restClient.post(GOOGLE_SUGGESTIONS_URL, {
    input,
    components: [{
      key: 'Country',
      value: 'CA',
    }],
  });

  response.then((data) => {
    const addressSuggestions = data.data.predictions?.map((location: any) => ({ id: location.place_id, placeId: location.place_id, description: location.description }));
    setSuggestions(addressSuggestions);
  }).catch((e:any) => {
    let msg: string;
    if (e?.response?.data?.ErrorCode === 400) {
      msg = t('errorXSS');
    } else {
      msg = e?.response?.data?.Description;
    }
    const message = msg || t('');
    toast.error(message, { duration: 5000 });
  });
}

function useGooglePlaces(): Array<any> {
  const [suggestions, setSuggestions] = useState([]);
  const { userState } = useAuth()!;
  const restClient = getRestClient(userState, ClientType.PLAIN);
  const [placeName, setPlaceName] = useState('');

  useEffect(() => {
    const getSuggestions = async () => {
      const status = await Network.getStatus();
      if (status.connected) {
        getGoogleSuggestions(restClient, placeName, setSuggestions);
      }
    };
    getSuggestions();
  }, [placeName]);

  return [setPlaceName, suggestions];
}

export default useGooglePlaces;