import { gql } from '@apollo/client';

export const ASSIGN_EQUIPMENT_TO_CLAIM = gql`
mutation assignEquipment($trans: TransactionDtoInput!) {
  assignEquipment(trans: $trans)
  {
    itemId
    status
    message
  }
}`;

export const ASSIGN_EQUIPMENTS_LIST_TO_CLAIM = gql`
mutation assignEquipmentList($trans: TransactionListDtoInput!) {
  assignEquipmentList(trans: $trans)
  {
    itemId
    status
    message
  }
}`;

