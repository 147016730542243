import { gql } from '@apollo/client';

export type EquipmentModelType = {
  model: string;
  modelIndx: string;
  equipmentClassId?: number;
};

const GET_EQUIPMENT_MODELS_LIST = gql`
query equipmentModels($regionId: Int!) {
  equipmentModels(regionId: $regionId)
  { 
    model
    modelIndx
    equipmentClassId
  } 
}`;

export default GET_EQUIPMENT_MODELS_LIST;