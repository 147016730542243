import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

class CameraManager {
  private static instance: CameraManager | null = null;
  
  private isCameraActive: boolean = false;

  private constructor() {
    // Private constructor ensures the class cannot be instantiated from outside
  }

  public static getInstance(): CameraManager {
    if (!this.instance) {
      this.instance = new CameraManager();
    }
    return this.instance;
  }

  public async startCamera(): Promise<any> {
    if (!this.isCameraActive) {
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        // Prepare the scanner
        await BarcodeScanner.hideBackground(); // make background of WebView transparent
        
        const result = await BarcodeScanner.startScan(); // start scanning
        this.isCameraActive = true;
        return result;
      }
    } else {
      console.warn('Camera is already active.');
    }
  }

  public stopCamera(): void {
    if (this.isCameraActive) {
      BarcodeScanner.stopScan();
      BarcodeScanner.showBackground();
      this.isCameraActive = false;
    }
  }
}

export default CameraManager;