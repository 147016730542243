import { gql } from '@apollo/client';

const SAVE_PHASE = gql`
mutation savePhaseAssignment($regionId: Int!, $phase: SavePhaseRequestInput, $xactTransactionId: String) {
  savePhaseAssignment(regionId: $regionId, phase: $phase, xactTransactionId: $xactTransactionId) {
    itemId
    status
    message
    errors {
      id
      code
      value
      parentId
    }
  } 
}`;

export default SAVE_PHASE;