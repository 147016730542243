import { gql } from '@apollo/client';
import { EquipmentGenericItemType } from '../../../../graphql/EquipmentGenericItemType';


export type EquipmentInfoType = {
  id: string;
  hasTranscationHistory: boolean;
  active: boolean;
  status: EquipmentGenericItemType;
  itemClass: EquipmentGenericItemType;
  itemModel: EquipmentGenericItemType; 
  assetTag: string;
  itemNumber: string;
  purchaseDate: string;
  disposalDate: string;
  saleDate: string;
  vendor: string;
  serialNumber: string;
  purchasePrice: string;
  lifeCycle: string;
  jobCostCat: EquipmentGenericItemType;
  branch: EquipmentGenericItemType;
  region: EquipmentGenericItemType;
  transitBranch: EquipmentGenericItemType;
  saleAmount: string;
  condition: EquipmentGenericItemType;
};

const GET_EQUIPMENT_INFO = gql`
query equipment($regionId: Int!, $itemId: Int!) {
  equipment(regionId: $regionId, itemId: $itemId)
  {
    id
    hasTranscationHistory
    active
    status {
      id
      value
    }
    itemClass {
      id
      value
    } 
    itemModel {
      id
      value
    } 
    assetTag
    itemNumber
    purchaseDate
    disposalDate
    saleDate
    vendor
    serialNumber
    purchasePrice
    lifeCycle
    jobCostCat {
      id
      value
    } 
    branch {
      id
      value
    }
    region {
      id
      value
    }
    transitBranch {
      id
      value
    }
    saleAmount
    condition {
      id
      value
    }
  }
}`;

export default GET_EQUIPMENT_INFO;