/* eslint-disable react/require-default-props */
import React, { CSSProperties, useState, useEffect } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonLoading,
  IonModal,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { close, closeOutline } from 'ionicons/icons';
import { Phase } from '../types/Phases';
import './PhaseSelector.css';
import AddPhaseForm from '../organism/AddPhaseForm';
import { PhaseListType } from './helper/PhaseList';
import { XactModel } from '../graphql/GetJobsList';
import ImportAssignmentModal from '../organism/ImportAssignmentModal';
import { DefaultPhaseIds } from './helper/AddPhaseActionSheetConst';
import FosCachedImage from '../components/FosCachedImage';
import { PermissionsList, usePermissions } from '../hooks/permissions';

type StyleType = {
  headerText: CSSProperties;
  container: CSSProperties;
  iconClose: CSSProperties;
  row: CSSProperties;
  column: CSSProperties;
  button: CSSProperties;
  icon: CSSProperties;
  list: CSSProperties;
  footer: CSSProperties;
};

const styles: StyleType = {
  headerText: {
    color: '#898989',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    marginInlineStart: 50,
  },
  container: {
    color: 'blue',
    marginTop: '25px',
    textAlign: 'center',
  },
  list: {
    background: '#FFFFFF',
  },
  iconClose: {
    width: 34,
    height: 34,
  },
  row: {
    marginInlineStart: 8,
  },
  column: {
    float: 'right',
    marginInlineEnd: 20,
  },
  button: {
    width: '100%',
    padding: '0 20px',
    fontWeight: 600,
    textTransform: 'none',
    color: '#F7921E',
  },
  icon: {
    marginInlineEnd: 8,
  },
  footer: {
    paddingTop: 10,
    paddingBottom: 10,
  },
};

const xactimateIcon = 'assets/images/icons/ic_xactimate.png';
const encircleIcon = 'assets/images/icons/ic_encircle.png';

const checkedIcon = 'assets/images/icons/ic_checked.png';
const dropdownIcon = 'assets/images/icons/ic_dropdown.png';

function mapIcons(phase: Phase): string[] {
  const icons = [];
  if (phase.linkedToEncircle) {
    icons.push(encircleIcon);
  }
  if (phase.linkedToXA) {
    icons.push(xactimateIcon);
  }
  return icons;
}

type Props = {
  phases: Array<Phase>;
  isFetching: boolean;
  border?: boolean;
  addPhaseError?: string;
  onChange: (value: number) => void;
  setAddPhaseError: (value: string) => void;
  addPhase: (value: PhaseListType) => void;
};

const PhaseSelector: React.FC<Props> = ({ phases, isFetching, border, addPhaseError, onChange, addPhase, setAddPhaseError }) => {
  const [phaseSelected, setPhaseSelected] = useState(phases[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [prePopulatedPhase, setPrePopulatedPhase] = useState<XactModel>();
  const [isPhaseModalOpen, setIsPhaseModalOpen] = useState(false);
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
  const { t } = useTranslation();

  const { permissions } = usePermissions();
  const isEditClaimsEnabled = permissions.includes(PermissionsList.EditClaims);

  useEffect(() => {
    setPhaseSelected(phases[0]);
  }, [phases]);

  const isPhaseCodeSelected = (phaseIndx: number): boolean => phaseIndx === phaseSelected.phaseIndx;
  const iconsList = mapIcons(phaseSelected);

  const onCloseImportAssignment = (item?: XactModel) => {
    setIsAssignmentModalOpen(false);
    if (!item) {
      return;
    }
    setPrePopulatedPhase(item);
    setIsPhaseModalOpen(true);
  };
  const prePopulatedPhaseString = prePopulatedPhase?.isEmergency ? DefaultPhaseIds.EMERGENCY : DefaultPhaseIds.REPAIR;
  const preSelectedPhase = prePopulatedPhase ? prePopulatedPhaseString : '';

  const onAddPhase = (value: PhaseListType) => {
    addPhase(value);
    // clear info after save
    setPrePopulatedPhase(undefined);
  };

  return (
    phases.length > 0 ?
      <div>
        <IonButton id="open-modal" expand="block" onClick={() => setIsOpen(true)} fill="clear" class={`select-button ${border ? 'control-border' : ''}`.trim()}>
          <span className="select-mask">
            {iconsList.length > 0 && iconsList.map(icon => <FosCachedImage style={styles.icon} key={icon} src={icon} />)}
            {phaseSelected.phaseCode || phaseSelected.phaseId}
          </span>
          <FosCachedImage src={dropdownIcon} slot='end' />
        </IonButton>
        <ImportAssignmentModal isOpen={isAssignmentModalOpen} onClose={onCloseImportAssignment} />
        <IonModal isOpen={isOpen} class="custom-modal">
          <IonHeader>
            <IonToolbar class="phase-selector-header">
              <IonTitle>{t('choosePhase').toString()}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>
                  <div className="close-icon-container">
                    <IonIcon className="tools-close-button" icon={close} />
                  </div>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding item-background-color">
            <IonList lines="inset" style={styles.list}>
              {phases.map((phase) =>
                <IonItem
                  key={phase.phaseIndx}
                  onClick={() => {
                    setPhaseSelected(phase);
                    onChange(phase.phaseIndx);
                    setIsOpen(false);
                  }}
                  class="custom-ion-item"
                >
                  {mapIcons(phase).length > 0 && mapIcons(phase).map(icon => <FosCachedImage style={styles.icon} key={icon} src={icon} />)}
                  <IonText>{phase.phaseCode || phase.phaseId}</IonText>
                  {isPhaseCodeSelected(phase.phaseIndx) && <FosCachedImage src={checkedIcon} slot='end' />}
                </IonItem>,
              )}
            </IonList>
          </IonContent>
          {isEditClaimsEnabled &&
            <IonFooter style={styles.footer} className="phase-selector-footer">
              <IonButton style={styles.button} class="ion-button" onClick={() => {
                setIsAssignmentModalOpen(true);
              }}>
                {t('importAssignment').toString()}
              </IonButton>
              <IonButton style={styles.button} onClick={() => setIsPhaseModalOpen(true)} class="ion-button">
                {t('addPhase').toString()}
              </IonButton>
            </IonFooter>
          }
          <IonToast
            isOpen={!!addPhaseError}
            message={addPhaseError}
            buttons={[{ role: 'cancel', icon: closeOutline }]}
            onDidDismiss={() => { setAddPhaseError(''); }}
          />
        </IonModal>
        <IonModal isOpen={isPhaseModalOpen} class="custom-modal">
          <IonHeader class="ion-no-border">
            <IonToolbar class="header phase-form-header">
              <IonTitle>{t('addPhase').toString()}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsPhaseModalOpen(false)}>
                  <div className="close-icon-container">
                    <IonIcon className="tools-close-button" icon={close} />
                  </div>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <AddPhaseForm
              preSelectedPhase={preSelectedPhase}
              onChange={onAddPhase}
              phaseModalOpen={setIsPhaseModalOpen}
              addPhaseLabel={t('andAndSavePhase').toString()}
              prePopulatedPhase={prePopulatedPhase}
            />
          </IonContent>
        </IonModal>
        <IonLoading isOpen={isFetching} duration={2000} message={t('loading')}/>
      </div>
      : null
  );
};

export default PhaseSelector;