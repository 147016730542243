import { Storage } from '@ionic/storage';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseCacheAndList from './baseCacheAndList';
import GET_BRANCHES_LIST from '../graphql/GetBranchesList';
import { BranchesType } from '../pages/helper/BranchesType';

const useBranches = (region: number, userState: UserState, storage: Storage) => {
  const branchesList:BranchesType[] = useBaseCacheAndList(region, userState, storage, GET_BRANCHES_LIST, claimsKeys.branchesList(), 'branches');
  return branchesList;
};

export default useBranches;