import { gql } from '@apollo/client';
import { ClaimOnlineStatus } from '../pages/helper/Const';

export type ClaimPhotosType = {
  filePath : string,
  thumbnailFileName : string,
  areaOfLoss : string,
  uploadedDate: string,
  phase: string,
  fileMetaData: string,
  sentToXactEm: string,
  sentToXactRe: string,
  isCustomerVisible: boolean,
  fileName: string,
  fileSize: number,
  fileTypeCd: string,
  fileExtension: string,
  description: string,
  selected: boolean,
  ctfileId: number,
  roomId: number,
  includedInIsi: boolean,
  updatedBy?: string,
  status?: ClaimOnlineStatus,
  tempId?: number;
  phaseIndx?: number;
  files?: File[];
  xactimate?: boolean,
  isSymbilityDoc?: boolean,
};

const GET_CLAIM_PHOTOS_LIST = gql`
query claimPhotos ($regionId: Int!, $claimIndx: Int!) {
  claimPhotos(regionId: $regionId, claimIndx: $claimIndx)
  {
    filePath
    thumbnailFileName
    areaOfLoss
    phase
    description
    fileExtension
    uploadedDate
    fileMetaData
    fileSize
    fileName
    sentToXactEm
    sentToXactRe
    isCustomerVisible
    fileTypeCd
    ctfileId
    roomId
    includedInIsi
    xactimate
    isSymbilityDoc
  }
}`;

export default GET_CLAIM_PHOTOS_LIST;