
import { useIonActionSheet } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { JobContacts } from '../pages/helper/JobContactsInterface';
import { usePermissions, PermissionsList } from './permissions';

enum JobContactEnum {
  SendNotification = 'SendNotification',
}

const useHandleJobContactsCall = () => {
  const [present] = useIonActionSheet();
  const { t } = useTranslation();
  const { permissions } = usePermissions();
  const isNotificationsEnabled = permissions.includes(PermissionsList.Notifications);
  const handleJobContactsCall = ({
    projectPhone1,
    projectPhone2,
    projectPhone3,
    businessContactPhone,
    projectName,
    businessContact,
    claimIndx,
    setOpenModal,
  }: JobContacts) => {
    present({
      header: t('contact'),
      cssClass: 'custom-action-sheet',
      buttons: [
        {
          text: t('sendNotification'),
          data: {
            action: JobContactEnum.SendNotification,
            claimIndx,
          },
          cssClass: 'my-custom-button',
        },
        {
          text: `${projectName} - ${projectPhone1}`,
          data: {
            action: 'callProjectName1',
            contact: projectPhone1,
            contactName: projectName,
          },
        },
        {
          text: `${projectName} - ${projectPhone2}`,
          data: {
            action: 'callProjectName2',
            contact: projectPhone2,
            contactName: projectName,
          },
        },
        {
          text: `${projectName} - ${projectPhone3}`,
          data: {
            action: 'callProjectName3',
            contact: projectPhone3,
            contactName: projectName,
          },
        },
        {
          text: `${businessContact} - ${businessContactPhone}`,
          data: {
            action: 'callBusinessContact',
            contact: businessContactPhone,
            contactName: businessContact,
          },
        },
        {
          text: t('cancel'),
          role: 'destructive',
          data: {
            action: 'cancel',
          },
        },
      ].filter(btn => btn.data.action === 'cancel' || (isNotificationsEnabled && btn.data.action === JobContactEnum.SendNotification)
       || (btn.data.contact && btn.data.contactName)),
      onDidDismiss: ({ detail }) => {
        if (!detail.data || detail.data?.action === 'cancel') return;
        if (detail.data.action === JobContactEnum.SendNotification) {
          if (setOpenModal) setOpenModal(true);
          return;
        }
        const aDocumentElement = document.createElement('a');
        aDocumentElement.href = `tel:${detail.data.contact}`;
        aDocumentElement.click();
      },
    });
  };
  return handleJobContactsCall;
};

export default useHandleJobContactsCall;