import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { UserState } from '../../../../hooks/authUtils';
import getGraphQLClient, { DomainParams } from '../../../../hooks/graphQLClientUtil';
import GET_EQUIPMENT_INFO from '../graphql/GetEquipmentInfo';


const useEquipment = (userState: UserState, variables: any) => useQuery({
  queryKey: ['equipment', variables],
  queryFn: (context: QueryFunctionContext) => getGraphQLClient(userState, DomainParams.Equipments).request(GET_EQUIPMENT_INFO, context.queryKey[1]),
  refetchOnWindowFocus: false,
});

export default useEquipment;
