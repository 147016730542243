/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */

import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as msal from '@azure/msal-browser';
import { User } from '../graphql/GetUser';
import { AUTH_CONF, BASE_API_URL } from '../utils/Env';

export interface UserAuthPayload {
  preferredUsername: string;
  accessToken: string;
  idToken: string;
  regionId: number;
  expiration: number;
}

export type MSSOAuthResponse = {
  accessToken: string;
  idToken: string;
  scopes: string[];
};

export type ResponseJWT = {
  name: string;
  oid: string;
  preferredUsername: string;
  roles: string[];
  exp: number,
  /* 
  "aud": "84548be3-62bb-4....8",
  "iss": "https://login.microsoft.....",
  "iat": 166819....20,
  "nbf": 1668....2,
  "exp": 1668194196,
  "aio": "AWQAm/....",
  "azp": "82218b1d-...",
  "azpacr": "0",
  "rh": "0.AQ4AxV4Xn1qH.......",
  "roles": [
    "CommonApi",
    "PayrollManagement",
    "EmployeeManagement",
    "FileManager",
    "SalesAccountManagement",
    "CompanyManagement",
    "EquipmentManagement"
  ],
  "scp": "EmployeeManagement_",
  "sub": "gb1iC2kLPqh....Y",
  "tid": "9f175ec5-....",
  "uti": "5vfE5eo.....",
  "ver 
  ": "2.0"
  */
};

export type UserState = {
  initialized: boolean;
  loggedIn: boolean;
  loading: boolean;
  userAuthPayload?: UserAuthPayload;
  error?: Error;
};

export type AuthContext = {
  user?: User;
  userState: UserState;
  logOut: () => Promise<void>;
  logIn: () => Promise<void>;
  refreshToken: () => Promise<void>;
  checkTokenExpiration: () => Promise<void>;
};

export const loginRequest = {
  scopes: AUTH_CONF.scopes,
};

export const msalConfig = {
  auth: {
    clientId: AUTH_CONF.clientId,
    authority: 'https://login.microsoftonline.com/9f175ec5-875a-4cc2-b995-e65d79bb421e',
    redirectUri: `${window.location.protocol}//${window.location.host}`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
        }
      },
    },
  },
};

export { AUTH_CONF } from '../utils/Env';

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`) // '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      // eslint-disable-next-line @typescript-eslint/comma-dangle
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const httpLink = createHttpLink({
  uri: `${BASE_API_URL}/common/graphql`,
});

export const AUTH_LOCATION_KEY = 'AUTH_MSSO';

export const authLink = setContext((_, { headers }) => {
  let auth: string | UserAuthPayload | null = window.localStorage.getItem(AUTH_LOCATION_KEY) || null;
  if (auth !== null) {
    auth = JSON.parse(auth as string) as UserAuthPayload;
  }
  const authorization = auth ? `Bearer ${auth.accessToken}` : '';

  return {
    headers: {
      ...headers,
      authorization,
    },
  };
});
