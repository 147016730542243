import { UserState } from '../../../../hooks/authUtils';
import getGraphQLClient, { DomainParams } from '../../../../hooks/graphQLClientUtil';
import GET_EQUIPMENT_BY_ASSET_TAG from '../graphql/GetEquipmentByAssetTag';

const getEquipmentByAssetTagHelper = async (userState: UserState, assetTag: string) => {
  try {
    const data = await getGraphQLClient(userState, DomainParams.Equipments).request(GET_EQUIPMENT_BY_ASSET_TAG, { assetTag });
    return data.equipmentByAssetTag;
  } catch (error) {
    return null;
  }
};

export default getEquipmentByAssetTagHelper;
