/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useIonRouter } from '@ionic/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from '../hooks/authContext';
import useBranches from '../hooks/branches';
import { ExpenseDtoInput } from '../hooks/expSaveExpense';
import useStorage from '../hooks/storage';
import useExpenseDepartments from '../hooks/expDepartments';
import { GUID } from '../graphql/GetUserProfileInfo';
import useExpenseMileageTypes from '../hooks/expMileageTypes';
import useUserYtdMileage from '../hooks/useUserYtdMileage';
import useCalculateExpenseMileageTotal from '../hooks/useCalculateExpenseMileageTotal';
import useSaveMileageExpense, { MileageExpenseDtoInput } from '../hooks/expSaveMileageExpense';
import useGetApplicationProperty from '../hooks/useGetApplicationProperty';
import { ApiStatus } from './helper/Const';
import useExpenseDetails from '../hooks/expDetails';
import useGetPayrollDetails from '../hooks/useGetPayrollDetails';
import { getErrorMsg } from './helper/AppHelper';
import { ExpenseDepartmentData } from './helper/ExpenseDepartmentType';

const AddMileageExpenseViewModel = (expenseId: string) => {
  const { userState } = useAuth()!;
  const navigation = useIonRouter();
  const regionId = userState.userAuthPayload?.regionId!;
  const storage = useStorage();
  const [date, setDate] = useState<Date>(new Date());
  const [branch, setBranch] = useState<number>(0);
  const [department, setDepartment] = useState<number>(0);
  const currency = 1;
  const [expense, setExpense] = useState<any>(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [distance, setDistance] = useState<number>();
  const [mileageTypeId, setMileageTypeId] = useState(1);
  const [saveError, setSaveError] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const userGuid = window.localStorage.getItem(GUID) as string;
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [formData, setFormData] = useState<any>();
  const branches = useBranches(regionId, userState, storage);
  const { userYtdMileage, isLoading: isLoadingYtd } = useUserYtdMileage(userState, regionId);
  const milesToKmsRate = 1.60934;
  const userYtdMileageMiles = ((userYtdMileage ?? 0) / milesToKmsRate);

  const { data: payrollDetails } = useGetPayrollDetails(userState);

  // TODO: replace hardcoded 15 to the mileage 
  const { data: expenseDepartments } = useExpenseDepartments(branch || expense?.branchId, 15);

  const { mileageTypes } = useExpenseMileageTypes();

  const mileageTypesOptions = mileageTypes.map(mileageType => ({ label: mileageType.text, value: Number(mileageType.value) }));
  const branchOptions = branches.sort((a, b) => a.branchName.localeCompare(b.branchName)).map(branch => ({ label: branch.branchName, value: branch.branchId }));
  const expenseDepartmentOptions = ((expenseDepartments?.expenseDepartments as ExpenseDepartmentData)?.items || []).sort((a, b) => a.department.localeCompare(b.department)).map(expDep => ({ label: expDep.department, value: expDep.departmentId }));

  const { mutateAsync: saveMileageExpenseMutation, isLoading: isSavingExpense } = useSaveMileageExpense();

  const { data: expenseMileageTotal } = useCalculateExpenseMileageTotal(userState, distance || 0, Number(mileageTypeId), userYtdMileage || 0, new Date(date));
  const { propertyValue: minExpenseDate } = useGetApplicationProperty('f80465b9-5b1f-4bd7-8150-9ff10f585480', 'expense-min-date');

  type FormData = {
    date: string;
    branch: number;
    department: number;
    mileageType: string;
    details: string;
    distance: string;
    destinations: string
  };

  const schema = yup.object({
    date: yup.string().required(t('dateIsRequired')),
    branch: yup.string().required(t('branchIdIsRequired')),
    department: yup.number().min(1, t('departmentIsRequired')).required(t('departmentIsRequired')),
    mileageType: yup.number().required(t('mileageTypeIsRequired')),
    details: yup.string().required(t('detailIsRequired')),
    distance: yup.string().test({
      name: 'distance',
      message: () => t('distanceIsRequired'),
      test: (value) => Number(value) > 0,
    }),
    destinations: yup.string().required(t('destinationIsRequired')),
  }).required();

  const { handleSubmit, register, control, setValue } = useForm<FormData>(
    {
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (userState.loggedIn) {
      const homeBranch = payrollDetails?.payrollDetails?.user?.branchId || 0;
      if (homeBranch > 0 && branch === 0) {
        setBranch(homeBranch);
        setValue('branch', homeBranch);
      }
    }    
  }, [setValue, userState, payrollDetails, branchOptions, branch]);

  useEffect(() => {
    setValue('department', department || expense?.gpdepartmentId);
  }, [setValue, expense, department]);

  const onSubmit = handleSubmit((data: FormData) => {
    setFormData(data);
    setShowActionSheet(true);
  });

  const onSuccessDetail = (data: any) => {
    const expense = data?.expense?.expense;
    if (expense) {
      setValue('date', expense.dateExpense);
      setDate(expense.date);
      setValue('branch', expense.branchId);
      setBranch(expense.branch);
      setValue('department', expense.gpdepartmentId);
      setTotalAmount(expense.totalAmount);
      setValue('details', expense.comments);      
      setValue('destinations', expense.destinations);      
      setValue('distance', expense.mileage);
      setDistance(expense.mileage);
      setValue('mileageType', expense.mileageTypeId);
      setMileageTypeId(expense.mileageTypeId);
      const pendingState = 1;
      let editAllowed = data?.expense?.expense.statusId === pendingState;

      const userGuid = window.localStorage.getItem(GUID)!;
      if (expense.employeeGuid !== userGuid) {
        editAllowed = false;
      }

      setEditMode(editAllowed);
      setExpense(data?.expense?.expense);
    } else {
      setEditMode(true);
    }
  };

  const { isLoading: isLoadingDetails } = useExpenseDetails(expenseId ? Number(expenseId) : 0, onSuccessDetail);


  useEffect(() => {
    const mileateTotal = expenseMileageTotal?.calculateExpenseMileageTotal || 0;
    setTotalAmount(mileateTotal);
  }, [expenseMileageTotal, expenseId]);

  const saveExpense = async (releaseAfterSave: boolean) => {
    try {
      const totalAmountVal = Number.isNaN(+totalAmount) ? 0 : +totalAmount;
      const expense: ExpenseDtoInput = {
        id: expenseId ? Number(expenseId) : 0,
        statusId: 0,
        parentId: 0,
        expenseMethodId: 3, // mileage expense
        regionId,
        createdByGuid: userGuid,
        employeeGuid: userGuid,
        isParent: false,
        dateCreated: new Date().toISOString(),
        dateExpense: formData.date,
        branchId: Number(formData.branch),
        gpdepartmentId: Number(formData.department),
        totalAmount: totalAmountVal,
        netAmount: totalAmountVal,
        comments: formData.details,
        destinations: formData.destinations,
        tip: 0,
        taxAmount: 0,
        merchant: '',
        mileage: Number(formData.distance),
        currencyId: currency,
        mileageTypeId: Number(formData.mileageType),
      };
      const mileageExpense: MileageExpenseDtoInput = {
        expenseObject: {
          expense,
          expenseTaxes: [],
          expenseReceipts: [],
        },
        branch: { branchId: Number(formData.branch) },
        notPersisted: false,
        ytdMileageKms: userYtdMileage || 0,
      };
      const resp = await saveMileageExpenseMutation({ expenses: [mileageExpense], releaseAfterSave });
      if (resp?.saveMileageExpense?.status === ApiStatus.SUCCESS) {
        setShowSuccessToast(true);
        setTimeout(() => { navigation.goBack(); }, 1000);
        return;
      }
      throw new Error(resp.saveMileageExpense?.message || t('saveExpenseError'));
    } catch (e: any) {
      const msg = getErrorMsg(e);
      const message = msg || t('saveExpenseError');
      setSaveError(message);
    }
  };

  const onChangeDistance = (localDistance: number) => {
    if (localDistance) {
      setDistance(localDistance);
    } else {
      setDistance(0);
      setTotalAmount(0);
    }
  };
  const maxExpenseDate = new Date().toISOString();

  return {
    branchesOptions: branchOptions,
    userYtdMileage,
    userYtdMileageMiles,
    setDate,
    setBranch,
    totalAmount,
    expenseDepartmentOptions,
    onChangeDistance,
    saveExpense,
    isSavingExpense,
    isLoadingDetails: expenseId ? isLoadingDetails : false,
    mileageTypesOptions,
    setMileageTypeId,
    maxExpenseDate,
    minExpenseDate: minExpenseDate ? new Date(minExpenseDate).toISOString() : '',
    saveError,
    setSaveError,
    showSuccessToast,
    setShowSuccessToast,
    showActionSheet,
    setShowActionSheet,
    onSubmit,
    control,
    register,
    isLoadingYtd: userYtdMileage ? isLoadingYtd : true,
    editMode,
    setDepartment,
  };
};

export default AddMileageExpenseViewModel;
