import React, { useEffect } from 'react';
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonPage,
  IonLoading,
  useIonRouter,
  IonInput,
  IonToast,
  IonActionSheet,
  IonNote,
} from '@ionic/react';
import { t } from 'i18next';
import './AddMileageExpense.css';
import { closeOutline } from 'ionicons/icons';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router';
import { Controller } from 'react-hook-form';
import FosCurrencyInput from '../components/FosCurrencyInput';
import FosTextArea from '../atom/FosTextArea';
import useAddMileageExpenseViewModel from './AddMileageExpenseModel';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
import FosDateTime from '../atom/FosDateTime';

interface RouteParams {
  expenseId: string;
}
const AddMileageExpense: React.FC = () => {
  const { expenseId } = useParams<RouteParams>();
  const {
    branchesOptions,
    userYtdMileage,
    userYtdMileageMiles,
    setDate,
    setBranch,
    totalAmount,
    expenseDepartmentOptions,
    onChangeDistance,
    saveExpense,
    isSavingExpense,
    isLoadingDetails,
    isLoadingYtd,
    mileageTypesOptions,
    setMileageTypeId,
    maxExpenseDate,
    minExpenseDate,
    saveError,
    setSaveError,
    showSuccessToast,
    setShowSuccessToast,
    showActionSheet,
    setShowActionSheet,
    onSubmit,
    control,
    register,
    editMode,
    setDepartment,
  } = useAddMileageExpenseViewModel(expenseId);
  const navigation = useIonRouter();

  useEffect(() => {
    if (showSuccessToast) {
      toast.success(t('expenseSaved'), { duration: 4000 });
      setShowSuccessToast(false);
    }
  }, [setShowSuccessToast, showSuccessToast]);

  const handleSaveExpense = async () => {
    await saveExpense(false);
  };
  const handleSaveAndReleaseExpense = async () => {
    await saveExpense(true);
  };

  const handleCancel = () => {
    setShowActionSheet(false);
  };

  return (
    <IonPage className="add-job">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="danger" onClick={() => navigation.goBack()}>{t('cancel').toString()}</IonButton>
          </IonButtons>
          <IonTitle>{t('addMileageExpense').toString()}</IonTitle>
          {editMode && <IonButtons slot="end">
            <IonButton type="button" strong onClick={() => {
              document.getElementById('addExpenseForm')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }}>
              {t('save').toString()}
            </IonButton>
          </IonButtons>}
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form id="addExpenseForm" className="fos-form ion-margin-top" onSubmit={onSubmit}>
          <IonGrid className="ion-no-padding ion-no-margin">
            <IonRow className="ion-no-padding ion-margin-bottom">
              <IonCol>
                <Controller
                  control={control}
                  name="date"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FosDateTime
                      {...register('date')}
                      id="date"
                      disabled={!editMode}
                      label={`${t('date')} *`}
                      presentation='date'
                      value={value}
                      showDefaultButtons
                      onIonChange={(e) => {
                        if (e.detail?.value) {
                          onChange(e);
                          setDate(new Date(e.detail.value));
                        }
                      }}
                      error={error?.message}
                      min={minExpenseDate}
                      max={maxExpenseDate}
                    />
                  )}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Controller
                  control={control}
                  name='branch'
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <FosSelectBigListItem
                      required
                      id="addExpenseBranch"
                      onChange={(e) => {
                        onChange(e);
                        setBranch(e);
                      }}
                      value={value}
                      disabled={!editMode}
                      name={name}
                      label={t('branch')}
                      options={branchesOptions}
                      error={error?.message}
                    />
                  )} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Controller
                  control={control}
                  name="department"
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <FosSelectBigListItem
                      required
                      id="addExpenseDepartment"
                      {...register('department')}
                      name={name}
                      onChange={(e) => {
                        onChange(e);
                        setDepartment(e);
                      }}
                      error={error?.message}
                      value={value}
                      label={t('department')}
                      disabled={expenseDepartmentOptions?.length === 0 || !editMode}
                      options={expenseDepartmentOptions} />
                  )} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Controller
                  control={control}
                  name='mileageType'
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FosSelectBigListItem
                      required
                      id="addExpenseMileageType"
                      {...register('mileageType')}
                      onChange={(e) => {
                        onChange(e);
                        setMileageTypeId(e);
                      }}
                      error={error?.message}
                      value={value}
                      name='mileageType'
                      disabled={!editMode}
                      label={t('mileageType')}
                      options={mileageTypesOptions} />
                  )} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-margin-end">
                <IonLabel>{`${t('distance').toString()} *`}</IonLabel>
                <Controller
                  control={control}
                  name="distance"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <IonItem lines="none" className={`fos-stacked-input-item no-border ${error ? 'ion-invalid' : ''}`.trim()}>
                      <IonInput
                        {...register('distance')}
                        className='distance-input'
                        type='number'
                        name='distance'
                        value={value}
                        disabled={!editMode}
                        onChange={onChange}
                        onIonChange={(e) => {
                          onChange(e);
                          onChangeDistance(Number(e.detail.value) || 0);
                        }}
                        placeholder='0'
                      />
                      {error?.message && <IonNote slot="error">{error.message}</IonNote>}
                    </IonItem>
                  )} />
              </IonCol>
              <IonCol>
                <IonLabel>{t('amount').toString()}</IonLabel>
                <FosCurrencyInput disabled value={totalAmount?.toString()} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>{`YTD Mileage : ${userYtdMileage?.toFixed(2)} km(s) / ${userYtdMileageMiles.toFixed(2)} Mile(s)`}</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Controller
                  control={control}
                  name="details"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FosTextArea
                      {...register('details')}
                      required
                      disabled={!editMode}
                      error={error?.message}
                      value={value}
                      onIonChange={onChange}
                      placeholder={t('details')} />
                  )} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Controller
                  control={control}
                  name="destinations"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FosTextArea
                      {...register('destinations')}
                      required
                      disabled={!editMode}
                      error={error?.message}
                      value={value}
                      onIonChange={onChange} placeholder={t('destinations')} />
                  )} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <IonToast
          isOpen={!!saveError}
          message={saveError}
          buttons={[{ role: 'cancel', icon: closeOutline }]}
          onDidDismiss={() => { setSaveError(''); }}
        />
        <Toaster
          containerStyle={{
            top: 50,
            left: 20,
            bottom: 20,
            right: 20,
          }}
        />
      </IonContent>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={handleCancel}
        buttons={[
          {
            text: t('save'),
            handler: handleSaveExpense,
          },
          {
            text: t('saveAndRelease'),
            handler: handleSaveAndReleaseExpense,
          },
          {
            text: t('Cancel'),
            role: 'cancel',
            handler: handleCancel,
          },
        ]}
      />
      <IonLoading isOpen={isLoadingDetails || isLoadingYtd} message={t('loading')} duration={5000} />
      <IonLoading isOpen={isSavingExpense} message={t('saving')} duration={5000} />

    </IonPage>

  );
};

export default AddMileageExpense;