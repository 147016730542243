import { gql } from '@apollo/client';

const GET_VERTICALS_LIST = gql`
query claimVerticals{
  claimVerticals{
    id
    name
    description
    order
    enabled
  }
}`;

export default GET_VERTICALS_LIST;