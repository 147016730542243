import SaveStorage, { SavedRecords } from '../../../utils/SaveStorage';
import { UserProfileInfoType } from '../UserProfileInfoType';

export const storeProfileInfo = async (info: UserProfileInfoType | null) => {
  const saveStorage = SaveStorage();
  await saveStorage.setItem(SavedRecords.ProfileInfo, info, 1);
};

export const getProfileInfo = async () => {
  const saveStorage = SaveStorage();
  const list = await saveStorage.getItems<UserProfileInfoType>(SavedRecords.ProfileInfo);
  return list[0] || null;
};

