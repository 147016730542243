import React, { useRef, useState } from 'react';
import { t } from 'i18next';
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import * as DOMPurify from 'dompurify';

import { useAuth } from '../hooks/authContext';
import './PreviewModal.css';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import GET_SAFETY_REPORT from '../graphql/GetSafetyReport';
import GET_SITE_INSPECTION_REPORT from '../graphql/GetSiteInspectionReport';
import { Phase } from '../types/Phases';
import { PermissionsList, usePermissions } from '../hooks/permissions';
import { GUID } from '../graphql/GetUserProfileInfo';

export enum ReportType {
  SAFETY = 'safety',
  INSPECTION = 'inspection',
}

const getGenerateQuery = (reportType: string) => {
  switch (reportType) {
    case ReportType.SAFETY:
      return GET_SAFETY_REPORT;
    case ReportType.INSPECTION:
      return GET_SITE_INSPECTION_REPORT;
    default:
      return GET_SITE_INSPECTION_REPORT;
  }
};

export interface Props {
  isModalOpen: boolean;
  html: string;
  claimIndx: number;
  reportName: string;
  language: string;
  phases: Array<Phase>;
  onCancel: () => any;
  onGenerate: () => any;
  onError: () => any;
  onSuccess: () => any;
}

const PreviewModal = React.forwardRef<HTMLIonModalElement, Props>(({
  isModalOpen,
  html,
  claimIndx,
  reportName,
  language,
  phases,
  onCancel,
  onGenerate,
  onError,
  onSuccess,
}, ref) => {
  const { userState, checkTokenExpiration } = useAuth()!;
  const [xaPhases, setXaPhases] = useState<Array<Phase>>([]);
  const [symbilityPhases, setSymbilityPhases] = useState<Array<Phase>>([]);
  const [xaSelected, setXaSelected] = useState<boolean>(true);
  const [symbilitySelected, setSymbilitySelected] = useState<boolean>(false);
  const [selectedPhaseIndx, setSelectedPhaseIndx] = useState<number | null>(null);
  const [showPhaseModal, setShowPhaseModal] = useState<boolean>(false);
  const { permissions } = usePermissions();
  const isSendToXAEnabled = permissions.includes(PermissionsList.AllowSendToXA);

  const variablesClaimDetails = {
    regionId: userState.userAuthPayload?.regionId!,
    claimIndx,
    attachToClaim: true, // send report to ClaimTrak
    frenchVersion: language === 'fr',
    phaseIndx: xaSelected || symbilitySelected ? selectedPhaseIndx : undefined,
    userGuid: window.localStorage.getItem(GUID),
    sendToSymbility: symbilitySelected ?? false,
  };
  const generateModal = useRef<HTMLIonModalElement>(null);

  const dismissGenerateModal = () => {
    generateModal.current?.dismiss();
    setShowPhaseModal(false);
  };

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: [],
    queryFn: async () => getGraphQLClient(userState).request(getGenerateQuery(reportName), variablesClaimDetails),
    enabled: false,
  });

  const localGenerate = async () => {
    onGenerate();
    await checkTokenExpiration();
    const { error } = await refetch();
    if (error) {
      onError();
    } else {
      onSuccess();
    }
    setXaSelected(true);
  };

  const onGenerateClickHandle = async () => {
    setShowPhaseModal(false);
    await localGenerate();
  };

  const clickHandler = async () => {
    const localXaPhases = phases?.filter(phase => phase.linkedToXA);
    const localSymbilityPhases = phases?.filter(phase => phase.linkedToSymbility);

    if (localXaPhases && localXaPhases.length > 0 && isSendToXAEnabled && reportName === ReportType.INSPECTION) {
      setXaPhases(localXaPhases);
      setSelectedPhaseIndx(localXaPhases[0].phaseIndx);
      setShowPhaseModal(true);
    } else if (localSymbilityPhases && localSymbilityPhases.length > 0 && reportName === ReportType.INSPECTION) {
      setSymbilityPhases(localSymbilityPhases);
      setSelectedPhaseIndx(localSymbilityPhases[0].phaseIndx);
      setShowPhaseModal(true);
    } else {
      await localGenerate();
    }
  };

  return (
    <>
    <IonModal ref={ref} isOpen={isModalOpen} onDidDismiss={onCancel}>
      <IonContent class="ion-padding" fullscreen dangerouslySetInnerHTML={
        {
          __html: DOMPurify.sanitize(html, {
            FORCE_BODY: true,
            FORBID_TAGS: ['script', 'html', 'head', 'body'],
          }),
        }
      } />
      <IonFooter className="preview-footer">
        <IonButton
          fill="solid"
          expand="block"
          className="ion-padding-start ion-padding-end ion-margin-bottom"
          onClick={clickHandler}
        >
          {t('generate').toString()}
        </IonButton>
        <IonButton
          fill="outline"
          expand="block"
          className="ion-padding-start ion-padding-end ion-margin-bottom"
          onClick={onCancel}
        >
          {t('cancel').toString()}
        </IonButton>
      </IonFooter>
      <IonLoading
        isOpen={isLoading}
        message={t('loadingReportPreview')}
        duration={3000}
      />
      <IonLoading
        isOpen={isFetching}
        message={t('generatingReport')}
      />
    </IonModal>
    <IonModal id="phase-modal" ref={generateModal} isOpen={showPhaseModal} onDidDismiss={dismissGenerateModal}>
      <IonHeader><h1 className='h1-center'>{t('generateIsr').toString()}</h1></IonHeader>
      {xaPhases?.length > 0 && <IonContent className="wrapper" id="phase-content" >
        <IonItem className='ion-item-border' lines="none">
          <IonCheckbox value={xaSelected} checked={xaSelected} onIonChange={() => setXaSelected(!xaSelected)} />
          <IonLabel>{t('submitToXact').toString()}</IonLabel>
        </IonItem>
        <IonItem className='ion-item-border' lines="none">
          <IonLabel>{t('phase').toString()}</IonLabel>
          <IonSelect disabled={!xaSelected} value={selectedPhaseIndx}  onIonChange={(event) => setSelectedPhaseIndx(event.detail.value)}>
            {xaPhases.map((phase) => <IonSelectOption key={phase.phaseIndx} value={phase.phaseIndx}>{phase.phaseCode}</IonSelectOption>)}
          </IonSelect>
        </IonItem>
      </IonContent>}
      {symbilityPhases?.length > 0 && <IonContent className="wrapper" id="phase-content" >
        <IonItem className='ion-item-border' lines="none">
          <IonCheckbox value={symbilitySelected} checked={symbilitySelected} onIonChange={() => setSymbilitySelected(!symbilitySelected)} />
          <IonLabel>{t('submitToSymbility').toString()}</IonLabel>
        </IonItem>
        <IonItem className='ion-item-border' lines="none">
          <IonLabel>{t('phase').toString()}</IonLabel>
          <IonSelect disabled={!symbilitySelected} value={selectedPhaseIndx}  onIonChange={(event) => setSelectedPhaseIndx(event.detail.value)}>
            {symbilityPhases.map((phase) => <IonSelectOption key={phase.phaseIndx} value={phase.phaseIndx}>{phase.phaseCode}</IonSelectOption>)}
          </IonSelect>
        </IonItem>
      </IonContent>}
      <IonFooter className="phase-footer"><IonButton className="generate-btn" onClick={onGenerateClickHandle}>{t('generate').toString()}</IonButton></IonFooter>
    </IonModal>
    </>
  );
});

export default PreviewModal;