import { useIonRouter } from '@ionic/react';
import { EquipmentAction } from '../../util/EquipmentUtil';

const ScanEquipmentViewModel = () => {
  
  const navigation = useIonRouter();

  const handleEquipmentAction = (action: EquipmentAction) => {
    navigation.push(`/scan-equipment/batch/${action}`);
  };

  return {
    handleEquipmentAction,
  };

};

export default ScanEquipmentViewModel;