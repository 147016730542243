import React, { useEffect, useMemo, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonModal,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { JobRelExpenseForm, JobRelExpenseFormData, JobRelExpenseFormErrors, createJobRelExp, getJobRelExpErrors } from './ExpJobRelExpenseForm';

interface JobRelExpenseFormModalProps {
  location: string
  initialExpense?: JobRelExpenseFormData | null
  isOpen: boolean
  editMode: boolean
  onClose: (expense?: JobRelExpenseFormData) => any
}

const JobRelExpenseFormModal: React.FC<JobRelExpenseFormModalProps> = ({ location, initialExpense, isOpen, editMode, onClose }) => {
  const jobRelExp: JobRelExpenseFormData = useMemo(() => createJobRelExp(), []);
  const [errors, setErrors] = useState<JobRelExpenseFormErrors>({});

  const [expense, setExpense] = useState<JobRelExpenseFormData>(jobRelExp);
  const { t } = useTranslation();

  useEffect(() => {
    setExpense(initialExpense || { ...jobRelExp });
  }, [initialExpense, jobRelExp]);

  const handleJobRelExpenseChange = (id: keyof JobRelExpenseFormData, value: any, updatedExpense: JobRelExpenseFormData) => {
    const exp = { ...updatedExpense };
    setExpense(exp);

    const errs = getJobRelExpErrors(exp, t);
    setErrors(errs);
  };

  const handleSave = () => {
    const errs = getJobRelExpErrors(expense, t);
    setErrors(errs);
    if (Object.values(errs).length) return;
    onClose(expense);
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="danger" onClick={() => onClose()}>{t('cancel').toString()}</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={handleSave}>
              {t('save').toString()}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <JobRelExpenseForm editMode={editMode} id="form-modal" errors={errors} showTotalAmount expense={expense} onChange={handleJobRelExpenseChange} location={location} />
      </IonContent>
    </IonModal>
  );
};

export default JobRelExpenseFormModal;
