import { gql } from '@apollo/client';

const GET_CONSTRUCTION_MANAGER_LIST = gql`
query projectCoordinators($regionId: Int!) {
  projectCoordinators(regionId: $regionId)
  { 
    id
    pmindx
    firstName
    lastName
  } 
}`;

export default GET_CONSTRUCTION_MANAGER_LIST;