import React, { } from 'react';
import { IonButton, IonList } from '@ionic/react';
import './FosToogleButtons.css';

export interface Props {
  selectedOption: number;
  leftButtonLabel: string;
  rightButtonLabel: string;
  onLeftClick: (event: any) => any;
  onRightClick: (event: any) => any;
}

const FosToogleButtons: React.FC<Props> = (({ selectedOption, leftButtonLabel, rightButtonLabel, onLeftClick, onRightClick }) => (
  <IonList className='fos-toogle-button-inline-container fos-toogle-button-vertical-border' slot='content'>
    <IonButton
      className={`fos-toogle-button-inline ion-text-wrap ${selectedOption !== 1 ? 'fos-toogle-button-inline-seleted' : ''}`}
      fill='clear'
      onClick={onLeftClick}
    >
      {leftButtonLabel}
    </IonButton>
    <div className="fos-toogle-button-divider" />
    <IonButton
      className={`fos-toogle-button-inline ion-text-wrap ${selectedOption !== 2 ? 'fos-toogle-button-inline-seleted' : ''}`}
      fill='clear'
      onClick={onRightClick}
    >
      {rightButtonLabel}
    </IonButton>
  </IonList>
));

export default FosToogleButtons;
