import React from 'react';
import { IonLabel, IonToolbar } from '@ionic/react';
import { t } from 'i18next';

export enum ClaimNoteMenuTabs {
  Notes = 'Notes',
  Notifications = 'Notifications',
}

export type ClaimNoteActiveOption = ClaimNoteMenuTabs.Notes | ClaimNoteMenuTabs.Notifications;

const selected = 'inspection-menu-label menu-text-size inspection-menu-selected';
const unselected = 'inspection-menu-label menu-text-size inspection-menu-unselected';

type Props = {
  noteSelectedOptions: ClaimNoteActiveOption;
  setNoteSelectedOptions: (arg0: ClaimNoteActiveOption) => void;
};

const ClaimNotesHeader: React.FC<Props> = ({ noteSelectedOptions, setNoteSelectedOptions }) => (
  <IonToolbar className="ion-no-padding" slot='fixed'>
      <div className='claim-menu-container'>
        <IonLabel
          className={noteSelectedOptions === ClaimNoteMenuTabs.Notes ? selected : unselected}
          onClick={() => setNoteSelectedOptions(ClaimNoteMenuTabs.Notes)}
        >
          {t('notes').toString()}
        </IonLabel>
        <IonLabel
          className={noteSelectedOptions === ClaimNoteMenuTabs.Notifications ? selected : unselected}
          onClick={() => setNoteSelectedOptions(ClaimNoteMenuTabs.Notifications)}
        >
          {t('communications').toString()}
        </IonLabel>
      </div>
  </IonToolbar>);

export default ClaimNotesHeader;