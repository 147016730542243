import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  IonCheckbox,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRange,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Rect } from '../sketch-tool';

interface RectEditProps {
  object: Rect;
  isOpen: boolean;
  onClose: (event: any) => any;
}

const black = '#000';

const RectEdit: FC<RectEditProps> = ({ object, isOpen, onClose }: RectEditProps) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [mode, setMode] = useState<'fill' | 'stroke'>('fill');
  const [color, setColor] = useState(black);
  const [showDimensions, setShowDimensions] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpen) return;
    setWidth(object.width);
    setHeight(object.height);
    setRotation(object.rotation);
    setMode(object.mode);
    setShowDimensions(object.showDimensions);
    setColor(object.fillStyle || object.strokeStyle || black);
  }, [isOpen, object]);

  useEffect(() => {
    if (isOpen) object.setWidth(width);
  }, [width, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setHeight(height);
  }, [height, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setRotation(rotation);
  }, [rotation, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setMode(mode);
  }, [mode, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setShowDimensions(showDimensions);
  }, [showDimensions, object, isOpen]);

  useEffect(() => {
    if (isOpen) {
      object.setFillStyle(color);
      object.setStrokeStyle(color);
    }
  }, [color, object, isOpen]);

  const handleWidthChange = (event: any) => {
    setWidth(Number(event.detail.value));
  };

  const handleHeightChange = (event: any) => {
    setHeight(Number(event.detail.value));
  };

  const handleRotationChange = (event: any) => {
    setRotation(Number(event.detail.value));
  };

  const handleModeChange = (event: any) => {
    setMode(event.detail.value);
  };

  const handleShowDimensionsChange = (event: any) => {
    setShowDimensions(event.detail.checked);
  };

  const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} initialBreakpoint={0.75} breakpoints={[0, 0.25, 0.5, 0.75, 1]}>
      <IonContent className="ion-padding">
        <div style={{ overflow: 'scroll' }}>
          <IonList>
            <IonListHeader>
              <IonLabel>
                {t('dimensions').toString()}
              </IonLabel>
            </IonListHeader>
            <IonItem>
              <IonLabel position="stacked">
                {t('width').toString()}
              </IonLabel>
              <IonInput value={width} onIonChange={handleWidthChange} type="number" />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                {t('height').toString()}
              </IonLabel>
              <IonInput value={height} onIonChange={handleHeightChange} type="number" />
            </IonItem>
            <IonListHeader>
              <IonLabel>
                {t('dimensions').toString()}
              </IonLabel>
            </IonListHeader>
            <IonItem lines="none">
              <IonLabel position="stacked">
                {t('rotation').toString()}
              </IonLabel>
              <IonRange
                value={rotation}
                onIonChange={handleRotationChange}
                pin
                pinFormatter={(value: number) => `${value}˚`}
                max={360}
              />
            </IonItem>
            <IonListHeader>
              <IonLabel>
                {t('style').toString()}
              </IonLabel>
            </IonListHeader>
            <IonItem lines="none">
              <IonLabel>
                {t('mode').toString()}
              </IonLabel>
              <IonSelect
                aria-label="mode"
                placeholder={t('rectMode')}
                value={mode}
                onIonChange={handleModeChange}
              >
                <IonSelectOption value="fill">
                  {t('fill').toString()}
                </IonSelectOption>
                <IonSelectOption value="stroke">
                  {t('stroke').toString()}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>
                {t('showDimensions').toString()}
              </IonLabel>
              <IonCheckbox checked={showDimensions} onIonChange={handleShowDimensionsChange} />
            </IonItem>
            <IonItem lines="none">
              <IonLabel>
                {t('color').toString()}
              </IonLabel>
              <input type="color" onChange={handleColorChange} value={color} />
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default RectEdit;
