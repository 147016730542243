import { Storage } from '@ionic/storage';
import GET_LOSS_TYPES_LIST from '../graphql/GetLossTypesList';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseCacheAndList from './baseCacheAndList';
import { LossTypesType } from '../pages/helper/LossTypesType';

const useLossTypes = (region: number, userState: UserState, storage: Storage) => {
  const lossTypeList:LossTypesType[] = useBaseCacheAndList(region, userState, storage, GET_LOSS_TYPES_LIST, claimsKeys.lossTypeFilter(), 'lossTypes');
  return lossTypeList;
};

export default useLossTypes;