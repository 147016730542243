import React from 'react';
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonThumbnail,
} from '@ionic/react';
import { arrowForward, arrowBack } from 'ionicons/icons';
import './FosDocItem.css';
import { ReactComponent as MoreIcon } from '../assets/icons/ic_more.svg';
import { ReactComponent as RightChevronIcon } from '../assets/icons/ic_chevron_right.svg';
import FosCachedImage from '../components/FosCachedImage';

interface Props {
  label?: string;
  secondLabel?: string;
  subheading?: string;
  thumbSrc?: string;
  icon?: string;
  iconColor?: string;
  labelLeftArrow?: boolean;
  labelRightArrow?: boolean;
  onThumbClick?: (event: any) => any;
  onItemClick?: (event: any) => any;
  onTextClick?: (event: any) => any;
  onOptionsClick?: (event: any) => any;
  onArrowClick?: (event: any) => any;
}

const FosDocItem = React.forwardRef<HTMLIonItemElement, Props>(({
  label,
  secondLabel,
  subheading,
  thumbSrc,
  icon,
  iconColor,
  labelLeftArrow,
  labelRightArrow,
  onThumbClick,
  onItemClick,
  onTextClick,
  onOptionsClick,
  onArrowClick,
}, ref) => (
  <IonItem ref={ref} class="fos-border ion-margin-bottom fos-doc-item" lines="none" onClick={onItemClick}>
    {thumbSrc &&
      <IonThumbnail className='fos-doc-item-thumb' slot="start" onClick={onThumbClick} >
        <FosCachedImage src={thumbSrc} />
      </IonThumbnail>}
    <div onClick={onTextClick} role="presentation" className="fos-doc-label-row">
      {label &&
        <div className='fos-doc-item-label'>
          {labelLeftArrow ? <IonIcon icon={arrowBack} className='icon-arrow' /> : ''}
          {labelRightArrow ? <IonIcon icon={arrowForward} className='icon-arrow' /> : ''}
          <IonLabel>
            {label}
          </IonLabel>
          {secondLabel && <IonLabel className='fos-doc-item-second-label'>
            {secondLabel}
          </IonLabel>}
        </div>
      }
      {subheading &&
        <div className='fos-doc-item-subheading-label'>
          <IonLabel>{subheading}</IonLabel>
        </div>
      }
    </div>
    <IonButtons slot="end">
      {onOptionsClick &&
        <IonButton onClick={onOptionsClick} className='fos-document-more-button'>
          <MoreIcon fill="none" className="more-icon" />
        </IonButton>
      }
      {onArrowClick &&
        <IonButton fill='clear' onClick={onArrowClick}>
          <RightChevronIcon className="right-chevron" fill="none" />
        </IonButton>
      }
      {icon && <IonIcon slot="start" icon={icon} color={iconColor} />}
    </IonButtons>
  </IonItem>
));

export default FosDocItem;
