/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

import { useIonRouter } from '@ionic/react';
import { t } from 'i18next';
import { GUID } from '../graphql/GetUserProfileInfo';
import { useAuth } from '../hooks/authContext';
import useUserExpenseSummary from '../hooks/useUserExpensesSummary';
import { UserExpenseSummaryType } from '../graphql/GetUserExpenseSummary';
import useUserExpenseList from '../hooks/useUserExpensesList';
import { UserExpenseObject } from '../graphql/GetUserExpenseList';
import useExpenseStatuses from '../hooks/useExpenseStatuses';
import { ExpenseStatusesType } from '../graphql/GetExpenseStatuses';
import { Option } from '../utils/Interfaces';
import FosAddExpenseActionSheet from '../atom/FosAddExpenseActionSheet';
import FosEditExpenseActionSheet from '../atom/FosEditExpenseActionSheet';

const UserExpensesViewModel = () => {
  const { userState } = useAuth()!;
  const myExpenseFilterModal = useRef<HTMLIonModalElement>(null);
  const [userExpenseSummaryFiltered, setUserExpenseSummaryFiltered] = useState<UserExpenseSummaryType[]>([]);
  const [userExpenseListFiltered, setUserExpenseListFiltered] = useState<UserExpenseObject[]>([]);
  const [expenseStatusList, setExpenseStatusList] = useState<ExpenseStatusesType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [expenseMethodId, setExpenseMethodId] = useState<number>(0);
  const [filterByStatus, setFilterByStatus] = useState<string>('0');
  const [expenseStatusFilter, setExpenseStatusFilter] = useState<number | null>(null);
  const [defaultStatus, setDefaultStatus] = useState<number>(0);
  const [isFilteringExpenses, setIsFilteringExpenses] = useState<boolean>(false);

  const { handleClick } = FosAddExpenseActionSheet();
  const { handleClick: editClick } = FosEditExpenseActionSheet(); 
  const ionRouter = useIonRouter();
  const regionId = userState.userAuthPayload?.regionId!;
  const userGuid = window.localStorage.getItem(GUID) as string;

  const { expenseStatuses } = useExpenseStatuses(userState);
  
  useEffect(() => {
    if (expenseStatuses && expenseStatuses.length > 0) {
      const defaultStatusId = expenseStatuses.find(e => e.name.includes('Pending'))?.id;
      if (defaultStatusId) {
        setDefaultStatus(defaultStatusId);
        setFilterByStatus(defaultStatusId.toString());
        setExpenseStatusFilter(defaultStatusId);
      }
    }
  }, [expenseStatuses]);

  const { userExpenseSummary, isFetching, refetch } = useUserExpenseSummary(userState, regionId, userGuid);

  const statusId = expenseStatusFilter || defaultStatus;
  const { userExpenseList, isFetching: isFetchingExpenses, refetch: refetchExpenseList } = useUserExpenseList(userState, regionId, userGuid, expenseMethodId, statusId);
 
  useEffect(() => {
    refetch();
    refetchExpenseList();    
  }, [ionRouter.routeInfo]);

  const onSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    if (expenseStatuses) {
      setExpenseStatusList(expenseStatuses);
    }
  }, [expenseStatuses]);

  useEffect(() => {
    setIsFilteringExpenses(true);
    if (searchTerm) {
      const filteredItems: UserExpenseObject[] = userExpenseList?.filter(userExpense => 
        userExpense.expense?.comments?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userExpense.expense?.merchant?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userExpense.expense?.id === Number(searchTerm) ||
        userExpense?.childExpenses?.some(c => 
          c?.expense?.comments?.toLowerCase().includes(searchTerm.toLowerCase()) || 
          c?.expense?.merchant?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          c.expense?.id === Number(searchTerm))) || [];
      setUserExpenseListFiltered(filteredItems);
    } else {
      setUserExpenseSummaryFiltered(userExpenseSummary || []);
      setUserExpenseListFiltered(userExpenseList || []);
    }    
    setIsFilteringExpenses(false);
  }, [expenseMethodId, searchTerm, userExpenseList, userExpenseSummary]);

  const addExpense = () => handleClick();
  const navigateToUserExpenseCategory = (id: number) => {
    setExpenseMethodId(id);
    setSearchTerm('');
  };

  const editUserExpenseCategory = (id: number, expenseMethodId: number) => {
    editClick(id.toString(), expenseMethodId);
  };

  const expenseSummaryItem = userExpenseSummaryFiltered?.length > 0 ?
    userExpenseSummaryFiltered?.find(userExpenseSummary => userExpenseSummary?.id === expenseMethodId)?.expenseMethodType : '';
  const selectedExpenseMethodName = t(`${expenseSummaryItem}`);

  const backToCategories = () => {
    setExpenseMethodId(0);
    setSearchTerm('');
  };

  const showResults = () => {
    // do filter
    setExpenseStatusFilter(filterByStatus ? Number(filterByStatus) : null);
    myExpenseFilterModal.current?.dismiss();
  };

  const clearFilter = () => {
    setFilterByStatus(defaultStatus.toString());
    setExpenseStatusFilter(null);
    myExpenseFilterModal.current?.dismiss();
  };

  const expenseStatusListOptions: Option[] = expenseStatusList
    .map((expenseStatus: ExpenseStatusesType) => ({ label: expenseStatus.name, value: expenseStatus.id.toString() }));

  return { 
    userExpenseSummaryFiltered,
    userExpenseListFiltered,
    isFetching,
    isFetchingExpenses,
    expenseMethodId,
    selectedExpenseMethodName,
    searchTerm,
    myExpenseFilterModal,
    expenseStatusListOptions,
    filterByStatus,
    setFilterByStatus,
    clearFilter,
    showResults,
    onSearchChange,
    addExpense,
    navigateToUserExpenseCategory,
    editUserExpenseCategory,
    backToCategories,
    isFilteringExpenses,
    refetchExpenseList,
  };
};

export default UserExpensesViewModel;