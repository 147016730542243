import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isPlatform } from '@ionic/react';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import { useAuth } from '../hooks/authContext';
import { ProcessQueueEvent } from '../processQueue';
import { jpegEncode } from '../components/FosCachedImage';
import SaveStorage, { SavedRecords } from '../utils/SaveStorage';
import NetworkContext from './NetworkContext';
import { queue } from '../photoUploadQueue';
import { HYBRID } from '../hooks/photo';
// import { delay } from '../utils/Utilities';

export interface AddPhotosType {
  regionId: string,
  claimIndex: string,
  description: string,
  roomId: number | undefined,
  includedInIsi: string | undefined,
  includeTimestamp: string | undefined,
  uploadBy: string,
  sendToXact: string | boolean | undefined,
  sendToSymbility: string | boolean | undefined,
  phaseIndx: string | number | undefined,
  files: File[],
  retryCount?: number,
  processed?: boolean,
  tempId?: number;
  filePath?: string;
  countFail: number;
}

interface PhotoUploadContextType {
  syncCount: number;
  totalCount: number;
  failCount: number;
  addPhotos: (photos: AddPhotosType) => void;
  editPhoto: (photo: AddPhotosType) => void;
}

const defaultContext: PhotoUploadContextType = {
  syncCount: 0,
  totalCount: 0,
  failCount: 0,
  addPhotos: () => { },
  editPhoto: () => { },
};
/*
const MAX_QUEUE_CHECK = 25;

const DELAY_TO_CHECK = 1000;

const TEXT_TO_CHECK = 'Not Queued';
*/
const PhotoUploadContext = createContext<PhotoUploadContextType>(defaultContext);

const PhotoUploadProvider = ({ children }: any) => {
  const { userState, refreshToken } = useAuth()!;
  const [totalCount, setTotalCount] = useState<number>(0);
  const [syncCount, setSyncCount] = useState<number>(0);
  const [failCount, setFailCount] = useState<number>(0);
  const network = useContext(NetworkContext);

  const uploadFn = useCallback(async (uploadPhoto: AddPhotosType) => {
    const formData = new FormData();

    formData.append('regionId', uploadPhoto.regionId);
    formData.append('description', uploadPhoto.description);

    if (uploadPhoto.roomId) {
      formData.append('roomId', uploadPhoto.roomId.toString());
    }
    if (uploadPhoto.includedInIsi) {
      formData.append('includedInIsi', uploadPhoto.includedInIsi);
    }
    if (uploadPhoto.includeTimestamp) {
      formData.append('includeTimestamp', uploadPhoto.includeTimestamp);
    }
    formData.append('uploadBy', uploadPhoto.uploadBy);

    formData.append('files', uploadPhoto.files[0]);

    if (uploadPhoto.phaseIndx) {
      formData.append('phaseIndx', uploadPhoto.phaseIndx.toString());
    }

    if (uploadPhoto.sendToXact) {
      formData.append('sendToXact', uploadPhoto.sendToXact.toString());
    }

    if (uploadPhoto.sendToSymbility) { 
      formData.append('sendToSymbility', uploadPhoto.sendToSymbility.toString());
    }

    const restClient = getRestClient(userState, ClientType.FORM);
    await restClient.post(`/common/claims/${uploadPhoto.claimIndex}/photos`, formData);
    /*
    const resp = await restClient.post(`/common/claims/${uploadPhoto.claimIndex}/photos/async`, formData);
    if (!resp?.data?.[0]) {
      throw new Error('File not queued.');
    }
    const fileId = resp.data?.[0];

    let getStatus;
    let maxCount = 0;
    while (getStatus?.data !== TEXT_TO_CHECK && maxCount <= MAX_QUEUE_CHECK) {
      // eslint-disable-next-line no-await-in-loop
      getStatus = await restClient.get(`/common/claims/${uploadPhoto.claimIndex}/photos/async/${fileId}`);
      maxCount++;
      delay(DELAY_TO_CHECK);
    } */
  }, [userState]);

  const pingAPI = useCallback(async () => {
    const restClient = getRestClient(userState, ClientType.JSON);
    return restClient.get('/common/mobile/ping');
  }, [userState]);

  const initQueue = useCallback(async () => {
    const updateStorage = async () => {
      const saveStorage = SaveStorage();
      await saveStorage.setItem(SavedRecords.AddPhotosType, queue.queue, 1);
    };

    queue.removeAllListeners();
    queue.setProcessFn(uploadFn);
    queue.setPreProcessFn(async (item) => {
      try {
        const resp = await pingAPI();
        if (!resp.data)
          refreshToken();
      } catch (ex: any) {
        if (ex.code === 'ERR_NETWORK') {
          throw ex;
        }
        refreshToken();
      }
      if (!isPlatform(HYBRID)) {
        const optimizedFile = await jpegEncode(item.files[0]);
        return { ...item, files: [optimizedFile] };
      }
      return item;
    });
    queue.on(ProcessQueueEvent.AddItem, async () => {
      setTotalCount(queue.countTotal());
      await updateStorage();
    });
    queue.on(ProcessQueueEvent.EditItem, async () => {
      await updateStorage();
    });
    queue.on(ProcessQueueEvent.ItemProcessFinished, async () => {
      setSyncCount(queue.countSynced());
      setFailCount(queue.countFailSynced());
      await updateStorage();
    });
    queue.on(ProcessQueueEvent.AllItemsProcessFinished, async () => {
      setTotalCount(0);
      setSyncCount(0);
      await updateStorage();
    });
    queue.initialized = true;
    const saveStorage = SaveStorage();
    const rows = await saveStorage.getItems<AddPhotosType[]>(SavedRecords.AddPhotosType);
    queue.setQueue(rows?.length ? Object.values(rows[0].data) : []);
    queue.sync();
  }, [pingAPI, refreshToken, uploadFn]);

  useEffect(() => {
    if (userState.userAuthPayload && !queue.initialized) {
      initQueue();
    }
  }, [userState, initQueue]);

  useEffect(() => {
    if (network.connected) {
      queue.sync();
    }
  }, [network.connected]);

  const value = useMemo(() => {
    const addPhotos = (addPhotosData: AddPhotosType) => {
      for (const file of addPhotosData.files) {
        const photo = { ...addPhotosData, files: [file] };
        photo.tempId = Date.now();
        queue.add(photo);
      }
      queue.sync();
    };
    const editPhoto = (addPhotoData: AddPhotosType) => {
      queue.edit(addPhotoData);
    };

    return { addPhotos, totalCount, syncCount, failCount, editPhoto };
  }, [totalCount, syncCount, failCount]);

  return (
    <PhotoUploadContext.Provider value={value}>
      {children}
    </PhotoUploadContext.Provider>
  );
};

export { PhotoUploadContext, PhotoUploadProvider };

export const usePhotoUpload: () => PhotoUploadContextType = () => useContext(PhotoUploadContext);