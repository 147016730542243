export enum DefaultPhases {
  EMERGENCY = 'Emergency',
  REPAIR = 'Repair',
  CONTENTS = 'Contents',
  CANCEL = 'Cancel',
}

export enum DefaultPhaseIds {
  EMERGENCY = 'EM',
  REPAIR = 'RE',
  CONTENTS = 'CT',
  CANCEL = '',
}

export function getPhaseIdFromLabel(label: DefaultPhases) {
  return {
    [DefaultPhases.EMERGENCY]: 'EM',
    [DefaultPhases.REPAIR]: 'RE',
    [DefaultPhases.CONTENTS]: 'CT',
    [DefaultPhases.CANCEL]: null,
  }[label];
}

export const ACTION_SHEET_DEFAULT_PHASES = [
  {
    text: DefaultPhases.EMERGENCY,
    data: {
      action: DefaultPhases.EMERGENCY,
    },
  },
  {
    text: DefaultPhases.REPAIR,
    data: {
      action: DefaultPhases.REPAIR,
    },
  },
  {
    text: DefaultPhases.CONTENTS,
    data: {
      action: DefaultPhases.CONTENTS,
    },
  },
  {
    text: DefaultPhases.CANCEL,
    role: 'destructive',
    data: {
      action: DefaultPhases.CANCEL,
    },
  },
];