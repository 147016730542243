import { Storage } from '@ionic/storage';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseCacheAndList from './baseCacheAndList';
import { ConstructionManagersType } from '../pages/helper/ConstructionManagersType';
import GET_CONSTRUCTION_MANAGER_LIST from '../graphql/GetConstructionManagersList';

const useConstructionManagers = (region: number, userState: UserState, storage: Storage) => {
  const constructionManagersList:ConstructionManagersType[] = useBaseCacheAndList(region, userState, storage, GET_CONSTRUCTION_MANAGER_LIST, claimsKeys.constructionManagersList(), 'projectCoordinators');
  return constructionManagersList;
};

export default useConstructionManagers;