import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import GET_TEMPLATES from '../graphql/GetTemplates';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';

const useTemplates = (userState: UserState, selectedLossType: string, params: any) => useQuery({
  queryKey: ['templates', params],
  queryFn: (context: QueryFunctionContext) => getGraphQLClient(userState).request(GET_TEMPLATES, context.queryKey[1]),
  refetchOnWindowFocus: false,
  enabled: !!selectedLossType,
});

export default useTemplates;
