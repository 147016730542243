import { UserState } from './authUtils';

import { DomainParams } from './graphQLClientUtil';
import useBaseQuery from './baseQuery';
import GET_VALIDATE_CLAIM_STATUS from '../graphql/GetValidateClaimStatus';

const useValidateClaimStatus = (userState: UserState, region: number, branchId: number, claimIndex: number, phaseIndex: number) => {
 
  const { data, isLoading, isFetching } = useBaseQuery(
    region,
    { regionId: +region, branchId: +branchId, claimIndex: +claimIndex, phaseIndex: +phaseIndex },
    userState,
    true,
    GET_VALIDATE_CLAIM_STATUS,
    ['useValidateClaimStatus'],
    DomainParams.Payroll,
  );
  const claimStatus: boolean | undefined = (!isLoading && !isFetching) ? data?.validateClaimStatus?.resultCode !== 'FAILURE' : undefined;
  return { claimStatus, isLoading, isFetching };
};

export default useValidateClaimStatus;
