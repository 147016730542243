import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRange,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { PenToolSettings, Sketch } from '../sketch-tool';

export interface PenToolSettingsProps {
  sketch: Sketch;
}

const SketchToolPenToolSettings = ({ sketch }: PenToolSettingsProps) => {
  const [penToolSettings, setPenToolSettings] = useState<PenToolSettings>(sketch?.penToolSettings || {});
  const { t } = useTranslation();

  useEffect(() => {
    if (!sketch) return;
    sketch.penToolSettings = penToolSettings;
  }, [sketch, penToolSettings]);

  const handlePenColorChange = (event: any) => {
    setPenToolSettings({ ...penToolSettings, strokeStyle: event.target.value });
  };

  const handlePenLineWidthChange = (event: any) => {
    setPenToolSettings({ ...penToolSettings, lineWidth: +event.detail.value });
  };

  return (
    <IonToolbar color="light">
      <IonGrid>
        <IonRow class="ion-align-items-center ion-nowrap" style={{ overflowX: 'scroll' }}>
          <IonCol size="14">
            <IonItem lines="none" color="light">
              <IonLabel>
                {t('lineWidth').toString()}
              </IonLabel>
              <IonRange value={penToolSettings.lineWidth || 1} min={1} max={30} onIonChange={handlePenLineWidthChange} />
            </IonItem>
          </IonCol>
          <IonCol>
            <input type="color" value={penToolSettings.strokeStyle || '#000000'} onChange={handlePenColorChange} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );
};

export default SketchToolPenToolSettings;
