import { useState } from 'react';
import { UserState } from './authUtils';

import { DomainParams } from './graphQLClientUtil';
import useBaseQuery from './baseQuery';
import GET_USER_EXPENSES_FOR_APPROVAL_LIST, { UserExpenseObject } from '../graphql/GetUserExpenseForApprovalList';

const useUserExpenseForApprovalList = (userState: UserState, region: number, userGuid: string, expenseMethodId: number, statusId: number) => {
  const [userExpenseForApprovalList, setUserExpenseForApprovalList] = useState<UserExpenseObject[]>();

  const onSuccess = async (data: any) => {
    const expenseList: UserExpenseObject[] = data?.expensesToBeApproved || [];
    setUserExpenseForApprovalList(expenseList);
  };

  const { isLoading, isFetching, refetch } = useBaseQuery(
    region,
    { regionId: region, userGuid, expenseMethodId, statusId },
    userState,
    !!expenseMethodId,
    GET_USER_EXPENSES_FOR_APPROVAL_LIST,
    ['useUserExpenseForApprovalList'],
    DomainParams.Payroll,
    onSuccess,
  );

  return { userExpenseForApprovalList, isLoading, isFetching, refetch };
};

export default useUserExpenseForApprovalList;
