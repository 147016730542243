import React, { useEffect, useMemo, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonModal,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SimpleExpenseForm, SimpleExpenseFormData, SimpleExpenseFormErrors, createSimpleExp, getSimpleExpErrors } from './ExpSimpleExpenseForm';

interface SimpleExpenseFormModalProps {
  location: string
  initialExpense?: SimpleExpenseFormData | null
  isOpen: boolean
  editMode: boolean
  onClose: (expense?: SimpleExpenseFormData) => any
}

const SimpleExpenseFormModal: React.FC<SimpleExpenseFormModalProps> = ({ location, initialExpense, isOpen, editMode, onClose }) => {
  const simpleExp: SimpleExpenseFormData = useMemo(() => createSimpleExp(), []);
  const [errors, setErrors] = useState<SimpleExpenseFormErrors>({});

  const [expense, setExpense] = useState<SimpleExpenseFormData>(simpleExp);
  const { t } = useTranslation();

  useEffect(() => {
    setExpense(initialExpense || { ...simpleExp });
  }, [initialExpense, simpleExp]);

  const handleSimpleExpenseChange = (id: keyof SimpleExpenseFormData, value: any, updatedExpense: SimpleExpenseFormData) => {
    const exp = { ...updatedExpense };
    if (id === 'branch') {
      exp.category = 0;
      exp.department = 0;
    }

    if (id === 'category') {
      exp.department = 0;
    }

    setExpense(exp);

    const errs = getSimpleExpErrors(exp, t);
    setErrors(errs);
  };

  const handleSave = () => {
    const errs = getSimpleExpErrors(expense, t);
    setErrors(errs);
    if (Object.values(errs).length) return;
    onClose(expense);
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="danger" onClick={() => onClose()}>{t('cancel').toString()}</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={handleSave}>
              {t('save').toString()}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <SimpleExpenseForm editMode={editMode} id="form-modal" errors={errors} showTotalAmount expense={expense} onChange={handleSimpleExpenseChange} location={location} />
      </IonContent>
    </IonModal>
  );
};

export default SimpleExpenseFormModal;
