/* eslint-disable no-console */
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Geolocation } from '@capacitor/geolocation';
import {
  IonContent,
  IonLoading,
  IonPage,
  IonFab,
  IonFabButton,
  IonIcon,
  IonToast,
} from '@ionic/react';
import { useQuery, QueryClient, MutationCache, QueryFunctionContext } from '@tanstack/react-query';
import toast, { Toaster } from 'react-hot-toast';
import { Variables } from 'graphql-request';
import { construct, closeOutline } from 'ionicons/icons';

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from '../hooks/authContext';
import Header from '../molecule/Header';
import JobsComponent from '../organism/JobsComponent';
import { claimsKeys } from '../hooks/claims';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import useLossTypes from '../hooks/lossTypes';
import GET_CLAIMS_LIST from '../graphql/GetClaimsList';
import useBranches from '../hooks/branches';
import useProjectManagers from '../hooks/projectManagers';
import useAdjusters from '../hooks/adjusters';
import useInsuranceCompanies from '../hooks/insuranceCompanies';
import usePropertyManagers from '../hooks/propertyManagers';
import useConstructionManagers from '../hooks/constructionManagers';
import useStorage from '../hooks/storage';
import { ClaimOnlineStatus } from './helper/Const';
import { getRefetchKey } from './helper/JobsHelper';
import useRegionList from '../hooks/regionsPerUser';
import './Jobs.css';
import SaveStorage from '../utils/SaveStorage';
import ToolsModal from '../organism/ToolsModal';
import NetworkContext from '../contexts/NetworkContext';
import { syncClaimDetails, useCreateNewClaimMutation, useEditClaimMutation } from './helper/offlineHelper/ClaimDetailsHelper';
import { useClaimOffline } from '../contexts/ClaimOfflineContext';
import { useClaimDataDownloader } from './helper/offlineHelper/ClaimDataDownloader';
import { getAndSyncClaimSiteInfo, useSaveSiteInfoMutation } from './helper/offlineHelper/ClaimSiteInfoHelper';
import { getAndSyncClaimLevels } from './helper/offlineHelper/ClaimLevelsHelper';
import useSaveClaimLevels from '../hooks/saveClaimLevels';
import { getAndSyncClaimSafety, useSaveSafetyMutation } from './helper/offlineHelper/ClaimSafetyHelper';
import { PermissionsList, usePermissions } from '../hooks/permissions';
import { getAndSyncClaimPhotos, useSavePhotoMutation } from './helper/offlineHelper/ClaimPhotosHelper';
import { GUID, REGION_ID } from '../graphql/GetUserProfileInfo';

type StyleType = {
  container: React.CSSProperties;
};

const styles: StyleType = {
  container: {
    display: 'flex',
  },
};

type Props = {};

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 2000,
      retry: 0,
    },
  },
  // configure global cache callbacks to show toast notifications
  mutationCache: new MutationCache({
    onSuccess: (data: any) => {
      toast.success(data.message);
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  }),
});

const Jobs: FC<Props> = () => {
  const { t } = useTranslation();
  const { userState, logIn, checkTokenExpiration } = useAuth()!;
  const [tokenRefreshing, setTokenRefreshing] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({});
  const [sortData, setSortData] = useState<any>([]);
  const graphQLClient = getGraphQLClient(userState);
  const [searchTerm, setSearchTerm] = useState('');
  const [latestToken, setLatestToken] = useState<string>(userState.userAuthPayload?.accessToken || '');
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [showToolsModal, setShowToolsModal] = useState(false);
  const network = useContext(NetworkContext);
  const history = useHistory();
  const [saveError, setSaveError] = useState('');
  const { offlineClaims : offlineClaimStorage, refresh }  = useClaimOffline()!;
  const [geolocation, setGeolocation] = useState<any>(null);

  const offlineClaim = useMemo(() => {
    const notEmptyFilter = filter.lossType || filter.pmidClaim || filter.branchCode;
    if (network.connected && (notEmptyFilter || searchTerm)) {
      return [];
    }
    return offlineClaimStorage || [];
  }
  , [filter, network.connected, offlineClaimStorage, searchTerm]);

  const filterObject = Object.keys(filter).length === 0 ? {} : filter;
  const region = userState.userAuthPayload?.regionId! || 
    Number(window.localStorage.getItem(`${REGION_ID}_${window.localStorage.getItem(GUID) || ''}`));

  const { jobIdx: downloadingJobIdx, downloadClaim } = useClaimDataDownloader({ region, refresh });

  const variablesClaims: Variables = {
    regionId: region,
    filterArray: filterObject,
    term: searchTerm,
    latitude: geolocation?.latitude || null,
    longitude: geolocation?.longitude || null,
    notCompleted: !searchTerm ? true : undefined,
  };

  useEffect(() => {
    if (!geolocation) {
      const getGeolocation = async () => {
        try {
          const coordinates = await Geolocation.getCurrentPosition();
          if (coordinates?.coords?.latitude && coordinates?.coords?.longitude) {
            setGeolocation(coordinates?.coords);
          }
        } finally {
          // user didn't allow geoLocation
        }
      };
      getGeolocation();
    }
  }, [geolocation]);

  const filteredList = (originalList: any, exclusionList: any) => originalList?.filter((claim: any) =>
    !exclusionList.some((excludedClaim: any) => excludedClaim.claimNumber === claim.claimNumber));

  const onSuccessGetClaims = async (data: any) => {
    let localJobs = data?.claims?.slice(0, 50) || [];
    // remove available offline from the list
    if (localJobs?.length > 0 && offlineClaim.length > 0) {
      const excludedClaim = offlineClaim.map((o: any) => o.data.claim);
      localJobs = filteredList(localJobs, excludedClaim);
    }
    setSortData(localJobs);
  };

  useEffect(() => {
    if (sortData?.length > 0 && offlineClaim.length > 0) {
      const excludedClaim = offlineClaim.map((o: any) => o.data.claim);
      const localJobs = filteredList(sortData, excludedClaim);
      if (localJobs.length !== sortData.length) {
        setSortData(localJobs);
      }
    }
  }, [offlineClaim, sortData]);

  const enabled = userState.userAuthPayload?.accessToken !== undefined;
  const { error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [...claimsKeys.list(), variablesClaims, getRefetchKey()],
    queryFn: (ctx: QueryFunctionContext) => graphQLClient.request(GET_CLAIMS_LIST, ctx.queryKey[1]),
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: onSuccessGetClaims,
  });

  // refetch when component change
  useEffect(() => {
    const asyncRefetch = async () => {
      await checkTokenExpiration();
      await refetch();
    };
    asyncRefetch();
  }, [refetch, checkTokenExpiration]);

  const { mutateAsync: mutateSaveClaimAsync } = useCreateNewClaimMutation();
  const { updateClaim: mutateEditClaimAsync } = useEditClaimMutation();
  const { mutateAsync: mutateSaveClaimSiteInfo } = useSaveSiteInfoMutation();
  const { mutateAsync: mutateSaveClaimLevels } = useSaveClaimLevels();
  const { mutateAsync: mutateSaveClaimSafety } = useSaveSafetyMutation();
  const { mutateAsync: mutateSaveClaimPhoto } = useSavePhotoMutation();

  useEffect(()=>{
    if (connectionStatus) {
      const checkStorage = async () => {
        const saveStorage = SaveStorage();
        await checkTokenExpiration();
        const filteredClaimStorage = offlineClaim?.filter(offlineClaim => offlineClaim?.data?.claim?.status === ClaimOnlineStatus.ToBeSynched);
        if (filteredClaimStorage?.length > 0) {
          filteredClaimStorage.forEach(async (claimData) => {
            await syncClaimDetails(claimData, saveStorage, mutateSaveClaimAsync, mutateEditClaimAsync);
          });
          await refetch();
          await refresh();
        }
        getAndSyncClaimSiteInfo(saveStorage, mutateSaveClaimSiteInfo);
        getAndSyncClaimLevels(saveStorage, region, mutateSaveClaimLevels);
        getAndSyncClaimSafety(saveStorage, mutateSaveClaimSafety);
        getAndSyncClaimPhotos(saveStorage, region, mutateSaveClaimPhoto);
      };
      checkStorage();
    }
  }, [
    connectionStatus,
    mutateEditClaimAsync,
    mutateSaveClaimAsync,
    mutateSaveClaimLevels,
    mutateSaveClaimSiteInfo,
    mutateSaveClaimSafety,
    mutateSaveClaimPhoto,
    offlineClaim,
    refetch,
    refresh,
    t,
    region,
    checkTokenExpiration,
  ]);

  useEffect(()=> {
    setConnectionStatus(network.connected);
  }, [network.connected]);

  useEffect(() => {
    if (!isFetching) {
      if (error && !tokenRefreshing) {
        const respError: any = error;
        if (respError?.response.ErrorCode === 400) {
          setSaveError(t('errorXSS'));
          return;
        }
        setTokenRefreshing(true);
        const asyncLogin = async () => {
          try {
            await logIn();
          } catch {
            history.replace('/login');
          }
        };
        asyncLogin();
      } else if (!error && tokenRefreshing) {
        setTokenRefreshing(false);
      }
      if (!!userState.userAuthPayload?.accessToken && latestToken !== userState.userAuthPayload?.accessToken) {
        setLatestToken(userState.userAuthPayload?.accessToken || '');
        refetch();
      }
    }
  }, [error, logIn, refetch, tokenRefreshing, isFetching, userState.userAuthPayload?.accessToken, latestToken, history, t]);

  useEffect(() => {
    if (isFetching || isLoading) return;
    const searchJobs = document.getElementById('search-jobs-bar') as HTMLIonSearchbarElement;
    if (searchJobs?.value) {
      // keep searching
      searchJobs.querySelector('input')?.focus();
    }
  }, [isFetching, isLoading]);

  if (error) console.error(error);

  // initialize storage
  const storage = useStorage();

  const lossTypes = useLossTypes(region, userState, storage);

  const branches = useBranches(region, userState, storage);

  const projectManagers = useProjectManagers(region, userState, storage);

  // load adjusters company list
  const adjusters = useAdjusters(region, userState, storage);

  // load insurance company list
  const insuranceCompanies = useInsuranceCompanies(region, userState, storage);

  // load property manager list
  const propertyManagers = usePropertyManagers(region, userState, storage);

  // load construction manager list
  useConstructionManagers(region, userState, storage);

  // load regions for the user
  useRegionList(userState, storage);

  const onFilterChange = (newFilter: any) => {
    setFilter(newFilter);
  };

  const onSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  const onDownloadClaimClick = (jobIdx: number) => {
    downloadClaim(jobIdx);
  };

  const { permissions } = usePermissions();
  const isAddClaimEnabled = permissions.includes(PermissionsList.AddClaims);
  const isViewClaimsEnabled = permissions.includes(PermissionsList.ViewClaims);

  useEffect(() => {
    if (permissions.length > 0 && !isViewClaimsEnabled) {
      history.replace('/tabs/profile');
    }
  }, [history, isViewClaimsEnabled, permissions]);

  return (
    <IonPage className='jobs-body'>
      <Header text="" headerType="jobs" onSearchJobs={onSearchChange} isAddClaimEnabled={isAddClaimEnabled} />
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
        onSuccess={() => {
          // resume mutations after initial restore from localStorage was successful
          queryClient.resumePausedMutations().then(() => {
            queryClient.invalidateQueries();
          });
        }}
      >
        <IonContent fullscreen>
          <div style={styles.container}>
            <JobsComponent
              offlineClaims={offlineClaim}
              claims={sortData}
              error={error}
              lossTypesFilter={lossTypes}
              projectManagersFilter={projectManagers}
              branchesFilter={branches}
              propertyManagers={propertyManagers}
              adjusters={adjusters}
              insurers={insuranceCompanies}
              onFilterChange={onFilterChange}
              onDownloadClaimClick={onDownloadClaimClick}
              downloadingJobIdx={downloadingJobIdx}
            />
          </div>
          <Toaster 
            containerStyle={{
              top: 150,
              left: 20,
              bottom: 20,
              right: 20,
            }}
          />
          <IonLoading
            isOpen={isLoading || isFetching}
            message={t('loadingJobs') || ''}
            duration={5000}
          />
          <ToolsModal
            isOpen={showToolsModal}
            onDismiss={() => setShowToolsModal(false)}
          />
          <IonFab slot="fixed" horizontal="end" vertical="bottom" onClick={() => setShowToolsModal(true)}>
            <IonFabButton className="tools-action-button">
              <IonIcon className="tools-action-button-icon" icon={construct} />
            </IonFabButton>
          </IonFab>
          <IonToast
            isOpen={!!saveError}
            message={saveError}
            buttons={[{ role: 'cancel', icon: closeOutline }]}
            onDidDismiss={() => { setSaveError(''); }}
            />
        </IonContent>
      </PersistQueryClientProvider>
    </IonPage>
  );
};

export default Jobs;
