/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import {
  IonList,
  IonButton,
  IonRadioGroup,
  IonContent,
  IonFooter,
  IonLoading,
  IonToast,
} from '@ionic/react';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './AddPhaseForm.css';
import './AddLevels.css';
import { Toaster, toast } from 'react-hot-toast';
import { closeOutline } from 'ionicons/icons';
import FosRadioButton from '../atom/FosRadioButton';
import FosCardHeader from '../atom/FosCardHeader';
import { provideAddedLevel, provideAddedRoom, provideLevelNameChange, provideRoomNameChange } from './AddLevelsHelper';

import useClaimLevels from '../hooks/claimLevels';
import { useAuth } from '../hooks/authContext';
import { LevelModelType, RoomModelType } from '../graphql/GetLevelsList';
import useSaveClaimLevels from '../hooks/saveClaimLevels';
import { ApiStatus, ClaimOnlineStatus } from '../pages/helper/Const';
import NetworkContext from '../contexts/NetworkContext';
import { storeClaimLevels } from '../pages/helper/offlineHelper/ClaimLevelsHelper';
import useDeleteRoom from '../hooks/deleteRoom';
import useDeleteLevel from '../hooks/deleteLevel';
import { ClaimPhotosType } from '../graphql/GetClaimPhotosList';
import useClaimPhotos from '../hooks/claimPhotos';
import useSketchesList from '../hooks/sketches';
import { useClaimOffline } from '../contexts/ClaimOfflineContext';
import { getErrorMsg } from '../pages/helper/AppHelper';

type Props = {
  onDone: (room?: RoomModelType) => any;
  enableSkip?: boolean;
  claimIndx: number;
};

const AddLevels: React.FC<Props> = ({ onDone, enableSkip, claimIndx }) => {
  const { t } = useTranslation();
  const { userState } = useAuth()!;
  const [levelsList, setLevelsList] = useState<LevelModelType[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<RoomModelType>();
  const region = userState.userAuthPayload?.regionId!;
  const [saveError, setSaveError] = useState('');
  const network = useContext(NetworkContext);
  const { mutateAsync: deleteRoom, isLoading: isRoomDeleting } = useDeleteRoom(userState);
  const { mutateAsync: deleteLevel, isLoading: isDeletingLevel } = useDeleteLevel(userState);
  const [refetchSketchesKey] = useState(Date.now());
  const [levelId, setLevelId] = useState<number>(0);
  const { refetch: refetchSketchList } = useSketchesList(userState, levelId, refetchSketchesKey);
  const { refresh } = useClaimOffline()!;

  const [claimPhotosList, setClaimPhotosList] = useState<ClaimPhotosType[]>([]);

  useClaimPhotos(
    region,
    claimIndx,
    userState,
    claimPhotosList,
    setClaimPhotosList,
  );

  const { refetch: refetchClaimLevels } = useClaimLevels(
    region,
    claimIndx!,
    userState,
    levelsList,
    setLevelsList,
  );

  const getRoomName = (name: string): string => `${t('room')} ${name}`;

  const handleChangeLevelName = (newLevelName: string, index: number) => {
    setLevelsList(provideLevelNameChange(levelsList, newLevelName, index));
  };

  const handleChangeRoomName = (newRoomName: string, roomPos: number, levelPos: number) => {
    setLevelsList(provideRoomNameChange(levelsList, newRoomName, roomPos, levelPos));
  };

  const onRoomSelect = (room: RoomModelType) => {
    setSelectedRoom(room);
  };

  /*
  * Save claim levels call
  * */
  const { isLoading: isSaving, mutateAsync } = useSaveClaimLevels();

  /*
  * Handling save claim levels service response
  * */
  const handleSaveClaimLevels = async (levelsList: LevelModelType[], callDone?: boolean) => {
    if (network.connected) {
      mutateAsync({ region, jobIdx: claimIndx!, levels: levelsList })
        .then((response) => {
          if (response?.saveLevels?.status === ApiStatus.SUCCESS) {
            if (callDone) onDone(selectedRoom);
            refetchClaimLevels();
            toast.success(t('siteInfoSaved'));
            return;
          }
          throw new Error(response.saveLevels?.message || t('saveSiteInfoError'));
        })
        .catch((e) => {
          const msg = getErrorMsg(e);
          const message = msg || t('saveSiteInfoError');
          setSaveError(message);
        });
    } else {
      await storeClaimLevels({ levels: levelsList, claimIndx: claimIndx!, status: ClaimOnlineStatus.ToBeSynched });
      if (callDone) onDone(selectedRoom);
      refresh();
    }
  };

  const onSave = () => {
    handleSaveClaimLevels(levelsList, true);
  };
  
  const handleAddLevel = (level?: LevelModelType, pos?: number) => {
    const newLevelList = provideAddedLevel(levelsList, level, pos);
    setLevelsList(newLevelList);
    handleSaveClaimLevels(newLevelList);
  };

  const handleAddRoom = (levelPos: number, roomName: string) => {
    handleAddLevel(provideAddedRoom(levelsList, levelPos, roomName), levelPos);
  };

  const handleRoomDelete = useCallback((roomPos: number, levelPos: number, roomId?: number) => {

    if (roomId) {
      const roomHasPhotos = claimPhotosList?.some((photo: ClaimPhotosType) => photo.roomId === roomId);

      if (roomHasPhotos) {
        toast.error(t('cannotDeleteRoomWithPhotos'));
        return;
      }
    
      deleteRoom(roomId)
        .then((response) => {
          if (response?.deleteRoom?.status === ApiStatus.SUCCESS) {
            const levels = [...levelsList];
            levels[levelPos].rooms.splice(roomPos, 1);
  
            toast.success(t('roomDeletedSuccessfully'));
            setLevelsList(levels);
            return;
          }
          throw new Error(response.deleteRoom?.message || t('deleteRoomError'));
        });
    } else {
      const levels = [...levelsList];
      levels[levelPos].rooms.splice(roomPos, 1);

      toast.success(t('roomDeletedSuccessfully'));
      setLevelsList(levels);
    }
  }, [
    claimPhotosList,
    deleteRoom,
    levelsList,
    t,
  ]);

  const handleLevelDelete = useCallback(async (levelPos: number, levelId?: number) => {
    if (levelId) {
      const levelHasRooms = levelsList[levelPos].rooms.length > 0;
      setLevelId(levelId!);

      const { data } = await refetchSketchList();
  
      if (data?.listSketches?.length! > 0) {
        toast.error(t('cannotDeleteLevelWithSketches'), { duration: 4000 });
        return;
      }

      if (levelHasRooms) {
        toast.error(t('cannotDeleteLevelWithRooms'));
        return;
      }

      deleteLevel(levelId)
        .then((response) => {
          if (response?.deleteLevel?.status === ApiStatus.SUCCESS) {
            const levels = [...levelsList];
            levels.splice(levelPos, 1);

            toast.success(t('levelDeletedSuccessfully'));
            setLevelsList(levels);
            return;
          }
          throw new Error(response.deleteLevel?.message || t('deleteLevelError'));
        });
    } else {
      const levels = [...levelsList];
      levels.splice(levelPos, 1);

      toast.success(t('levelDeletedSuccessfully'));
      setLevelsList(levels);
    }
  }, [
    t,
    refetchSketchList,
    deleteLevel,
    levelsList,
  ]);

  return (
    <>
      <IonContent className='ion-padding'>
        <IonRadioGroup>
          <IonList lines='none'>
            {levelsList && levelsList.map((item, levelIndex) =>
              <div key={item.levelId} className='fos-border levels-container'>
                <FosCardHeader
                  label={item.levelName}
                  modalTitle={t('renameLevel')}
                  onLevelDelete={() => handleLevelDelete(levelIndex, item.levelId)}
                  onChangeLevelName={(levelName: string) => handleChangeLevelName(levelName, levelIndex)}
                />
                {item.rooms.map((room, roomIndex) =>
                  <div key={`${room.roomId}-${roomIndex}`}>
                    <FosRadioButton
                      label={room.roomName}
                      modalTitle={t('renameRoom')}
                      value={room.roomId?.toString() ?? ''}
                      onChange={() => onRoomSelect(room)}
                      onChangeRoomName={(roomName: string) => handleChangeRoomName(roomName, item.rooms.indexOf(room), levelIndex)}
                      onRoomDelete={() => handleRoomDelete(item.rooms.indexOf(room), levelIndex, room.roomId)}
                    />
                    <div className='divider' />
                  </div>)
                }
                <IonButton className='levels-room-button' onClick={() => handleAddRoom(levelIndex, getRoomName(`${item.rooms.length + 1}`))} expand='block'>
                  {t('addRoom').toString()}
                </IonButton>
              </div>)
            }
          </IonList>
          <IonButton expand='block' className='media-inspection-button' onClick={() => handleAddLevel()}>{t('addLevel').toString()}</IonButton>
        </IonRadioGroup>
        <IonLoading isOpen={isSaving} message={t('savingLevels')} duration={1} />
        <IonLoading isOpen={isRoomDeleting} message={t('deletingRoom')} duration={1} />
        <IonLoading isOpen={isDeletingLevel} message={t('deletingLevel')} duration={1} />
        <IonToast
          isOpen={!!saveError}
          message={saveError}
          buttons={[{ role: 'cancel', icon: closeOutline }]}
          onDidDismiss={() => { setSaveError(''); }}
        />
      </IonContent>
      <IonFooter className='levels-done-footer ion-padding'>
        {enableSkip && !selectedRoom ? <IonButton
          expand='block'
          fill="outline"
          onClick={() => onDone()}
        >
          {t('skip').toString()}
        </IonButton> :
          <IonButton
            disabled={levelsList.length === 0}
            expand='block'
            fill='solid'
            onClick={() => onSave()}
          >
            {t('save').toString()}
          </IonButton>
        }
      </IonFooter>
      <Toaster />
    </>
  );
};

export default AddLevels;
