import React from 'react';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
import { OTHER_VERTICAL } from '../pages/helper/Const';

export interface VerticalWrapperProps {
  value?: number,
  name: string,
  label: string,
  options: any,
  error?: string,
  setIsOtherVerticalSelected: any,
  onChange: (newValue: number) => void
}

export const VerticalWrapperComponent: React.FC<VerticalWrapperProps> =  ({ onChange, setIsOtherVerticalSelected, ...otherProps }) => {
  const handleVerticalChange = (value: number) => {
    onChange(value);
    if (value === OTHER_VERTICAL) {
      setIsOtherVerticalSelected(true);
    } else {
      setIsOtherVerticalSelected(false);
    }
  };

  return (
    <FosSelectBigListItem
      id="addClaimVertical"
      onChange={handleVerticalChange}
      {...otherProps}
    />
  );
};