import { gql } from '@apollo/client';

const GET_TAX_TYPES = gql`
query taxTypes {
    taxTypes
  {
    id
    includeInNet
    name
    parentTaxId
    province
    rate
  }
}`;

export default GET_TAX_TYPES;