import React, { Suspense, useContext, useEffect } from 'react';
import {
  IonApp,
  IonHeader,
  IonProgressBar,
  IonTitle,
  IonToolbar,
  setupIonicReact,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import Routes from './pages/Routes';

import NetworkContext from './contexts/NetworkContext';
import { ClaimOfflineProvider } from './contexts/ClaimOfflineContext';
import { useAuth } from './hooks/authContext';
import { usePhotoUpload } from './contexts/PhotoUploadContext';
import { usePhotoDownload } from './contexts/PhotoDownloadContext';

setupIonicReact();
const queryClient = new QueryClient();
const App: React.FC = () => {
  const { userState, refreshToken } = useAuth()!;
  const { t } = useTranslation();
  const networkStatus = useContext(NetworkContext);
  const photoUploadContext = usePhotoUpload()!;
  const photoDownloadContext = usePhotoDownload();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userState.userAuthPayload?.accessToken) {
        refreshToken();
      }
    }, 15 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [refreshToken, userState.userAuthPayload?.accessToken]);

  return (
    <ClaimOfflineProvider>
      <IonApp>
        {!networkStatus.connected && <IonHeader className='status-bar'>{t('offlineMode').toString()}</IonHeader>}
        {networkStatus.connected &&
          <IonHeader className='upload-bar'>
            {photoUploadContext.totalCount > 0 && <IonToolbar color="success">
              <IonTitle color="dark">
                {`${t('fileUpload').toString()} ${photoUploadContext.syncCount} ${t('of').toString()} ${photoUploadContext.totalCount}`}
              </IonTitle>
              <IonProgressBar type="indeterminate" />
            </IonToolbar>}
            {photoDownloadContext.totalCount > 0 && <IonToolbar color="success">
              <IonTitle color="dark">
                {`${t('fileDownload').toString()} ${photoDownloadContext.syncCount} ${t('of').toString()} ${photoDownloadContext.totalCount}`}
              </IonTitle>
              <IonProgressBar type="indeterminate" />
            </IonToolbar>}
          </IonHeader>
        }
        <QueryClientProvider client={queryClient}>
          <Suspense fallback="...is loading">
            <Routes />
          </Suspense>
        </QueryClientProvider>
      </IonApp>
    </ClaimOfflineProvider>
  );
};

export default App;
