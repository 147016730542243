import { UserExpenseSummaryType } from '../../../graphql/GetUserExpenseSummary';
import SaveStorage, { SavedRecords } from '../../../utils/SaveStorage';

export const storeUserExpenseSummary = async (info: UserExpenseSummaryType[] | null) => {
  const saveStorage = SaveStorage();
  await saveStorage.setItem(SavedRecords.ExpenseSummary, info, 1);
};

export const getUserExpenseSummary = async () => {
  const saveStorage = SaveStorage();
  const list = await saveStorage.getItems<any>(SavedRecords.ExpenseSummary);
  return list[0] || null;
};

