import { airMover } from './air-mover';
import { dehumidifier } from './dehumidifier';
import { door } from './door';
import { saturation } from './saturation';
import { window90cm } from './window-90';

export const lib = [
  door,
  window90cm,
  dehumidifier,
  airMover,
  saturation,
];

lib.forEach((obj, idx) => {
  obj.id = idx + 1;
});

export default lib;
export { type LibItem } from './common';