import React from 'react';
import { IonItem, IonLabel, IonText, IonToggle } from '@ionic/react';
import './FosToggle.css';

export interface Props {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (event: any) => any;
}

const FosToggle = React.forwardRef<HTMLIonToggleElement, Props>(({ label, checked, disabled, onChange }, ref) => (
  <IonItem
    lines="none"
    disabled={disabled}
    className="fos-border ion-margin-bottom">
    {label &&
      <IonLabel>
        <IonText className='fos-toggle-label'>{label}</IonText>
      </IonLabel>}
    <IonToggle
      ref={ref}
      checked={checked}
      slot="end"
      onIonChange={(event) => onChange(event.detail.checked)} />
  </IonItem>
));

export default FosToggle;
