import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseQuery from './baseQuery';
import GET_CLAIM_DOCUMENT_TYPES_LIST, { ClaimDocumentTypesType } from '../graphql/GetClaimDocumentTypesList';

const useClaimDocumentTypes = (region: number, claimIndx: number, userState: UserState) => {
  const enabled = !!claimIndx;
  const { data, isLoading, isFetching } = useBaseQuery(
    region,
    { claimIndx },
    userState,
    enabled,
    GET_CLAIM_DOCUMENT_TYPES_LIST,
    claimsKeys.claimDocumentTypes(),
  );
  const claimDocumentTypesList: ClaimDocumentTypesType[] =
    !isLoading && !isFetching ? data?.documentTypes || [] : [];
  return { claimDocumentTypesList, isLoading };
};

export default useClaimDocumentTypes;