/* eslint-disable react/no-array-index-key */
import { IonButton, IonIcon, IonList, IonText } from '@ionic/react';
import React from 'react';
import './FosCard.css';
import { locationOutline } from 'ionicons/icons';
import { ContactsInfoType } from '../pages/helper/ContactsInfoType';
import FosCachedImage from '../components/FosCachedImage';

export interface Props {
  status?: string;
  date?: string;
  title?: string;
  subtitle?: string;
  location?: string;
  contacts?: ContactsInfoType[];
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  leftButtonIcon?: string;
  rightButtonIcon?: string;
  showButtons?: boolean;
  hasMinHeight?: boolean;
  onLeftButtonClick?: (event: any) => any;
  onRightButtonClick?: (event: any) => any;
  onClick?: (event: any) => any;
}

export enum FosCardStatus {
  Available = 'Available',
  InTransit = 'In Transit',
}

const FosCard: React.FC<Props> = (({
  status,
  date,
  title,
  subtitle,
  location,
  contacts,
  leftButtonLabel,
  rightButtonLabel,
  leftButtonIcon,
  rightButtonIcon,
  showButtons = true,
  hasMinHeight = true,
  onLeftButtonClick,
  onRightButtonClick,
  onClick,
}) => {

  const noButtonsLayout = showButtons ? '' : 'fos-card-container-no-buttons';
  const hasMinHeightLayout = hasMinHeight ? 'fos-card-min-height' : '';

  const handleStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case FosCardStatus.Available: return 'fos-card-status-green-color';
      case FosCardStatus.InTransit: return 'fos-card-status-orange-color';
      default: return 'fos-card-status-default-color';
    }
  };

  return (
    <div className='ion-padding-bottom fos-card'>
      <IonList lines='none' className={`fos-card-container ion-no-padding ${noButtonsLayout} ${hasMinHeightLayout}`} onClick={onClick}>
        <div className='fos-card-inline-status-content'>
          <span>
            <IonText className={`fos-card-status fos-card-status-green-color ${handleStatus(status ?? '')}`}>
              {status}
            </IonText>
          </span>
          <span>
            <IonText className='fos-card-date'>
              {date}
            </IonText>
          </span>
        </div >
        <div>
          <IonText className='fos-card-title'>
            {title}
          </IonText>
        </div>
        <div>
          <IonText className='fos-card-subtitle'>
            {subtitle}
          </IonText>
        </div>
        {location &&
          <div className='fos-card-location-items'>
            <div className="location-pin-wrapper">
              <IonIcon icon={locationOutline} className="location-pin-icon" />
            </div>
            <IonText className='fos-card-location'>{location}</IonText>
          </div>}
        <IonList lines='none'>
          {contacts && contacts.map((contact, index) => (
            <div key={index} className='fos-card-row'>
              <div className='fos-card-column'>
                <span className='fos-card-contact-label'>
                  <IonText>{contact.label}</IonText>
                </span>
              </div>
              <div className='fos-card-column'>
                <span className='fos-card-contact-label'>
                  <IonText>{contact.value}</IonText>
                </span>
              </div>
            </div>
          ))}
        </IonList>
      </IonList>
      {showButtons &&
        <div className='fos-card-inline-buttons-content'>
          <IonButton className='fos-card-button-label' fill='clear' onClick={onLeftButtonClick}>
            <FosCachedImage src={leftButtonIcon} />
            {leftButtonLabel}
          </IonButton>
          <div className="fos-card-button-divider" />
          <IonButton className='fos-card-button-label' fill='clear' onClick={onRightButtonClick}>
            <FosCachedImage src={rightButtonIcon} />
            {rightButtonLabel}
          </IonButton>
        </div>
      }
    </div>
  );
});

export default FosCard;
