import { BadgeInfo } from '../../../graphql/GetBadgeInfo';
import SaveStorage, { SavedRecords } from '../../../utils/SaveStorage';

export const storeBadgeInfo = async (info: BadgeInfo | null) => {
  const saveStorage = SaveStorage();
  await saveStorage.setItem(SavedRecords.BadgeInfo, info, 1);
};

export const getBadgeInfo = async () => {
  const saveStorage = SaveStorage();
  const list = await saveStorage.getItems<BadgeInfo>(SavedRecords.BadgeInfo);
  return list[0] || null;
};

