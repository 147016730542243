import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { 
  IonButtons,
  IonHeader,
  IonIcon, 
  IonTitle, 
  IonToolbar, 
  IonButton,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import './FosToolbar.css';

type Props = {
  title?: string;
  additionalBackClick?: () => void;
};

const FosScanToolbar: FC<Props> = ({ title, additionalBackClick }) => {
  const history = useHistory();

  const handleBack = () => {
    if (additionalBackClick) {
      additionalBackClick();
    }
    history.goBack();
  };

  return (
    <IonHeader>
      <IonToolbar className='fos-toolbar'>
        <IonButtons slot="start">
          <IonButton onClick={handleBack}>
            <IonIcon icon={arrowBack} />
          </IonButton>
        </IonButtons>
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default FosScanToolbar;
