import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonNote,
  IonText,
  IonToast,
} from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeOutline } from 'ionicons/icons';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMaskito } from '@maskito/react';
import moment from 'moment';
import { useAuth } from '../hooks/authContext';
import FosSelectItem from '../atom/FosSelectItem';
import { Option } from '../utils/Interfaces';

import './ClaimNotesForm.css';
import './ClaimNotificationForm.css';
import useClaimDetails from '../hooks/useClaimDetails';

import { emailRegexNoGlobal, phoneRegex } from '../pages/helper/FormsHelper';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import AutocompleteSearch from '../molecule/AutocompleteSearch';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import GET_EMAIL_SUGGESTIONS, { EmailSuggestion } from '../graphql/GetEmailSuggestions';
import { CustomEmailContact } from './OtherEmailsModal';
import FormItemDivider from '../atom/FormItemDivider';
import FosFormatTextArea from '../atom/FosFormatTextArea';
import toIsoLocalTime from '../utils/IsoLocalTime';
import i18n from '../i18n';

type Props = {
  userName: string;
  claimIndx?: string;
  notification?: any;
  onSubmit: (success: boolean) => void;
  defaultPhone?: string;
};

export enum MessageTemplate {
  ArrivalConfirmation = 'ArrivalConfirmation',
  OnTheWay = 'OnTheWay',
  FreeText = 'FreeText',
}

export enum MessageChannel {
  Email = 'Email',
  SMS = 'SMS',
}

type FormData = {
  claimIndx: number;
  type: string;
  template: string;
  phone: string;
  who: string;
  when: string;
  whenDate: string;
  message: string;
  recipients: string[];
  ccRecipients: string[];
  messageTemplate: MessageTemplate;
  messageChannel: MessageChannel;
};

const ClaimNotificationForm: React.FC<Props> = ({ userName, claimIndx, notification, onSubmit, defaultPhone }) => {
  const { t } = useTranslation();
  const { userState } = useAuth()!;
  const region = userState.userAuthPayload?.regionId!;

  const [saveError, setSaveError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [claim, setClaim] = useState<any>();
  const [whenDate, setWhenDate] = useState<any>(null);
  const now = new Date();
  const maxDate = toIsoLocalTime(new Date(now.setFullYear(now.getFullYear() + 1)));
  const graphQLClient = getGraphQLClient(userState);
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const [ccSuggestions, setCcSuggestions] = useState<Array<any>>([]);
  const [selectedEmails, setSelectedEmails] = useState<Array<CustomEmailContact>>([]);
  const [ccRecipients, setCcRecipients] = useState<Array<CustomEmailContact>>([]);
  const [isInvalidCcEmailAlertOpen, setInvalidCcEmailAlert] = useState(false);
  const tempValue = 'temp';

  const phoneMask = useMaskito({
    options: {
      mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    },
  });

  const mapToCustomEmailContact = (email: string): CustomEmailContact => ({
    email,
    id: tempValue,
    selected: true,
    fullName: email,
  });

  const onClaimDetailsSuccess = async (_data: any) => {
    if (_data) {
      // use data
      setClaim(_data.claim);
      if (notification?.ccAddresses) {
        const mappedCcRecipients = notification.ccAddresses.map(mapToCustomEmailContact);
        setCcRecipients(mappedCcRecipients);
      }    
    }
  };

  const { isFetching } = useClaimDetails(region, !claimIndx ? 0 : Number(claimIndx), userState, onClaimDetailsSuccess);
  const typeOptions: Option[] = [
    { label: t('Email'), value: MessageChannel.Email },
    { label: t('SMS'), value: MessageChannel.SMS },
  ];
  const templateOptions: Option[] = [
    { label: t('arrivalConfirmation'), value: MessageTemplate.ArrivalConfirmation },
    { label: t('onTheWay'), value: MessageTemplate.OnTheWay },
    { label: t('freeText'), value: MessageTemplate.FreeText },
  ];

  const whenOptions: Option[] = [
    {
      label: '5 minutes',
      value: '5 minutes',
    },
    {
      label: '10 minutes',
      value: '10 minutes',
    },
    {
      label: '15 minutes',
      value: '15 minutes',
    },
    {
      label: '30 minutes',
      value: '30 minutes',
    },
    {
      label: '45 minutes',
      value: '45 minutes',
    },
    {
      label: '1 hour',
      value: '1 hour',
    },
    {
      label: '2 hours',
      value: '2 hours',
    },
  ];
  const schema = yup
    .object({
      type: yup.string().required(),
      template: yup.string().required(),
      who: yup.string().test(
        'who-set',
        () => t('WhoRequired'),
        () => selectedEmails?.length > 0,
      ),
      when: yup.string().test(
        'when-required',
        () => t('WhenRequired'),
        (value, ctx) => {
          const templateFormValue = ctx.parent.template;
          return !(
            (templateFormValue === MessageTemplate.OnTheWay && !value) ||
            (templateFormValue === MessageTemplate.ArrivalConfirmation && !whenDate)
          );
        },
      ),
      message: yup.string(),
      phone: yup.string().when({
        is: (val: string) => val?.length > 0,
        then: yup.string().matches(phoneRegex, { message: t('invalidPhoneNumber') }),
      }),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: MessageChannel.Email,
      template: MessageTemplate.ArrivalConfirmation,
    },
  });

  const { template, when, type, phone, message } = watch();
  const isOnTheWaySelected = template === MessageTemplate.OnTheWay;
  const isFreeTextSelected = template === MessageTemplate.FreeText;
  const isArrivalConfirmationSelected = template === MessageTemplate.ArrivalConfirmation;
  const emailInputRef = useRef('');
  const ccEmailInputRef = useRef('');

  /*
   * Submit form
   * */
  const onSubmitHandler = handleSubmit(async () => {
    if (template === MessageTemplate.ArrivalConfirmation && new Date(whenDate) < new Date()) {
      setSaveError(t('InvalidWhen'));
      return;
    }
    try {
      setIsLoading(true);
      const communication: any = {};
      const localWhen =
        template === (MessageTemplate.OnTheWay || MessageTemplate.FreeText)
          ? when
          : toIsoLocalTime(new Date(when || whenDate));
      const whoProp = selectedEmails?.length > 0 ? selectedEmails[0].email : userState.userAuthPayload?.preferredUsername;
      const whoPropEmployeeName = selectedEmails?.length > 0 ? selectedEmails[0].fullName : userName;
      let mappedCcEmails: string[] = [];
      ccRecipients.forEach((i) => {
        mappedCcEmails = [...mappedCcEmails, i.email];
      });
      communication.templateData = [
        {
          key: 'claimIndx',
          value: claimIndx,
        },
        {
          key: 'claimNumber',
          value: claim.claimNumber,
        },
        {
          key: 'userName',
          value: whoProp,
        },
        {
          key: 'eta',
          value: localWhen,
        },
        {
          key: 'region',
          value: region?.toString(),
        },
      ];
      if (template === MessageTemplate.OnTheWay) {
        communication.templateData.push({
          key: 'employeeName',
          value: whoPropEmployeeName,
        });
      }
      communication.messageTemplate = template;
      communication.messageChannel = type;
      communication.message = message;
      const recipient = type === MessageChannel.SMS ? phone : claim?.projEmail;
      if (type === MessageChannel.Email && !emailRegexNoGlobal.test(recipient)) {setSaveError(t('invalidNotificationEmailMessage')); setIsLoading(false); return; }
      communication.recipients = [recipient];
      communication.isFrench = i18n.language === 'fr';
      communication.ccRecipients = mappedCcEmails;
      const restClient = getRestClient(userState, ClientType.FORM);
      const resp = await restClient.post('/common/communication/send-communication', communication);
      if (resp.status === 200) {
        onSubmit(true);
      } else {
        const message = t('saveClaimNotificationError');
        setSaveError(message);
      }
    } catch (e: any) {
      let msg: string;
      if (e?.response?.data?.ErrorCode === 400) {
        msg = t('errorXSS');
      } else {
        msg = e?.response?.data?.Description;
      }
      const message = msg || t('saveClaimNotificationError');
      setSaveError(message);
    }
    setIsLoading(false);
  });

  const getEmailSuggestions = useCallback(
    (emailInput: string, isCc?: boolean) => {
      if (isCc) {
        if (emailInput.length > 0) {
          graphQLClient.request(GET_EMAIL_SUGGESTIONS, { term: emailInput }).then((res) => {
            const emailSuggestions = res?.userEmailsByTerm?.map((emailSuggestion: EmailSuggestion) => ({
              id: emailSuggestion.email,
              description: emailSuggestion.email,
              fullName: emailSuggestion.fullName,
            }));
            setCcSuggestions(emailSuggestions);
          });
        } else {
          setCcSuggestions([]);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (emailInput.length > 0) {
          graphQLClient.request(GET_EMAIL_SUGGESTIONS, { term: emailInput }).then((res) => {
            const emailSuggestions = res?.userEmailsByTerm?.map((emailSuggestion: EmailSuggestion) => ({
              id: emailSuggestion.email,
              description: emailSuggestion.email,
              fullName: emailSuggestion.fullName,
            }));
            setSuggestions(emailSuggestions);
          });
        } else {
          setSuggestions([]);
        }
      }
    },
    [graphQLClient],
  );
  const onAddEmailClick = () => {
    // @ts-ignore
    const email = ccEmailInputRef.current.value;
    const testEmail = emailRegexNoGlobal.test(email !== undefined ? email : '');

    setInvalidCcEmailAlert(!testEmail);


    if (!testEmail) {
      return;
    }

    if (email && testEmail) {
      setCcRecipients([...ccRecipients, { email, id: tempValue, selected: true }]);

      setSuggestions([]);
      setCcSuggestions([]);
      if (emailInputRef?.current) {
        // @ts-ignore
        emailInputRef.current.value = '';
      }
      if (ccEmailInputRef?.current) {
        // @ts-ignore
        ccEmailInputRef.current.value = '';
      }
    }
  };
  const onOptionSelect = (option?: any, isCc?: boolean) => {
    if (isCc) {
      if (option) {
        setCcRecipients([
          ...ccRecipients,
          { email: option.description, id: tempValue, selected: true, fullName: option.fullName },
        ]);
      } else {
        // @ts-ignore
        setCcRecipients([
          ...ccRecipients,
          // @ts-ignore
          { email: ccEmailInputRef.current.value, id: tempValue, selected: true, fullName: tempValue },
        ]);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (option) {
        setSelectedEmails([{ email: option.description, id: tempValue, selected: true, fullName: option.fullName }]);
      } else {
        // @ts-ignore
        setSelectedEmails([{ email: emailInputRef.current.value, id: tempValue, selected: true, fullName: option.fullName }]);
      }
    }
    setSuggestions([]);
    setCcSuggestions([]);
    if (emailInputRef?.current) {
      // @ts-ignore
      emailInputRef.current.value = '';
    }
    if (ccEmailInputRef?.current) {
      // @ts-ignore
      ccEmailInputRef.current.value = '';
    }
  };

  const onCustomContactChange = useCallback(
    (event: any, contact: CustomEmailContact) => {
      if (event.detail.checked) {
        const filteredSelectedEmails = selectedEmails.filter((selected) => selected.email !== contact.email);
        const newSelectedEmails = [...filteredSelectedEmails, { ...contact, selected: true }];

        setSelectedEmails(newSelectedEmails);
      } else if (contact.id === tempValue) {
        const filteredSelectedEmails = selectedEmails.filter((selected) => selected.email !== contact.email);
        const newSelectedEmails = [...filteredSelectedEmails, { ...contact, selected: false }];

        setSelectedEmails(newSelectedEmails);
      } else {
        const updatedSelectedEmails = selectedEmails.filter((selected) => selected.email !== contact.email);

        setSelectedEmails(updatedSelectedEmails);
      }
    },
    [selectedEmails],
  );

  // TODO: ADD "DO YOU WANT TO DELETE" MODAL
  const removeCcRecipient = (email: string) => {
    setCcRecipients(ccRecipients.filter((recipient) => recipient.email !== email));
  };

  useEffect(() => {
    register('recipients', { value: selectedEmails?.map((r) => r.email) });
    register('ccRecipients', { value: ccRecipients?.map((r) => r.email) });
  }, [selectedEmails, ccRecipients, register]);

  const isEdit = notification && Object.keys(notification).length > 0;
  const enableSave = !isEdit && (type === MessageChannel.SMS ? !!phone : !!claim?.projEmail);

  useEffect(() => {
    if (isEdit) {
      const messageTemplate = notification?.metadata?.find(
        (n: { key: string; value: string }) => n.key === 'messageTemplate',
      );
      if (messageTemplate?.value === MessageTemplate.OnTheWay) {
        setValue('template', MessageTemplate.OnTheWay);
      } else if (messageTemplate?.value === MessageTemplate.FreeText) {
        setValue('template', MessageTemplate.FreeText);
      } else {
        setValue('template', MessageTemplate.ArrivalConfirmation);
      }

      const eta = notification?.metadata?.find((n: { key: string; value: string }) => n.key === 'eta');
      if (eta?.value) {
        if (messageTemplate?.value === MessageTemplate.OnTheWay) {
          setValue('when', eta?.value);
          setWhenDate(eta?.value);
        } else {
          setValue('when', toIsoLocalTime(eta?.value));
          setWhenDate(toIsoLocalTime(new Date(eta?.value)));
        }
      }
      setValue('message', notification.body);
      const type = notification?.messageType === 'MailMessage' ? MessageChannel.Email : MessageChannel.SMS;
      setValue('type', type);
      if (type === MessageChannel.SMS) {
        setValue('phone', notification.recipients[0]);
      }

      const who = notification?.metadata?.find((n: { key: string; value: string }) => n.key === 'userName');
      if (who?.value) {
        setSelectedEmails([{ email: who.value, id: tempValue, selected: true }]);
      }
    }
  }, [isEdit, notification, template, setValue]);

  return (
    <>
      <IonContent className="ion-padding">
        <form id="addNotesForm" onSubmit={onSubmitHandler}>
          <FosSelectItem
            disabled={isEdit}
            label={t('type')}
            options={typeOptions}
            {...register('type')}
            error={errors.type?.message}
          />
          {type === MessageChannel.SMS && (
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <IonItem
                  lines="none"
                  className={`fos-stacked-input-item ion-margin-bottom ${error ? 'ion-invalid' : ''}`.trim()}
                >
                  <IonInput
                    type="tel"
                    readonly={isEdit}
                    ref={async (phoneInput) => {
                      if (phoneInput) {
                        const input = await phoneInput.getInputElement();
                        phoneMask(input);
                      }
                    }}
                    className="fos-stacked-input"
                    placeholder={defaultPhone}
                    defaultValue={defaultPhone}
                    value={value}
                    onChange={onChange}
                    onIonChange={onChange}
                  />
                  {error?.message && <IonNote slot="error">{error.message}</IonNote>}
                </IonItem>
              )}
            />
          )}
          <FosSelectItem
            label={t('Template')}
            disabled={isEdit}
            options={templateOptions}
            {...register('template')}
            error={errors.template?.message}
          />

              {!isEdit && (
                <AutocompleteSearch
                  disabled={isEdit}
                  ref={emailInputRef}
                  placeholder={`${t('whoFosStaff').toString()} *`}
                  onChange={(evt: any) => {
                    getEmailSuggestions(evt.target.value, false);
                    if (errors.who) {
                      errors.who.message = '';
                    }
                  }}
                  options={suggestions}
                  onOptionSelect={(option) => onOptionSelect(option)}
                  debounce={1000}
                  error={errors.who?.message}
                  marginBottom
                />
              )}
              {selectedEmails.length > 0 && (
              <div className="selected-section recents-section recents-label">
                <IonLabel>{t('selectedEmployee').toString()}</IonLabel>
                <FormItemDivider />
                <IonList>
                  {selectedEmails.map((contact, index) => (
                    <div key={`${contact.email + index}`}>
                      <IonItem lines="none" className="ion-no-padding">
                        <IonCheckbox
                          disabled
                          className="form-item-checkbox"
                          slot="start"
                          color="primary"
                          checked={contact.selected!}
                          onIonChange={(e) => onCustomContactChange(e, contact)}
                        />
                        <IonLabel>
                          <IonText className="recents-label">{contact.fullName}</IonText> -{' '}
                          <IonText className="fos-share-with-contact-value">{contact.email}</IonText>
                        </IonLabel>
                      </IonItem>
                      <FormItemDivider />
                    </div>
                  ))}
                </IonList>
              </div>
              )}
          {isArrivalConfirmationSelected && (
            <IonItem
              disabled={isEdit}
              id="date-time-trigger"
              lines="none"
              className="fos-select-date-item ion-margin-top"
            >
              <div className="fos-select-date-border" />
              <IonLabel id="open-date-input" className="ion-text-wrap">
                <IonText className="fos-select-date-label">{t('when').toString()} *</IonText>
              </IonLabel>
              <IonText className="fos-select-date-label">
                {moment(whenDate).isValid() ? moment(whenDate).format('dddd, MMMM Do YYYY, h:mm a') : ''}
              </IonText>
              <IonModal trigger="date-time-trigger" className="center fixed-300-width">
                <IonDatetime
                  showDefaultButtons
                  presentation="date-time"
                  id="idWhenDate"
                  name="idWhenDate"
                  size="cover"
                  value={whenDate}
                  onIonChange={(v) => {
                    setWhenDate(v.target.value);
                    if (errors.when) {
                      errors.when.message = '';
                    }
                  }}
                  max={maxDate}
                />
              </IonModal>
            </IonItem>
          )}
          {isOnTheWaySelected && (
            <FosSelectItem
              disabled={isEdit}
              removeMarginBottom
              label={`${t('when')} *`}
              marginTop
              onIonChange={() => {
                if (errors.when) {
                  errors.when.message = '';
                }
              }}
              options={whenOptions}
              {...register('when')}
              interfaceOptions={{ header: t('when') }}
            />
          )}
          {isFreeTextSelected && <div />}
          {errors.when?.message && (
            <IonNote className="note-error" slot="end">
              {errors.when?.message}
            </IonNote>
          )}
          <div className="ion-margin-top" />
          <FosFormatTextArea
            label={t('details').toString()}
            hideToolBar={type === MessageChannel.SMS || isEdit}
            control={control}
            readonly={isEdit}
            name="message"
          />
          <IonToast
            isOpen={!!saveError}
            duration={3000}
            message={saveError}
            buttons={[{ role: 'cancel', icon: closeOutline }]}
            onDidDismiss={() => {
              setSaveError('');
            }}
          />
          {type === MessageChannel.Email && (
            <div className="sent-emails">
              {(claim?.projEmail || isEdit) && (
                <>
                  <IonLabel>
                    <IonText className="fos-share-with-contact-title">{t('messageTo').toString()}:</IonText>
                  </IonLabel>
                  <IonList>
                    <IonItem lines="none" className="ion-no-padding">
                      <IonLabel>
                        {notification?.recipients && notification.recipients[0] ? (
                          <IonText className="fos-share-with-contact-value">{notification.recipients[0]}</IonText>
                        ) : (
                          <div>
                            <IonText>{claim?.contactName}</IonText>
                            {claim?.contactName && ' - '}
                            <IonText className="fos-share-with-contact-value">{claim?.projEmail}</IonText>
                          </div>
                        )}
                      </IonLabel>
                    </IonItem>
                  </IonList>
                </>
              )}
              {type === MessageChannel.Email && (
            <>
                {!isEdit && (
                  <AutocompleteSearch
                    disabled={isEdit}
                    ref={ccEmailInputRef}
                    placeholder={`${t('CcEmails').toString()}`}
                    onChange={(evt: any) => {
                      getEmailSuggestions(evt.target.value, true);
                    }}
                    options={ccSuggestions}
                    onOptionSelect={(option) => onOptionSelect(option, true)}
                    debounce={1000}
                    addButton
                    onAddClick={() => onAddEmailClick()}
                    disableEnter
                  />
                )}
                {isInvalidCcEmailAlertOpen && (
                  <IonText className="invalid-entry-message">{t('pleaseEnterAValidEmailAddress').toString()}</IonText>
                )}
                {ccRecipients.length > 0 && (
                <div className="selected-section recents-section recents-label">
                  <IonLabel>{t('selectedCcEmails').toString()}</IonLabel>
                  <FormItemDivider />
                  <IonList>
                    {ccRecipients.map((contact, index) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                      <div key={`${contact.email + index}`} onClick={() => removeCcRecipient(contact.email)}>
                        <IonItem lines="none" className="ion-no-padding">
                          <IonCheckbox
                            disabled
                            className="form-item-checkbox"
                            slot="start"
                            color="primary"
                            checked={contact.selected!}
                            onIonChange={(e) => onCustomContactChange(e, contact)}
                          />
                          <IonLabel>
                            <IonText className="fos-share-with-contact-value">{contact.email}</IonText>
                          </IonLabel>
                        </IonItem>
                        <FormItemDivider />
                      </div>
                    ))}
                  </IonList>
                </div>
                )}
              </>
              )}
              {!claim?.projEmail && !isEdit && (
                <IonItem lines="none">
                  <IonLabel>{t('missingContactInfoAtClaimDetails').toString()}</IonLabel>
                </IonItem>
              )}
            </div>
          )}
        </form>
        <IonLoading isOpen={isFetching} message={t('loading')} duration={2000} />
      </IonContent>
      <IonFooter className="ion-padding claim-notes-form-footer">
        <IonButton disabled={!enableSave} expand="block" fill="solid" onClick={onSubmitHandler}>
          {t('send').toString()}
        </IonButton>
      </IonFooter>
      <IonLoading isOpen={isLoading} message={t('saving')} duration={20000} />
    </>
  );
};

export default ClaimNotificationForm;
