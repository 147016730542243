import { gql } from '@apollo/client';

export type UserSingleExpenseStatus = {
  id: number,
  name: string,
};

export type UserSingleExpense = {
  id: number,
  comments: string,
  merchant: string,
  totalAmount: number,
  isParent: boolean,
  status: UserSingleExpenseStatus,
};

export type ChildExpenseList = {
  expense: UserSingleExpense;
};

export type UserExpenseObject = {
  expense: UserSingleExpense,
  childExpenses: ChildExpenseList[],
};

const GET_USER_EXPENSES_LIST = gql`
query personalExpenses ($userGuid: UUID!, $expenseMethodId: Int!, $statusId: Int) {
  personalExpenses (employeeGuid: $userGuid, expenseMethodId: $expenseMethodId, statusId: $statusId) {
    expense {
      id
      comments
      merchant
      totalAmount
      isParent
      status {
        name
        id
      }     
    }
    childExpenses {
      expense {
          id
          comments
          merchant
          isParent
          totalAmount
          status {
              name
              id
          }
      }
    }
  }
}`;

export default GET_USER_EXPENSES_LIST;