import toast from 'react-hot-toast';
import { t } from 'i18next';
import SaveStorage, { SaveStorageReturn, SavedRecords, StorageRecord } from '../../../utils/SaveStorage';
import { ApiStatus, ClaimOnlineStatus } from '../Const';
import { deepClone } from '../../../utils/Utilities';
import { getErrorMsg } from '../AppHelper';

type StoreClaimLevels = {
  levels: any[];
  claimIndx: number;
  status: ClaimOnlineStatus;
};

// used to save new claim or save the edit of existent claim
const saveClaimLevels = async ({
  levels,
  claimIndx,
  status,
} : StoreClaimLevels) => {
  const saveStorage = SaveStorage();
  await saveStorage.setItem(SavedRecords.ClaimsLevels, { levels, status }, claimIndx);
};

export const storeClaimLevels = async ({
  levels,
  claimIndx,
  status,
} : StoreClaimLevels) => {
  if (!levels) {
    return;
  }
  await saveClaimLevels({ levels, claimIndx, status });
};

export const getAllClaimLevels = async () => {
  const saveStorage = SaveStorage();
  const claimLevelsList = await saveStorage.getItems(SavedRecords.ClaimsLevels);
  return claimLevelsList;
};

export const getClaimLevels = async (
  claimIndx: string,
) => {
  const claimLevelsList = await getAllClaimLevels();
  const claimLevels = claimLevelsList.find(cSI => cSI.primaryKey === Number.parseInt(claimIndx, 10));
  if (!claimLevels?.data?.levels) {
    return { levels: undefined, status: undefined };
  }
  const levels = claimLevels?.data?.levels;
  const status = claimLevels?.data?.status;
  return { levels, status };
};

export const syncClaimLevels = async (
  claimLevels: StorageRecord,
  saveStorage: SaveStorageReturn,
  region: number,
  mutateSaveClaimLevels: any,
) => {
  try {
    const cloneClaimLevels = deepClone(claimLevels);
    const res = await mutateSaveClaimLevels({ region, jobIdx: Number(cloneClaimLevels.primaryKey), levels: cloneClaimLevels.data.levels });
    
    if (res?.saveLevels?.status === ApiStatus.SUCCESS) {
      claimLevels.data.status = ClaimOnlineStatus.AvailableOffline;
      await saveStorage.setItem(SavedRecords.ClaimsLevels, claimLevels.data, claimLevels.primaryKey);
    }
  } catch (e: any) {
    const msg = getErrorMsg(e);
    toast.error(msg || t('saveClaimError'), { duration: 4000 });
  }
};

export const getAndSyncClaimLevels = async (
  saveStorage: SaveStorageReturn,
  region: number,
  mutateSaveClaimLevels: any,
) => {
  const claimLevelsList = await getAllClaimLevels();
  const filteredLevelsList = claimLevelsList?.filter(cll => cll.data.status === ClaimOnlineStatus.ToBeSynched);
  if (filteredLevelsList?.length > 0) {
    filteredLevelsList.forEach(async (claimLevels) => {
      await syncClaimLevels(claimLevels, saveStorage, region, mutateSaveClaimLevels);
    });
  }
};