import { useEffect, useState } from 'react';
import './ClaimPhotos.css';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { CameraSource } from '@capacitor/camera';

import { isPlatform, useIonLoading } from '@ionic/react';
import { useAuth } from '../hooks/authContext';
import useClaimPhotos from '../hooks/claimPhotos';
import { ClaimPhotosType } from '../graphql/GetClaimPhotosList';
import { LevelModelType, RoomModelType } from '../graphql/GetLevelsList';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import UPDATE_PHOTOS_ROOM from '../graphql/UpdatePhotosRoom';
import { Phase } from '../types/Phases';
import useClaimLevels from '../hooks/claimLevels';
import { getLevelListWithPhotos, PhotoLevels, PhotoRooms } from '../pages/helper/LevelRoomsPhotos';
import { cameraTakePicture, ClaimPhotoActiveOption, ClaimPhotoMenuTabs } from '../pages/helper/ClaimPhotosHelper';
import { usePhotoUpload } from '../contexts/PhotoUploadContext';
import { HYBRID } from '../hooks/photo';

type PropsClaimPhotos = {
  claimIndx: number;
  phases: Phase[];
  refetchPhotos: number;
};

interface MutationParams {
  roomId?: number;
  ctFileIds: number[];
}
const emptyBlob = { blobArray: [] };

const ClaimPhotosViewModel = ({ claimIndx, refetchPhotos }: PropsClaimPhotos) => {
  const { userState } = useAuth()!;
  const [photoSelectedOptions, setPhotoSelectedOptions] = useState<ClaimPhotoActiveOption>(ClaimPhotoMenuTabs.Rooms);
  const { t } = useTranslation();
  const [isLevelsModalOpen, setIsLevelsModalOpen] = useState(false);
  const [isSelectEnabled, setIsSelectEnabled] = useState<boolean>(false);
  const [selectedPhotos, setSelectedPhotos] = useState<ClaimPhotosType[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(true);
  const [levelsList, setLevelsList] = useState<PhotoLevels[]>([]);
  const [claimLevelsList, setClaimLevelsList] = useState<LevelModelType[]>([]);
  const [isAddPhotoToClaimFormOpen, setIsAddPhotoToClaimFormOpen] = useState<boolean>(false);
  const [isEditBulkPhotoToClaimFormOpen, setIsEditBulkPhotoToClaimFormOpen] = useState<boolean>(false);
  const [selectedPhotoEdit, setSelectedPhotoEdit] = useState<ClaimPhotosType | null>();
  const [selectedBulkPhotoEdit, setSelectedBulkPhotoEdit] = useState<ClaimPhotosType[] | null>(null);
  const [selectedRoom, setSelectedRoom] = useState<PhotoRooms>();
  const [selectedLevel, setSelectedLevel] = useState<PhotoLevels>();
  const [claimPhotosList, setClaimPhotosList] = useState<ClaimPhotosType[]>([]);
  const [photoUploadCount, setPhotoUploadCount] = useState<number>(0);
  const photoUploadcontext = usePhotoUpload()!;
  const [present, dismiss] = useIonLoading();

  const { mutate } = useMutation({
    mutationFn: (mutationParams: MutationParams) => getGraphQLClient(userState).request(UPDATE_PHOTOS_ROOM, mutationParams),
  });

  const region = userState.userAuthPayload?.regionId!;
  const { isFetching, isLoading, refetch: refetchPhotosAPI, refetchOffline } = useClaimPhotos(
    region,
    claimIndx,
    userState,
    claimPhotosList,
    setClaimPhotosList,
  );

  useEffect(() => {
    refetchPhotosAPI();
  }, [refetchPhotos, refetchPhotosAPI]);

  const { refetch: refetchLevels } = useClaimLevels(
    region,
    claimIndx!,
    userState,
    claimLevelsList,
    setClaimLevelsList,
  );

  useEffect(() => {    
    if (claimLevelsList?.length && claimPhotosList?.length) {
      const levelListLocal = getLevelListWithPhotos(claimPhotosList, claimLevelsList);

      setLevelsList(levelListLocal);
    }
  }, [claimLevelsList, claimPhotosList]);

  const showNoPhoto = !isLoading && !isFetching && claimPhotosList.length === 0;

  const openLevelsModal = () => {
    setIsLevelsModalOpen(true);
  };

  const selectRoom = (room: PhotoRooms, level: PhotoLevels):void => {
    setPhotoSelectedOptions(ClaimPhotoMenuTabs.RoomDetails);
    setSelectedRoom(room);
    setSelectedLevel(level);
  };

  const backToRooms = () => {
    setPhotoSelectedOptions(ClaimPhotoMenuTabs.Rooms);
    setSelectedRoom(undefined);
    setSelectedLevel(undefined);
  };

  const onRoomSelected = (room?: RoomModelType) => {
    setIsLevelsModalOpen(false);
    if (room) {
      // mutation to update selected photos
      const photoIds = selectedPhotos.map((claimPhotos: ClaimPhotosType) => claimPhotos.ctfileId);
      mutate({
        roomId: room.roomId,
        ctFileIds: photoIds,
      }, {
        onSuccess: () => {
          toast.success(t('imageAssociated'), { duration: 4000 });
          refetchPhotosAPI();
          setSelectedPhotoEdit(null);
          setSelectedPhotos([]);
          setIsSelectEnabled(false);
          setIsSelectAll(true);
          claimPhotosList.forEach(photo => {
            const photoChanged = photo;
            photoChanged.selected = false;
          });
        },
      });
    } else {
      claimPhotosList.forEach(photo => {
        const photoChanged = photo;
        if (selectedPhotos.filter(s => s.ctfileId === photoChanged.ctfileId).length > 0) {
          photoChanged.selected = true;
        } else {
          photoChanged.selected = false;
        }
      });
    }
  };

  useEffect(() => {
    // keep photos selection when open "move to" modal
    if (selectedPhotos.length > 0 && claimPhotosList.filter(cPhotos => cPhotos.selected).length === 0) {
      claimPhotosList.forEach(photo => {
        const photoChanged = photo;
        if (selectedPhotos.filter(s => s.ctfileId === photoChanged.ctfileId).length > 0) {
          photoChanged.selected = true;
        } else {
          photoChanged.selected = false;
        }
      });
    }
  }, [claimPhotosList, selectedPhotos]);

  const [claimPictures, setClaimPictures] = useState<{
    blobArray: File[];
  }>(emptyBlob);

  const addNewToGalleryHandler = async (source: CameraSource) => {
    if (isPlatform(HYBRID)) {
      present(t('loading'));
    } else {
      present(t('loading'), 3000);
    }
    try {
      const files = await cameraTakePicture(source);
      dismiss();
      if (files.blobArray.length > 0) {
        setClaimPictures(files);
        setIsAddPhotoToClaimFormOpen(true);
      }
    } catch {
      dismiss();  
    }
  };

  const onDismissAddPhotoToClaimForm = async () => {
    setIsAddPhotoToClaimFormOpen(false);
    setIsEditBulkPhotoToClaimFormOpen(false);
    setClaimPictures(emptyBlob);
    setSelectedPhotoEdit(null);
    setSelectedBulkPhotoEdit(null);
    await refetchLevels();
    claimPhotosList.forEach(photo => {
      const photoChanged = photo;
      if (selectedPhotos.filter(s => s.ctfileId === photoChanged.ctfileId).length > 0) {
        photoChanged.selected = true;
      } else {
        photoChanged.selected = false;
      }
    });
  };

  const onSubmitAddPhotoToClaimForm = (success: boolean, msg?: string) => {
    if (success) {
      toast.success(t('photoWillBeSaved'), { duration: 4000 });
    } else {
      toast.error(msg || t('failToSavePhoto'), { duration: 4000 });
    }
    setIsAddPhotoToClaimFormOpen(false);
    setIsEditBulkPhotoToClaimFormOpen(false);
    setClaimPictures(emptyBlob);
    setSelectedPhotoEdit(null);
    setSelectedPhotos([]);
    setSelectedBulkPhotoEdit(null);
    setIsSelectEnabled(false);
    refetchOffline();
    setIsSelectAll(true);
    claimPhotosList.forEach(photo => {
      const photoChanged = photo;
      photoChanged.selected = false;
    });
    // without timeout it refresh the api so quickly that didn't bring the new photo
    setTimeout(() => {
      refetchPhotosAPI();
      refetchLevels();
    }, 2000);
  };

  useEffect(() => {
    if (photoUploadCount !== photoUploadcontext.totalCount) {
      setPhotoUploadCount(photoUploadcontext.totalCount);
      if (photoUploadcontext.totalCount === 0) {
        setTimeout(() => {
          refetchPhotosAPI();
        }, 2000);
      }
    }
  }, [photoUploadCount, photoUploadcontext.totalCount, refetchPhotosAPI]);

  return {
    backToRooms,
    photoSelectedOptions,
    selectedLevel,
    selectedRoom,
    setPhotoSelectedOptions,
    isLoading,
    isFetching,
    showNoPhoto,
    addNewToGalleryHandler,
    claimPhotosList,
    isSelectAll,
    isSelectEnabled,
    selectedPhotos,
    openLevelsModal,
    setIsAddPhotoToClaimFormOpen,
    setIsSelectAll,
    setIsSelectEnabled,
    setSelectedPhotoEdit,
    setSelectedPhotos,
    levelsList,
    selectRoom,
    isLevelsModalOpen,
    setIsLevelsModalOpen,
    onRoomSelected,
    isAddPhotoToClaimFormOpen,
    claimPictures,
    onDismissAddPhotoToClaimForm,
    onSubmitAddPhotoToClaimForm,
    region,
    selectedPhotoEdit,
    isEditBulkPhotoToClaimFormOpen,
    setIsEditBulkPhotoToClaimFormOpen,
    selectedBulkPhotoEdit,
    setSelectedBulkPhotoEdit,
  };
};

export default ClaimPhotosViewModel;
export type { PropsClaimPhotos };