import React, { FC } from 'react';
import { IonButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import './FosButtonIcon.css';
import { t } from 'i18next';

type Props = {
  title?: string;
  rightButtonText?: string;
  onSaveClick: (event: any) => any;
  onCancelClick: (event: any) => any;
};

const FosFormHeader: FC<Props> = ({ title, rightButtonText, onSaveClick, onCancelClick }) => (
  <IonHeader>
    <IonToolbar>
      <IonButtons slot="start">
        <IonButton color="danger" onClick={onCancelClick}>
          {t('cancel').toString()}
        </IonButton>
      </IonButtons>
      <IonTitle>{title}</IonTitle>
      <IonButtons slot="end">
        <IonButton onClick={onSaveClick}>
          {rightButtonText ?? t('save').toString()}
        </IonButton>
      </IonButtons>
    </IonToolbar>
  </IonHeader>
);

export default FosFormHeader;
