import React from 'react';
import { TFunction } from 'i18next';
import { print } from 'graphql';
import gql from 'graphql-tag';
import { ExpenseBaseForm, ExpenseBaseFormData, ExpenseBaseFormErrors, getBaseExpErrors } from './ExpBaseExpenseForm';
import { ExpTax } from './ExpTaxes';
import { SimpleExpenseForm, SimpleExpenseFormData, SimpleExpenseFormErrors, createSimpleExp, getSimpleExpErrors } from './ExpSimpleExpenseForm';
import { ExpenseBaseDtoInput, ExpenseDtoInput, ExpenseReceiptDtoInput } from '../hooks/expSaveExpense';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import { UserState } from '../hooks/authUtils';
import { ExpenseMethod } from '../pages/ExpenseMethod';

export type SimpleExpenseFormFullData = {
  id: number
  date: string
  creditCardId?: string
  creditCardNumber: string
  location: string
  branch: number
  branchName: string
  category: number
  department: number
  currency: string
  totalAmount: string
  tip: string
  taxes: ExpTax[]
  merchant: string
  comments: string
  receipts: File[]
  receiptsData: ExpenseReceiptDtoInput[]
  hasTaxes: boolean
  isParent: boolean
  statusId: number
};

export type SimpleExpenseFormFullErrors = ExpenseBaseFormErrors & SimpleExpenseFormErrors;

export interface Props {
  expense: SimpleExpenseFormFullData;
  errors: SimpleExpenseFormFullErrors;
  expenseMethod: ExpenseMethod;
  editMode: boolean;
  onChange: (id: keyof SimpleExpenseFormFullData, value: any, data: SimpleExpenseFormFullData) => any
}

export function createSimpleExpFull(homeBranch: number, homeRegion: string): SimpleExpenseFormFullData {
  return {
    ...createSimpleExp({ branch: homeBranch }),
    date: '',
    creditCardNumber: '',
    creditCardId: undefined,
    location: homeRegion ?? '',
    currency: '',
    merchant: '',
    comments: '',
    receipts: [],
    receiptsData: [],
    tip: '',
    isParent: false,
    statusId: 1, // Pending
  };
}

const saveSimpleExpenseMutation = gql`
mutation(
  $userGuid: UUID!,  
  $expenses: [ExpenseBaseDtoInput],  
  $releaseAfterSave: Boolean!,
  $files: [Upload!],
) { 
  saveExpense(
    userGuid: $userGuid,
    expenses: $expenses,
    releaseAfterSave: $releaseAfterSave,
    receipts:{
      files: $files
    }
  ) { 
    itemId
    status
    message
  }
}
`;

export async function saveSimpleExpFull(
  userGuid: string,
  userState: UserState,
  simpleExpensesFull: any[],
  releaseAfterSave: Boolean,
) {
  const formData = new FormData();
  const expenses: ExpenseBaseDtoInput[] = [];

  simpleExpensesFull.forEach(simpleExpFull => {
    const totalAmount = Number.isNaN(+simpleExpFull.totalAmount) ? 0 : +simpleExpFull.totalAmount;
    const taxAmount = simpleExpFull.taxes.reduce((amount: any, tax: any) => amount + +tax.total, 0);
    const tip = Number.isNaN(+simpleExpFull.tip) ? 0 : +simpleExpFull.tip;
    const netAmount = totalAmount - taxAmount - tip;
    const regionId = userState.userAuthPayload?.regionId!;
    const expense: ExpenseDtoInput = {
      id: simpleExpFull.id < 1000000 ? simpleExpFull.id : 0,
      branchId: +simpleExpFull.branch,
      regionId,
      comments: simpleExpFull.comments,
      createdByGuid: userGuid,
      currencyId: +simpleExpFull.currency,
      dateExpense: simpleExpFull.date,
      dateCreated: new Date().toISOString(),
      employeeGuid: userGuid,
      expenseMethodId: 2, // out of pocket
      gpcategoryId: +simpleExpFull.category === 0 ? undefined : +simpleExpFull.category,
      gpdepartmentId: simpleExpFull.department ? +simpleExpFull.department : 0,
      province: simpleExpFull.location,
      isParent: simpleExpFull.isParent ?? false,
      parentId: 0,
      statusId: 1,
      totalAmount,
      netAmount,
      tip,
      taxAmount,
      merchant: simpleExpFull.merchant,
    };

    const expenseObject: ExpenseBaseDtoInput = {
      expenseObject: {
        expense,
        expenseTaxes: simpleExpFull.taxes.map((t: any) => ({ ...t, total: +t.total })),
        expenseReceipts: simpleExpFull.receiptsData,
      },
    };

    expenses.push(expenseObject);
  });

  const operations = {
    query: print(saveSimpleExpenseMutation),
    variables: {
      userGuid,
      files: null,
      expenses,
      releaseAfterSave,
    },
  };
  formData.append('operations', JSON.stringify(operations));

  const map: any = {};
  if (simpleExpensesFull[0].receiptsData) {
    simpleExpensesFull[0].receipts?.forEach((receipt: any, index: any) => {
      map[index] = ['variables.files'];
      formData.set(`${index}`, receipt);
    });
  }

  formData.append('map', JSON.stringify(map));
  const restClient = getRestClient(userState, ClientType.FORM);
  const res = await restClient.post('/payroll/graphql', formData);
  return res;
}

export async function getSimpleExpFullErrors(simpleExpFull: SimpleExpenseFormFullData, t: TFunction): Promise<SimpleExpenseFormFullErrors> {
  if (simpleExpFull.isParent) {
    return { ...getBaseExpErrors(simpleExpFull, t) };
  }

  return {

    ...getBaseExpErrors(simpleExpFull, t),
    ...getSimpleExpErrors(simpleExpFull, t),
  };
}

export const SimpleExpenseFormFull: React.FC<Props> = ({ expense, errors, expenseMethod, editMode, onChange }) => {
  const handleExpenseBaseFormChange = (id: keyof ExpenseBaseFormData, value: any, baseExpense: ExpenseBaseFormData) => {
    const exp: SimpleExpenseFormFullData = { ...expense, ...baseExpense };
    onChange(id, value, exp);
  };

  const handleChange = (id: keyof SimpleExpenseFormData, value: any, simpleExpense: SimpleExpenseFormData) => {
    const exp: SimpleExpenseFormFullData = { ...expense, ...simpleExpense };
    onChange(id, value, exp);
  };

  return (
    <div className="fos-form ion-margin-top">
      <div className="ion-margin-bottom">
        <ExpenseBaseForm editMode={editMode} expenseMethod={expenseMethod} totalAmountReadOnly={expenseMethod === ExpenseMethod.CREDIT_CARD} expense={expense} errors={errors} onChange={handleExpenseBaseFormChange} />
      </div>
      <SimpleExpenseForm editMode={editMode} id="form-full" errors={errors} onChange={handleChange} expense={expense} location={expense.location} />
    </div>
  );
};

export default SimpleExpenseFormFull;
