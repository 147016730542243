import React, { useEffect, useState } from 'react';
import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRange,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { EllipseToolSettings, Sketch } from '../sketch-tool';

export interface RectToolSettingsProps {
  sketch: Sketch;
}

const SketchToolEllipseToolSettings = ({ sketch }: RectToolSettingsProps) => {
  const [ellipseToolSettings, setEllipseToolSettings] = useState<EllipseToolSettings>(sketch?.ellipseToolSettings || {});
  const { t } = useTranslation();

  useEffect(() => {
    if (!sketch) return;
    sketch.ellipseToolSettings = ellipseToolSettings;
  }, [sketch, ellipseToolSettings]);

  const handleModeChange = (event: any) => {
    setEllipseToolSettings({ ...ellipseToolSettings, mode: event.target.value });
  };

  const handleColorChange = (event: any) => {
    const color = event.target.value;
    setEllipseToolSettings({ ...ellipseToolSettings, strokeStyle: color, fillStyle: color });
  };

  const handleLineWidthChange = (event: any) => {
    setEllipseToolSettings({ ...ellipseToolSettings, lineWidth: +event.detail.value });
  };

  const handleShowDimensionsChange = (event: any) => {
    setEllipseToolSettings({ ...ellipseToolSettings, showDimensions: event.detail.checked });
  };

  const color = ellipseToolSettings?.mode === 'stroke' ? ellipseToolSettings.strokeStyle : ellipseToolSettings.fillStyle;

  return (
    <IonToolbar color="light">
      <IonGrid>
        <IonRow class="ion-align-items-center ion-nowrap" style={{ overflowX: 'scroll' }}>
          <IonCol size="6">
            <IonItem lines="none" color="light">
              <IonSelect
                aria-label="mode"
                placeholder={t('rectMode')}
                value={ellipseToolSettings.mode || 'stroke'}
                onIonChange={handleModeChange}
              >
                <IonSelectOption value="fill">
                  {t('fill').toString()}
                </IonSelectOption>
                <IonSelectOption value="stroke">
                  {t('stroke').toString()}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem lines="none" color="light">
              <IonLabel>
                {t('showDimensions').toString()}
              </IonLabel>
              <IonCheckbox checked={ellipseToolSettings.showDimensions || false} onIonChange={handleShowDimensionsChange} />
            </IonItem>
          </IonCol>
          <IonCol size="14">
            <IonItem lines="none" color="light">
              <IonLabel>
                {t('lineWidth').toString()}
              </IonLabel>
              <IonRange value={ellipseToolSettings.lineWidth || 1} min={1} max={30} onIonChange={handleLineWidthChange} />
            </IonItem>
          </IonCol>
          <IonCol>
            <input type="color" value={color || '#000000'} onChange={handleColorChange} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );
};

export default SketchToolEllipseToolSettings;
