/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable  jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import {
  IonContent,
  IonLoading,
  IonModal,
  IonHeader,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { close } from 'ionicons/icons';
import { User } from '../graphql/GetUser';
import useGetDigitalBadgeInfo from '../hooks/getDigitalBadgeInfo';
import { useAuth } from '../hooks/authContext';
import { ReactComponent as ProfileIcon } from '../assets/icons/ic_profile.svg';
import { ReactComponent as FirstOnSiteIcon } from '../assets/icons/ic_first-on-site.svg';
import './Badge.css';

type Props = {
  user: User | undefined;
};

const Badge: React.FC<Props> = ({ user }) => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const { t } = useTranslation();
  const [qrFullscreen, setQrFullscreen] = React.useState(false);
  const variables = { id: user?.userGuid };
  const { badgeInfo, isLoading } = useGetDigitalBadgeInfo(regionId, userState, variables);
  const qrCodeString = `data:image/png;base64, ${badgeInfo?.qrCodeImage}`;
  const profileImageString = `data:image/png;base64, ${badgeInfo?.userPhoto}`;

  let imageTag;
  if (badgeInfo?.userPhoto != null) {
    imageTag = <img className="badge-picture" src={profileImageString} alt="" />;
  } else {
    imageTag = <ProfileIcon fill="none" className="no-picture-icon" />;
  }

  return (
    <>
      <IonContent className="no-scroll ion-no-padding">
        <div className='badge-page'>
          <div className="badge-container">
            <FirstOnSiteIcon className="logo" />
            {imageTag}
            <span className="name">{badgeInfo?.fullName}</span>
            <span className="job-title">{badgeInfo?.title}</span>
            <div className="branch-region-qr-container">
              <div className="branch-region-container">
                <span className="branch-region">{badgeInfo?.branch}</span><br />
                <span className="branch-region">{badgeInfo?.region}</span>
              </div>
              <img className="qr-code" src={qrCodeString} alt="" onClick={() => setQrFullscreen(true)} />
            </div>
          </div>
        </div>
        <IonModal isOpen={qrFullscreen} class="custom-modal">
          <IonHeader class="ion-no-border">
            <IonToolbar className="phase-selector-header toolbar-title-default ios in-toolbar">
              <IonTitle>{t('employeeQRCode').toString()}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setQrFullscreen(false)}>
                  <div className="close-icon-container">
                    <IonIcon className="tools-close-button" icon={close} />
                  </div>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='qr-modal-content ion-padding'>
            <img src={qrCodeString} alt="" className="modal-qr" />
          </IonContent>
        </IonModal>
      </IonContent>
      <IonLoading isOpen={isLoading} message='loading' duration={2000} />
    </>
  );
};

export default Badge;

