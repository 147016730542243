import { duplicateButtonImage, rotateButtonImage } from './common';
import { ObjectConfig, ObjectExport, Rect, SketchObject, SketchObjectType } from './sketch-object';

export type DrawingPoint = [number, number];

export interface DrawingConfig extends ObjectConfig {
  points?: DrawingPoint[];
  strokeStyle?: string;
  lineWidth?: number;
}

export interface DrawingExport extends ObjectExport {
  type: SketchObjectType.Drawing | SketchObjectType.Wall;
  points?: DrawingPoint[];
  strokeStyle?: string;
  lineWidth?: number;
}

export type DrawingStatus = 'started' | 'finished';

export class Drawing extends SketchObject {
  points: DrawingPoint[];

  strokeStyle: string;

  lineWidth: number;

  dragStartPoints: DrawingPoint[] | null = null;

  private path?: Path2D;

  private currentTransform?: DOMMatrix;

  constructor(config: DrawingConfig) {
    super(config);
    const {
      points = [],
      strokeStyle = '',
      lineWidth = 1,
    } = config;
    this.points = points;
    this.strokeStyle = strokeStyle;
    this.lineWidth = lineWidth;
  }

  setPoints(points: DrawingPoint[]) {
    this.points = points;
    this.emit('update');
  }

  setStrokeStyle(strokeStyle: string) {
    this.strokeStyle = strokeStyle;
    this.emit('update');
  }

  setLineWidth(lineWidth: number) {
    this.lineWidth = lineWidth;
    this.emit('update');
  }

  addPoint(x: number, y: number) {
    this.points.push([x, y]);
    this.emit('update');
  }

  export(): DrawingExport {
    return {
      ...super.export(),
      type: SketchObjectType.Drawing,
      points: this.points,
      strokeStyle: this.strokeStyle,
      lineWidth: this.lineWidth,
    };
  }

  draw() {
    if (!this.visible || !this.canvas) return;
    const p0 = this.points[0];
    if (!this.canvas || !p0) return;
    const ctx = this.canvas?.getContext('2d')!;
    ctx.save();
    ctx.lineWidth = this.lineWidth;
    ctx.strokeStyle = this.strokeStyle;
    this.path = new Path2D();
    ctx.translate(p0[0], p0[1]);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-p0[0], -p0[1]);
    this.path.moveTo(p0[0], p0[1]);
    for (const [x, y] of this.points.slice(1)) {
      this.path.lineTo(x, y);
    }
    ctx.stroke(this.path);
    this.currentTransform = ctx.getTransform();
    ctx.restore();
    if (this.showDimensions) this.drawDimensions();
    if (this.selected) {
      this.drawSelection();
      this.drawRotateButton();
      this.drawDuplicateButton();
    }
  }

  drawName(): void { }

  drawSquareFootage(): void { }

  drawDimensions(): void { }

  drawSelection() {
    if (!this.canvas) return;
    const ctx = this.canvas?.getContext('2d')!;
    const p0 = this.points[0];
    if (!p0) return;
    ctx.save();
    ctx.fillStyle = 'red';
    ctx.translate(p0[0], p0[1]);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-p0[0], -p0[1]);
    const path = new Path2D();
    const size = 8;
    const halfSize = size / 2;
    path.rect(p0[0] - halfSize, p0[1] - halfSize, size, size);
    const pN = this.points[this.points.length - 1];
    path.rect(pN[0] - halfSize, pN[1] - halfSize, size, size);
    path.moveTo(p0[0], p0[1]);
    let lastPoint: DrawingPoint = p0;
    for (const [x, y] of this.points.slice(1)) {
      if (this.getDistance([x, y], lastPoint) > 20) {
        path.rect(x - halfSize, y - halfSize, size, size);
        lastPoint = [x, y];
      }
    }
    ctx.fill(path);
    ctx.restore();
  }

  isContainedByRect(outerRect: Rect): boolean {
    return this.isObjectContainedByRect(this.getBoundingRect(), outerRect);
  }

  isPointInsideObject(x: number, y: number) {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const v = ctx.isPointInStroke(this.path!, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideRotationButton(x: number, y: number): boolean {
    const p0 = this.points[0];
    if (!this.canvas || !p0) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    path.rect(p0[0] - 12 - 24, p0[1], 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideDuplicateButton(x: number, y: number): boolean {
    const p0 = this.points[0];
    if (!this.canvas || !p0) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const rx = p0[0];
    const ry = p0[1] + 12;
    path.rect(rx, ry, 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideResizeButton(): boolean {
    return false;
  }

  handleDragStart(): void {
    this.dragStartPoints = [...this.points];
  }

  handleDrag({ deltaX, deltaY }: HammerInput): void {
    const sketch = this.getSketch();
    if (!this.dragStartPoints || !sketch) return;
    this.points = this.dragStartPoints.map(([x, y]) => [x + deltaX / sketch.zoom, y + deltaY / sketch.zoom]);
    this.emit('update');
  }

  handleDragEnd(): void {
    this.dragStartPoints = null;
  }

  handleResizeStart(): void { }

  handleResize(): void { }

  handleResizeEnd(): void { }

  drawRotateButton(): void {
    const p0 = this.points[0];
    if (!p0) return;
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(p0[0], p0[1]);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-p0[0], -p0[1]);
    const btnWidth = 24;
    const x = p0[0] - 12 - btnWidth;
    const y = p0[1] + 12;
    ctx.drawImage(rotateButtonImage, x, y);
    ctx.restore();
  }

  drawResizeButton(): void { }

  drawDuplicateButton(): void {
    const p0 = this.points[0];
    if (!p0) return;
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(p0[0], p0[1]);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-p0[0], -p0[1]);
    const x = p0[0];
    const y = p0[1] + 12;
    ctx.drawImage(duplicateButtonImage, x, y);
    ctx.restore();
  }

  getDistance([x1, y1]: DrawingPoint, [x2, y2]: DrawingPoint) {
    const x = y2 - y1;
    const y = x2 - x1;
    return Math.sqrt(x * x + y * y);
  }

  getLastPoint() {
    return this.points[this.points.length - 1];
  }

  private getBoundingRect() {
    const xValues: number[] = this.points.map(([x]) => x);
    const yValues: number[] = this.points.map(([, y]) => y);
    const minX = Math.min(...xValues);
    const maxX = Math.max(...xValues);
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);
    const width = maxX - minX;
    const height = maxY - minY;
    return {
      x: minX,
      y: minY,
      width,
      height,
    };
  }
}

export default Drawing;
