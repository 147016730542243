import React, { useState } from 'react';
import { IonButton, IonItem, IonLabel, useIonActionSheet } from '@ionic/react';
import './FosCardHeader.css';
import { useTranslation } from 'react-i18next';
import RenameModal from './RenameModal';
import { ReactComponent as MoreIcon } from '../assets/icons/ic_more.svg';

export interface Props {
  label: string;
  modalTitle: string;
  onChangeLevelName: (levelName: string) => any;
  onLevelDelete: () => any;
}


const FosCardHeader = React.forwardRef<HTMLIonItemElement, Props>(({ label, onChangeLevelName, modalTitle, onLevelDelete }, ref) => {

  const [present] = useIonActionSheet();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDuplicateLevelEnabled = process.env.REACT_APP_ENV !== 'prod';
  const isDeleteLevelEnabled = true;

  const cancel = () => {
    setIsModalOpen(false);
  };

  const changeLevelName = (newName: string) => {
    if (newName !== '') {
      onChangeLevelName(newName);
      setIsModalOpen(false);
    }
  };
  
  enum LevelAction {
    RENAME = 'Rename Level',
    DUPLICATE = 'Duplicate Level',
    DELETE = 'Delete Level',
    CANCEL = 'Cancel',
  }

  const levelActionSheetButtons = [
    {
      text: t('renameLevel'),
      data: {
        action: LevelAction.RENAME,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: LevelAction.CANCEL,
      },
    },
  ];
  if (isDuplicateLevelEnabled) {
    const duplicateLevelButton = {
      text: t('duplicateLevel'),
      data: {
        action: LevelAction.DUPLICATE,
      },
    };
    levelActionSheetButtons.splice(1, 0, duplicateLevelButton);
  }
  if (isDeleteLevelEnabled) {
    const deleteLevelButton = {
      text: t('deleteLevel'),
      role: 'destructive',
      data: {
        action: LevelAction.DELETE,
      },
    };
    levelActionSheetButtons.splice(2, 0, deleteLevelButton);
  }

  const handleClick = () => {
    present({
      header: label,
      cssClass: 'custom-action-sheet',
      buttons: levelActionSheetButtons,
      onDidDismiss: ({ detail }) => {
        switch (detail.data?.action) {
          case LevelAction.RENAME: {
            setIsModalOpen(true);
            break;
          }
          case LevelAction.DUPLICATE: {
            // todo: add functionality
            break;
          }
          case LevelAction.DELETE: {
            onLevelDelete();
            break;
          }
          case LevelAction.CANCEL:
          default: break;
        }
      },
    });
  };

  return (
    <>
      <IonItem ref={ref} className='fos-card-header-level'>
        <IonLabel className='fos-card-header-level'>
          {label}
        </IonLabel>
        <IonButton fill='clear' className='media-inspection-more-button' onClick={handleClick}>
          <MoreIcon fill="none" className="more-icon" />
        </IonButton>
      </IonItem>
      <RenameModal currentName={label} modalTitle={modalTitle} isModalOpen={isModalOpen} onCancel={cancel} onNameChanged={(newName) => changeLevelName(newName)}/>
    </>
  );
});

export default FosCardHeader;
