import React, { } from 'react';
import { IonButton, IonList } from '@ionic/react';
import './FosDoubleButton.css';

export interface Props {
  leftButtonLabel: string;
  rightButtonLabel: string;
  onLeftClick: (event: any) => any;
  onRightClick: (event: any) => any;
}

const FosDoubleButton: React.FC<Props> = (({ leftButtonLabel, rightButtonLabel, onLeftClick, onRightClick }) => (
    <IonList className='fos-double-button-inline-container fos-double-button-vertical-border' slot='content'>
      <IonButton className='fos-double-button-inline ion-text-wrap' fill='clear' onClick={onLeftClick}>
        {leftButtonLabel}
      </IonButton>
      <div className="fos-double-button-divider" />
      <IonButton className='fos-double-button-inline ion-text-wrap' fill='clear' onClick={onRightClick}>
        {rightButtonLabel}
      </IonButton>
    </IonList>
));

export default FosDoubleButton;
