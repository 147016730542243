import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonText,
  IonToast,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Toaster, toast } from 'react-hot-toast';

import './OtherEmailsModal.css';
import { closeOutline } from 'ionicons/icons';
import FosModalHeader from '../atom/FosModalHeader';
import FormItemDivider from '../atom/FormItemDivider';
import SaveStorage, { SavedRecords } from '../utils/SaveStorage';
import { useAuth } from '../hooks/authContext';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import ClaimDetailsContext from '../contexts/ClaimDetails';
import generateNotesEmailBody, { generateNotesEmailSubject } from '../utils/GenerateNotesEmailBody';
import AutocompleteSearch from '../molecule/AutocompleteSearch';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import GET_EMAIL_SUGGESTIONS, { EmailSuggestion } from '../graphql/GetEmailSuggestions';
import { getErrorMsg } from '../pages/helper/AppHelper';
import { emailRegexNoGlobal } from '../pages/helper/FormsHelper';

interface Props {
  isModalOpen: boolean;
  note: string;
  noteId?: number;
  regionId?: number;
  claimNumber: string;
  userName: string;
  onDismiss: (status?: string, sentEmail?: Array<string>) => any;
}

export interface CustomEmailContact {
  id: string;
  email: string;
  selected?: boolean;
  fullName?: string;
}

const MAX_SAVED_EMAILS = 5;

const OtherEmailsModal: React.FC<Props> = ({ isModalOpen, note, noteId, regionId, claimNumber, userName, onDismiss }) => {
  const { t } = useTranslation();
  const { userState } = useAuth()!;
  const restClient = getRestClient(userState, ClientType.PLAIN);
  const [emailBody, setEmailBody] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedEmails, setSelectedEmails] = useState<Array<CustomEmailContact>>([]);
  const [savedEmails, setSavedEmails] = useState<Array<CustomEmailContact>>([]);
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const [searchError, setSearchError] = useState('');
  const claim = useContext(ClaimDetailsContext);
  const inputRef = useRef('');
  const graphQLClient = getGraphQLClient(userState);
  const [isInvalidEmailAlertOpen, setInvalidEmailAlert] = useState(false);

  useEffect(() => {
    setEmailBody(note);
  }, [note]);

  const getEmailSuggestions = useCallback((emailInput: string) => {
    if (emailInput.length > 0) {
      try {
        graphQLClient.request(GET_EMAIL_SUGGESTIONS, { term: emailInput }).then((res) => {
          const emailSuggestions = res?.userEmailsByTerm?.map((emailSuggestion: EmailSuggestion) => ({ id: emailSuggestion.email, description: emailSuggestion.email }));
          setSuggestions(emailSuggestions);
        }).catch((e: any) => {
          const msg = getErrorMsg(e);
          const message = msg || '';
          setSearchError(message);
        });
      } catch (e: any) {
        const msg = getErrorMsg(e);
        const message = msg || '';
        setSearchError(message);
      }
    } else {
      setSuggestions([]);
    }
  }, [graphQLClient]);

  const getSavedEmails = useCallback(async () => {
    const saveStorage = SaveStorage();
    const emails = await saveStorage.getItems(SavedRecords.Emails);
    const saved = emails.map((email: any) => ({ email: email.data.email, id: email.primaryKey }));
    setSavedEmails(saved);
    return saved;
  }, []);

  const saveEmail = useCallback(async (emails: Array<string>) => {
    const storedEmails = await getSavedEmails();
     
    const unsavedEmails = emails.filter((email) => !storedEmails.find((savedEmail) => savedEmail.email === email));

    if (storedEmails.length < MAX_SAVED_EMAILS) {

      const saveStorage = SaveStorage();
      unsavedEmails.forEach(async (email) => {
        await saveStorage.setItem(SavedRecords.Emails, { email });
      });
      getSavedEmails();
    } else {

      const saveStorage = SaveStorage();
      await saveStorage.deleteItem(SavedRecords.Emails, storedEmails[0].id);
      unsavedEmails.forEach(async (email) => {
        await saveStorage.setItem(SavedRecords.Emails, { email });
      });
      getSavedEmails();
    }

  }, [getSavedEmails]);

  const sendEmail = useCallback(async (emails: Array<string>) => {
    setIsLoading(true);

    const { status } = await restClient.post('/platform/email/send', {
      to: emails[0],
      ccList: emails.slice(1)?.join(';'),
      body: generateNotesEmailBody({
        userName,
        emailBody,
        claimIndx: claimNumber,
        projName: claim?.projName,
        projAddr: claim?.projAddr,
      }),
      subject: generateNotesEmailSubject({ claimNumber, projName: claim?.projName, projAddr: claim?.projAddr }),
    });

    setIsLoading(false);

    if (status === 200) {
      const promises = emails.map(email => 
        restClient.post(`/common/claims/note/share?regionId=${regionId}`, {
          noteId,
          email,
          sharedBy: userName,
        }),
      );

      try {
        await Promise.all(promises);
      } catch (error) {
        console.error('Error logging note sharing:', error);
      }
      
      saveEmail(emails.length >= MAX_SAVED_EMAILS ? emails.slice(-5, 0) : emails);
      onDismiss('success', emails);
    } else {
      toast.error(t('errorSendingEmail').toString(), { duration: 4000 });
    }
  
  }, [
    t,
    userName,
    noteId,
    regionId,
    emailBody,
    onDismiss,
    restClient,
    saveEmail,
    claimNumber,
    claim?.projName,
    claim?.projAddr,
  ]);

  const onOptionSelect = (option?: any) => {
    // @ts-ignore
    const email = option?.description || inputRef.current.value;
    if (email) {
      setSelectedEmails([...selectedEmails, { email, id: 'temp', selected: true }]);
    }
    setSuggestions([]);
    if (inputRef?.current) {
      // @ts-ignore
      inputRef.current.value = '';
    }
  };

  const onAddEmailClick = () => {
    // @ts-ignore
    const email = inputRef.current.value;
    // TODO: REGEX CHECK HERE
    const testEmail = emailRegexNoGlobal.test(email !== undefined ? email : '');
    setInvalidEmailAlert(!testEmail);

    if (email && testEmail) {
      setSelectedEmails([...selectedEmails, { email, id: 'temp', selected: true }]);
      setSuggestions([]);
      if (inputRef?.current) {
        // @ts-ignore
        inputRef.current.value = '';
      }
    }

  };

  useEffect(() => {
    getSavedEmails();
  }, [getSavedEmails]);

  const onCustomContactChange = useCallback((event: any, contact: CustomEmailContact) => {
    if (event.detail.checked) {
      
      const filteredSelectedEmails = selectedEmails.filter((selected) => selected.email !== contact.email);
      const newSelectedEmails = [...filteredSelectedEmails, { ...contact, selected: true }];

      setSelectedEmails(newSelectedEmails);
    } else if (contact.id === 'temp') {
      const filteredSelectedEmails = selectedEmails.filter((selected) => selected.email !== contact.email);
      const newSelectedEmails = [...filteredSelectedEmails, { ...contact, selected: false }];

      setSelectedEmails(newSelectedEmails);
    } else {
      const updatedSelectedEmails = selectedEmails.filter((selected) => selected.email !== contact.email);

      setSelectedEmails(updatedSelectedEmails);
      setSavedEmails([...savedEmails, contact]);
    }
  }, [
    savedEmails,
    selectedEmails,
  ]);

  const onSavedEmailChange = useCallback((event: any, contact: CustomEmailContact) => {
    if (event.detail.checked) {
      const updatedSelectedEmails = [...selectedEmails, { ...contact, selected: true }];
      const updatedSavedEmails = savedEmails.filter((selected) => selected.email !== contact.email);

      setSelectedEmails(updatedSelectedEmails);
      setSavedEmails(updatedSavedEmails);
    }
  }, [
    savedEmails,
    selectedEmails,
  ]);

  const onSave = useCallback(() => {
    const emailList = selectedEmails.map((selected) => selected.email);

    sendEmail(emailList);
    setSelectedEmails([]);
    onDismiss();
  }, [
    onDismiss,
    sendEmail,
    selectedEmails,
  ]);

  return (
    <IonModal isOpen={isModalOpen}>
      <FosModalHeader title={t('customShare').toString()} onCloseClick={onDismiss} />
      <IonContent class='ion-padding'>
      {isInvalidEmailAlertOpen && (
            <IonText className="invalid-entry-message">{t('pleaseEnterAValidEmailAddress').toString()}</IonText>
      )}
        <AutocompleteSearch
          ref={inputRef}
          placeholder={t('emailAddress').toString()}
          onChange={(evt: any) => getEmailSuggestions(evt.target.value)}
          options={suggestions}
          onOptionSelect={(option) => onOptionSelect(option)}
          debounce={1000}
          addButton
          onAddClick={() => onAddEmailClick()}
        />
        {
          selectedEmails.length > 0 && (              
            <div className='selected-section recents-section recents-label'>
              <IonLabel>{t('selectedEmails').toString()}</IonLabel>
              <FormItemDivider />
              <IonList>
                {selectedEmails.map((contact, index) => (
                  <div key={`${contact.email + index}`}>
                    <IonItem
                      lines="none"
                      className='ion-no-padding'
                    >
                      <IonCheckbox
                        className="form-item-checkbox"
                        slot="start"
                        color="primary"
                        checked={contact.selected!}
                        onIonChange={(e) => onCustomContactChange(e, contact)}
                      />
                      <IonLabel>
                        <IonText className='fos-share-with-contact-value'>{contact.email}</IonText>
                      </IonLabel>
                    </IonItem>
                    <FormItemDivider />
                  </div>
                ))}
              </IonList>
            </div>
          )
        }
        {
          savedEmails.length > 0 && (
            <div className='recents-section recents-label'>
              <IonLabel>Recents</IonLabel>
                    <FormItemDivider />
              <IonList>
                {savedEmails.map((contact, index) => (
                  <div key={`${contact.email + index}`}>
                    <IonItem
                      lines="none"
                      className='ion-no-padding'
                    >
                      <IonCheckbox
                        className="form-item-checkbox"
                        slot="start"
                        color="primary"
                        onIonChange={(e) => onSavedEmailChange(e, contact)}
                      />
                      <IonLabel>
                        <IonText className='fos-share-with-contact-value'>{contact.email}</IonText>
                      </IonLabel>
                    </IonItem>
                    <FormItemDivider />
                  </div>
                ))}
              </IonList>
            </div>
          )
        }
      </IonContent>
      <IonToast
        isOpen={!!searchError}
        message={searchError}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => { setSearchError(''); }}
      />
      <IonFooter>
        <IonButton
          fill="solid"
          expand="block"
          className="ion-padding-start ion-padding-end ion-margin-bottom"
          onClick={onSave}
        >
          {t('share').toString()}
        </IonButton>
      </IonFooter>
      <Toaster
        containerStyle={{
          top: 50,
          left: 20,
          bottom: 20,
          right: 20,
        }} />
      <IonLoading
        isOpen={isLoading}
        message={t('sendingEmail').toString()}
        duration={3000}
      />
    </IonModal>
  );
};

export default OtherEmailsModal;