enum ClassType {
  NA = 'N/A',
  I = 'I',
  II = 'II',
  III = 'III',
  IV = 'IV',
}

export const classTypeList = [{ value: ClassType.NA }, { value: ClassType.I }, { value: ClassType.II }, { value: ClassType.III }, { value: ClassType.IV }];

enum CategoryOption {
  NA = 'N/A',
  Cat1 = 'Cat. 1 - Clean',
  Cat2 = 'Cat. 2 - Grey',
  Cat3 = 'Cat. 3 - Black',
}

enum CategoryOptionLabel {
  NA = 'N/A',
  Cat1 = 'Cat. 1',
  Cat2 = 'Cat. 2',
  Cat3 = 'Cat. 3',
}

export const categoryOptionsList = [{ value: CategoryOption.NA, label: CategoryOptionLabel.NA }, 
  { value: CategoryOption.Cat1, label: CategoryOptionLabel.Cat1 }, 
  { value: CategoryOption.Cat2, label: CategoryOptionLabel.Cat2 }, 
  { value: CategoryOption.Cat3, label: CategoryOptionLabel.Cat3 }];