import React, { useEffect, useRef } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
  IonToolbar,
  isPlatform,
  useIonActionSheet,
  useIonViewWillEnter,
} from '@ionic/react';
import { filterOutline, barcode, add, closeOutline } from 'ionicons/icons';
import { t } from 'i18next';
import { Toaster, toast } from 'react-hot-toast';
import { useHistory } from 'react-router';
import './EquipmentsByClasses.css';
import FosButtonIcon, { FosButtonIconStyle } from '../../../../../atom/FosButtonIcon';
import FosCard from '../../../../../atom/FosCard';
import SearchInput from '../../../../../atom/SearchInput';
import { EquipmentsType } from '../../../data/graphql/GetEquipmentsList';
import { EquipmentStatus } from '../../../../../pages/helper/Const';
import FosToolbar from '../../../../../components/FosToolbar';
import useEquipmentByClassesViewModel from './EquipmentsByClassesViewModel';
import FilterModal from '../../../../../organism/FilterModal';
import FosMultiSelectItem from '../../../../../components/FosMultiSelectItem';
import { EquipmentAction } from '../../../util/EquipmentUtil';
import { HYBRID } from '../../../../../hooks/photo';

const EquipmentsByClasses: React.FC = () => {

  const [present] = useIonActionSheet();
  const history = useHistory();
  const POP = 'POP';
  const filterModal = useRef<HTMLIonModalElement>(null);

  const {
    EQUIPMENT_ACTION_SHEET,
    isFetching,
    isSaving,
    showErrorToast,
    showSuccessToast,
    updateError,
    equipmentsSubset,
    selectedModel,
    selectedBranch,
    selectedStatus,
    equipmentBranchesOptions,
    equipmentModelsOptions,
    equipmentStatusOptions,
    scanActionSheetButtons,
    showResults,
    clearFilter,
    handleModelChange,
    handleBranchChange,
    handleStatusChange,
    handleOnDidDismiss,
    onSearchTerm,
    onEditClick,
    onAddClick,
    refetchEquipments,
    handleItemSelection,
    setShowErrorToast,
    setShowSuccessToast,
    setUpdateError,
    handleOnDidDismissScanOption,
  } = useEquipmentByClassesViewModel();

  /* 
  * Refetch equipments when EquipmentEdition page is closed.
  */
  useIonViewWillEnter(() => {
    if (history.action === POP) {
      refetchEquipments();
    }
  });

  /*
  * Observing variables to show toasts.
  */
  useEffect(() => {
    if (showErrorToast) {
      toast.error(t('optionNotAvailable'), { duration: 2000 });
      setShowErrorToast(false);
    } else if (showSuccessToast) {
      toast.success(t('equipmentStatusUpdated'), { duration: 2000 });
      setShowSuccessToast(false);
    }
  }, [setShowErrorToast, setShowSuccessToast, showErrorToast, showSuccessToast]);

  const getEquipmentActionsSheets = (equipment: EquipmentsType) => 
    Number(equipment?.status?.id) === EquipmentStatus.Available ? EQUIPMENT_ACTION_SHEET : 
      EQUIPMENT_ACTION_SHEET.filter(button => 
        button.data.action === EquipmentAction.ReturnToBranch || 
        button.data.action === EquipmentAction.CANCEL,
      );

  const onStatusCLick = (equipment: EquipmentsType) => {
    present({
      header: t('chooseStatus'),
      cssClass: 'custom-action-sheet',
      buttons: getEquipmentActionsSheets(equipment),
      onDidDismiss: ({ detail }) => handleOnDidDismiss(detail.data?.action, equipment),
    });
  };

  const dismissModal = () => filterModal.current?.dismiss();

  const handleClearFilter = () => {
    clearFilter();
    dismissModal();
  };

  const handleShowResults = () => {
    showResults();
    dismissModal();
  };

  const showScanOptionsSheet = () => {
    present({
      header: t('chooseType'),
      cssClass: 'custom-action-sheet',
      buttons: scanActionSheetButtons,
      onDidDismiss: ({ detail }) => handleOnDidDismissScanOption(detail.data?.action),
    });
  };

  return (
    <IonPage>
      <FosToolbar title={equipmentsSubset[0]?.itemClass?.value} />
      <IonToolbar className='equipment-custom-ion-toolbar'>
        <div className="ion-padding-horizontal equipment-toolbar">
          <SearchInput id="search-equipment-bar" debounce={1000} onSearch={onSearchTerm} />
          <FosButtonIcon id='equipmentFilterModal' icon={filterOutline} />
          {isPlatform(HYBRID) && <FosButtonIcon icon={barcode} style={FosButtonIconStyle.Orange} onClick={showScanOptionsSheet} />}
          <FosButtonIcon icon={add} style={FosButtonIconStyle.Blue} onClick={onAddClick} />
        </div>
      </IonToolbar>
      <IonContent className='equipment-body'>
        <IonGrid>
          <IonList lines='none'>
          {equipmentsSubset.length === 0 && !isFetching ?
              <p className="ion-text-center">{t('noRecord').toString()}</p> : 
            <IonRow>
              {equipmentsSubset.map((equipment, index) => (
                  <IonCol
                    key={`${equipment.itemNumber + index}`}
                    className='ion-padding-horizontal equipment-list-item-padding-bottom'
                    size="12"
                    size-md="6"
                    size-lg="4">
                    <FosCard
                      status={equipment.status.value}
                      title={`${equipment.itemClass.value} - ${equipment.itemModel.value}`}
                      subtitle={equipment.itemNumber}
                      location={equipment.branch.value}
                      leftButtonLabel={t('status').toString()}
                      rightButtonLabel={t('edit').toString()}
                      hasMinHeight={false}
                      onLeftButtonClick={() => onStatusCLick(equipment)}
                      onRightButtonClick={() => onEditClick(equipment.id)}
                      onClick={() => handleItemSelection(equipment)} />
                  </IonCol>
              ))}
            </IonRow>}
          </IonList>
        </IonGrid>
        <IonLoading isOpen={isSaving} message={t('saving')} duration={2000} />
        <IonLoading isOpen={isFetching} message={t('loading')} duration={2000} />
      </IonContent>
      <FilterModal
        ref={filterModal}
        trigger="equipmentFilterModal"
        onClear={handleClearFilter}
        onClose={dismissModal}
        onShowResults={handleShowResults}>
        <>
          <FosMultiSelectItem
            title={t('itemModel').toString()}
            options={equipmentModelsOptions}
            value={selectedModel}
            onChange={handleModelChange} />
          <FosMultiSelectItem
            title={t('branch').toString()}
            options={equipmentBranchesOptions}
            value={selectedBranch}
            onChange={handleBranchChange} />
          <FosMultiSelectItem
            title={t('status').toString()}
            options={equipmentStatusOptions}
            value={selectedStatus}
            onChange={handleStatusChange} />
        </>
      </FilterModal>
      <IonToast
        isOpen={!!updateError}
        message={updateError}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => { setUpdateError(''); }} />
      <Toaster
        containerStyle={{
          top: 50,
          left: 20,
          bottom: 20,
          right: 20,
        }} />
    </IonPage>
  );
};

export default EquipmentsByClasses;
