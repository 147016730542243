import { useContext, useState } from 'react';
import { UserState } from './authUtils';

import { DomainParams } from './graphQLClientUtil';
import NetworkContext from '../contexts/NetworkContext';
import useBaseQuery from './baseQuery';
import GET_USER_EXPENSES_FOR_APPROVAL_SUMMARY, { UserExpenseForApprovalSummaryType } from '../graphql/GetUserExpenseForApprovalSummary';

const useUserExpenseForApprovalSummary = (userState: UserState, region: number, userGuid: string) => {
  const network = useContext(NetworkContext);
  const [userExpenseForApprovalSummary, setUserExpenseForApprovalSummary] = useState<UserExpenseForApprovalSummaryType[]>();

  const onSuccess = async (data: any) => {
    const expenseSummary: UserExpenseForApprovalSummaryType[]  = data?.expensesForMyApprovalSummary || [];
    setUserExpenseForApprovalSummary(expenseSummary);
  };

  const { isLoading, isFetching } = useBaseQuery(
    region,
    { regionId: region, userGuid },
    userState,
    network.connected,
    GET_USER_EXPENSES_FOR_APPROVAL_SUMMARY,
    ['useUserExpenseForApprovalSummary'],
    DomainParams.Payroll,
    onSuccess,
  );

  return { userExpenseForApprovalSummary, isLoading, isFetching };
};

export default useUserExpenseForApprovalSummary;
