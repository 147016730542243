/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonSelectOption,
} from '@ionic/react';
import type { CheckboxCustomEvent } from '@ionic/react';
import { Virtuoso } from 'react-virtuoso';
import { t } from 'i18next';

export type SelectOptionProps = React.ComponentProps<typeof IonSelectOption> & { label: string };

export interface Item {
  text: string;
  value: string;
}

interface TypeaheadProps {
  items: SelectOptionProps[];
  title?: string;
  name?: string;
  okText?: string;
  cancelText?: string;
  value?: string;
  onSelectionCancel?: () => void;
  onSelectionChange?: (item: string) => void;
  onSearchInput?: (input: string) => any;
}

const FosSelectItemWithVirtualScroll  = ({
  items,
  title,
  name,
  okText,
  cancelText,
  value,
  onSelectionCancel,
  onSelectionChange,
  onSearchInput,
} : TypeaheadProps) => {
  const [filteredItems, setFilteredItems] = useState<SelectOptionProps[]>([...items]);
  const [selectedValue, setSelectedValue] = useState<string>(value || '');

  const isChecked = (localValue: string) => selectedValue === localValue;

  const cancelChanges = () => {
    if (onSelectionCancel !== undefined) {
      onSelectionCancel();
    }
  };

  const confirmChanges = (selected: string) => {
    if (onSelectionChange !== undefined) {
      onSelectionChange(selected);
    }
  };

  const filterList = (searchQuery: string | null | undefined) => {
    if (!searchQuery) {
      setFilteredItems([...items]);
    } else {
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredItems(items.filter(item => item.label.toLowerCase().includes(normalizedQuery)));
    }
  };

  const searchbarInput = (ev: any) => {
    filterList(ev.target.value);
    if (onSearchInput) onSearchInput(ev.target.value);
  };

  const checkboxChange = (ev: CheckboxCustomEvent) => {
    const { checked, value: localValue } = ev.detail;

    if (checked) {
      setSelectedValue(localValue);
    } else {
      setSelectedValue('');
    }
    confirmChanges(checked ? localValue : '');
  };

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  interface ImStatelessProps {
    index: number;
  }

  const ImStateless = ({ index }: ImStatelessProps) => (
    <div>
      {filteredItems[index] && 
      <IonItem key={filteredItems[index].value}>
        <IonLabel>{filteredItems[index].label}</IonLabel>
        <IonCheckbox
          name={name}
          value={filteredItems[index].value}
          checked={isChecked(filteredItems[index].value)}
          onIonChange={checkboxChange}
          />
      </IonItem>
      }
    </div>
  );
  const ImStatelessComponent = React.memo(ImStateless);

  const itemContent = (index: number) => <ImStatelessComponent index={index} />;

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancelChanges}>{cancelText}</IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => confirmChanges(selectedValue)}>{okText}</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar debounce={1000} onIonChange={searchbarInput} />
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding" style={{ '--overflow': 'hidden' }}>
        <IonList id="modal-list" inset style={{ height: '100%', marginTop: 0, marginBottom: 0 }}>
          {filteredItems.length > 0 ? 
            <Virtuoso totalCount={filteredItems.length} itemContent={itemContent} style={{ height: '100%' }} />
            : <IonLabel class="ion-padding">{t('noResultFound').toString()}</IonLabel>
          }
        </IonList>
      </IonContent>
    </>
  );
};
export default FosSelectItemWithVirtualScroll;