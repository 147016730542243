/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { IonIcon } from '@ionic/react';
import './ButtonIcon.css';

type Props = {
  icon?: string;
  onPress?: () => void;
  containerStyle?: React.CSSProperties;
  id?: string;
  hide?: boolean;
};

type StyleType = {
  container: React.CSSProperties;
  icon: React.CSSProperties;
};

const styles: StyleType = {
  container: {
    width: 54,
    height: 46,
    background: '#FFFFFF',
    border: '1px solid #E7E7EF',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 'none',
  },
  icon: {
    color: '#3D3D3D',
    width: 25,
    height: 25,
  },
};

const ButtonIcon: FC<Props> = ({ icon, onPress, containerStyle = {}, id, hide }) => (
  <button
    style={{ ...styles.container, ...containerStyle }}
    onClick={onPress}
    type="button"
    className={`button-icon ${hide ? 'hide-button' : ''}`}
    id={id}
  >
    <IonIcon icon={icon} style={styles.icon} />
  </button>
);

export default ButtonIcon;
