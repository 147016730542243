import React, { FC } from 'react';
import {
  Drawing,
  Ellipse,
  Rect,
  SketchImage,
  SketchObject,
  SketchObjectExport,
  SketchObjectType,
  Text,
  Wall,
} from '../sketch-tool';
import SketchToolRectEdit from './SketchToolRectEdit';
import SketchToolEllipseEdit from './SketchToolEllipseEdit';
import SketchToolImageEdit from './SketchToolImageEdit';
import SketchToolTextEdit from './SketchToolTextEdit';
import SketchToolDrawingEdit from './SketchToolDrawingEdit';
import WallEdit from './SketchToolWallEdit';

export interface ObjectEditProps {
  object?: SketchObject;
  isOpen: boolean;
  onClose: (event: any) => any;
}

const SketchToolObjectEdit: FC<ObjectEditProps> = ({ object, isOpen, onClose }) => {
  const e = object?.export() as SketchObjectExport;
  switch (e?.type) {
    case SketchObjectType.Rect:
      return <SketchToolRectEdit object={object as Rect} isOpen={isOpen} onClose={onClose} />;
    case SketchObjectType.Ellipse:
      return <SketchToolEllipseEdit object={object as Ellipse} isOpen={isOpen} onClose={onClose} />;
    case SketchObjectType.Image:
      return <SketchToolImageEdit object={object as SketchImage} isOpen={isOpen} onClose={onClose} />;
    case SketchObjectType.Text:
      return <SketchToolTextEdit object={object as Text} isOpen={isOpen} onClose={onClose} />;
    case SketchObjectType.Wall:
      return <WallEdit object={object as Wall} isOpen={isOpen} onClose={onClose} />;
    case SketchObjectType.Drawing:
      return <SketchToolDrawingEdit object={object as Drawing} isOpen={isOpen} onClose={onClose} />;
    default:
      return null;
  }
};

export default SketchToolObjectEdit;
