import { StorageRecord } from '../../utils/SaveStorage';
import { Option } from '../../utils/Interfaces';
import { ClaimOnlineStatus, PhaseStatus } from './Const';
import { PhaseListType } from './PhaseList';
import { User } from '../../graphql/GetUser';
import { DefaultPhaseIds } from './AddPhaseActionSheetConst';

type FormatDataParameter = {
  data: any,
  phaseList: PhaseListType[],
  user: User | undefined,
  claimIndx: number,
  lossAddressLat: number | undefined,
  lossAddressLon: number | undefined,
};

export const formatDataForm = ({
  data,
  phaseList,
  user,
  claimIndx,
  lossAddressLat,
  lossAddressLon,
}: FormatDataParameter) => {
  const formClaim: any = { ...data };

  // edit
  if (claimIndx) {
    formClaim.claimIndx = claimIndx;
  } else {
    formClaim.phases = phaseList.map(phaseData => ({
      claimIndx: 0, // The API requires this value but we do not have it yet. Set it to 0 as suggested by Daniel
      phaseIndx: 0, // The API requires this value but we do not have it yet. Set it to 0 as suggested by Daniel
      description: phaseData.description,
      estValue: phaseData.estValue,
      phaseId: phaseData.phaseId,
      openedBy: user?.firstName && user?.lastName ? `${user?.firstName[0]}.${user?.lastName}` : '',
      pmId: phaseData.projectManagerId || data.jobPcoord,
      status: phaseData.phaseId === DefaultPhaseIds.EMERGENCY ? PhaseStatus.NewA : PhaseStatus.New,
    }));
  }

  formClaim.dedAmt = Number.parseFloat(formClaim.dedAmt);
  if (Number.isNaN(formClaim.dedAmt)) {
    delete formClaim.dedAmt;
  }

  formClaim.lossCat = Number.parseInt(formClaim.lossCat, 10);
  if (Number.isNaN(formClaim.lossCat)) {
    delete formClaim.lossCat;
  }

  if (formClaim.contactPhone) {
    // The API does not accept dashes ("-") inside the phone number
    formClaim.contactPhone = formClaim.contactPhone?.replace(/-/g, '');
  }

  formClaim.lossAddressLon = lossAddressLon;
  formClaim.lossAddressLat = lossAddressLat;
  return formClaim;
};

export const formatOfflineClaim = (
  offlineClaim: any, 
  projectManagersOptions: Option[],
  adjusterOptions: Option[],
  insuranceCompaniesOptions: Option[],
) => {
  const { data: { claim } }: any = offlineClaim;
  const fullYear = new Date().getFullYear();
  const twoDigitYear = fullYear.toString().slice(-2);
  claim.claimNumber = claim.claimNumber ? claim.claimNumber : `${claim.brId}${twoDigitYear}${claim.jobPcoord}`;
  claim.pmnameClaim = projectManagersOptions?.find(pm => pm.value === claim.jobPcoord)?.label || '';
  claim.adjuster = adjusterOptions?.find(manager => manager.value === claim.adjCode)?.label || '';
  claim.insurer = insuranceCompaniesOptions?.find(insurer => insurer.value === claim.claimCo)?.label || '';
  claim.status = !claim.status ? ClaimOnlineStatus.ToBeSynched : claim.status;
  claim.claimIndx = offlineClaim.primaryKey;
  return claim;
};

export const getClaimStorage = async (
  jobIdx: string,
  offlineClaims: StorageRecord[],
  projectManagersOptions: Option[],
  adjusterOptions: Option[],
  insuranceCompaniesOptions: Option[],
) => {
  const offlineClaim = offlineClaims.find(c => c.primaryKey === Number(jobIdx));
  if (!offlineClaim?.data?.claim) {
    return { claimForm: undefined, operation: undefined };
  }
  const claimForm = formatOfflineClaim(
    offlineClaim,
    projectManagersOptions,
    adjusterOptions,
    insuranceCompaniesOptions,
  );
  return { claimForm, operation: offlineClaim.data.operation };
};