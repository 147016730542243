import { gql } from '@apollo/client';

export type ClaimDocumentsType = {
  ctfileId: number,
  filePath : string,
  thumbnailFileName : string,
  areaOfLoss : string,
  uploadedDate: string,
  phase: string,
  fileMetaData: string,
  sentToXactEm: string,
  sentToXactRe: string,
  isCustomerVisible: boolean,
  fileName: string,
  fileSize: number,
  fileTypeCd: string,
  fileExtension: string,
  modifiedDate: string;
  description: string,
  xactimate?: boolean,
  isSymbilityDoc?: boolean,
};

const GET_CLAIM_DOCUMENTS_LIST = gql`
query claimDocuments ($regionId: Int!, $claimIndx: Int!) {
  claimDocuments(regionId: $regionId, claimIndx: $claimIndx)
  {
    ctfileId
    filePath 
    thumbnailFileName
    areaOfLoss
    uploadedDate
    modifiedDate
    phase
    fileMetaData
    sentToXactEm
    sentToXactRe
    isCustomerVisible
    fileName
    fileSize
    fileTypeCd
    fileExtension
    description
    xactimate
    isSymbilityDoc
  }
}`;

export default GET_CLAIM_DOCUMENTS_LIST;