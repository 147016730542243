import { gql } from '@apollo/client';

const SAVE_CLAIM = gql`
mutation SaveClaim($regionId: Int!, $claim: SaveClaimRequestInput!, $xactTransactionId: String) {
  saveClaim(regionId: $regionId, claim: $claim, xactTransactionId: $xactTransactionId)
  {
    itemId
    status
    message
  }
}`;

export default SAVE_CLAIM;
