/* eslint-disable @typescript-eslint/no-use-before-define */
import { useIonRouter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useAuth } from '../../../../../hooks/authContext';
import useEquipmentsByClasses from '../../../data/hooks/useEquipmentsByClasses';
import { EquipmentsByClassesType } from '../../../data/graphql/GetEquipmentsByClasses';
import { ScanEquipmentAction, ScanEquipmentOption } from '../../../util/EquipmentUtil';
import useFilterAndSearchEquipments from '../../../data/hooks/useFilterAndSearchEquipments';
import { EquipmentsType } from '../../../data/graphql/GetEquipmentsList';
import { Option } from '../../../../../utils/Interfaces';
import useStorage from '../../../../../hooks/storage';
import useEquipmentModels from '../../../data/hooks/useEquipmentModels';
import useEquipmentStatus from '../../../data/hooks/useEquipmentStatus';
import useEquipmentBranches from '../../../data/hooks/useEquipmentBranches';

const EquipmentViewModel = () => {
  const navigation = useIonRouter();
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const initFilter = { };
  const [filter, setFilter] = useState<any>({});
  const [equipByClassError, setEquipByClassError] = useState(false);
  const { equipmentsByClassesList, refetch, error } = useEquipmentsByClasses(regionId, userState, { regionId, filter: Object.keys(filter).length === 0 ? { active: true } : filter }, !equipByClassError);
  const [equipmentClasses, setEquipmentsClasses] = useState<EquipmentsByClassesType[]>([]);
  const [xssErrorEquipment, setXssErrorEquipment] = useState(false);
  const { equipmentsList, isFetching, error: errorEquipment } = useFilterAndSearchEquipments(regionId, userState, xssErrorEquipment ? 1 : null, { regionId, filter: Object.keys(filter).length === 0 ? { active: true } : filter });
  const equipmentsSubset: EquipmentsType[] = equipmentsList.slice(0, 100);
  const [isFilteredOrSearched, setIsFilteredOrSearched] = useState(false);

  const [selectedModel, setSelectedModel] = useState<Option[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Option[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Option[]>([]);
  
  const equipmentModels = useEquipmentModels(regionId, userState, useStorage());
  const equipmentModelsOptions = equipmentModels.map(model => ({ label: model.model, value: String(model.modelIndx) }));
  const equipmentBranches = useEquipmentBranches(regionId, userState, useStorage());
  const equipmentBranchesOptions = equipmentBranches.map(branch => ({ label: branch.value, value: branch.id }));
  const equipmentStatus = useEquipmentStatus(regionId, userState, useStorage());
  const equipmentStatusOptions = equipmentStatus.map(status => ({ label: status.value, value: status.id }));

  useEffect(() => {
    if (error?.response?.ErrorCode === 400 && !equipByClassError) {
      setEquipByClassError(true);
    }
  }, [error?.response?.ErrorCode, equipByClassError]);

  useEffect(() => {
    if (errorEquipment?.response?.ErrorCode === 400 && !xssErrorEquipment) {
      setXssErrorEquipment(true);
    }
  }, [errorEquipment?.response?.ErrorCode, xssErrorEquipment]);

  const scanActionSheetButtons = [
    {
      text: t('single'),
      data: {
        action: ScanEquipmentAction.Single,
      },
    },
    {
      text: t('batch'),
      data: {
        action: ScanEquipmentAction.Batch,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: ScanEquipmentAction.Cancel,
      },
    },
  ];

  useEffect(() => {
    if (!isFetching) {
      setEquipmentsClasses(equipmentsByClassesList);
    }
  }, [equipmentsByClassesList, isFetching]);

  const handleOnDidDismissScan = (action: any) => {
    switch (action) {
      case ScanEquipmentAction.Single: {
        navigateToScanEquipment(ScanEquipmentOption.Single);
        break;
      }
      case ScanEquipmentAction.Batch: {
        navigateToScanBatchOptions();
        break;
      }
      case ScanEquipmentAction.Cancel:
      default: break;
    }
  };

  const onSearchTerm = (term: string) => {
    const newFilter = { ...filter };
    newFilter.searchStr = term;
    onFilterChange(newFilter);
  };

  const onFilterChange = (newFilter: any) => {
    setFilter(newFilter);
    setXssErrorEquipment(false);
    setEquipByClassError(false);
    setIsFilteredOrSearched(Object.keys(newFilter).some(key => newFilter[key] && key !== 'active'));
  };

  const clearFilter = () => {
    setSelectedModel([]);
    setSelectedBranch([]);
    setSelectedStatus([]);
    onFilterChange(initFilter);
    setIsFilteredOrSearched(false);
  };

  const showResults = () => {
    onFilterChange(filter);
  };

  const handleModelChange = (selectedOptions: Option[]) => {
    setSelectedModel(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions.length > 0) {
      newFilter.modelIds = selectedOptions.map(o => Number(o.value));
    } else {
      newFilter.modelIds = undefined;      
    }
    setFilter(newFilter);
    setXssErrorEquipment(false);
    setEquipByClassError(false);
  };

  const handleBranchChange = (selectedOptions: Option[]) => {
    setSelectedBranch(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions.length > 0) {
      newFilter.branchIds = selectedOptions.map(o => o.value);
    } else {
      newFilter.branchIds = undefined;      
    }
    setFilter(newFilter);
  };

  const handleStatusChange = (selectedOptions: Option[]) => {
    setSelectedStatus(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions.length > 0) {
      newFilter.statusIds = selectedOptions.map(o => Number(o.value));
    } else {
      newFilter.statusIds = undefined;      
    }
    setFilter(newFilter);
  };

  const navigateToEquipmentsByClass = (equipmentClassId: number) => navigation.push(`/equipments-classes/${equipmentClassId}`);

  const navigateToScanEquipment = (scanType: string) => navigation.push(`/scan-equipment/${scanType}`);

  const navigateToScanBatchOptions = () => navigation.push('/scan-batch-options');

  const navigateToAddEquipment = () => navigation.push('/equipment-edition/undefined');

  return {
    isFetching,
    error,
    equipByClassError,
    xssErrorEquipment,
    equipmentClasses,
    scanActionSheetButtons,
    selectedModel,
    selectedBranch,
    selectedStatus,
    equipmentBranchesOptions,
    equipmentStatusOptions,
    equipmentModelsOptions,
    equipmentsSubset,
    isFilteredOrSearched,
    showResults,
    clearFilter,
    handleModelChange,
    handleBranchChange,
    handleStatusChange,
    onFilterChange,
    handleOnDidDismissScan,
    onSearchTerm,
    refetch,
    navigateToEquipmentsByClass,
    navigateToScanEquipment,
    navigateToAddEquipment,
  };

};

export default EquipmentViewModel;
