let refetchKey = Date.now();

export function updateRefetchKey() {
  refetchKey = Date.now();
}

export function getRefetchKey() {
  return refetchKey;
}

export default { updateRefetchKey, refetchKey };
