import { Storage } from '@ionic/storage';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseCacheAndList from './baseCacheAndList';
import { VerticalType } from '../pages/helper/VerticalType';
import GET_VERTICALS_LIST from '../graphql/GetVerticalsList';
import { DomainParams } from './graphQLClientUtil';

const useVerticals = (userState: UserState, storage: Storage) => {
  const verticalsList:VerticalType[] = useBaseCacheAndList(0, userState, storage, GET_VERTICALS_LIST, claimsKeys.verticalsList(), 
    'claimVerticals',
    DomainParams.Common);
  return verticalsList;
};

export default useVerticals;