import { gql } from '@apollo/client';

const GET_JOB_COST_TYPES = gql`
query jobCostTypes($regionId: Int!) {
    jobCostTypes(regionId: $regionId)
  {
    id
    code
    parentId
    value
  }
}`;

export default GET_JOB_COST_TYPES;