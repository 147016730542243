import { gql } from '@apollo/client';

const GET_CLAIM_DETAILS = gql`
  query claim($regionId: Int!, $claimIndx: Int!) {
    claim(regionId: $regionId, claimIndx: $claimIndx) { 
      claimIndx 
      claimNumber 
      claimNo
      policyNo
      lossDate 
      adjuster 
      contactName 
      contactPhone 
      projEmail 
      projEmail2 
      lossType 
      lossDesc 
      jobDateOpen 
      completionDate 
      insurer 
      projAddr
      projCity 
      projProv 
      projZip 
      yearOfConstruction
      projMngrClaimPhone
      contactPhone
      lossAddressLat
      lossAddressLon
      pmNameClaim
      projName
      propertyManager
      calledIn
      dedAmt
      lossCat
      constructionManagerId
      constructionManager
      pmEmail
      verticalId
      verticalName
      projPhone1
      projPhone2
      projPhone3
      phases {
        phaseIndx
        description
        status
        statusDesc
        phaseCode
        dateOpened
        dateClosed
        estValue
        linkedToSymbility
        linkedToXA
        linkedToEncircle
        phaseId
      }
    } 
  }`;

export default GET_CLAIM_DETAILS;