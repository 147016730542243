export enum DocType {
  PDF = 'pdf',
  Word = 'doc',
  Wordx = 'docx',
  Excel = 'xls',
  Excelx = 'xlsx',
  Txt = 'txt',
}

const getDocIcon = (docType: string) => {
  switch (docType) {
    case DocType.PDF: return 'assets/images/icons/ic_doc_pdf.png';
    
    case DocType.Wordx:
    case DocType.Word: return 'assets/images/icons/ic_doc_word.png';
    
    case DocType.Excelx:
    case DocType.Excel: return 'assets/images/icons/ic_doc_excel.png';
    
    case DocType.Txt: return 'assets/images/icons/ic_doc_txt.png';
    default: return 'assets/images/icons/ic_doc_txt.png';
  }
};
export default getDocIcon;