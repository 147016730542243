import { IonLabel, IonList } from '@ionic/react';
import React, { useEffect } from 'react';
import './FosTextArea.css';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';

export interface Props {
  label?: string;
  name: string;
  control: any;
  readonly?: boolean;
  hideToolBar?: boolean;
  onInputChange?: () => any;
}

const FosFormatTextArea = ({ label, name, control, readonly, hideToolBar, onInputChange }: Props) => {
  useEffect(() => {
    const addScrollEvent = () => {
      document?.activeElement?.scrollIntoView(true);
    };
    window.addEventListener('keyboardDidShow', addScrollEvent);
    return () => {
      window.removeEventListener('keyboardDidShow', addScrollEvent);
    };
  }, []);
  
  return (
    <IonList lines='none' className='input-text-area'>
      <IonLabel>{label}</IonLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: true,
        }}
        render={({ field: { value, onChange, ref } }) => (
          <ReactQuill
            ref={ref}
            className={hideToolBar ? 'no-format-toolbar' : ''}
            onFocus={() => window.scrollTo({ top: 50 })}
            theme="snow"
            readOnly={readonly}
            onChange={(description) => {
              onChange(description);
              if (onInputChange) {
                onInputChange();
              }
            }}            
            value={value || ''}
          />
        )}
      />
    </IonList>
  );
};

export default FosFormatTextArea;
