import { gql } from '@apollo/client';

export type UserExpenseForApprovalSummaryType = {
  id: number,
  name: string,
  endDate: string,
  expenses: number,
  expensesCount: number,
  expenseMethodType: string,
};

const GET_USER_EXPENSES_FOR_APPROVAL_SUMMARY = gql`
query expensesForMyApprovalSummary ($userGuid: UUID!) {
  expensesForMyApprovalSummary (employeeGuid: $userGuid) {
    id
    name
    endDate
    expenses
    expensesCount
    expenseMethodType
  }
}`;

export default GET_USER_EXPENSES_FOR_APPROVAL_SUMMARY;