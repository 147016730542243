import { useContext, useEffect } from 'react';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseQuery from './baseQuery';
import GET_CLAIM_PHOTOS_LIST, { ClaimPhotosType } from '../graphql/GetClaimPhotosList';
import { DomainParams } from './graphQLClientUtil';
import NetworkContext from '../contexts/NetworkContext';
import { ClaimOnlineStatus } from '../pages/helper/Const';
import { getClaimPhotos, getPhotosToBeAdded, storeClaimPhotos } from '../pages/helper/offlineHelper/ClaimPhotosHelper';

const useClaimPhotos = (
  region: number,
  claimIndx: number,
  userState: UserState,
  photosList?: ClaimPhotosType[],
  setPhotosList?: (_levelsList: ClaimPhotosType[]) => void,
  onSuccess?: (_data: any, claimIndx: number) => Promise<void>,
) => {
  const network = useContext(NetworkContext);
  const enabled = network.connected && !!claimIndx;

  const onSuccessPhotos = async (_data: any) => {
    if (_data?.claimPhotos?.length) {
      if (setPhotosList) {
        setPhotosList(_data?.claimPhotos);
      }
      await storeClaimPhotos({ photos: _data?.claimPhotos, claimIndx, status: ClaimOnlineStatus.AvailableOffline });
    }
    if (onSuccess) await onSuccess(_data, claimIndx);
  };

  const { data, isLoading, isFetching, refetch } = useBaseQuery(
    region,
    { claimIndx },
    userState,
    enabled,
    GET_CLAIM_PHOTOS_LIST,
    claimsKeys.claimPhotos(),
    DomainParams.Common,
    onSuccessPhotos,
  );

  useEffect(() => {
    if (!network.connected && photosList?.length === 0 && claimIndx) {
      const getAsync = async () => {
        const { photos: localClaimPhotos } = await getClaimPhotos(claimIndx);
        const photosToBeAdded = await getPhotosToBeAdded(claimIndx);
        if ((localClaimPhotos?.length || photosToBeAdded.length) && setPhotosList) {
          setPhotosList([...localClaimPhotos, ...photosToBeAdded]);
        }
      };
      getAsync();
    }
  }, [claimIndx, network.connected, photosList?.length, setPhotosList]);

  const refetchOffline = async () => {
    if (!network.connected && claimIndx) {
      const getAsync = async () => {
        const { photos: localClaimPhotos } = await getClaimPhotos(claimIndx);
        const photosToBeAdded = await getPhotosToBeAdded(claimIndx);
        if ((localClaimPhotos?.length || photosToBeAdded.length) && setPhotosList) {
          setPhotosList([...localClaimPhotos, ...photosToBeAdded]);
        }
      };
      getAsync();
    }
  };
  
  const claimPhotosList: ClaimPhotosType[] =
    !isLoading && !isFetching ? data?.claimPhotos || [] : [];
  return { claimPhotosList, isLoading, isFetching, refetch, refetchOffline };
};

export default useClaimPhotos;