import React, { FC } from 'react';
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonThumbnail,
} from '@ionic/react';
import { t } from 'i18next';
import { LibItem } from '../sketch-tool/object-lib';
import FosCachedImage from '../components/FosCachedImage';

interface SketchToolObjectLibProps {
  lib?: LibItem[];
  isOpen: boolean;
  onClose: (libObjectId?: number) => any;
}

const SketchToolObjectLib: FC<SketchToolObjectLibProps> = ({ lib, isOpen, onClose }) => (
  <IonModal
    isOpen={isOpen}
    initialBreakpoint={0.75}
    onIonModalDidDismiss={() => onClose()}
    breakpoints={[0, 0.25, 0.5, 0.75, 1]}>
    <IonContent className="ion-padding">
      <IonList>
        <IonListHeader>
          <IonLabel>{t('objects').toString()}</IonLabel>
        </IonListHeader>

        {lib?.map(obj => (
          <IonItem key={obj.id} onClick={() => onClose(obj.id)}>
            <IonThumbnail>
              <FosCachedImage src={obj.thumbUrl} />
            </IonThumbnail>
            <IonLabel>{obj.name}</IonLabel>
          </IonItem>
        ))}
      </IonList>
    </IonContent>
  </IonModal>
);

export default SketchToolObjectLib;
