import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ellipsisHorizontal, locationOutline } from 'ionicons/icons';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonLoading,
  useIonActionSheet,
  useIonRouter,
  useIonAlert,
} from '@ionic/react';
import { Toaster, toast } from 'react-hot-toast';

import getRestClient, { ClientType } from '../utils/AxiosClient';
import { ICC_URL } from '../utils/Env';
import { useAuth } from '../hooks/authContext';

import { Phase } from '../types/Phases';
import FosFooterButton from '../atom/FosFooterButton';
import './ClaimDetailsInfo.css';
import addCurrencyMask from '../utils/CurrencyMask';
import FosCachedImage from '../components/FosCachedImage';
import ClaimStorageInfo from './ClaimStorageInfo';
import { PermissionsList, usePermissions } from '../hooks/permissions';

import { ReactComponent as EncircleIcon } from '../assets/icons/ic_encircle.svg';
import { ClaimActionSheet } from '../pages/helper/EncircleClaimRequest';
import { ENCIRCLE_CLAIM_ROOT_URL, ClaimType, Region } from '../pages/helper/Const';
import openDocumentURL from '../pages/helper/Documents';

const phoneIcon = 'assets/images/icons/ic_orange_phone.png';

type Props = {
  activePhase: Phase | null;
  isLoading: boolean;
  claim: any;
  region: number;
  isOffline?: boolean;
  statusDesc: string;
  handleMapButtonClick: (ev: React.MouseEvent<Element, MouseEvent>) => void;
  handleCallButtonClick: (ev: React.MouseEvent<Element, MouseEvent>) => void;
};

const ClaimDetailsInfo: React.FC<Props> = ({
  activePhase,
  isLoading,
  claim,
  region,
  isOffline,
  statusDesc,
  handleMapButtonClick,
  handleCallButtonClick,
}) => {
  const { t } = useTranslation();
  const navigation = useIonRouter();
  const [showStorageInfo, setShowStorageInfo] = useState(false);
  const [showEncircleIcon, setShowEncircleIcon] = useState(false);
  const [encircleId, setEncircleId] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isinternalLoading, setIsinternalLoading] = useState(false);
  const xactimateIcon = 'assets/images/icons/ic_xactimate.png';

  const { permissions } = usePermissions();
  const isEditClaimsEnabled = permissions.includes(PermissionsList.EditClaims);
  const { userState, user } = useAuth()!;

  const [presentAlert] = useIonAlert();
  const [present] = useIonActionSheet();

  const initEncircle = useCallback(
    async (claim: any) => {
      try {
        if (claim) {
          const restClient = getRestClient(userState, ClientType.PLAIN);
          const res = await restClient.get(`/ihub/claims/${region}/${claim?.claimIndx}`);

          if (res.status === 200) {
            if (res?.data?.claimNumber) {
              setShowEncircleIcon(true);
              setEncircleId(res?.data?.claimNumber);
            }
          }
        }
      } catch (error: any) {
        setEncircleId('');
      }
    },
    [region, userState],
  );

  useEffect(() => {
    initEncircle(claim);
    if (showErrorToast) {
      toast.error(errorMessage, { duration: 4000 });
      setShowErrorToast(false);
    } else if (showSuccessToast) {
      toast.success(t('saveEncircleClaimSuccess'), { duration: 4000 });
      setShowSuccessToast(false);
    }
  }, [
    setShowErrorToast,
    setShowSuccessToast,
    setErrorMessage,
    initEncircle,
    claim,
    showErrorToast,
    showSuccessToast,
    errorMessage,
    t,
  ]);

  const pushToEncircle = async (claimRequest: any, regionId: string, sendToCommercial?: boolean) => {
    try {
      setIsSaving(true);
      const restClient = getRestClient(userState, ClientType.FORM);
      const res = await restClient.post('/ihub/claims/encircle', {
        claimIndx: claimRequest.claimIndx,
        regionId,
        typeOfLoss: claimRequest.lossType,
        assignmentIdentifier: claimRequest.claimNumber,
        insuranceCompanyName: claimRequest.insurer,
        dateOfLoss: claimRequest.lossDate,
        projectManagerName: claimRequest.pmNameClaim,
        adjusterName: claimRequest.adjusterName,
        fullAddress: [claimRequest?.projAddr, claimRequest?.projCity, claimRequest?.projProv]
          .filter(Boolean)
          .join(', '),
        lossDetails: claimRequest.lossDesc,
        policyholderEmailAddress: claimRequest.projEmail,
        policyholderName: claimRequest.projName,
        catCode: claimRequest.lossCat,
        userGUID: user?.userGuid,
        sendToCommercial,
      });

      if (res.status === 200) {
        setShowSuccessToast(true);
      } else {
        setShowErrorToast(true);
        setErrorMessage(t('saveEncircleClaimError'));
      }
    } catch (error: any) {
      setShowErrorToast(true);
      const errorResponse = error?.response?.data ?? t('saveEncircleClaimError');
      setErrorMessage(errorResponse);
    } finally {
      setIsSaving(false);
    }
  };

  const openXaLink = async () => {
    const restClient = getRestClient(userState, ClientType.JSON);
    try {
      restClient
        .get('/common/xacts/get-xact-info', {
          params: {
            regionId: region,
            claimIndx: claim.claimIndx,
            phaseIndx: activePhase?.phaseIndx,
          },
        })
        .then((res) => {
          openDocumentURL(
            `https://xactanalysis.com/apps/cxa/detail.jsp?mfn=${res.data.xactTransactionId}#d_assignment`,
          );
        });
    } catch {
      // TODO: better error handling
      // eslint-disable-next-line no-console
      console.log('no xact record');
    }
  };

  const getIccToken = async (claimNumber: string) => {
    try {
      setIsinternalLoading(true);
      const restClient = getRestClient(userState, ClientType.PLAIN);
      const result = await restClient.get(`/common/claims/icc-token/${claimNumber}`);

      if (result.status === 200) {
        return result.data;
      }
      return null;
    } catch (error: any) {
      setShowErrorToast(true);
      const errorResponse = error?.response?.data ?? t('openIccError');
      setErrorMessage(errorResponse);
    } finally {
      setIsinternalLoading(false);
    }
  };

  const setEncircleCommercialHub = () => {
    presentAlert({
      header: t('sendToCommercialHeader'),
      message: t('sendToCommercialContent'),
      buttons: [
        {
          handler: () => {
            pushToEncircle(claim, region.toString(), true);
          },
          text: t('yes'),
        },
        {
          handler: () => {
            pushToEncircle(claim, region.toString(), false);
          },
          text: t('no'),
          cssClass: 'encircle-dialog-buttons',
        },
      ],
    });
  };

  const openIcc = async (claim: any, isRoofing?: boolean) => {
    const token = await getIccToken(claim.claimNumber);
  
    // Base URL for ICC
    const baseURL = `${ICC_URL}`;
  
    // Initialize URLSearchParams with mandatory parameters
    const params = new URLSearchParams({      
      fos_partnerid: '5', // Always 5 for FOS
      type_id: '1', // Always 1 for claims
      fos_token_id: token || '',
      fos_claim_id: claim.claimNumber || '',
      fullname: user?.fullName || '',
      city: claim.projCity || '',
    });

    if (user?.username) {
      params.append('user_id', `${user.username}@firstonsite.ca`);
    }
  
    // Add additional parameter for roofing if needed
    if (isRoofing) {
      params.append('r', 'true');
    }
  
    // Construct full URL
    const url = `${baseURL}?${params.toString()}`;
    
    await openDocumentURL(url);
  };
  

  const setIccEstimate = async (claim: any) => {
    presentAlert({
      header: t('setIccEstimateHeader'),
      message: t('setIccEstimateContent'),
      buttons: [
        {
          handler: async () => {
            await openIcc(claim);
          },
          text: t('iccFlooring'),
        },
        {
          handler: async () => {
            await openIcc(claim, true);
          },
          text: t('iccRoofing'),
          cssClass: 'encircle-dialog-buttons',
        },
      ],
    });
  };  

  const handlePushToEncircle = async (claimRequest: any, regionId: string) => {
    if (claimRequest.lossCat === ClaimType.Commercial && !claimRequest.claimNumber.startsWith('Z')) {
      setEncircleCommercialHub();
    } else {
      await pushToEncircle(claimRequest, regionId, false);
    }
  };

  const handleJobActionSheet = ({ claim, regionId, externalId }: ClaimActionSheet) => {
    present({
      header: t('claimOptions'),
      cssClass: 'custom-action-sheet',
      buttons: [
        {
          text: t('pushToEncircle'),
          data: {
            claimIndx: claim.claimIndx,
            regionId,
            typeOfLoss: claim?.typeOfLoss,
            assignmentIdentifier: claim.assignmentIdentifier,
            dateOfLoss: claim.dateOfLoss,
            insuranceCompanyName: claim.insuranceCompanyName,
            projectManagerName: claim.projectManagerName,
            adjusterName: claim.adjusterName,
            fullAddress: claim.fullAddress,
            lossDetails: claim.lossDetails,
            policyholderEmailAddress: claim.policyholderEmailAddress,
            policyholderName: claim.policyholderName,
            catCode: claim.catCode,
            externalId: !externalId,
          },
          handler: () => {
            handlePushToEncircle(claim, regionId);
          },
        },
        {
          text: t('openInEncircle'),
          data: {
            externalId,
          },
          handler: () => {
            const aDocumentElement = document.createElement('a');
            aDocumentElement.href = `${ENCIRCLE_CLAIM_ROOT_URL}/${externalId}/overview`;
            aDocumentElement.target = '_blank';
            aDocumentElement.click();
          },
        },
        {
          text: t('openIcc'),
          data: {
            claim,
          },
          handler: async () => {
            await setIccEstimate(claim);
          },
        },
        {
          text: t('cancel'),
          role: 'destructive',
          data: {
            action: 'cancel',
          },
        },
      ].filter(btn => {
        const regions = [
          Region.BritishColumbia,
          Region.Quebec,
          Region.Atlantic,
          Region.Prairies,
          Region.Alberta,
        ];
  
        const showEncircleButtons = (
          (claim.lossCat === ClaimType.Commercial && +regionId === Region.Ontario) ||
          regions.includes(+regionId)
        );
  
        if (btn.data.action === 'cancel') {
          return true;
        }
  
        if (btn.text === t('openInEncircle') && externalId && showEncircleButtons) {
          return true;
        }
  
        if (btn.text === t('pushToEncircle') && !externalId && showEncircleButtons) {
          return true;
        }
  
        if (btn.text === t('openIcc') && (+regionId === Region.Ontario || regions.includes(+regionId))) {
          return true;
        }
  
        return false;
      }),
    });
  };   

  return (
    <>
      <IonContent fullscreen className="claim-details-content">
        {isLoading && <div className="ion-padding">{t('loading').toString()}...</div>}
        {isinternalLoading && <div className="ion-padding">{t('loading').toString()}...</div>}
        {!isLoading && activePhase && (
          <div className="claim-details-container">
            <div className="loss-info section">
              <div className="section-row" style={{ position: 'relative' }}>
                <div className="section-col">
                  <div className="heading" role="presentation">
                    {t('lossCalledIn').toString()}
                  </div>
                  <div>{format(new Date(claim?.lossDate), 'MM/dd/yyyy')}</div>
                </div>
                <div className="section-col">
                  <div className="heading">{t('status').toString()}</div>
                  <div>{statusDesc}</div>
                </div>
                <div style={{ position: 'absolute', right: 0, top: 0, display: 'flex' }}>
                  {showEncircleIcon && <EncircleIcon fill="clear"/>}
                  <IonButton size="small" fill="clear" className="ion-no-margin" onClick={() => handleJobActionSheet({ claim,
                    regionId: region.toString(),
                    externalId: encircleId,
                  })}>
                    <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
                  </IonButton>
                </div>
              </div>
              <div className="section-row">
                <div className="section-col">
                  <div className="heading">{t('insurer').toString()}</div>
                  <div>{claim?.insurer}</div>
                </div>
                <div className="section-col">
                  <div className="heading">{t('adjuster').toString()}</div>
                  <div>{claim?.adjuster}</div>
                </div>
              </div>
              <div className="section-row">
                <div className="section-col">
                  <div className="heading">{t('claimNumber').toString()}</div>
                  <div>{claim?.claimNo}</div>
                </div>
                <div className="section-col">
                  <div className="heading">{t('policyNo').toString()}</div>
                  <div>{claim?.policyNo}</div>
                </div>
              </div>
            </div>
            <div className="onsite-info section">
              <div className="section-row">
                <div className="section-col">
                  <div className="heading">{t('onsiteContact').toString()}</div>
                  <div>{claim?.contactName}</div>
                </div>
                <div className="section-col">
                  <div className="heading">{t('onsiteContactNumber').toString()}</div>
                  <div>{claim?.contactPhone}</div>
                </div>
              </div>
              <div className="stand-alone-section">
                <div className="heading">{t('address').toString()}</div>
                <div>{`${claim?.projAddr}, ${claim?.projCity}, ${claim?.projProv}`}</div>
              </div>
              <div className="stand-alone-section">
                <div className="heading">{t('yearOfConstruction').toString()}</div>
                <div>{claim?.yearOfConstruction}</div>
              </div>
              <div className="stand-alone-section">
                <div className="heading">{t('onsiteContacteEmail').toString()}</div>
                <div>{claim?.projEmail}</div>
              </div>
            </div>
            <div className="job-info section">
              <div className="section-row">
                <div className="section-col">
                  <div className="heading">{t('jobType').toString()}</div>
                  <div>{claim?.lossType}</div>
                </div>
                <div className="section-col">
                  <div className="heading">{t('incidentType').toString()}</div>
                  <div>{claim?.lossType}</div>
                </div>
              </div>
            </div>
            {claim?.lossDesc && (
              <div className="incident-info section">
                <div className="section-row">
                  <div className="stand-alone-section">
                    <div className="heading">{t('incidentDescription').toString()}</div>
                    <div>{claim?.lossDesc}</div>
                  </div>
                </div>
              </div>
            )}
            <div className="dates-info section">
              <div className="section-row">
                <div className="section-col">
                  <div className="heading">{t('startDate').toString()}</div>
                  <div>{activePhase.dateOpened ? format(new Date(activePhase.dateOpened), 'MM/dd/yyyy') : '-'}</div>
                </div>
                <div className="section-col">
                  <div className="heading">{t('closedDate').toString()}</div>
                  <div>{activePhase.dateClosed ? format(new Date(activePhase.dateClosed), 'MM/dd/yyyy') : '-'}</div>
                </div>
              </div>
              <div className="section-row">
                <div className="section-col">
                  <div className="heading">{t('estimate').toString()}</div>
                  <div>{addCurrencyMask(activePhase?.estValue)}</div>
                </div>
              </div>
            </div>
            {activePhase?.linkedToXA && (
  <div
    role="button"
    tabIndex={0}
    style={{ 
      display: 'inline-flex', 
      alignItems: 'center', 
      marginTop: '10px',
    }}
  >
    <span
      onClick={openXaLink}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          openXaLink();
        }
      }}
      style={{
        cursor: 'pointer',
        textDecoration: 'underline',
        fontStyle: 'italic',
        display: 'inline-flex',
        alignItems: 'center',
      }}
      role="button"
      tabIndex={0}
    >
      <img src={xactimateIcon} alt="" style={{ paddingRight: '5px' }} />
      {t('openInXa').toString()}
    </span>
  </div>
            )}

          </div>
        )}
        <ClaimStorageInfo
          claimIndex={claim?.claimIndx}
          isOpen={showStorageInfo}
          onIonModalDidDismiss={() => setShowStorageInfo(false)}
        />
      </IonContent>
      <Toaster containerStyle={{ top: 100, left: 20, bottom: 20, right: 20 }} />
      <IonLoading isOpen={isSaving} duration={2000} message={t('saving')} />
      {isEditClaimsEnabled && (
        <FosFooterButton
          label={t('edit').toString()}
          onClick={() => navigation.push(`/edit-job/${claim?.claimIndx}${isOffline ? '/true' : ''}`)}
        />
      )}
      <IonFooter className="claim-details--footer">
        <IonButton fill="clear" className="claim-details--footer-buttons right-divider" onClick={handleMapButtonClick}>
          <IonIcon icon={locationOutline} />
        </IonButton>
        <IonButton fill="clear" className="claim-details--footer-buttons" onClick={handleCallButtonClick}>
          <FosCachedImage src={phoneIcon} />
        </IonButton>
      </IonFooter>
    </>
  );
};

export default ClaimDetailsInfo;
