/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  useIonActionSheet,
} from '@ionic/react';
import { filterOutline } from 'ionicons/icons';
import { MultiSelect } from 'react-multi-select-component';
import toast, { Toaster } from 'react-hot-toast';

import SearchInput from '../atom/SearchInput';
import ButtonIcon from '../atom/ButtonIcon';
import { ReactComponent as CertificateIcon } from '../assets/icons/ic_certificate.svg';
import { ReactComponent as FilePlus } from '../assets/icons/ic_file_plus.svg';

import './ClaimDocuments.css';
import FosDocItem from '../atom/FosDocItem';
import getDocIcon from '../pages/helper/DocIconHelper';
import handleDateFormat, { DATE_FORMAT_MM_DD_YYYY_HH_MM } from '../utils/DateFormat';
import useClaimDetails from '../hooks/useClaimDetails';

import FilterModal from './FilterModal';
import { Option } from '../utils/Interfaces';
import { ExcludedDocType, OVERRIDE_STRINGS } from '../pages/helper/Const';
import useClaimDocumentTypes from '../hooks/claimDocumentTypes';
import { ClaimDocumentsType } from '../graphql/GetClaimDocumentsList';
import { useAuth } from '../hooks/authContext';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import DocumentUpload from '../atom/DocumentUpload';
import AddDocumentToClaimForm from './AddDocumentToClaimForm';
import { Phase } from '../types/Phases';
import useHandleDocumentActionSheet from '../hooks/handleDocumentActionSheet';
import { PermissionsList, usePermissions } from '../hooks/permissions';
import WorkAuthorizationForm from './WorkAuthorizationForm';
import CertificateOfCompletionForm from './CertificateOfCompletionForm';
import AuthorizationToDisposeContents from './AuthorizationToDisposeContents';
import ValuableLiabilityWaiver from './ValuableLiabilityWaiver';

type Props = {
  claimDocs: ClaimDocumentsType[];
  claimIndx: number;
  phases: Phase[];
  refetchDocuments: () => void;
  isLoading: boolean;
};

const ClaimDocuments: React.FC<Props> = ({ claimDocs, claimIndx, phases, refetchDocuments, isLoading }) => {
  const { t } = useTranslation();
  const fileUploadRef = useRef<any>(null);
  const [isAddDocumentToClaimFormOpen, setIsAddDocumentToClaimFormOpen] = useState<boolean>(false);
  const [claimDocuments, setClaimDocuments] = useState<File[]>([]);
  const { userState } = useAuth()!;
  const [docsList, setDocsList] = useState<ClaimDocumentsType[]>();
  const filterModal = useRef<HTMLIonModalElement>(null);
  const phaseOptions: Option[] = phases.map((phase) => ({ label: phase.phaseCode, value: phase.phaseCode }));
  const [filter, setFilter] = useState<any>([]);
  const [selectedDocument, setSelectedDocument] = useState<any>([]);
  const [selectedTypesList, setSelectedTypesList] = useState<Option[]>([]);
  const [selectedPhasesList, setSelectedPhasesList] = useState<Option[]>([]);
  const { permissions } = usePermissions();
  const isAddDocumentEnabled = permissions.includes(PermissionsList.AddDocuments);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [present] = useIonActionSheet();
  const [isWorkAuthorizationModalOpen, setWorkAuthorizationModalOpen] = useState(false);
  const [isCertificateOfCompletionOpen, setCertificateOfCompletionOpen] = useState(false);
  const [isAuthorizationToDisposeContentsModalOpen, setAuthorizationToDisposeContentsModalOpen] = useState(false);
  const [isValuableLiabilityWaiverModalOpen, setValuableLiabilityWaiverModalOpen] = useState(false);
  
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const regionId = userState.userAuthPayload?.regionId!;
  const { claimDocumentTypesList } = useClaimDocumentTypes(regionId, claimIndx, userState);

  const claimDetail = useClaimDetails(regionId, claimIndx, userState);

  const documentTypeOptions: Option[] = claimDocumentTypesList
    .filter(docType => docType.fileTypeCd !== ExcludedDocType.Image) // Exclude 'Image' file types
    .map((docType) => ({
      label: docType.description,
      value: docType.fileTypeCd,
    }));

  const formDocumentTypes : Option[] = claimDocumentTypesList
    .filter(docType => docType.fileTypeCd !== ExcludedDocType.Image && docType.fileTypeCd !== ExcludedDocType.PurchaseOrder
      && docType.fileTypeCd !== ExcludedDocType.Invoice && docType.fileTypeCd !== ExcludedDocType.SiteInspection)
    .map((docType) => ({
      label: docType.description,
      value: docType.fileTypeCd,
    }));

  const fetchDocumentDetails = async (documentId: number) => {
    try {
      setIsDocumentLoading(true);
      const restClient = getRestClient(userState, ClientType.PLAIN);
      const result = await restClient.get(`/common/claims/documents/${documentId}`);
      if (result.status === 200) {
        setSelectedDocument(result.data);
      }      
    } catch (error) {
      toast.error(t('failToFetchDocumentDetails'), { duration: 2000 });
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const { isEditDocumentToClaimFormOpen, handleDocumentActionSheet, setIsEditDocumentToClaimFormOpen } = useHandleDocumentActionSheet(fetchDocumentDetails);

  // Todo: add the appropriate action.
  const handleOnItemClick = async (idx: number) => {
    console.log(`Clicked on item '${claimDocs[idx].fileName}'`);
  };

  const handleOnOptionsClick = (event: MouseEvent, idx: number) => {
    event.stopPropagation();
    handleDocumentActionSheet({ docURL: claimDocs[idx].filePath, docId: claimDocs[idx].ctfileId });
  };

  const onSearchChange = (term: string) => {
    const filteredItems = claimDocs.filter((doc) => doc.fileName.toLowerCase().includes(term.toLowerCase()));
    setDocsList(filteredItems);
  };

  useEffect(() => {
    setDocsList(claimDocs);
  }, [claimDocs]);

  const clearFilter = () => {
    setFilter([]);
    setSelectedTypesList([]);
    setSelectedPhasesList([]);
    setDocsList(claimDocs);
    filterModal.current?.dismiss();
  };

  const showResults = () => {
    const filters = Object.keys(filter);
    if (filters.length > 0) {
      const filteredItems = claimDocs.filter((item) => {
        let check = true;
        if (filter.types && !filter.types.includes(item.fileTypeCd)) check = false;
        if (filter.phases && !filter.phases.includes(item.phase)) check = false;
        return check;
      });
      setDocsList(filteredItems);
    } else {
      setDocsList(claimDocs);
    }
    filterModal.current?.dismiss();
  };

  const handleCertificationClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    setOpenModal(true);
  };
  const handleTypeChange = (selectedOptions: Option[]) => {
    setSelectedTypesList(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.types = selectedOptions?.map((o) => o.value);
    } else {
      newFilter.types = undefined;
    }
    setFilter(newFilter);
  };

  const handlePhaseChange = (selectedOptions: Option[]) => {
    setSelectedPhasesList(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.phases = selectedOptions?.map((o) => o.value);
    } else {
      newFilter.phases = undefined;
    }
    setFilter(newFilter);
  };

  const fileUploadClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.fileUpload();
    }
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files: File[] = Array.from(e.target.files);
      setClaimDocuments(files);
      setIsAddDocumentToClaimFormOpen(true);
    }
    // Clear the input after files are selected
    e.target.value = '';
  };

  const onDismissAddDocumentToClaimForm = () => {
    setIsAddDocumentToClaimFormOpen(false);
    setIsEditDocumentToClaimFormOpen(false);
    setClaimDocuments([]);
  };

  const onSubmitAddDocumentToClaimForm = (success: boolean, msg: string) => {
    if (success) {
      toast.success(t('documentCreated'), { duration: 4000 });
    } else {
      toast.error(msg || t('failToCreateDocument'), { duration: 4000 });
    }
    setTimeout(() => {
      refetchDocuments();
      setIsSaving(false);
    }, 2000);
    setClaimDocuments([]);
    setIsAddDocumentToClaimFormOpen(false);
    setIsEditDocumentToClaimFormOpen(false);
  };

  enum DocumentAction {
    WORKAUTH = 'Work Authorizations',
    CERTOFCOMP = 'Certificate Of Completion',
    VALLIAB = 'Valuable Liability Waiver',
    DISPOSECONT = 'Authorization to Dispose of Contents',
    CANCEL = 'Cancel',
  }

  const actionSheetButtons: any[] = [
    {
      text: t('workAuthorization'),
      data: {
        action: DocumentAction.WORKAUTH,
      },
    },
    {
      text: t('certificateOfCompletion'),
      data: {
        action: DocumentAction.CERTOFCOMP,
      },
    },
    {
      text: t('valuableLiabilityWaiver'),
      data: {
        action: DocumentAction.VALLIAB,
      },
    },
    {
      text: t('authorizationToDisposeContents'),
      data: {
        action: DocumentAction.DISPOSECONT,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: DocumentAction.CANCEL,
      },
    },
  ];

  const handleOptionsOnClick = (event: MouseEvent) => {
    event.stopPropagation();
    present({
      header: t('options'),
      cssClass: 'custom-action-sheet',
      buttons: actionSheetButtons,
      onDidDismiss: ({ detail }) => {
        switch (detail.data?.action) {
          case DocumentAction.WORKAUTH: {
            setWorkAuthorizationModalOpen(true);
            break;
          }
          case DocumentAction.CERTOFCOMP: {
            setCertificateOfCompletionOpen(true);
            break;
          }          
          case DocumentAction.VALLIAB: {
            setValuableLiabilityWaiverModalOpen(true);
            break;
          }          
          case DocumentAction.DISPOSECONT: {
            setAuthorizationToDisposeContentsModalOpen(true);
            break;
          }
          case DocumentAction.CANCEL:
          default:
            break;
        }
      },
    });
  };

  const onSubmitCertificate = (success: boolean, msg?: string) => {
    setCertificateOfCompletionOpen(false);
    refetchDocuments();
    if (!success && msg) {
      toast.error(msg || t('failToCreateDocument'), { duration: 4000 });
    }
  };

  const onSubmitValuableLiability = (success: boolean, msg?: string) => {
    setValuableLiabilityWaiverModalOpen(false);
    refetchDocuments();
    if (!success && msg) {
      toast.error(msg || t('failToCreateDocument'), { duration: 4000 });
    }
  };

  const onSubmitAuthorization = (success: boolean, msg?: string) => {
    setAuthorizationToDisposeContentsModalOpen(false);
    refetchDocuments();
    if (!success && msg) {
      toast.error(msg || t('failToCreateDocument'), { duration: 4000 });
    }
  };

  const onSubmitWorkAuthorization = (success: boolean, msg?: string) => {
    setWorkAuthorizationModalOpen(false);
    refetchDocuments();
    if (!success && msg) {
      toast.error(msg || t('failToCreateDocument'), { duration: 4000 });
    }
  };

  return (
    <>
      <IonContent className="ion-padding">
        <IonLoading isOpen={isLoading || isDocumentLoading || isSaving} message={t('loading')} duration={8000} />
        <div className="documents-header">
          <SearchInput id="search-jobs-bar" debounce={1000} onSearch={onSearchChange} />
          <ButtonIcon id="docFilterModal" icon={filterOutline} />
        </div>
        <IonList lines="none" className="ion-padding-top">
          {docsList?.map((doc, idx) => (
            <div key={`${doc.fileName + idx}`}>
              <FosDocItem
                thumbSrc={getDocIcon(doc.fileExtension)}
                label={doc.fileName}
                subheading={doc?.uploadedDate && handleDateFormat(doc.uploadedDate, DATE_FORMAT_MM_DD_YYYY_HH_MM)}
                onItemClick={() => handleOnItemClick(idx)}
                onOptionsClick={(event: MouseEvent) => {
                  handleOnOptionsClick(event, idx);
                }}
              />
            </div>
          ))}
        </IonList>
      </IonContent>
      <DocumentUpload onSelectFile={(ev) => onSelectFile(ev)} ref={fileUploadRef} />
      <AddDocumentToClaimForm
        isEditMode={isEditDocumentToClaimFormOpen}
        existingDocumentData={selectedDocument}
        isOpen={isAddDocumentToClaimFormOpen || isEditDocumentToClaimFormOpen}
        claimDocuments={claimDocuments}
        onSavingChange={setIsSaving}
        onDismiss={() => onDismissAddDocumentToClaimForm()}
        onSubmit={(success: boolean, msg: string) => onSubmitAddDocumentToClaimForm(success, msg)}
        regionId={regionId}
        documentTypeOptions={formDocumentTypes}
        claimIndex={claimIndx}
        phases={phases}
      />
      {isAddDocumentEnabled && (
        <IonFooter className="claim-details--footer">
          <IonButton
            fill="clear"
            className="claim-details--footer-buttons right-divider"
            onClick={() => fileUploadClick()}
          >
            <FilePlus fill="none" className="documents-foot-icon" />
          </IonButton>
          <IonButton fill="clear" className="claim-details--footer-buttons" onClick={handleOptionsOnClick}>
            <CertificateIcon fill="none" className="documents-foot-icon" />
          </IonButton>
        </IonFooter>
      )}
      <FilterModal
        ref={filterModal}
        trigger="docFilterModal"
        onClear={clearFilter}
        onClose={() => filterModal.current?.dismiss()}
        onShowResults={showResults}
      >
        <div className="ion-margin-top">
          <IonLabel>{t('type').toString()}</IonLabel>
          <MultiSelect
            options={documentTypeOptions}
            value={selectedTypesList}
            onChange={handleTypeChange}
            labelledBy="Select"
            overrideStrings={OVERRIDE_STRINGS(t)}
          />
        </div>
        <div className="ion-margin-top">
          <IonLabel>{t('phase').toString()}</IonLabel>
          <MultiSelect
            options={phaseOptions}
            value={selectedPhasesList}
            onChange={handlePhaseChange}
            labelledBy="Select"
            overrideStrings={OVERRIDE_STRINGS(t)}
          />
        </div>
      </FilterModal>
      <IonModal isOpen={isWorkAuthorizationModalOpen} class="custom-modal">
        <WorkAuthorizationForm
          isOpen={isWorkAuthorizationModalOpen}
          claimIndx={claimIndx}
          onDismiss={() => setWorkAuthorizationModalOpen(false)}
          onSubmit={onSubmitWorkAuthorization}
          region={regionId}
          claim={claimDetail}
        />
      </IonModal>
      <IonModal isOpen={isCertificateOfCompletionOpen} class="custom-modal">
        <CertificateOfCompletionForm
          isOpen={isCertificateOfCompletionOpen}
          claimIndx={claimIndx}
          onDismiss={() => setCertificateOfCompletionOpen(false)}
          onSubmit={onSubmitCertificate}
          region={regionId}
        />
      </IonModal>
      <IonModal isOpen={isAuthorizationToDisposeContentsModalOpen} class="custom-modal">
        <AuthorizationToDisposeContents
          isOpen={isAuthorizationToDisposeContentsModalOpen}
          claimIndx={claimIndx}
          onDismiss={() => setAuthorizationToDisposeContentsModalOpen(false)}
          onSubmit={onSubmitAuthorization}
          region={regionId}
        />
      </IonModal>
      <IonModal isOpen={isValuableLiabilityWaiverModalOpen} class="custom-modal">
        <ValuableLiabilityWaiver
          isOpen={isValuableLiabilityWaiverModalOpen}
          claimIndx={claimIndx}
          onDismiss={() => setValuableLiabilityWaiverModalOpen(false)}
          onSubmit={onSubmitValuableLiability}
          region={regionId}
        />
      </IonModal>
      <Toaster />
    </>
  );
};

export default ClaimDocuments;
