import { Network } from '@capacitor/network';
import React, { PropsWithChildren, createContext, useEffect } from 'react';

const NetworkContext = createContext({ connected: true, connectionType: 'wifi' });

export const NetworkProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [networkStatus, setNetworkStatus] = React.useState({ connected: true, connectionType: 'wifi' });
  
  useEffect(() => {
    Network.addListener('networkStatusChange', async (status) => {
      setNetworkStatus(status);
    });

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    const checkStatus = async () => {
      const status = await Network.getStatus();
      setNetworkStatus(status);
    };
    checkStatus();
  }, []);

  return (
    <NetworkContext.Provider value={networkStatus}>
      {children}
    </NetworkContext.Provider>
  );
};

export default NetworkContext;