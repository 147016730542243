import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { closeOutline } from 'ionicons/icons';
import { Toaster } from 'react-hot-toast';
import FosTextArea from '../atom/FosTextArea';
import useBranches from '../hooks/branches';
import { useAuth } from '../hooks/authContext';
import useStorage from '../hooks/storage';
import ExpenseTaxes, { ExpTax } from './ExpTaxes';
import FosCurrencyInput from '../components/FosCurrencyInput';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
import useExpenseJobCostCategories from '../hooks/expJobCostCategories';
import useExpenseJobDepartments from '../hooks/expJobDepartments';
import useExpenseJobCostTypes from '../hooks/expJobCostTypes';
import useClaimsPhases from '../hooks/claimsPhases';
import useValidateClaimStatus from '../hooks/useValidateClaimStatus';

export type JobRelExpenseFormData = {  
  id: number
  branch: number
  branchName: string
  creditCardId?: string
  creditCardNumber: string
  projectId: string
  projectIdParent?: string
  costType: string
  category: number
  department: string
  totalAmount: string
  taxes: ExpTax[]
  comments: string
  hasTaxes: boolean
};

export type JobRelExpenseFormErrors = {
  id?: string
  branch?: string
  branchName?: string
  projectId?: string
  projectIdParent?: string
  costType?: string
  category?: string
  department?: string
  totalAmount?: string
  taxes?: string
  comments?: string
  hasTaxes?: string
};

export interface Props {
  id: string
  errors?: JobRelExpenseFormErrors
  showTotalAmount?: boolean;
  location: string;
  expense: JobRelExpenseFormData;
  editMode: boolean;
  onChange: (id: keyof JobRelExpenseFormData, value: any, data: JobRelExpenseFormData) => any
}

export function createJobRelExp(initialValues?: Partial<JobRelExpenseFormData>): JobRelExpenseFormData {
  const tempId = Math.trunc(Math.random() * (10000000 - 1000000) + 1000000);
  return {
    id: tempId,
    branch: initialValues?.branch || 0,
    branchName: initialValues?.branchName || '',
    projectId: initialValues?.projectId || '',
    costType: initialValues?.costType || '',
    category: initialValues?.category || 0,
    department: initialValues?.department || '',
    totalAmount: initialValues?.totalAmount || '',
    taxes: initialValues?.taxes || [],
    comments: initialValues?.comments || '',
    hasTaxes: false,
    creditCardNumber: '',
    creditCardId: undefined,
  };
}

export function getJobRelExpErrors(expense: JobRelExpenseFormData, t: TFunction) {
  const errs: JobRelExpenseFormErrors = {};
  if (!expense.branch) errs.branch = t('branchIdIsRequired');
  if (!expense.category) errs.category = t('categoryIsRequired');
  if (!expense.department) errs.department = t('departmentIsRequired');
  if (!expense.comments) errs.comments = t('commentsFieldIsRequired');
  if (!expense.projectId) errs.projectId = t('projectIdIsRequired');
  if (!expense.costType) errs.costType = t('costTypeIsRequired');
  if (expense.hasTaxes && expense.taxes.length === 0 || expense.taxes.some(x => !x.total)) errs.taxes = t('taxIsRequired');
  return errs;
}

export const JobRelExpenseForm: React.FC<Props> = ({ id, showTotalAmount, location, expense, errors, editMode, onChange }) => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const [claimIndex, setClaimIndex] = useState<number>(0);
  const [phaseIndex, setPhaseIndex] = useState<number>(0);
  const [projectNumber, setProjectNumber] = useState<string | undefined>('');

  const [claimValidationError, setClaimValidationError] = useState<string>('');
  const [checkClaimStatus, setCheckClaimStatus] = useState<boolean>(false);

  const { claimStatus, isLoading: isFetchingClaimStatus } = useValidateClaimStatus(userState, regionId, expense.branch, claimIndex, phaseIndex);
  const { t } = useTranslation();

  const storage = useStorage();
  const branches = useBranches(regionId, userState, storage);
  const [searchJobsTerm, setSearchJobsTerm] = useState('');
  const { data: expenseJobCostTypes } = useExpenseJobCostTypes();
  const { data: expenseJobCostCategories } = useExpenseJobCostCategories(expense.department, expense.costType);

  const { data: claimList } = useClaimsPhases(searchJobsTerm);
  const { data: expenseJobDepartments } = useExpenseJobDepartments(projectNumber);

  const branchesOptions = branches.sort((a, b) => a.branchName.localeCompare(b.branchName)).map(branch => ({ label: branch.branchName, value: branch.branchId.toString() }));
  const departmentOptions = expenseJobDepartments.sort((a, b) => a.value.localeCompare(b.value)).map(dep => ({ label: dep.value, value: dep.id }));
  const costTypeOptions = expenseJobCostTypes.sort((a, b) => a.value.localeCompare(b.value)).map(type => ({ label: type.value, value: type.code }));
  const categoryOptions = expenseJobCostCategories.sort((a, b) => a.value.localeCompare(b.value)).map(cat => ({ label: cat.value, value: cat.id }));
  const projectIdOptions = claimList.sort((a, b) => a.value.localeCompare(b.value)).map(claim => ({ label: claim.value, value: claim.code, claimIndex: claim.parentId }));
  if (!errors) errors = {};

  const handleTaxValueChange = (taxes: ExpTax[]) => {
    const exp: JobRelExpenseFormData = { ...expense, taxes };
    onChange('taxes', taxes, exp);
  };

  const handleExpenseRequired = (isRequired: boolean) => {
    expense.hasTaxes = isRequired;
  };


  useEffect(() => {
    if (!checkClaimStatus || phaseIndex === 0 || claimStatus === undefined) return;

    const claim = claimList.find(claim => claim.code === phaseIndex.toString());
    const exp: JobRelExpenseFormData = { ...expense, ...{ [id]: phaseIndex } };

    if (!claimStatus) {
      setClaimValidationError(t('phaseNotAllowCost'));
      setCheckClaimStatus(false);
      return;
    }
    exp.projectId = phaseIndex.toString();
    exp.projectIdParent = claim?.parentId;
    onChange('projectId', phaseIndex, exp);
    setProjectNumber(claim?.value);
    setCheckClaimStatus(false);
  }, [phaseIndex, claimList, expense, t, claimStatus, onChange, isFetchingClaimStatus, id, checkClaimStatus]);

  const handleChangeProject = (value: any) => {
    const claim = claimList.find(claim => claim.code === value);
    setClaimIndex(+(claim?.parentId ?? 0));
    setPhaseIndex(+value);
    setCheckClaimStatus(true);
  };

  const handleValueChange = (id: keyof JobRelExpenseFormData, value: any) => {
    const exp: JobRelExpenseFormData = { ...expense, ...{ [id]: value } };
    if (id === 'branch') {
      exp.branchName = branchesOptions.find(b => b.value === value)?.label || '';
      exp.branch = value;
    }
    onChange(id, value, exp);
  };

  const handleSearchJobInput = (input: string) => {
    setSearchJobsTerm(input);
  };

  return (
    <form className="fos-form ion-margin-top">
      <IonGrid className="ion-no-padding ion-no-margin">
        <IonRow>
          <IonCol>
            <FosSelectBigListItem
              required
              disabled={!editMode}
              id={`${id}-branch-simple-expense-form`}
              name="branch-simple-expense-form"
              value={`${expense.branch}`}
              onChange={(val: string) => { handleValueChange('branch', val); }}
              label={t('branch')}
              error={errors.branch}
              options={branchesOptions}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FosSelectBigListItem
              required
              disabled={!editMode}
              id={`${id}-project-id-job-rel-expense-form`}
              name="project-id-job-rel-expense-form"
              value={`${expense.projectId}`}
              onChange={handleChangeProject}
              label={t('projectId')}
              options={projectIdOptions}
              error={errors.projectId}
              onSearchInput={handleSearchJobInput}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FosSelectBigListItem
              required
              id={`${id}-department-job-rel-expense-form`}
              name="department-job-rel-expense-form"
              value={`${expense.department}`}
              onChange={(val: string) => { handleValueChange('department', val); }}
              label={t('department')}
              disabled={!expense.branch || !expense.projectId || !editMode}
              options={departmentOptions}
              error={errors.department}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FosSelectBigListItem
              required
              id={`${id}-cost-type-job-rel-expense-form`}
              name="cost-type-job-rel-expense-form"
              value={`${expense.costType}`}
              onChange={(val: string) => { handleValueChange('costType', val); }}
              label={t('costType')}
              disabled={!expense.branch || !editMode}
              options={costTypeOptions}
              error={errors.costType}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FosSelectBigListItem
              required
              id={`${id}-category-job-rel-expense-form`}
              name="category-job-rel-expense-form"
              value={`${expense.category}`}
              onChange={(val: string) => { handleValueChange('category', val); }}
              disabled={!expense.branch || !expense.costType || !expense.department || !editMode}
              label={t('category')}
              options={categoryOptions}
              error={errors.category}
            />
          </IonCol>
        </IonRow>
        {showTotalAmount && <IonRow className="ion-margin-top">
          <IonCol>
            <IonItem lines="none">
              <IonLabel>{t('totalAmount').toString()}</IonLabel>
              <FosCurrencyInput disabled={!editMode} error={errors.totalAmount} value={expense.totalAmount} onChange={(val) => handleValueChange('totalAmount', val)} />
            </IonItem>
          </IonCol>
        </IonRow>}
        <ExpenseTaxes editMode={editMode} error={errors.taxes} onChange={handleTaxValueChange} isRequired={handleExpenseRequired} taxes={expense.taxes} location={location} />
        <IonRow>
          <IonCol>
            <FosTextArea disabled={!editMode} error={errors.comments} required value={expense.comments} onIonChange={(event) => handleValueChange('comments', event.detail.value)} placeholder={t('comments')} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonToast
        isOpen={!!claimValidationError}
        message={claimValidationError}
        duration={3000}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => { setClaimValidationError(''); }} />
      <Toaster
        containerStyle={{
          top: 50, left: 20, bottom: 20, right: 20,
        }} />

      <IonLoading isOpen={isFetchingClaimStatus} message={t('loading')} duration={5000} />

    </form>

  );
};

export default JobRelExpenseForm;
