import { SketchObjectType } from '../sketch-object';
import { type LibItem } from './common';

export const airMover: LibItem = {
  id: Math.random() * 100000,
  thumbUrl: '/assets/images/sketch-tool/air-mover.svg',
  name: 'Air Mover',
  type: SketchObjectType.Image,
  src: '/assets/images/sketch-tool/air-mover.svg',
  width: 48,
  height: 48,
};
