import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonToggle,
  isPlatform,
} from '@ionic/react';
import { TextZoom as textZoom } from '@capacitor/text-zoom';
import { NativeBiometric } from 'capacitor-native-biometric';
import { useTranslation } from 'react-i18next';
import { Toaster, toast } from 'react-hot-toast';
import languages from '../supportedLanguages';
import ButtonOutlined from '../atom/ButtonOutlined';
import useStorage from '../hooks/storage';
import { useAuth } from '../hooks/authContext';
import useRegionList from '../hooks/regionsPerUser';

import './UserSettings.css';
import { GUID, REGION_ID } from '../graphql/GetUserProfileInfo';
import { HYBRID } from '../hooks/photo';
import { Drawing, Sketch, SketchTool } from '../sketch-tool';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import { APP_VERSION } from '../pages/helper/Const';

const TEXT_ZOOM_KEY = 'FOS_TEXT_ZOOM';
const USER_FACE_ID = 'USER_FACE_ID';
const USER_SIGNATURE = 'USER_SIGNATURE';

type StyleType = {
  userSettings: CSSProperties;
  container: CSSProperties;
  label: CSSProperties;
  version: CSSProperties;
  list: CSSProperties;
  title: CSSProperties;
  select: CSSProperties;
  toggle: CSSProperties;
};

type Props = {
  logout: () => void;
};

const styles: StyleType = {
  userSettings: {
    marginTop: 20,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    color: '#666666',
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
  },
  label: {
    color: '#A8A8A8',
    fontSize: 14,
    marginBottom: 6,
  },
  version: {
    color: '#A8A8A8',
    fontSize: 16,
    marginBottom: 10,
    textTransform: 'uppercase',
  },
  list: {
    border: '1px solid #E7E7EF',
    borderRadius: 8,
    marginBottom: 20,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 46,
  },
  title: {
    marginLeft: 20,
    marginBottom: 20,
    fontSize: 24,
    color: '#222222',
    letterSpacing: '-0.02em',
    fontWeight: 600,
  },
  select: {
    fontSize: 15,
    width: '100%',
  },
  toggle: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 14,
    color: '#A8A8A8',
  },
};

type Sorts = 'nearest' | 'mx';

const sketch = new Sketch({ zoomEnabled: false, showScale: false, hideGrid: true });
sketch.activeTool = SketchTool.Pen;
sketch.penToolSettings = { lineWidth: 3 };

const UserSettings: FC<Props> = ({ logout }) => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const [sort, setSort] = useState<Sorts>('nearest');
  const storage = useStorage();
  const { userState } = useAuth()!;
  const regions = useRegionList(userState, storage);
  const [region, setRegion] = useState<number>(userState.userAuthPayload?.regionId! || 1);
  const [faceId, setFaceId] = useState(false);
  const [textZoomValue, setTextZoomValue] = useState(10);
  const sketchContainerRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const restClient = getRestClient(userState, ClientType.FORM);
  const isSortEnabled = process.env.REACT_APP_ENV !== 'prod';

  useEffect(() => {
    const saveSignature = async () => {
      const dataURL = sketch.toDataURL();
      const res = await fetch(dataURL);
      const blob = await res.blob();
      const file = new File([blob], `${sketch.name}.png`);

      const formData = new FormData();
      formData.append('file', file);
      restClient.post(`/employees/signature?userGuid=${window.localStorage.getItem(GUID)}`, formData);
    };

    sketch.on('addobject', (obj: Drawing) => {
      obj.on('ready', () => {
        localStorage.setItem(USER_SIGNATURE, JSON.stringify(sketch.export()));
        saveSignature();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isPlatform('hybrid')) {
      return;
    }

    const savedTextZoom = window.localStorage.getItem(TEXT_ZOOM_KEY);
    if (savedTextZoom) {
      setTextZoomValue(Number(savedTextZoom));
      return;
    }

    textZoom.getPreferred()
      .then(result => setTextZoomValue(result.value * 10))
      .catch(e => window.console.error(e));
  }, []);

  useEffect(() => {
    const savedFaceId: boolean = window.localStorage.getItem(USER_FACE_ID) === 'true';
    if (savedFaceId) {
      setFaceId(savedFaceId);
    }
  }, []);

  useEffect(() => {
    if (!isPlatform('hybrid')) {
      return;
    }

    window.localStorage.setItem(TEXT_ZOOM_KEY, String(textZoomValue));
    textZoom.set({ value: textZoomValue / 10 })
      .catch(e => window.console.error(e));
  }, [textZoomValue]);

  useEffect(() => {
    const canvas = canvasRef.current!;
    const container = sketchContainerRef.current!;
    const { width } = container.getBoundingClientRect();
    canvas.width = width;
    canvas.height = 200;
    canvas.style.background = '#efefef';
    sketch.setCanvas(canvas);
    const currSig = localStorage.getItem(USER_SIGNATURE);
    if (currSig) {
      sketch.loadObjects(JSON.parse(currSig).objects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFaceIdToggle = async (event: any) => {
    if (!isPlatform(HYBRID)) {
      toast.error(t('biometricNotAvailable'));
      return;
    }
    if (event.detail.checked) {
      // Save user's credentials
      NativeBiometric.setCredentials({
        username: window.localStorage.getItem(GUID) || 'userName',
        password: '', // we don't use user password
        server: 'www.fos.ca.com',
      }).then();
    } else {
      // Delete user's credentials
      NativeBiometric.deleteCredentials({
        server: 'www.fos.ca.com',
      }).then();
    }
    window.localStorage.setItem(USER_FACE_ID, event.detail.checked);
    setFaceId(event.detail.checked);
  };

  const clearSignature = () => {
    sketch.clear();
    window.localStorage.removeItem(USER_SIGNATURE);
  };

  const onRegionChange = (reg: CustomEvent) => {
    setRegion(reg.detail.value);
    window.localStorage.setItem(`${REGION_ID}_${window.localStorage.getItem(GUID) || ''}`, reg.detail.value.toString());
    logout();
  };

  return (
    <div style={styles.userSettings}>
      <span className="user-settings-title" style={styles.title}>{t('settings').toString()}</span>
      <div style={styles.container}>
        <IonLabel style={styles.version}>{t('version').toString()}: {APP_VERSION}</IonLabel>
        <span style={styles.label}>{t('preferredLang').toString()}</span>
        <IonList style={styles.list}>
          <IonItem lines="none">
            <IonSelect style={styles.select}
              className="language-select"
              interface="action-sheet"
              placeholder="Select Language"
              value={currentLang}
              onIonChange={(lng) => {
                i18n.changeLanguage(lng.detail.value);
                setCurrentLang(lng.detail.value);
                setSort(sort);
                setRegion(region);

              }}>
              {Object.keys(languages).map((lng) => (
                <IonSelectOption value={lng} key={lng}>
                  {t(lng).toString()}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonList>
        <span style={styles.label}>
          {t('preferredSort').toString()}
        </span>
        <IonList style={styles.list}>
          <IonItem lines="none">
            <IonSelect style={styles.select} interface="action-sheet" value={sort} onIonChange={(lng) => {
              setSort(lng.detail.value);
            }}>
              <IonSelectOption value="nearest">
                {t('nearestLocation').toString()}
              </IonSelectOption>
              {isSortEnabled && <IonSelectOption value="newest-active">
                {t('newestActive').toString()}
              </IonSelectOption>}
              {isSortEnabled && <IonSelectOption value="newest-all">
                {t('newestAll').toString()}
              </IonSelectOption>}
            </IonSelect>
          </IonItem>
        </IonList>
        <span style={styles.label}>
          {t('preferredRegion').toString()}
        </span>
        <IonList style={styles.list}>
          <IonItem lines="none">
            <IonSelect style={styles.select} interface="action-sheet" value={region} onIonChange={onRegionChange}>
              {regions.map((reg) => (
                <IonSelectOption value={reg.regionId} key={reg.regionId}>
                  {reg.regionName}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonList>
        <span style={styles.label}>
          {t('textZoom').toString()}
        </span>
        <IonItem lines="none" className="ion-no-padding">
          <IonRange
            value={textZoomValue}
            pin
            pinFormatter={(value: number) => `${value * 10}%`}
            snaps
            min={2}
            max={18}
            onIonChange={(event) => setTextZoomValue(+event.detail.value)}
          />
        </IonItem>
        {isPlatform(HYBRID) &&
          <IonItem className="face-id-toggle" style={styles.toggle} lines="none">
            <IonLabel>
              {t('faceId').toString()}
            </IonLabel>
            <IonToggle color='success' checked={faceId} onIonChange={handleFaceIdToggle} />
          </IonItem>
        }
        <span className="ion-margin-top" style={styles.label}>
          {t('mySignature').toString()}
        </span>
        <div ref={sketchContainerRef} style={{ position: 'relative', height: 200 }}>
          <canvas ref={canvasRef} height={200} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <IonButton fill="clear" className="ion-margin-bottom " onClick={clearSignature}>
            {t('clearSignature').toString()}
          </IonButton>
        </div>
      </div>
      <div className="ion-margin-bottom">
        <ButtonOutlined text={t('logout') || ''} onClick={logout} />
      </div>
      <Toaster
        containerStyle={{
          top: 50,
          left: 20,
          bottom: 20,
          right: 20,
        }}
      />
    </div>
  );
};

export default UserSettings;
