import { useQuery } from '@tanstack/react-query';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import GET_JOB_COST_CATEGORIES from '../graphql/ExpJobCostCategories';

export interface JobCostCategory {
  id: string
  code: string
  parentId: string
  value: string
}

const useExpenseJobCostCategories = (departmentId?: string, jobCostTypeId?: string) => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const graphQLClient = getGraphQLClient(userState, DomainParams.Common);
  const { isLoading, data } = useQuery({
    queryKey: ['expJobCostCategories', regionId, departmentId, jobCostTypeId],
    queryFn: () =>
      graphQLClient.request(GET_JOB_COST_CATEGORIES, { regionId, departmentId: departmentId || undefined, jobCostTypeId: jobCostTypeId || undefined }),
    refetchOnWindowFocus: false,
  });
  return { isLoading, data: (data?.jobCostCategories || []) as JobCostCategory[] };
};

export default useExpenseJobCostCategories;