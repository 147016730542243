import { IonItem, IonList, IonText } from '@ionic/react';
import React from 'react';
import './FosLabelValue.css';

export interface Props {
  label?: string;
  value?: any
  disabled?: boolean;
}

const FosLabelValue: React.FC<Props> = React.forwardRef<HTMLIonTextElement, Props>(({ label, value, disabled }, ref) => (
  <IonList className='ion-margin-bottom'>
    <IonItem lines="none" className='fos-label-value-item-style fos-border' disabled={disabled} >
      {label &&
        <IonText className='fos-label-style' slot='start'>
          {label}
        </IonText>}
      {value &&
        <IonText ref={ref} className='fos-value-style' slot="end">
          {value}
        </IonText>}
    </IonItem>
  </IonList>
));

export default FosLabelValue;
