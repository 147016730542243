import React, { useState, useEffect } from 'react';
import { IonLabel, IonNote } from '@ionic/react';
import { t } from 'i18next';
import debounce from '../utils/Utilities';
import './AutocompleteSearch.css';

interface Props {
  options: Array<any>;
  label?: string;
  debounce?: number;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onChange?: (event: any) => any;
  onOptionSelect?: (option: any) => void;
  onAddClick?: () => void;
  addButton?: boolean;
  marginBottom?: boolean;
  disableEnter?: boolean;
}

const AutocompleteSearch = React.forwardRef<any, Props>(
  (
    {
      options,
      label,
      debounce: debounceTime,
      placeholder,
      disabled,
      error,
      onChange,
      onOptionSelect,
      onAddClick,
      addButton,
      marginBottom,
      disableEnter,
    },
    ref,
  ) => {
    const handleOnChange = debounce(onChange, debounceTime || 300);
    const [activeOptionIndex, setActiveOptionIndex] = useState(0);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
      if (options.length === 0) {
        setActiveOptionIndex(0);
      }
    }, [options]);

    const keyHandler = (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowDown' && activeOptionIndex < options.length) {
        setActiveOptionIndex(activeOptionIndex + 1);
      }

      if (event.key === 'ArrowUp' && activeOptionIndex > 0) {
        setActiveOptionIndex(activeOptionIndex - 1);
      }
      if (!disableEnter) {
        if (event.key === 'Enter') {
          if (onOptionSelect) {
            onOptionSelect(options[activeOptionIndex]);
            setInputValue('');
          }
        }
      }
    };

    const handleOptionClick = (option: any) => {
      if (onOptionSelect) {
        onOptionSelect(option);
        setInputValue('');
      }
    };
    const isMarginBottom = marginBottom
      ? 'autocomplete-search-wrapper ion-margin-bottom'
      : 'autocomplete-search-wrapper';


    const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = evt.target;
      setInputValue(value);
      handleOnChange(evt);
    };

    return (
      <div className={isMarginBottom}>
        <IonLabel position="stacked" style={{ display: !label ? 'none' : undefined }}>
          {label || 'Label'}
        </IonLabel>
        <input
          disabled={disabled}
          ref={ref}
          autoComplete="off"
          className="autocomplete-search"
          placeholder={placeholder}
          type="text"
          id="fname"
          name="fname"
          onChange={handleInputChange}
          onKeyDown={keyHandler}
        />
        {error && (
          <IonNote className="note-error" slot="error">
            {error}
          </IonNote>
        )}
        {(options && options.length > 0) || (addButton && inputValue && options.length === 0) ? (
          <div className="suggestion-area" role="listbox">
            {options.map((option, index) => (
              <div
                key={option.id}
                tabIndex={0}
                role="option"
                aria-selected={activeOptionIndex === index}
                className={activeOptionIndex === index ? 'suggestion active' : 'suggestion'}
                onFocus={() => setActiveOptionIndex(index)}
                onMouseEnter={() => setActiveOptionIndex(index)}
                onClick={() => handleOptionClick(option)}
                onKeyDown={keyHandler}
              >
                {option.fullName && option.description
                  ? `${option.fullName} - ${option.description}`
                  : option.fullName || option.description}
              </div>
            ))}
            {addButton && inputValue && options.length === 0 && (
              <div
                className="suggestion active"
                tabIndex={0}
                role="button"
                onClick={() => {
                  if (onAddClick) {
                    onAddClick();
                    setInputValue('');
                  }
                }}
                onKeyDown={keyHandler}
              >
                {t('add').toString()}
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  },
);

export default AutocompleteSearch;
