import { gql } from '@apollo/client';

const GET_EXPENSE_DEPARTMENTS = gql`
query expenseDepartments ($regionId: Int!, $branchId: Int!, $categoryId: Int!, $page: Int!, $itemsPerPage: Int!) {
  expenseDepartments(regionId: $regionId, branchId: $branchId, categoryId: $categoryId, page: $page, itemsPerPage: $itemsPerPage)
  {
    currentPage
    items {
      department
      departmentCode
      departmentId
    }
    pageSize
    totalItems
    totalPages
  }
}`;

export default GET_EXPENSE_DEPARTMENTS;