import { gql } from '@apollo/client';

// TODO replace mutation once service is ready
const UPDATE_PHOTOS =  gql`mutation UpdateCtPhoto($request: UpdateCtPhotoInput) {
  updateCtPhoto(request: $request)
  {
    itemId
    status
    message
    errors {
      code
      value
    }
  }
}`;

export default UPDATE_PHOTOS;