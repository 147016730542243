import { Storage } from '@ionic/storage';
import { UserState } from '../../../../hooks/authUtils';
import GET_EQUIPMENT_CLASSES_LIST, { EquipmentClassType } from '../graphql/GetEquipmentClasses';
import useBaseCacheAndList from '../../../../hooks/baseCacheAndList';
import equipmentKeys from './equipmentKeys';
import { DomainParams } from '../../../../hooks/graphQLClientUtil';

const useEquipmentClasses = (region: number, userState: UserState, storage: Storage) => {
  const equipmentClassType: EquipmentClassType[] = useBaseCacheAndList(
    region,
    userState,
    storage,
    GET_EQUIPMENT_CLASSES_LIST,
    equipmentKeys.equipmentClasses(),
    'equipmentClasses',
    DomainParams.Equipments);
  return equipmentClassType;
};

export default useEquipmentClasses;