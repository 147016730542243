export enum ClaimDetailsMenuTabs {
  ClaimInfo = 'Claim Info',
  Photos = 'Photos',
  Moisture = 'Moisture',
  Notes = 'DocumentText',
  Documents = 'Documents',
}

export type ClaimDetailsActiveOption = ClaimDetailsMenuTabs.ClaimInfo | 
ClaimDetailsMenuTabs.Photos | 
ClaimDetailsMenuTabs.Moisture | 
ClaimDetailsMenuTabs.Notes | 
ClaimDetailsMenuTabs.Documents;
