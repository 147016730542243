import { Variables } from 'graphql-request';
import { DomainParams } from './graphQLClientUtil';
import GET_PAYROLL_DETAILS from '../graphql/GetPayrollDetails';
import { UserState } from './authUtils';
import { GUID, REGION_ID } from '../graphql/GetUserProfileInfo';
import useBaseQuery from './baseQuery';

const useGetPayrollDetails = (userState: UserState) => {
  const regionId = userState.userAuthPayload?.regionId! || 
  Number(window.localStorage.getItem(`${REGION_ID}_${window.localStorage.getItem(GUID) || ''}`));
  const userGuid = window.localStorage.getItem(GUID) as string;  
  const variables: Variables = { userGuid };
  const { data, isLoading } = useBaseQuery(
    regionId,
    variables,
    userState,
    true,
    GET_PAYROLL_DETAILS,
    ['useGetPayrollDetails'],
    DomainParams.Payroll,
  );
  
  return { isLoading, data };
};


export default useGetPayrollDetails;