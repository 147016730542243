import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';
import DELETE_NOTE from '../graphql/DeleteNote';

/*
* GraphQL call: delete note
* */
const useDeleteNote = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({ regionId, id }: { regionId: number, id: number }) => graphQLClient.request(DELETE_NOTE, { regionId, id }),
  });
  return { isLoading, mutateAsync };
};

export default useDeleteNote;