import { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import GET_EXPENSE_MILEAGE_TYPES, { MileageTypesType } from '../graphql/ExpenseMileageTypes';
import { GUID, REGION_ID } from '../graphql/GetUserProfileInfo';
import useBaseQuery from './baseQuery';

const useExpenseMileageTypes = () => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId! || Number(window.localStorage.getItem(`${REGION_ID}_${window.localStorage.getItem(GUID) || ''}`));
  
  const { isLoading, data } = useBaseQuery(
    regionId,
    {},
    userState,
    true,
    GET_EXPENSE_MILEAGE_TYPES,
    ['expMileageTypes'],
    DomainParams.Payroll,
  );
  return { isLoading, mileageTypes: (data?.mileageTypes as MileageTypesType[]) || [] };
};

export default useExpenseMileageTypes;