import React, { useEffect, useState } from 'react';
import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRange,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { WallToolSettings, Sketch } from '../sketch-tool';

export interface WallToolSettingsProps {
  sketch: Sketch;
}

const SketchToolWallToolSettings = ({ sketch }: WallToolSettingsProps) => {
  const [wallToolSettings, setWallToolSettings] = useState<WallToolSettings>(sketch?.wallToolSettings || {});
  const { t } = useTranslation();

  useEffect(() => {
    if (!sketch) return;
    sketch.wallToolSettings = wallToolSettings;
  }, [sketch, wallToolSettings]);

  const handlePenColorChange = (event: any) => {
    setWallToolSettings({ ...wallToolSettings, strokeStyle: event.target.value });
  };

  const handlePenLineWidthChange = (event: any) => {
    setWallToolSettings({ ...wallToolSettings, lineWidth: +event.detail.value });
  };

  const handleShowDimensionsChange = (event: any) => {
    setWallToolSettings({ ...wallToolSettings, showDimensions: event.detail.checked });
  };

  return (
    <IonToolbar color="light">
      <IonGrid>
        <IonRow class="ion-align-items-center ion-nowrap" style={{ overflowX: 'scroll' }}>
          <IonCol>
            <IonItem lines="none" color="light">
              <IonLabel>
                {t('showDimensions').toString()}
              </IonLabel>
              <IonCheckbox checked={wallToolSettings.showDimensions || false} onIonChange={handleShowDimensionsChange} />
            </IonItem>
          </IonCol>
          <IonCol size="14">
            <IonItem lines="none" color="light">
              <IonLabel>
                {t('lineWidth').toString()}
              </IonLabel>
              <IonRange value={wallToolSettings.lineWidth || 1} min={1} max={30} onIonChange={handlePenLineWidthChange} />
            </IonItem>
          </IonCol>
          <IonCol>
            <input type="color" value={wallToolSettings.strokeStyle || '#000000'} onChange={handlePenColorChange} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );
};

export default SketchToolWallToolSettings;
