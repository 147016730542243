import { gql } from '@apollo/client';

const GET_PAYROLL_DETAILS = gql`
query payrollDetails($userGuid: UUID!) {
  payrollDetails(userGuid: $userGuid) {
      user {
          branchId
      }
      userDetail
      {
        province
        allowExpenseEntry
      }
  }
}`;

export default GET_PAYROLL_DETAILS;