/* eslint-disable @typescript-eslint/no-use-before-define */
import { useIonViewDidLeave } from '@ionic/react';
import { useState, useEffect } from 'react';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { useHistory, useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { t } from 'i18next';
import { useAuth } from '../../../../hooks/authContext';
import { EquipmentAction, getStatusId } from '../../util/EquipmentUtil';
import { ApiStatus } from '../../../../pages/helper/Const';
import getGraphQLClient, { DomainParams } from '../../../../hooks/graphQLClientUtil';
import UPDATE_EQUIPMENTS_STATUSES from '../../data/graphql/UpdateEquipmentsStatuses';
import getEquipmentByItemIdHelper from '../../data/helper/getEquipmentByItemIdHelper';
import { getErrorMsg } from '../../../../pages/helper/AppHelper';

interface RouteParams {
  scanType: string;
  action: EquipmentAction;
}

const ScanEquipmentViewModel = () => {
  const { userState, checkTokenExpiration } = useAuth()!;
  const [isLoading, setIsLoading] = useState(false);
  const regionId = userState.userAuthPayload?.regionId!;
  const [invalidCode, setInvalidCode] = useState(false);
  const [equipmentNotAvailable, setEquipmentNotAvailable] = useState(false);
  const [alertDismissed, setAlertDismissed] = useState(false);
  const [onScanSuccess, setOnScanSuccess] = useState(false);
  const [onUpdateStatusSuccess, setOnUpdateStatusSuccess] = useState(false);
  const [onUpdateStatusError, setOnUpdateStatusError] = useState('');
  const [forceUpdate, setForceUpdate] = useState(false);

  const [scannedEquipments, setScannedEquipments] = useState<any[]>([]);
  const { scanType, action } = useParams<RouteParams>();
  const isBatchScan = scanType === 'batch';
  const history = useHistory();

  const stopScan = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  };

  useIonViewDidLeave(() => {
    stopScan();
  });

  useEffect(() => { 
    stopScan();
  }, []);

  const onFinishClick = async () => {
    stopScan();
    if (action === EquipmentAction.AddToClaim) {
      setIsLoading(true);
      const equipment = await getEquipmentByItemIdHelper(userState, regionId, scannedEquipments[0]);
      setIsLoading(false);
      history.push({
        pathname: `/equipment-assignment/${encodeURIComponent(scannedEquipments.toString())}`,
        state: equipment,
      });
    } else {
      onSubmit();
    }
  };

  /* 
  * Submit update
  * */
  const onSubmit = async () => {
    await checkTokenExpiration();
    mutateAsync()
      .then((response) => {
        if (response?.updateEquipmentsStatuses?.status === ApiStatus.SUCCESS) {
          setOnUpdateStatusSuccess(true);
          setTimeout(() => { history.push('/tabs/equipments'); }, 1000);
          return;
        }
        throw new Error(response.updateEquipmentsStatuses?.message || t('updateEquipmentStatusError'));
      })
      .catch((e) => {
        const msg = getErrorMsg(e);
        const message = msg || t('updateEquipmentStatusError');
        setOnUpdateStatusError(message);
      });
  };

  /*
  * Mutation to save the update
  * */
  const { isLoading: isSaving, mutateAsync } = useMutation({
    mutationFn: () =>
      getGraphQLClient(userState, DomainParams.Equipments).request(UPDATE_EQUIPMENTS_STATUSES, {
        regionId,
        itemIds: scannedEquipments,
        statusId: getStatusId(action),
      }),
  });

  /**
  * Needed to create this forceUpdate variable to make sure when the user comes back to the scanner screen
  * it will open the camera again.
  */
  const handleForceUpdate = () => {
    setForceUpdate(prevState => !prevState);
  };

  return {
    isLoading,
    invalidCode,
    equipmentNotAvailable,
    alertDismissed,
    onScanSuccess,
    isBatchScan,
    action,
    isSaving,
    onUpdateStatusSuccess,
    onUpdateStatusError,
    forceUpdate,
    scannedEquipments,
    setOnUpdateStatusError,
    stopScan,
    setInvalidCode,
    setAlertDismissed,
    setOnScanSuccess,
    onFinishClick,
    handleForceUpdate,
    setEquipmentNotAvailable,
    setScannedEquipments,
  };

};

export default ScanEquipmentViewModel;
