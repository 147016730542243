import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';
import GET_SKETCHES_LIST from '../graphql/GetSketchesList';

const useSketchesList = (userState: UserState, levelId: number, refetchKey: number) => useQuery({
  queryKey: ['sketchesList', { levelId }, refetchKey],
  queryFn: (context: QueryFunctionContext) => getGraphQLClient(userState).request(GET_SKETCHES_LIST, context.queryKey[1]),
  refetchOnWindowFocus: false,
});

export default useSketchesList;
