import { gql } from '@apollo/client';

const DECLINE_EXPENSE = gql`
mutation RejectExpense($userGuid: UUID!, $expenseId: Int!, $reason: String!) {
  rejectExpense(expenseId: $expenseId, userGuid: $userGuid, reason: $reason) {
    status
    itemId
  }
}`;


export default DECLINE_EXPENSE;
