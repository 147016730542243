/* eslint-disable react/require-default-props */
import React, { PropsWithChildren, useState } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import {
  IonModal,
  IonContent,
  IonHeader,
  IonFooter,
  IonRow,
  IonButton,
  isPlatform,
  IonIcon,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { close } from 'ionicons/icons';
import ButtonOutlined from '../atom/ButtonOutlined';

const MODAL_INITIAL_BREAKPOINT = 1;
const HYBRID = 'hybrid';

type StyleType = {
  header: React.CSSProperties;
  headerText: React.CSSProperties;
  closeIcon: React.CSSProperties;
  buttonArea: React.CSSProperties;
  clearAllButton: React.CSSProperties;
  seeResultButton: React.CSSProperties;
};

const styles: StyleType = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
  headerText: {
    fontWeight: 'bold',
    marginTop: 0,
  },
  closeIcon: {
    backgroundColor: '#ecedf2',
    width: 44,
    marginTop: 5,
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '90px',
    alignItems: 'center',
  },
  clearAllButton: {
    width: '40%',
  },
  seeResultButton: {
    width: '100%',
    fontWeight: 600,
    marginRight: '4%',
  },
};

export interface Props {
  trigger?: string;
  onClose?: () => any;
  onShowResults?: () => any;
  onClear?: () => any;
}

const FilterModal = React.forwardRef<HTMLIonModalElement, PropsWithChildren<Props>>(({ trigger, children, onClose, onClear, onShowResults }, ref) => {
  const { t } = useTranslation();
  const [keyboardShow, setKeyboardShow] = useState(false);
  if (isPlatform(HYBRID)) {
    Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardShow(true);
    });
    Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardShow(false);
    });
  }

  return (
    <IonModal ref={ref} trigger={trigger} initialBreakpoint={MODAL_INITIAL_BREAKPOINT} breakpoints={[0, 0.25, 0.5, 0.75, MODAL_INITIAL_BREAKPOINT]}>
      <IonContent className="ion-padding">
        <IonHeader className="header" style={styles.header}>
          <h1 style={styles.headerText}>
            {t('filters').toString()}
          </h1>
          <div className="close-icon-container">
            <IonIcon icon={close} onClick={onClose} className="tools-close-button" />
          </div>
        </IonHeader>
        {children}
      </IonContent>
      <IonFooter hidden={keyboardShow}>
        <IonRow style={styles.buttonArea}>
          <div style={styles.clearAllButton}>
            <ButtonOutlined text={t('clearAll').toString()} onClick={onClear} />
          </div>
          <IonButton fill='solid' style={styles.seeResultButton} onClick={onShowResults}>
            {t('showResults').toString()}
          </IonButton>
        </IonRow>
      </IonFooter>
    </IonModal>
  );
});

export default FilterModal;
