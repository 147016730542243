import { useState } from 'react';
import { UserState } from './authUtils';

import { DomainParams } from './graphQLClientUtil';
import useBaseQuery from './baseQuery';
import GET_EXPENSES_STATUSES, { ExpenseStatusesType } from '../graphql/GetExpenseStatuses';

const useExpenseStatuses = (userState: UserState) => {
  const [expenseStatuses, setExpenseStatuses] = useState<ExpenseStatusesType[]>([]);

  const onSuccess = async (data: any) => {
    const expenseList: ExpenseStatusesType[]  = data?.expenseStatuses || [];
    setExpenseStatuses(expenseList);
  };

  const { isLoading, isFetching } = useBaseQuery(
    1,
    { },
    userState,
    true,
    GET_EXPENSES_STATUSES,
    ['useExpenseStatuses'],
    DomainParams.Payroll,
    onSuccess,
  );

  return { expenseStatuses, isLoading, isFetching };
};

export default useExpenseStatuses;
