import { duplicateButtonImage, resizeButtonImage, rotateButtonImage } from './common';
import { ObjectConfig, ObjectExport, Rect, SketchObject, SketchObjectType } from './sketch-object';

export interface SketchImageConfig extends ObjectConfig {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  src?: string;
}

export interface SketchImageExport extends ObjectExport {
  type: SketchObjectType.Image;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  src?: string;
}

export class SketchImage extends SketchObject {
  x: number;

  y: number;

  src: string;

  dragStart: HammerPoint | null = null;

  private image: HTMLImageElement;

  private currentTransform?: DOMMatrix;

  private resizeStart?: { width: number; height: number };

  constructor(config: SketchImageConfig) {
    super(config);
    const {
      x = 0,
      y = 0,
      width,
      height,
      src = '',
    } = config;
    this.x = x;
    this.y = y;
    this.src = src;
    this.image = new Image(width, height);
    this.image.crossOrigin = 'anonymous';
    this.image.src = src;
    this.image.onload = () => {
      this.emit('load');
    };
  }

  set width(width: number) {
    this.image.width = width;
  }

  get width() {
    return this.image.width;
  }

  set height(height: number) {
    this.image.height = height;
  }

  get height() {
    return this.image.height;
  }

  setX(x: number) {
    this.x = x;
    this.emit('update');
  }

  setY(y: number) {
    this.y = y;
    this.emit('update');
  }

  setWidth(width: number) {
    this.width = width;
    this.emit('update');
  }

  setHeight(height: number) {
    this.height = height;
    this.emit('update');
  }

  export(): SketchImageExport {
    return {
      ...super.export(),
      type: SketchObjectType.Image,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      src: this.src,
    };
  }

  draw(): void {
    if (!this.visible || !this.canvas) return;
    const ctx = this.canvas!.getContext('2d');

    const scaleX = this.canvas.width / this.image.width;
    const scaleY = this.canvas.height / this.image.height;
    const scaleToFit = Math.min(scaleX, scaleY);

    // Calculate the new dimensions
    const newWidth = this.image.width * scaleToFit;
    const newHeight = this.image.height * scaleToFit;

    ctx?.save();
    ctx?.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx?.rotate(this.rotation * Math.PI / 180);
    ctx?.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    ctx?.drawImage(this.image, this.x, this.y, newWidth, newHeight);
    this.currentTransform = ctx?.getTransform();
    ctx?.restore();
    if (this.showDimensions) this.drawDimensions();
    if (this.selected && !this.isPartOfGroup()) {
      this.drawSelection();
      this.drawRotateButton();
      this.drawResizeButton();
      this.drawDuplicateButton();
    }
  }

  drawName(): void { }

  drawSquareFootage(): void { }

  drawDimensions() {
    const ctx = this.canvas!.getContext('2d')!;
    const widthTxt = this.getAmountFormatted(this.width);
    const { width: widthTxtWidth } = ctx.measureText(widthTxt);
    const heightTxt = this.getAmountFormatted(this.height);
    const { actualBoundingBoxAscent: heightTxtHeight } = ctx.measureText(heightTxt);
    ctx.save();
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const distFromBorder = 12;
    ctx.fillText(widthTxt, this.x + this.width / 2 - widthTxtWidth / 2, this.y - distFromBorder);
    ctx.restore();
    ctx.save();
    const x = this.x + distFromBorder;
    const y = this.y + this.height / 2 + heightTxtHeight / 2;
    ctx.translate(x, y);
    ctx.rotate(-90 * Math.PI / 180);
    ctx.translate(-x, -y);
    ctx.fillText(heightTxt, x, y - distFromBorder);
    ctx.restore();
  }

  drawSelection() {
    const ctx = this.canvas!.getContext('2d')!;
    const size = 8;
    ctx.save();
    ctx.fillStyle = 'red';
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const x1 = this.x - size / 2;
    const y1 = this.y - size / 2;
    ctx.fillRect(x1, y1, size, size);
    ctx.fillRect(x1 + this.width, y1, size, size);
    ctx.fillRect(x1, y1 + this.height, size, size);
    ctx.fillRect(x1 + this.width, y1 + this.height, size, size);
    ctx.restore();
  }

  isContainedByRect(outerRect: Rect): boolean {
    return this.isObjectContainedByRect(this, outerRect);
  }

  isPointInsideObject(x: number, y: number) {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    path.rect(this.x, this.y, this.width, this.height);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideRotationButton(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    path.rect(this.x + this.width / 2 - 24 / 2, this.y - 12 - 24, 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideResizeButton(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    path.rect(this.x + this.width + 24 / 2, this.y - 12 - 24, 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideDuplicateButton(x: number, y: number): boolean {
    if (!this.canvas) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const btnWidth = 24;
    const rx = this.x + this.width + 12;
    const ry = this.y + this.height / 2 - btnWidth / 2;
    path.rect(rx, ry, btnWidth, btnWidth);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  handleDragStart(): void {
    this.dragStart = { x: this.x, y: this.y };
  }

  handleDrag({ deltaX, deltaY }: HammerInput): void {
    const sketch = this.getSketch();
    if (!this.dragStart || !sketch) return;
    const { x, y } = this.dragStart;
    this.x = x + deltaX / sketch.zoom;
    this.y = y + deltaY / sketch.zoom;
    this.emit('update');
  }

  handleDragEnd(): void {
    this.dragStart = null;
  }

  handleResizeStart() {
    this.resizeStart = { width: this.width, height: this.height };
  }

  handleResize(event: HammerInput) {
    this.width = this.resizeStart!.width + event.deltaX;
    this.height = this.resizeStart!.height + event.deltaY;
    this.emit('update');
  }

  handleResizeEnd(): void {
    delete this.resizeStart;
    this.emit('update');
  }

  drawRotateButton() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const btnWidth = 24;
    const x = this.x + this.width / 2 - btnWidth / 2;
    const y = this.y - 12 - btnWidth;
    ctx.drawImage(rotateButtonImage, x, y);
    ctx.restore();
  }

  drawResizeButton(): void {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const btnWidth = 24;
    const x = this.x + this.width + btnWidth / 2;
    const y = this.y - 12 - btnWidth;
    ctx.drawImage(resizeButtonImage, x, y);
    ctx.restore();
  }

  drawDuplicateButton() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const btnWidth = 24;
    const x = this.x + this.width + 12;
    const y = this.y + this.height / 2 - btnWidth / 2;
    ctx.drawImage(duplicateButtonImage, x, y);
    ctx.restore();
  }
}
