import { gql } from '@apollo/client';

export type NotesDepartmentsType = {
  noteDepartmentCode : string,
  noteDepartmentDesc : string,
};


const GET_NOTES_DEPARTMENTS_LIST = gql`
query notesDepartments {
  notesDepartments
  { 
    noteDepartmentCode 
    noteDepartmentDesc
  } 
}`;

export default GET_NOTES_DEPARTMENTS_LIST;