import { t } from 'i18next';

export const checkVersion = (appVersion: string, minVersion: string) : boolean => {
  if (!appVersion || !minVersion) return true;
  const [majorApp, minorApp, patchesApp] = appVersion.split('.');
  const [majorMin, minorMin, patchesMin] = minVersion.split('.');
  if (Number(majorMin) > Number(majorApp)) return false;
  if (Number(majorMin) < Number(majorApp)) return true;
  if (Number(minorMin) > Number(minorApp)) return false;
  if (Number(minorMin) < Number(minorApp)) return true;
  if (Number(patchesMin) > Number(patchesApp)) return false;
  return true;
};

export const getErrorMsg = (exception: any) : string => {
  if (exception?.response?.ErrorCode === 400) {
    return t('errorXSS');
  } 
  return exception?.message?.split(':')[0];
};