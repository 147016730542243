import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';
import DELETE_CT_FILES from '../graphql/DeleteCtFiles';

const useDeleteCTFiles = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (ctFileIds: Array<number>) => graphQLClient.request(DELETE_CT_FILES, { ctFileIds }),
  });

  return { isLoading, mutateAsync };
};
  
export default useDeleteCTFiles;
