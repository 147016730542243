import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { GUID } from '../graphql/GetUserProfileInfo';
import { useAuth } from '../hooks/authContext';
import { UserExpenseSummaryType } from '../graphql/GetUserExpenseSummary';
import { UserExpenseObject } from '../graphql/GetUserExpenseList';
import useUserExpenseForApprovalSummary from '../hooks/useUserExpensesForApprovalSummary';
import useUserExpenseForApprovalList from '../hooks/useUserExpensesForApprovalList';
import { ExpenseStatusesType } from '../graphql/GetExpenseStatuses';
import useExpenseStatuses from '../hooks/useExpenseStatuses';
import { Option } from '../utils/Interfaces';
import FosAddExpenseActionSheet from '../atom/FosAddExpenseActionSheet';

const UserExpensesForApprovalViewModel = () => {
  const { userState } = useAuth()!;
  const expenseForApprovalFilterModal = useRef<HTMLIonModalElement>(null);
  const [userExpenseSummaryForApprovalFiltered, setUserExpenseSummaryForApprovalFiltered] = useState<UserExpenseSummaryType[]>([]);
  const [userExpenseListForApprovalFiltered, setUserExpenseListForApprovalFiltered] = useState<UserExpenseObject[]>([]);
  const [expenseStatusList, setExpenseStatusList] = useState<ExpenseStatusesType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [expenseMethodId, setExpenseMethodId] = useState<number>(0);
  const [filterByStatus, setFilterByStatus] = useState<string>('0');
  const [expenseStatusFilter, setExpenseStatusFilter] = useState<number | null>(null);
  const [defaultStatus, setDefaultStatus] = useState<number>(0);
  const { handleClick } = FosAddExpenseActionSheet();
  const regionId = userState.userAuthPayload?.regionId!;
  const userGuid = window.localStorage.getItem(GUID) as string;

  const { expenseStatuses } = useExpenseStatuses(userState);
  
  useEffect(() => {
    if (expenseStatuses && expenseStatuses.length > 0) {
      const defaultStatusId = expenseStatuses.find(e => e.name.includes('Released to Approver'))?.id;
      if (defaultStatusId) {
        setDefaultStatus(defaultStatusId);
        setFilterByStatus(defaultStatusId.toString());
        setExpenseStatusFilter(defaultStatusId);
      }
    }
  }, [expenseStatuses]);

  useEffect(() => {
    if (expenseStatuses) {
      setExpenseStatusList(expenseStatuses);
    }
  }, [expenseStatuses]);

  const { userExpenseForApprovalSummary, isFetching } = useUserExpenseForApprovalSummary(userState, regionId, userGuid);

  const statusId = expenseStatusFilter || defaultStatus;
  const { userExpenseForApprovalList, isFetching: isFetchingExpenses, refetch: refetchExpenseList } = useUserExpenseForApprovalList(userState, regionId, userGuid, expenseMethodId, statusId);

  const onSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    if (searchTerm) {
      const filteredItems: UserExpenseObject[] = userExpenseForApprovalList?.filter(userExpense => 
        userExpense?.expense.comments?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userExpense?.expense.merchant?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userExpense?.expense.id === Number(searchTerm)) || [];
      setUserExpenseListForApprovalFiltered(filteredItems);
    } else {
      setUserExpenseSummaryForApprovalFiltered(userExpenseForApprovalSummary || []);
      setUserExpenseListForApprovalFiltered(userExpenseForApprovalList || []);
    }

  }, [expenseMethodId, searchTerm, userExpenseForApprovalList, userExpenseForApprovalSummary]);

  const addExpense = () => handleClick();
  const navigateToUserExpenseCategory = (id: number) => {
    setExpenseMethodId(id);
    setSearchTerm('');
  };

  const expenseSummaryItem = userExpenseSummaryForApprovalFiltered.find(userExpenseSummary => userExpenseSummary.id === expenseMethodId)?.expenseMethodType ?? '';
  const selectedExpenseMethodName = t(`${expenseSummaryItem}`);

  const backToCategories = () => {
    setExpenseMethodId(0);
    setSearchTerm('');
  };

  const showResults = () => {
    // do filter
    setExpenseStatusFilter(filterByStatus ? Number(filterByStatus) : null);
    expenseForApprovalFilterModal.current?.dismiss();
  };

  const clearFilter = () => {
    setFilterByStatus(defaultStatus.toString());
    setExpenseStatusFilter(null);
    expenseForApprovalFilterModal.current?.dismiss();
  };

  const expenseStatusListOptions: Option[] = expenseStatusList
    .map((expenseStatus: ExpenseStatusesType) => ({ label: expenseStatus.name, value: expenseStatus.id.toString() }));

  return { 
    userExpenseSummaryForApprovalFiltered,
    userExpenseListForApprovalFiltered,
    isFetching,
    isFetchingExpenses,
    expenseMethodId,
    selectedExpenseMethodName,
    searchTerm,
    expenseForApprovalFilterModal,
    expenseStatusListOptions,
    filterByStatus,
    clearFilter,
    showResults,
    setFilterByStatus,
    onSearchChange,
    addExpense,
    navigateToUserExpenseCategory,
    backToCategories,
    refetchExpenseList,
  };
};

export default UserExpensesForApprovalViewModel;