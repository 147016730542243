import { gql } from '@apollo/client';

export type EquipmentsByClassesType = {
  class: string;
  count: number; 
  equipmentClassId: number;
};

const GET_EQUIPMENTS_BY_CLASSES = gql`
query groupEquipmentsByClasses($regionId: Int!) {
  groupEquipmentsByClasses(regionId: $regionId)
  { 
    class 
    count 
    equipmentClassId
  } 
}`;

export default GET_EQUIPMENTS_BY_CLASSES;