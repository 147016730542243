import { gql } from '@apollo/client';

const GET_BRANCHES_LIST = gql`
query branches($regionId: Int!) {
  branches(regionId: $regionId)
  { 
    branchId 
    regionId 
    branchCode 
    branchName
  } 
}`;

export default GET_BRANCHES_LIST;