import { Storage } from '@ionic/storage';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseCacheAndList from './baseCacheAndList';
import { AdjustersType } from '../pages/helper/AdjustersType';
import GET_ADJUSTERS_LIST from '../graphql/GetAdjustersList';

const useAdjusters = (region: number, userState: UserState, storage: Storage) => {
  const adjustersList:AdjustersType[] = useBaseCacheAndList(region, userState, storage, GET_ADJUSTERS_LIST, claimsKeys.adjustersList(), 'adjusters');
  return adjustersList;
};

export default useAdjusters;