import React, { useRef } from 'react';
import {
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './SelectItem.css';
import { createPortal } from 'react-dom';
import FosSelectItemWithVirtualScroll from './FosSelectItemWithVirtualScroll';

export type SelectOptionProps = React.ComponentProps<typeof IonSelectOption> & { label: string };

export interface Props {
  id: string;
  label: string;
  labelPosition?: 'stacked';
  options: SelectOptionProps[];
  okText?: string;
  cancelText?: string;
  value?: any;
  name?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (event: any) => any;
  onAdditionalChange?: (event: any) => any;
  onSearchInput?: (input: string) => any;
}

const FosSelectBigListItem: React.FC<Props> = ({
  onChange,
  onAdditionalChange,
  onSearchInput,
  id,
  name,
  label,
  value,
  error,
  okText,
  cancelText,
  options,
  labelPosition,
  disabled,
  required,
}) => {
  const { t } = useTranslation();
  const classNames = [
    'fos-select-item',
    error ? 'ion-invalid' : '',
    labelPosition === 'stacked' ? 'fos-select-item-label-stacked' : 'fos-select-item-label-default',
  ]
    .filter(cls => !!cls)
    .join(' ');
  const modal = useRef<HTMLIonModalElement>(null);

  const selectionChanged = (values: string) => {
    if (onChange) onChange(values);
    if (onAdditionalChange) onAdditionalChange(values);
    modal.current?.dismiss();
  };

  return (
    <>
      <IonItem id={`open-modal-${id}`} lines="none" className={classNames} disabled={disabled}>
        <div className="fos-select-border" />
        {label && <IonLabel class="fos-select-label ion-text-wrap" position={labelPosition}>
          <IonText className='fos-select-label-text'>
            {label}{required && <span className="required-asterisk"> *</span>}
          </IonText>
        </IonLabel>}
        {value ?
          <IonLabel class="ion-sel-1-lbl fos-select-item-placeholder" position={labelPosition}>
            <IonText>{options.find(o => o.value === value)?.label}</IonText>
            <div className="select-icon" role="presentation"><div className="select-icon-inner" /></div>
          </IonLabel> :
          <IonLabel class="fos-select-label ion-text-wrap fos-select-item-placeholder" position={labelPosition}>
            <IonText className='fos-text-with-opacity'>{t('choose').toString()}</IonText>
            <div className="select-icon" role="presentation"><div className="select-icon-inner" /></div>
          </IonLabel>
        }
        <div className="arrow" />
      </IonItem>
      {error && <p className="ion-no-margin ion-padding-start">
        <small>
          <IonNote color="danger">{error}</IonNote>
        </small>
      </p>}
      <div className='ion-margin-bottom' />
      {createPortal(
        (<IonModal trigger={`open-modal-${id}`} ref={modal}>
          <FosSelectItemWithVirtualScroll
            name={name}
            title={label}
            items={options}
            value={value}
            okText={okText || t('ok').toString()}
            cancelText={cancelText || t('cancel').toString()}
            onSelectionCancel={() => modal.current?.dismiss()}
            onSelectionChange={selectionChanged}
            onSearchInput={onSearchInput}
          />
        </IonModal>),
        document.querySelector('ion-app')!,
      )}
    </>
  );
};

export default FosSelectBigListItem;
