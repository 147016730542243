import { gql } from '@apollo/client';

const GET_EXPENSE_DETAIL = gql`
query expense ($expenseId: Int!) {
  expense (expenseId: $expenseId) {
    expense {
      id
      currencyId
      exchangeRate
      expenseMethodId
      creditCardId
      gpcategoryId
      gpvendorId
      employeeGuid
      cctransactionId
      mileageTypeId
      mileage
      parentId
      province
      merchant
      comments
      destinations
      taxAmount
      tip
      dateExpense
      branchId
      gpdepartmentId
      totalAmount
      statusId
    }
    expenseUserCreditCards {
      id
      cardNumber
    }
    expenseReceipts {
      id
      expenseId
      fileName
      fileExtension
      filePath
      fileType
      fileSize
      uploadDate
      updateBy
      deleted
    }
    expenseTaxes {
      id
      taxType
      total
      totalOverride
      totalRestricted
      includeInNet
      isOverride
      glAccountIndx
      glAccountStr
      glAccountDesc
      gpAccountCode
      parentId
    }
    expenseClaims {
      id
      expenseId
      regionId
      claimIndx
      claimName
      phaseIndx
      departmentId
      departmentName
      jobCostTypeId
      jobCostTypeName
      jobCostId
      jobCostName
      ratio
      dateExported
      invoiceRefNumber
      creditInvoiceRefNumber
      creditExpenseId
    }
    expenseReceiptFiles
    gpVendor {
      regionId
      regionCode
      vendorid
      vendname
    }    
    childExpenses {
      expense {
          id
          parentId
          employeeGuid
          regionId
          branchId
          dateExpense
          totalAmount
          tip
          netAmount
          taxAmount
          currencyId
          exchangeRate
          expenseMethodId
          creditCardId
          gpcategoryId
          gpdepartmentId
          gpvendorId
          cctransactionId
          mileageTypeId
          mileage
          province
          merchant
          comments
          destinations
          hasErrors
          lastError
          isParent
          isCcreconciled
          isCtvalid
          isGpvalid
          hasTaxRestrictions
          gl00rate
          gl33rate
          statusId
          dateCreated
          createdByGuid
          dateReleased
          dateApproved
          approvedByGuid
          dateDeclined
          declinedByGuid
          approverNotes
          dateFinanceApproved
          approvedFinanceByGuid
          dateFinanceDeclined
          declinedFinanceByGuid
          financeNotes
          dateGpexport
          gpbatchName
          gpdocNumber
          gpexportByGuid
          dateCtexport
          ctpurchVchnumwk
      }
      expenseReceipts {
          id
          expenseId
          fileName
          fileExtension
          filePath
          fileType
          fileSize
          description
          thumbnailFileName
          fileBlob
          uploadDate
          uploadedBy
          updateDate
          updateBy
          deleted
          deletedBy
          deleteDate
          fileMetaData
          fileGuid
      }
      expenseClaims {
          id
          expenseId
          regionId
          claimIndx
          claimName
          phaseIndx
          departmentId
          departmentName
          jobCostTypeId
          jobCostTypeName
          jobCostId
          jobCostName
          ratio
          dateExported
          invoiceRefNumber
          creditInvoiceRefNumber
          creditExpenseId
      }
      gpVendor {
          regionId
          regionCode
          vendorid
          vendname
      }
      expenseTaxes {
          id
          taxType
          total
          totalOverride
          totalRestricted
          includeInNet
          isOverride
          glAccountIndx
          glAccountStr
          glAccountDesc
          gpAccountCode
          parentId
      }
    }
  }
}`;

export default GET_EXPENSE_DETAIL;