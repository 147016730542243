/* eslint-disable react/no-array-index-key */
import { IonAccordionGroup, IonButton, IonContent, IonList } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import AreaAccordion from '../atom/AreaAccordion';
import RoomAccordion from '../atom/RoomAccordion';
import { LevelModelType, RoomModelType } from '../graphql/GetLevelsList';
import { useAuth } from '../hooks/authContext';
import useClaimPhotos from '../hooks/claimPhotos';
import { provideAddedRoom } from '../organism/AddLevelsHelper';
import AddPhotoToClaimForm from '../organism/AddPhotoToClaimForm';
import { Phase } from '../types/Phases';
import './AreaInspection.css';
import { ClaimPhotosType } from '../graphql/GetClaimPhotosList';
import useSketchesList from '../hooks/sketches';

type Props = {
  inspectionLevels: LevelModelType[];
  claimIndx: number;
  phases: Phase[];
  onAddLevel: (newLevel?: LevelModelType, pos?: number) => any;
  onChangeLevelName: (newLevelName: string, pos: number) => any;
  onChangeRoomName: (newRoomName: string, roomPos: number, levelPos: number) => any;
  onRoomDelete: (roomPos: number, levelPos: number, roomId?: number) => any;
  onLevelDelete: (levelPos: number, levelId?: number) => any;
  onPhotosDelete: (photoId: number) => any;
};

const getRoomName = (name: string): string => `Room ${name}`;

const emptyBlob = { blobArray: [] };

const AreaInspection: React.FC<Props> = ({
  onAddLevel,
  inspectionLevels,
  claimIndx,
  onChangeLevelName,
  onChangeRoomName,
  onRoomDelete,
  onLevelDelete,
  onPhotosDelete,
  phases,
}) => {

  const { t } = useTranslation();
  const { userState } = useAuth()!;
  const [claimPictures, setClaimPictures] = useState<{
    blobArray: File[];
  }>(emptyBlob);
  const [isAddPhotoToClaimFormOpen, setIsAddPhotoToClaimFormOpen] = useState<boolean>(false);
  const [selectedRoom, setSelectedRoom] = useState<number>();
  const region = userState.userAuthPayload?.regionId!;
  const [claimPhotosList, setClaimPhotosList] = useState<ClaimPhotosType[]>([]);
  const [refetchSketchesKey] = useState(Date.now());
  const [levelId, setLevelId] = useState<number>(0);
  const { refetch } = useSketchesList(userState, levelId, refetchSketchesKey);

  const { refetch: refetchPhotos } = useClaimPhotos(
    region,
    claimIndx,
    userState,
    claimPhotosList,
    setClaimPhotosList,
  );

  const addLevel = async () => {
    onAddLevel();
  };

  const handleAddRoom = (levelPos: number, roomName: string) => {
    onAddLevel(provideAddedRoom(inspectionLevels, levelPos, roomName), levelPos);
  };

  const handleChangeLevelName = (levelPos: number, levelName: string) => {
    onChangeLevelName(levelName, levelPos);
  };

  const handleChangeRoomName = (roomPos: number, levelPos: number, roomName: string) => {
    onChangeRoomName(roomName, roomPos, levelPos);
  };

  const handleLevelDelete = useCallback(async (levelPos: number, levelId?: number, rooms?: RoomModelType[]) => {
    setLevelId(levelId!);
    const { data } = await refetch();

    if (data?.listSketches?.length! > 0) {
      toast.error(t('cannotDeleteLevelWithSketches'), { duration: 4000 });
      return;
    }

    if (rooms?.length! > 0) {
      toast.error(t('deleteLevelError'), { duration: 4000 });
      return;
    }

    onLevelDelete(levelPos, levelId);
  }, [
    onLevelDelete,
    t,
    refetch,
  ]);

  const deletePhoto = (claimPhotoId: number) => {
    onPhotosDelete(claimPhotoId);
    setTimeout(() => {
      refetchPhotos();
    }, 2000);
  };

  const onDismissAddPhotoToClaimForm = () => {
    setIsAddPhotoToClaimFormOpen(false);
    setClaimPictures(emptyBlob);
  };

  const onSubmitAddPhotoToClaimForm = () => {
    setIsAddPhotoToClaimFormOpen(false);
    setClaimPictures(emptyBlob);
    setTimeout(() => {
      refetchPhotos();
    }, 2000);
    toast.success(t('imageAssociated'), { duration: 4000 });
  };

  return (
    <>
      <IonContent className="ion-padding area-inspection-body" fullscreen>
        <IonList lines="none">
          {inspectionLevels && inspectionLevels.map((item, idx) =>
            <div key={`${item.levelId}_${idx}`}>
              <IonAccordionGroup multiple className='fos-border accordion-group-container'>
                <AreaAccordion
                  claimIndex={claimIndx}
                  levelId={item.levelId! || 1}
                  region={region}
                  onChangeLevelName={(levelName: string) => handleChangeLevelName(idx, levelName)}
                  onLevelDelete={() => handleLevelDelete(idx, item.levelId, item.rooms)}
                  levelName={item.levelName} />
                <IonList>
                  {item.rooms.map((room: RoomModelType, roomIndex: number) =>
                    <div key={item.rooms.indexOf(room)}>
                      <RoomAccordion
                        roomId={room.roomId}
                        roomName={room.roomName}
                        claimPhotos={claimPhotosList.filter(claimPhoto => claimPhoto.roomId === room.roomId)}
                        setSelectedRoom={setSelectedRoom}
                        setClaimPictures={setClaimPictures}
                        setIsAddPhotoToClaimFormOpen={setIsAddPhotoToClaimFormOpen}
                        onChangeRoomName={(roomName: string) => handleChangeRoomName(roomIndex, idx, roomName)}
                        onRoomDelete={() => onRoomDelete(roomIndex, idx, room.roomId)}
                        onPhotoDelete={deletePhoto}
                      />
                    </div>,
                  )}
                </IonList>
                <IonButton
                  className='media-inspection-room-button'
                  onClick={() => handleAddRoom(idx, getRoomName(`${item.rooms.length + 1}`))}
                  expand='block'>
                  {t('addRoom').toString()}
                </IonButton>
              </IonAccordionGroup>
            </div>,
          )
          }
        </IonList>
        <IonButton expand='block' className='media-inspection-button' onClick={addLevel}>{t('addLevel').toString()}</IonButton>
      </IonContent>
      <AddPhotoToClaimForm
        isOpen={isAddPhotoToClaimFormOpen}
        claimPhotos={claimPictures}
        onDismiss={() => onDismissAddPhotoToClaimForm()}
        onSubmit={() => onSubmitAddPhotoToClaimForm()}
        region={region}
        claimIndex={claimIndx}
        phases={phases}
        selectedRoomId={selectedRoom}
      />
    </>
  );
};

export default AreaInspection;