import { gql } from '@apollo/client';

const GET_EQUIPMENT_BRANCHES_LIST = gql`
query branches($regionId: Int!) {
  branches(regionId: $regionId)
  { 
    id
    value
  } 
}`;

export default GET_EQUIPMENT_BRANCHES_LIST;