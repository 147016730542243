/**
 * Bottom navigation icons. 
* */ 
const briefcase = 'assets/images/icons/ic_briefcase.png';
const briefcaseSelected = 'assets/images/icons/ic_briefcase_selected.png';
const equipment = 'assets/images/icons/ic_equipment.png';
const equipmentSelected = 'assets/images/icons/ic_equipment_selected.png';
const profile = 'assets/images/icons/ic_profile.png';
const profileSelected = 'assets/images/icons/ic_profile_selected.png';

export enum Tabs {
  JOBS = 'jobs',
  EQUIPMENT = 'equipment',
  PROFILE = 'profile',
}

export const jobsTabIcon = (currentTab: CustomEvent<any> | null | undefined): string => {
  if (currentTab == null) {
    // returning the default first icon
    return briefcaseSelected;
  }
  return currentTab?.detail.tab === Tabs.JOBS ? briefcaseSelected : briefcase;
};

export const equipmentTabIcon = (currentTab: CustomEvent<any> | undefined): string => currentTab?.detail.tab === Tabs.EQUIPMENT ?  equipmentSelected : equipment;

export const profileTabIcon = (currentTab: CustomEvent<any> | undefined): string => currentTab?.detail.tab === Tabs.PROFILE ? profileSelected : profile;