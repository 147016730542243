import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
  useIonRouter,
  useIonViewDidEnter,
  isPlatform,
} from '@ionic/react';
import { t } from 'i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { closeOutline } from 'ionicons/icons';
import { useAuth } from '../../../../hooks/authContext';
import useEquipmentClasses from '../../data/hooks/useEquipmentClasses';
import useEquipmentModels from '../../data/hooks/useEquipmentModels';
import useStorage from '../../../../hooks/storage';
import useEquipmentBranches from '../../data/hooks/useEquipmentBranches';
import useEquipmentCostCategories from '../../data/hooks/useEquipmentCostCategories';
import useEquipmentStatus from '../../data/hooks/useEquipmentStatus';
import useEquipmentConditions from '../../data/hooks/useEquipmentConditions';
import useEquipment from '../../data/hooks/useEquipment';
import { EquipmentInfoType } from '../../data/graphql/GetEquipmentInfo';
import getGraphQLClient, { DomainParams } from '../../../../hooks/graphQLClientUtil';
import SAVE_EQUIPMENT_FORM from '../../data/graphql/SaveEquipmentForm';
import { ApiStatus } from '../../../../pages/helper/Const';
import FosFormHeader from '../../../../atom/FosFormHeader';
import FosToggle from '../../../../atom/FosToggle';
import FosSelectItem from '../../../../atom/FosSelectItem';
import FosDateTime from '../../../../atom/FosDateTime';
import StackedInput from '../../../../atom/StackedInput';
import { GUID } from '../../../../graphql/GetUserProfileInfo';
import FosCurrencyInput from '../../../../components/FosCurrencyInput';
import FosSelectBigListItem from '../../../../atom/FosSelectBigListItem';
import { getErrorMsg } from '../../../../pages/helper/AppHelper';

interface RouteParams {
  itemId: string;
  assetTag: string;
}

interface Option {
  label: string;
  value: string;
}

type FormData = {
  id: number;
  hasTranscationHistory: boolean;
  active: boolean;
  statusId: string;
  itemClassId: string;
  itemModelId: string;
  assetTag: string;
  itemNumber: string;
  purchaseDate: string;
  disposalDate: string;
  saleDate: string;
  vendor: string;
  serialNumber: string;
  purchasePrice: number;
  lifeCycle: string;
  jobCostCatId: string;
  branchId: string;
  transitBranchId: string;
  saleAmount: number;
  conditionId: string;
};

const EquipmentEdition: React.FC = () => {
  const { userState, checkTokenExpiration } = useAuth()!;
  const region = userState.userAuthPayload?.regionId!;
  const [isMobile, setIsMobile] = useState(false);
  const [assetTagValue, setAssetTagValue] = useState('');
  const [itemNumberValue, setItemNumberValue] = useState('');
  const [vendorValue, setVendorValue] = useState('');
  const [serialNumberValue, setSerialNumberValue] = useState('');
  const [lifeCycleValue, setLifeCycleValue] = useState('');
  const [saveError, setSaveError] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [filteredModelsOptions, setFilteredModelsOptions] = useState<Option[]>([]);
  const { itemId, assetTag } = useParams<RouteParams>();
  const navigation = useIonRouter();

  const equipmentClasses = useEquipmentClasses(region, userState, useStorage());
  const equipmentClassesOptions = equipmentClasses.map(equipmentClass => ({ label: equipmentClass.class, value: String(equipmentClass.classIndx) }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const equipmentModels = useEquipmentModels(region, userState, useStorage())
    .sort((a, b) => a.model.localeCompare(b.model));
  const equipmentBranches = useEquipmentBranches(region, userState, useStorage());
  const equipmentBranchesOptions = equipmentBranches.map(branch => ({ label: branch.value, value: branch.id }));
  const equipmentCostCategories = useEquipmentCostCategories(region, userState, useStorage());
  const equipmentCostCategoriesOptions = equipmentCostCategories.map(costCategory => ({ label: costCategory.value, value: costCategory.id }));
  const equipmentStatus = useEquipmentStatus(region, userState, useStorage());
  const equipmentStatusOptions = equipmentStatus.map(status => ({ label: status.value, value: status.id }));
  const equipmentConditions = useEquipmentConditions(region, userState, useStorage());
  const equipmentConditionsOptions = equipmentConditions.map(condition => ({ label: condition.value, value: condition.id }));

  const { data, isLoading } = useEquipment(userState, { regionId: region, itemId: Number(itemId) });
  const equipment: EquipmentInfoType = !isLoading ? data?.equipment : null;

  const equipmentModelsOptions = equipmentModels.filter(model => model.equipmentClassId?.toString() === equipment?.itemClass?.id)
    .map(model => ({ label: model.model, value: String(model.modelIndx) }));
  const schema = yup.object({
    active: yup.boolean(),
    statusId: yup.string().required(t('equipmentStatusErrorMessage')),
    itemClassId: yup.string().required(t('equipmentClassErrorMessage')),
    itemModelId: yup.string().required(t('equipmentModelErrorMessage')),
    assetTag: yup.string().nullable()
      .when({
        is: (val: string) => val?.length > 0,
        then: yup.string().max(7, t('equipmentAssetTagMaxLength')),
      }),
    itemNumber: yup.string().required(t('equipmentItemNumberErrorMessage')),
    purchaseDate: yup.string().nullable(),
    disposalDate: yup.string().nullable(),
    saleDate: yup.string().nullable(),
    vendor: yup.string().nullable()
      .when({
        is: (val: string) => val?.length > 0,
        then: yup.string().max(50, t('equipmentVendorMaxLength')),
      }),
    serialNumber: yup.string().required(t('equipmentSerialNumberErrorMessage')),
    purchasePrice: yup.string().nullable(),
    lifeCycle: yup.string().nullable(),
    jobCostCatId: yup.string().required(t('equipmentJobCostCatErrorMessage')),
    branchId: yup.string().required(t('equipmentBranchErrorMessage')),
    transitBranchId: yup.string().required(t('equipmentBranchErrorMessage')),
    saleAmount: yup.string().nullable(),
    conditionId: yup.string().nullable(),
  }).required();

  const { handleSubmit, register, control, setValue, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  useIonViewDidEnter(() => {
    setIsMobile(isPlatform('mobile'));
  });

  useEffect(() => {
    setValue('active', equipment?.active ?? false);
    setValue('statusId', equipment?.status?.id);
    setValue('itemClassId', equipment?.itemClass?.id);
    setValue('itemModelId', equipment?.itemModel?.id);
    setValue('assetTag', equipment?.assetTag ?? assetTag);
    setValue('itemNumber', equipment?.itemNumber ?? '');
    setValue('purchaseDate', equipment?.purchaseDate ?? null);
    setValue('disposalDate', equipment?.disposalDate ?? null);
    setValue('saleDate', equipment?.saleDate ?? null);
    setValue('vendor', equipment?.vendor ?? null);
    setValue('serialNumber', equipment?.serialNumber ?? '');
    setValue('purchasePrice', equipment?.purchasePrice ? Number.parseFloat(equipment?.purchasePrice) : 0);
    setValue('lifeCycle', equipment?.lifeCycle ?? null);
    setValue('saleAmount', equipment?.saleAmount ? Number.parseFloat(equipment?.saleAmount) : 0);
    setValue('jobCostCatId', equipment?.jobCostCat?.id);
    setValue('conditionId', equipment?.condition?.id ?? null);
    setValue('branchId', equipment?.branch?.id);
    setValue('transitBranchId', equipment?.transitBranch?.id);
  }, [
    assetTag,
    equipment?.id,
    equipment?.active,
    equipment?.assetTag,
    equipment?.branch?.id,
    equipment?.disposalDate,
    equipment?.itemClass?.id,
    equipment?.itemModel?.id,
    equipment?.itemNumber,
    equipment?.jobCostCat?.id,
    equipment?.lifeCycle,
    equipment?.purchaseDate,
    equipment?.purchasePrice,
    equipment?.serialNumber,
    equipment?.status?.id,
    equipment?.transitBranch?.id,
    equipment?.vendor,
    equipment?.condition,
    equipment?.saleAmount,
    equipment?.saleDate,
    setValue]);

  useEffect(() => {
    setAssetTagValue(equipment?.assetTag);
    setItemNumberValue(equipment?.itemNumber);
    setVendorValue(equipment?.vendor);
    setSerialNumberValue(equipment?.serialNumber);
    setLifeCycleValue(equipment?.lifeCycle);
    setValue('purchaseDate', equipment?.purchaseDate ?? null);
    setValue('disposalDate', equipment?.disposalDate ?? null);
    setValue('saleDate', equipment?.saleDate ?? null);
  }, [
    equipment?.assetTag,
    equipment?.disposalDate,
    equipment?.itemNumber,
    equipment?.lifeCycle,
    equipment?.purchaseDate,
    equipment?.saleDate,
    equipment?.serialNumber,
    equipment?.vendor,
    setValue]);

  const isInvalidItemId = () => itemId === 'undefined' || Number(equipment?.id) === 0;

  /*
  * Mutation to save the form
  * */
  const { isLoading: isSaving, mutateAsync } = useMutation({
    mutationFn: async (formData: FormData) => {
      const item: any = { ...formData };
      item.id = isInvalidItemId() ? 0 : Number(itemId);
      item.hasTranscationHistory = equipment?.hasTranscationHistory ?? false;

      // For saving the equipment, the service needs to receive a null value if it is empty.
      // otherwise it returns error. ¯\_(ツ)_/¯
      item.vendor = item.vendor === '' ? null : item.vendor;
      item.purchasePrice = (item.purchasePrice === undefined || item.purchasePrice === '') ? null : item.purchasePrice.toString();
      item.lifeCycle = item.lifeCycle === '' ? null : item.lifeCycle;
      item.saleAmount = (item.saleAmount === undefined || item.saleAmount === '') ? null : item.saleAmount.toString();

      await checkTokenExpiration();
      return getGraphQLClient(
        userState,
        DomainParams.Equipments)
        .request(SAVE_EQUIPMENT_FORM, {
          regionId: region,
          userGuid: window.localStorage.getItem(GUID),
          item,
        });
    },
  });

  /* 
  * Submit form
  * */
  const onSubmit = handleSubmit((formData: FormData) => {
    mutateAsync(formData)
      .then((response) => {
        if (response?.saveEquipment?.status === ApiStatus.SUCCESS) {
          toast.success(t('equipmentSaved'), { duration: 4000 });
          setTimeout(() => { navigation.goBack(); }, 1000);
          return;
        }
        throw new Error(response.saveEquipment?.message || t('saveEquipmentError'));
      })
      .catch((e) => {
        const msg = getErrorMsg(e);
        const message = msg || t('saveEquipmentError');
        setSaveError(message);
      });
  });

  const onSaveClick = () => {
    document.getElementById('editEquipmentForm')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const handleClassSelection = (value: any) => {
    setSelectedClass(value);
    setValue('itemModelId', '');
  };

  useEffect(() => {
    if (selectedClass) {
      const filteredModels = equipmentModels.filter(model => model.equipmentClassId?.toString() === selectedClass);
      const newModelOptions = filteredModels.map(model => ({
        label: model.model,
        value: String(model.modelIndx),
      }));
      setFilteredModelsOptions(newModelOptions);
    }
  }, [selectedClass, equipmentModels]);

  const handleJobCostCatSelection = (): string => equipmentCostCategories.find(category => category.id === equipment?.jobCostCat?.id)?.value ?? '';
  const handleConditionSelection = (): string => equipmentConditions.find(condition => condition.id === equipment?.condition?.id)?.value ?? '';

  return (
    <IonPage>
      <FosFormHeader
        title={ itemId === 'undefined' ? t('addEquipment') : t('editItem')}
        onSaveClick={onSaveClick}
        onCancelClick={() => navigation.goBack()} />
      <IonContent>
        <form id="editEquipmentForm" onSubmit={onSubmit}>
          <IonList lines='none' className='ion-padding'>
            <Controller
              control={control}
              name="active"
              defaultValue={false}
              render={({ field: { value, onChange, ref } }) => (
                <FosToggle
                  label={t('active')}
                  checked={value}
                  ref={ref}
                  onChange={onChange} />
              )} />
            <FosSelectItem
              placeholder={equipment?.status?.value}
              label={t('status')}
              options={equipmentStatusOptions}
              error={errors.statusId?.message}
              {...register('statusId')}
              required />
            <Controller
              control={control}
              name="itemClassId"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="selectClass"
                  onChange={onChange}
                  onAdditionalChange={handleClassSelection}
                  value={value}
                  name={name}
                  label={t('class')}
                  options={equipmentClassesOptions}
                  error={error?.message} 
                  required/>
              )} />
            <Controller
              control={control}
              name="itemModelId"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="selectModel"
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={t('itemModel')}
                  options={filteredModelsOptions.length === 0 ? equipmentModelsOptions : filteredModelsOptions}
                  error={error?.message} 
                  required/>
              )} />
            <IonGrid className="ion-no-padding ion-no-margin">
              <IonRow className="ion-no-padding ion-no-margin">
                <IonCol className="ion-margin-end">
                  <StackedInput
                    label={t('assetTag')}
                    value={assetTagValue}
                    onIonChange={event => setAssetTagValue(event.target.value)}
                    placeholder='Ex.: E002123'
                    error={errors.assetTag?.message}
                    {...register('assetTag')} />
                </IonCol>
                <IonCol>
                  <StackedInput
                    label={t('itemNumber')}
                    value={itemNumberValue}
                    onIonChange={event => setItemNumberValue(event.target.value)}
                    placeholder='Ex.: E002123'
                    error={errors.itemNumber?.message}
                    {...register('itemNumber')}
                    required />
                </IonCol>
              </IonRow>
            </IonGrid>
            <Controller
              control={control}
              name="purchaseDate"
              render={({ field }) => (
                <FosDateTime
                  id="purchaseDate"
                  label={t('purchaseDate')}
                  presentation='date'
                  showDefaultButtons
                  {...field}
                  onIonChange={e => field.onChange(e.detail.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="disposalDate"
              render={({ field }) => (
                <FosDateTime
                  id="disposalDate"
                  label={t('disposalDate')}
                  presentation='date'
                  showDefaultButtons
                  {...field}
                  onIonChange={e => field.onChange(e.detail.value)}
                />
              )}
            />

            <Controller
              control={control}
              name="saleDate"
              render={({ field }) => (
                <FosDateTime
                  id="saleDate"
                  label={t('saleDate')}
                  presentation='date'
                  showDefaultButtons
                  {...field}
                  onIonChange={e => field.onChange(e.detail.value)}
                />
              )}
            />
            <StackedInput
              label={t('vendor')}
              value={vendorValue}
              onIonChange={event => setVendorValue(event.target.value)}
              error={errors.vendor?.message}
              {...register('vendor')} />
            <StackedInput
              label={t('serialNumber')}
              value={serialNumberValue}
              onIonChange={event => setSerialNumberValue(event.target.value)}
              error={errors.serialNumber?.message}
              {...register('serialNumber')} 
              required />
            <IonGrid className="ion-no-padding ion-no-margin">
              <IonRow className="ion-no-padding ion-no-margin">
                <IonCol className="ion-margin-end">
                  <Controller
                    control={control}
                    name="purchasePrice"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FosCurrencyInput
                        value={value?.toString()}
                        label={t('purchasePrice')}
                        onChange={onChange}
                        error={error?.message} />
                    )} />
                </IonCol>
                <IonCol className="ion-margin-end">
                  <StackedInput
                    label={t('lifeCycle')}
                    value={lifeCycleValue}
                    onIonChange={event => setLifeCycleValue(event.target.value)}
                    type='number'
                    placeholder='0'
                    {...register('lifeCycle')} />
                </IonCol>
                <IonCol>
                  <Controller
                    control={control}
                    name="saleAmount"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FosCurrencyInput
                        value={value?.toString()}
                        label={t('saleAmount')}
                        onChange={onChange}
                        error={error?.message} />
                    )} />
                </IonCol>
              </IonRow>
            </IonGrid>
            <FosSelectItem
              placeholder={handleJobCostCatSelection()}
              label={t('jobCostCat')}
              options={equipmentCostCategoriesOptions}
              error={errors.jobCostCatId?.message}
              {...register('jobCostCatId')}
              required />
            <FosSelectItem
              placeholder={handleConditionSelection()}
              label={t('condition')}
              options={equipmentConditionsOptions}
              {...register('conditionId')} />
            <IonGrid className="ion-no-padding ion-no-margin">
              <IonRow className="ion-no-padding ion-no-margin">
                <IonCol className={`${isMobile ? '' : 'ion-margin-end'}`}>
                  <FosSelectItem
                    placeholder={equipment?.branch?.value}
                    label={t('branch')}
                    options={equipmentBranchesOptions}
                    error={errors.branchId?.message}
                    {...register('branchId')}
                    required />
                </IonCol>
                <IonCol>
                  <FosSelectItem
                    placeholder={equipment?.transitBranch?.value}
                    label={t('transitBranch')}
                    options={equipmentBranchesOptions}
                    error={errors.transitBranchId?.message}
                    {...register('transitBranchId')}
                    required />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonList>
        </form>
      </IonContent>
      <IonLoading isOpen={isLoading} message={t('loading')} duration={3000} />
      <IonLoading isOpen={isSaving} message={t('saving')} duration={3000} />
      <IonToast
        isOpen={!!saveError}
        message={saveError}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => { setSaveError(''); }} />
      <Toaster
        containerStyle={{
          top: 50,
          left: 20,
          bottom: 20,
          right: 20,
        }} />
    </IonPage>
  );
};

export default EquipmentEdition;