import { gql } from '@apollo/client';

const SAVE_SAFETY_INFO = gql`
mutation saveInspectionSafety($info: InspectionSafetyDtoInput!) {
  saveInspectionSafety(info: $info)
  {
    itemId
    status
    message
  }
}`;

export default SAVE_SAFETY_INFO;
