import React, { useEffect } from 'react';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonList,
  IonLoading,
  IonToast,
} from '@ionic/react';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import { closeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import FosLabelInputValue from '../atom/FosLabelInputValue';
import FormItemCheckbox from '../atom/FormItemCheckbox';
import PreviewModal, { ReportType } from '../atom/PreviewModal';
import FosFormatTextArea from '../atom/FosFormatTextArea';
import useSafetyViewModel, { SafetyProps } from './SafetyViewModel';

const Safety: React.FC<SafetyProps> = ({
  claimId,
  claimYearOfConstruction,
  phases,
  onSaveClick,
  mandatoryFieldsInvalid,
  isMandatoryFieldsInvalid,
  hasChangedMessageControl,
}) => {
  const {
    control,
    html,
    isModalOpen,
    isSaving,
    onPreviewClick,
    onSubmit,
    saveError,
    setIsModalOpen,
    setSaveError,
    onReportGenerated,
    onReportError,
    generateRepertMessage,
    setGenerateReportMessage,
    isGeneratingReport,
    setIsGeneratingReport,
    isYearOfConstructionValid,
    hasChanged,
    testMandatoryField,
    MandatoryFields,
    handleUserChange,
    trigger,
  } = useSafetyViewModel(claimId, claimYearOfConstruction, onSaveClick, mandatoryFieldsInvalid, isMandatoryFieldsInvalid);

  const { i18n } = useTranslation();
  useEffect(() => {
    if (hasChanged) {
      hasChangedMessageControl(true);
      if (!isYearOfConstructionValid) {
        mandatoryFieldsInvalid(true);
      }
    } else {
      mandatoryFieldsInvalid(false);
      hasChangedMessageControl(false);
    }
  }, [hasChanged, hasChangedMessageControl, isYearOfConstructionValid, mandatoryFieldsInvalid]);

  const onSafetyPreviewClick = async () => {
    await trigger();
    if (hasChanged && !isYearOfConstructionValid) {
      mandatoryFieldsInvalid(true);
      toast.error(t('mandatoryFieldsNotValid'));
      return;
    }
    if (isYearOfConstructionValid) {
      onPreviewClick();
    } else {
      toast.error(t('mandatoryFieldsNotValid'));
      return;
    }
    mandatoryFieldsInvalid(false);
  };

  return (
    <>
      <IonContent>
        <form id="addSafetyForm" onSubmit={onSubmit}>
          <IonList className="ion-padding">
            <Controller
              name="yearOfConstruction"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FosLabelInputValue
                  value={value}
                  onIonChange={(value) => {
                    onChange(value);
                    testMandatoryField(MandatoryFields.YearOfConstruction);
                    handleUserChange();
                  }}
                  label={t('yearOfConstruction')}
                  error={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="demolished"
              defaultValue={false}
              render={({ field: { value, onChange, ref } }) => (
                <FormItemCheckbox
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    handleUserChange();
                  }}
                  ref={ref}
                  label={t('safetyCheckboxDemolished').toString()}
                />
              )}
            />
            <Controller
              control={control}
              name="utility"
              defaultValue={false}
              render={({ field: { value, onChange, ref } }) => (
                <FormItemCheckbox
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    handleUserChange();
                  }}
                  ref={ref}
                  label={t('safetyCheckboxUtility').toString()}
                />
              )}
            />
            <Controller
              control={control}
              name="crawlspace"
              defaultValue={false}
              render={({ field: { value, onChange, ref } }) => (
                <FormItemCheckbox
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    handleUserChange();
                  }}
                  ref={ref}
                  label={t('safetyCheckboxCrawlSpace').toString()}
                />
              )}
            />
            <Controller
              control={control}
              name="heights"
              defaultValue={false}
              render={({ field: { value, onChange, ref } }) => (
                <FormItemCheckbox
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    handleUserChange();
                  }}
                  ref={ref}
                  label={t('safetyCheckboxHeight').toString()}
                />
              )}
            />
            <FosFormatTextArea
              label={t('safetyHazards').toString()}
              control={control}
              name="hazards"
              onInputChange={() => handleUserChange()}
            />
            <FosFormatTextArea
              label={t('safetyCorrectiveActions').toString()}
              control={control}
              name="correctiveActions"
              onInputChange={() => handleUserChange()}
            />
          </IonList>
        </form>
        <IonFooter>
          <IonButton
            className="ion-padding-start ion-padding-end ion-margin-bottom"
            expand="block"
            fill="outline"
            onClick={onSafetyPreviewClick}
          >
            {t('previewSafetyReport').toString()}
          </IonButton>
        </IonFooter>
      </IonContent>
      <IonLoading isOpen={isSaving} message={t('savingSiteInfo')} duration={1} />
      <IonLoading isOpen={isGeneratingReport} message={t('generatingReport')} duration={1} />
      <IonToast
        isOpen={!!saveError}
        message={saveError}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => {
          setSaveError('');
        }}
      />
      <IonToast
        isOpen={!!generateRepertMessage}
        message={generateRepertMessage}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => {
          setGenerateReportMessage('');
        }}
        duration={3000}
        position="top"
      />
      <PreviewModal
        html={html}
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        claimIndx={parseInt(claimId, 10)}
        reportName={ReportType.SAFETY}
        onGenerate={() => setIsGeneratingReport(true)}
        onError={onReportError}
        onSuccess={onReportGenerated}
        language={i18n.language}
        phases={phases}
      />
    </>
  );
};

export default Safety;
