import { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import GET_EXPENSE_DEPARTMENTS from '../graphql/ExpGetExpenseDepartments';
import useBaseQuery from './baseQuery';

const useExpenseDepartments = (branchId: number = -1, categoryId: number = -1) => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const page = 1;
  const enabled: boolean = (branchId > 0 && categoryId > 0);

  const itemsPerPage = 1000;
  const { isLoading, data } = useBaseQuery(
    regionId,
    { branchId: Number(branchId), categoryId: Number(categoryId), page, itemsPerPage },
    userState,
    enabled,
    GET_EXPENSE_DEPARTMENTS,
    ['expDepartments'],
    DomainParams.Payroll,
  );

  return { isLoading, data };
};

export default useExpenseDepartments;