/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import { filterOutline, close, closeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import dateSub from 'date-fns/sub';
import toast from 'react-hot-toast';
import SearchInput from '../atom/SearchInput';
import JobCard from '../molecule/JobCard';
import ButtonIcon from '../atom/ButtonIcon';
import { useAuth } from '../hooks/authContext';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import GET_JOBS_LIST, { XactModel } from '../graphql/GetJobsList';
import FilterModal from './FilterModal';
import './ImportAssignmentModal.css';
import SelectDateItem from '../atom/SelectDateItem';
import useBranches from '../hooks/branches';
import useStorage from '../hooks/storage';
import FosSelectItem from '../atom/FosSelectItem';

const closeIcon = 'assets/images/icons/ic_close.png';

interface Props {
  isOpen: boolean;
  onClose?: (item?: XactModel) => any;
}

interface Filter {
  dateFrom: string;
  dateTo: string;
  branchId?: string;
}

interface FilterProps {
  branches: {
    label: string;
    value: string;
  }[]
  onShowResults: (filter: Filter) => any;
}

const ImportAssignmentFilter = ({ branches, onShowResults }: FilterProps) => {
  const filterModal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation();
  const [branchId, setBranchId] = useState('');
  const [dateFrom, setDateFrom] = useState(dateSub(new Date(), { years: 1 }).toISOString());
  const [dateTo, setDateTo] = useState(new Date().toISOString());

  const handleShowResults = () => {
    filterModal.current?.dismiss();
    onShowResults({ dateFrom, dateTo, branchId });
  };

  const handleClearFilter = () => {
    setDateFrom(dateSub(new Date(), { years: 1 }).toISOString());
    setDateTo(new Date().toISOString());
    setBranchId('');
  };

  return (
    <FilterModal
      ref={filterModal}
      trigger="showAssignmentsFilterButton"
      onShowResults={handleShowResults}
      onClose={() => filterModal.current?.dismiss()}
      onClear={handleClearFilter}
    >
      <SelectDateItem id="dateFrom" label="From" presentation="date" value={dateFrom} onIonChange={(event: CustomEvent) => setDateFrom(new Date(event.detail?.value).toISOString())} />
      <SelectDateItem id="dateTo" label="To" presentation="date" value={dateTo} onIonChange={(event: CustomEvent) => setDateTo(new Date(event.detail?.value).toISOString())} />
      <FosSelectItem value={branchId} onChange={(event) => setBranchId(event.detail.value)} labelPosition="stacked" label={t('branchId')} options={branches} />
    </FilterModal>
  );
};

const ImportAssignmentModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState);
  const [searchTerm, setSearchTerm] = useState('');
  const [branchId, setBranchId] = useState<string>();
  const [dateFrom, setDateFrom] = useState(dateSub(new Date(), { years: 1 }).toISOString());
  const [dateTo, setDateTo] = useState(new Date().toISOString());
  const [page] = useState(1);
  const region = userState.userAuthPayload?.regionId!;
  const storage = useStorage();
  const branches = useBranches(region, userState, storage);
  const brancheOptions = branches.map(branch => ({ label: branch.branchCode, value: branch.branchId }));
  const isFilterEnabled = process.env.REACT_APP_ENV !== 'prod';
  const [saveError, setSaveError] = useState('');

  const variables = {
    regionId: region,
    term: searchTerm,
    page,
    dateFrom,
    dateTo,
    branchId,
    itemsPerPage: 100,
    show: '0', // unlinked assignments
  };

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ['getJobsList', variables],
    queryFn: (ctx: QueryFunctionContext) => graphQLClient.request(GET_JOBS_LIST, ctx.queryKey[1]),
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isFetching || isLoading) return;
    const respError: any = error;
    if (respError?.response?.ErrorCode === 400) {
      setSaveError(t('errorXSS'));
      return;
    }
    const searchAssignments = document.getElementById('search-assignments-bar') as HTMLIonSearchbarElement;
    if (searchAssignments?.value) {
      // keep searching
      searchAssignments.querySelector('input')?.focus();
    }
  }, [error, isFetching, isLoading, t]);

  const showFilterResults = (filter: Filter) => {
    setDateFrom(filter.dateFrom);
    setDateTo(filter.dateTo);
    setBranchId(filter.branchId);
  };

  const resetSearchCriteria = () => {
    setSearchTerm('');
    setBranchId('');
    setDateFrom(dateSub(new Date(), { years: 1 }).toISOString());
    setDateTo(new Date().toISOString());
  };

  const handleClose = (item?: XactModel) => {
    resetSearchCriteria();
    if (onClose) onClose(item);
  };

  return (
    <IonModal isOpen={isOpen} class="custom-modal">
      <IonHeader class="ion-no-border">
        <IonToolbar class="header">
          <IonTitle>{t('importAssignment').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => handleClose()}>
              <div className="close-icon-container">
                <IonIcon className="tools-close-button" icon={close} />
              </div>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="bg-same-as-content">
          <div className="assignment-searchbar-container">
            <SearchInput
              id="search-assignments-bar"
              debounce={1000}
              onSearch={(val: string) => setSearchTerm(val)}
            />
            {isFilterEnabled && <ButtonIcon id="showAssignmentsFilterButton" icon={filterOutline} />}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {data?.jobs?.items?.map((item: XactModel, idx: number) => (
          <JobCard
            key={idx}
            claimNumber={item.xactTransactionId}
            lossType={item.typeOfLoss}
            fullAddr={item.address?.trim()}
            projAddr=""
            projCity=""
            projProv=""
            projectManager=""
            propertyManager=""
            projectName={item.contactName || t('noContact')}
            status=""
            businessContact={item.contactName || t('noContact')}
            claimIndx={item.claimIndx}
            dateOpened={new Date(item.receiveDate)}
            hideButtons
            onClick={() => handleClose(item)}
          />
        ))}
        <IonLoading
          isOpen={isFetching || isLoading}
          message={t('loadingAssignments') || ''}
          duration={5000}
        />
        <IonToast
          isOpen={!!saveError}
          message={saveError}
          buttons={[{ role: 'cancel', icon: closeOutline }]}
          onDidDismiss={() => { setSaveError(''); }}
          />
      </IonContent>
      <ImportAssignmentFilter branches={brancheOptions} onShowResults={showFilterResults} />
    </IonModal>
  );
};

export default ImportAssignmentModal;