const equipmentKeys = {
  equipments:() => ['findEquipments'],
  equipmentClasses:() => ['equipmentClasses'],
  equipmentModels:() => ['equipmentModels'],
  equipmentBranches:() => ['equipmentBranches'],
  equipmentCostCategories:() => ['equipmentCostCategories'],
  equipmentStatusList:() => ['equipmentStatusList'],
  equipmentConditions:() => ['equipmentConditions'],
  equipmentByClasses:() => ['equipmentsByClasses'],
};

export default equipmentKeys;
