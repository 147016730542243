import { useQuery } from '@tanstack/react-query';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import GET_EXPENSE_CATEGORIES_BY_BRANCH from '../graphql/ExpGetExpenseCategoriesByBranch';
import { ExpenseCategoryByBranch } from '../pages/helper/ExpenseCategoryType';

const useExpenseCategoriesByBranch = (branchId: number = -1) => {
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState, DomainParams.Payroll);
  const { isLoading, data } = useQuery({
    queryKey: ['expCategories', branchId],
    queryFn: () => 
      graphQLClient.request(GET_EXPENSE_CATEGORIES_BY_BRANCH, { branchId: Number(branchId) }),
    refetchOnWindowFocus: false,
  });
  return { isLoading, data: (data?.expenseCategoriesByBranch || []) as ExpenseCategoryByBranch[] };
};

export default useExpenseCategoriesByBranch;