import { Browser } from '@capacitor/browser';
import { isPlatform } from '@ionic/react';
import { HYBRID } from '../../hooks/photo';

const openDocumentURL = async (url:string) : Promise<void> => {
  if (isPlatform(HYBRID)) {
    await Browser.open({ url });
  } else {
    window.open(url);
  }
};
export default openDocumentURL;