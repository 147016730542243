import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import UNDO_EXPENSE from '../graphql/UndoExpense';
import { GUID } from '../graphql/GetUserProfileInfo';

/*
* GraphQL call: undo Expense
* */
const useUndoExpense = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState, DomainParams.Payroll);
  const userGuid = window.localStorage.getItem(GUID) as string;
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (id: number) => graphQLClient.request(UNDO_EXPENSE, { userGuid, id }),
  });
  return { isLoading, mutateAsync };
};

export default useUndoExpense;