/* eslint-disable react/require-default-props */
import React from 'react';
import { IonSearchbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './SearchInput.css';

interface Props {
  id?: string;
  debounce?: number;
  onSearch?: (value: string) => any
}

const SearchInput = ({ id, debounce, onSearch }: Props) => {
  const { t } = useTranslation();
  return (
    <IonSearchbar
      id={id}
      className="search-input"
      debounce={debounce}
      onIonChange={(ev) => { if (onSearch) onSearch(ev.target.value || ''); }}
      placeholder={t('search').toString()}
    />
  );
};

export default SearchInput;
