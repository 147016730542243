import { useQuery } from '@tanstack/react-query';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import GET_EXPENSE_RECEIPT_FILES from '../graphql/ExpenseReceiptFiles';
import { ExpenseReceiptFile } from './expSaveExpense';

const useExpenseReceiptFile = (expenseId: number, onSuccess: ((data: any) => void) | undefined) => {
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState, DomainParams.Payroll);
  const enabled = !!expenseId;
  const { isLoading, isFetching, data } = useQuery({
    queryKey: ['expenseReceiptFile', expenseId],
    queryFn: () => 
      graphQLClient.request(GET_EXPENSE_RECEIPT_FILES, { expenseId }),
    refetchOnWindowFocus: false,
    enabled,
    onSuccess,
  });
  return { isLoading: isLoading || isFetching, data: (data?.expenseReceiptFile?.expenseReceiptFile || {}) as ExpenseReceiptFile };
};

export default useExpenseReceiptFile;