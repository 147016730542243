import { useState } from 'react';
import { UserState } from '../../../../hooks/authUtils';
import useBaseQuery from '../../../../hooks/baseQuery';
import { DomainParams } from '../../../../hooks/graphQLClientUtil';
import GET_EQUIPMENTS_LIST, { EquipmentsType } from '../graphql/GetEquipmentsList';
import equipmentKeys from './equipmentKeys';


const useFilterAndSearchEquipments = (region: number, userState: UserState, classId: any, variables: any) => {
  const [equipmentsList, setEquipmentsList] = useState<EquipmentsType[]>([]);

  const onSuccess = async (data: any) => {    
    const expenseList = data?.equipments || [];
    setEquipmentsList(expenseList);
  };
  const enabled = classId === null || !!classId;
  const { isLoading, isFetching, error, refetch } = useBaseQuery(
    region,
    variables,
    userState,
    enabled,
    GET_EQUIPMENTS_LIST,
    equipmentKeys.equipments(),
    DomainParams.Equipments,
    onSuccess,
    false,
  );
  return { equipmentsList, isLoading, refetch, isFetching, error };
};

export default useFilterAndSearchEquipments;