/* eslint-disable react/require-default-props */
import React from 'react';
import {
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonText,
} from '@ionic/react';
import './SelectDateItem.css';

export interface Props {
  id: string;
  presentation?: 'date' | 'time' | 'date-time' | 'time-date';
  label: string;
  value?: string;
  name?: string;
  error?: string;
  disabled?: boolean;
  min?: string | number | undefined;
  max?: string | number | undefined;
  onChange?: (event: any) => any;
  onIonChange?: (event: any) => any;
  onBlur?: (event: any) => any;
}

const SelectDateItem = React.forwardRef<HTMLIonDatetimeElement, Props>(({ id, name, label, value, error, presentation, disabled, min, max, onChange, onIonChange, onBlur }, ref) => (
  <IonItem disabled={disabled} lines="none" className={`fos-select-date-item ion-margin-bottom ${error ? 'ion-invalid' : ''}`.trim()}>
    <div className="fos-select-date-border" />
    <IonLabel className="ion-text-wrap">
      <IonText className='fos-select-date-label'>{label}</IonText>
    </IonLabel>
    <IonDatetimeButton datetime={id} className={value ? '' : 'fos-datetime-button-no-value'} />
    <IonModal keepContentsMounted>
      <IonDatetime
        showDefaultButtons
        ref={ref}
        id={id}
        presentation={presentation}
        name={name}
        value={value}
        onIonChange={onIonChange}
        onChange={onChange}
        onBlur={onBlur}
        max={max?.toString()}
        min={min?.toString()}
      />
    </IonModal>
    {error && <IonNote slot="error">{error}</IonNote>}
  </IonItem>
));

export default SelectDateItem;
