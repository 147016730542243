import { UserState } from './authUtils';

import { DomainParams } from './graphQLClientUtil';
import useBaseQuery from './baseQuery';
import GET_EXPENSE_MILEAGE_TOTAL from '../graphql/GetCalculateExpenseMileageTotal';
import { GUID, REGION_ID } from '../graphql/GetUserProfileInfo';

const useCalculateExpenseMileageTotal = (userState: UserState, mileage: number, mileageTypeId: number, ytdMileage: number, referenceDate: Date) => {
  const regionId = userState.userAuthPayload?.regionId! || 
  Number(window.localStorage.getItem(`${REGION_ID}_${window.localStorage.getItem(GUID) || ''}`));
  const enabled: boolean = !!mileage && !!mileageTypeId;

  const { data, isLoading } = useBaseQuery(
    regionId,
    { mileage, mileageTypeId, ytdMileage, referenceDate },
    userState,
    enabled,
    GET_EXPENSE_MILEAGE_TOTAL,
    ['calculateExpenseMileageTotal'],
    DomainParams.Payroll,
  );

  return { data, isLoading };
};

export default useCalculateExpenseMileageTotal;
