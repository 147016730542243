import { useQuery } from '@tanstack/react-query';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import GET_TAX_TYPES from '../graphql/ExpGetTaxTypes';

export interface TaxType {
  id: number
  includeInNet: boolean
  name: string
  parentTaxId: number
  province: string
  rate: number
}

const useExpenseTaxTypes = () => {
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState, DomainParams.Payroll);
  const { isLoading, data } = useQuery({
    queryKey: ['expTaxTypes'],
    queryFn: () =>
      graphQLClient.request(GET_TAX_TYPES),
    refetchOnWindowFocus: false,
  });
  return { isLoading, data: (data?.taxTypes || []) as TaxType[] };
};

export default useExpenseTaxTypes;