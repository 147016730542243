import { gql } from '@apollo/client';

export interface XactModel {
  address: string;
  lossAddressCity: string;
  lossAddressCountry: string;
  lossAddressPostal: string;
  lossAddressState: string;
  lossAddressStreet: string;
  claimIndx: number;
  claimNumber: string;
  company: string;
  contactName: string;
  id: number;
  isEmergency: boolean;
  lossDate: string;
  phaseIndx: number;
  policyNumber: string;
  projectNumber: string;
  receiveDate: string;
  recipientXactAddress: string;
  senderXactAddress: string;
  typeOfLoss: string;
  xactTransactionId: string;
}

const GET_JOBS_LIST = gql`
query GetJobsList($regionId: Int!, $page: Int!, $itemsPerPage: Int, $term: String, $show: String) {
  jobs(regionId: $regionId, page: $page, itemsPerPage: $itemsPerPage, term: $term, show: $show)
  {
    items {
      address
      lossAddressCity
      lossAddressCountry
      lossAddressPostal
      lossAddressState
      lossAddressStreet
      claimIndx
      claimNumber
      company
      contactName
      id
      isEmergency
      lossDate
      phaseIndx
      policyNumber
      projectNumber
      receiveDate
      recipientXactAddress
      senderXactAddress
      typeOfLoss
      xactTransactionId
    }
  }
}`;

export default GET_JOBS_LIST;