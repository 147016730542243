/* eslint-disable no-console */
import React, { useEffect } from 'react';
import {
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonText,
  IonToast,
} from '@ionic/react';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import { closeOutline } from 'ionicons/icons';
import 'react-quill/dist/quill.snow.css';

import moment from 'moment';
import FormItemCheckbox from '../atom/FormItemCheckbox';
import StackedLargeInput from '../atom/StackedLargeInput';
import './SiteInspectionInfo.css';
import FosFormatTextArea from '../atom/FosFormatTextArea';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
// eslint-disable-next-line import/no-named-as-default
import useSiteInspectionInfoViewModel, { Props } from './SiteInspectionInfoViewModel';
import FosCurrencyInput from '../components/FosCurrencyInput';
import StackedInput from '../atom/StackedInput';

const SiteInspectionInfo: React.FC<Props> = ({ claimIndx, mandatoryFieldsInvalid, isMandatoryFieldsInvalid, setIsLossTypeValid, hasChangedMessageControl  }) => {
  const {
    DETAILED_LOSS_TYPE,
    onSubmit,
    control,
    lossTypeOptions,
    selectedLossType,
    classTypeOptions,
    errors,
    lossCategoryOptions,
    sourceCategoryOptions,
    handleAddClick,
    templateOptions,
    isSaving,
    isLoadingData,
    saveError,
    setSaveError,
    mobilizationEta,
    setMobilizationEta,
    getMobilizationEtaString,
    insurer,
    hasChanged,
    testMandatoryField,
    isLossCategoryValid,
    isClassValid,
    isCauseOfLossValid,
    isSourceCategoryValid,
    isDryingStrategyValid,
    MandatoryFields,
    handleUserChange,
  } = useSiteInspectionInfoViewModel({ claimIndx, mandatoryFieldsInvalid, isMandatoryFieldsInvalid, setIsLossTypeValid, hasChangedMessageControl });

  useEffect(() => {
    if (hasChanged) {
      hasChangedMessageControl(true);
      if (!isCauseOfLossValid) {
        mandatoryFieldsInvalid(true);
      }
      if (DETAILED_LOSS_TYPE.includes(selectedLossType) && (!isClassValid || !isLossCategoryValid || !isSourceCategoryValid)) {
        mandatoryFieldsInvalid(true);
      }
      if (insurer === 'Aviva Canada' && DETAILED_LOSS_TYPE.includes(selectedLossType) && !isDryingStrategyValid) {
        mandatoryFieldsInvalid(true);
      }
    } else {
      mandatoryFieldsInvalid(false);
      hasChangedMessageControl(false);
    }
  }, [DETAILED_LOSS_TYPE, hasChanged, hasChangedMessageControl, insurer, isCauseOfLossValid, isClassValid, isDryingStrategyValid, isLossCategoryValid, isSourceCategoryValid, mandatoryFieldsInvalid, selectedLossType]);

  return (
    <IonContent className="ion-padding site-inspection-info-body" fullscreen>
      <form id="addSiteInspectionForm" onSubmit={onSubmit} onChange={() => handleUserChange()}>
        <IonList>
          <Controller
            control={control}
            name="causeOfLoss"
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <FosSelectBigListItem
                id="addClaimLossType"
                onChange={(value) => {
                  onChange(value);
                  testMandatoryField(MandatoryFields.CauseOfLoss);
                  handleUserChange();
                }}
                value={value}
                name={name}
                label={t('lossType')}
                options={lossTypeOptions}
                error={error?.message}
              />
            )}
          />
          {DETAILED_LOSS_TYPE.includes(selectedLossType) && (
            <>
              <Controller
                control={control}
                name="class"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <FosSelectBigListItem
                    id="class"
                    onChange={(value) => {
                      onChange(value);
                      testMandatoryField(MandatoryFields.Class);
                      handleUserChange();
                    }}
                    value={value}
                    name={name}
                    label={t('class')}
                    options={classTypeOptions}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="categoryOfLoss"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <FosSelectBigListItem
                    id="categoryOfLoss"
                    onChange={(value) => {
                      onChange(value);
                      testMandatoryField(MandatoryFields.LossCategory);
                      handleUserChange();
                    }}
                    value={value}
                    name={name}
                    label={t('lossCategory')}
                    options={lossCategoryOptions}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="categoryOfSource"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <FosSelectBigListItem
                    id="categoryOfSource"
                    onChange={(value) => {
                      onChange(value);
                      testMandatoryField(MandatoryFields.SourceCategory);
                      handleUserChange();
                    }}
                    value={value}
                    name={name}
                    label={t('sourceCategory')}
                    options={sourceCategoryOptions}
                    error={error?.message}
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name="hvacaffected"
            defaultValue={false}
            render={({ field: { value, onChange, ref } }) => (
              <FormItemCheckbox label={t('hvacAffected')} value={value} ref={ref} onChange={(e) => { onChange(e); handleUserChange(); }} />
            )}
          />
          <Controller
            control={control}
            name="suddenLoss"
            defaultValue={false}
            render={({ field: { value, onChange, ref } }) => (
              <FormItemCheckbox label={t('suddenOrAccidental')} value={value} ref={ref} onChange={(e) => { onChange(e); handleUserChange(); }} />
            )}
          />
          <IonText>{t('reserve').toString()}</IonText>
          <IonGrid className="ion-no-padding ion-no-margin">
            <IonRow className="ion-no-padding ion-no-margin">
              <IonCol className="ion-margin-end">
                <Controller
                  control={control}
                  name="emReserve"
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <FosCurrencyInput
                      value={value?.toString()}
                      label={t('emergency')}
                      onChange={(value) => { onChange(value); handleUserChange(); }}
                      error={error?.message}
                    />
                  )}
                />
              </IonCol>
              <IonCol className="ion-margin-end">
                <Controller
                  control={control}
                  name="reReserve"
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <FosCurrencyInput
                      value={value?.toString()}
                      label={t('repair')}
                      onChange={(value) => { onChange(value); handleUserChange(); }}
                      error={error?.message}
                    />
                  )}
                />
              </IonCol>
              <IonCol>
                <Controller
                  control={control}
                  name="ctReserve"
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <FosCurrencyInput
                      value={value?.toString()}
                      label={t('contents')}
                      onChange={(value) => { onChange(value); handleUserChange(); }}
                      error={error?.message}
                    />
                  )}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonItem id="date-time-trigger" lines="none" className="fos-select-date-item ion-margin-bottom">
            <div className="fos-select-date-border" />
            <IonLabel id="open-date-input" className="ion-text-wrap">
              <IonText className="fos-select-date-label">{t('mobilizationETA').toString()}</IonText>
            </IonLabel>
            <IonText className="fos-select-date-label">
              {moment(mobilizationEta || null).isValid()
                ? moment(getMobilizationEtaString(mobilizationEta)).format('MMMM Do YYYY, h:mm a')
                : ''}
            </IonText>
            <IonModal trigger="date-time-trigger" className="center fixed-300-width">
              <IonDatetime
                showDefaultButtons
                presentation="date-time"
                id="idWhenDate"
                name="idWhenDate"
                size="cover"
                value={mobilizationEta || null}
                onIonChange={setMobilizationEta}
              />
            </IonModal>
          </IonItem>
          <Controller
            control={control}
            name="nonRestorableContent"
            defaultValue={false}
            render={({ field: { value, onChange, ref } }) => (
              <FormItemCheckbox label={t('nonRestorableContent')} value={value} ref={ref} onChange={(e) => { onChange(e); handleUserChange(); }} />
            )}
          />
          <Controller
            control={control}
            name="emAfterHours"
            defaultValue={false}
            render={({ field: { value, onChange, ref } }) => (
              <FormItemCheckbox label={t('emCompletedAfterHours')} value={value} ref={ref} onChange={(e) => { onChange(e); handleUserChange(); }} />
            )}
          />
          <Controller
            control={control}
            name="emEstimateNeeded"
            defaultValue={false}
            render={({ field: { value, onChange, ref } }) => (
              <FormItemCheckbox label={t('emEstimateNeeded')} value={value} ref={ref} onChange={(e) => { onChange(e); handleUserChange(); }} />
            )}
          />
          <Controller
            control={control}
            name="repairEstimateNeeded"
            defaultValue={false}
            render={({ field: { value, onChange, ref } }) => (
              <FormItemCheckbox label={t('repairEstimateNeeded')} value={value} ref={ref} onChange={(e) => { onChange(e); handleUserChange(); }} />
            )}
          />
          <Controller
            control={control}
            name="tearOutNeeded"
            defaultValue={false}
            render={({ field: { value, onChange, ref } }) => (
              <FormItemCheckbox label={t('addlTearOutNeeded')} value={value} ref={ref} onChange={(e) => { onChange(e); handleUserChange(); }} />
            )}
          />
          <Controller
            control={control}
            name="lossOfIncome"
            defaultValue={false}
            render={({ field: { value, onChange, ref } }) => (
              <FormItemCheckbox label={t('lossOfIncome')} value={value} ref={ref} onChange={(e) => { onChange(e); handleUserChange(); }} />
            )}
          />
          <Controller
            control={control}
            name="docusketchLink"
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <StackedInput
                onChange={(value) => { onChange(value); handleUserChange(); }}
                onIonChange={(value) => { onChange(value); }}
                label={t('360link')}
                error={error?.message}
                value={value}
                name={name}
              />
            )}
          />
          {insurer === 'Aviva Canada' && (
            <>
              {insurer === 'Aviva Canada' && DETAILED_LOSS_TYPE.includes(selectedLossType) && (
                <>
                  <IonRow className="isr-label-padding">
                    <IonLabel>{t('dryingStrategy').toString()}</IonLabel>
                  </IonRow>
                  <IonRow>
                    <FosFormatTextArea control={control} name="dryingStrategy" onInputChange={()=>{handleUserChange(); testMandatoryField(MandatoryFields.DryingStrategy);}}/>
                    {errors.dryingStrategy && <IonText className="invalid-entry-message">{errors.dryingStrategy.message}</IonText>}
                  </IonRow>
                </>
              )}
              <Controller
                control={control}
                name="emEstimatedDuration"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <StackedInput
                    onChange={(value) => { onChange(value); handleUserChange(); }}
                    onIonChange={(value) => { onChange(value); }}
                    label={t('emEstimatedDuration')}
                    error={error?.message}
                    value={value}
                    name={name}
                  />
                )}
              />
              <Controller
                control={control}
                name="reEstimatedDuration"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <StackedInput
                    onChange={(value) => { onChange(value); handleUserChange(); }}
                    onIonChange={(value) => { onChange(value);}}
                    label={t('reEstimatedDuration')}
                    error={error?.message}
                    value={value}
                    name={name}
                  />
                )}
              />
              {/* <Controller
                control={control}
                name="pmEmail"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <StackedInput
                    onChange={onChange}
                    onIonChange={onChange}
                    label={t('pmEmail')}
                    error={error?.message}
                    value={value}
                    name={name}
                  />
                )}
              />
              <Controller
                control={control}
                name="pmPhone"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <StackedInput
                    onChange={onChange}
                    onIonChange={onChange}
                    label={t('pmPhone')}
                    error={error?.message}
                    value={value}
                    name={name}
                  />
                )}
              /> */}
              <Controller
                control={control}
                name="contentsHandling"
                render={({ field: { onChange, value } }) => (
                  <div className='grey-test-isr'>
                  <IonLabel>{t('contentsHandling').toString()}</IonLabel>
                    <IonRadioGroup
                      value={value || 'notRequired'}
                      onIonChange={(e) => {
                        onChange(e.detail.value);
                      }}
                    >
                      <IonRow className="ion-align-items-center">
                        <IonCol>
                          <IonItem lines="none">
                            <IonLabel>{t('notRequired').toString()}</IonLabel>
                            <IonRadio value='notRequired' class="isr-radio"/>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem lines="none">
                            <IonLabel>{t('onsiteOnly').toString()}</IonLabel>
                            <IonRadio value='onsiteOnly' class="isr-radio"/>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem lines="none">
                            <IonLabel>{t('specialHandling').toString()}</IonLabel>
                            <IonRadio value='specialHandling' class="isr-radio"/>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonRadioGroup>
                  </div>
                )}
              />

              <div className="grey-test-isr">
                <IonLabel>{t('otherInfo').toString()}</IonLabel>
                <div className="ion-padding-bottom">
                  <Controller
                    control={control}
                    name="isAsbestos"
                    render={({ field: { onChange, value, name } }) => (
                      <IonItem lines="none">
                        <IonLabel>{t('isAsbestos').toString()}</IonLabel>
                        <IonCheckbox
                          onIonChange={(e) => onChange(e.detail.checked)}
                          checked={value}
                          name={name}
                          color="primary"
                          className="form-item-checkbox"
                        />
                      </IonItem>
                    )}
                  />
                  <Controller
                    control={control}
                    name="isMold"
                    render={({ field: { onChange, value, name } }) => (
                      <IonItem lines="none">
                        <IonLabel>{t('isMold').toString()}</IonLabel>
                        <IonCheckbox
                          onIonChange={(e) => onChange(e.detail.checked)}
                          checked={value}
                          name={name}
                          color="primary"
                          className="form-item-checkbox"
                        />
                      </IonItem>
                    )}
                  />
                  <Controller
                    control={control}
                    name="preexistingDamage"
                    render={({ field: { onChange, value, name } }) => (
                      <IonItem lines="none">
                        <IonLabel>{t('preexistingDamage').toString()}</IonLabel>
                        <IonCheckbox
                          onIonChange={(e) => onChange(e.detail.checked)}
                          checked={value}
                          name={name}
                          color="primary"
                          className="form-item-checkbox"
                        />
                      </IonItem>
                    )}
                  />
                </div>
              </div>
              <IonRow>
                <FosFormatTextArea control={control} name="otherInfoBox" />
              </IonRow>
            </>
          )}
          {selectedLossType && (
            <>
              <IonRow>
                <IonLabel>{t('generalCauseDetails').toString()}</IonLabel>
                <StackedLargeInput
                  onAddClick={(selectedOption: string) => handleAddClick(selectedOption)}
                  label={t('useTemplate')}
                  options={templateOptions}
                  control={control}
                  name="generalCauseDetails"
                />
              </IonRow>
              <IonRow>
                <FosFormatTextArea
                  label={t('preExistingFactors').toString()}
                  control={control}
                  name="preExistingFactors"
                />
              </IonRow>
              <IonRow>
                <FosFormatTextArea
                  label={t('internalFieldCrewNotes').toString()}
                  control={control}
                  name="fieldCrewNotes"
                />
              </IonRow>
              <IonRow>
                <FosFormatTextArea label={t('areaDamages').toString()} control={control} name="areaDamages" />
              </IonRow>
              <IonRow>
                <FosFormatTextArea label={t('contents').toString()} control={control} name="contents" />
              </IonRow>
            </>
          )}
        </IonList>
      </form>
      <IonLoading isOpen={isSaving} message={t('savingSiteInfo')} duration={1000} />
      <IonLoading isOpen={isLoadingData} message={t('loadingSiteInfo')} duration={1000} />
      <IonToast
        isOpen={!!saveError}
        message={saveError}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => {
          setSaveError('');
        }}
      />
    </IonContent>
  );
};

export default SiteInspectionInfo;
