import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';
import DELETE_LEVEL from '../graphql/DeleteLevel';

const useDeleteLevel = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (id: number) => graphQLClient.request(DELETE_LEVEL, { id }),
  });

  return { isLoading, mutateAsync };
};
  
export default useDeleteLevel;
