import { gql } from '@apollo/client';

const GET_LOSS_TYPES_LIST = gql`
query lossTypes($regionId: Int!) {
  lossTypes(regionId: $regionId)
  { 
    active
    lossCategory
  } 
}`;

export default GET_LOSS_TYPES_LIST;