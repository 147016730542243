import { Storage } from '@ionic/storage';
import { UserState } from '../../../../hooks/authUtils';
import GET_EQUIPMENT_MODELS_LIST, { EquipmentModelType } from '../graphql/GetEquipmentModels';
import useBaseCacheAndList from '../../../../hooks/baseCacheAndList';
import equipmentKeys from './equipmentKeys';
import { DomainParams } from '../../../../hooks/graphQLClientUtil';


const useEquipmentModels = (region: number, userState: UserState, storage: Storage) => {
  const equipmentModels: EquipmentModelType[] = useBaseCacheAndList(
    region,
    userState,
    storage,
    GET_EQUIPMENT_MODELS_LIST,
    equipmentKeys.equipmentModels(),
    'equipmentModels',
    DomainParams.Equipments);
  return equipmentModels;
};

export default useEquipmentModels;