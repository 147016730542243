import { gql } from '@apollo/client';

const DELETE_SKETCH = gql`
mutation DeleteSketch($id: Int!) {
  deleteSketch(id: $id)
  {
    status
    itemId
  }
}`;

export default DELETE_SKETCH;
