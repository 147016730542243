import React from 'react';
import { IonFooter, IonButton } from '@ionic/react';

import './FosFooterButton.css';

export interface Props {
  label: string;
  onClick: (event: any) => any;
}

const FosFooterButton: React.FC<Props> = ({ label, onClick }) => (
  <IonFooter className="fos-footer-button">
    <IonButton
      fill="outline"
      expand="block"
      className="ion-padding-start ion-padding-end ion-margin-bottom"
      onClick={onClick}
    >
      {label}
    </IonButton>
  </IonFooter>
);

export default FosFooterButton;