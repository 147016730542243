import { Storage } from '@ionic/storage';
import { UserState } from '../../../../hooks/authUtils';
import { EquipmentGenericItemType } from '../../../../graphql/EquipmentGenericItemType';
import useBaseCacheAndList from '../../../../hooks/baseCacheAndList';
import GET_EQUIPMENT_CONDITIONS_LIST from '../graphql/GetEquipmentConditions';
import equipmentKeys from './equipmentKeys';
import { DomainParams } from '../../../../hooks/graphQLClientUtil';


const useEquipmentConditions = (region: number, userState: UserState, storage: Storage) => {
  const equipmentConditions: EquipmentGenericItemType[] = useBaseCacheAndList(
    region,
    userState,
    storage,
    GET_EQUIPMENT_CONDITIONS_LIST,
    equipmentKeys.equipmentConditions(),
    'conditions',
    DomainParams.Equipments);
  return equipmentConditions;
};

export default useEquipmentConditions;