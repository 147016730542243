/* eslint-disable react/require-default-props */
import React from 'react';
import { IonCheckbox, IonItem, IonLabel, IonSelectOption } from '@ionic/react';
import './FormItemCheckbox.css';

export type SelectOptionProps = React.ComponentProps<typeof IonSelectOption> & { label: string };

export interface Props {
  label: string;
  labelPosition?: 'stacked';
  value?: boolean;
  onChange: (event: any) => any;
}

const FormItemCheckbox = React.forwardRef<HTMLIonCheckboxElement, Props>(({
  label,
  labelPosition,
  value,
  onChange,
}, ref) => {

  const classNames = [
    'fos-select-item',
    'ion-margin-bottom',
    labelPosition === 'stacked' ? 'fos-select-item-label-stacked' : 'fos-select-item-label-default',
  ]
    .filter(cls => !!cls)
    .join(' ');

  return (
    <IonItem lines="none" className={classNames}>
      <div className="form-item-checkbox-border" />
      {label && <IonLabel className="fos-select-label ion-text-wrap ion-padding-top ion-padding-bottom" position={labelPosition}>{label}</IonLabel>}
      <IonCheckbox
        ref={ref}
        onIonChange={(e) => onChange(e.detail.checked)}
        checked={value}
        className="form-item-checkbox"
        slot="end"
        color="primary" />
    </IonItem>
  );
});

export default FormItemCheckbox;
