import { useMutation } from '@tanstack/react-query';
import { LevelModelType } from '../graphql/GetLevelsList';
import SAVE_CLAIM_LEVELS from '../graphql/SaveClaimLevels';
import getGraphQLClient from './graphQLClientUtil';
import { useAuth } from './authContext';

/*
* GraphQL call: save claim levels
* */
const useSaveClaimLevels = () => {
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({ region, jobIdx, levels } : { region: number, jobIdx: number, levels: LevelModelType[] }) => 
      graphQLClient.request(SAVE_CLAIM_LEVELS, { claimIndx: jobIdx, regionId: region, levels }),
  });
  return { isLoading, mutateAsync };
};

export default useSaveClaimLevels;