import React, { useEffect } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  useIonActionSheet,
  useIonRouter,
  useIonViewWillEnter,
} from '@ionic/react';
import { t } from 'i18next';
import { Toaster, toast } from 'react-hot-toast';
import { useHistory } from 'react-router';
import useStorage from '../../../../hooks/storage';
import { useAuth } from '../../../../hooks/authContext';
import FosFormHeader from '../../../../atom/FosFormHeader';
import FosLabelValue from '../../../../atom/FosLabelValue';
import FosFooterButton from '../../../../atom/FosFooterButton';
import useEquipmentDetailsViewModel from './EquipmentDetailsViewModel';
import useEquipmentByClassesViewModel from '../list/equipmentsclass/EquipmentsByClassesViewModel';
import useEquipmentBranches from '../../data/hooks/useEquipmentBranches';

const EquipmentDetails: React.FC = () => {
  const navigation = useIonRouter();
  const [present] = useIonActionSheet();
  const POP = 'POP';
  const history = useHistory();
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;

  const equipmentBranches = useEquipmentBranches(regionId, userState, useStorage());

  const {
    isFetching,
    isSaving,
    showErrorToast,
    showSuccessToast,
    equipmentActionSheetButtons,
    equipment,
    handleOnDidDismiss,
    setShowErrorToast,
    setShowSuccessToast,
    refetch,
  } = useEquipmentDetailsViewModel();

  const { onEditClick } = useEquipmentByClassesViewModel();

  const onManageCLick = () => {
    present({
      header: t('chooseStatus'),
      cssClass: 'custom-action-sheet',
      buttons: equipmentActionSheetButtons,
      onDidDismiss: ({ detail }) => handleOnDidDismiss(detail.data?.action),
    });
  };

  /*
  * Observing variables to show toasts.
  */
  useEffect(() => {
    if (showErrorToast) {
      toast.error(t('optionNotAvailable'), { duration: 2000 });
      setShowErrorToast(false);
    } else if (showSuccessToast) {
      toast.success(t('equipmentStatusUpdated'), { duration: 2000 });
      setShowSuccessToast(false);
    }
  }, [setShowErrorToast, setShowSuccessToast, showErrorToast, showSuccessToast]);

  /* 
  * Refetch equipment info when the user comes back from equipment assignment page.
  */
  useIonViewWillEnter(() => {
    if (history.action === POP) {
      refetch();
    }
  });

  return (
    <IonPage>
      <FosFormHeader
        title={t('editItem').toString()}
        rightButtonText={equipmentBranches.some(branch => branch.id === equipment?.branch?.id) ? t('manage') : ''}
        onSaveClick={onManageCLick}
        onCancelClick={() => navigation.goBack()} />
      <IonContent className='ion-padding'>
        <IonList lines='none'>
          <FosLabelValue label={t('status')} value={equipment?.status?.value} />
          <FosLabelValue label={t('class')} value={equipment?.itemClass?.value} />
          <FosLabelValue label={t('itemModel')} value={equipment?.itemModel?.value} />
          <IonGrid className="ion-no-padding ion-no-margin">
            <IonRow className="ion-no-padding ion-no-margin">
              <IonCol size='6' className="ion-padding-end">
                <FosLabelValue label={t('assetTag')} value={equipment?.assetTag} />
              </IonCol>
              <IonCol size='6'>
                <FosLabelValue label={t('itemNumber')} value={equipment?.itemNumber} />
              </IonCol>
            </IonRow>
          </IonGrid>
          <FosLabelValue label={t('branch')} value={equipment?.branch?.value} />
        </IonList>
      </IonContent>
      { equipment?.region?.id === regionId?.toString() && <FosFooterButton label={t('edit').toString()} onClick={ () => onEditClick(Number(equipment?.id))} />}
      <Toaster containerStyle={{ top: 50, left: 20, bottom: 20, right: 20 }} />
      <IonLoading isOpen={isSaving} duration={2000} message={t('saving')} />
      <IonLoading isOpen={isFetching} duration={2000} message={t('loading')} />
    </IonPage>
  );
};

export default EquipmentDetails;