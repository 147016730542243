import { InputChangeEventDetail, IonItem, IonList, IonText, IonCheckbox } from '@ionic/react';
import React from 'react';
import './FosLabelCheckbox.css';

export interface Props {
  label?: string;
  value?: any | null;
  checked?: boolean;
  onValueChange?: (newValue: any) => void;
}

const FosLabelCheckbox: React.FC<Props> = ({ label, value, onValueChange, checked }) => {
  const handleValueChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const rawValue = event.detail.value;
    if (onValueChange) {
      onValueChange(rawValue);
    }
  };

  const clickHandler = () => {
    if (checked !== undefined) {
      checked = !checked;
    }
  };
  const textClickHandler = (event: React.MouseEvent<HTMLIonTextElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (checked !== undefined) {
      checked = !checked;
    }
  };


  return (
    <IonList className="ion-margin-bottom fos-dateinput-label-list">
      <IonItem lines="none" className="fos-label-value-item-style form-border-color" onClick={clickHandler}>
        <IonText className="fos-date-label-style" slot="start" onClick={textClickHandler}>
          {label}
        </IonText>
        <IonCheckbox
          className="fos-checkbox-style"
          slot="end"
          checked={checked}
          value={value}
          onIonChange={handleValueChange}
          color="primary"
        />
      </IonItem>
    </IonList>
  );
};

export default FosLabelCheckbox;
