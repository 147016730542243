import React, { useState } from 'react';
import { IonFab, IonFabButton, IonIcon, IonToolbar } from '@ionic/react';
import { t } from 'i18next';
import { add } from 'ionicons/icons';
import UserExpenses from './UserExpenses';
import FosToogleButtons from '../atom/FosToogleButtons';
import UserExpensesForApproval from './UserExpensesForApproval';
import FosAddExpenseActionSheet from '../atom/FosAddExpenseActionSheet';

const UserExpenseMenu = () => {
  const [selectedMenu, setSelectedMenu] = useState(1);
  const { handleClick } = FosAddExpenseActionSheet();
  const addExpense = () => handleClick();

  return (
    <>
      <IonToolbar className='user-expenses-custom-ion-toolbar-button'>
        <div>
          <FosToogleButtons 
            selectedOption={selectedMenu}
            leftButtonLabel={t('expenses').toString()}
            rightButtonLabel={t('awaitingApproval').toString()}
            onLeftClick={() => setSelectedMenu(1)}
            onRightClick={() => setSelectedMenu(2)}
          />
        </div>
      </IonToolbar>
      {selectedMenu === 1 ? <UserExpenses /> : <UserExpensesForApproval />}
      <IonFab slot="fixed" horizontal="end" vertical="bottom" className='add-expense-btn' onClick={addExpense}>
        <IonFabButton className="tools-action-button">
          <IonIcon className="tools-action-button-icon" icon={add} />
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default UserExpenseMenu;