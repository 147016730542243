import React from 'react';
import { IonGrid, IonRow, IonCol, IonText, IonLabel } from '@ionic/react';
import { PhotoLevels, PhotoRooms } from '../pages/helper/LevelRoomsPhotos';
import FosCachedImage from '../components/FosCachedImage';

type Props = {
  levelsList: PhotoLevels[];
  selectRoom: (room: PhotoRooms, level: PhotoLevels) => void;
};


const ClaimPhotosRoomGridImage: React.FC<Props> = ({ levelsList, selectRoom }) => 
<IonGrid>
  <IonRow className='photo-ion-row'>
    {levelsList.length > 0 && levelsList.map((level: PhotoLevels) => level.rooms.map((room: PhotoRooms) => (
      <IonCol className='photo-ion-col room-container' sizeMd='2' sizeLg='2' sizeXl='1' size="4" key={level.name + room.name}>
        <FosCachedImage 
          onClick={() => selectRoom(room, level)}
          className='claim-photo'
          src={room.images[0]} 
        />
        <IonText className='room-text'>
          <IonLabel className='level-label'>{level.name}</IonLabel>
          <IonLabel className='room-label'>{room.name}</IonLabel>
        </IonText>
      </IonCol>
    )))}
  </IonRow>
</IonGrid>;
export default ClaimPhotosRoomGridImage;