/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './SelectItem.css';

export type SelectOptionProps = React.ComponentProps<typeof IonSelectOption> & { label: string };

export interface Props {
  label: string;
  labelPosition?: 'stacked';
  options: SelectOptionProps[];
  placeholder?: string;
  okText?: string;
  cancelText?: string;
  name?: string;
  error?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  onBlur?: (event: any) => any;
  onChange?: (event: any) => any;
  onIonChange?: (event: any) => any;
  marginTop?: boolean;
  removeMarginBottom?: boolean;
  interfaceOptions?: object;
  asterisk?: boolean;
}

const FosSelectItem = React.forwardRef<HTMLIonSelectElement, Props>(({ 
  onChange, 
  onBlur, 
  name, 
  label,
  error,
  placeholder,
  okText,
  cancelText,
  options,
  labelPosition,
  disabled,
  required,
  marginTop,
  removeMarginBottom,
  interfaceOptions,
  asterisk,
}, ref) => {
  const { t } = useTranslation();
  const classNames = [
    'fos-select-item', 'label-choose-asterisk',
    removeMarginBottom ? '' : 'ion-margin-bottom',
    error ? 'ion-invalid' : '',
    marginTop ? 'ion-margin-top' : '',
    labelPosition === 'stacked' ? 'fos-select-item-label-stacked' : 'fos-select-item-label-default',
  ]
    .filter(cls => !!cls)
    .join(' ');

  return (
    <IonItem lines="none" className={classNames} disabled={disabled}>
      <div className="fos-select-border" />
      <div className="label-asterisk-container">
        {label && <IonLabel class="fos-select-label ion-text-wrap" position={labelPosition}>
          <IonText className='fos-select-label-text'>
            {label}{asterisk && '*'}
          </IonText>
        </IonLabel>}
        {required && <span className="asterisk-select"> *</span>}
      </div>
      <IonSelect className='fos-select-item-label'
        interfaceOptions={interfaceOptions || {}}
        slot="end"
        ref={ref}
        name={name}
        placeholder={placeholder || t('choose')}
        okText={okText || t('ok')}
        cancelText={cancelText || t('cancel')}
        onIonChange={onChange}
        onBlur={onBlur}
      >
        {options.map((optionProps, idx) => <IonSelectOption key={idx} {...optionProps}>{optionProps.label}</IonSelectOption>)}
      </IonSelect>
      {error && <IonNote slot="error">{error}</IonNote>}
    </IonItem>
  );
});

export default FosSelectItem;
