import { useContext, useEffect, useState } from 'react';
import { UserState } from './authUtils';
import GET_USER_PROFILE_INFO from '../graphql/GetUserProfileInfo';
import { DomainParams } from './graphQLClientUtil';
import NetworkContext from '../contexts/NetworkContext';
import { UserProfileInfoType } from '../pages/helper/UserProfileInfoType';
import { getProfileInfo, storeProfileInfo } from '../pages/helper/offlineHelper/ProfileInfoHelper';
import useBaseQuery from './baseQuery';

const useUserProfileInfo = (userState: UserState, region: number, userGuid: string) => {
  const network = useContext(NetworkContext);
  const [userInfo, setUserInfo] = useState<UserProfileInfoType>();

  const onSuccess = async (data: any) => {
    const info = data?.ctUser || null;
    setUserInfo(info);
    await storeProfileInfo(info);
  };

  const { isLoading } = useBaseQuery(
    region,
    { regionId: region, userGuid },
    userState,
    network.connected,
    GET_USER_PROFILE_INFO,
    ['profileInfo'],
    DomainParams.Common,
    onSuccess,
  );

  useEffect(() => {
    if (network.connected) return;
    const getAsync = async () => {
      const result = await getProfileInfo();
      if (result?.data) setUserInfo(result.data);
    };
    getAsync();
  }, [network.connected]);

  return { userInfo, isLoading };
};

export default useUserProfileInfo;
