import { useContext, useEffect } from 'react';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseQuery from './baseQuery';
import GET_LEVELS_LIST, { LevelModelType } from '../graphql/GetLevelsList';
import { DomainParams } from './graphQLClientUtil';
import { getClaimLevels, storeClaimLevels } from '../pages/helper/offlineHelper/ClaimLevelsHelper';
import { ClaimOnlineStatus } from '../pages/helper/Const';
import NetworkContext from '../contexts/NetworkContext';

const useClaimLevels = (
  region: number,
  claimIndx: number,
  userState: UserState,
  levelsList?: LevelModelType[],
  setLevelsList?: (_levelsList: LevelModelType[]) => void,
  onSuccess?: (_data: any) => void,
) => {
  const network = useContext(NetworkContext);
  const enabled = network.connected && !!claimIndx;

  const localSuccess = async (_data: any) => {
    if (_data?.listLevels?.length) {
      if (setLevelsList) {
        setLevelsList(_data?.listLevels);
      }
      await storeClaimLevels({ levels: _data?.listLevels, claimIndx: claimIndx!, status: ClaimOnlineStatus.AvailableOffline });
    }
    if (onSuccess) await onSuccess(_data);
  };

  useEffect(() => {
    if (!network.connected && levelsList?.length === 0 && claimIndx) {
      const getAsync = async () => {
        const { levels: localInspectionLevels } = await getClaimLevels(claimIndx!.toString());
        if (localInspectionLevels?.length && setLevelsList) {
          setLevelsList(localInspectionLevels);
        }
      };
      getAsync();
    }
  }, [claimIndx, levelsList?.length, network.connected, setLevelsList]);

  const { data, isLoading, isFetching, refetch } = useBaseQuery(
    region,
    { claimIndx },
    userState,
    enabled,
    GET_LEVELS_LIST,
    claimsKeys.claimLevels(),
    DomainParams.Common,
    localSuccess,
  );
  const claimLevelsList: LevelModelType[] =
    !isLoading && !isFetching ? data?.listLevels || [] : [];
  return { claimLevelsList, isLoading, isFetching, refetch };
};

export default useClaimLevels;