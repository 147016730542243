/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import {
  IonAccordion,
  IonButton,
  IonItem,
  IonLabel,
  useIonActionSheet,
  IonList,
} from '@ionic/react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import './RoomAccordion.css';
import { CameraSource } from '@capacitor/camera';
import RenameModal from './RenameModal';
import FosDoubleButton from './FosDoubleButton';
import { cameraTakePicture } from '../pages/helper/ClaimPhotosHelper';
import FosThumbItem from './FosThumbItem';
import { ClaimPhotosType } from '../graphql/GetClaimPhotosList';
import { ReactComponent as MoreIcon } from '../assets/icons/ic_more.svg';

export interface Props {
  roomName: string;
  roomId?: number;
  claimPhotos: ClaimPhotosType[];
  onChangeRoomName: (roomName: string) => any;
  onRoomDelete: () => any;
  setClaimPictures: (files: {
    blobArray: File[];
  }) => void;
  onPhotoDelete: (photoId: number) => void;
  setSelectedRoom: (roomId: number) => void;
  setIsAddPhotoToClaimFormOpen: (isOpen: boolean) => void;
}

enum RoomAction {
  RENAME = 'Rename Room',
  DUPLICATE = 'Duplicate Room',
  DELETE = 'Delete Room',
  CANCEL = 'Cancel',
}

const RoomAccordion = React.forwardRef<HTMLIonAccordionElement, Props>(({
  roomName,
  roomId,
  claimPhotos,
  onChangeRoomName,
  onRoomDelete,
  setClaimPictures,
  setSelectedRoom,
  onPhotoDelete,
  setIsAddPhotoToClaimFormOpen,
}, ref) => {

  const { t } = useTranslation();
  const roomAccordion = useRef(null);
  const [present] = useIonActionSheet();
  const [currentRoomName, setCurrentRoomName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDuplicateRoomEnabled = process.env.REACT_APP_ENV !== 'prod';  
  const isDeleteRoomEnabled = true;

  const cancel = () => {
    setIsModalOpen(false);
    setCurrentRoomName(roomName);
  };

  const changeRoomName = (newName: string) => {
    if (newName !== '') {
      onChangeRoomName(newName);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    setCurrentRoomName(roomName);

    const roomActionSheetButtons = [
      {
        text: t('renameRoom'),
        data: {
          action: RoomAction.RENAME,
        },
      },
      {
        text: t('cancel'),
        role: 'destructive',
        data: {
          action: RoomAction.CANCEL,
        },
      },
    ];
    
    if (isDuplicateRoomEnabled) {
      const duplicateRoomButton = {
        text: t('duplicateRoom'),
        data: {
          action: RoomAction.DUPLICATE,
        },
      };
      roomActionSheetButtons.splice(1, 0, duplicateRoomButton);
    }

    if (isDeleteRoomEnabled) {
      const deleteRoomButton = {
        text: t('deleteRoom'),
        role: 'destructive',
        data: {
          action: RoomAction.DELETE,
        },
      };
      roomActionSheetButtons.splice(2, 0, deleteRoomButton);
    }

    if (roomAccordion.current) {
      (roomAccordion.current as HTMLElement).onclick = (event) => {
        event.stopPropagation();
        present({
          header: roomName,
          cssClass: 'custom-action-sheet',
          buttons: roomActionSheetButtons,
          onDidDismiss: ({ detail }) => {
            switch (detail.data?.action) {
              case RoomAction.RENAME: {
                setIsModalOpen(true);
                break;
              }
              case RoomAction.DUPLICATE: {
                // todo: add functionality
                break;
              }
              case RoomAction.DELETE: {
                if (claimPhotos.length > 0) {
                  toast.error(t('cannotDeleteRoomWithPhotos'));
                } else {
                  onRoomDelete();
                }
                break;
              }
              case RoomAction.CANCEL:
              default: break;
            }
          },
        });
      };
    }
  }, [
    isDeleteRoomEnabled,
    isDuplicateRoomEnabled,
    roomName,
    present,
    t,
    claimPhotos,
    onRoomDelete,
  ]);

  const addNewToGalleryHandler = async (source: CameraSource) => {
    const files = await cameraTakePicture(source);

    setClaimPictures(files);
    setIsAddPhotoToClaimFormOpen(true);
    if (roomId) {
      setSelectedRoom(roomId);
    }
  };

  const handlePhotoDelete = (event:  any, claimPhotoId: number) => {
    event.stopPropagation();
    onPhotoDelete(claimPhotoId);
  };

  return (
    <>
      <IonAccordion ref={ref}>
        <IonItem lines='none' slot="header">
          <IonLabel className='media-inspection-label'>{roomName}</IonLabel>
          <IonButton className='room-accordion-more-button' fill='clear' ref={roomAccordion}>
            <MoreIcon fill="none" className="more-icon" />
          </IonButton>
        </IonItem>
        <div className="ion-padding-top ion-padding-bottom" slot="content">
          <IonList>
            {claimPhotos.map(claimPhoto => (
              <FosThumbItem
                key={claimPhoto.ctfileId}
                label={`${claimPhoto.fileName}.${claimPhoto.fileExtension}`}
                thumbSrc={claimPhoto.thumbnailFileName || claimPhoto.filePath}
                onClick={() => {}}
                onRemove={(e) => handlePhotoDelete(e, claimPhoto.ctfileId)}
              />
            ))}
          </IonList>
          <FosDoubleButton
            leftButtonLabel={t('upload').toString()}
            rightButtonLabel={t('takePhoto').toString()}
            onLeftClick={() => addNewToGalleryHandler(CameraSource.Photos)}
            onRightClick={() => addNewToGalleryHandler(CameraSource.Camera)} />
          </div>
        <div className="room-inspection-divider" />
      </IonAccordion>
      <div className="room-inspection-divider" />
      <RenameModal
        currentName={currentRoomName}
        modalTitle={t('renameRoom')}
        isModalOpen={isModalOpen}
        onCancel={cancel}
        onNameChanged={(newName) => changeRoomName(newName)} />
    </>
  );
});

export default RoomAccordion;
