import { gql } from '@apollo/client';

const GET_EQUIPMENT_STATUS_LIST = gql`
query statusList($regionId: Int!) {
  statusList(regionId: $regionId)
  { 
    id
    value
  } 
}`;

export default GET_EQUIPMENT_STATUS_LIST;