import { gql } from '@apollo/client';

/**
 * Single Scan
 */
const UPDATE_EQUIPMENT_STATUS = gql`
mutation updateEquipmentStatus($regionId: Int!, $itemId: Int!, $statusId: Int!) {
  updateEquipmentStatus(regionId: $regionId, itemId: $itemId, statusId: $statusId)
  {
    itemId
    status
    message
  }
}`;

export default UPDATE_EQUIPMENT_STATUS;
