import { gql } from '@apollo/client';

const DELETE_CT_FILES =  gql`mutation deleteCTFiles($ctFileIds: [Int!]) {
  deleteCTFiles(ctFileIds: $ctFileIds)
  {
    itemId
    status
    message
    errors {
      code
      value
    }
  }
}`;

export default DELETE_CT_FILES;