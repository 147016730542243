import React from 'react';
import { useTranslation } from 'react-i18next';
import { CameraSource } from '@capacitor/camera';
import { ReactComponent as CameraIcon } from '../assets/icons/ic_camera.svg';
import { ReactComponent as FolderPlus } from '../assets/icons/ic_folder_plus.svg';
import './NoPhotos.css';

type Props = {
  addNewToGallery: (source: CameraSource) => void;
};

const NoPhotos: React.FC<Props> = ({ addNewToGallery }) => {
  const { t } = useTranslation();

  return (
    <div slot="fixed" className="upload-wrapper no-photos">
      <div className="upload-heading">
        <div>{t('noPhotos').toString()}</div>
        <span>{t('addPhotos').toString()}</span>
      </div>
      <div className="upload-container">
        <div className="upload-section v-separator">
          <FolderPlus fill="none" className="upload-icon" onClick={() => addNewToGallery(CameraSource.Photos)} />
          <div>{t('importPhotos').toString()}</div>
        </div>
        <div className="upload-section">
          <CameraIcon fill="none" className="upload-icon" onClick={() => addNewToGallery(CameraSource.Camera)} />
          <div>{t('takePhoto').toString()}</div>
        </div>
      </div>
    </div>
  );
};

export default NoPhotos;