import { useQuery } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import GET_APP_VERSION from '../graphql/GetAppVersion';

const useGetAppVersion = (userState: UserState, isEnabled: boolean) => {
  const enabled = !!userState.userAuthPayload?.accessToken && isEnabled;
  const graphQLClient = getGraphQLClient(userState, DomainParams.Common);

  const { isLoading, data } = useQuery({
    queryKey: ['appVersion'],
    queryFn: () => 
      graphQLClient.request(GET_APP_VERSION),
    refetchOnWindowFocus: false,
    enabled,
  });
  return { data, isLoading };
};

export default useGetAppVersion;