import { gql } from '@apollo/client';

export type MileageTypesType = {
  text: string;
  value: string;
};

const GET_EXPENSE_MILEAGE_TYPES = gql`
query mileageTypes {
  mileageTypes {
    text
    value
  }
}`;

export default GET_EXPENSE_MILEAGE_TYPES;