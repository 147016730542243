import { gql } from '@apollo/client';

const GET_JOB_COST_CATEGORIES = gql`
query jobCostCategories($regionId: Int!, $departmentId: String, $jobCostTypeId: String) {
  jobCostCategories(regionId: $regionId, departmentId: $departmentId, jobCostTypeId: $jobCostTypeId)
  {
    id
    code
    parentId
    value
  }
}`;

export default GET_JOB_COST_CATEGORIES;