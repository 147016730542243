import { gql } from '@apollo/client';

const GET_EXPENSE_RECEIPT_FILES = gql`
query expenseReceiptFile ($expenseId: Int!) {
  expenseReceiptFile (expenseId: $expenseId)
    {
      receiptId
      fileName
      fileLink
    }
}`;

export default GET_EXPENSE_RECEIPT_FILES;