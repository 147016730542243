import { duplicateButtonImage, resizeButtonImage, rotateButtonImage } from './common';
import { ObjectConfig, ObjectExport, SketchObject, SketchObjectType, SystemOfMeasurement } from './sketch-object';

export interface RectConfig extends ObjectConfig {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  fillStyle?: string;
  strokeStyle?: string;
  lineWidth?: number;
  mode?: 'fill' | 'stroke';
  borderRadius?: number;
}

export interface RectArgs {
  x: number;
  y: number;
  width: number;
  height: number
}

export interface RectExport extends ObjectExport {
  type: SketchObjectType.Rect;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  fillStyle?: string;
  strokeStyle?: string;
  lineWidth?: number;
  mode?: 'fill' | 'stroke';
  borderRadius?: number;
}

export class Rect extends SketchObject {
  x: number;

  y: number;

  width: number;

  height: number;

  mode: 'fill' | 'stroke';

  fillStyle: string;

  strokeStyle: string;

  lineWidth: number;

  dragStart: HammerPoint | null = null;

  borderRadius?: number;

  private path?: Path2D;

  private currentTransform?: DOMMatrix;

  private resizeStart?: { width: number; height: number };

  private widthTextRect: RectArgs | null;

  private heightTextRect: RectArgs | null;

  constructor(config: RectConfig) {
    super(config);
    const {
      x = 0,
      y = 0,
      width = 0,
      height = 0,
      fillStyle = '',
      strokeStyle = '',
      lineWidth = 1,
      mode = 'stroke',
      borderRadius,
    } = config;

    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.fillStyle = fillStyle;
    this.strokeStyle = strokeStyle;
    this.lineWidth = lineWidth;
    this.mode = mode;
    this.borderRadius = borderRadius;
    this.widthTextRect = null;
    this.heightTextRect = null;
  }

  setX(x: number) {
    this.x = x;
    this.emit('update');
  }

  setY(y: number) {
    this.y = y;
    this.emit('update');
  }

  setWidth(width: number) {
    if (width < 0) return;
    this.width = width;
    this.emit('update');
  }

  setHeight(height: number) {
    if (height < 0) return;
    this.height = height;
    this.emit('update');
  }

  setDimensions(width: number, height: number) {
    if (width < 0 || height < 0) return;
    this.width = width;
    this.height = height;
    this.emit('update');
  }

  setFillStyle(fillStyle: string) {
    this.fillStyle = fillStyle;
    this.emit('update');
  }

  setStrokeStyle(strokeStyle: string) {
    this.strokeStyle = strokeStyle;
    this.emit('update');
  }

  setLineWidth(lineWidth: number) {
    this.lineWidth = lineWidth;
    this.emit('update');
  }

  setMode(mode: 'fill' | 'stroke') {
    this.mode = mode;
    this.emit('update');
  }

  setBorderRadius(borderRadius?: number) {
    this.borderRadius = borderRadius;
    this.emit('update');
  }

  export(): RectExport {
    return {
      ...super.export(),
      type: SketchObjectType.Rect,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      fillStyle: this.fillStyle,
      strokeStyle: this.strokeStyle,
      lineWidth: this.lineWidth,
      mode: this.mode,
    };
  }

  draw() {
    if (!this.visible || !this.canvas) return;
    if (this.mode === 'fill') {
      this.fill();
    } else {
      this.stroke();
    }

    if (this.showName) this.drawName();
    if (this.showSquareFootage) this.drawSquareFootage();
    if (this.showDimensions) this.drawDimensions();
    if (this.selected && !this.isPartOfGroup()) {
      this.drawSelection();
      this.drawRotateButton();
      this.drawResizeButton();
      this.drawDuplicateButton();
    }
  }

  drawName() {
    const ctx = this.canvas?.getContext('2d')!;
    const { width: nameWidth } = ctx.measureText(this.name);
    ctx.save();
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    ctx.fillText(this.name, this.x + this.width / 2 - nameWidth / 2, this.y - 12);
    ctx.restore();
  }

  drawSquareFootage() {
    const { systemOfMeasurement, feetToPixels, meterToPixels } = this;
    const ctx = this.canvas?.getContext('2d')!;
    ctx.save();
    const unit = SketchObject.getMeasureUnit(systemOfMeasurement);
    const scaleInPixels = systemOfMeasurement === SystemOfMeasurement.Imperial
      ? feetToPixels
      : meterToPixels;
    const squareFootage = (this.width / scaleInPixels) * (this.height / scaleInPixels);
    const squareFootageTxt = `${Math.abs(squareFootage).toFixed(2)}${unit}²`;
    const { width, actualBoundingBoxAscent } = ctx.measureText(squareFootageTxt);
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    ctx.fillText(squareFootageTxt, this.x + this.width / 2 - width / 2, this.y + this.height + 12 + actualBoundingBoxAscent);
    ctx.restore();
  }

  drawDimensions() {
    const ctx = this.canvas!.getContext('2d')!;
    const widthTxt = this.getAmountFormatted(this.width);
    const { width: widthTxtWidth, actualBoundingBoxAscent: widthTxtHeight } = ctx.measureText(widthTxt);
    const heightTxt = this.getAmountFormatted(this.height);
    const { width: heightTxtWidth, actualBoundingBoxAscent: heightTxtHeight } = ctx.measureText(heightTxt);
    if (widthTxtWidth > this.width || heightTxtHeight > this.height) {
      return;
    }

    ctx.save();
    if (this.mode === 'fill') {
      ctx.strokeStyle = '#fff';
      ctx.fillStyle = '#fff';
    }
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const path = new Path2D();
    const distFromBorder = 12;
    const gapBetweenLineAndTxt = 5;
    path.moveTo(this.x, this.y + distFromBorder);
    path.lineTo(this.x + this.width / 2 - widthTxtWidth / 2 - gapBetweenLineAndTxt, this.y + distFromBorder);
    ctx.fillText(widthTxt, this.x + this.width / 2 - widthTxtWidth / 2, this.y + distFromBorder + widthTxtHeight / 2);
    this.widthTextRect = { x: this.x + this.width / 2 - widthTxtWidth / 2, y: this.y + distFromBorder - widthTxtHeight / 2, width: widthTxtWidth, height: widthTxtHeight };
    path.moveTo(this.x + this.width / 2 - widthTxtWidth / 2 + widthTxtWidth + gapBetweenLineAndTxt, this.y + distFromBorder);
    path.lineTo(this.x + this.width, this.y + distFromBorder);
    ctx.save();
    const x = this.x + distFromBorder;
    const y = this.y + this.height / 2 + heightTxtHeight / 2;
    ctx.translate(x, y);
    ctx.rotate(-90 * Math.PI / 180);
    ctx.translate(-x, -y);
    ctx.fillText(heightTxt, x, y);
    this.heightTextRect = { x, y, width: heightTxtWidth, height: heightTxtHeight };
    ctx.restore();
    path.moveTo(x, this.y);
    path.lineTo(x, y - heightTxtWidth - gapBetweenLineAndTxt);
    path.moveTo(x, y + gapBetweenLineAndTxt);
    path.lineTo(x, this.y + this.height);
    ctx.stroke(path);
    ctx.restore();
  }

  drawSelection() {
    const ctx = this.canvas!.getContext('2d')!;
    const size = 8;
    ctx.save();
    ctx.fillStyle = 'red';
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const x1 = this.x - size / 2;
    const y1 = this.y - size / 2;
    ctx.fillRect(x1, y1, size, size);
    ctx.fillRect(x1 + this.width, y1, size, size);
    ctx.fillRect(x1, y1 + this.height, size, size);
    ctx.fillRect(x1 + this.width, y1 + this.height, size, size);
    ctx.restore();
  }

  isContainedByRect(outerRect: Rect) {
    return this.isObjectContainedByRect(this, outerRect);
  }

  isPointInsideObject(x: number, y: number) {
    if (!this.canvas || !this.path) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const v = ctx.isPointInPath(this.path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideRotationButton(x: number, y: number): boolean {
    if (!this.canvas || !this.path) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    path.rect(this.x + this.width / 2 - 24 / 2, this.y - 12 - 24, 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideResizeButton(x: number, y: number): boolean {
    if (!this.canvas || !this.path) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    path.rect(this.x + this.width + 24 / 2, this.y - 12 - 24, 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideDuplicateButton(x: number, y: number): boolean {
    if (!this.canvas || !this.path) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    path.rect(this.x + this.width + 12, this.y + this.height / 2 - 24 / 2, 24, 24);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideWidthText(x: number, y: number) {
    if (!this.canvas || !this.path || !this.widthTextRect) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const { x: rx, y: ry, width, height } = this.widthTextRect;
    path.rect(rx, ry, width, height);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  isPointInsideHeightText(x: number, y: number) {
    if (!this.canvas || !this.path || !this.heightTextRect) return false;
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.setTransform(this.currentTransform);
    const path = new Path2D();
    const { x: rx, y: ry, width, height } = this.heightTextRect;
    ctx.translate(rx, ry);
    ctx.rotate(-90 * Math.PI / 180);
    ctx.translate(-rx, -ry);
    path.rect(rx, ry - height, width, height);
    const v = ctx.isPointInPath(path, x, y);
    ctx.restore();
    return v;
  }

  handleDragStart(): void {
    this.dragStart = { x: this.x, y: this.y };
  }

  handleDrag({ deltaX, deltaY }: HammerInput): void {
    const sketch = this.getSketch();
    if (!this.dragStart || !sketch) return;
    const { x, y } = this.dragStart;
    this.x = x + deltaX / sketch.zoom;
    this.y = y + deltaY / sketch.zoom;
    this.emit('update');
  }

  handleDragEnd(): void {
    this.dragStart = null;
  }

  handleResizeStart() {
    this.resizeStart = { width: this.width, height: this.height };
  }

  handleResize(event: HammerInput) {
    this.setDimensions(this.resizeStart!.width + event.deltaX, this.resizeStart!.height + event.deltaY);
  }

  handleResizeEnd(): void {
    delete this.resizeStart;
    this.emit('update');
  }

  drawRotateButton() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const btnWidth = 24;
    const x = this.x + this.width / 2 - btnWidth / 2;
    const y = this.y - 12 - btnWidth;
    ctx.drawImage(rotateButtonImage, x, y);
    ctx.restore();
  }

  drawResizeButton() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const btnWidth = 24;
    const x = this.x + this.width + btnWidth / 2;
    const y = this.y - 12 - btnWidth;
    ctx.drawImage(resizeButtonImage, x, y);
    ctx.restore();
  }

  drawDuplicateButton() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    const btnWidth = 24;
    const x = this.x + this.width + 12;
    const y = this.y + this.height / 2 - btnWidth / 2;
    ctx.drawImage(duplicateButtonImage, x, y);
    ctx.restore();
  }

  private fill() {
    const canvas = this.canvas!;
    const ctx = canvas.getContext('2d')!;
    ctx.save();
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    ctx.fillStyle = this.fillStyle;
    ctx.lineWidth = this.lineWidth;
    this.path = new Path2D();
    if (typeof this.borderRadius === 'number') {
      this.path.roundRect(this.x, this.y, this.width, this.height, this.borderRadius);
    } else {
      this.path.rect(this.x, this.y, this.width, this.height);
    }
    ctx.fill(this.path);
    this.currentTransform = ctx.getTransform();
    ctx.restore();
  }

  private stroke() {
    const ctx = this.canvas!.getContext('2d')!;
    ctx.save();
    ctx.strokeStyle = this.strokeStyle;
    ctx.lineWidth = this.lineWidth;
    ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
    ctx.rotate(this.rotation * Math.PI / 180);
    ctx.translate(-(this.x + this.width / 2), -(this.y + this.height / 2));
    this.path = new Path2D();
    if (typeof this.borderRadius === 'number') {
      this.path.roundRect(this.x, this.y, this.width, this.height, this.borderRadius);
    } else {
      this.path.rect(this.x, this.y, this.width, this.height);
    }
    ctx.stroke(this.path);
    this.currentTransform = ctx.getTransform();
    ctx.restore();
  }
}
