import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { Variables } from 'graphql-request';
import { Storage } from '@ionic/storage';
import { useEffect, useState } from 'react';
import { DocumentNode } from 'graphql';
import { UserState } from './authUtils';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';

const useBaseCacheAndList = (
  region: number,
  userState: UserState,
  storage: Storage,
  baseGraphqlQuery: DocumentNode,
  baseKey: string[],
  baseResult: string,
  domainParam?: DomainParams) => {
  const { checkTokenExpiration } = useAuth()!;
  const [baseCached, setBaseCached] = useState<any>(null);
  const baseKeyString = `${baseKey?.[0]}-${region?.toString()}`;
  useEffect(() => {
    const loadStoredBase = async () => {
      const stored = await storage.get(baseKeyString) || [];
      setBaseCached(stored);
    };
    loadStoredBase();
  }, [storage, baseKeyString]);
  const graphQLClient = getGraphQLClient(userState, domainParam);
  const variablesBase: Variables = {
    regionId: region,
  };
  const loadBaseEnabled = baseCached !== null && baseCached.length === 0 && userState.userAuthPayload?.accessToken !== undefined;

  const baseQuery = useQuery({
    queryKey: [...baseKey, variablesBase],
    queryFn: async (ctx: QueryFunctionContext) => {
      await checkTokenExpiration();
      return graphQLClient.request(baseGraphqlQuery, ctx.queryKey[1]); 
    },
    enabled: loadBaseEnabled,
    refetchOnWindowFocus: false,
  });

  const { data: dataBase, error: errorBase, isLoading: isLoadingBase, isFetching: isFetchingBase } = baseQuery;

  let base = [];
  if (baseCached?.length > 0) {
    base = baseCached;
  } else {
    base = !isLoadingBase && !isFetchingBase ? dataBase?.[baseResult] || [] : [];
  }
  if (base.length > 0 && baseCached?.length === 0) {
    storage.set(baseKeyString, base);
  }
  // eslint-disable-next-line no-console
  if (errorBase) console.error(errorBase);
  return base;
};

export default useBaseCacheAndList;