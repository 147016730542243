// @ts-nocheck
export default function debounce(func, wait) {
  let timeout;
  // eslint-disable-next-line func-names
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export const deepClone = <T>(source: T): T => {
  if (source === null || typeof source !== 'object') {
    return source;
  }

  if (Array.isArray(source)) {
    return source.map((item) => deepClone(item)) as any;
  }

  const clonedObject = {} as T;
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      clonedObject[key] = deepClone(source[key]);
    }
  }

  return clonedObject;
};

// eslint-disable-next-line no-promise-executor-return
export const delay = (ms: number) => new Promise( resolve => setTimeout(resolve, ms) );