import React, { CSSProperties, FC } from 'react';

type StyleType = {
  container: CSSProperties;
};

type Props = {
  number: number;
};

const styles: StyleType = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    color: '#666666',
    marginRight: 27,
    marginTop: 20,
    marginLeft: 27,
    padding: 16,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#E5E5E5',
  },
};

const TBD: FC<Props> = ({ number }) => (
  <div style={styles.container}>
    WORK IN PROGRESS {number}
  </div>
);

export default TBD;
