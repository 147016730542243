import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { close } from 'ionicons/icons';
import Moment from 'moment';
import ReactQuill from 'react-quill';
import { useAuth } from '../hooks/authContext';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import useFormViewModel, { PrintFormProps } from './FormViewModel';
import './WorkAuthorizationForm.css';
import FosCertificateSelectItem from '../atom/FosCertificateSelectItem';
import FosLabelCheckbox from '../atom/FosLabelCheckbox';
import openDocumentURL from '../pages/helper/Documents';
import FosCurrencyInput from '../components/FosCurrencyInput';
import { Template as TemplateEnum } from '../utils/FormTemplate';

type FormData = {
  perAttachedQuote: boolean;
  quoteDate?: Date;
  scopeOfWork?: string;
  includeSignature: boolean;
  private: boolean;
  language?: string;
  phaseIndx?: number;
  ownerEmail?: string;
  ownerName?: string;
  estimateAmount?: string;
};

const WorkAuthorizationForm: React.FC<PrintFormProps> = ({ isOpen, claimIndx, onDismiss, onSubmit, region, claim }) => {
  const { t } = useTranslation();
  const {
    validateField,
    validateEmail,
    isLoading,
    setIsLoading,
    isEmailAlertOpen,
    isNameAlertOpen,
    setIsNameAlertOpen,
    isPhaseAlertOpen,
    setIsPhaseAlertOpen,
    isLanguageAlertOpen,
    setIsLanguageAlertOpen,
    selectedTemplate,
    setSelectedTemplate,
    isDateAlertOpen,
    setIsDateAlertOpen,
    phaseArray,
    setPhaseArray,
    isEstimateAlertOpen,
    setIsEstimateAlertOpen,
    setIsEmailAlertOpen,
    renderPhaseOptions,
  } = useFormViewModel();
  const { userState } = useAuth()!;
  const restClient = getRestClient(userState, ClientType.FORM);
  const { user } = useAuth()!;

  const [formData, setFormData] = useState<FormData>({
    perAttachedQuote: false,
    quoteDate: undefined,
    scopeOfWork: undefined,
    includeSignature: true,
    private: false,
    language: undefined,
    phaseIndx: undefined,
    ownerEmail: undefined,
    ownerName: undefined,
    estimateAmount: undefined,
  });

  useEffect(() => {
    if (claim?.data.claim.phases !== phaseArray?.length) {
      setPhaseArray(claim?.data.claim.phases as Array<any>);
    }
  }, [claim, phaseArray, setPhaseArray]);

  function validateForm(data: FormData) {
    let check = true;
    check = validateEmail(setIsEmailAlertOpen, data.ownerEmail) === false ? false : check;
    check = validateField(data.ownerName === undefined, setIsNameAlertOpen) === false ? false : check;
    check = validateField(data.phaseIndx === undefined, setIsPhaseAlertOpen) === false ? false : check;
    check = validateField(data.language === undefined, setIsLanguageAlertOpen) === false ? false : check;
    check =
      validateField(data.quoteDate === undefined && data.perAttachedQuote === true, setIsDateAlertOpen) === false
        ? false
        : check;
    return check;
  }
  function validateFormTD(data: FormData) {
    let check = true;
    check = validateEmail(setIsEmailAlertOpen, data.ownerEmail) === false ? false : check;
    check = validateField(data.ownerName === undefined, setIsNameAlertOpen) === false ? false : check;
    check = validateField(data.language === undefined, setIsLanguageAlertOpen) === false ? false : check;
    check = validateField(data.estimateAmount === undefined, setIsEstimateAlertOpen) === false ? false : check;

    return check;
  }

  const onClickSignNowHandler = async () => {
    if (validateForm(formData)) {
      setIsLoading(true);
      try {
        restClient
          .post('/common/work-authorization/sign', {
            regionId: region,
            claimIndx,
            phaseIndx: formData.phaseIndx,
            ownerName: formData.ownerName,
            ownerEmail: formData.ownerEmail,
            fosEmail: userState.userAuthPayload?.preferredUsername,
            dateOfQuote: Moment(formData.quoteDate).format('MM/DD/YYYY'),
            asOutlined: formData.scopeOfWork !== undefined,
            asPerAttached: formData.perAttachedQuote,
            scope: formData.scopeOfWork,
            language: formData.language,
            fosName: '',
            isTd: false,
            estimateAmount: 0,
          })
          .then((res) => {
            openDocumentURL(res.data.signingUrl);
          })
          .then(() => {
            setIsLoading(false);
            onSubmit(true);
          })
          .catch((ex) => {
            const msg = ex?.response?.data?.ErrorCode === 400 ? t('errorXSS') : '';
            setIsLoading(false);
            onSubmit(false, msg);
          });
      } catch {
        setIsLoading(false);
        onSubmit(false);
      }
    }
  };

  const onClickSignNowHandlerTD = async () => {
    if (validateFormTD(formData)) {
      setIsLoading(true);
      try {
        restClient
          .post('/common/work-authorization/sign', {
            regionId: region,
            claimIndx,
            phaseIndx: formData.phaseIndx,
            ownerName: formData.ownerName,
            ownerEmail: formData.ownerEmail,
            fosEmail: userState.userAuthPayload?.preferredUsername,
            dateOfQuote: Moment(formData.quoteDate).format('MM/DD/YYYY'),
            asOutlined: formData.scopeOfWork !== undefined,
            asPerAttached: formData.perAttachedQuote,
            scope: '',
            fosName: user?.fullName ?? '',
            language: formData.language,
            isTd: true,
            estimateAmount: formData.estimateAmount,
          })
          .then((res) => {
            openDocumentURL(res.data.signingUrl);
          })
          .then(() => {
            setIsLoading(false);
            onSubmit(true);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } catch {
        setIsLoading(false);
        onSubmit(false);
      }
    }
  };

  const getDateValueString = () => {
    if (formData.quoteDate !== undefined) {
      return Moment(formData.quoteDate).format('DD MMMM, YYYY');
    }
    return undefined;
  };
  const isDate = () => {
    if (formData.quoteDate === undefined) {
      return true;
    }
    return false;
  };
  if (selectedTemplate === TemplateEnum.TD) {
    return (
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar class="header">
            <IonTitle>{t('workAuthorization').toString()}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onDismiss()}>
                <div className="close-icon-container">
                  <IonIcon className="tools-close-button" icon={close} />
                </div>{' '}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding">
          <FosCertificateSelectItem
            altBorder
            asterisk
            label={t('template')}
            options={[
              { label: t('standardResidential'), value: 1 },
              { label: t('TD'), value: 2 },
            ]}
            onChange={(newValue) => {
              setSelectedTemplate(newValue.detail.value);
            }}
            placeholder={t('standardResidential')}
          />

          <div className="certificate-of-completion-section row">
            <IonLabel className="select-label">{t('ownerName').toString()}*</IonLabel>
            <IonTextarea
              name="ownerName"
              className="work-auth-border-color"
              autoGrow
              required
              value={formData.ownerName}
              onIonChange={(ev) => {
                setFormData((prevData) => {
                  const newData = {
                    ...prevData,
                    ownerName: ev.detail.value!.toString(),
                  };
                  setIsNameAlertOpen(newData.ownerName === undefined);
                  return newData;
                });
              }}
            />
            {isNameAlertOpen && (
              <IonText className="invalid-entry-message">{t('pleaseEnterAnOwnerName').toString()}</IonText>
            )}
          </div>
          <div className="certificate-of-completion-section row">
            <IonLabel className="select-label">{t('ownerEmail').toString()}*</IonLabel>
            <IonTextarea
              name="ownerEmail"
              className="work-auth-border-color"
              autoGrow
              required
              value={formData.ownerEmail}
              onIonChange={(evt) => {
                setFormData((prevData) => {
                  const newData = {
                    ...prevData,
                    ownerEmail: evt.detail.value!.toString(),
                  };
                  validateEmail(setIsEmailAlertOpen, newData.ownerEmail);
                  return newData;
                });
              }}
            />
            {isEmailAlertOpen && (
              <IonText className="invalid-entry-message">{t('pleaseEnterAValidEmailAddress').toString()}</IonText>
            )}
          </div>

          <div className="certificate-of-completion-section row">
            <IonLabel className="select-label">{t('estimateAmount').toString()}*</IonLabel>
            <div style={{ padding: '5px' }} />
            <FosCurrencyInput
              disableMarginBottom
              value={formData.estimateAmount?.toString()}
              onChange={(evt) => {
                setFormData((prevData) => {
                  const newData = {
                    ...prevData,
                    estimateAmount: evt,
                  };
                  setIsEstimateAlertOpen(newData.estimateAmount === undefined);
                  return newData;
                });
              }}
            />
            {isEstimateAlertOpen && (
              <IonText className="invalid-entry-message">{t('pleaseEnterAnEstimateAmount').toString()}</IonText>
            )}
          </div>

          <FosCertificateSelectItem
            altBorder
            disableMarginBottom
            asterisk
            label={t('language')}
            options={[
              { label: t('english'), value: 'english' },
              { label: t('french'), value: 'french' },
            ]}
            onChange={(newValue) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  language: newValue.detail.value,
                };
                setIsLanguageAlertOpen(newData.language === undefined);
                return newData;
              });
            }}
          />
          {isLanguageAlertOpen && (
            <IonText className="invalid-entry-message margin-bottom-adjust">
              {t('pleaseSelectLanguage').toString()}
            </IonText>
          )}
          <IonFooter class="add-padding">
            <IonButton
              class=" button-fill-margin"
              fill="solid"
              expand="block"
              onClick={() => onClickSignNowHandlerTD()}
            >
              {t('signNow').toString()}
            </IonButton>
          </IonFooter>
          <IonLoading isOpen={isLoading} message={t('loadingForm')} />
        </IonContent>
      </IonModal>
    );
  }
  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar class="header">
          <IonTitle>{t('workAuthorization').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <div className="close-icon-container">
                <IonIcon className="tools-close-button" icon={close} />
              </div>{' '}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <FosCertificateSelectItem
          altBorder
          asterisk
          label={t('template')}
          options={[
            { label: t('standardResidential'), value: 1 },
            { label: t('TD'), value: 2 },
          ]}
          onChange={(newValue) => {
            setSelectedTemplate(newValue.detail.value);
          }}
          placeholder={t('standardResidential')}
        />
        <div>
          <FosLabelCheckbox
            label={t('asPerAttachedQuote').toString()}
            onValueChange={() => {
              if (formData.perAttachedQuote) {
                setFormData({ ...formData, perAttachedQuote: !formData.perAttachedQuote, quoteDate: undefined });
                setIsDateAlertOpen(false);
              } else {
                setFormData({ ...formData, perAttachedQuote: !formData.perAttachedQuote });
              }
            }}
            value={!!formData.perAttachedQuote}
            checked={!!formData.perAttachedQuote}
          />
        </div>
        <div className="ion-margin-bottom" hidden={!formData.perAttachedQuote}>
          <IonItem className="work-auth-border-color fos-label-value-item-style" id="date-trigger">
            <div className="label-container" />
            <IonLabel className="fos-certificate-select-label">
              <IonText className="fos-select-date-label">{t('quoteDate').toString()}*</IonText>
            </IonLabel>
            <IonText hidden={isDate()} className="wa-select-date-label">
              {getDateValueString()}
            </IonText>
            <IonModal trigger="date-trigger" className="center fixed-300-width" keepContentsMounted>
              <IonDatetime
                showDefaultButtons
                id="quoteDate"
                presentation="date"
                name="quoteDate"
                value={formData.quoteDate?.toISOString()}
                onIonChange={(newValue) => {
                  if (typeof newValue.detail.value === 'string' && newValue.detail.value !== undefined) {
                    setFormData({
                      ...formData,
                      quoteDate: new Date(Date.parse(newValue.detail.value)),
                    });
                    setIsDateAlertOpen(false);
                  }
                  if (newValue.detail.value === undefined && formData.perAttachedQuote === true) {
                    setIsDateAlertOpen(true);
                  }
                }}
              />
            </IonModal>
          </IonItem>
          {isDateAlertOpen && (
            <IonText className="invalid-entry-message">{t('pleaseSelectQuoteDate').toString()}</IonText>
          )}
        </div>
        <FosCertificateSelectItem
          altBorder
          asterisk
          disableMarginBottom
          label={t('Phase')}
          options={renderPhaseOptions()}
          onChange={(newValue) => {
            setFormData((prevData) => {
              const newData = {
                ...prevData,
                phaseIndx: newValue.detail.value,
              };
              setIsPhaseAlertOpen(newData.phaseIndx === undefined);
              return newData;
            });
          }}
        />
        {isPhaseAlertOpen && <IonText className="invalid-entry-message">{t('pleaseSelectPhase').toString()}</IonText>}
        <div className="scope-of-work-section row add-padding">
          <IonLabel className="select-label">{t('scopeOfWork').toString()}</IonLabel>
          <ReactQuill
            className="scope-of-work"
            value={formData.scopeOfWork}
            onChange={(e) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  scopeOfWork: e,
                };
                return newData;
              });
            }}
          />
        </div>

        <div className="certificate-of-completion-section row">
          <IonLabel className="select-label">{t('ownerName').toString()}*</IonLabel>
          <IonTextarea
            name="ownerName"
            className="work-auth-border-color"
            autoGrow
            required
            value={formData.ownerName}
            onIonChange={(ev) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  ownerName: ev.detail.value!.toString(),
                };
                setIsNameAlertOpen(newData.ownerName === undefined);
                return newData;
              });
            }}
          />
          {isNameAlertOpen && (
            <IonText className="invalid-entry-message">{t('pleaseEnterAnOwnerName').toString()}</IonText>
          )}
        </div>
        <div className="certificate-of-completion-section row">
          <IonLabel className="select-label">{t('ownerEmail').toString()}*</IonLabel>
          <IonTextarea
            name="ownerEmail"
            className="work-auth-border-color"
            autoGrow
            required
            value={formData.ownerEmail}
            onIonChange={(evt) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  ownerEmail: evt.detail.value!.toString(),
                };
                validateEmail(setIsEmailAlertOpen, newData.ownerEmail);
                return newData;
              });
            }}
          />
          {isEmailAlertOpen && (
            <IonText className="invalid-entry-message">{t('pleaseEnterAValidEmailAddress').toString()}</IonText>
          )}
        </div>
        <FosCertificateSelectItem
          altBorder
          disableMarginBottom
          asterisk
          label={t('language')}
          options={[
            { label: t('english'), value: 'english' },
            { label: t('french'), value: 'french' },
          ]}
          onChange={(newValue) => {
            setFormData((prevData) => {
              const newData = {
                ...prevData,
                language: newValue.detail.value,
              };
              setIsLanguageAlertOpen(newData.language === undefined);
              return newData;
            });
          }}
        />
        {isLanguageAlertOpen && (
          <IonText className="invalid-entry-message margin-bottom-adjust">
            {t('pleaseSelectLanguage').toString()}
          </IonText>
        )}
        <IonFooter class="add-padding">
          <IonButton class=" button-fill-margin" fill="solid" expand="block" onClick={() => onClickSignNowHandler()}>
            {t('signNow').toString()}
          </IonButton>
        </IonFooter>
        <IonLoading isOpen={isLoading} message={t('loadingForm')} />
      </IonContent>
    </IonModal>
  );
};

export default WorkAuthorizationForm;
