import { gql } from '@apollo/client';

const GET_TEMPLATES = gql`
query templates($lossType: String) {
  templates(lossType: $lossType) 
  {
    id
    lossType
    subType 
    templateName 
    causeOfLoss 
    preExistingFactors
    areaDamages
    contents
  }
}`;

export default GET_TEMPLATES;