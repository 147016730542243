/* eslint-disable @typescript-eslint/comma-dangle */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './hooks/authContext';

import './i18n';
import { PhotoUploadProvider } from './contexts/PhotoUploadContext';
import { NetworkProvider } from './contexts/NetworkContext';
import { PhotoDownloadProvider } from './contexts/PhotoDownloadContext';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <NetworkProvider>
      <AuthProvider>
        <PhotoUploadProvider>
          <PhotoDownloadProvider>
            <App />
          </PhotoDownloadProvider>
        </PhotoUploadProvider>
      </AuthProvider>
    </NetworkProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// eslint-disable-next-line no-console
console.log('sw-version-v4');
