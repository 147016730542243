import { gql } from '@apollo/client';

const DELETE_NOTE = gql`
mutation DeleteNote($regionId: Int!, $id: Int!) {
  deleteNote(regionId: $regionId, id: $id)
  {
    status
  }
}`;

export default DELETE_NOTE;
