import { gql } from '@apollo/client';

const SAVE_CLAIM_LEVELS = gql`
mutation saveLevels($claimIndx: Int!, $regionId: Int!, $levels: [SaveLevelDtoInput!]) {
  saveLevels(claimIndx: $claimIndx, regionId: $regionId, levels: $levels)
  {
    itemId
    status
    message
  }
}`;

export default SAVE_CLAIM_LEVELS;
