import { GalleryPhoto, Photo } from '@capacitor/camera';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { isPlatform } from '@ionic/react';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';

// eslint-disable-next-line import/prefer-default-export
export const PHOTO_STORAGE: string = 'photos';

export const HYBRID = 'hybrid';

export interface UserPhoto {
  filepath: string;
  webviewPath: string;
}

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('method did not return a string');
      }
    };
    reader.readAsDataURL(blob);
  });
}

export const getDataFromPhoto = async (photo: Photo): Promise<string> => {
  let base64Data: string;
  // "hybrid" will detect Cordova or Capacitor;
  if (isPlatform(HYBRID)) {
    const file = await Filesystem.readFile({
      path: photo.path!,
    });
    base64Data = file.data;
    const savedFile = await Filesystem.writeFile({
      path: 'temp',
      data: base64Data,
      directory: Directory.Data,
    });
    return Capacitor.convertFileSrc(savedFile.uri);
  }
  base64Data = await base64FromPath(photo.webPath!);
  return base64Data;

};

export const getDataFromGalleryPhotoArray = async (photos: GalleryPhoto[]): Promise<string[]> => {
  const base64Promises = photos.map((photo: GalleryPhoto) => getDataFromPhoto(photo as Photo));

  const base64Array = await Promise.all(base64Promises);

  return base64Array;
};

export const savePicture = async (photo: Photo, fileName: string): Promise<UserPhoto> => {
  let base64Data: string;
  // "hybrid" will detect Cordova or Capacitor;
  if (isPlatform(HYBRID)) {
    const file = await Filesystem.readFile({
      path: photo.path!,
    });
    base64Data = file.data;
  } else {
    base64Data = await base64FromPath(photo.webPath!);
  }
  const savedFile = await Filesystem.writeFile({
    path: fileName,
    data: base64Data,
    directory: Directory.Data,
  });

  let photoSaved;
  if (isPlatform(HYBRID)) {
    // Display the new image by rewriting the 'file://' path to HTTP
    // Details: https://ionicframework.com/docs/building/webview#file-protocol
    photoSaved = {
      filepath: savedFile.uri,
      webviewPath: Capacitor.convertFileSrc(savedFile.uri),
    };
  } else {
    // Use webPath to display the new image instead of base64 since it's
    // already loaded into memory
    photoSaved = {
      filepath: fileName,
      webviewPath: photo.webPath!,
    };
  }
  Preferences.set({
    key: PHOTO_STORAGE,
    value: JSON.stringify(photoSaved),
  });

  return photoSaved;
};

export const loadSaved = async (): Promise<UserPhoto> => {
  const { value } = await Preferences.get({ key: PHOTO_STORAGE });

  const photo = (value ? JSON.parse(value) : {}) as UserPhoto;
  // If running on the web...
  if (!isPlatform(HYBRID)) {
    const file = await Filesystem.readFile({
      path: photo.filepath,
      directory: Directory.Data,
    });
    // Web platform only: Load the photo as base64 data
    photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
  }
  return photo;
};
