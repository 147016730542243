import { gql } from '@apollo/client';

const DELETE_LEVEL =  gql`mutation deleteLevel($id: Int!) {
  deleteLevel(id: $id)
  {
    itemId
    status
    message
    errors {
      code
      value
    }
  }
}`;

export default DELETE_LEVEL;