import { useMutation } from '@tanstack/react-query';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import { BranchDtoInput, ExpenseObjectDtoInput } from './expSaveExpense';
import SAVE_MILEAGE_EXPENSE from '../graphql/ExpMileageSaveExpense';
import { GUID } from '../graphql/GetUserProfileInfo';

export interface MileageExpenseDtoInput {
  expenseObject: ExpenseObjectDtoInput
  branch: BranchDtoInput
  notPersisted: boolean
  ytdMileageKms: number
}

/*
* GraphQL call: save mileage expense
* */
const useSaveMileageExpense = () => {
  const userGuid = window.localStorage.getItem(GUID) as string;

  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState, DomainParams.Payroll);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({ expenses, releaseAfterSave }: { expenses: MileageExpenseDtoInput[], releaseAfterSave: boolean }) =>
      graphQLClient.request(SAVE_MILEAGE_EXPENSE, { userGuid, expenses, releaseAfterSave }),
  });
  return { isLoading, mutateAsync };
};

export default useSaveMileageExpense;