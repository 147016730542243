import { Variables } from 'graphql-request';
import { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import GET_APPLICATION_PROPERTY from '../graphql/GetApplicationProperty';
import useBaseQuery from './baseQuery';

const useGetApplicationProperty = (applicationId: string, propertyName: string) => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;

  const variablesRegion: Variables = {
    applicationId,
    propertyName,
  };
  const { isLoading, data } = useBaseQuery(
    regionId,
    variablesRegion,
    userState,
    true,
    GET_APPLICATION_PROPERTY,
    ['applicationProperty'],
    DomainParams.Common,
  );
  return { isLoading, propertyValue: (data?.applicationProperty?.propertyValue) || '' };
};

export default useGetApplicationProperty;