import { IonLabel, IonList, IonNote, IonTextarea } from '@ionic/react';
import React from 'react';
import './FosTextArea.css';

export interface Props {
  label?: string;
  value?: string;
  placeholder?: string;
  rows?: number;
  onIonChange?: (event: any) => any;
  required?: boolean
  error?: string
  disabled?: boolean
}

const defaultRows = 4;

const FosTextArea: React.FC<Props> = React.forwardRef<HTMLIonTextareaElement, Props>(({
  label,
  value,
  placeholder,
  rows = defaultRows,
  required,
  error,
  disabled,
  onIonChange,
}, ref) => (
  <IonList className='ion-padding-bottom'>
    <IonLabel>{label}
    </IonLabel>
    <IonTextarea
      className='fos-text-area-border custom-textarea'
      disabled={disabled}
      autoGrow
      ref={ref}
      placeholder={`${placeholder}${required ? ' *' : ''}`}
      value={value}
      onIonChange={onIonChange}
      rows={rows}
      required={required}
    />
    {error && <small className="ion-padding"><IonNote color="danger">{error}</IonNote></small>}
  </IonList>
));

export default FosTextArea;
