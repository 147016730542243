import React, { FC } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import './FosButtonIcon.css';

export enum FosButtonIconStyle {
  White = 'white',
  Orange = 'orange',
  Blue = 'blue',
}

type Props = {
  id?: string;
  icon?: string;
  style?: FosButtonIconStyle;
  onClick?: () => void;
};

const FosButtonIcon: FC<Props> = ({ id, icon, onClick, style = FosButtonIconStyle.White }) => {
  const handleBackgroundColor = (): string => {
    switch (style) {
      case FosButtonIconStyle.White: return 'fos-button-background-white';
      case FosButtonIconStyle.Blue: return 'fos-button-background-blue';
      case FosButtonIconStyle.Orange: return 'fos-button-background-orange';
      default: return 'fos-button-background-white';
    }
  };

  const handleIconColor = (): string => {
    switch (style) {
      case FosButtonIconStyle.White: return 'fos-button-icon-color-blue';
      case FosButtonIconStyle.Blue: return 'fos-button-icon-color-grey';
      case FosButtonIconStyle.Orange: return 'fos-button-icon-color-white';
      default: return 'fos-button-icon-color-blue';
    }
  };

  return (
    <IonButton
      id={id}
      fill='clear'
      className={`fos-button-icon-container ion-no-padding ion-no-margin ${handleBackgroundColor()}`}
      onClick={onClick}>
      <IonIcon className={`fos-button-icon ${handleIconColor()}`} icon={icon} />
    </IonButton>
  );
};


export default FosButtonIcon;
