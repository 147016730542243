import { gql } from '@apollo/client';

const GET_SITE_INSPECTION_INFO = gql`
  query inspectionSiteInfo($claimId: String) {
    inspectionSiteInfo(claimId: $claimId) {
      id
      claimId
      causeOfLoss
      class
      categoryOfLoss
      categoryOfSource
      docusketchLink
      hvacaffected
      suddenLoss
      emReserve
      reReserve
      ctReserve
      mobilizationEta
      nonRestorableContent
      emAfterHours
      emEstimateNeeded
      repairEstimateNeeded
      tearOutNeeded
      generalCauseDetails
      preExistingFactors
      fieldCrewNotes
      lossOfIncome
      contents
      areaDamages
      dryingStrategy
      emEstimatedDuration
      reEstimatedDuration
      contentsHandling
      otherInfoBox
      isAsbestos
      isMold
      preexistingDamage
      pmEmail
      pmPhone
      isAviva
    }
  }
`;

export default GET_SITE_INSPECTION_INFO;
