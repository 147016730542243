import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';
import SAVE_SKETCH from '../graphql/SaveSketch';

/*
* GraphQL call: save sketch
* */
const useSaveSketch = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (sketch: any) => graphQLClient.request(SAVE_SKETCH, { sketch }),
  });
  return { isLoading, mutateAsync };
};

export default useSaveSketch;