import { gql } from '@apollo/client';

const SAVE_CLAIM_NOTE = gql`
mutation saveClaimNote($regionId: Int!, $note: ClaimNoteDtoInput!) {
  saveClaimNote(regionId: $regionId, note: $note)
  {
    itemId
    status
    message
  }
}`;

export default SAVE_CLAIM_NOTE;
