import { gql } from '@apollo/client';

const GET_ADJUSTERS_LIST = gql`
query adjusters($regionId: Int!) {
  adjusters(regionId: $regionId)
  { 
    activeclient
    adjCode
    clientEmail
    firstName
    lastName
  } 
}`;

export default GET_ADJUSTERS_LIST;