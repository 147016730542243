import React, { FC, useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonText,
  useIonRouter,
  IonButtons,
  IonTitle,
  IonButton,
  IonLabel,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import './Header.css';
import { Phase } from '../types/Phases';
import PhaseSelector from '../pages/PhaseSelector';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import { useAuth } from '../hooks/authContext';
import SAVE_PHASE from '../graphql/SavePhase';
import { PhaseListType } from '../pages/helper/PhaseList';
import './ClaimDetailsHeader.css';
import { ClaimDetailsMenuTabs, ClaimDetailsActiveOption } from '../pages/helper/ClaimDetailsMenuHelper';
import { DefaultPhaseIds } from '../pages/helper/AddPhaseActionSheetConst';
import { PhaseStatus } from '../pages/helper/Const';
import { ReactComponent as DetailsIcon } from '../assets/icons/ic_details.svg';
import { ReactComponent as DocumentsIcon } from '../assets/icons/ic_documents.svg';
import { ReactComponent as MoistureIcon } from '../assets/icons/ic_moisture.svg';
import { ReactComponent as NotesIcon } from '../assets/icons/ic_notes.svg';
import { ReactComponent as PhotosIcon } from '../assets/icons/ic_photos.svg';
import { PermissionsList, usePermissions } from '../hooks/permissions';
import { ReactComponent as RightChevronIcon } from '../assets/icons/ic_chevron_right.svg';
import { getErrorMsg } from '../pages/helper/AppHelper';

type Props = {
  claimNumber: string;
  claimStatus: string;
  lossType: string;
  phases: Array<Phase>;
  isFetching: boolean;
  jobIdx: string;
  yearOfConstruction: string;
  setActivePhase: (value: number) => void;
  refetchClaim: () => void;
  activeClaimDetailOption: ClaimDetailsActiveOption;
  onClaimDetailOptionPress: (option: ClaimDetailsActiveOption) => void;
  insurer: string;
};

interface PhasePayload {
  phaseId: string;
  description: string;
  pmId: string;
  estValue: number;
  openedBy: string;
  claimIndx: number;
  phaseIndx: number;
  status: number;
}

interface MutationParams {
  regionId: number;
  phase: PhasePayload;
  xactTransactionId?: string;
}

const ClaimDetailsHeader: FC<Props> = ({
  claimNumber,
  claimStatus,
  lossType,
  phases,
  isFetching,
  jobIdx,
  yearOfConstruction,
  setActivePhase,
  refetchClaim,
  activeClaimDetailOption,
  onClaimDetailOptionPress,
  insurer,
}) => {
  const navigation = useIonRouter();
  const { userState, user } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const [saveError, setSaveError] = useState('');
  const { t } = useTranslation();

  const { permissions } = usePermissions();
  const isEditClaimsEnabled = permissions.includes(PermissionsList.EditClaims);

  const { mutate } = useMutation({
    mutationFn: (mutationParams: MutationParams) => getGraphQLClient(userState).request(SAVE_PHASE, mutationParams),
  });

  const onAddPhaseError = (error: any) => {
    const msg = getErrorMsg(error);
    const message = msg || t('saveClaimError');
    setSaveError(message);
  };

  const addPhaseItem = (value: PhaseListType) => {
    mutate(
      {
        regionId,
        phase: {
          phaseId: value.phaseId,
          description: value.description,
          pmId: value.projectManagerId!,
          estValue: value.estValue!,
          openedBy: user?.firstName && user?.lastName ? `${user?.firstName[0]}.${user?.lastName}` : '',
          claimIndx: Number(jobIdx),
          phaseIndx: 0,
          status: value.phaseId === DefaultPhaseIds.EMERGENCY ? PhaseStatus.NewA : PhaseStatus.New,
        },
        xactTransactionId: value.xactTransactionId || undefined,
      },
      {
        onSuccess: refetchClaim,
        onError: onAddPhaseError,
      },
    );
  };

  const selected = 'claim-details-menu-icon claim-details-menu-selected ion-no-padding';
  const unselected = 'claim-details-menu-icon claim-details-menu-not-selected ion-no-padding';

  const handleIconSelectionColor = (tabInfo: ClaimDetailsMenuTabs): string =>
    activeClaimDetailOption === tabInfo ? 'icon-selected' : 'icon-not-selected';

  const isMoistureEnabled = process.env.REACT_APP_ENV !== 'prod';

  const onBackClick = () => {
    navigation.goBack();
  };

  
  return (
    <IonHeader>
      <IonToolbar className="claim-details-header">
        <IonButtons slot="start">
          <IonButton onClick={() => onBackClick()}>
            <RightChevronIcon className="left-chevron" fill="none" />
            {t('back').toString()}
          </IonButton>
        </IonButtons>
        <IonTitle>
          <div>
            <IonText className="status">{claimStatus}</IonText>
          </div>
          <IonLabel className="claim-title">
            {claimNumber} ({lossType})
          </IonLabel>
        </IonTitle>
        {isEditClaimsEnabled && (
          <IonButtons slot="end">
            <IonButton
              onClick={() =>
                navigation.push(
                  `/jobs/${jobIdx}/site-inspection/${claimNumber}/${encodeURIComponent(lossType)}/${yearOfConstruction}/${insurer}`,
                )
              }
            >
              {t('inspect').toString()}
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
      <IonToolbar className="ion-padding-horizontal claim-details-header">
        <div className="phase-select-mask">
          {phases?.length && (
            <PhaseSelector
              isFetching={isFetching}
              phases={phases || []}
              onChange={setActivePhase}
              addPhase={addPhaseItem}
              addPhaseError={saveError}
              setAddPhaseError={setSaveError}
            />
          )}
        </div>
      </IonToolbar>
      <IonToolbar className="claim-details-header ion-no-padding">
        <div className="icon-row claim-details-header">
          <IonButton
            fill="clear"
            className={activeClaimDetailOption === ClaimDetailsMenuTabs.ClaimInfo ? selected : unselected}
            onClick={() => onClaimDetailOptionPress(ClaimDetailsMenuTabs.ClaimInfo)}
          >
            <DetailsIcon fill="none" className={handleIconSelectionColor(ClaimDetailsMenuTabs.ClaimInfo)} />
          </IonButton>
          <IonButton
            fill="clear"
            className={activeClaimDetailOption === ClaimDetailsMenuTabs.Photos ? selected : unselected}
            onClick={() => onClaimDetailOptionPress(ClaimDetailsMenuTabs.Photos)}
          >
            <PhotosIcon fill="none" className={handleIconSelectionColor(ClaimDetailsMenuTabs.Photos)} />
          </IonButton>
          {isMoistureEnabled && (
            <IonButton
              fill="clear"
              className={activeClaimDetailOption === ClaimDetailsMenuTabs.Moisture ? selected : unselected}
              onClick={() => onClaimDetailOptionPress(ClaimDetailsMenuTabs.Moisture)}
            >
              <MoistureIcon fill="none" className={handleIconSelectionColor(ClaimDetailsMenuTabs.Moisture)} />
            </IonButton>
          )}
          <IonButton
            fill="clear"
            className={activeClaimDetailOption === ClaimDetailsMenuTabs.Documents ? selected : unselected}
            onClick={() => onClaimDetailOptionPress(ClaimDetailsMenuTabs.Documents)}
          >
            <DocumentsIcon className={handleIconSelectionColor(ClaimDetailsMenuTabs.Documents)} />
          </IonButton>
          <IonButton
            fill="clear"
            className={activeClaimDetailOption === ClaimDetailsMenuTabs.Notes ? selected : unselected}
            onClick={() => onClaimDetailOptionPress(ClaimDetailsMenuTabs.Notes)}
          >
            <NotesIcon fill="none" className={handleIconSelectionColor(ClaimDetailsMenuTabs.Notes)} />
          </IonButton>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default ClaimDetailsHeader;
