/* eslint-disable react/require-default-props */
import React, { FC, MouseEvent, useState } from 'react';
import {
  IonText,
  IonButton,
  IonIcon,
  IonSpinner,
  useIonAlert,
  IonModal,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { locationOutline, cloudDownloadOutline } from 'ionicons/icons';
import toast from 'react-hot-toast';
import useHandleJobLocationMap from '../hooks/handleJobLocationMap';
import useHandleJobContactsCall from '../hooks/handleJobContactsCall';

import { ReactComponent as MobileIcon } from '../assets/icons/ic_mobile.svg';

import './JobCard.css';
import { ClaimOnlineStatus } from '../pages/helper/Const';
import FosModalHeader from '../atom/FosModalHeader';
import ClaimNotificationForm from '../organism/ClaimNotificationForm';
import { useAuth } from '../hooks/authContext';

type Props = {
  claimNumber: string;
  dateOpened: Date;
  lossType: string;
  fullAddr?: string;
  projAddr: string;
  projCity: string;
  projProv: string;
  status: string;
  projectManager: string;
  propertyManager: string;
  businessContact: string;
  projectName?: string;
  claimIndx: number;
  projectPhone1?: string;
  projectPhone2?: string;
  projectPhone3?: string;
  businessContactPhone?: string;
  lossAddressLat?: string;
  lossAddressLon?: string;
  hideButtons?: boolean;
  downloadingJobIdx?: number;
  onClick?: (jobIdx: number) => any;
  onDownloadClick?: (jobIdx: number) => any;
};

type StyleType = {
  mainContainer: React.CSSProperties;
  container: React.CSSProperties;
  title: React.CSSProperties;
  subtitle: React.CSSProperties;
  projectAddress: React.CSSProperties;
  status: React.CSSProperties;
  date: React.CSSProperties;
  inlineStatusContent: React.CSSProperties;
  inlineAddressContent: React.CSSProperties;
  inlineButtonsContent: React.CSSProperties;
  label: React.CSSProperties;
  labelManager: React.CSSProperties;
  verticalLine: React.CSSProperties;
  row: React.CSSProperties;
  column: React.CSSProperties;
  jobCardActionButton: React.CSSProperties;
};

const styles: StyleType = {
  mainContainer: {
    paddingBottom: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 14,
    paddingBottom: 14,
    borderWidth: 1,
    background: '#F2F2F9',
    borderStartStartRadius: 10,
    borderStartEndRadius: 10,
    minWidth: 335,
    maxWidth: 335,
    width: '100%',
    marginTop: 10,
    paddingRight: 20,
    paddingLeft: 20,
  },
  title: {
    color: '#222222',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: 'Proxima Nova',
    marginBottom: 2,
  },
  subtitle: {
    color: '#222222',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '20px',
    fontFamily: 'Proxima Nova',
    marginBottom: 6,
  },
  projectAddress: {
    fontSize: 13,
    color: '#3D3D3D',
    fontWeight: 400,
    lineHeight: '16px',
    marginLeft: '6px',
  },
  status: {
    color: '#53C497',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: '12px',
    textTransform: 'uppercase',
  },
  date: {
    color: '#F7921E',
    fontSize: 10,
  },
  inlineStatusContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  inlineAddressContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  inlineButtonsContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    background: '#E7E7EF',
    borderEndEndRadius: 10,
    borderEndStartRadius: 10,
    alignItems: 'center',
  },
  label: {
    fontSize: '12px',
    color: '#898989',
  },
  labelManager: {
    color: '#F7921E',
    fontSize: '12px',
  },
  verticalLine: {
    borderLeft: '1px solid #F2F2F9',
    height: 42,
  },
  row: {
    display: 'flex',
  },
  column: {
    flex: '1',
    textAlign: 'start',
  },
  jobCardActionButton: {
    width: '50%',
  },
};

const JobCard: FC<Props> = (props: Props) => {
  const {
    claimNumber = '',
    dateOpened = new Date(),
    lossType = '',
    fullAddr,
    projAddr = '',
    projCity = '',
    projProv = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    status = ClaimOnlineStatus.Synched,
    projectManager = '',
    propertyManager = '',
    businessContact = '',
    projectName = '',
    claimIndx,
    projectPhone1,
    projectPhone2,
    projectPhone3,
    businessContactPhone,
    lossAddressLat = '',
    lossAddressLon = '',
    hideButtons = false,
    downloadingJobIdx,
    onClick,
    onDownloadClick,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const downloading = downloadingJobIdx === claimIndx;
  const downloadingAnotherClaim = downloadingJobIdx && downloadingJobIdx > 0;
  const localDateOpened = dateOpened ? dateOpened.toLocaleDateString() : '';
  const handleJobLocationMap = useHandleJobLocationMap();
  const handleJobContactsCall = useHandleJobContactsCall();
  const [presentAlert] = useIonAlert();
  const { t } = useTranslation();
  const { user } = useAuth()!;

  const handleCallButtonClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    handleJobContactsCall({
      projectName,
      businessContact,
      projectPhone1,
      projectPhone2,
      projectPhone3,
      businessContactPhone,
      claimIndx,
      setOpenModal,
    });
  };

  const onSaveNotification = (success: boolean) => {
    if (success) {
      toast.success(t('claimNotificationSaved'), { duration: 4000 });
    } else {
      toast.error(t('saveNotificationError'), { duration: 4000 });
    }
    setOpenModal(false);
  };

  const handleMapButtonClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    handleJobLocationMap({ lossAddressLat, lossAddressLon, projAddr });
  };

  const handleDownloadClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    if (downloadingAnotherClaim) {
      presentAlert({
        header: t('alert'),
        message: t('alreadyDownloadingClaim'),
        buttons: [t('ok')],
      });
      return;
    }
    if (onDownloadClick) onDownloadClick(claimIndx);
  };

  const setBorderEndRadius = hideButtons ? 10 : undefined;
  return (
    <div style={styles.mainContainer} onClick={() => { if (onClick) onClick(claimIndx); }} role="presentation">
      <div style={{ ...styles.container, borderEndStartRadius: setBorderEndRadius, borderEndEndRadius: setBorderEndRadius }} className="job-card">
        <div style={styles.inlineStatusContent}>
          <div>
            <IonText style={styles.status}>
              {status}
            </IonText>
          </div>
          <div>
            <IonText style={styles.date}>{localDateOpened}</IonText>
          </div>
        </div>
        <span style={styles.title} className="job-card-title">
          {claimNumber} {lossType && `(${lossType})`}
        </span>
        <span style={styles.subtitle} className="job-card-title">
          {projectName || ''}
        </span>

        {(projAddr || projCity || projProv || fullAddr) && <div style={styles.inlineAddressContent}>
          <div className="location-pin-wrapper">
            <IonIcon icon={locationOutline} className="location-pin-icon" />
          </div>
          <div style={styles.projectAddress} className="job-address">
            {!fullAddr && <span>{projAddr}, {projCity}, {projProv}</span>}
            {fullAddr && <span>{fullAddr}</span>}
          </div>
          {onDownloadClick && <div className='download-info' >
            {!downloading && <IonIcon icon={cloudDownloadOutline} onClick={(ev) => handleDownloadClick(ev)} className="download-icon" />}
            {downloading && <IonSpinner name="lines-sharp-small" />}
          </div>
          }
        </div>}
        {projectManager && <div style={styles.row}>
          <div style={styles.column}>
            <span style={styles.labelManager}>
              <IonText>
                {t('projectManager').toString()}
              </IonText>
            </span>
          </div>
          <div style={styles.column}>
            <span style={styles.label}>
              <IonText>{projectManager}</IonText>
            </span>
          </div>
        </div>}
        {propertyManager ? (
          <div style={styles.row}>
            <div style={styles.column}>
              <span style={styles.label}>
                <IonText>
                  {t('adjuster').toString()}
                </IonText>
              </span>
            </div>
            <div style={styles.column}>
              <span style={styles.label}>
                <IonText>{propertyManager}</IonText>
              </span>
            </div>
          </div>
        ) : null}
      </div>
      {!hideButtons && <div style={styles.inlineButtonsContent} className="job-cta-container">
        <IonButton color='light' fill='clear' onClick={handleMapButtonClick} style={styles.jobCardActionButton}>
          <IonIcon icon={locationOutline} className="location-icon" />
        </IonButton>
        <IonButton color='light' fill='clear' onClick={handleCallButtonClick} style={styles.jobCardActionButton}>
          <MobileIcon fill="none" className="phone-icon" />
        </IonButton>
      </div>}
      <IonModal isOpen={openModal} class="custom-modal" onClick={(ev: MouseEvent) => ev.stopPropagation()}>
        <FosModalHeader title={t('createNotification').toString()} onCloseClick={() => setOpenModal(false)} />
        <ClaimNotificationForm
          userName={user?.fullName ?? ''}
          claimIndx={claimIndx?.toString()}
          onSubmit={(success: boolean) => onSaveNotification(success)}
          defaultPhone={businessContactPhone || t('phoneNumberPlaceHolder')}
        />
      </IonModal>
    </div>
  );
};

export default JobCard;


