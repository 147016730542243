/* eslint-disable no-console */
import { Directory, FileInfo, Filesystem } from '@capacitor/filesystem';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { format } from 'date-fns';
import { closeOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FosCachedImage, { CACHE_FOLDER } from '../components/FosCachedImage';
import FosFooterButton from '../atom/FosFooterButton';

export interface Props {
  claimIndex: number
  isOpen: boolean
  onIonModalDidDismiss: (event: any) => any
}

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line prefer-exponentiation-operator, no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const ClaimStorageInfo: React.FC<Props> = ({ claimIndex, isOpen, onIonModalDidDismiss }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [presentAlert] = useIonAlert();

  const readFiles = useCallback(() => {
    if (!(claimIndex && isOpen)) return;
    Filesystem.readdir({
      path: `${CACHE_FOLDER}/${claimIndex}`,
      directory: Directory.Cache,
    })
      .then(dir => {
        setFiles(dir.files);
      })
      .catch(err => console.log('Error reading claim directory', err));
  }, [claimIndex, isOpen]);

  useEffect(() => {
    readFiles();
  }, [readFiles]);

  const doClearStorage = async () => {
    for (const file of files) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await Filesystem.deleteFile({
          path: `${CACHE_FOLDER}/${claimIndex}/${file.name}`,
          directory: Directory.Cache,
        });
      } catch (err) {
        console.log('Error deleting file', err);
      }
    }
    readFiles();
  };

  const clearStorage = () => {
    if (!files.length) return;
    presentAlert({
      header: t('clearFiles'),
      message: t('confirmRemoveFiles'),
      buttons: [
        t('cancel'),
        {
          handler: doClearStorage,
          text: t('clearClaimStorage'),
        },
      ],
    });
  };

  const totalSize = useMemo(() => {
    // eslint-disable-next-line no-return-assign
    const size = files.reduce((size, file) => size += file.size, 0);
    return formatBytes(size);
  }, [files]);

  return (
    <IonModal isOpen={isOpen} onIonModalDidDismiss={onIonModalDidDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('storageInfo').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onIonModalDidDismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonListHeader>
            <IonLabel>{t('generalInfo').toString()}</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel>
              <h3>{t('totalFiles').toString()}: {files.length}</h3>
              <p>{t('memoryUsage').toString()}: {totalSize}</p>
            </IonLabel>
          </IonItem>
        </IonList>
        {!!files.length && <IonList>
          <IonListHeader>
            <IonLabel>
              {t('claimFiles').toString()}
            </IonLabel>
          </IonListHeader>
          {files.map(file => (
            <IonItem key={file.name}>
              <IonThumbnail slot="start">
                <FosCachedImage src="" localPath={`${CACHE_FOLDER}/${claimIndex}/${file.name}`} />
              </IonThumbnail>
              <IonLabel>
                <h3>{t('lastModified').toString()}: {format(new Date(Number(file.mtime)), 'MM/dd/yyyy HH:mm')}</h3>
                <p>{formatBytes(file.size)}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>}
      </IonContent>
      <FosFooterButton onClick={clearStorage} label={t('clearClaimStorage')} />
    </IonModal>
  );
};

export default ClaimStorageInfo;
