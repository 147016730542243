import { gql } from '@apollo/client';

// TODO replace mutation once service is ready
const UPDATE_BULK_PHOTOS =  gql`mutation updateCtPhotos($request: UpdateCtPhotosInput) {
  updateCtPhotos(request: $request)
  {
    itemId
    status
    message
    errors {
      code
      value
    }
  }
}`;

export default UPDATE_BULK_PHOTOS;