/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
  IonLabel,
} from '@ionic/react';
import { MultiSelect } from 'react-multi-select-component';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { closeOutline, filterOutline } from 'ionicons/icons';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { Variables } from 'graphql-request';
import { useHistory, useLocation, useParams } from 'react-router';
import './ClaimEquipmentAssignment.css';
import { Geolocation } from '@capacitor/geolocation';
import { useAuth } from '../../../../hooks/authContext';
import GET_CLAIMS_LIST, { ClaimType } from '../../../../graphql/GetClaimsList';
import { getRefetchKey } from '../../../../pages/helper/JobsHelper';
import { OVERRIDE_STRINGS } from '../../../../pages/helper/Const';
import { ProjectManagersType } from '../../../../pages/helper/ProjectManagersType';
import { BranchesType } from '../../../../pages/helper/BranchesType';
import { LossTypesType } from '../../../../pages/helper/LossTypesType';
import { Option } from '../../../../utils/Interfaces';
import getGraphQLClient from '../../../../hooks/graphQLClientUtil';
import useStorage from '../../../../hooks/storage';
import useLossTypes from '../../../../hooks/lossTypes';
import useBranches from '../../../../hooks/branches';
import useProjectManagers from '../../../../hooks/projectManagers';

import GET_CLAIM_DETAILS from '../../../../graphql/GetClaimDetails';
import FilterModal from '../../../../organism/FilterModal';
import SearchInput from '../../../../atom/SearchInput';
import FosButtonIcon from '../../../../atom/FosButtonIcon';
import FosCard from '../../../../atom/FosCard';
import handleDateFormat, { DATE_FORMAT_MM_DD_YYYY } from '../../../../utils/DateFormat';
import FosModalHeader from '../../../../atom/FosModalHeader';
import AssignEquimentForm from './AssignEquipmentForm';
import { EquipmentsType } from '../../data/graphql/GetEquipmentsList';

interface RouteParams {
  equipmentsIds: string;
}

const ClaimEquipmentAssignment: React.FC = () => {
  const { t } = useTranslation();
  const { userState, checkTokenExpiration } = useAuth()!;
  const { equipmentsIds } = useParams<RouteParams>();
  const regionId = userState.userAuthPayload?.regionId!;
  const [isLoadingClaimData, setIsLoadingClaimData] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [claimsList, setClaimsList] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClaimData, setSelectedClaimData] = useState(null);
  const [selectedLossType, setSelectedLossType] = useState<Option[]>([]);
  const [selectedProjectManager, setSelectedProjectManager] = useState<Option[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Option[]>([]);
  const [loadError, setLoadError] = useState('');
  const history = useHistory();
  const [geolocation, setGeolocation] = useState<any>(null);

  const filterObject = Object.keys(filter).length === 0 ? {} : filter;
  const location = useLocation();
  const equipment = location.state as EquipmentsType;
  const filterModal = useRef<HTMLIonModalElement>(null);

  const variablesClaims: Variables = {
    regionId,
    filterArray: filterObject,
    term: searchTerm,
    latitude: geolocation?.latitude || null,
    longitude: geolocation?.longitude || null,
    notCompleted: !searchTerm ? true : undefined,
    hasPhase: true,
  };

  const styles: any = {
    ionLabel: {
      paddingLeft: 5,
      marginBottom: 5,
      marginTop: 5,
    },
    divFilter: {
      marginBottom: 15,
      marginTop: 10,
    },
  };

  useEffect(() => {
    if (!geolocation) {
      const getGeolocation = async () => {
        try {
          const coordinates = await Geolocation.getCurrentPosition();
          if (coordinates?.coords?.latitude && coordinates?.coords?.longitude) {
            setGeolocation(coordinates?.coords);
          }
        } finally {
          // user didn't allow geoLocation
        }
      };
      getGeolocation();
    }
  }, [geolocation]);

  const isFetchingData = (): boolean => isLoadingClaimData || isFetching || isRefetching;
  
  const storage = useStorage();

  const lossTypes = useLossTypes(regionId, userState, storage);

  const branches = useBranches(regionId, userState, storage);

  const projectManagers = useProjectManagers(regionId, userState, storage);

  const lostTypesOptions: Option[] = lossTypes.map((lossType: LossTypesType) => ({ label: lossType.lossCategory, value: lossType.lossCategory }));

  const projectManagersOptions: Option[] = projectManagers.map((pm: ProjectManagersType) => ({ label: pm.value, value: pm.id }));

  const branchesOptions: Option[] = branches.map((branch: BranchesType) => ({ label: branch.branchName, value: branch.branchCode }));

  const onSuccessGetClaims = async (data: any) => {
    setClaimsList(data?.claims || []);
  };

  const onErrorGetClaims = async (ex: any) => {
    if (ex.response.ErrorCode === 400) {
      setLoadError(t('errorXSS'));
    }
  };

  const { isFetching, isRefetching, refetch } = useQuery({
    queryKey: ['', variablesClaims, getRefetchKey()],
    queryFn: (ctx: QueryFunctionContext) => getGraphQLClient(userState).request(GET_CLAIMS_LIST, ctx.queryKey[1]),
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: false,
    onSuccess: onSuccessGetClaims,
    onError: onErrorGetClaims,
  });

  // refetch when component change
  useEffect(() => {
    const asyncRefetch = async () => {
      await checkTokenExpiration();
      await refetch();
    };
    asyncRefetch();
  }, [refetch, checkTokenExpiration]);

  const handleClaimSelection = (claim: ClaimType) => {
    (async () => {
      const claimData = await getClaimData(Number(claim.claimIndx));
      if (claimData) {
        setSelectedClaimData(claimData);
        setIsModalOpen(true);
      } else {
        setLoadError(t('tryAgain'));
      }
    })();
  };

  const handleLossTypeChange = (selectedOptions: Option[]) => {
    setSelectedLossType(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.lossType = selectedOptions?.map(o => o.value);
    } else {
      newFilter.lossType = undefined;
    }
    setFilter(newFilter);
  };

  const handleProjectManagerChange = (selectedOptions: Option[]) => {
    setSelectedProjectManager(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.pmidClaim = selectedOptions?.map(o => o.value);
    } else {
      newFilter.pmidClaim = undefined;
    }
    setFilter(newFilter);
  };

  const handleBranchChange = (selectedOptions: Option[]) => {
    setSelectedBranch(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.branchCode = selectedOptions?.map(o => o.value);
    } else {
      newFilter.branchCode = undefined;
    }
    setFilter(newFilter);
  };

  const dismissModal = () => filterModal.current?.dismiss();
  const initFilter = {};

  const clearFilter = () => {
    setSelectedLossType([]);
    setSelectedBranch([]);
    setSelectedProjectManager([]);

    onFilterChange(initFilter);
    dismissModal();
  };

  const showResults = () => {
    onFilterChange(filter);

    dismissModal();
  };

  const getClaimData = async (claimIndx: number) => {
    try {
      setIsLoadingClaimData(true);
      const data = await getGraphQLClient(userState).request(GET_CLAIM_DETAILS, { regionId, claimIndx });
      setIsLoadingClaimData(false);
      return data.claim;
    } catch (error) {
      setIsLoadingClaimData(false);
      return null;
    }
  };

  const onFilterChange = (newFilter: any) => {
    setFilter(newFilter);
  };

  const onSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  const handleOnAssignSuccess = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      const isBatchScan = equipmentsIds?.length > 0;
      if (isBatchScan) {
        setTimeout(() => { history.push('/tabs/equipments'); }, 1000);
      } else {
        history.push('/tabs/equipments');
      }
    }, 1000);
  };

  const handleEquipmentIds = (): number[] => {
    if (equipmentsIds !== undefined) {
      return decodeURIComponent(equipmentsIds).split(',').map(Number);
    }
    return [];
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{t('addToClaim').toString()}</IonTitle>
        </IonToolbar>
        <IonToolbar className='assignment-custom-ion-toolbar ion-padding-top'>
          <div className="equipment-toolbar">
            <SearchInput id="search-claims-bar" debounce={1000} onSearch={onSearchChange} />
            <FosButtonIcon id='equipmentClaimsFilterModal' icon={filterOutline} />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-no-padding'>
        <IonList lines='none'>
          {claimsList.length === 0 && !isFetching ?
            <p className="ion-text-center">{t('noRecord').toString()}</p> :
            claimsList.map((claim, index) => (
              <div key={index} className='ion-padding-horizontal'>
                <FosCard
                  status={claim.status}
                  date={handleDateFormat(claim.lossDate, DATE_FORMAT_MM_DD_YYYY)}
                  title={`${claim.claimNumber} (${claim.lossType})`}
                  location={`${claim.projAddr}, ${claim.projCity}, ${claim.projProv}`}
                  showButtons={false}
                  contacts={[{ label: 'Contact', value: claim.pmnameClaim }]}
                  onClick={() => handleClaimSelection(claim)}
                  hasMinHeight={false} />
              </div>
            ))}
        </IonList>
      </IonContent>
      <FilterModal ref={filterModal} trigger="equipmentClaimsFilterModal" onClear={clearFilter} onClose={() => filterModal.current?.dismiss()} onShowResults={showResults}>
        <>
          {lostTypesOptions.length > 0 &&
            <div style={styles.divFilter}>
              <div style={styles.ionLabel}>
                <IonLabel>
                  {t('lossType').toString()}
                </IonLabel>
              </div>
              <MultiSelect
                options={lostTypesOptions}
                value={selectedLossType}
                onChange={handleLossTypeChange}
                labelledBy="Select"
                overrideStrings={OVERRIDE_STRINGS(t)}
              />
            </div>
          }
          {projectManagersOptions.length > 0 &&
            <div style={styles.divFilter}>
              <div style={styles.ionLabel}>
                <IonLabel>
                  {t('projectManager').toString()}
                </IonLabel>
              </div>
              <MultiSelect
                options={projectManagersOptions}
                value={selectedProjectManager}
                onChange={handleProjectManagerChange}
                labelledBy="Select"
                overrideStrings={OVERRIDE_STRINGS(t)}
              />
            </div>
          }
          {branchesOptions.length > 0 &&
            <div style={styles.divFilter}>
              <div style={styles.ionLabel}>
                <IonLabel>
                  {t('profileInfoHomeBranch').toString()}
                </IonLabel>
              </div>
              <MultiSelect
                options={branchesOptions}
                value={selectedBranch}
                onChange={handleBranchChange}
                labelledBy="Select"
                overrideStrings={OVERRIDE_STRINGS(t)}
              />
            </div>
          }
        </>
      </FilterModal>
      <IonModal isOpen={isModalOpen} class="custom-modal">
        <FosModalHeader title='Assign to Claim' onCloseClick={() => setIsModalOpen(false)} />
        <AssignEquimentForm
          equipment={equipment}
          claim={selectedClaimData}
          equipmentsIds={handleEquipmentIds()}
          onAssignSuccess={handleOnAssignSuccess} />
      </IonModal>
      <IonLoading isOpen={isFetchingData()} message={t('loading')} duration={2000} />
      <IonToast
        isOpen={!!loadError}
        message={loadError}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => { setLoadError(''); }} />
    </IonPage>
  );
};

export default ClaimEquipmentAssignment;
