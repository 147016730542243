import { gql } from '@apollo/client';

const APPROVE_EXPENSE = gql`
mutation ApproveExpense($userGuid: UUID!, $expenseId: Int!) {
  approveExpense(userGuid: $userGuid, expenseId: $expenseId) {
    status
    itemId
  }
}`;


export default APPROVE_EXPENSE;
