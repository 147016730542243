import { IonButton } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FosSelectItem, { SelectOptionProps } from './FosSelectItem';
import './StackedInput.css';
import './StackedLargeInput.css';

export interface Props {
  label?: string;
  options: SelectOptionProps[];
  name: string;
  control: any;
  onAddClick: (selectedOption: string) => any;
}

const StackedLargeInput = ({
  label,
  options,
  name,
  control,
  onAddClick,
} : Props) => {

  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState('');

  const handleClick = () => {
    onAddClick(selectedOption);
  };

  const handleSelection = (option: string) => {
    setSelectedOption(option);
  };

  return (
    <div className='stacked-large-input ion-margin-bottom ion-margin-top stacked-large-input-border'>
      {options.length > 0 &&
        <>
          <span>
            <IonButton fill='outline' className='stacked-large-input-button-float-right' onClick={() => handleClick()}>{t('add').toString()}</IonButton>
          </span><div className='stacked-large-input-select'>
            <FosSelectItem
              label={label!}
              options={options}
              onChange={(event) => handleSelection(event.detail.value)}
            />
          </div>
        </>
      }
      <Controller
        control={control}
        name={name}
        rules={{
          required: true,
        }}
        render={({ field: { value, onChange, ref } }) => (
          <ReactQuill
            ref={ref}
            theme="snow"
            placeholder={t('addDetails')}
            onChange={(description) => onChange(description)}
            value={value || ''}
          />
        )}
      />
    </div>
  );
};

export default StackedLargeInput;
