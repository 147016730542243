import { PickerColumnOption, useIonPicker } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export interface ImperialMeasurePickerArgs {
  handler: (value: any) => any;
  currentFeets?: number;
  currentInches?: number;
}

const useImperialMeasurePicker = () => {
  const [presentIonPicker] = useIonPicker();
  const { t } = useTranslation();
  const presentImperialMeasurePicker = ({ handler, currentFeets, currentInches }: ImperialMeasurePickerArgs) => {
    const ftOptions: PickerColumnOption[] = new Array(101)
      .fill(undefined)
      .map((v, idx) => ({ text: `${idx}`, value: idx }));
    const inOptions: PickerColumnOption[] = new Array(12)
      .fill(undefined)
      .map((v, idx) => ({ text: `${idx}`, value: idx }));
    presentIonPicker({
      columns: [
        {
          name: 'feets',
          suffix: 'ft',
          options: ftOptions,
          selectedIndex: currentFeets || 0,
        },
        {
          name: 'inches',
          suffix: 'in',
          options: inOptions,
          selectedIndex: currentInches || 0,
        },
      ],
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
        },
        {
          text: t('confirm'),
          handler,
        },
      ],
    });
  };

  return [presentImperialMeasurePicker];
};

export default useImperialMeasurePicker;
