import { gql } from '@apollo/client';

const UPDATE_CT_PHOTOS_POSITIONS = gql`
mutation UpdateCtPhotosPositions($claimIndx: Int!, $ctPhotoPositions: [UpdateCtPhotosPositionsInput]) {
  updateCtPhotosPositions(claimIndx: $claimIndx, ctPhotoPositions: $ctPhotoPositions)
  {
    status
    itemId
  }
}`;

export default UPDATE_CT_PHOTOS_POSITIONS;
