import { Storage } from '@ionic/storage';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseCacheAndList from './baseCacheAndList';
import GET_INSURANCE_COMPANIES_LIST from '../graphql/GetInsuranceCompaniesList';
import { InsuranceCompaniesType } from '../pages/helper/InsuranceCompaniesType';

const useInsuranceCompanies = (region: number, userState: UserState, storage: Storage) => {
  const adjustersList:InsuranceCompaniesType[] = useBaseCacheAndList(region, userState, storage, GET_INSURANCE_COMPANIES_LIST, claimsKeys.insuranceCompaniesList(), 'insuranceCompanies');
  return adjustersList;
};

export default useInsuranceCompanies;