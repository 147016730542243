import { useQuery } from '@tanstack/react-query';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import { useAuth } from './authContext';
import GET_JOB_DEPARTMENTS from '../graphql/ExpJobDepartments';

export interface JobDepartment {
  id: string
  code: string
  parentId: string
  value: string
}

const useExpenseJobDepartments = (projectNumber: string | undefined) => {
  const { userState } = useAuth()!;
  const regionId = userState.userAuthPayload?.regionId!;
  const graphQLClient = getGraphQLClient(userState, DomainParams.Common);
  const { isLoading, data } = useQuery({
    queryKey: ['expJobDepartments', projectNumber],
    queryFn: () =>
      graphQLClient.request(GET_JOB_DEPARTMENTS, { regionId, projectNumber }),
  });
  return { isLoading, data: (data?.jobDepartments || []) as JobDepartment[] };
};

export default useExpenseJobDepartments;