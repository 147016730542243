import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { useAuth } from '../../../hooks/authContext';
import getGraphQLClient from '../../../hooks/graphQLClientUtil';
import SaveStorage, { SaveStorageReturn, SavedRecords, StorageRecord } from '../../../utils/SaveStorage';
import { ApiStatus, ClaimOnlineStatus } from '../Const';
import { deepClone } from '../../../utils/Utilities';
import SAVE_SAFETY_INFO from '../../../graphql/SaveSafetyInfo';
import { getErrorMsg } from '../AppHelper';

type StoreClaimSafety = {
  info: any;
  claimIndx: string;
  status: ClaimOnlineStatus;
};

// used to save new claim or save the edit of existent claim
const saveClaimSafety = async ({
  info,
  claimIndx,
  status,
} : StoreClaimSafety) => {
  const saveStorage = SaveStorage();
  await saveStorage.setItem(SavedRecords.ClaimsSafety, { info, status }, Number(claimIndx));
};

export const storeClaimSafety = async ({
  info,
  claimIndx,
  status,
} : StoreClaimSafety) => {
  if (!info) {
    return;
  }
  await saveClaimSafety({ info, claimIndx, status });
};

export const getAllClaimSafety = async () => {
  const saveStorage = SaveStorage();
  const claimSafetyList = await saveStorage.getItems(SavedRecords.ClaimsSafety);
  return claimSafetyList;
};

export const getClaimSafety = async (
  claimIndx: number,
) => {
  const claimSafetyList = await getAllClaimSafety();
  const claimSafety = claimSafetyList.find(cSI => cSI.primaryKey === claimIndx);
  if (!claimSafety?.data?.info) {
    return { info: undefined, status: undefined };
  }
  const info = claimSafety?.data?.info;
  const status = claimSafety?.data?.status;
  return { info, status };
};

export const useSaveSafetyMutation = () => { 
  const { userState } = useAuth()!;
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (info: any) => graphQLClient.request(SAVE_SAFETY_INFO, { info }),
  });
  return { isLoading, mutateAsync };
};


export const syncClaimSafety = async (
  claimSafety: StorageRecord,
  saveStorage: SaveStorageReturn,
  mutateSaveClaimSafety: any,
) => {
  try {
    const cloneClaimSafety = deepClone(claimSafety);
    const res = await mutateSaveClaimSafety(cloneClaimSafety.data.info);
    
    if (res?.saveInspectionSafety?.status === ApiStatus.SUCCESS) {
      claimSafety.data.status = ClaimOnlineStatus.AvailableOffline;
      await saveStorage.setItem(SavedRecords.ClaimsSafety, claimSafety.data, claimSafety.primaryKey);
    }
  } catch (e: any) {
    const msg = getErrorMsg(e);
    toast.error(msg || t('saveClaimError'), { duration: 4000 });
  }
};

export const getAndSyncClaimSafety = async (
  saveStorage: SaveStorageReturn,
  mutateSaveClaimSafety: any,
) => {
  const claimSafetyList = await getAllClaimSafety();
  const filteredSafetyList = claimSafetyList?.filter(cSI => cSI.data.status === ClaimOnlineStatus.ToBeSynched);
  if (filteredSafetyList?.length > 0) {
    filteredSafetyList.forEach(async (claimSafety) => {
      await syncClaimSafety(claimSafety, saveStorage, mutateSaveClaimSafety);
    });
  }
};