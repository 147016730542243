import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';
import DELETE_ROOM from '../graphql/DeleteRoom';

const useDeleteRoom = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (id: number) => graphQLClient.request(DELETE_ROOM, { id }),
  });

  return { isLoading, mutateAsync };
};
  
export default useDeleteRoom;
