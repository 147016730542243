import { Drivers, Storage } from '@ionic/storage';
import { useMemo } from 'react';

// initialize storage
const useStorage = () => {
  const storage = useMemo(() => new Storage({
    name: '__mydb',
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
  }), []);
  storage.create();
  return storage;
};
export default useStorage;
