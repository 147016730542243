/* eslint-disable react/no-array-index-key */
import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonLabel, isPlatform, useIonRouter } from '@ionic/react';
import { MultiSelect } from 'react-multi-select-component';
import JobCard from '../molecule/JobCard';
import { LossTypesType } from '../pages/helper/LossTypesType';
import { ClaimOnlineStatus, OVERRIDE_STRINGS } from '../pages/helper/Const';
import { Option } from '../utils/Interfaces';
import { ProjectManagersType } from '../pages/helper/ProjectManagersType';
import { BranchesType } from '../pages/helper/BranchesType';
import FilterModal from './FilterModal';
import { PropertyManagersType } from '../pages/helper/PropertyManagersType';
import { formatOfflineClaim } from '../pages/helper/ClaimHelper';
import { AdjustersType } from '../pages/helper/AdjustersType';
import { InsuranceCompaniesType } from '../pages/helper/InsuranceCompaniesType';

type StyleType = {
  container: React.CSSProperties;
  jobList: React.CSSProperties;
  ionLabel: React.CSSProperties;
  divFilter: React.CSSProperties;
  tabletJobList: React.CSSProperties;
};

const styles: StyleType = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  jobList: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginBottom: 20,
  },
  tabletJobList: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginInlineStart: '40px',
    marginInlineEnd: '40px',
  },
  ionLabel: {
    paddingLeft: 5,
    marginBottom: 5,
    marginTop: 5,
  },
  divFilter: {
    marginBottom: 15,
    marginTop: 10,
  },
};

type Props = {
  claims: any;
  offlineClaims: any;
  error: any;
  lossTypesFilter: LossTypesType[];
  projectManagersFilter: ProjectManagersType[];
  branchesFilter: BranchesType[];
  propertyManagers: PropertyManagersType[];
  adjusters: AdjustersType[];
  insurers: InsuranceCompaniesType[];
  onFilterChange: Function;
  onDownloadClaimClick: Function;
  downloadingJobIdx?: number;
};

const JobsComponent: FC<Props> = ({
  claims,
  offlineClaims,
  error,
  lossTypesFilter,
  projectManagersFilter,
  branchesFilter,
  propertyManagers,
  adjusters,
  insurers,
  onFilterChange,
  onDownloadClaimClick,
  downloadingJobIdx,
}) => {
  const navigation = useIonRouter();
  const filterModal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation();
  const initFilter = {};
  const [filter, setFilter] = useState<any>(initFilter);
  const isTablet = isPlatform('tablet');
  const [selectedLossType, setSelectedLossType] = useState<Option[]>([]);
  const [selectedProjectManager, setSelectedProjectManater] = useState<Option[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Option[]>([]);

  const handleLossTypeChange = (selectedOptions: Option[]) => {
    setSelectedLossType(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.lossType = selectedOptions?.map(o => o.value);
    } else {
      newFilter.lossType = undefined;
    }
    setFilter(newFilter);
  };

  const handleProjectManagerChange = (selectedOptions: Option[]) => {
    setSelectedProjectManater(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.pmidClaim = selectedOptions?.map(o => o.value);
    } else {
      newFilter.pmidClaim = undefined;
    }
    setFilter(newFilter);
  };

  const handleBranchChange = (selectedOptions: Option[]) => {
    setSelectedBranch(selectedOptions);
    const newFilter = { ...filter };
    if (selectedOptions?.length > 0) {
      newFilter.branchCode = selectedOptions?.map(o => o.value);
    } else {
      newFilter.branchCode = undefined;
    }
    setFilter(newFilter);
  };

  const lostTypesOptions: Option[] = lossTypesFilter.map((lossType: LossTypesType) => ({ label: lossType.lossCategory, value: lossType.lossCategory }));

  const projectManagersOptions: Option[] = projectManagersFilter.map((pm: ProjectManagersType) => ({ label: pm.value, value: pm.id }));

  const branchesOptions: Option[] = branchesFilter.map((branch: BranchesType) => ({ label: branch.branchName, value: branch.branchCode }));

  const adjusterOptions = adjusters.map(adjuster => ({ label: `${adjuster.firstName} ${adjuster.lastName}`, value: adjuster.adjCode }));

  const insuranceCompaniesOptions = insurers.map(insuranceCompanie => ({ label: insuranceCompanie.company, value: insuranceCompanie.coCode }));

  propertyManagers.map(manager => ({ label: `${manager.firstName} ${manager.lastName}`, value: manager.adjCode }));

  const clearFilter = () => {
    setSelectedLossType([]);
    setSelectedBranch([]);
    setSelectedProjectManater([]);
    setFilter(initFilter);
    onFilterChange(initFilter);
    filterModal.current?.dismiss();
  };
  const showResults = () => {
    onFilterChange(filter);
    filterModal.current?.dismiss();
  };

  const handleJobClick = (jobIdx: number, isOffline?: Boolean) => {
    navigation.push(`/jobs/${jobIdx}${isOffline ? '/true' : ''}`, 'forward', 'push');
  };

  const handleDownloadClick = (jobIdx: number) => {
    onDownloadClaimClick(jobIdx);
  };

  const formatOfflineClaimLocal = (offlineClaim: any) => formatOfflineClaim(offlineClaim, projectManagersOptions, adjusterOptions, insuranceCompaniesOptions);

  const handleStatus = (status: string): string => {
    switch (status) {
      case ClaimOnlineStatus.AvailableOffline: return t('availableOffline');
      case ClaimOnlineStatus.ToBeSynched: return t('toBeSynched');
      case ClaimOnlineStatus.Synched: return t('synched');
      default: return t('synched');
    }
  };

  return (
    <div style={styles.container}>
      <FilterModal ref={filterModal} trigger="filterModal" onClear={clearFilter} onClose={() => filterModal.current?.dismiss()} onShowResults={showResults}>
        <>
          {lostTypesOptions.length > 0 &&
            <div style={styles.divFilter}>
              <div style={styles.ionLabel}>
                <IonLabel>
                  {t('lossType').toString()}
                </IonLabel>
              </div>
              <MultiSelect
                options={lostTypesOptions}
                value={selectedLossType}
                onChange={handleLossTypeChange}
                labelledBy="Select"
                overrideStrings={OVERRIDE_STRINGS(t)}
              />
            </div>
          }
          {projectManagersOptions.length > 0 &&
            <div style={styles.divFilter}>
              <div style={styles.ionLabel}>
                <IonLabel>
                  {t('projectManager').toString()}
                </IonLabel>
              </div>
              <MultiSelect
                options={projectManagersOptions}
                value={selectedProjectManager}
                onChange={handleProjectManagerChange}
                labelledBy="Select"
                overrideStrings={OVERRIDE_STRINGS(t)}
              />
            </div>
          }
          {branchesOptions.length > 0 &&
            <div style={styles.divFilter}>
              <div style={styles.ionLabel}>
                <IonLabel>
                  {t('profileInfoHomeBranch').toString()}
                </IonLabel>
              </div>
              <MultiSelect
                options={branchesOptions}
                value={selectedBranch}
                onChange={handleBranchChange}
                labelledBy="Select"
                overrideStrings={OVERRIDE_STRINGS(t)}
              />
            </div>
          }
        </>
      </FilterModal>
      <div style={isTablet ? styles.tabletJobList : styles.jobList}>
        {
          offlineClaims &&
          offlineClaims.length > 0 &&
          offlineClaims.map((offlineClaim: any, index: number) => {
            const claim = formatOfflineClaimLocal(offlineClaim);
            return (
              <JobCard
                key={offlineClaim.primaryKey + index}
                claimNumber={claim.claimNumber}
                dateOpened={new Date(claim.lossDate)}
                lossType={claim.lossType}
                projAddr={claim.projAddr}
                projCity={claim.projCity}
                projProv={claim.projProv}
                status={handleStatus(claim.status)}
                projectManager={claim.pmnameClaim}
                propertyManager={claim.propertyManager}
                businessContact={claim.contactName}
                projectName={claim.projName}
                claimIndx={claim.claimIndx}
                projectPhone1={claim.projPh1 || claim.projPhone1}
                projectPhone2={claim.projPh2 || claim.projPhone2}
                projectPhone3={claim.projPh3 || claim.projPhone3}
                businessContactPhone={claim.contactPhone}
                lossAddressLat={claim.lossAddressLat}
                lossAddressLon={claim.lossAddressLon}
                downloadingJobIdx={downloadingJobIdx}
                onClick={() => handleJobClick(offlineClaim.primaryKey, true)}
                onDownloadClick={() => handleDownloadClick(offlineClaim.primaryKey)}
              />
            );
          })}
        {
          claims &&
          claims.length > 0 &&
          claims.map((claim: any, index: number) => (
            <JobCard
              key={claim.claimNumber + index}
              claimNumber={claim.claimNumber}
              dateOpened={new Date(claim.lossDate)}
              lossType={claim.lossType}
              projAddr={claim.projAddr}
              projCity={claim.projCity}
              projProv={claim.projProv}
              status={t('synched')}
              projectManager={claim.pmnameClaim}
              propertyManager={claim.adjuster}
              businessContact={claim.contactName}
              projectName={claim.projName}
              claimIndx={claim.claimIndx}
              projectPhone1={claim.projPh1 || claim.projPhone1}
              projectPhone2={claim.projPh2 || claim.projPhone2}
              projectPhone3={claim.projPh3 || claim.projPhone3}
              businessContactPhone={claim.contactPhone || claim.projPh1 || claim.projPhone1}
              lossAddressLat={claim.lossAddressLat}
              lossAddressLon={claim.lossAddressLon}
              downloadingJobIdx={downloadingJobIdx}
              onClick={handleJobClick}
              onDownloadClick={() => handleDownloadClick(claim.claimIndx)}
            />
          ))}
        {error && <div className="ion-padding">Error</div>}
      </div>
    </div>
  );
};

export default JobsComponent;

