import { t } from 'i18next';
import { LevelModelType } from '../graphql/GetLevelsList';

export const getRoomName = (name: string): string => `Room ${name}`;

/**
 * Returns the updated list of levels with the changed name of the specified level.
 * */
export const provideLevelNameChange = (levelsList: LevelModelType[], newLevelName: string, index: number) => {
  const levelsCopy = [...levelsList];
  levelsCopy[index].levelName = newLevelName;
  return levelsCopy;
};


/**
 * Returns the updated list of levels with the changed name of the specified room.
 * */
export const provideRoomNameChange = (levelsList: LevelModelType[], newRoomName: string, roomPos: number, levelPos: number) => {
  const levelsCopy = [...levelsList];
  levelsCopy[levelPos].rooms[roomPos] = {
    roomName: newRoomName,
    levelId: levelsCopy[levelPos].levelId,
    roomId: levelsCopy[levelPos].rooms[roomPos].roomId,
  };
  return levelsCopy;
};

/**
 * Returns the list of levels with the new level added.
 * */
export const provideAddedLevel = (levelsList: LevelModelType[], level?: LevelModelType, pos?: number) => {
  const levelsCopy = [...levelsList];
  if (level !== undefined) {
    if (typeof pos === 'number') {
      levelsCopy.splice(pos, 1, level);
    } else {
      levelsCopy.push(level);
    }
  } else {
    const newLevel: LevelModelType = {
      levelName: `${t('level')} ${levelsList.length + 1}`,
      rooms: [{ roomName: `${t('room')} 1` }],
    };
    levelsCopy.push(newLevel);
  }
  return levelsCopy;
};

/**
 * Returns the level with a new room added.
 * */
export const provideAddedRoom = (levelsList: LevelModelType[], levelPos: number, roomName: string) => {
  const level = levelsList[levelPos];
  const levelCopy: LevelModelType = {
    ...level,
    rooms: [...level.rooms, { roomName, levelId: level.levelId }],
  };
  return levelCopy;
};
