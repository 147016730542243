/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
import { EventEmitter } from 'events';
import { Network } from '@capacitor/network';

export enum ProcessQueueEvent {
  QueueInitialized = 'queueInitialized',
  AddItem = 'addItem',
  EditItem = 'editItem',
  ItemProcessFinished = 'itemProcessFinished',
  AllItemsProcessFinished = 'allItemsProcessFinished',
}

interface ProcessItem {
  processed?: boolean;
  tempId?: number;
  countFail: number;
}

const MAX_RETRY = 3;

type PreProcessFn<T> = (item: T) => Promise<T>;
type ProcessFn<T> = (item: T) => Promise<void>;

export class ProcessQueue<T extends ProcessItem> extends EventEmitter {
  initialized = false;

  queue: T[] = [];

  syncStarted: boolean = false;

  preProcessFn?: PreProcessFn<T>;

  processFn?: ProcessFn<T>;

  setProcessFn(fn: ProcessFn<T>) {
    this.processFn = fn;
  }

  setPreProcessFn(fn: PreProcessFn<T>) {
    this.preProcessFn = fn;
  }

  setQueue(queue: T[]) {
    this.queue = [];
    for (const item of queue) {
      this.add(item);
    }
  }

  setInitialized(initialized: boolean) {
    this.initialized = initialized;
    if (initialized)
      this.emit(ProcessQueueEvent.QueueInitialized);
  }

  countTotal() {
    return this.queue.length;
  }

  countSynced() {
    return this.queue.filter(item => item.processed).length;
  }

  countFailSynced() {
    return this.queue.filter(item => !item.processed && item.countFail >= MAX_RETRY).length;
  }

  countPending() {
    return this.countTotal() - this.countSynced();
  }

  add(item: T) {
    this.queue.unshift(item);
    this.emit(ProcessQueueEvent.AddItem, item);
  }

  edit(item: T) {
    const index = this.queue.findIndex((q: T) => q.tempId === item.tempId);
    this.queue.splice(index, 1, item);
    this.emit(ProcessQueueEvent.EditItem, item);
  }

  async sync() {
    if (this.syncStarted || this.countPending() === 0 || !this.initialized) return;
    this.syncStarted = true;
    await this.syncNext();
  }

  private getNext() {
    return this.queue.find(p => !p.processed && p.countFail < MAX_RETRY);
  }

  private async syncNext() {
    const item = this.getNext();
    if (!item) {
      this.syncStarted = false;
      this.queue = [];
      this.emit(ProcessQueueEvent.AllItemsProcessFinished);
      return;
    }
 
    try {
      const networkStatus = await Network.getStatus();
      if (!networkStatus.connected) {
        this.syncStarted = false;
        return;
      }

      if (!this.processFn) throw new Error('Process function undefined');
      let preProcessedItem = item;
      if (this.preProcessFn) {
        preProcessedItem = await this.preProcessFn(item);
      }
      await this.processFn(preProcessedItem);
      item.processed = true;
      this.emit(ProcessQueueEvent.ItemProcessFinished, item);
    } catch (ex: any) {
      console.log(ex);
      if (ex.code === 'ERR_NETWORK') {
        this.syncStarted = false;
        return;
      }
      item.countFail++;
      this.emit(ProcessQueueEvent.ItemProcessFinished, item);
    }
    await this.syncNext();
  }
}
