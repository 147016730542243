import React, { useState } from 'react';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonRadio,
  IonSelectOption,
  useIonActionSheet,
} from '@ionic/react';
import './FosRadioButton.css';
import { useTranslation } from 'react-i18next';
import RenameModal from './RenameModal';
import { ReactComponent as MoreIcon } from '../assets/icons/ic_more.svg';

export type SelectOptionProps = React.ComponentProps<typeof IonSelectOption> & { label: string };

export interface Props {
  label: string;
  modalTitle: string;
  value: string;
  onChangeRoomName: (roomName: string) => any;
  onRoomDelete: () => any;
  onChange?: (event: any) => any;
}

const FosRadioButton: React.FC<Props> = (({
  modalTitle,
  label,
  value,
  onChangeRoomName,
  onChange,
  onRoomDelete,
}) => {

  const [present] = useIonActionSheet();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDuplicateRoomEnabled = process.env.REACT_APP_ENV !== 'prod';
  const isDeleteRoomEnabled = true;

  const cancel = () => {
    setIsModalOpen(false);
  };

  const changeRoomName = (newName: string) => {
    if (newName !== '') {
      onChangeRoomName(newName);
      setIsModalOpen(false);
    }
  };

  enum RoomAction {
    RENAME = 'Rename Room',
    DUPLICATE = 'Duplicate Room',
    DELETE = 'Delete Room',
    CANCEL = 'Cancel',
  }

  const roomActionSheetButtons = [
    {
      text: t('renameRoom'),
      data: {
        action: RoomAction.RENAME,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: RoomAction.CANCEL,
      },
    },
  ];
  if (isDuplicateRoomEnabled) {
    const duplicateRoomButton = {
      text: t('duplicateRoom'),
      data: {
        action: RoomAction.DUPLICATE,
      },
    };
    roomActionSheetButtons.splice(1, 0, duplicateRoomButton);
  }

  if (isDeleteRoomEnabled) {
    const deleteRoomButton = {
      text: t('deleteRoom'),
      role: 'destructive',
      data: {
        action: RoomAction.DELETE,
      },
    };
    roomActionSheetButtons.splice(2, 0, deleteRoomButton);
  }

  const handleClick = () => {
    present({
      header: label,
      cssClass: 'custom-action-sheet',
      buttons: roomActionSheetButtons,
      onDidDismiss: ({ detail }) => {
        switch (detail.data?.action) {
          case RoomAction.RENAME: {
            setIsModalOpen(true);
            break;
          }
          case RoomAction.DUPLICATE: {
            // todo: add functionality
            break;
          }
          case RoomAction.DELETE: {
            onRoomDelete();
            break;
          }
          case RoomAction.CANCEL:
          default: break;
        }
      },
    });
  };

  return (
    <IonItem>
      <IonItem className='radio-label'>
        <IonLabel>{label}</IonLabel>
        <IonRadio slot="start" onClick={onChange} value={value} />
      </IonItem>
      <IonButton fill='clear' className='media-inspection-more-button' onClick={handleClick}>
        <MoreIcon fill="none" className="more-icon" />
      </IonButton>
      <RenameModal modalTitle={modalTitle} currentName={label} isModalOpen={isModalOpen} onCancel={cancel} onNameChanged={(newName) => changeRoomName(newName)}/>
    </IonItem>
  );
});

export default FosRadioButton;
