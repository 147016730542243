import { gql } from '@apollo/client';

const UPDATE_CLAIM = gql`
mutation UpdateClaim($regionId: Int!, $claim: UpdateClaimRequestInput!) {
  updateClaim(regionId: $regionId, claim: $claim)
  {
    itemId
    status
    message
  }
}`;

export default UPDATE_CLAIM;
