import { Storage } from '@ionic/storage';
import { UserState } from './authUtils';
import useBaseCacheAndList from './baseCacheAndList';
import GET_NOTES_DEPARTMENTS_LIST, { NotesDepartmentsType } from '../graphql/GetNotesDepartmentsList';
import { claimsKeys } from './claims';

const useClaimNotesDepartments = (region: number, userState: UserState, storage: Storage) => {
  const categoriesList: NotesDepartmentsType[] = useBaseCacheAndList(region, userState, storage, GET_NOTES_DEPARTMENTS_LIST, claimsKeys.claimNotesDepartments(), 'notesDepartments');
  return categoriesList;
  
};

export default useClaimNotesDepartments;