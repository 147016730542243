import React, { useCallback, useContext, useEffect } from 'react';
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { cloudOffline, close, wifi, cellular } from 'ionicons/icons';
import { Toaster, toast } from 'react-hot-toast';

import './ToolsModal.css';
import NetworkContext from '../contexts/NetworkContext';
import { useClaimOffline } from '../contexts/ClaimOfflineContext';
import FosTextArea from '../atom/FosTextArea';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import { useAuth } from '../hooks/authContext';
import openDocumentURL from '../pages/helper/Documents';
import { usePhotoUpload } from '../contexts/PhotoUploadContext';

const MODAL_INITIAL_BREAKPOINT = 1;
const VIDEO_HELP_URL = 'https://files.firstonsite.ca/mobiletrak/help/training';
const DOCUMENT_HELP_URL = 'https://files.firstonsite.ca/mobiletrak/help/userguide';

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
};

const ToolsModal: React.FC<Props> = ({ isOpen, onDismiss }) => {
  const { t } = useTranslation();

  const [unsyncedItemCount, setUnsyncedItemCount] = React.useState(0);
  const [status, setStatus] = React.useState(false);
  const [connectionType, setConnectionType] = React.useState('wifi');
  const network = useContext(NetworkContext);
  const offlineClaimContext  = useClaimOffline()!;
  const [emailBody, setEmailBody] = React.useState('');
  const { user, userState } = useAuth()!;
  const restClient = getRestClient(userState, ClientType.PLAIN);
  const photoUploadContext = usePhotoUpload()!;

  const sendEmail = useCallback(async () => {

    try {
      const { status } = await restClient.post('/platform/email/send', {
        to: 'tom.murphy@firstonsite.ca',
        body: emailBody,
        subject: `${user?.username} - Feedback Form`,
      });

      if (status === 200) {
        setEmailBody('');
        toast.success(t('emailSent').toString(), { duration: 4000 });
      } else {
        toast.error(t('errorSendingEmail').toString(), { duration: 4000 });
      }

    } catch (e: any) {
      let msg: string;
      if (e?.response?.data?.ErrorCode === 400) {
        msg = t('errorXSS');
      } else {
        msg = e?.response?.data?.Description;
      }
      const message = msg || t('saveSiteInfoError');

      toast.error(message, { duration: 5000 });
    }

  }, [
    t,
    user?.username,
    emailBody,
    restClient,
  ]);

  useEffect(() => {
    setUnsyncedItemCount(offlineClaimContext.toBeSyncCount);
  }, [offlineClaimContext.toBeSyncCount]);

  useEffect(() => {
    setStatus(network.connected);
    setConnectionType(network.connectionType);
  }, [
    network.connected,
    network.connectionType,
  ]);

  return (
    <IonModal
      initialBreakpoint={MODAL_INITIAL_BREAKPOINT}
      breakpoints={[0, 0.25, 0.5, 0.75, MODAL_INITIAL_BREAKPOINT]}
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <IonHeader className="tools-header">
        <h1 className="tools-header-text">
          {t('tools').toString()}
        </h1>
        <div className="close-icon-container">
          <IonIcon className="tools-close-button" icon={close} onClick={onDismiss} />
        </div>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="tool-section">
          <h1 className="tools-header-text">
            {t('syncStatus').toString()}
          </h1>
          <div className="status-container">
            {unsyncedItemCount > 0 || photoUploadContext.failCount > 0 ?
              <>
                <span className="unsynced-status">{t('unsynched').toString()}</span>
                {unsyncedItemCount > 0 ? 
                  <span className="unsynced-count">{`${t('toBeSynched').toString()} (${unsyncedItemCount} ${t('claim').toString()})`}</span>
                  : 
                  <span className="unsynced-count">{`${t('failToSavePhoto').toString()} (${photoUploadContext.failCount} ${t('photos').toString()})`}</span>
                }
              </>
              :
              <div className="synced-status">{t('synched').toString()}</div>
            }
          </div>
        </div>
        <div className="tool-section">
          <h1 className="tools-header-text">
            {t('networkQuality').toString()}
          </h1>
          <div className="network-connection-types">
            <IonItem>
              <IonIcon className="tools-icon" icon={cellular} />
              <IonText>{t('cellular').toString()}</IonText>
              <IonCheckbox
                className='cellular-checkbox'
                slot="end"
                checked={connectionType === 'cellular'}
                disabled
              />
            </IonItem>
            <IonItem>
              <IonIcon className="tools-icon" icon={wifi} />
              <IonText>{t('wifi').toString()}</IonText>
              <IonCheckbox
                className='cellular-checkbox'
                slot="end"
                checked={connectionType === 'wifi'}
                disabled
              />
            </IonItem>
            <IonItem>
              <IonIcon className="tools-icon" icon={cloudOffline} />
              <IonText>{t('offlineModeStatus').toString()}</IonText>
              <IonCheckbox
                className='cellular-checkbox'
                slot="end"
                checked={!status}
                disabled
              />
            </IonItem>
          </div>
          <div className="help-section">
            <h1 className="tools-header-text">
              {t('help').toString()}
            </h1>
            <IonButton className="help-button" expand='block' fill='outline' onClick={() => openDocumentURL(VIDEO_HELP_URL)}>{t('trainingVideo').toString()}</IonButton>
            <IonButton className="help-button" expand='block' fill='outline' onClick={() => openDocumentURL(DOCUMENT_HELP_URL)}>{t('userGuide').toString()}</IonButton>
            <FosTextArea
              value={emailBody}
              placeholder={t('provideFeedback')}
              rows={10}
              onIonChange={(event: any) => setEmailBody(event.target.value)}
            />
            <IonButton expand='block' fill='outline' onClick={sendEmail}>{t('submitFeedback').toString()}</IonButton>
          </div>
        </div>
      </IonContent>
      <Toaster
        containerStyle={{
          top: 50,
          left: 20,
          bottom: 20,
          right: 20,
        }} />
    </IonModal>
  );
};

export default ToolsModal;