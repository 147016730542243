/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC, useState, useEffect } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import toast, { Toaster } from 'react-hot-toast';
import { closeOutline } from 'ionicons/icons';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import TBD from '../molecule/TBD';
import { InspectionActiveOption, InspectionMenuTabs } from './helper/InspectionMenuHelper';
import InspectionMenu from '../molecule/InspectionMenu';
import SiteInspectionInfo from './SiteInspectionInfo';
import './Inspection.css';
import AreaInspection from './AreaInspection';
import Safety from './Safety';
import FosFooterButton from '../atom/FosFooterButton';
import PreviewModal, { ReportType } from '../atom/PreviewModal';
import useInspectionViewModel from './InspectionViewModel';
import { ReorderSiteInspectionImagesModal } from '../organism/ReorderSiteInspectionImagesModal';

type Props = { };

const Inspection: FC<Props> = () => {
  const {
    navigation,
    claimNumber,
    handleLossType,
    inspectionLevels,
    activeOption,
    setActiveOption,
    isSaving,
    jobIdx,
    phases,
    handleAddLevel,
    handleChangeLevelName,
    handleChangeRoomName,
    setIsModalOpen,
    handleYearOfConstruction,
    saveError,
    setSaveError,
    html,
    isModalOpen,
    onSaveClick,
    showSuccessToast,
    setShowSuccessToast,
    setGenerateReportMessage,
    onReportError,
    onReportGenerated,
    generateRepertMessage,
    isGeneratingReport,
    setIsGeneratingReport,
    handleRoomDelete,
    isDeletingRoom,
    handleLevelDelete,
    isDeletingLevel,
    showRoomDeletedMessage,
    setShowRoomDeletedMessage,
    showLevelDeletedMessage,
    setShowLevelDeletedMessage,
    handleCTFileDelete,
    isDeletingCTFile,
    showCTFileDeletedMessage,
    setShowCTFileDeletedMessage,
    showReorderImagesModal,
    setShowReorderImagesModal,
    getISRHTML,
  } = useInspectionViewModel();
  const [isMandatoryFieldsInvalid, setIsMandatoryFieldsInvalid] = useState(false);
  const [isLossTypeValid, setIsLossTypeValid] = useState(false);
  const [hasFormChanged, setHasFormChanged] = useState(false);

  const { i18n } = useTranslation();

  useEffect(() => {
    if (showRoomDeletedMessage) {
      toast.success(t('roomDeletedSuccessfully'));
      setShowRoomDeletedMessage(false);
    }
  }, [
    showRoomDeletedMessage,
    setShowRoomDeletedMessage,
  ]);

  useEffect(() => {
    if (showLevelDeletedMessage) {
      toast.success(t('levelDeletedSuccessfully'));
      setShowLevelDeletedMessage(false);
    }
  }, [
    showLevelDeletedMessage,
    setShowLevelDeletedMessage,
  ]);

  useEffect(() => {
    if (showCTFileDeletedMessage) {
      toast.success(t('photoDeleted'));
      setShowCTFileDeletedMessage(false);
    }
  }, [setShowCTFileDeletedMessage, showCTFileDeletedMessage]);

  useEffect(() => {
    if (showSuccessToast) {
      toast.success(t('siteInfoSaved'));
      setShowSuccessToast(false);
    }
  }, [setShowSuccessToast, showSuccessToast]);

  const handleOptionPress = async (option: InspectionActiveOption) => {
    await onSaveClick();
    if (isMandatoryFieldsInvalid) {
      toast.error(t('mandatoryFieldsNotValid'));
      return;
    }
    if (hasFormChanged) {
      setShowSuccessToast(true);
    }
    setActiveOption(option);
  };
  
  const handleSavePress = async () => {
    if (hasFormChanged) {
      await onSaveClick();
      if (isMandatoryFieldsInvalid) {
        toast.error(t('mandatoryFieldsNotValid'));
      } else {
        setShowSuccessToast(true);
      }
    }
  };

  const handlePreviewClick = async () => {
    await onSaveClick();
    if (isMandatoryFieldsInvalid || !isLossTypeValid) {
      toast.error(t('mandatoryFieldsNotValid'));
      return;
    }
    setIsModalOpen(true);
    getISRHTML();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="ion-no-padding inspection-ion-toolbar">
          <IonButtons slot="start">
            <IonButton color="danger" onClick={() => navigation.goBack()}>{t('cancel').toString()}</IonButton>
          </IonButtons>
          <IonTitle>
            <div>
              <IonLabel className='inspection-header-title'>
                {t('siteInspection').toString()}
              </IonLabel>
            </div>
            <IonLabel className='inspection-header-claim'>{claimNumber} ({handleLossType()})</IonLabel>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton type="button" strong onClick={handleSavePress}>
              {t('save').toString()}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="ion-no-padding">
          <InspectionMenu activeOption={activeOption} onOptionPress={handleOptionPress} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={isSaving} />
        {activeOption === InspectionMenuTabs.SiteInfo && 
            <SiteInspectionInfo 
            claimIndx={jobIdx} 
            mandatoryFieldsInvalid={setIsMandatoryFieldsInvalid}
            isMandatoryFieldsInvalid={isMandatoryFieldsInvalid}
            setIsLossTypeValid={setIsLossTypeValid}
            hasChangedMessageControl={setHasFormChanged}/>}
        {activeOption === InspectionMenuTabs.Areas &&
          <AreaInspection
            claimIndx={parseInt(jobIdx, 10)}
            phases={phases}
            onAddLevel={handleAddLevel}
            onChangeLevelName={handleChangeLevelName}
            onChangeRoomName={handleChangeRoomName}
            onRoomDelete={handleRoomDelete}
            onLevelDelete={handleLevelDelete}
            onPhotosDelete={handleCTFileDelete}
            inspectionLevels={inspectionLevels} />}
        {activeOption === InspectionMenuTabs.AllScopes && <TBD number={2} />}
        {activeOption === InspectionMenuTabs.Safety && <Safety onSaveClick={onSaveClick} claimId={jobIdx} claimYearOfConstruction={handleYearOfConstruction()} phases={phases} 
            mandatoryFieldsInvalid={setIsMandatoryFieldsInvalid}
            isMandatoryFieldsInvalid={isMandatoryFieldsInvalid}
            hasChangedMessageControl={setHasFormChanged}/>}
      </IonContent>
      <FosFooterButton
        label={t('preview').toString()}
        onClick={handlePreviewClick}
      />
      <FosFooterButton
        label={t('reorderImages').toString()}
        onClick={() => setShowReorderImagesModal(Number(jobIdx))}
      />
      <IonLoading isOpen={isSaving} message={t('savingLevels')} duration={1} />
      <IonLoading isOpen={isGeneratingReport} message={t('generatingReport')} duration={1} />
      <IonLoading isOpen={isDeletingRoom} message={t('deletingRoom')} duration={1} />
      <IonLoading isOpen={isDeletingLevel} message={t('deletingLevel')} duration={1} />
      <IonLoading isOpen={isDeletingCTFile} message={t('photoDeleted')} duration={1} />
      <Toaster />
      <IonToast
        isOpen={!!saveError}
        message={saveError}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => { setSaveError(''); }}
      />
      <IonToast
        isOpen={!!generateRepertMessage}
        message={generateRepertMessage}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => { setGenerateReportMessage(''); }}
        duration={3000}
        position='top'
      />
      <ReorderSiteInspectionImagesModal
        claimIndx={showReorderImagesModal}
        onDidDismiss={() => setShowReorderImagesModal(0)}
      />
      <PreviewModal
        html={html}
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        claimIndx={parseInt(jobIdx, 10)}
        reportName={ReportType.INSPECTION}
        onGenerate={() => setIsGeneratingReport(true)}
        onError={onReportError}
        onSuccess={onReportGenerated}
        language={i18n.language}
        phases={phases}
      />
    </IonPage>
  );
};

export default Inspection;
