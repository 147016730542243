import { gql } from '@apollo/client';

const DELETE_ROOM =  gql`mutation deleteRoom($id: Int!) {
  deleteRoom(id: $id)
  {
    itemId
    status
    message
    errors {
      code
      value
    }
  }
}`;

export default DELETE_ROOM;