import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonModal,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { close } from 'ionicons/icons';
import { useAuth } from '../hooks/authContext';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import './CertificateOfCompletion.css';
import FosCertificateSelectItem from '../atom/FosCertificateSelectItem';
import openDocumentURL from '../pages/helper/Documents';
import useFormViewModel, { PrintFormProps } from './FormViewModel';

type FormData = {
  ownerName?: string;
  ownerEmail?: string;
  language?: string;
};

const ValuableLiabilityWaiver: React.FC<PrintFormProps> = ({ isOpen, claimIndx, onDismiss, region, onSubmit }) => {
  const { t } = useTranslation();
  const { validateField,
    validateEmail,
    isLoading,
    setIsLoading,
    isEmailAlertOpen,
    setIsEmailAlertOpen,
    isNameAlertOpen,
    setIsNameAlertOpen,
    isLanguageAlertOpen,
    setIsLanguageAlertOpen,
  } = useFormViewModel();

  const { userState } = useAuth()!;
  const restClient = getRestClient(userState, ClientType.FORM);

  const [formData, setFormData] = useState<FormData>({
    ownerName: undefined,
    ownerEmail: undefined,
    language: undefined,
  });

  function validateForm(data: FormData) {
    let check = true;
    check = validateField(data.ownerName === undefined, setIsNameAlertOpen) === false ? false : check;
    check = validateEmail(setIsEmailAlertOpen, data.ownerEmail) === false ? false : check;
    check = validateField(data.language === undefined, setIsLanguageAlertOpen) === false ? false : check;
    return check;
  }

  const onClickSignNowHandler = async () => {
    if (validateForm(formData)) {
      setIsLoading(true);
      try {
        restClient
          .get('/common/valuable-liability-waiver/sign', {
            params: {
              regionId: region,
              claimIndx,
              language: formData.language,
              ownerEmail: formData.ownerEmail,
              fosEmail: userState.userAuthPayload?.preferredUsername,
              ownerName: formData.ownerName,
            },
          })
          .then((res) => {
            openDocumentURL(res.data.signingUrl);
            // agreementId = res.data.agreementId;
          })
          .then(() => {
            setIsLoading(false);
            onSubmit(true);
          })
          .catch((ex) => {
            const msg = ex?.response?.data?.ErrorCode === 400 ? t('errorXSS') : '';
            setIsLoading(false);
            onSubmit(false, msg);
          });
      } catch {
        setIsLoading(false);
        onSubmit(false);
      }
    }
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar class="header">
          <IonTitle>{t('valuableLiabilityWaiver').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <div className="close-icon-container">
                <IonIcon className="tools-close-button" icon={close} />
              </div>{' '}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
      <div className="certificate-of-completion-section row">
          <IonLabel className="select-label">{t('ownerName').toString()}*</IonLabel>
          <IonTextarea
            name="ownerName"
            className="owner-info"
            autoGrow
            required
            value={formData.ownerName}
            onIonChange={(ev) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  ownerName: ev.detail.value!.toString(),
                };
                setIsNameAlertOpen(newData.ownerName === undefined);
                return newData;
              });
            }}
          />
          {isNameAlertOpen && (
            <IonText className="invalid-entry-message">{t('pleaseEnterAnOwnerName').toString()}</IonText>
          )}
        </div>
      <div className="certificate-of-completion-section row">
          <IonLabel className="select-label">{t('ownerEmail').toString()}*</IonLabel>
          <IonTextarea
            name="ownerEmail"
            className="owner-info"
            autoGrow
            required
            value={formData.ownerEmail}
            onIonChange={(evt) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  ownerEmail: evt.detail.value!.toString(),
                };
                validateEmail(setIsEmailAlertOpen, newData.ownerEmail);
                return newData;
              });
            }}
          />
          {isEmailAlertOpen && (
            <IonText className="invalid-entry-message">{t('pleaseEnterAValidEmailAddress').toString()}</IonText>
          )}
        </div>
        <FosCertificateSelectItem
          altBorder
          disableMarginBottom={isLanguageAlertOpen}
          asterisk
          label={t('language')}
          options={[
            { label: t('english'), value: 'english' },
            // TODO: ADD WHEN FR PDF AVAILABLE { label: t('french'), value: 'french' },
          ]}
          onChange={(newValue) => {
            setFormData((prevData) => {
              const newData = {
                ...prevData,
                language: newValue.detail.value,
              };
              setIsLanguageAlertOpen(newData.language === undefined);
              return newData;
            });
          }}
        />
        {isLanguageAlertOpen && (
          <IonText className="invalid-entry-message margin-bottom-adjust">
            {t('pleaseSelectLanguage').toString()}
          </IonText>
        )}
        <IonFooter>
          <IonButton class=" button-fill-margin" fill="solid" expand="block" onClick={() => onClickSignNowHandler()}>
            {t('signNow').toString()}
          </IonButton>
        </IonFooter>
        <IonLoading isOpen={isLoading} message={t('sendingForm')} />
      </IonContent>
    </IonModal>
  );
};
export default ValuableLiabilityWaiver;