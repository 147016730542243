import { useState } from 'react';
import { emailRegexNoGlobal } from '../pages/helper/FormsHelper';
import { Template as TemplateEnum } from '../utils/FormTemplate';

type PrintFormProps = {
  isOpen: boolean;
  claimIndx: number;
  onDismiss: () => void;
  onSubmit: (success: boolean, msg?: string) => void;
  region: number;
  claim?: any;
};




const DailyReportsViewModel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailAlertOpen, setIsEmailAlertOpen] = useState(false);
  const [isEmail2AlertOpen, setIsEmail2AlertOpen] = useState(false);
  const [isNameAlertOpen, setIsNameAlertOpen] = useState(false);
  const [isName2AlertOpen, setIsName2AlertOpen] = useState(false);
  const [isPhaseAlertOpen, setIsPhaseAlertOpen] = useState(false);
  const [isLanguageAlertOpen, setIsLanguageAlertOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateEnum>(TemplateEnum.Standard);
  const [isDateAlertOpen, setIsDateAlertOpen] = useState(false);
  const [isSecondSigner, setIsSecondSigner] = useState(false);
  const [phaseArray, setPhaseArray] = useState<Array<any>>([]);
  const [isEstimateAlertOpen, setIsEstimateAlertOpen] = useState(false);



  function validateField(condition: boolean, setAlertState: (arg0: boolean) => void) {
    const isValid = !condition;
    setAlertState(!isValid);
    return isValid;
  }
  const renderPhaseOptions = () =>
    phaseArray.map((phase) => ({
      label: phase.phaseCode,
      value: phase.phaseIndx,
    }));
  function validateEmail(setAlertState: (arg0: boolean) => void, email?: string) {
    const testEmail = emailRegexNoGlobal.test(email !== undefined ? email : '');
    setAlertState(!testEmail);
    return testEmail;
  }

  
  return {
    validateField,
    validateEmail,
    isLoading,
    setIsLoading,
    isEmailAlertOpen,
    setIsEmailAlertOpen,
    isEmail2AlertOpen,
    setIsEmail2AlertOpen,
    isName2AlertOpen,
    setIsName2AlertOpen,
    isNameAlertOpen,
    setIsNameAlertOpen,
    isPhaseAlertOpen,
    setIsPhaseAlertOpen,
    isLanguageAlertOpen,
    setIsLanguageAlertOpen,
    selectedTemplate,
    setSelectedTemplate,
    isDateAlertOpen,
    setIsDateAlertOpen,
    isSecondSigner,
    setIsSecondSigner,
    phaseArray,
    setPhaseArray,
    isEstimateAlertOpen,
    setIsEstimateAlertOpen,
    renderPhaseOptions,
  };
};

export default DailyReportsViewModel;
export type { PrintFormProps };