import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseQuery from './baseQuery';
import GET_CLAIM_DOCUMENTS_LIST, { ClaimDocumentsType } from '../graphql/GetClaimDocumentsList';

const useClaimDocuments = (region: number, claimIndx: number, userState: UserState) => {
  const enabled = !!claimIndx;
  const { data, isLoading, isFetching, refetch } = useBaseQuery(
    region,
    { claimIndx },
    userState,
    enabled,
    GET_CLAIM_DOCUMENTS_LIST,
    claimsKeys.claimDocuments(),
  );
  const claimDocumentsList: ClaimDocumentsType[] =
    !isLoading && !isFetching ? data?.claimDocuments || [] : [];
  return { claimDocumentsList, isLoading: isLoading || isFetching, refetch };
};

export default useClaimDocuments;