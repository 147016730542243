import { gql } from '@apollo/client';

const SAVE_SITE_INSPECTION_INFO = gql`
mutation saveInspectionSiteInfo($info: InspectionSiteInfoDtoInput!) {
  saveInspectionSiteInfo(info: $info)
  {
    itemId
    status
    message
  }
}`;

export default SAVE_SITE_INSPECTION_INFO;
