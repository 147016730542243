import { ClaimPhotosType } from '../../graphql/GetClaimPhotosList';
import { LevelModelType } from '../../graphql/GetLevelsList';

export interface PhotoGroupedByRoom {
  [key: string | number]: string[];
}

export interface PhotoRooms {
  name: string;
  id: number;
  images: string[];
}

export interface PhotoLevels {
  name: string;
  id: number | undefined;
  rooms: PhotoRooms[];
}

export const groupPhotosByRoom = (claimPhotosListParameter: ClaimPhotosType[]) => claimPhotosListParameter
  .reduce((groups: PhotoGroupedByRoom, item) => {
    if (!item.roomId) { 
      return groups; 
    }
    const images:string[] = (groups[item.roomId] || []);
    images.push(item.thumbnailFileName || item.filePath);
    const copyGroup = groups;
    copyGroup[item.roomId] = images;
    return groups;
  }, {});

export const levelListWithPhotos = (claimLevelsListParam: LevelModelType[], photosByRoom: PhotoGroupedByRoom): PhotoLevels[] => {
  const levelListLocal: PhotoLevels[] = [];
  claimLevelsListParam.map(level => {
    const rooms: PhotoRooms[] = [];
    level.rooms.map(room => {
      if (room?.roomId && photosByRoom[room?.roomId]) {
        rooms.push({ name: room.roomName, id: room.roomId, images: photosByRoom[room?.roomId] });
      }
      return false;
    });
    if (rooms.length) {
      levelListLocal.push({
        name: level.levelName,
        id: level.levelId,
        rooms,
      });
    }
    return false;
  });
  return levelListLocal;
};

export const getLevelListWithPhotos = (claimPhotosListParameter: ClaimPhotosType[], claimLevelsListParam: LevelModelType[]) => {
  const photosByRoom = groupPhotosByRoom(claimPhotosListParameter);

  const levelListLocal: PhotoLevels[] = levelListWithPhotos(claimLevelsListParam, photosByRoom);
  return levelListLocal;
};