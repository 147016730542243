import { t } from 'i18next';

interface EmailBodyInfo {
  userName: string;
  emailBody: string;
  claimIndx?: string;
  projName?: string;
  projAddr?: string;
}

export default function generateNotesEmailBody({ userName, emailBody, claimIndx, projName, projAddr }:EmailBodyInfo): string {
  return `\
    <strong>${userName}</strong> ${t('sharedANoteWithYou')}:\
    <br /><br />\
    ${t('equipmentClaim')}: <strong>${claimIndx}</strong>\
    <br />\
    ${t('name')}: <strong>${projName}</strong>\
    <br />\
    ${t('address')}: <strong>${projAddr}</strong>\
    <br /><br />\
    <strong>${t('noteDetails')}:</strong>\
    <br />${emailBody}\
  `;
}

interface EmailSubjectInfo {
  claimNumber?: string;
  projName?: string;
  projAddr?: string;
}

export function generateNotesEmailSubject({ claimNumber, projName, projAddr }: EmailSubjectInfo): string {
  return `${t('sharedNote')}: ${ claimNumber }, ${t('name')}: ${ projName }, ${t('address')}: ${ projAddr }`;
}