export enum SavedRecords {
  Claims = 'Claims',
  Emails = 'Emails',
  ClaimsSiteInfo = 'ClaimsSiteInfo',
  ClaimsLevels = 'ClaimsLevels',
  ClaimsSafety = 'ClaimsSafety',
  ClaimsPhoto = 'ClaimsPhoto',
  AddPhotosType = 'AddPhotosType',
  DownloadPhotosQueue = 'DownloadPhotosQueue',
  ProfileInfo = 'ProfileInfo',
  ExpenseSummary = 'ExpenseSummary',
  BadgeInfo = 'BadgeInfo',
}

const dbName = 'FOS_DB';

export type StorageRecord<T = any> = {
  primaryKey: any;
  data: T;
};

export type SaveStorageReturn = {
  setItem: (key: SavedRecords, value: any, primaryKey?: number) => Promise<void>;
  getItems: <T = any>(key: SavedRecords) => Promise<StorageRecord<T>[]>;
  deleteItem: (key: SavedRecords, id: any) => Promise<Boolean>;
};

const SaveStorage = (): SaveStorageReturn => {
  const openStorageFOS = (): Promise<IDBDatabase> => new Promise((resolve, reject) => {

    // Increment second parameter here when add more keys to SavedRecords
    const indexedDBFOS: IDBOpenDBRequest = indexedDB.open(dbName, 9);

    indexedDBFOS.onerror = (event: Event) => {
      reject(new Error(`IndexedDB error: ${(event.target as IDBRequest)?.result}`));
    };

    indexedDBFOS.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db: IDBDatabase = (event.target as IDBRequest)?.result;
      const keys = Object.keys(SavedRecords);
      keys.forEach((key: string) => {
        if (!db.objectStoreNames.contains(key)) {
          db.createObjectStore(key, { autoIncrement: true });
        }
      });
    };

    indexedDBFOS.onsuccess = (event: Event) => {
      const db = (event.target as IDBRequest)?.result;
      resolve(db);
    };
  });

  const setItem = async (key: SavedRecords, value: any, primaryKey?: number) => {
    const db = await openStorageFOS();
    const transaction = db.transaction([key], 'readwrite');
    const store = transaction.objectStore(key);
    if (primaryKey) {
      store.put(value, primaryKey);
    } else {
      store.add(value);
    }
  };

  const getItems = async (key: SavedRecords): Promise<StorageRecord[]> => {
    const db = await openStorageFOS();
    const transaction = db.transaction([key], 'readonly');
    const store = transaction.objectStore(key);
    const request: IDBRequest = store.openCursor();
    const dataWithIds: StorageRecord[] = [];
    return new Promise((resolve, reject) => {
      request.onsuccess = (event: Event) => {
        const cursor: IDBCursorWithValue = (event.target as IDBRequest).result;
        if (cursor) {
          const dataWithId = { primaryKey: cursor.primaryKey, data: { ...cursor.value } };
          dataWithIds.push(dataWithId);
          cursor.continue();
        } else {
          resolve(dataWithIds);
        }
      };
      request.onerror = (event: Event) => {
        reject(new Error(`IndexedDB error: ${(event.target as IDBRequest)?.error}`));
      };
    });
  };

  const deleteItem = async (key: SavedRecords, id: any): Promise<Boolean> => {
    const db = await openStorageFOS();
    const transaction = db.transaction([key], 'readwrite');
    const store = transaction.objectStore(key);
    const request: IDBRequest = store.delete(id);
    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        resolve(true);
      };
      request.onerror = (event: Event) => {
        reject(new Error(`IndexedDB error: ${(event.target as IDBRequest)?.error}`));
      };
    });
  };

  return {
    setItem,
    getItems,
    deleteItem,
  };
};

export default SaveStorage;