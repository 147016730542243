import React, { useState } from 'react';
import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonToolbar,
  useIonActionSheet,
  useIonAlert,
  useIonRouter,
} from '@ionic/react';
import { arrowBack, filterOutline } from 'ionicons/icons';
import { t } from 'i18next';
import userExpensesViewModel from './UserExpensesViewModel';
import FosDocItem from '../atom/FosDocItem';
import './UserExpenses.css';
import addCurrencyMask from '../utils/CurrencyMask';
import SearchInputWithValue from '../atom/SearchInputWithValue';
import ButtonIcon from '../atom/ButtonIcon';
import FilterModal from '../organism/FilterModal';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
import { ExpenseMethod } from '../pages/ExpenseMethod';
import { ApiStatus } from '../pages/helper/Const';
import useDeleteExpense from '../hooks/deleteExpense';
import useUndoExpense from '../hooks/undoExpense';
import { useAuth } from '../hooks/authContext';
import { ExpenseStatusType } from './ExpenseStatus';

const UserExpenses: React.FC = () => {
  const {
    userExpenseSummaryFiltered,
    userExpenseListFiltered,
    isFetching,
    isFetchingExpenses,
    isFilteringExpenses,
    expenseMethodId,
    selectedExpenseMethodName,
    searchTerm,
    myExpenseFilterModal,
    expenseStatusListOptions,
    filterByStatus,
    setFilterByStatus,
    clearFilter,
    showResults,
    onSearchChange,
    navigateToUserExpenseCategory,
    backToCategories,
    refetchExpenseList,
  } = userExpensesViewModel();

  const [presentAlert] = useIonAlert();
  const navigation = useIonRouter();
  const [present] = useIonActionSheet();
  enum ExpenseAction {
    RESET = 'RESET',
    RELEASE = 'RELEASE',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    UNDO = 'UNDO',
    VIEW = 'VIEW',
    CANCEL = 'Cancel',
  }

  const [loading, setLoading] = useState(false);
  const { userState } = useAuth()!;
  const { mutateAsync: deleteExpense } = useDeleteExpense(userState);
  const { mutateAsync: undoExpense } = useUndoExpense(userState);

  const expensesActionSheetButtons = [
    {
      text: t('edit'),
      statuses: [ExpenseStatusType.Pending],
      expenseMethods: [ExpenseMethod.MILEAGE, ExpenseMethod.CREDIT_CARD, ExpenseMethod.OUT_OF_POCKET],
      data: {
        action: ExpenseAction.EDIT,
      },
    },
    {
      text: t('delete'),
      expenseMethods: [ExpenseMethod.MILEAGE, ExpenseMethod.OUT_OF_POCKET],
      statuses: [ExpenseStatusType.Pending],
      data: {
        action: ExpenseAction.DELETE,
      },
    },
    {
      text: t('undo'),
      statuses: [ExpenseStatusType.ReleasedToApprover, ExpenseStatusType.DeclinedByFinance],
      expenseMethods: [ExpenseMethod.MILEAGE, ExpenseMethod.CREDIT_CARD, ExpenseMethod.OUT_OF_POCKET],
      data: {
        action: ExpenseAction.UNDO,
      },
    },
    {
      text: t('view'),
      expenseMethods: [ExpenseMethod.MILEAGE, ExpenseMethod.CREDIT_CARD, ExpenseMethod.OUT_OF_POCKET],
      statuses: [
        ExpenseStatusType.ReleasedToApprover,
        ExpenseStatusType.ReleasedToFinance,
        ExpenseStatusType.Exported,
        ExpenseStatusType.DeclinedByFinance,
        ExpenseStatusType.ApprovedByFinance,
      ],
      data: {
        action: ExpenseAction.VIEW,
      },
    },
    {
      text: t('cancel'),
      expenseMethods: [ExpenseMethod.MILEAGE, ExpenseMethod.CREDIT_CARD, ExpenseMethod.OUT_OF_POCKET],
      role: 'destructive',
      statuses: [
        ExpenseStatusType.Pending,
        ExpenseStatusType.ReleasedToApprover,
        ExpenseStatusType.ReleasedToFinance,
        ExpenseStatusType.Exported,
        ExpenseStatusType.DeclinedByFinance,
        ExpenseStatusType.ApprovedByFinance,
      ],
      data: {
        action: ExpenseAction.CANCEL,
      },
    },
  ];

  const handleError = (err: string) => {
    presentAlert({
      header: t('warning'),
      message: err,
    });
  };

  const deleteExpenseInternal = async (expenseId: number) => {
    setLoading(true);
    if (expenseId) {
      const result = await deleteExpense(expenseId);
      if (result?.deleteExpense?.status === ApiStatus.FAILURE) {
        handleError(result?.deleteExpense?.message);
      } else {
        refetchExpenseList();
      }
    }
    setLoading(false);
  };

  const undoExpenseInternal = async (expenseId: number) => {
    setLoading(true);
    if (expenseId) {
      const result = await undoExpense(expenseId);
      if (result?.undoExpense?.status === ApiStatus.FAILURE) {
        handleError(result?.undoExpense?.message);
      } else {
        refetchExpenseList();
      }
    }
    setLoading(false);
  };

  const actionSheetForPendingExpenses = (expenseId: number, expenseMethodId: number, status: number, childExpenseId?: number) => {
    const statusEnum = status as ExpenseStatusType;
    const actionButtons = expensesActionSheetButtons.filter(a => 
      a.statuses?.some(s => s === statusEnum) && a.expenseMethods?.some(m => m === expenseMethodId));

    present({
      header: 'Expense',
      cssClass: 'custom-action-sheet',
      buttons: actionButtons,
      onDidDismiss: ({ detail }) => {
        switch (detail.data?.action) {
          case ExpenseAction.VIEW:
          case ExpenseAction.EDIT: {
            if (expenseMethodId === ExpenseMethod.MILEAGE) {
              navigation.push(`/mileage-expense/${expenseId}`);
            } else if (expenseMethodId === ExpenseMethod.CREDIT_CARD) {
              navigation.push(`/credit-card-expense/${expenseId}`);
            } else {
              navigation.push(`/expense/${expenseId}`);
            }

            break;
          }
          case ExpenseAction.DELETE: {
            presentAlert({
              header: t('confirm'),
              message: t('confirmDeleteExpense'),
              buttons: [
                t('cancel'),
                {
                  handler: () => deleteExpenseInternal(Number(childExpenseId ?? expenseId)),
                  text: t('delete'),
                },
              ],
            });

            break;
          }
          case ExpenseAction.UNDO: {
            presentAlert({
              header: t('confirm'),
              message: t('confirmUndoExpense'),
              buttons: [
                t('cancel'),
                {
                  handler: () => undoExpenseInternal(Number(childExpenseId ?? expenseId)),
                  text: t('undo'),
                },
              ],
            });

            break;
          }
          case ExpenseAction.CANCEL:
          default: break;
        }
      },
    });
  };

  return (
    <>
      {!!expenseMethodId && <IonToolbar className='user-expenses-custom-ion-toolbar'>
        <div className="ion-padding-horizontal user-expenses-toolbar">
          <SearchInputWithValue value={searchTerm} id="search-user-expenses-bar" debounce={1000} onSearch={onSearchChange} />
          <ButtonIcon id="myExpenseFilterModal" icon={filterOutline} hide={!expenseMethodId} />
        </div>
        <div role="presentation" className='user-expenses-category'>
          <IonIcon onClick={() => backToCategories()} slot="start" className='arrow-back-icon' icon={arrowBack} />
          <IonLabel className='user-expenses-category-label'
          >
            {selectedExpenseMethodName} - {expenseStatusListOptions.filter(s => s.value === filterByStatus)[0].label}
          </IonLabel>
        </div>
      </IonToolbar>}
      <div className={`user-expense-content ${expenseMethodId ? 'user-expense-content-details' : ''}`}>
        {!!expenseMethodId && <FilterModal
          ref={myExpenseFilterModal}
          trigger="myExpenseFilterModal"
          onClear={clearFilter}
          onClose={() => myExpenseFilterModal.current?.dismiss()}
          onShowResults={showResults}
        >
          <IonList>
            <div className='user-expenses-div-filter'>
              <div className='user-expenses-ion-label'>
                <IonLabel>
                  {t('status').toString()}
                </IonLabel>
              </div>
            </div>
            <FosSelectBigListItem
              id="filterUserExpenseByStatus"
              onChange={(value: string) => setFilterByStatus(value || filterByStatus)}
              value={filterByStatus.toString()}
              name='filterByStatus'
              label={t('status')}
              options={expenseStatusListOptions}
            />
          </IonList>
        </FilterModal>}
        {!isFilteringExpenses && !isFetchingExpenses && expenseMethodId ? <IonList lines='none'>
          {userExpenseListFiltered.map((userExpense) => (
            expenseMethodId === ExpenseMethod.MILEAGE ?
              <div><FosDocItem
                key={userExpense.expense.id}
                label={`${userExpense.expense.id} - ${userExpense.expense.comments}`}
                secondLabel={addCurrencyMask(userExpense.expense.totalAmount)}
                onItemClick={() => actionSheetForPendingExpenses(userExpense.expense.id, expenseMethodId, userExpense.expense.status.id)}
              /></div>
              :
              <IonAccordionGroup key={userExpense.expense.id}>
                <IonAccordion key={userExpense.expense.id} value={userExpense.expense.id.toString()}>
                  <IonItem slot="header" color="light">
                    <IonLabel>{userExpense.expense.id} - {expenseMethodId === ExpenseMethod.CREDIT_CARD ? userExpense.expense.merchant : userExpense?.expense?.comments}</IonLabel>
                  </IonItem>
                  <div className="ion-padding-top ion-padding-bottom" slot="content">
                    {userExpense.expense.isParent ? userExpense.childExpenses.map((childExp: any) => {
                      const childExpense = childExp.expense;
                      return <div key={childExpense.id}>
                        <FosDocItem
                          label={`${childExpense.id} - ${expenseMethodId === ExpenseMethod.CREDIT_CARD ? childExpense.merchant : childExpense.comments}`}
                          secondLabel={addCurrencyMask(childExpense.totalAmount)}
                          onItemClick={() => actionSheetForPendingExpenses(userExpense.expense.id, expenseMethodId, childExpense.status.id, childExpense.id)}
                        />
                      </div>;
                    }) :
                      <div>
                        <FosDocItem
                          key={userExpense.expense.id}
                          label={`${userExpense.expense.id} - ${expenseMethodId === ExpenseMethod.CREDIT_CARD ? userExpense?.expense?.merchant : userExpense?.expense?.comments}`}
                          secondLabel={addCurrencyMask(userExpense.expense.totalAmount)}
                          onItemClick={() => actionSheetForPendingExpenses(userExpense.expense.id, expenseMethodId, userExpense.expense.status.id)}
                        />
                      </div>}
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
          ))}
          {userExpenseListFiltered.length === 0 && !(isFetching || isFetchingExpenses) &&
            <p className="ion-text-center">{t('noRecord').toString()}</p>
          }
        </IonList> : <IonList lines='none'>
          {userExpenseSummaryFiltered?.length > 0 && userExpenseSummaryFiltered?.map((userExpense) => (
            <div key={userExpense.id}>
              <FosDocItem
                label={t(`${userExpense.expenseMethodType}`)}
                secondLabel={addCurrencyMask(userExpense.expenses)}
                onItemClick={() => navigateToUserExpenseCategory(userExpense.id)}
                onArrowClick={() => { }}
              />
            </div>
          ))}
        </IonList>}
        <IonLoading isOpen={loading || isFetching || isFetchingExpenses} message={t('loading')} duration={5000} />
      </div>
    </>
  );
};

export default UserExpenses;