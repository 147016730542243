import { useState } from 'react';
import { UserState } from '../../../../hooks/authUtils';
import useBaseQuery from '../../../../hooks/baseQuery';
import { DomainParams } from '../../../../hooks/graphQLClientUtil';
import GET_EQUIPMENTS_BY_CLASSES, { EquipmentsByClassesType } from '../graphql/GetEquipmentsByClasses';
import equipmentKeys from './equipmentKeys';


const useEquipmentsByClasses = (region: number, userState: UserState, variables: any, enabled: boolean) => {
  const [equipmentsByClassesList, setEquipmentsByClassesList] = useState<EquipmentsByClassesType[]>([]);

  const onSuccess = async (data: any) => {    
    const expenseList = data?.groupEquipmentsByClasses || [];
    setEquipmentsByClassesList(expenseList);
  };
  const { isLoading, isFetching, refetch, error } = useBaseQuery(
    region,
    variables,
    userState,
    enabled,
    GET_EQUIPMENTS_BY_CLASSES,
    equipmentKeys.equipmentByClasses(),
    DomainParams.Equipments,
    onSuccess,
    false,
  );

  return { equipmentsByClassesList, isLoading, refetch, isFetching, error };
};

export default useEquipmentsByClasses;