/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
  useIonViewDidLeave,
} from '@ionic/react';
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toaster, toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import useScanEquipmentViewModel from './ScanEquipmentViewModel';
import FosScanToolbar from '../../../../components/FosScanToolbar';
import './ScanEquipment.css';
import CameraManager from '../../util/CameraManager';
import { EquipmentAction, EquipmentStatus, ScanEquipmentOption, isValidCode } from '../../util/EquipmentUtil';
import getEquipmentByAssetTagHelper from '../../data/helper/getEquipmentByAssetTagHelper';
import { useAuth } from '../../../../hooks/authContext';

interface RouteParams {
  scanType: string;
  action: EquipmentAction;
}

const ScanEquipment: React.FC = () => {
  const { t } = useTranslation();
  const documentBody = document?.querySelector('body')?.classList;
  const scannerActive = 'scanner-active';
  const [isLoading, setIsLoading] = useState(false);
  const { userState } = useAuth()!;
  const navigation = useIonRouter();
  const { scanType } = useParams<RouteParams>();
  const [isScanning, setIsScanning] = useState(false);
  const isBatchScan = scanType === 'batch';
  const [isAddingBatchScan, setIsAddingBatchScan] = useState(false);
  const history = useHistory();

  const [numberOfItems, setNumberOfItems] = useState(0);
  
  const {
    invalidCode,
    equipmentNotAvailable,
    scannedEquipments,
    onScanSuccess,
    isSaving,
    onUpdateStatusSuccess,
    onUpdateStatusError,
    forceUpdate,
    setOnUpdateStatusError,
    stopScan,
    setInvalidCode,
    setOnScanSuccess,
    onFinishClick,
    handleForceUpdate,
    setEquipmentNotAvailable,
    setScannedEquipments,
  } = useScanEquipmentViewModel();

  useEffect(() => {
    const cameraManager = CameraManager.getInstance();

    const handleSingleScan = (regionId: string, equipmentId: string) => {
      stopScan();
      navigation.push(`/equipment-details/${regionId}/${equipmentId}`);
    };

    const handleBatchScan = async (equipmentId: number, euipmentStatus: number) => {
      if (Number(euipmentStatus) !== EquipmentStatus.Available) {
        await BarcodeScanner.stopScan();
        setEquipmentNotAvailable(true);
      } else {
        // avoid duplicated scanned items.
        if (!scannedEquipments.includes(equipmentId)) {
          scannedEquipments?.push(equipmentId);
        }
        setOnScanSuccess(true);
        setNumberOfItems(scannedEquipments.length);
        
        setScannedEquipments(scannedEquipments);
        handleForceUpdate();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await handleScan();
      }
    };

    const startScan = async () => {
      try {
        await BarcodeScanner.hideBackground();
        const result = await BarcodeScanner.startScan();
        handleForceUpdate();
        if (result.hasContent && isValidCode(result.content)) {
          setInvalidCode(false);
          setIsLoading(true);
          setEquipmentNotAvailable(false);
          const equipmentData = await getEquipmentByAssetTagHelper(userState, result.content);
          setIsLoading(false);
          if (equipmentData) {
            if (scanType === ScanEquipmentOption.Single) {
              handleSingleScan(equipmentData.region?.id, equipmentData.id);
            } else {
              handleBatchScan(equipmentData.id, equipmentData.status?.id);
            }
          } else if (scanType === ScanEquipmentOption.Batch) {
            // ask to add new equipment
            setIsAddingBatchScan(true);
            await BarcodeScanner.stopScan();
            handleForceUpdate();
            await handleScan();
          } else {
            navigation.push(`/equipment-edition/undefined/${result.content}`);
          }
        } else {
          setInvalidCode(true);
          await BarcodeScanner.stopScan();
          handleForceUpdate();
          await handleScan();
        }
      } catch (error) {
        setInvalidCode(true);
      }
    };

    const handleScan = async () => {
      try {
        await BarcodeScanner.prepare();
        await startScan();
      } finally {
        setIsScanning(false);
      }
    };

    const startCameraAsync = async () => {
      documentBody?.add(scannerActive);
      try {
        await handleScan();
      } catch (error) {
        setOnScanSuccess(false);
      }
    };

    if (!forceUpdate) {
      startCameraAsync();
    }

    return () => {
      documentBody?.remove(scannerActive);
      cameraManager.stopCamera();
    };
  }, [
    handleForceUpdate,
    setOnScanSuccess,
    forceUpdate,
    documentBody,
    setInvalidCode,
    userState,
    scanType,
    navigation,
    isScanning,
    stopScan,
    setEquipmentNotAvailable,
    setScannedEquipments,
    scannedEquipments,
    history,
  ]);

  useEffect(() => {
    setTimeout(() => {
      const videoElement = document.querySelector('video');
      if (videoElement) {
        const videoContainer = videoElement.parentElement;
        if (videoContainer) {
          const headerHeight = '56px';

          videoContainer.style.top = headerHeight;
          videoContainer.style.height = isBatchScan ? '80%' : `calc(100% - ${headerHeight})`; // Adjust height to fill the rest of the screen below the header
        }
      }
    }, 3000); 

  }, [documentBody, isBatchScan]);

  useEffect(() => {
    if (onScanSuccess) {
      toast.success(t('itemScanned'), { duration: 2000 });
      setOnScanSuccess(false);
    }
  }, [onScanSuccess, setOnScanSuccess, t]);

  useEffect(() => {
    if (onUpdateStatusSuccess) {
      toast.success(t('equipmentStatusUpdated'), { duration: 2000 });
      setOnScanSuccess(false);
    } else if (onUpdateStatusError !== '') {
      toast.error(onUpdateStatusError, { duration: 2000 });
      setOnUpdateStatusError('');
    }
  }, [onUpdateStatusError, onUpdateStatusSuccess, setOnScanSuccess, setOnUpdateStatusError, t]);

  const additionalStopScan = useCallback(() => {
    stopScan();
  }, [stopScan]);

  useIonViewDidLeave(() => {
    stopScan();
  });

  return (
    <IonPage>
      <FosScanToolbar title={t('scanEquipment').toString()} additionalBackClick={additionalStopScan} />
      <IonAlert
        isOpen={invalidCode}
        header={t('scanEquipmentInvalidCodeHeader')}
        message={t('scanEquipmentInvalidCodeMessage')}
        buttons={[{ text: t('ok') }]}
        cssClass='scan-equipment-alert'
        onDidDismiss={() => { setInvalidCode(false); }} />
      <IonAlert
        isOpen={isAddingBatchScan}
        header={t('pleaseAddEquipmentBeforeBatchScan')}
        message={t('pleaseAddEquipmentBeforeBatchScan')}
        buttons={[{ text: t('ok') }]}
        cssClass='scan-equipment-alert'
        onDidDismiss={() => { setIsAddingBatchScan(false); }} />
      <IonAlert
        isOpen={equipmentNotAvailable}
        header={t('scanEquipmentInvalidCodeHeader')}
        message={t('equipmentNotAccessible')}
        buttons={[{ text: t('ok') }]}
        cssClass='scan-equipment-alert'
        onDidDismiss={() => {
          setEquipmentNotAvailable(false); 
          history.goBack();
        }} />
      <IonContent id='body'>
        <IonLoading message={t('loading')} isOpen={isLoading} duration={2000} />
        <IonLoading message={t('saving')} isOpen={isSaving} duration={2000} />
      </IonContent>
      {isBatchScan &&
        <IonFooter className='scan-equipment-footer'>
          <IonGrid>
            <IonRow className='ion-text-start ion-align-items-center'>
              <IonCol size='9'>
                <IonLabel className='ion-padding-start'>
                  <IonText>{t('scannedItems').toString()} ({numberOfItems})</IonText>
                </IonLabel>
              </IonCol>
              <IonCol size='3'>
                <IonButton onClick={onFinishClick}>
                  Done
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      }
      <Toaster containerStyle={{ top: 80, left: 20, bottom: 20, right: 20 }} />
    </IonPage>
  );
};

export default ScanEquipment;
