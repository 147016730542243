import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseQuery from './baseQuery';
import GET_CLAIM_DETAILS from '../graphql/GetClaimDetails';
import { DomainParams } from './graphQLClientUtil';

const useClaimDetails = (region: number, claimIndx: number, userState: UserState, onClaimDetailsSuccess? : (data: any) => void) => {
  const enabled = !!claimIndx;

  const { data, isLoading, status, refetch, isFetching } = useBaseQuery(
    region,
    { claimIndx },
    userState,
    enabled,
    GET_CLAIM_DETAILS,
    claimsKeys.claim(),
    DomainParams.Common,
    onClaimDetailsSuccess,
  );
  return { data, isLoading, status, refetch, isFetching };
};

export default useClaimDetails;