import { gql } from '@apollo/client';

export type EmailSuggestion = {
  email: String;
  username: String;
  fullName: String;
  branchId: Number;
};

const GET_EMAIL_SUGGESTIONS = gql`
query userEmailsByTerm ($term: String) {
  userEmailsByTerm(term: $term)
  {
    email
    username
    fullName
    branchId
  }
}`;

export default GET_EMAIL_SUGGESTIONS;