import React, { FC, useCallback, useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
} from '@ionic/react';
import { PushNotifications } from '@capacitor/push-notifications';
import Jobs from './Jobs';
import Profile from './Profile';
import { equipmentTabIcon, jobsTabIcon, profileTabIcon, Tabs as TabNames } from './helper/BottomNavigationHelper';
import './Routes.css';
import Equipments from '../features/equipment/view/list/equipments/Equipments';
import { usePermissions, PermissionsList } from '../hooks/permissions';
import FosCachedImage from '../components/FosCachedImage';
import { GUID } from '../graphql/GetUserProfileInfo';
import { HYBRID } from '../hooks/photo';
import useSaveDeviceToken from '../hooks/saveDeviceToken';
import { useAuth } from '../hooks/authContext';

type Props = {};

const Tabs: FC<Props> = () => {
  const { t } = useTranslation();
  const { userState } = useAuth()!;
  const [currentTab, setCurrentTab] = useState<any>(null);
  const history = useHistory();

  const { permissions } = usePermissions();
  const isViewClaimsEnabled = permissions.includes(PermissionsList.ViewClaims);
  const isViewEquipmentEnabled = permissions.includes(PermissionsList.EquipmentManager)
    || permissions.includes(PermissionsList.EquipmentManager_EditEquipment);

  const { mutateAsync } = useSaveDeviceToken();

  const addListeners = useCallback(async () => {
    await PushNotifications.addListener('registration', token => {
      if (isPlatform(HYBRID) && window.localStorage.getItem(GUID) && userState.loggedIn) {
        mutateAsync({
          deviceToken: token.value,
          plataform: 'APNS',
          userId: window.localStorage.getItem(GUID) || '',
        });
      }
    });
  
    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      const claimId = notification?.notification?.data?.aps?.['thread-id'] || '';
      if (claimId) {
        window.localStorage.setItem('open_notifications', 'true');
        history.replace(`/jobs/${claimId}/true`);
      }
    });
  }, [history, mutateAsync, userState.loggedIn]);


  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  };


  useEffect(()=> {
    if (isPlatform(HYBRID) && window.localStorage.getItem(GUID) && userState.loggedIn) {
      addListeners();
      registerNotifications();
    }
  }, [addListeners, userState.loggedIn]);


  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/tabs/jobs" component={Jobs} />
        <Route exact path="/tabs/equipments" component={Equipments} />
        <Route exact path="/tabs/profile" component={Profile} />
        <Route exact path="/tabs">
          <Redirect to="/tabs/jobs" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar onIonTabsDidChange={setCurrentTab} slot="bottom" class="ion-tab-bar" style={{}}>
        {isViewClaimsEnabled && <IonTabButton tab={TabNames.JOBS} href="/tabs/jobs" class="ion-tab-button">
          <FosCachedImage src={jobsTabIcon(currentTab)} />
          <IonLabel class="ion-label">
            <span>{t('jobs').toString()}</span>
          </IonLabel>
        </IonTabButton>}
        {isViewEquipmentEnabled && <IonTabButton tab={TabNames.EQUIPMENT} href="/tabs/equipments" class="ion-tab-button">
          <FosCachedImage src={equipmentTabIcon(currentTab)} />
          <IonLabel class="ion-label">
            <span>{t('equipment').toString()}</span>
          </IonLabel>
        </IonTabButton>}
        <IonTabButton tab={TabNames.PROFILE} href="/tabs/profile" class="ion-tab-button">
          <FosCachedImage src={profileTabIcon(currentTab)} />
          <IonLabel class="ion-label">
            <span>{t('profile').toString()}</span>
          </IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Tabs;