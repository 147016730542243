import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import SaveStorage, { SavedRecords, StorageRecord } from '../utils/SaveStorage';
import { ClaimOnlineStatus } from '../pages/helper/Const';
import { useAuth } from '../hooks/authContext';
import { GUID, REGION_ID } from '../graphql/GetUserProfileInfo';

interface ClaimOfflineContextType {
  offlineClaims: StorageRecord[];
  toBeSyncCount: number;
  refresh: () => Promise<void>;
}

const defaultContext: ClaimOfflineContextType = {
  offlineClaims: [],
  toBeSyncCount: 0,
  refresh: async () => {},
}; 

const ClaimOfflineContext = createContext<ClaimOfflineContextType>(defaultContext);

const ClaimOfflineProvider = ({ children }: any) => {
  const { userState } = useAuth()!;
  const [offlineClaims, setOfflineClaims] = useState<StorageRecord[]>([]);
  const [toBeSyncCount, setToBeSyncCount] = useState<number>(0);
  const regionId = userState.userAuthPayload?.regionId! || 
    Number(window.localStorage.getItem(`${REGION_ID}_${window.localStorage.getItem(GUID) || ''}`));

  const values = useMemo(() => {
    const refresh = async () => {
      const saveStorage = SaveStorage();
      const claimStorage = await saveStorage.getItems(SavedRecords.Claims);
      const claimStorageByRegionId = claimStorage?.filter(claim => claim.data?.regionId === regionId);
      setOfflineClaims(claimStorageByRegionId);
      const claimInfoList = (await saveStorage.getItems(SavedRecords.ClaimsSiteInfo))?.filter(a => a.data?.status === ClaimOnlineStatus.ToBeSynched);
      const claimSafetyList = (await saveStorage.getItems(SavedRecords.ClaimsSafety))?.filter(a => a.data?.status === ClaimOnlineStatus.ToBeSynched);
      const claimPhotoList = (await saveStorage.getItems(SavedRecords.ClaimsPhoto))?.filter(a => a.data?.status === ClaimOnlineStatus.ToBeSynched);
      const claimLevelList = (await saveStorage.getItems(SavedRecords.ClaimsLevels))?.filter(a => a.data?.status === ClaimOnlineStatus.ToBeSynched);

      // count when there is clain to be sync or there is any info, safety, photo or level to sync
      const toBeSync = claimStorageByRegionId?.filter(o => o.data.claim.status === ClaimOnlineStatus.ToBeSynched 
        || claimInfoList?.filter(i => i.primaryKey === o.data.claim.claimIndx).length > 0
        || claimSafetyList?.filter(i => i.primaryKey === o.data.claim.claimIndx).length > 0
        || claimPhotoList?.filter(i => i.primaryKey === o.data.claim.claimIndx).length > 0
        || claimLevelList?.filter(i => i.primaryKey === o.data.claim.claimIndx).length > 0)?.length || 0;
      
      setToBeSyncCount(toBeSync);
    };

    return {
      offlineClaims,
      toBeSyncCount,
      refresh,
    };
  }, [offlineClaims, regionId, toBeSyncCount]);

  useEffect(() => {
    // load data from storage
    const init = async () => {
      const saveStorage = SaveStorage();
      const claimStorage = await saveStorage.getItems(SavedRecords.Claims);
      const claimStorageByRegionId = claimStorage?.filter(claim => claim.data?.regionId === regionId);
      setOfflineClaims(claimStorageByRegionId);
    };
    init();
  }, [regionId]);

  return (
    <ClaimOfflineContext.Provider value={values}>
      {children}
    </ClaimOfflineContext.Provider>
  );
};

export { ClaimOfflineContext, ClaimOfflineProvider };

export const useClaimOffline: () => ClaimOfflineContextType = () => useContext(ClaimOfflineContext);
