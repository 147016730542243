import { isPlatform, useIonActionSheet } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { LocationMapInterface } from '../pages/helper/LocationMapInterface';

const useHandleJobLocationMap = () => {
  const [present] = useIonActionSheet();
  const { t } = useTranslation();
  const handleJobLocationMap = ({
    lossAddressLat,
    lossAddressLon,
    projAddr,
  }: LocationMapInterface) => {
    present({
      header: t('map'),
      cssClass: 'custom-action-sheet',
      buttons: [
        {
          text: projAddr,
          data: {
            action: 'mapAddress',
            lat: lossAddressLat,
            long: lossAddressLon,
            address: projAddr,
          },
        },
        {
          text: t('cancel'),
          role: 'destructive',
          data: {
            action: 'cancel',
          },
        },
      ].filter(btn => btn.data.action === 'cancel' || (btn.data.lat && btn.data.long)),
      onDidDismiss: ({ detail }) => {
        if (!detail.data || detail.data?.action === 'cancel') return;
        const aDocumentElement = document.createElement('a');
        const geocoords = `${detail.data.lat},${detail.data.long}`;
        if (!isPlatform('hybrid')) {
          // open google maps
          aDocumentElement.href = `https://maps.google.com/?q=${geocoords}`;
          aDocumentElement.target = '_blank';
        } else if (isPlatform('ios')) {
          aDocumentElement.href = `maps://?q=${geocoords}`; // keep this hardcoded for testing
        } else {
          const label = encodeURI(detail.data.address);
          aDocumentElement.href = `geo:0,0?q=${geocoords}(${label})`; // keep this hardcoded for testing
        }
        aDocumentElement.click();
      },
    });
  };
  return handleJobLocationMap;
};

export default useHandleJobLocationMap;