import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
} from '@ionic/react';
import './FosRadioButton.css';
import { useTranslation } from 'react-i18next';

export interface Props {
  modalTitle: string;
  currentName: string;
  isModalOpen: boolean;
  onCancel: () => any;
  onNameChanged: (newName: string) => any;
}

const RenameModal = React.forwardRef<HTMLIonModalElement, Props>(({
  modalTitle,
  currentName,
  isModalOpen,
  onCancel,
  onNameChanged,
}, ref) => {

  const { t } = useTranslation();
  const [newLevelName, setNewLevelName] = useState('');

  useEffect(() => {
    setNewLevelName(currentName);
  }, [currentName]);
  
  return (
    <IonModal ref={ref} backdropDismiss={false} isOpen={isModalOpen} initialBreakpoint={0.3}>
      <IonItem className='media-modal-label ion-no-padding' lines='none'>
        <IonLabel>{modalTitle}</IonLabel>
      </IonItem>
      <IonItem lines='none' className='media-modal-label ion-no-padding'>
        <IonInput className='media-modal-input-border' value={newLevelName} onIonChange={(event) => setNewLevelName(event.detail.value ?? '')} />
      </IonItem>
      <IonRow className='media-modal-buttons'>
        <IonCol className='ion-no-padding media-modal-buttons-margin'>
          <IonButton expand='block' onClick={onCancel} fill='outline'>{t('cancel').toString()}</IonButton>
        </IonCol>
        <IonCol className='ion-no-padding'>
          <IonButton disabled={newLevelName!.trim() === ''} onClick={() => onNameChanged(newLevelName)} expand='block'>{t('update').toString()}</IonButton>
        </IonCol>
      </IonRow>
    </IonModal>
  );
});

export default RenameModal;
