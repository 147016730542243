import { useContext, useEffect, useState } from 'react';
import { UserState } from './authUtils';

import { DomainParams } from './graphQLClientUtil';
import NetworkContext from '../contexts/NetworkContext';
import useBaseQuery from './baseQuery';
import GET_USER_EXPENSES_SUMMARY, { UserExpenseSummaryType } from '../graphql/GetUserExpenseSummary';
import { getUserExpenseSummary, storeUserExpenseSummary } from '../pages/helper/offlineHelper/UserExpenseSummaryHelper';

const useUserExpenseSummary = (userState: UserState, region: number, userGuid: string) => {
  const network = useContext(NetworkContext);
  const [userExpenseSummary, setUserExpenseSummary] = useState<UserExpenseSummaryType[]>();

  const onSuccess = async (data: any) => {
    const expenseSummary: UserExpenseSummaryType[]  = data?.myPendingExpensesSummary || [];
    setUserExpenseSummary(expenseSummary);
    await storeUserExpenseSummary(expenseSummary);
  };

  const { isLoading, isFetching, refetch } = useBaseQuery(
    region,
    { regionId: region, userGuid },
    userState,
    network.connected,
    GET_USER_EXPENSES_SUMMARY,
    ['userExpenseSummary'],
    DomainParams.Payroll,
    onSuccess,
  );

  useEffect(() => {
    if (network.connected) return;
    const getAsync = async () => {
      const result = await getUserExpenseSummary();
      if (result?.data) setUserExpenseSummary(result.data);
    };
    getAsync();
  }, [network.connected]);

  return { userExpenseSummary, isLoading, isFetching, refetch };
};

export default useUserExpenseSummary;
