import React, { FC } from 'react';
import './InspectionMenu.css';
import { IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { InspectionActiveOption, InspectionMenuTabs } from '../pages/helper/InspectionMenuHelper';


type Props = {
  activeOption: InspectionActiveOption;
  onOptionPress: (option: InspectionActiveOption) => void
};

const selected = 'inspection-menu-label inspection-menu-selected';
const unselected = 'inspection-menu-label inspection-menu-unselected';

const InspectionMenu: FC<Props> = ({ activeOption, onOptionPress }) => {
  const { t } = useTranslation();

  const isAllScopesEnabled = process.env.REACT_APP_ENV !== 'prod';
  
  return (
    <div className='inspection-menu-container'>
      <IonLabel className={activeOption === InspectionMenuTabs.SiteInfo ? selected : unselected} onClick={() => onOptionPress(InspectionMenuTabs.SiteInfo)}>
        {t('siteInfo').toString()}
      </IonLabel>
      <IonLabel className={activeOption === InspectionMenuTabs.Areas ? selected : unselected} onClick={() => onOptionPress(InspectionMenuTabs.Areas)}>
        {t('areas').toString()}
      </IonLabel>
      {isAllScopesEnabled && 
        <IonLabel className={activeOption === InspectionMenuTabs.AllScopes ? selected : unselected} onClick={() => onOptionPress(InspectionMenuTabs.AllScopes)}>
          {t('allScopes').toString()}
        </IonLabel>
      }
      <IonLabel className={activeOption === InspectionMenuTabs.Safety ? selected : unselected} onClick={() => onOptionPress(InspectionMenuTabs.Safety)}>
        {t('safety').toString()}
      </IonLabel>
    </div>
  );
};

export default InspectionMenu;
