import { gql } from '@apollo/client';

const GET_CLAIMS_PHASES_LIST = gql`
query claimsPhases($regionId: Int!, $term: String) {
  claimsPhases(regionId: $regionId, term: $term)
  { 
    code
    id
    parentId
    value
  }
}`;

export default GET_CLAIMS_PHASES_LIST;