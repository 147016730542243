import React from 'react';
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonThumbnail,
} from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import './FosThumbItem.css';

interface Props {
  label: string;
  thumbSrc: string;
  onClick?: React.MouseEventHandler<HTMLIonItemElement>;
  onRemove?: React.MouseEventHandler<HTMLIonButtonElement>;
}

const FosThumbItem = ({ label, thumbSrc, onClick, onRemove }: Props) => (
  <IonItem class="fos-thumb-item ion-no-padding ion-margin" lines="none" onClick={onClick}>
    <IonThumbnail slot="start">
      <img alt={`${label} thumbnail`} src={thumbSrc} />
    </IonThumbnail>
    <IonLabel>
      {label}
    </IonLabel>
    {onRemove &&
      <IonButtons slot="end">
        <IonButton fill="clear" color="danger" onClick={onRemove}>
          <IonIcon icon={trashOutline} />
        </IonButton>
      </IonButtons>
    }
  </IonItem>
);

export default FosThumbItem;
