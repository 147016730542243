import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import * as yup from 'yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { close } from 'ionicons/icons';
import { Option } from '../utils/Interfaces';

import { ReactComponent as BackArrow } from '../assets/icons/ic-back-arrow.svg';
import { useAuth } from '../hooks/authContext';
import './AddPhotoToClaimForm.css';
import { ClaimPhotosType } from '../graphql/GetClaimPhotosList';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import { ApiStatus } from '../pages/helper/Const';
import UPDATE_BULK_PHOTOS from '../graphql/UpdateBulkPhotos';
import FosCachedImage from '../components/FosCachedImage';
import NetworkContext from '../contexts/NetworkContext';
import { saveOfflineClaimPhoto } from '../pages/helper/offlineHelper/ClaimPhotosHelper';
import FosSelectItem from '../atom/FosSelectItem';
import { Phase } from '../types/Phases';
import { usePermissions, PermissionsList } from '../hooks/permissions';

const closeIcon = 'assets/images/icons/ic_close.png';

type Props = {
  isOpen: boolean;
  editClaimPhoto: ClaimPhotosType[] | null;
  claimIndex: number,
  phases: Phase[];
  onDismiss: () => void;
  onSubmit: (success: boolean) => void;
};

type FormData = {
  description: string;
  phaseId: string;
};


const BulkEditPhotoToClaimForm: React.FC<Props> = ({
  isOpen,
  editClaimPhoto,
  claimIndex,
  phases,
  onDismiss,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { userState, user } = useAuth()!;

  const [isLevelsModalOpen, setIsLevelsModalOpen] = useState(false);
  const [isShowInISIChecked, setIsShowInISIChecked] = useState(false);
  const [isXactBound, setIsXactBound] = useState(false);
  const [isSymbilityBound, setIsSymbilityBound] = useState(false);
  const [isXactimateChecked, setIsXactimateChecked] = useState(false);
  const [isSymbilityChecked, setIsSymbilityChecked] = useState(false);
  const phaseOptions: Option[] = phases.map((phase) => ({ label: phase.phaseCode, value: phase.phaseCode }));
  const network = useContext(NetworkContext);
  const { permissions } = usePermissions();
  const isSendToXAEnabled = permissions.includes(PermissionsList.AllowSendToXA);
 
  const schema = yup.object({
    phaseId: yup
      .string()
      .required(t('phaseSelectionRequired')),
    description: yup
      .string()
      .max(60, t('projectNameMaxLengthIs60')),
  });

  const { handleSubmit, register, setValue, watch, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { phaseId } = watch();

  useEffect(() => {
    const phase = phases.find(f => f.phaseCode === phaseId);
    const newSendToXAEnabled = !!phase?.linkedToXA && isSendToXAEnabled;
    const newSendToSymbilityEnabled = !!phase?.linkedToSymbility;
    setIsXactBound(newSendToXAEnabled);
    setIsSymbilityBound(newSendToSymbilityEnabled);

    if (!newSendToXAEnabled) {
      setIsXactimateChecked(false);
    }

    if (!newSendToSymbilityEnabled) {
      setIsSymbilityChecked(false);
    }
  }, [isSendToXAEnabled, phaseId, phases]);


  // clean form after close
  useEffect(() => {
    if (!isOpen) {
      setValue('description', '');
      setValue('phaseId', '');
      setIsShowInISIChecked(false);
    }
  }, [isOpen, setValue]);

  /*
  * Mutation to save the form
  * */
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (formData: FormData) => {
      const request: any = { ...formData };

      request.ctfileIds = editClaimPhoto?.map(photo => photo.ctfileId);
      request.includeInIsi = isShowInISIChecked ? 1 : 0;
      request.updatedBy = user?.username;
      request.updateDate = new Date();
      request.sendToXact = isXactimateChecked;
      request.sendToSymbility = isSymbilityChecked;
      const phaseId = request.phaseId ? phases?.find(f => f.phaseCode === request.phaseId)?.phaseIndx.toString() : undefined;
      if (phaseId) {
        request.phaseIndx = Number(phaseId);
      }
      delete request.phaseId;
      return getGraphQLClient(userState).request(UPDATE_BULK_PHOTOS, { request });
    },
  });

  const onEditHandler = handleSubmit(async (data: FormData) => {
    if (!phaseId) {
      return;
    }
    if (network.connected) {
      mutateAsync(data)
        .then((response) => {
          if (response?.updateCtPhotos?.status === ApiStatus.SUCCESS) {
            onSubmit(true);
            return;
          }

          throw new Error(response.updateCtPhotos?.message || t('saveSiteInfoError'));
        })
        .catch(() => {
          onSubmit(false);
        });
    } else {
      const request: any = { ...data };
      const ctFileIds: number[] = editClaimPhoto?.map(photo => photo.ctfileId) || [];
      for (let photoIdIndex = 0; photoIdIndex < ctFileIds.length; photoIdIndex++) {
        const photoId = ctFileIds[photoIdIndex];
        request.ctfileId = photoId;
        request.includeInIsi = isShowInISIChecked ? 1 : 0;
        request.updatedBy = user?.username;
        request.updateDate = new Date();
        const phaseId = request.phaseId ? phases?.find(f => f.phaseCode === request.phaseId)?.phaseIndx.toString() : undefined;
        if (phaseId) {
          request.phaseIndx = Number(phaseId);
        }
        request.sendToXact = isXactimateChecked;
        request.sendToSymbility = isSymbilityChecked;
        delete request.phaseId;
        // eslint-disable-next-line no-await-in-loop
        await saveOfflineClaimPhoto({ photo: request, claimIndx: claimIndex });
      }
      onSubmit(true);
    }
  });

  const saveClaimPhoto = () => {
    if (editClaimPhoto) {
      onEditHandler();
    }
  };

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar class="header">
            <IonButtons slot="start">
              <IonButton onClick={() => onDismiss()}>
                <BackArrow fill="none" className="back-arrow" />
              </IonButton>
            </IonButtons>
            <IonTitle>{t('importPhotos').toString()}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onDismiss()}>
                <div className="close-icon-container">
                  <IonIcon className="tools-close-button" icon={close} onClick={onDismiss} />
                </div>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding">
          <div className="row upload-selected">
            <IonLabel className='upload-photo-label'>{t('addInformation').toString()}</IonLabel>
          </div>
          <FosSelectItem
            {...register('phaseId')}
            label={t('phase')}
            options={phaseOptions}
            error={errors.phaseId?.message}
          />
          <div className="description-section row">
            <IonLabel className="select-label">{t('description').toString()}</IonLabel>
            <IonTextarea
              {...register('description')}
              className="description"
              autoGrow
            />
          </div>
          <IonGrid className="ion-no-padding ion-no-margin">
            <IonRow className="ion-no-padding ion-no-margin">
              <IonCol className="ion-margin-end">
                <IonItem lines="none" className="margin-bottom-20">
                  <IonCheckbox
                    checked={!!isShowInISIChecked}
                    value={!!isShowInISIChecked}
                    onIonChange={() => setIsShowInISIChecked(!isShowInISIChecked)}
                  />
                  <IonLabel>{t('showInISI').toString()}</IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
            {isXactBound &&
              <>
                <IonRow className="ion-no-padding ion-no-margin">
                  <IonCol>
                    <IonItem lines="none" className="margin-bottom-20">
                      <IonCheckbox
                        checked={!!isXactimateChecked}
                        value={!!isXactimateChecked}
                        onIonChange={() => setIsXactimateChecked(!isXactimateChecked)} />
                      <IonLabel>{t('submitToXact').toString()}</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonLabel className='xa-submitted-desc'>{t('xaSubmittedDescription').toString()}</IonLabel>
                </IonRow>
              </>
            }
            {isSymbilityBound &&
              <>
                <IonRow className="ion-no-padding ion-no-margin">
                  <IonCol>
                    <IonItem lines="none" className="margin-bottom-20">
                      <IonCheckbox
                        checked={!!isSymbilityChecked}
                        value={!!isSymbilityChecked}
                        onIonChange={() => setIsSymbilityChecked(!isSymbilityChecked)} />
                      <IonLabel>{t('submitToSymbility').toString()}</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonLabel className='xa-submitted-desc'>{t('symbilitySubmittedDescription').toString()}</IonLabel>
                </IonRow>
              </>
            }
          </IonGrid>
        </IonContent>
        <IonFooter class="ion-padding">
          <IonButton
            fill="solid"
            expand="block"
            onClick={saveClaimPhoto}>
            {editClaimPhoto ? t('save').toString() : t('continue').toString()}
          </IonButton>
        </IonFooter>
      </IonModal>
      <IonModal isOpen={isLevelsModalOpen} class="custom-modal">
        <IonHeader class="ion-no-border">
          <IonToolbar class="header">
            <IonTitle>{t('addImagesTo').toString()}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsLevelsModalOpen(false)}>
                <FosCachedImage src={closeIcon} className="phase-close-icon" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      </IonModal>
      <IonLoading isOpen={isLoading} message={t('saving')} duration={2000} />
    </>
  );
};

export default BulkEditPhotoToClaimForm;