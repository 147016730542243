/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState } from 'react';
import { useIonAlert } from '@ionic/react';
import { t } from 'i18next';
import { useHistory, useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from '../../../../hooks/authContext';
import { EquipmentsType } from '../../data/graphql/GetEquipmentsList';
import { EquipmentStatus, ApiStatus } from '../../../../pages/helper/Const';
import getGraphQLClient, { DomainParams } from '../../../../hooks/graphQLClientUtil';
import UPDATE_EQUIPMENT_STATUS from '../../data/graphql/UpdateEquipmentStatus';
import useEquipment from '../../data/hooks/useEquipment';
import { EquipmentInfoType } from '../../data/graphql/GetEquipmentInfo';
import { EquipmentAction } from '../../util/EquipmentUtil';
import { getErrorMsg } from '../../../../pages/helper/AppHelper';

interface RouteParams {
  regionId: string;
  itemId: string;
}

const EquipmentDetailsViewModel = () => {
  const { userState, checkTokenExpiration } = useAuth()!;
  const [presentAlert] = useIonAlert();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const history = useHistory();
  const { regionId, itemId } = useParams<RouteParams>();

  const { data, isFetching, isRefetching, refetch } = useEquipment(userState, { regionId: Number(regionId), itemId: Number(itemId) });
  const equipment: EquipmentInfoType = !isFetching && !isRefetching ? data?.equipment : null;

  const fullEquipmentActionSheetButtons = [
    {
      text: t('inTransit'),
      data: {
        action: EquipmentAction.InTransit,
      },
    },
    {
      text: t('addToClaim'),
      data: {
        action: EquipmentAction.AddToClaim,
      },
    },
    {
      text: t('branchTransfer'),
      data: {
        action: EquipmentAction.BranchTransfer,
      },
    },
    {
      text: t('returnToBranch'),
      data: {
        action: EquipmentAction.ReturnToBranch,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: EquipmentAction.CANCEL,
      },
    },
  ];

  const equipmentActionSheetButtons = Number(equipment?.status?.id) === EquipmentStatus.Available ? fullEquipmentActionSheetButtons : 
    fullEquipmentActionSheetButtons.filter(button => 
      button.data.action === EquipmentAction.ReturnToBranch || 
      button.data.action === EquipmentAction.CANCEL,
    );

  const handleOnDidDismiss = (action: any) => {
    switch (action) {
      case EquipmentAction.InTransit: {
        handleSelectedAction(EquipmentStatus.InTransit);
        break;
      }
      case EquipmentAction.AddToClaim: {
        if (Number(equipment.status.id) === EquipmentStatus.Available) {
          handleItemSelection();
        } else {
          setShowErrorToast(true);
        }
        break;
      }
      case EquipmentAction.BranchTransfer: {
        handleSelectedAction(EquipmentStatus.InterBranchTransit);
        break;
      }
      case EquipmentAction.ReturnToBranch: {
        presentAlert({
          header: t('confirm'),
          message: t('confirmReturnExpense'),
          buttons: [
            t('no'),
            {
              handler: () => handleSelectedAction(EquipmentStatus.Available, true),
              text: t('yes'),
            },
          ],
        });
        break;        
      }
      case EquipmentAction.CANCEL:
      default: break;
    }
  };

  const isValidUpdate = (returnToBranch: boolean) =>
    (returnToBranch && Number(equipment.status.id) !== EquipmentStatus.Available) ||
    (Number(equipment.status.id) === EquipmentStatus.Available && !returnToBranch);

  const handleSelectedAction = (newStatus: EquipmentStatus, returnToBranch: boolean = false) => {
    if (isValidUpdate(returnToBranch)) {
      onSubmit(newStatus);
    } else {
      setShowErrorToast(true);
    }
  };

  const handleItemSelection = () => {
    const scannedEquipment: EquipmentsType = {
      id: Number(equipment.id), 
      itemNumber: equipment.itemNumber,
      itemClass: equipment.itemClass,
      itemModel: equipment.itemModel,
      status: equipment.status,
      branch: equipment.branch,
      transitBranch: equipment.transitBranch,
    };
    if (Number(equipment.status.id) === EquipmentStatus.Available) {
      history.push({
        pathname: '/equipment-assignment',
        state: scannedEquipment,
      });
    } else {
      setShowErrorToast(true);
    }
  };

  /* 
  * Submit update
  * */
  const onSubmit = async (statusId: number) => {
    await checkTokenExpiration();
    mutateAsync({ statusId })
      .then((response) => {
        if (response?.updateEquipmentStatus?.status === ApiStatus.SUCCESS) {
          setShowSuccessToast(true);
          refetch();
          return;
        }
        throw new Error(response.updateEquipmentStatus?.message || t('updateEquipmentStatusError'));
      })
      .catch((e) => {
        const msg = getErrorMsg(e);
        const message = msg || t('updateEquipmentStatusError');
        setUpdateError(message);
      });
  };

  /*
  * Mutation to save the update
  * */
  const { isLoading: isSaving, mutateAsync } = useMutation({
    mutationFn: (params: any) =>
      getGraphQLClient(userState, DomainParams.Equipments).request(UPDATE_EQUIPMENT_STATUS, {
        regionId,
        itemId: equipment.id,
        statusId: params.statusId,
      }),
  });

  return {
    isFetching,
    isSaving,
    updateError,
    equipmentActionSheetButtons,
    showErrorToast,
    showSuccessToast,
    equipment,
    handleOnDidDismiss,
    setShowErrorToast,
    setShowSuccessToast,
    refetch,
  };

};

export default EquipmentDetailsViewModel;
