import { gql } from '@apollo/client';

export type ClaimType = {
  branchCode: string;
  claimNumber: string;
  claimNo: string;
  projName: string;
  lossType: string;
  projAddr: string;
  projCity: string;
  projProv: string;
  status: string;
  lossDate: string;
  adjuster: string;
  pmnameClaim: string;
  contactName: string;
  claimIndx: string;
  lossAddressLat: string;
  lossAddressLon: string;
  adjusterPhone: string;
  propertyManagerPhone: string;
  contactPhone: string;
  projPh1?: string;
  projPhone1?: string;
  projPh2?: string;
  projPhone2?: string;
  projPh3?: string;
  projPhone3?: string;
};

const GET_CLAIMS_LIST = gql`
query claims($regionId: Int!, $filterArray: ClaimFilterArrayInput, $term: String, $notCompleted: Boolean, $hasPhase: Boolean, $latitude: Float, $longitude: Float) {
  claims(regionId: $regionId, filterArray: $filterArray, term: $term, notCompleted: $notCompleted, hasPhase: $hasPhase, latitude: $latitude, longitude: $longitude)
  { 
    branchCode 
    claimNumber 
    claimNo
    projName
    lossType
    projAddr
    projCity
    projProv
    status
    lossDate
    adjuster
    pmnameClaim
    contactName
    claimIndx
    lossAddressLat
    lossAddressLon
    adjusterPhone
    propertyManagerPhone
    contactPhone
    distance
    projPh1
    projPh2
    projPh3
  } 
}`;

export default GET_CLAIMS_LIST;