import { Storage } from '@ionic/storage';
import { UserState } from './authUtils';
import { claimsKeys } from './claims';
import useBaseCacheAndList from './baseCacheAndList';
import { PropertyManagersType } from '../pages/helper/PropertyManagersType';
import GET_PROPERTY_MANAGER_LIST from '../graphql/GetPropertyManagersList';

const usePropertyManagers = (region: number, userState: UserState, storage: Storage) => {
  const propertyManagersList:PropertyManagersType[] = useBaseCacheAndList(region, userState, storage, GET_PROPERTY_MANAGER_LIST, claimsKeys.propertyManagersList(), 'propertyManagers');
  return propertyManagersList;
};

export default usePropertyManagers;