import { gql } from '@apollo/client';

const GET_VALIDATE_CLAIM_STATUS = gql`
query validateClaimStatus($branchId: Int!, $claimIndex: Int!, $phaseIndex: Int!) {
  validateClaimStatus(branchId: $branchId, claimIndex: $claimIndex, phaseIndex: $phaseIndex) {
    isCTValid
    isGPValid
    canCTDelete
    canCTUpdate
    canCTEXport
    canCTTransfer
    canGPExport
    errorMessage
    resultCode
  }
}`;

export default GET_VALIDATE_CLAIM_STATUS;