import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';
import UPDATE_CT_PHOTOS_POSITIONS from '../graphql/UpdateCtPhotosPositions';

export interface UpdateCtPhotosPositionsInput {
  ctfileId: number
  position: number
}

interface Args {
  claimIndx: number
  ctPhotoPositions: UpdateCtPhotosPositionsInput[]
}

const useUpdateCtPhotosPositions = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState);
  const { isLoading, mutateAsync } = useMutation<any, unknown, Args>({
    mutationFn: ({ claimIndx, ctPhotoPositions }) => graphQLClient.request(UPDATE_CT_PHOTOS_POSITIONS, { claimIndx, ctPhotoPositions }),
  });
  return { isLoading, mutateAsync };
};

export default useUpdateCtPhotosPositions;