import { PickerColumnOption, useIonPicker } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export interface MetricMeasurePickerArgs {
  handler: (value: any) => any;
  currentMeters?: number;
  currentCentimeters?: number;
}

const useMetricMeasurePicker = () => {
  const [presentIonPicker] = useIonPicker();
  const { t } = useTranslation();
  const presentMetricMeasurePicker = ({ handler, currentMeters, currentCentimeters }: MetricMeasurePickerArgs) => {
    const mOptions: PickerColumnOption[] = new Array(101)
      .fill(undefined)
      .map((v, idx) => ({ text: `${idx}`, value: idx }));
    const cmOptions: PickerColumnOption[] = new Array(100)
      .fill(undefined)
      .map((v, idx) => ({ text: `${idx}`, value: idx }));
    presentIonPicker({
      columns: [
        {
          name: 'meters',
          suffix: 'm',
          options: mOptions,
          selectedIndex: currentMeters || 0,
        },
        {
          name: 'centimeters',
          suffix: 'cm',
          options: cmOptions,
          selectedIndex: currentCentimeters || 0,
        },
      ],
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
        },
        {
          text: t('confirm'),
          handler,
        },
      ],
    });
  };

  return [presentMetricMeasurePicker];
};

export default useMetricMeasurePicker;
