import { UserState } from './authUtils';
import GET_CLAIM_NOTIFICATIONS, { ClaimNotificationsType } from '../graphql/GetClaimNotifications';
import { claimsKeys } from './claims';
import useBaseQuery from './baseQuery';

const useClaimNotifications = (region: number, claimIndx: number, userState: UserState) => {
  const enabled = !!claimIndx;
  const { data, isLoading, isFetching, refetch } = useBaseQuery(
    region,
    { claimNumber: claimIndx.toString() },
    userState,
    enabled,
    GET_CLAIM_NOTIFICATIONS,
    claimsKeys.communicationMessages(),
  );
  const claimNotificationsList: ClaimNotificationsType[] =
    !isLoading && !isFetching ? data?.communicationMessages?.sort(
      (a: ClaimNotificationsType, b: ClaimNotificationsType) => 
        new Date(b.submittedDateTime || 0).getTime() - new Date(a.submittedDateTime || 0).getTime()) || [] : [];
  return { claimNotificationsList, isLoading: isLoading || isFetching, refetch };
};

export default useClaimNotifications;