import Hammer from 'hammerjs';
import { t } from 'i18next';
import { SketchGroup, SketchGroupConfig, SketchGroupExport, SketchObjectExport } from './group';
import { Drawing, DrawingPoint } from './drawing';
import { Rect } from './rect';
import { Text } from './text';
import { Ellipse } from './ellipse';
import { SketchImage } from './image';
import { SketchObject, SketchObjectType, SketchTool } from './sketch-object';
import { Wall } from './wall';

export interface SketchConfig extends SketchGroupConfig {
  defaultObjectName?: string;
  lib?: SketchObjectExport[];
  zoomEnabled?: boolean;
  showScale?: boolean;
  hideGrid?: boolean;
  pickFile?: () => Promise<string | null>;
}

export interface SketchExport extends SketchGroupExport {
  file?: File;
}

export interface PenToolSettings {
  strokeStyle?: string;
  lineWidth?: number;
}

export interface RectToolSettings {
  lineWidth?: number;
  strokeStyle?: string;
  fillStyle?: string;
  mode?: 'fill' | 'stroke';
  showDimensions?: boolean;
}

export interface EllipseToolSettings {
  lineWidth?: number;
  strokeStyle?: string;
  fillStyle?: string;
  mode?: 'fill' | 'stroke';
  showDimensions?: boolean;
}

export interface WallToolSettings {
  strokeStyle?: string;
  lineWidth?: number;
  showDimensions?: boolean;
}

export interface ImageToolSettings {
  showDimensions?: boolean;
}

export class Sketch extends SketchGroup {
  x = 0;

  y = 0;

  zoom = 1;

  activeTool: SketchTool = SketchTool.Sel;

  lib: SketchObjectExport[] = [];

  currentLibObject?: number;

  pickFile?: () => Promise<string | null>;

  penToolSettings: PenToolSettings = {};

  rectToolSettings: RectToolSettings = {
    showDimensions: true,
  };

  ellipseToolSettings: EllipseToolSettings = {
    showDimensions: false,
  };

  wallToolSettings: WallToolSettings = {
    showDimensions: true,
    lineWidth: 3,
  };

  imageToolSettings: ImageToolSettings = {
    showDimensions: true,
  };

  zoomEnabled: boolean;

  showScale: boolean;

  hideGrid: boolean;

  private defaultObjectName: string;

  private hammer: HammerManager | null = null;

  private dragStart: HammerPoint | null = null;

  private undoMementos: SketchExport[] = [];

  private redoMementos: SketchExport[] = [];

  private selectStart: { x: number; y: number } | null = null;

  private selectEnd: { x: number; y: number } | null = null;

  constructor(config: SketchConfig = {}) {
    super(config);
    const {
      pickFile,
      defaultObjectName = 'Object',
      lib = [],
      zoomEnabled = true,
      showScale = true,
      hideGrid = false,
    } = config;
    this.pickFile = pickFile;
    this.defaultObjectName = defaultObjectName;
    this.lib = lib;
    this.zoomEnabled = zoomEnabled;
    this.showScale = showScale;
    this.hideGrid = hideGrid;

    this.on('dragstart', () => this.handleDragStart());
    this.on('drag', (event: HammerInput) => this.handleDrag(event));
    this.on('dragend', () => this.handleDragEnd());
    this.on('update', () => this.draw());
    this.on('tap', () => this.handleSketchTap());
    this.on('pinchin', (event) => this.handlePinchIn(event));
    this.on('pinchout', (event) => this.handlePinchOut(event));
    this.handleWheel = this.handleWheel.bind(this);
  }

  setX(x: number) {
    this.x = x;
    this.emit('update');
  }

  setY(y: number) {
    this.y = y;
    this.emit('update');
  }

  draw() {
    if (!this.canvas) return;
    const ctx = this.canvas.getContext('2d')!;
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawGrid();
    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.scale(this.zoom, this.zoom);
    super.draw();
    if (this.selectStart && this.selectEnd) {
      this.drawSelectionArea();
    }
    ctx.restore();
  }

  toDataURL() {
    return this.canvas?.toDataURL() || '';
  }

  isPointInsideObject(): boolean {
    return true;
  }

  groupSelected() {
    const allSelected = this.getSelected();
    const group = new SketchGroup({ objects: allSelected.map(obj => obj.export() as SketchObjectExport) });
    this.add(group);
    allSelected.forEach(obj => this.remove(obj));
  }

  ungroup(object: SketchGroup) {
    const { objects } = object.export();
    this.loadObjects(objects || []);
    this.remove(object);
  }

  resetTransform() {
    this.zoom = 1;
    this.x = 0;
    this.y = 0;
    this.emit('update');
  }

  add(obj: SketchObject): void {
    obj.on('willchangetext', () => this.saveUndoMemento());
    obj.on('willduplicate', () => this.saveUndoMemento());
    super.add(obj);
  }

  remove(obj: SketchObject): void {
    this.saveUndoMemento();
    super.remove(obj);
  }

  setCanvas(canvas: HTMLCanvasElement | null): void {
    super.setCanvas(canvas);

    if (this.canvas) {
      this.hammer?.destroy();
      this.canvas.removeEventListener('wheel', this.handleWheel);
    }
    this.canvas = canvas;
    if (!canvas) {
      this.hammer = null;
      return;
    }

    this.hammer = new Hammer.Manager(canvas);
    this.hammer.add([
      new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }),
      new Hammer.Tap({ event: 'tap' }),
      new Hammer.Pinch(),
    ]);
    this.hammer.on('panstart', (event) => this.handleCanvasPanStart(event));
    this.hammer.on('panmove', (event) => this.handleCanvasPanMove(event));
    this.hammer.on('panend', (event) => this.handleCanvasPanEnd(event));
    this.hammer.on('tap', (event) => this.handleCanvasTap(event as HammerInput & { tapCount: number }));
    this.hammer.on('pinchin', (event) => this.handleCanvasPinchIn(event));
    this.hammer.on('pinchout', (event) => this.handleCanvasPinchOut(event));
    this.canvas?.addEventListener('wheel', this.handleWheel);
    this.draw();
  }

  handleDragStart() {
    this.dragStart = { x: this.x, y: this.y };
  }

  handleDrag(event: HammerInput) {
    this.setX(this.dragStart!.x + event.deltaX);
    this.setY(this.dragStart!.y + event.deltaY);
  }

  handleDragEnd() {
    this.dragStart = null;
  }

  handlePinchIn({ center }: HammerInput) {
    if (!this.canvas) return;
    this.zoomCanvas(center.x, center.y, true);
  }

  handlePinchOut({ center }: HammerInput) {
    if (!this.canvas) return;
    this.zoomCanvas(center.x, center.y, false);
  }

  async save(): Promise<SketchExport> {
    const dataURL = this.toDataURL();
    const res = await fetch(dataURL);
    const blob = await res.blob();
    const file = new File([blob], `${this.name}.png`);
    return {
      ...this.export(),
      file,
    };
  }

  undo() {
    const memento = this.undoMementos.pop();
    if (memento?.objects) {
      this.saveRedoMemento();
      this.objects = [];
      this.loadObjects(memento.objects);
      this.emit('update');
    }
  }

  redo() {
    const memento = this.redoMementos.pop();
    if (memento?.objects) {
      // do not call saveUndoMementos here because it resets the redo stack
      this.undoMementos.push(this.export());
      this.objects = [];
      this.loadObjects(memento.objects);
      this.emit('update');
    }
  }

  getTranslatedZoomPoint(x: number, y: number) {
    const p = { x: x - this.canvas!.offsetLeft, y: y - this.canvas!.offsetTop };
    return {
      x: (p.x - this.x) / this.zoom,
      y: (p.y - this.y) / this.zoom,
    };
  }

  destroy() {
    super.destroy();
  }

  private drawGrid() {
    if (!this.canvas || this.hideGrid) return;
    const squareSize = this.meterToPixels / 5;
    const numSquaresX = Math.floor(this.canvas.width * 2 / squareSize);
    const numSquaresY = Math.floor(this.canvas.height * 2 / squareSize);
    const ctx = this.canvas.getContext('2d')!;
    ctx.save();
    ctx.scale(this.zoom, this.zoom);
    ctx.fillStyle = '#eeeeee';
    const gridWidth = numSquaresX * squareSize;
    const gridHeight = numSquaresY * squareSize;
    ctx.fillRect(0, 0, gridWidth, gridHeight);
    ctx.strokeStyle = '#e0e0e0';
    for (let x = 0; x < numSquaresX; x++) {
      for (let y = 0; y < numSquaresY; y++) {
        const squareX = x * squareSize;
        const squareY = y * squareSize;
        ctx.strokeRect(squareX, squareY, squareSize, squareSize);
      }
    }

    if (this.showScale) {
      ctx.fillStyle = '#616161';
      const text = '20x20cm';
      const { width: textWidth } = ctx.measureText(text);
      const x = this.canvas.width - textWidth - 12;
      const y = this.canvas.height - 12;
      ctx.fillText(text, x, y);
    }

    ctx.restore();
  }

  private handleSketchTap() {
    this.deselect();
  }

  private getLastObject() {
    return this.objects[this.objects.length - 1];
  }

  private handleCanvasPanStart(event: HammerInput) {
    this.saveUndoMemento();
    switch (this.activeTool) {
      case SketchTool.Sel:
        this.handlePanStartSel(event);
        break;
      case SketchTool.Move:
        this.handlePanStartMove(event);
        break;
      case SketchTool.Rect:
        this.handlePanStartRect(event);
        break;
      case SketchTool.Ellipse:
        this.handlePanStartEllipse(event);
        break;
      case SketchTool.Pen:
        this.handlePanStartDrawing(event);
        break;
      case SketchTool.Text:
        this.handlePanStartText(event);
        break;
      case SketchTool.Wall:
        this.handlePanStartWall(event);
        break;
    }
  }

  private handleCanvasPanMove(event: HammerInput) {
    switch (this.activeTool) {
      case SketchTool.Move:
        this.handlePanMoveMove(event);
        break;
      case SketchTool.Sel:
        this.handlePanMoveSel(event);
        break;
      case SketchTool.Rect:
        this.handlePanMoveRect(event);
        break;
      case SketchTool.Ellipse:
        this.handlePanMoveEllipse(event);
        break;
      case SketchTool.Pen:
        this.handlePanMoveDrawing(event);
        break;
      case SketchTool.Text:
        this.handlePanMoveText(event);
        break;
      case SketchTool.Wall:
        this.handlePanMoveWall(event);
        break;
    }
  }

  private handleCanvasPanEnd(event: HammerInput) {
    switch (this.activeTool) {
      case SketchTool.Rect:
      case SketchTool.Ellipse:
        return;
      case SketchTool.Move:
        this.handlePanEndMove(event);
        break;
      case SketchTool.Text:
        this.handlePanEndText();
        break;
      case SketchTool.Pen:
        this.handlePanEndDrawing();
        break;
      case SketchTool.Sel:
        this.handlePanEndSel();
        break;
      case SketchTool.Wall:
        this.handlePanEndWall(event);
        break;
    }
  }

  private handleCanvasTap(event: HammerInput & { tapCount: number }) {
    if (event.tapCount === 2) {
      this.handleCanvasDblTap(event);
      return;
    }

    switch (this.activeTool) {
      case SketchTool.Text:
      case SketchTool.Rect:
      case SketchTool.Ellipse:
      case SketchTool.Pen:
      case SketchTool.Move:
        return;
      case SketchTool.Sel:
        this.handleTapSel(event);
        break;
      case SketchTool.Img:
        this.handleTapImage(event);
        break;
      case SketchTool.LibObject:
        this.handleTapLibObject(event);
        break;
    }
  }

  private handleCanvasDblTap(event: HammerInput) {
    if (this.activeTool !== SketchTool.Sel) return;

    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    for (let i = this.objects.length - 1; i >= 0; i--) {
      const obj = this.objects[i];
      const x = event.center.x - canvasX;
      const y = event.center.y - canvasY;
      if (obj instanceof Rect) {
        if (obj.isPointInsideWidthText(x, y)) {
          obj.emit('dbltapwidth', obj);
          return;
        }

        if (obj.isPointInsideHeightText(x, y)) {
          obj.emit('dbltapheight', obj);
          return;
        }
      }

      if (obj.selected && obj.isPointInsideObject(x, y)) {
        obj.emit('dbltap', event);
        return;
      }
    }
    this.emit('dbltap', event);
  }

  private handleCanvasPinchIn(event: HammerInput) {
    if (this.activeTool === SketchTool.Move) {
      this.emit('pinchin', event);
    }
  }

  private handleCanvasPinchOut(event: HammerInput) {
    if (this.activeTool === SketchTool.Move) {
      this.emit('pinchout', event);
    }
  }

  // *** Pan start handlers ***

  private handlePanStartDrawing({ center }: HammerInput) {
    const drawing = new Drawing({
      ...this.penToolSettings,
      systemOfMeasurement: this.systemOfMeasurement,
      name: `${this.defaultObjectName} ${this.objects.length}`,
      unsaved: true,
    });
    this.add(drawing);
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x, y } = this.getTranslatedZoomPoint(center.x - canvasX, center.y - canvasY);
    drawing.addPoint(x, y);
  }

  private handlePanStartText({ center }: HammerInput) {
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x, y } = this.getTranslatedZoomPoint(center.x - canvasX, center.y - canvasY);
    this.add(new Text({
      x,
      y,
      name: `${this.defaultObjectName} ${this.objects.length}`,
      unsaved: true,
      systemOfMeasurement: this.systemOfMeasurement,
    }));
  }

  private handlePanStartRect({ center }: HammerInput) {
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x, y } = this.getTranslatedZoomPoint(center.x - canvasX, center.y - canvasY);
    const rect = new Rect({
      ...this.rectToolSettings,
      systemOfMeasurement: this.systemOfMeasurement,
      x,
      y,
      name: `${this.defaultObjectName} ${this.objects.length}`,
      unsaved: true,
    });
    this.add(rect);
  }

  private handlePanStartEllipse({ center }: HammerInput) {
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x, y } = this.getTranslatedZoomPoint(center.x - canvasX, center.y - canvasY);
    const ellipse = new Ellipse({
      ...this.ellipseToolSettings,
      systemOfMeasurement: this.systemOfMeasurement,
      x,
      y,
      name: `${this.defaultObjectName} ${this.objects.length}`,
      unsaved: true,
    });
    this.add(ellipse);
  }

  private handlePanStartWall({ center }: HammerInput) {
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x: cx, y: cy } = this.getTranslatedZoomPoint(center.x - canvasX, center.y - canvasY);
    const point: DrawingPoint = [cx, cy];
    const intersection = this.searchWallIntersections(point);
    const wall = new Wall({
      points: [intersection || point],
      name: `${this.defaultObjectName} ${this.objects.length}`,
      systemOfMeasurement: this.systemOfMeasurement,
      unsaved: true,
      ...this.wallToolSettings,
    });
    this.add(wall);
  }

  private searchWallIntersections(point: DrawingPoint) {
    const walls = this.objects.filter(obj => obj instanceof Wall) as Wall[];
    for (const wall of walls) {
      const intersection = this.getWallIntersection(wall, point);
      if (intersection) return intersection;
    }
    return null;
  }

  private getWallIntersection(wall: Wall, point: DrawingPoint): DrawingPoint | null {
    for (const p of wall.points) {
      if (wall.getDistance(point, p) < 24) return [...p];
    }
    return null;
  }

  private handlePanStartMove(event: HammerInput) {
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    for (let i = this.objects.length - 1; i >= 0; i--) {
      const obj = this.objects[i];
      const x = event.center.x - canvasX;
      const y = event.center.y - canvasY;
      if (obj.selected && obj.isPointInsideObject(x, y)) {
        obj.emit('dragstart', event);
        return;
      }

      if (obj.selected && obj.isPointInsideRotationButton(x, y)) {
        obj.emit('rotatestart', event);
        return;
      }

      if (obj.selected && obj.isPointInsideResizeButton(x, y)) {
        obj.emit('resizestart', event);
        return;
      }
    }
    this.emit('dragstart', event);
  }

  private handlePanStartSel(event: HammerInput) {
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    this.selectStart = this.getTranslatedZoomPoint(event.center.x - canvasX, event.center.y - canvasY);
  }

  // *** Pan move handlers ***

  private handlePanMoveMove(event: HammerInput) {
    for (const obj of this.objects) {
      if (obj.dragging) {
        obj.emit('drag', event);
        return;
      }

      if (obj.rotating) {
        obj.emit('rotate', event);
        return;
      }

      if (obj.resizing) {
        obj.emit('resize', event);
        return;
      }
    }
    this.emit('drag', event);
  }

  private handlePanMoveSel(event: HammerInput) {
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    this.selectEnd = this.getTranslatedZoomPoint(event.center.x - canvasX, event.center.y - canvasY);
    const x = Math.min(this.selectStart!.x, this.selectEnd.x);
    const y = Math.min(this.selectStart!.y, this.selectEnd.y);
    const width = Math.abs(this.selectEnd.x - this.selectStart!.x);
    const height = Math.abs(this.selectEnd.y - this.selectStart!.y);
    const outerRect = {
      x,
      y,
      width,
      height,
    };
    for (const obj of this.objects) {
      const sel = obj.isContainedByRect(outerRect);
      if (sel) {
        obj.emit('insideselection');
      } else {
        obj.emit('outsideselection');
      }
    }
  }

  private handlePanMoveRect(event: HammerInput) {
    const rect = this.getLastObject() as Rect;
    rect.setWidth(event.deltaX / this.zoom);
    rect.setHeight(event.deltaY / this.zoom);
  }

  private handlePanMoveEllipse(event: HammerInput) {
    const ellipse = this.getLastObject() as Ellipse;
    ellipse.setRadiusX(Math.abs(event.deltaX / this.zoom));
    ellipse.setRadiusY(Math.abs(event.deltaX / this.zoom));
  }

  private handlePanMoveDrawing(event: HammerInput) {
    const drawing = this.getLastObject() as Drawing;
    const { center } = event;
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x, y } = this.getTranslatedZoomPoint(center.x - canvasX, center.y - canvasY);
    drawing.addPoint(x, y);
  }

  private handlePanMoveWall(event: HammerInput) {
    const wall = this.getLastObject() as Wall;
    const { center } = event;
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x, y } = this.getTranslatedZoomPoint(center.x - canvasX, center.y - canvasY);
    wall.replaceLastPointIfNeeded(x, y);
  }

  private handlePanMoveText(event: HammerInput) {
    const txt = this.getLastObject() as Text;
    txt.setWidth(event.deltaX / this.zoom);
    txt.setHeight(event.deltaY / this.zoom);
  }

  // *** Pan end handlers ***

  private handlePanEndMove(event: HammerInput) {
    for (const obj of this.objects) {
      if (obj.dragging) {
        obj.emit('dragend', event);
        return;
      }

      if (obj.rotating) {
        obj.emit('rotateend', event);
        return;
      }

      if (obj.resizing) {
        obj.emit('resizeend', event);
        return;
      }
    }
    this.emit('dragend', event);
  }

  private handlePanEndText() {
    const txt = this.getLastObject() as Text;
    txt.text = t('myText');
    txt.setStrokeStyle('rgba(0, 0, 0, 0)');
    txt.emit('ready');
  }

  private handlePanEndDrawing() {
    const drawing = this.getLastObject() as Drawing;
    drawing.emit('ready');
  }

  private handlePanEndWall(event: HammerInput) {
    const wall = this.getLastObject() as Wall;
    const { center } = event;
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x, y } = this.getTranslatedZoomPoint(center.x - canvasX, center.y - canvasY);
    const intersection = this.searchWallIntersections([x, y]);
    wall.replaceLastPointIfNeeded(...(intersection || [x, y]));
    wall.emit('ready');
  }

  private handlePanEndSel() {
    this.selectStart = null;
    this.selectEnd = null;
    this.draw();
  }

  // *** Tap handlers

  private handleTapSel(event: HammerInput) {
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    for (let i = this.objects.length - 1; i >= 0; i--) {
      const obj = this.objects[i];
      if (obj.isPointInsideObject(event.center.x - canvasX, event.center.y - canvasY)) {
        obj.emit('tap', event);
        return;
      }

      if (obj.isPointInsideDuplicateButton(event.center.x - canvasX, event.center.y - canvasY)) {
        obj.emit('duplicate', event);
        return;
      }
    }
    this.emit('tap', event);
  }

  private async handleTapImage({ center }: HammerInput) {
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x, y } = this.getTranslatedZoomPoint(center.x - canvasX, center.y - canvasY);
    if (!this.pickFile) return;
    const src = await this.pickFile();
    if (src) {
      this.saveUndoMemento();
      const img = new SketchImage({
        ...this.imageToolSettings,
        src,
        x,
        y,
        name: `${this.defaultObjectName} ${this.objects.length}`,
        unsaved: true,
        systemOfMeasurement: this.systemOfMeasurement,
      });
      img.on('load', () => {
        this.add(img);
      });
    }
  }

  private handleTapLibObject(event: HammerInput) {
    if (!this.currentLibObject) return;
    this.saveUndoMemento();
    const { x: canvasX, y: canvasY } = this.canvas!.getBoundingClientRect();
    const { x, y } = this.getTranslatedZoomPoint(event.center.x - canvasX, event.center.y - canvasY);
    const libObject = this.lib.find(obj => obj.id === this.currentLibObject);
    if (!libObject) return;
    this.loadObject(libObject.type === SketchObjectType.Image ? { ...this.imageToolSettings, ...libObject, x, y } : libObject);
  }

  private handleWheel({ offsetX, offsetY, deltaY }: WheelEvent) {
    if (!this.canvas) return;
    this.zoomCanvas(offsetX, offsetY, deltaY > 0);
  }

  private zoomCanvas(x: number, y: number, zoomIn?: boolean) {
    if (!this.zoomEnabled) return;
    const scaleAmount = 1.02;
    const zoomDelta = zoomIn ? 1 / scaleAmount : scaleAmount;
    const translatedZoomPoint = this.getTranslatedZoomPoint(x, y);
    const newZoom = this.zoom * zoomDelta;
    if (newZoom > 0.1 && newZoom < 10) {
      this.zoom *= zoomDelta;
      this.x = x - translatedZoomPoint.x * this.zoom;
      this.y = y - translatedZoomPoint.y * this.zoom;
      this.emit('update');
    }
  }

  private saveUndoMemento() {
    this.redoMementos = []; // reset redo
    // the strategy we chose to implement undo redo is memory intensive,
    // so limiting the number of undo actions helps avoiding memory issues.
    const maxUndoMementos = 30;
    if (this.undoMementos.length === maxUndoMementos) {
      this.undoMementos.shift();
    }
    this.undoMementos.push(this.export());
  }

  private saveRedoMemento() {
    this.redoMementos.push(this.export());
  }

  private drawSelectionArea() {
    if (!(this.selectStart && this.selectEnd)) return;
    const width = this.selectEnd.x - this.selectStart.x;
    const height = this.selectEnd.y - this.selectStart.y;
    const ctx = this.canvas?.getContext('2d')!;
    ctx.save();
    ctx.fillStyle = 'rgba(0, 0, 0, .3)';
    ctx.fillRect(this.selectStart.x, this.selectStart.y, width, height);
    ctx.restore();
  }
}

export default Sketch;
