import { gql } from '@apollo/client';

const GET_APP_VERSION = gql`
query appVersion {
  appVersion {
    currentVersion
    minVersion
    url
  }
}`;

export default GET_APP_VERSION;