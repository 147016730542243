import { gql } from '@apollo/client';

const GET_PROJECT_MANAGER_LIST = gql`
query projectManagers($regionId: Int!) {
  projectManagers(regionId: $regionId)
  { 
    id
    value
  } 
}`;

export default GET_PROJECT_MANAGER_LIST;