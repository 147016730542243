import React, { FC } from 'react';
import { IonLabel, IonText } from '@ionic/react';
import { MultiSelect } from 'react-multi-select-component';
import { t } from 'i18next';
import { Option } from '../utils/Interfaces';
import { OVERRIDE_STRINGS } from '../pages/helper/Const';

type Props = {
  title?: string;
  options: Option[];
  value: Option[];
  onChange?: any;
};

const styles: any = {
  ionLabel: {
    paddingLeft: 5,
    marginBottom: 5,
    marginTop: 5,
  },
  divFilter: {
    marginBottom: 15,
    marginTop: 10,
  },
};

const FosMultiSelectItem: FC<Props> = ({ title, options, value, onChange }) => (
  <div style={styles.divFilter}>
    <div style={styles.ionLabel}>
      <IonLabel>
        <IonText>
          {title}
        </IonText>
      </IonLabel>
    </div>
    <MultiSelect
      options={options}
      value={value}
      onChange={onChange}
      labelledBy="Select"
      overrideStrings={OVERRIDE_STRINGS(t)} />
  </div>
);

export default FosMultiSelectItem;
