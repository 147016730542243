import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRange,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Drawing } from '../sketch-tool';

interface DrawingEditProps {
  object: Drawing;
  isOpen: boolean;
  onClose: (event: any) => any;
}

const black = '#000';

const DrawingEdit: FC<DrawingEditProps> = ({ object, isOpen, onClose }: DrawingEditProps) => {
  const [lineWidth, setLineWidth] = useState(0);
  const [rotation, setRotation] = useState(0);
  const { t } = useTranslation();
  const [color, setColor] = useState(black);

  useEffect(() => {
    if (!isOpen) return;
    setLineWidth(object.lineWidth);
    setRotation(object.rotation);
    setColor(object.strokeStyle);
  }, [isOpen, object]);

  useEffect(() => {
    if (isOpen) object.setLineWidth(lineWidth);
  }, [lineWidth, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setRotation(rotation);
  }, [rotation, object, isOpen]);

  useEffect(() => {
    if (isOpen) object.setStrokeStyle(color);
  }, [color, object, isOpen]);

  const handleLineWidthChange = (event: any) => {
    setLineWidth(Number(event.detail.value));
  };

  const handleRotationChange = (event: any) => {
    setRotation(Number(event.detail.value));
  };

  const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} initialBreakpoint={0.25} breakpoints={[0, 0.25, 0.5, 0.75]}>
      <IonContent className="ion-padding">
        <div style={{ overflow: 'scroll' }}>
          <IonList>
            <IonListHeader>
              <IonLabel>
                {t('style').toString()}
              </IonLabel>
            </IonListHeader>
            <IonItem>
              <IonLabel position="stacked">
                {t('lineWidth').toString()}
              </IonLabel>
              <IonInput value={lineWidth} onIonChange={handleLineWidthChange} type="number" />
            </IonItem>
            <IonListHeader>
              <IonLabel>
                {t('position').toString()}
              </IonLabel>
            </IonListHeader>
            <IonItem lines="none">
              <IonLabel position="stacked">
                {t('rotation').toString()}
              </IonLabel>
              <IonRange
                value={rotation}
                onIonChange={handleRotationChange}
                pin
                pinFormatter={(value: number) => `${value}˚`}
                max={360}
              />
            </IonItem>
            <IonListHeader>
              <IonLabel>
                {t('style').toString()}
              </IonLabel>
            </IonListHeader>
            <IonItem lines="none">
              <IonLabel>
                {t('color').toString()}
              </IonLabel>
              <input type="color" onChange={handleColorChange} value={color} />
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default DrawingEdit;