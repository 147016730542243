import { gql } from '@apollo/client';

const GET_INSURANCE_COMPANIES_LIST = gql`
query insuranceCompanies($regionId: Int!) {
  insuranceCompanies(regionId: $regionId)
  { 
    active
    cmpnyIndx
    coCode
    company
  } 
}`;

export default GET_INSURANCE_COMPANIES_LIST;