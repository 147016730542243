import { useMutation } from '@tanstack/react-query';
import { UserState } from './authUtils';
import getGraphQLClient, { DomainParams } from './graphQLClientUtil';
import DELETE_EXPENSE from '../graphql/DeleteExpense';

/*
* GraphQL call: delete Expense
* */
const useDeleteExpense = (userState: UserState) => {
  const graphQLClient = getGraphQLClient(userState, DomainParams.Payroll);
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (id: number) => graphQLClient.request(DELETE_EXPENSE, { id }),
  });
  return { isLoading, mutateAsync };
};

export default useDeleteExpense;