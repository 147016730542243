import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  IonAccordion,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  useIonActionSheet,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import SketchTool from '../organism/SketchTool';
import {
  Sketch,
  SketchExport,
  SketchImage,
  SketchObject,
  SketchObjectType,
  SystemOfMeasurement,
} from '../sketch-tool';
import RenameModal from './RenameModal';
import FosThumbItem from './FosThumbItem';
import FosDoubleButton from './FosDoubleButton';
import { ReactComponent as MoreIcon } from '../assets/icons/ic_more.svg';
import { useAuth } from '../hooks/authContext';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import useSketchesList from '../hooks/sketches';
import useDeleteSketch from '../hooks/deleteSketch';
import useSaveSketch from '../hooks/saveSketch';
import { SketchType } from '../pages/helper/SketchType';
import { GUID } from '../graphql/GetUserProfileInfo';
import './AreaAccordion.css';

export interface Props {
  claimIndex: number;
  levelName: string;
  levelId: number;
  region: number;
  onChangeLevelName: (levelName: string) => any;
  onLevelDelete: () => any;
}

const AreaAccordion = React.forwardRef<HTMLIonAccordionElement, Props>(({ claimIndex, levelName, levelId, region, onChangeLevelName, onLevelDelete }, ref) => {

  const { t } = useTranslation();
  const levelAccordion = useRef(null);
  const { userState } = useAuth()!;
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [present] = useIonActionSheet();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLevelName, setCurrentLevelName] = useState('');
  const [sketchToLoad, setSketchToLoad] = useState<SketchExport | null>(null);
  const [savingSketch, setSavingSketch] = useState(false);
  const [refetchSketchesKey, setRefetchSketchesKey] = useState(Date.now());
  const { data: listSketchesRes } = useSketchesList(userState, levelId, refetchSketchesKey);
  const { mutateAsync: deleteSketch } = useDeleteSketch(userState);
  const { mutateAsync: saveSketchMutation } = useSaveSketch(userState);
  const isDuplicateLevelEnabled = process.env.REACT_APP_ENV !== 'prod';
  const isDeleteLevelEnabled = true;

  const sketchList: SketchExport[] = listSketchesRes?.listSketches?.map((s: SketchType) => ({
    id: s.sketchId,
    name: s.name,
    objects: s.sketchObjects?.map(o => ({ ...JSON.parse(o.jsonObject), id: o.sketchObjectId })) || [],
    systemOfMeasurement: s.unitSystem,
  })) || [];

  const [sketchToolIsOpen, setSketchToolIsOpen] = useState(false);

  const cancel = () => {
    setIsModalOpen(false);
    setCurrentLevelName(levelName);
  };

  const changeLevelName = (newName: string) => {
    if (newName !== '') {
      onChangeLevelName(newName);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {

    setCurrentLevelName(levelName);

    enum LevelAction {
      RENAME = 'Rename Level',
      DUPLICATE = 'Duplicate Level',
      DELETE = 'Delete Level',
      CANCEL = 'Cancel',
    }

    const levelActionSheetButtons = [
      {
        text: t('renameLevel'),
        data: {
          action: LevelAction.RENAME,
        },
      },
      {
        text: t('cancel'),
        role: 'destructive',
        data: {
          action: LevelAction.CANCEL,
        },
      },
    ];
    if (isDuplicateLevelEnabled) {
      const duplicateLevelButton = {
        text: t('duplicateLevel'),
        data: {
          action: LevelAction.DUPLICATE,
        },
      };
      levelActionSheetButtons.splice(1, 0, duplicateLevelButton);
    }
    if (isDeleteLevelEnabled) {
      const deleteLevelButton = {
        text: t('deleteLevel'),
        role: 'destructive',
        data: {
          action: LevelAction.DELETE,
        },
      };
      levelActionSheetButtons.splice(2, 0, deleteLevelButton);
    }

    if (levelAccordion.current) {
      (levelAccordion.current as HTMLElement).onclick = (event) => {
        event.stopPropagation();
        present({
          header: levelName,
          cssClass: 'custom-action-sheet',
          buttons: levelActionSheetButtons,
          onDidDismiss: ({ detail }) => {
            switch (detail.data?.action) {
              case LevelAction.RENAME: {
                setIsModalOpen(true);
                break;
              }
              case LevelAction.DUPLICATE: {
                // todo: add functionality
                break;
              }
              case LevelAction.DELETE: {
                onLevelDelete();
                break;
              }
              case LevelAction.CANCEL:
              default: break;
            }
          },
        });
      };
    }
  }, [isDeleteLevelEnabled, isDuplicateLevelEnabled, levelName, present, t, onLevelDelete]);

  const mapSketchExportToSave = (sketchExport: SketchExport) => ({
    claimIndx: claimIndex,
    levelId,
    name: sketchExport.name,
    sketchId: sketchExport.id,
    sketchObjects: sketchExport.objects?.map((o, order) => ({
      jsonObject: JSON.stringify(o),
      order,
      sketchId: sketchExport.id || 0,
      sketchObjectId: o.id || 0,
    })) || [],
    unitSystem: sketchExport.systemOfMeasurement === SystemOfMeasurement.Imperial ? 'I' : 'M',
  });

  const saveSketchImage = async (sketchId: string, file: File) => {
    try {
      setSavingSketch(true);
      const formData = new FormData();
      formData.append('SketchId', sketchId);
      formData.append('RegionId', `${region}`);
      formData.append('File', file);
      const restClient = getRestClient(userState, ClientType.FORM);
      const res = await restClient.post(`/common/claims/${claimIndex}/sketch`, formData);
      setSavingSketch(false);
      if (res?.data.downloadLink) return res?.data.downloadLink;
    } catch (error) {
      setSavingSketch(false);
    }

    return URL.createObjectURL(file);
  };

  const saveSketch = async (sketchExport: SketchExport) => {
    const res = await saveSketchMutation(mapSketchExportToSave(sketchExport));
    if (sketchExport.file && res.saveSketch.itemId) {
      await saveSketchImage(res.saveSketch.itemId, sketchExport.file);
    }
  };

  const handleSaveSketchTool = async (sketchExport: SketchExport) => {
    setSketchToolIsOpen(false);
    setSketchToLoad(null);
    await saveSketch(sketchExport);
    setRefetchSketchesKey(Date.now());
  };

  const saveFile = async (file: File) => {
    try {
      setSavingSketch(true);
      const formData = new FormData();
      formData.append('file', file);
      const restClient = getRestClient(userState, ClientType.FORM);
      const res = await restClient.put('/common/sketch/object/image', formData);
      setSavingSketch(false);
      if (res?.data.downloadLink) {
        const url = new URL(res.data.downloadLink);
        url.searchParams.set('fofid', res.data.fileId);
        return url.href;
      }
    } catch (error) {
      setSavingSketch(false);
    }

    return URL.createObjectURL(file);
  };

  const deleteFile = async (fileId: string) => {
    try {
      setSavingSketch(true);
      const restClient = getRestClient(userState, ClientType.JSON);
      await restClient.delete(`/common/sketch/object/image?fileId=${fileId}&userGuid=${window.localStorage.getItem(GUID)}`);
      setSavingSketch(false);
    } catch (error) {
      setSavingSketch(false);
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length <= 0) return;
    const file = files[0];
    const src = await saveFile(file);
    const s = new Sketch({ name: file.name });
    const canvas = document.createElement('canvas');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    s.setCanvas(canvas);
    const imgObj = s.loadObject({
      id: 0,
      type: SketchObjectType.Image,
      name: file.name,
      x: 0,
      y: 0,
      src,
      rotation: 0,
    });
    imgObj.on('load', async () => {
      await saveSketch(await s.save());
      setRefetchSketchesKey(Date.now());
      event.target.value = ''; // reset input
    });
  };

  const deleteImageFile = async (obj: SketchImage) => {
    const url = new URL(obj.src);
    const fileId = url.searchParams.get('fofid');
    if (fileId) {
      await deleteFile(fileId);
    }
  };

  const handleCancelSketchTool = async (newObjects: SketchObject[] = []) => {
    setSketchToolIsOpen(false);
    setSketchToLoad(null);
    for (const obj of newObjects) {
      if (obj instanceof SketchImage) {
        // eslint-disable-next-line no-await-in-loop
        await deleteImageFile(obj);
      }
    }
  };

  const handleSketchClick = (sketch: SketchExport) => {
    setSketchToLoad(sketch);
    setSketchToolIsOpen(true);
  };

  const handleRemoveSketch = async (sketch: SketchExport) => {
    await deleteSketch(+sketch.id!);
    setRefetchSketchesKey(Date.now());
  };

  const handleCreateSketch = async () => {
    const newSketch: SketchExport = {
      type: SketchObjectType.Group,
      id: 0,
      name: t('newSketch'),
      objects: [],
    };

    const res = await saveSketchMutation({
      claimIndx: claimIndex,
      levelId,
      name: newSketch.name,
      sketchId: 0,
      sketchObjects: [],
    });

    newSketch.id = Number(res!.saveSketch!.itemId);

    setSketchToLoad(newSketch);
    setSketchToolIsOpen(true);
    setRefetchSketchesKey(Date.now());
  };

  const handleDeleteSketchObject = async (obj: SketchObject) => {
    if (obj instanceof SketchImage) {
      await deleteImageFile(obj);
    }
  };

  const handleUploadSketch = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <IonLoading isOpen={savingSketch} />
      <IonAccordion ref={ref} value="level" className='media-inspection-accordion-item'>
        <IonItem slot="header" color="light">
          <IonLabel className='media-inspection-label'>{levelName}</IonLabel>
          <IonButton fill='clear' className='media-inspection-more-button' ref={levelAccordion}>
            <MoreIcon fill="none" className="more-icon" />
          </IonButton>
        </IonItem>
        <div className="ion-padding-top ion-padding-bottom" slot="content">
          <IonList>
            {sketchList.map(sketch => (
              <FosThumbItem
                key={sketch.id}
                label={sketch.name!}
                thumbSrc="assets/images/sketch.svg"
                onClick={() => handleSketchClick(sketch)}
                onRemove={(event) => {
                  event.stopPropagation();
                  handleRemoveSketch(sketch);
                }}
              />
            ))}
          </IonList>
          <FosDoubleButton
            leftButtonLabel={t('uploadSketch').toString()}
            rightButtonLabel={t('createSketch').toString()}
            onLeftClick={handleUploadSketch}
            onRightClick={handleCreateSketch} />
        </div>
        <input ref={fileInputRef} type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />
      </IonAccordion>
      <div className="media-inspection-divider" />
      <SketchTool
        saveFile={saveFile}
        sketch={sketchToLoad}
        isOpen={sketchToolIsOpen}
        onClose={handleCancelSketchTool}
        onSave={handleSaveSketchTool}
        onDeleteObject={handleDeleteSketchObject}
      />
      <RenameModal
        currentName={currentLevelName}
        modalTitle={t('renameLevel')}
        isModalOpen={isModalOpen}
        onCancel={cancel}
        onNameChanged={(newName) => changeLevelName(newName)} />
    </>
  );
});

export default AreaAccordion;
