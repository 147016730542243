import axios from 'axios';
import { UserState } from '../hooks/authUtils';
import { BASE_API_URL } from './Env';

export enum ClientType {
  PLAIN = 'text/plain',
  FORM = 'multipart/form-data',
  JSON = 'application/json',
  PDF = 'application/pdf',
}

export enum AcceptType {
  PDF = 'application/octet-stream',
}

const getRestClient = (userState: UserState, clientType: ClientType, acceptType?: AcceptType) =>
  axios.create({
    baseURL: BASE_API_URL,
    headers: {
      common: {
        Authorization: `Bearer ${userState.userAuthPayload?.accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': clientType,
        Accept: acceptType || 'application/json',
        'Access-Control': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, Access-Control-Allow-Origin, Access-Control',
        'GraphQL-preflight': 1,
      },
    },
  });

export default getRestClient;