import * as Comlink from 'comlink';
import type { EncodeOptions as AvifEncodeOptions } from './avif/enc/avif_enc';
import { EncodeOptions as JpegEncodeOptions } from './mozjpeg/enc/mozjpeg_enc';

interface CodecWorker {
  avifEncode: (imageData: ImageData, options?: AvifEncodeOptions) => Promise<ArrayBufferLike>
  jpegEncode: (imageData: ImageData, options?: JpegEncodeOptions) => Promise<ArrayBuffer>
}

const worker = new Worker(new URL('./codecWorker.ts', import.meta.url));

export function getFileImageData(blob: Blob): Promise<ImageData> {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(blob);

    const img = document.createElement('img');
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d')!;
      ctx.drawImage(img, 0, 0);
      const data = ctx.getImageData(0, 0, img.width, img.height);
      URL.revokeObjectURL(url);
      resolve(data);
    });
    img.src = url;
  });
}

export default Comlink.wrap<CodecWorker>(worker);
