/* eslint-disable react/require-default-props */
import { IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import React, { FC, MouseEvent } from 'react';

import './ButtonSolid.css';

type Props = {
  id?: string;
  onClick?: (ev: MouseEvent) => any;
};

type StyleType = {
  container: React.CSSProperties;
  icon: React.CSSProperties;
};

const styles: StyleType = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 16px',
    width: 54,
    height: 46,
    background: '#1C244F',
    borderRadius: 12,
    flex: 'none',
  },
  icon: {
    color: '#FFFFFF',
    width: 24,
    height: 24,
  },
};

const ButtonSolid: FC<Props> = ({ id, onClick }: Props) => (
  <button className='add-button-solid' type="button" style={styles.container} id={id} onClick={onClick}>
    <IonIcon icon={add} style={styles.icon} />
  </button>
);

export default ButtonSolid;
