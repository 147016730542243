import { gql } from '@apollo/client';

export type UserSuggestion = {
  userGuid: String;
  userName: String;
  fullName: String;
};

const GET_USER_SUGGESTIONS = gql`
query userByNameTerm ($term: String) {
  userByNameTerm(term: $term)
  {
    userGuid
    userName
    fullName
  }
}`;

export default GET_USER_SUGGESTIONS;