import { useState } from 'react';
import { useIonActionSheet } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { DocumentActionSheetInterface } from '../pages/helper/DocumentActionSheetInterface';
import openDocumentURL from '../pages/helper/Documents';
import { PermissionsList, usePermissions } from './permissions';

enum DocumentAction {
  OPEN = 'Open',
  EDIT = 'Edit',
  DELETE = 'Delete',
  SHARE = 'Share',
  CANCEL = 'Cancel',
}

const useHandleDocumentActionSheet = (fetchDocumentDetails: any) => {
  const [present] = useIonActionSheet();
  const { t } = useTranslation();
  const { permissions } = usePermissions();
  const isDeleteDocumentEnabled = permissions.includes(PermissionsList.DeleteDocuments);
  const isDeleteEnabled = process.env.REACT_APP_ENV !== 'prod' && isDeleteDocumentEnabled;
  const isShareEnabled = process.env.REACT_APP_ENV !== 'prod';
  const [isEditDocumentToClaimFormOpen, setIsEditDocumentToClaimFormOpen] = useState<boolean>(false);

  const handleDocumentActionSheet = ({
    docURL,
    docId,
  }: DocumentActionSheetInterface) => {
    const buttons: any[] = [
      {
        text: t('open'),
        data: {
          action: DocumentAction.OPEN,
          docURL,
          docId,
        },
      },
      {
        text: t('edit'),
        data: {
          action: DocumentAction.EDIT,
        },
      },
      {
        text: t('cancel'),
        role: 'destructive',
        data: {
          action: DocumentAction.CANCEL,
        },
      },
    ];
    if (isShareEnabled) {
      const shareButton = {
        text: t('share'),
        data: {
          action: DocumentAction.SHARE,
        },
      };
      buttons.splice(1, 0, shareButton);
    }
    if (isDeleteEnabled) {
      const deleteButton = {
        text: t('delete'),
        data: {
          action: DocumentAction.DELETE,
        },
      };
      buttons.splice(1, 0, deleteButton);
    }

    present({
      header: t('documentOptions'),
      cssClass: 'custom-action-sheet',
      buttons,
      onDidDismiss: ({ detail }) => {
        switch (detail.data?.action) {
          case DocumentAction.OPEN: {
            openDocumentURL(detail.data?.docURL);
            break;
          }
          case DocumentAction.EDIT: {
            setIsEditDocumentToClaimFormOpen(true);
            fetchDocumentDetails(docId);
            break;
          }
          case DocumentAction.DELETE: {
            // todo: add functionality
            break;
          }
          case DocumentAction.SHARE: {
            // todo: add functionality
            break;
          }
          case DocumentAction.CANCEL:
          default: break;
        }
      },
    });
  };
  return { 
    isEditDocumentToClaimFormOpen,
    handleDocumentActionSheet, 
    setIsEditDocumentToClaimFormOpen,
  };
};

export default useHandleDocumentActionSheet;