import { gql } from '@apollo/client';

const GET_PHASES_LIST = gql`
query phaseCategories($regionId: Int!) {
  phaseCategories(regionId: $regionId)
  { 
    id
    value
  } 
}`;

export default GET_PHASES_LIST;