import { gql } from '@apollo/client';

const SAVE_EQUIPMENT_FORM = gql`
mutation saveEquipment($regionId: Int!, $userGuid: UUID!, $item: SaveEquipmentDtoInput!) {
  saveEquipment(regionId: $regionId, userGuid: $userGuid, item: $item)
  {
    itemId
    status
    message
  }
}`;

export default SAVE_EQUIPMENT_FORM;
