/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable no-console */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { MsAuthPlugin } from '@recognizebv/capacitor-plugin-msauth';
import * as msal from '@azure/msal-browser';
import camelCase from 'camelcase-keys';
import { isPlatform } from '@ionic/react';
import { SilentRequest } from '@azure/msal-browser';
import { useHistory } from 'react-router';
import { GetUserResponse, GET_USER, User } from '../graphql/GetUser';
import {
  authLink,
  MSSOAuthResponse,
  AUTH_CONF,
  httpLink,
  loginRequest,
  msalConfig,
  AuthContext,
  parseJwt,
  ResponseJWT,
  UserState,
  UserAuthPayload,
  AUTH_LOCATION_KEY,
} from './authUtils';
import { GUID, REGION_ID } from '../graphql/GetUserProfileInfo';

type Props = {
  children: React.ReactNode;
};

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const myMSALObj = new msal.PublicClientApplication(msalConfig);

export const UserAuthContext = createContext<AuthContext | undefined>(undefined);

export const AuthProvider = (props: Props) => {
  const [userState, setUserState] = useState<UserState>({
    initialized: false,
    loggedIn: false,
    loading: false,
  });
  const [user, setUser] = useState<User | undefined>();
  const history = useHistory();
  const [error, setError] = useState<Error | undefined>();

  const getUser = useMemo(
    () => async (userAuthPayload: UserAuthPayload) => {
      window.localStorage.setItem(AUTH_LOCATION_KEY, JSON.stringify(userAuthPayload));
      try {
        const userQuery: GetUserResponse = await client!.query({
          query: GET_USER,
          variables: {
            username: userAuthPayload.preferredUsername.split('@')[0],
          },
        });
        const { userByUserName } = userQuery?.data || { userByUserName: {} };
        window.localStorage.setItem(AUTH_LOCATION_KEY, JSON.stringify(userAuthPayload));
        setUser(userByUserName);

        // get from storage to match what user selected at settings dropdown.
        const regionStorage = window.localStorage.getItem(`${REGION_ID}_${userByUserName?.userGuid || ''}`);
        if (regionStorage) {
          userAuthPayload.regionId = Number(regionStorage);
        } else {
          // default region 1
          userAuthPayload.regionId = userByUserName?.regionId || 1;
        }

        setUserState({
          loading: false,
          initialized: true,
          loggedIn: true,
          userAuthPayload,
        });
        window.localStorage.setItem(GUID, userByUserName !== null ? userByUserName.userGuid as string : '');
        window.localStorage.setItem(`${REGION_ID}_${userByUserName?.userGuid || ''}`, userAuthPayload.regionId.toString());

      } catch (e) {
        setError(e as Error);
        window.localStorage.removeItem(AUTH_LOCATION_KEY);
        setUser(undefined);
        setUserState({
          loading: false,
          initialized: true,
          loggedIn: false,
        });
      }
    },
    [setUserState, setUser]
  );

  const values = useMemo(() => {
    const manageResponse = async (response: MSSOAuthResponse) => {
      const parsedJWT: ResponseJWT = camelCase(parseJwt(response.accessToken));

      const userPersist: UserAuthPayload = {
        preferredUsername: parsedJWT.preferredUsername,
        accessToken: response.accessToken,
        idToken: response.idToken,
        regionId: 0,
        expiration: parsedJWT.exp,
      };
      await getUser(userPersist);
    };
    const logIn = async () => {
      setUserState({ ...userState, loading: true });

      try {
        let response: MSSOAuthResponse | null = null;

        if (isPlatform('hybrid')) {
          response = await MsAuthPlugin.login(AUTH_CONF); // native
        } else {
          response = await myMSALObj.loginPopup(loginRequest);
        }
        if (response !== null) {
          await manageResponse(response);
        }
      } catch (e) {
        console.log('error on sso');
        setError(e as Error);
        setUserState({ ...userState, loading: false });
        window.localStorage.removeItem(AUTH_LOCATION_KEY);
      }
    };

    const logOut = async () => {
      if (isPlatform('hybrid')) {
        await MsAuthPlugin.logout(AUTH_CONF);
      } else {
        const username = userState.userAuthPayload?.preferredUsername;
        if (username) {
          await myMSALObj.logoutPopup({ account: myMSALObj.getAccountByUsername(username) });
        }
      }
      await client?.clearStore();
      window.localStorage.removeItem(AUTH_LOCATION_KEY);
      setUserState({ initialized: true, loggedIn: false, userAuthPayload: undefined, loading: false });
      setUser(undefined);
    };

    const refreshToken = async () => {
      try {
        let response: MSSOAuthResponse | null = null;

        if (isPlatform('hybrid')) {
          response = await MsAuthPlugin.login(AUTH_CONF); // native
        } else {
          const request: SilentRequest = loginRequest;
          const account = myMSALObj.getAllAccounts()[0];
          request.account = account;
          response = await myMSALObj.acquireTokenSilent(request);
        }
        const lastToken = userState.userAuthPayload?.accessToken;
        if (response?.accessToken && lastToken !== response.accessToken) {
          const userAuthPayload : UserAuthPayload | undefined = userState.userAuthPayload || undefined;
          if (userAuthPayload) {
            const parsedJWT: ResponseJWT = camelCase(parseJwt(response.accessToken));
            userAuthPayload.accessToken = response.accessToken;
            userAuthPayload.idToken = response.idToken;
            userAuthPayload.expiration = parsedJWT.exp;
          }
          setUserState({
            loading: false,
            initialized: true,
            loggedIn: true,
            userAuthPayload,
          });
        }
      } catch (e) {
        setError(e as Error);
        setUserState({ ...userState, loading: false });
        window.localStorage.removeItem(AUTH_LOCATION_KEY);
        history.replace('/tabs/jobs');
      }
    };

    const checkTokenExpiration = async () => {
      const expiration = userState.userAuthPayload?.expiration;
      if (expiration) {
        const currentDate = new Date();
        const tokenExpDate = new Date(expiration * 1000);
        if (tokenExpDate < currentDate) {
          await refreshToken();
        }
      }
    };

    return {
      userState,
      logOut,
      logIn,
      refreshToken,
      checkTokenExpiration,
      error,
      user,
    };
  }, [userState, error, user, getUser, history]);

  useEffect(() => {
    if (!userState.initialized && !userState.loading) {
      const storage = window.localStorage.getItem(AUTH_LOCATION_KEY);
      const userStorage: UserAuthPayload | null = storage ? JSON.parse(storage) : null;

      if (userStorage !== null) {
        setUserState({ ...userState, loading: true });
        getUser(userStorage);
      } else {
        setUserState({
          initialized: true,
          loggedIn: false,
          loading: false,
        });
        setUser(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <UserAuthContext.Provider value={values} {...props} />;
};

export const useAuth: () => AuthContext | undefined = () => useContext(UserAuthContext);
