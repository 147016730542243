import { gql } from '@apollo/client';

export type RegionsType = {
  regionId : number,
  regionCode : string,
  regionCodeShort : string,
  regionName: string,
};

const GET_REGIONS_LIST = gql`
query associatedRegions($userGuid: UUID!) {
  associatedRegions(userGuid: $userGuid)
  { 
    regionId
    regionCode
    regionCodeShort
    regionName
  } 
}`;

export default GET_REGIONS_LIST;