import { gql } from '@apollo/client';

export type EquipmentClassType = {
  active: boolean;
  class: string;
  classIndx: string;
};

const GET_EQUIPMENT_CLASSES_LIST = gql`
query equipmentClasses($regionId: Int!) {
  equipmentClasses(regionId: $regionId)
  { 
    active
    class
    classIndx
  } 
}`;

export default GET_EQUIPMENT_CLASSES_LIST;