import { useContext, useEffect } from 'react';
import { UserState } from './authUtils';
import { DomainParams } from './graphQLClientUtil';
import GET_SAFETY_INFO from '../graphql/GetSafetyInfo';
import useBaseQuery from './baseQuery';
import { claimsKeys } from './claims';
import NetworkContext from '../contexts/NetworkContext';
import { getClaimSafety, storeClaimSafety } from '../pages/helper/offlineHelper/ClaimSafetyHelper';
import { ClaimOnlineStatus } from '../pages/helper/Const';

const useSafetyInfo = (
  userState: UserState,
  claimId: string,
  safetyInfo?: any,
  setSafetyInfo?: (_safetyInfo: any) => void,
  onSuccess?: (_data: any) => void,
  setValuesForForm?: (_safetyInfo: any) => void,
) => {
  const network = useContext(NetworkContext);
  const enabled = network.connected && !!claimId;
  const region = userState.userAuthPayload?.regionId!;

  const localSuccess = async (_data: any) => {
    if (_data?.inspectionSafety) {
      if (setSafetyInfo) {
        setSafetyInfo(_data?.inspectionSafety);
      }
      if (setValuesForForm) {
        setValuesForForm(_data?.inspectionSafety);
      }
      await storeClaimSafety({ info: _data?.inspectionSafety, claimIndx: claimId!, status: ClaimOnlineStatus.AvailableOffline });
    }
    if (onSuccess) await onSuccess(_data);
  };

  const { data, isLoading, isFetching, refetch } = useBaseQuery(
    region,
    { claimId },
    userState,
    enabled,
    GET_SAFETY_INFO,
    claimsKeys.inspectionSafety(),
    DomainParams.Common,
    localSuccess,
  );

  useEffect(() => {
    if (!network.connected && !safetyInfo && claimId) {
      const getAsync = async () => {
        const { info: localInspectionSafety } = await getClaimSafety(Number(claimId));
        if (setSafetyInfo) {
          setSafetyInfo(localInspectionSafety);
        }
        if (setValuesForForm) {
          setValuesForForm(localInspectionSafety);
        }
      };
      getAsync();
    }
  }, [claimId, network.connected, safetyInfo, setSafetyInfo, setValuesForForm]);
  
  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};

export default useSafetyInfo;
