import { Storage } from '@ionic/storage';
import { UserState } from '../../../../hooks/authUtils';
import { EquipmentGenericItemType } from '../../../../graphql/EquipmentGenericItemType';
import useBaseCacheAndList from '../../../../hooks/baseCacheAndList';
import GET_EQUIPMENT_COST_CATEGORIES_LIST from '../graphql/GetEquipmentCostCategories';
import equipmentKeys from './equipmentKeys';
import { DomainParams } from '../../../../hooks/graphQLClientUtil';

const useEquipmentCostCategories = (region: number, userState: UserState, storage: Storage) => {
  const equipmentBranches: EquipmentGenericItemType[] = useBaseCacheAndList(
    region,
    userState,
    storage,
    GET_EQUIPMENT_COST_CATEGORIES_LIST,
    equipmentKeys.equipmentCostCategories(),
    'equipmentCostCategories',
    DomainParams.Equipments);
  return equipmentBranches;
};

export default useEquipmentCostCategories;