import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import './FosLabelValue.css';
import { close } from 'ionicons/icons';
import './FosModalHeader.css';

export interface Props {
  title?: string;
  onCloseClick: (event: any) => any;
}

const FosModalHeader: React.FC<Props> = (({ title, onCloseClick }) => (
  <IonHeader class="ion-no-border">
    <IonToolbar class="fos-modal-header">
      {title && <IonTitle>{title}</IonTitle>}
      <IonButtons slot="end">
        <IonButton className='close-button' fill='clear' onClick={onCloseClick}>
          <div className="close-icon-container">
            <IonIcon className="tools-close-button" icon={close} />
          </div>
        </IonButton>
      </IonButtons>
    </IonToolbar>
  </IonHeader>
));

export default FosModalHeader;
