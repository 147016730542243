import { gql } from '@apollo/client';

const SAVE_SKETCH = gql`
mutation SaveSketch($sketch: SketchInput!) {
  saveSketch(sketch: $sketch)
  {
    status
    itemId
  }
}`;

export default SAVE_SKETCH;
