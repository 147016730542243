import { FileType } from './Const';

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'];

const getFileType = (filename: string) => {
  const extension = filename?.split('.')?.pop()?.toLowerCase();
  if (extension) {
    if (imageExtensions.includes(extension)) {
      return FileType.Image;
    } 
    
    return FileType.Document;
  }
};

export default getFileType;