import { gql } from '@apollo/client';

export type ClaimDocumentTypesType = {
  fileTypeCd : string,
  description : string,
};

const GET_CLAIM_DOCUMENT_TYPES_LIST = gql`
query documentTypes  {
  documentTypes
  {
    description
    fileTypeCd
  }
}`;

export default GET_CLAIM_DOCUMENT_TYPES_LIST;